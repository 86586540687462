import * as React from 'react'

import { observer } from 'mobx-react'

import PermitDatePicker from '../../../PermitDatePicker/PermitDatePicker'
import SitePermitCreationFormStore from '../../SitePermitCreationForm.store'
import FormDropdownOptionsModal from './components/FormDropdownOptionsModal/FormDropdownOptionsModal'

// localization: no text to translate

interface IProps {
  store: SitePermitCreationFormStore
}

@observer
export default class PermitContentPickers extends React.Component<IProps> {
  public render() {
    const { store } = this.props
    const { isDatePickerDisplayed, resetSelectedField } = store

    if (isDatePickerDisplayed) {
      const {
        datePickerStart,
        datePickerEnd,
        shouldShowTimePicker,
        isOneDayMode,
        maxDateRangeInYears,
      } = store

      return (
        <PermitDatePicker
          applyDates={this.onApplyDates}
          hideDatePicker={resetSelectedField}
          initialStartDate={datePickerStart}
          initialEndDate={datePickerEnd}
          shouldShowTimePicker={shouldShowTimePicker}
          isOneDayMode={isOneDayMode}
          maxRangeInYears={maxDateRangeInYears}
        />
      )
    }

    return <FormDropdownOptionsModal store={store} />
  }

  private onApplyDates = (newStartDate: Date, newEndDate: Date) => {
    const { isPermitDatesFieldSelected, applyDates, changeSelectedFieldValue } =
      this.props.store

    if (isPermitDatesFieldSelected) {
      applyDates(newStartDate, newEndDate)
    } else {
      changeSelectedFieldValue(newStartDate.getTime())
    }
  }
}
