import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type IProjectVisitorOptionsFieldsFragment = Pick<
  Types.IProjectVisitorOptions,
  | 'id'
  | 'projectId'
  | 'limitUserDirectory'
  | 'permitRequestEnabled'
  | 'requireToCreateAccount'
> & {
  contactsVisibleToVisitors?: Types.Maybe<
    Pick<Types.IContactsVisibleToVisitors, 'teams' | 'users' | 'defaultTeams'>
  >
}

export type IGetProjectVisitorOptionsQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetProjectVisitorOptionsQuery = {
  projectVisitorOptions?: Types.Maybe<{
    data: Array<IProjectVisitorOptionsFieldsFragment>
  }>
}

export type ISaveProjectVisitorOptionsMutationVariables = Types.Exact<{
  projectVisitorOptions: Types.IProjectVisitorOptionsInput
}>

export type ISaveProjectVisitorOptionsMutation = {
  saveProjectVisitorOptions?: Types.Maybe<IProjectVisitorOptionsFieldsFragment>
}

export type IListenToProjectVisitorOptionsSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToProjectVisitorOptionsSubscription = {
  projectVisitorOptions?: Types.Maybe<
    Pick<Types.IProjectVisitorOptionsChangeEvent, 'id'> & {
      item?: Types.Maybe<IProjectVisitorOptionsFieldsFragment>
    }
  >
}

export const ProjectVisitorOptionsFieldsFragmentDoc = gql`
  fragment ProjectVisitorOptionsFields on ProjectVisitorOptions {
    id
    projectId
    limitUserDirectory
    permitRequestEnabled
    requireToCreateAccount
    contactsVisibleToVisitors {
      teams
      users
      defaultTeams
    }
  }
`
export const GetProjectVisitorOptionsDocument = gql`
  query GetProjectVisitorOptions($projectId: ObjectId!) {
    projectVisitorOptions(projectId: $projectId, limit: 1) {
      data {
        ...ProjectVisitorOptionsFields
      }
    }
  }
  ${ProjectVisitorOptionsFieldsFragmentDoc}
`

/**
 * __useGetProjectVisitorOptionsQuery__
 *
 * To run a query within a React component, call `useGetProjectVisitorOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectVisitorOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectVisitorOptionsQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectVisitorOptionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetProjectVisitorOptionsQuery,
    IGetProjectVisitorOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetProjectVisitorOptionsQuery,
    IGetProjectVisitorOptionsQueryVariables
  >(GetProjectVisitorOptionsDocument, options)
}
export function useGetProjectVisitorOptionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetProjectVisitorOptionsQuery,
    IGetProjectVisitorOptionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetProjectVisitorOptionsQuery,
    IGetProjectVisitorOptionsQueryVariables
  >(GetProjectVisitorOptionsDocument, options)
}
export type GetProjectVisitorOptionsQueryHookResult = ReturnType<
  typeof useGetProjectVisitorOptionsQuery
>
export type GetProjectVisitorOptionsLazyQueryHookResult = ReturnType<
  typeof useGetProjectVisitorOptionsLazyQuery
>
export type GetProjectVisitorOptionsQueryResult = Apollo.QueryResult<
  IGetProjectVisitorOptionsQuery,
  IGetProjectVisitorOptionsQueryVariables
>
export const SaveProjectVisitorOptionsDocument = gql`
  mutation SaveProjectVisitorOptions(
    $projectVisitorOptions: ProjectVisitorOptionsInput!
  ) {
    saveProjectVisitorOptions(projectVisitorOptions: $projectVisitorOptions) {
      ...ProjectVisitorOptionsFields
    }
  }
  ${ProjectVisitorOptionsFieldsFragmentDoc}
`
export type ISaveProjectVisitorOptionsMutationFn = Apollo.MutationFunction<
  ISaveProjectVisitorOptionsMutation,
  ISaveProjectVisitorOptionsMutationVariables
>

/**
 * __useSaveProjectVisitorOptionsMutation__
 *
 * To run a mutation, you first call `useSaveProjectVisitorOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveProjectVisitorOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveProjectVisitorOptionsMutation, { data, loading, error }] = useSaveProjectVisitorOptionsMutation({
 *   variables: {
 *      projectVisitorOptions: // value for 'projectVisitorOptions'
 *   },
 * });
 */
export function useSaveProjectVisitorOptionsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveProjectVisitorOptionsMutation,
    ISaveProjectVisitorOptionsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveProjectVisitorOptionsMutation,
    ISaveProjectVisitorOptionsMutationVariables
  >(SaveProjectVisitorOptionsDocument, options)
}
export type SaveProjectVisitorOptionsMutationHookResult = ReturnType<
  typeof useSaveProjectVisitorOptionsMutation
>
export type SaveProjectVisitorOptionsMutationResult =
  Apollo.MutationResult<ISaveProjectVisitorOptionsMutation>
export type SaveProjectVisitorOptionsMutationOptions =
  Apollo.BaseMutationOptions<
    ISaveProjectVisitorOptionsMutation,
    ISaveProjectVisitorOptionsMutationVariables
  >
export const ListenToProjectVisitorOptionsDocument = gql`
  subscription ListenToProjectVisitorOptions($projectId: ObjectId!) {
    projectVisitorOptions(projectId: $projectId) {
      id
      item {
        ...ProjectVisitorOptionsFields
      }
    }
  }
  ${ProjectVisitorOptionsFieldsFragmentDoc}
`

/**
 * __useListenToProjectVisitorOptionsSubscription__
 *
 * To run a query within a React component, call `useListenToProjectVisitorOptionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToProjectVisitorOptionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToProjectVisitorOptionsSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToProjectVisitorOptionsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToProjectVisitorOptionsSubscription,
    IListenToProjectVisitorOptionsSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToProjectVisitorOptionsSubscription,
    IListenToProjectVisitorOptionsSubscriptionVariables
  >(ListenToProjectVisitorOptionsDocument, options)
}
export type ListenToProjectVisitorOptionsSubscriptionHookResult = ReturnType<
  typeof useListenToProjectVisitorOptionsSubscription
>
export type ListenToProjectVisitorOptionsSubscriptionResult =
  Apollo.SubscriptionResult<IListenToProjectVisitorOptionsSubscription>
