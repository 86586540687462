import { enumToList } from '~/client/src/shared/utils/converters'

import Localization from '../localization/LocalizationManager'

enum FormReportFilterType {
  COMPANY = 'company',
  STATUS = 'status',
  LOCATION = 'location',
}

export const getFormReportFilterTypeCaption = (type: FormReportFilterType) => {
  switch (type) {
    case FormReportFilterType.COMPANY:
      return Localization.translator.company
    case FormReportFilterType.STATUS:
      return Localization.translator.status
    case FormReportFilterType.LOCATION:
      return Localization.translator.location
    default:
      throw new Error(type + ' is unhandled')
  }
}

export const formReportFilterTypes = enumToList(FormReportFilterType)

export default FormReportFilterType
