import * as React from 'react'

import { Dialog, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import CompactCompaniesDirectory from '~/client/src/shared/components/CompactCompaniesDirectory/CompactCompaniesDirectory'
import * as Icons from '~/client/src/shared/components/Icons'
import LocationPicker from '~/client/src/shared/components/LocationPicker/LocationPicker'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import UsersDirectory from '~/client/src/shared/components/UsersDirectory/UsersDirectory'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import {
  AndOrOperator,
  IsNotOperator,
} from '~/client/src/shared/models/LogicOperation'
import OperationRule from '~/client/src/shared/models/OperationRule'
import User from '~/client/src/shared/models/User'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { NOOP } from '~/client/src/shared/utils/noop'

import BallInCourtRulesTableRowStore from './BallInCourtRulesTableRow.store'
import CategorySelectorRow from './components/CategorySelectorRow'

// localization: translated

interface IProps {
  bicRule: OperationRule
  ruleIndex: number
  className?: string

  tagsStore: TagsStore

  getUsersFromAssignment: (assignmentExpressions: string[]) => User[]
  onRemoveRuleClick: (ruleId: string) => void
  onDuplicateRuleClick: (ruleId: string) => void
  onChangeUserForRule: (
    rule: OperationRule,
    userId: string,
    isNew?: boolean,
  ) => void
  onRuleExpressionChange: (
    rule: OperationRule,
    locationAndCompanyExpression: string,
  ) => void
}

const ICON_SIZE = 20

@observer
export default class BallInCourtRulesTableRow extends React.Component<IProps> {
  private readonly store: BallInCourtRulesTableRowStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new BallInCourtRulesTableRowStore(
      props.tagsStore,
      props.bicRule,
      props.onRuleExpressionChange,
    )
  }

  public componentDidUpdate(prevProps: IProps) {
    const { bicRule } = this.props
    if (bicRule !== prevProps.bicRule) {
      this.store.init(bicRule)
    }
  }

  public render() {
    const { ruleIndex, className } = this.props
    const {
      selectedBicRule,
      changeHoveredRuleId,
      resetHoveredRuleId,
      toggleUsersDirectory,
    } = this.store

    return (
      <div
        className={classList({
          'row y-start lp05 bic-rules-table-row pt10 relative': true,
          [className]: !!className,
        })}
        onMouseEnter={changeHoveredRuleId.bind(null, selectedBicRule.id)}
        onMouseLeave={resetHoveredRuleId}
      >
        <div className="row no-grow x-center y-center text large pl15 pr10 mt10">
          {ruleIndex}.
        </div>
        <div className="col pl5">
          <div className="row y-start relative">
            {this.renderAssignedUsers(selectedBicRule)}
          </div>
          <div className="mw100">
            <div
              className="row text large line-extra-large grey-30 pointer py6 px10"
              onClick={toggleUsersDirectory}
            >
              <Icon icon={IconNames.PLUS} className="no-grow" />
              <span className="no-grow nowrap ml3">
                {Localization.translator.addUser}
              </span>
            </div>
          </div>
          <span className="row text large line-extra-large grey-30 pt10 pb5 px10">
            {Localization.translator.notifyUserIfWorkflowHasTags}:
          </span>
          <div className="row y-start relative">
            {this.renderRuleExpression()}
          </div>
        </div>
        {this.renderRuleActionButtons(selectedBicRule.id)}
      </div>
    )
  }

  private renderAssignedUsers(operationRule: OperationRule): JSX.Element {
    const { getUsersFromAssignment } = this.props

    const users = getUsersFromAssignment(
      operationRule.assignmentsAsSubexpressions,
    )

    return (
      <div className="row wrap ml5">
        {users?.map(user => (
          <div key={user.id} className="row no-grow py4 pl10">
            <div className="row w-fit-content">
              <UserProfilePreview user={user} isBriefInfoHidden />
              <Icon
                className="bg-grey-scale-light br-rounded pointer text white ml10"
                icon={IconNames.SMALL_CROSS}
                onClick={this.handleRemoveUserFromRule.bind(
                  null,
                  operationRule,
                  user.id,
                )}
              />
            </div>
          </div>
        ))}
        {this.renderUserPickerDialog(users, operationRule)}
      </div>
    )
  }

  private renderRuleExpression(): JSX.Element {
    const {
      locationElements,
      companyElements,
      locationNotOperatorValue,
      companyNotOperatorValue,
      toggleLocationPicker,
      toggleCompaniesPicker,
      removeTagFromElements,
    } = this.store

    return (
      <div className="col mt10">
        {this.renderLocationPickerDialog()}
        {this.renderCompanyPickerDialog()}

        <CategorySelectorRow
          className="location-sel"
          categoryNameAndIconElement={this.locationCategorySelector}
          categoryElements={locationElements}
          noElementsTitle={Localization.translator.anyLocation}
          isNotOperatorValue={locationNotOperatorValue}
          onAddNewElement={toggleLocationPicker}
          onTagRemove={removeTagFromElements}
          onNotOperatorChange={this.handleNotLocationOperatorChange}
        />
        <CategorySelectorRow
          className="company-sel"
          categoryNameAndIconElement={this.companyCategorySelector}
          categoryElements={companyElements}
          noElementsTitle={Localization.translator.anyCompany}
          categoryOperator={AndOrOperator.AND}
          isNotOperatorValue={companyNotOperatorValue}
          onAddNewElement={toggleCompaniesPicker}
          onTagRemove={removeTagFromElements}
          onNotOperatorChange={this.handleNotCompanyOperatorChange}
        />
      </div>
    )
  }

  private renderUserPickerDialog(
    selectedUsers: User[],
    operationRule: OperationRule,
  ): JSX.Element {
    const { shouldShowUsersDirectory, hideUsersDirectory } = this.store

    return (
      <Dialog
        isOpen={shouldShowUsersDirectory}
        onClose={hideUsersDirectory}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        className="bic-rule-tags-dialog bg-white"
      >
        <UsersDirectory
          className="scrollable pt10 bg-transparent relative"
          selectedUsers={selectedUsers}
          shouldUseGroupByModal
          isSelectionMode
          shouldSelectOnlyUsers
          shouldHideSelectAllBtn
          onUserAddClick={this.handleAddUserToRule.bind(null, operationRule)}
          onUserRemoveClick={this.handleRemoveUserFromRule.bind(
            null,
            operationRule,
          )}
          onHeaderCloseClick={hideUsersDirectory}
        />
      </Dialog>
    )
  }

  private renderLocationPickerDialog(): JSX.Element {
    const {
      shouldShowLocationPicker,
      selectedLocationTagIds,
      hideLocationPicker,
    } = this.store

    return (
      <Dialog
        isOpen={shouldShowLocationPicker}
        onClose={hideLocationPicker}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        className="bic-rule-tags-dialog bg-white"
      >
        <LocationPicker
          title={Localization.translator.selectLocation}
          applyButtonTitle={Localization.translator.select}
          resetDefaultsTitle={Localization.translator.clearAll_items}
          selectedIds={selectedLocationTagIds}
          isSingleSelectionMode={true}
          onChange={NOOP}
          onSingleSelect={this.addNewLocationToElements}
          onApplyChanges={hideLocationPicker}
          onClose={hideLocationPicker}
          onResetSelection={this.handleClearAllLocations}
        />
      </Dialog>
    )
  }

  private renderCompanyPickerDialog(): JSX.Element {
    const {
      shouldShowCompaniesPicker,
      selectedCompanyTagIds,
      hideCompaniesPicker,
      addNewTagToElements,
      removeTagFromElements,
    } = this.store

    return (
      <Dialog
        isOpen={shouldShowCompaniesPicker}
        onClose={hideCompaniesPicker}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        className="bic-rule-tags-dialog bg-white"
      >
        <CompactCompaniesDirectory
          className="relative"
          isPhoneHidden={true}
          isSelectionMode={true}
          selectedCompanyIds={selectedCompanyTagIds}
          onHeaderCloseClick={hideCompaniesPicker}
          onCompanyAddClick={addNewTagToElements}
          onCompanyRemoveClick={removeTagFromElements}
          onResetClick={this.handleClearAllCompanies}
        />
      </Dialog>
    )
  }

  private renderRuleActionButtons(ruleId: string): JSX.Element {
    const { onDuplicateRuleClick, onRemoveRuleClick } = this.props
    const { hoveredRuleId } = this.store

    if (hoveredRuleId !== ruleId) {
      return null
    }

    return (
      <div className="col mt25 no-grow bic-rule-action-btns">
        <Icon
          icon={IconNames.DUPLICATE}
          iconSize={ICON_SIZE}
          className="text grey-light pointer absolute bic-duplicate-btn"
          onClick={onDuplicateRuleClick.bind(null, ruleId)}
        />
        <Icon
          icon={IconNames.TRASH}
          iconSize={ICON_SIZE}
          className="text grey-light pointer absolute mt50 bic-delete-btn"
          onClick={onRemoveRuleClick.bind(null, ruleId)}
        />
      </div>
    )
  }

  private get locationCategorySelector(): JSX.Element {
    return (
      <>
        <Icons.Location className="bic-rule-category-icon mr4" />
        {Localization.translator.location}
      </>
    )
  }

  private get companyCategorySelector(): JSX.Element {
    return (
      <>
        <Icons.CompanyCompact className="bic-rule-category-icon mr4" />
        {Localization.translator.company}
      </>
    )
  }

  private handleAddUserToRule = (rule: OperationRule, userId: string) => {
    this.store.hideUsersDirectory()
    this.props.onChangeUserForRule(rule, userId, true)
  }

  private handleRemoveUserFromRule = (rule: OperationRule, userId: string) => {
    this.store.hideUsersDirectory()
    this.props.onChangeUserForRule(rule, userId)
  }

  private handleClearAllLocations = () => {
    this.store.clearAllElements()
  }

  private handleClearAllCompanies = () => {
    this.store.clearAllElements(true)
  }

  private handleNotLocationOperatorChange = (operator: IsNotOperator) => {
    this.store.changeNotOperator(operator)
  }

  private handleNotCompanyOperatorChange = (operator: IsNotOperator) => {
    this.store.changeNotOperator(operator, true)
  }

  private addNewLocationToElements = (location: LocationBase) => {
    this.store.addNewTagToElements(location.id)
  }
}
