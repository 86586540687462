import React from 'react'

import { Icon, Intent, ProgressBar, Spinner } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import {
  IReport,
  IReportFileInput,
  IReportTemplate,
  IReportTemplateFile,
  ISubscription,
  ReportStatus,
} from '~/client/graph'
import { ListenGeneratedReportDocument } from '~/client/graph/operations/generated/Reports.generated'
import { IAppConfig } from '~/client/src/shared/Config'
import { Loader } from '~/client/src/shared/components/Loader'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import { ReportFileTypes } from '~/client/src/shared/enums/ReportFileTypes'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import {
  DOWNLOAD_REPORT_FILE,
  LOAD_REPORT_TEMPLATE,
} from '~/client/src/shared/stores/EventStore/eventConstants'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { showErrorToast } from '~/client/src/shared/utils/toaster'
import { downloadFile } from '~/client/src/shared/utils/util'

import EventsStore from '../../stores/EventStore/Events.store'

import './ReportsDownload.scss'

const reportFileTypeLabel = {
  [ReportFileTypes.LIST_AS_TABLE]: 'PDF',
  [ReportFileTypes.LIST_OF_SINGLE]: 'Detailed PDF',
  [ReportFileTypes.CSV_LIST_REPORT]: 'CSV',
}

interface IProps {
  eventsStore?: EventsStore
  graphExecutorStore?: GraphExecutorStore
  projectDateStore?: ProjectDateStore
  configuration?: IAppConfig
  activeReportViewType: string
  title: string
  templateId: string
  reportData: any
  dateFrom: Date
  dateTo: Date
  isProjectRangeActive: boolean
  isCSVOnly?: boolean
}

@inject(
  'eventsStore',
  'graphExecutorStore',
  'configuration',
  'projectDateStore',
)
@observer
export default class ReportsDownload extends React.Component<IProps> {
  private readonly reportGenerationChangesSubscriptionId =
    'reportGenerationStatusChanges'
  @observable private open: boolean = false
  @observable private loading: boolean = false
  @observable private isDownloading: boolean = false
  @observable private downloadingReport?: IReport = null
  @observable private fileTypes: IReportTemplateFile[]

  public componentDidMount(): void {
    if (this.props.activeReportViewType) {
      this.loadReportTemplate()
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    if (this.props.activeReportViewType !== prevProps.activeReportViewType) {
      this.loadReportTemplate()
    }
  }

  private listenReportGenerating = () => {
    const { activeProject } = this.props.eventsStore.appState
    this.props.graphExecutorStore.subscribe(
      ListenGeneratedReportDocument,
      { projectId: activeProject.id },
      false,
      this.reportGenerationChangesSubscriptionId,
      ({ report }: ISubscription) => {
        this.onStatusChanged(report.item)
      },
    )
  }

  private terminateGeneratingSubscription = () => {
    this.props.graphExecutorStore.terminateSubscription(
      this.reportGenerationChangesSubscriptionId,
    )
  }

  @action.bound
  private onStatusChanged(result: IReport) {
    this.downloadingReport = result
    switch (result.status) {
      case ReportStatus.Done:
        // UI tweak to show smoothly progress bar
        window.setTimeout(() => {
          // Do not use target="_blank". Browser will block your redirect
          downloadFile(result.reportUrls[0], result.reportUrls[0])
          this.isDownloading = false
          this.downloadingReport = null
        }, 400)
        this.terminateGeneratingSubscription()
        break
      case ReportStatus.Failed:
        showErrorToast(Localization.translator.filedToGenerateReport)
        this.isDownloading = false
        this.downloadingReport = null
    }
  }

  @action.bound
  private loadReportTemplate() {
    this.loading = true
    this.props.eventsStore.dispatch(
      LOAD_REPORT_TEMPLATE,
      this.props.templateId,
      this.reportTemplateReceived,
    )
  }

  @action.bound
  private reportTemplateReceived(reportTemplate: IReportTemplate) {
    this.fileTypes = reportTemplate.files || []
    this.loading = false
  }

  public render() {
    const { isCSVOnly, dateFrom } = this.props

    return (
      <div className="ml15 no-flex relative col x-center">
        <>
          {this.isDownloading && (
            <ProgressBar
              intent={Intent.SUCCESS}
              value={this.downloadingProgress}
            />
          )}
          {isCSVOnly ? (
            <div
              className={classList({
                'row y-center btn-filter reports-header-btn px10 x-between':
                  true,
                'unclickable-element': this.open || this.isDownloading,
                'reports-header-btn-outline': !this.isDownloading,
              })}
              key={ReportFileTypes.CSV_LIST_REPORT + dateFrom.toString()}
              onClick={this.startDownload.bind(
                this,
                this.fileTypes?.find(
                  _ => _.fileId == ReportFileTypes.CSV_LIST_REPORT,
                ),
              )}
            >
              {this.isDownloading
                ? `${this.downloadingStatusLabel}...`
                : `${Localization.translator.download} ${
                    reportFileTypeLabel[ReportFileTypes.CSV_LIST_REPORT]
                  }`}
              {this.isDownloading && (
                <Spinner intent={Intent.NONE} size={14} className="mx10" />
              )}
            </div>
          ) : (
            <div
              className={classList({
                'row y-center btn-filter gant-header-btn pl15 x-between download-button':
                  true,
                'unclickable-element': this.open || this.isDownloading,
                'gant-header-btn-outline': !this.isDownloading,
              })}
              onClick={this.toggle}
            >
              {this.isDownloading
                ? `${this.downloadingStatusLabel}...`
                : Localization.translator.download}
              {this.isDownloading ? (
                <Spinner intent={Intent.NONE} size={14} className="mx10" />
              ) : (
                <Icon icon={IconNames.CHEVRON_DOWN} iconSize={11} />
              )}
            </div>
          )}
          {this.open && (
            <MenuCloser closeMenu={this.toggle}>
              <div className="dropdown absolute bg-white download-dropdown ba-light-input-border">
                {this.loading ? (
                  <Loader />
                ) : (
                  this.fileTypes.map(fileType => (
                    <div
                      key={fileType.fileId}
                      className="dropdown__item row y-center py10 px10 text large pointer"
                      onClick={this.startDownload.bind(this, fileType)}
                    >
                      {reportFileTypeLabel[fileType.fileId]}
                    </div>
                  ))
                )}
              </div>
            </MenuCloser>
          )}
        </>
      </div>
    )
  }

  @action.bound
  private startDownload(fileType: IReportTemplateFile) {
    const {
      eventsStore,
      title,
      templateId,
      reportData,
      configuration: { TENANT_ID },
      projectDateStore: { getMonthDayAndYearToDisplay },
      dateFrom,
      dateTo,
      isProjectRangeActive,
    } = this.props

    const dateFromToDisplay = getMonthDayAndYearToDisplay(dateFrom)
    const dateToToDisplay = getMonthDayAndYearToDisplay(dateTo)
    const fileNameDates = isProjectRangeActive
      ? Localization.translator.fullProject
      : `${dateFromToDisplay}-${dateToToDisplay}`

    const { activeProject } = eventsStore.appState
    const files: IReportFileInput[] = [
      {
        fileId: fileType.fileId,
        fileName: `${TENANT_ID}.${
          activeProject.name
        }.${title} ${fileNameDates}.${fileType.fileType.toLowerCase()}`,
      },
    ]

    const data = {
      projectId: activeProject.id,
      timezoneId: activeProject.timezoneId,
      logoUrl: activeProject.logoUrl,
      ...reportData,
    }

    this.open = false
    this.isDownloading = true

    this.listenReportGenerating()

    this.props.eventsStore.dispatch(
      DOWNLOAD_REPORT_FILE,
      templateId,
      files,
      title,
      data,
      data.projectId,
      dateFrom.getTime(),
      dateTo.getTime(),
      data.timezoneId,
      false,
      isProjectRangeActive,
    )
  }

  private get downloadingStatusLabel() {
    switch (this.downloadingReport?.status) {
      case ReportStatus.Starting:
      case ReportStatus.FetchingData:
        return Localization.translator.downloadingStatus.fetching
      case ReportStatus.CompilingHandlebarsTemplate:
      case ReportStatus.GeneratingReport:
        return Localization.translator.downloadingStatus.generating
      case ReportStatus.Done:
        return Localization.translator.downloadingStatus.downloading
      default:
        return Localization.translator.downloadingStatus.starting
    }
  }

  private get downloadingProgress() {
    switch (this.downloadingReport?.status) {
      case ReportStatus.Starting:
      case ReportStatus.FetchingData:
        return 0.4
      case ReportStatus.CompilingHandlebarsTemplate:
      case ReportStatus.GeneratingReport:
        return 0.7
      case ReportStatus.Done:
        return 1
      default:
        return 0.1
    }
  }

  @action.bound
  private toggle() {
    this.open = !this.open
  }
}
