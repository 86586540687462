import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'

import { ISitePermit, SitePermitStatus } from '~/client/graph'
import { DeleteFormsDocument } from '~/client/graph/operations/generated/SitePermits.generated'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import SitePermit from '~/client/src/shared/models/Permit'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import ProjectDateStore, {
  isAfter,
} from '~/client/src/shared/stores/ui/ProjectDate.store'
import {
  ToastTheme,
  showErrorToast,
  showToast,
} from '~/client/src/shared/utils/toaster'

import InitialState from '../InitialState'
import GraphExecutorStore from './GraphExecutor.store'
import LocationAttributesStore from './LocationAttributes.store'

export default class SitePermitsStore {
  @observable public isDataReceived = false

  public constructor(
    private eventsStore: EventsStore,
    private graphExecutorStore: GraphExecutorStore,
    private projectDateStore: ProjectDateStore,
    private locationAttributesStore: LocationAttributesStore,
  ) {}

  @computed
  public get list(): SitePermit[] {
    const { sitePermits, isFormsDisabled } = this.eventsStore.appState
    // TODO: Clear memory when functionality is disabled and request data when enabled again
    return isFormsDisabled
      ? []
      : Array.from(sitePermits.values()).filter(f => !f.isDeleted)
  }

  @computed
  public get allSitemapAttributes(): LocationBase[] {
    return this.locationAttributesStore.allAttributesWithDeletedItems
  }

  public getPermitsForDay = (
    date: Date | number,
    permits: SitePermit[] = [],
  ) => {
    const { isWithinDateInterval, isSameDay } = this.projectDateStore
    const now = Date.now()
    return (permits.length ? permits : this.list).filter(
      p =>
        // start < date < end
        isWithinDateInterval(p.startDate, p.endDate, date) ||
        // or (end < date <= today) and (not finished)
        (p.isLate && (isAfter(now, date) || isSameDay(now, date))),
    )
  }

  public getFormById = (id: string): SitePermit => {
    if (this.byId.has(id)) {
      return this.byId.get(id)
    }
  }

  public getRelatedPermitsByAttribute(attributeId: string): SitePermit[] {
    return this.list.filter(permit => {
      return permit.locations?.some(l => l.id === attributeId)
    })
  }

  public getClosedIntervals = (attributeId: string) => {
    return this.list.filter(permit => {
      const isRelatedPermit = permit.locations?.some(l => l.id === attributeId)
      return isRelatedPermit && permit.shouldShowClosure
    })
  }

  public clearList() {
    this.isDataReceived = false
    this.byId.clear()
  }

  public receiveList(list: ISitePermit[]) {
    this.clearList()
    this.updateFromList(list)
    this.isDataReceived = true
  }

  public updateFromList(list: ISitePermit[]) {
    list.forEach(dto => {
      this.receiveOne(dto.id, dto)
    })
  }

  public receiveOne(id: string, dto: ISitePermit) {
    if (dto) {
      const permit = SitePermit.fromDto(dto)
      this.byId.set(permit.id, permit)
    } else {
      this.byId.delete(id)
    }
  }

  @action.bound
  public async deleteForms(ids: string[]) {
    this.state.loading.set(e.DELETE_SITE_PERMITS, true)

    const res = await this.graphExecutorStore.mutate(DeleteFormsDocument, {
      ids,
    })

    const deletedFormIds = res?.data?.softDeleteManyForms
    this.markAsDeleted(deletedFormIds)

    this.state.loading.set(e.DELETE_SITE_PERMITS, false)

    if (res?.error) {
      return showErrorToast(Localization.translator.failedToDelete)
    }

    this.showSuccessToast(deletedFormIds?.length)
  }

  @action.bound
  private markAsDeleted(ids: string[]) {
    if (!ids?.length) {
      return
    }

    ids.forEach(id => {
      if (!this.byId.has(id)) return

      const form = this.byId.get(id)
      form.isDeleted = true
      form.setStatus(SitePermitStatus.Deleted)
    })
  }

  private showSuccessToast(deletedFormsCount: number) {
    if (deletedFormsCount) {
      showToast(
        Localization.translator.successfullyDeletedForms(deletedFormsCount),
        ToastTheme.SUCCESS,
        IconNames.TRASH,
      )
    }
  }

  public get isDeleting(): boolean {
    return this.state.loading.get(e.DELETE_SITE_PERMITS)
  }

  private get byId() {
    return this.state.sitePermits
  }

  private get state(): InitialState {
    return this.eventsStore.appState
  }
}
