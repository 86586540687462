import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ICommonPermitFieldsFragment_AttachmentPermitField_Fragment = Pick<
  Types.IAttachmentPermitField,
  'fieldId' | 'type'
> & {
  attachmentValues?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IAttachment, 'fileName' | 'size' | 'url'>>>
  >
}

export type ICommonPermitFieldsFragment_ChecklistAnswerItemPermitField_Fragment =
  Pick<Types.IChecklistAnswerItemPermitField, 'fieldId' | 'type'> & {
    checklistValues?: Types.Maybe<
      Array<
        Types.Maybe<
          Pick<Types.IChecklistAnswerItem, 'checklistItemId' | 'answer'>
        >
      >
    >
  }

export type ICommonPermitFieldsFragment_DateTimePermitField_Fragment = Pick<
  Types.IDateTimePermitField,
  'dateTimeValues' | 'fieldId' | 'type'
>

export type ICommonPermitFieldsFragment_LocationPermitField_Fragment = Pick<
  Types.ILocationPermitField,
  'fieldId' | 'type'
> & {
  locationValues?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.ISiteLocation, 'id' | 'type' | 'levels'>>>
  >
}

export type ICommonPermitFieldsFragment_MaterialFormField_Fragment = Pick<
  Types.IMaterialFormField,
  'fieldId' | 'type'
> & {
  materialValues?: Types.Maybe<
    Array<
      Types.Maybe<
        Pick<
          Types.IFormMaterial,
          'materialId' | 'procurementId' | 'quantity' | 'locationId'
        >
      >
    >
  >
}

export type ICommonPermitFieldsFragment_MeasurePermitField_Fragment = Pick<
  Types.IMeasurePermitField,
  'fieldId' | 'type'
> & {
  measureValues?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.IMeasure, 'units' | 'value'>>>
  >
}

export type ICommonPermitFieldsFragment_ObjectIdPermitField_Fragment = Pick<
  Types.IObjectIdPermitField,
  'objectIdValues' | 'fieldId' | 'type'
>

export type ICommonPermitFieldsFragment_StringPermitField_Fragment = Pick<
  Types.IStringPermitField,
  'stringValues' | 'fieldId' | 'type'
>

export type ICommonPermitFieldsFragment_TablePermitField_Fragment = Pick<
  Types.ITablePermitField,
  'fieldId' | 'type'
>

export type ICommonPermitFieldsFragmentFragment =
  | ICommonPermitFieldsFragment_AttachmentPermitField_Fragment
  | ICommonPermitFieldsFragment_ChecklistAnswerItemPermitField_Fragment
  | ICommonPermitFieldsFragment_DateTimePermitField_Fragment
  | ICommonPermitFieldsFragment_LocationPermitField_Fragment
  | ICommonPermitFieldsFragment_MaterialFormField_Fragment
  | ICommonPermitFieldsFragment_MeasurePermitField_Fragment
  | ICommonPermitFieldsFragment_ObjectIdPermitField_Fragment
  | ICommonPermitFieldsFragment_StringPermitField_Fragment
  | ICommonPermitFieldsFragment_TablePermitField_Fragment

export type IPermitModelFieldsFragment = Pick<
  Types.ISitePermit,
  | 'createdAt'
  | 'endDate'
  | 'id'
  | 'isClosure'
  | 'projectId'
  | 'startDate'
  | 'status'
  | 'threadId'
  | 'title'
  | 'typeId'
  | 'updatedAt'
  | 'code'
  | 'currentWorkflowStepId'
  | 'workflowStepLevel'
  | 'isAutoActivationEnabled'
  | 'isDeleted'
> & {
  specificInspectionOptions?: Types.Maybe<
    Pick<
      Types.IInspectionOptions,
      | 'deadlineTime'
      | 'inspectionFrequency'
      | 'inspectionFrequencyType'
      | 'selectedDaysToRepeat'
    >
  >
  fields?: Types.Maybe<
    Array<
      Types.Maybe<
        | ICommonPermitFieldsFragment_AttachmentPermitField_Fragment
        | ICommonPermitFieldsFragment_ChecklistAnswerItemPermitField_Fragment
        | ICommonPermitFieldsFragment_DateTimePermitField_Fragment
        | ICommonPermitFieldsFragment_LocationPermitField_Fragment
        | ICommonPermitFieldsFragment_MaterialFormField_Fragment
        | ICommonPermitFieldsFragment_MeasurePermitField_Fragment
        | ICommonPermitFieldsFragment_ObjectIdPermitField_Fragment
        | ICommonPermitFieldsFragment_StringPermitField_Fragment
        | ({
            tableValues?: Types.Maybe<
              Array<
                Types.Maybe<{
                  cells?: Types.Maybe<
                    Array<
                      Types.Maybe<
                        | ICommonPermitFieldsFragment_AttachmentPermitField_Fragment
                        | ICommonPermitFieldsFragment_ChecklistAnswerItemPermitField_Fragment
                        | ICommonPermitFieldsFragment_DateTimePermitField_Fragment
                        | ICommonPermitFieldsFragment_LocationPermitField_Fragment
                        | ICommonPermitFieldsFragment_MaterialFormField_Fragment
                        | ICommonPermitFieldsFragment_MeasurePermitField_Fragment
                        | ICommonPermitFieldsFragment_ObjectIdPermitField_Fragment
                        | ICommonPermitFieldsFragment_StringPermitField_Fragment
                        | ICommonPermitFieldsFragment_TablePermitField_Fragment
                      >
                    >
                  >
                }>
              >
            >
          } & ICommonPermitFieldsFragment_TablePermitField_Fragment)
      >
    >
  >
}

export type IGetSitePermitListQueryVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetSitePermitListQuery = {
  sitePermits?: Types.Maybe<{ data: Array<IPermitModelFieldsFragment> }>
}

export type ISaveSitePermitsMutationVariables = Types.Exact<{
  permits:
    | Array<Types.Maybe<Types.IPermitWithFieldsInput>>
    | Types.Maybe<Types.IPermitWithFieldsInput>
}>

export type ISaveSitePermitsMutation = {
  saveManySitePermits?: Types.Maybe<
    Array<Types.Maybe<IPermitModelFieldsFragment>>
  >
}

export type IDeleteFormsMutationVariables = Types.Exact<{
  ids:
    | Array<Types.Maybe<Types.Scalars['ObjectId']>>
    | Types.Maybe<Types.Scalars['ObjectId']>
}>

export type IDeleteFormsMutation = Pick<Types.IMutation, 'softDeleteManyForms'>

export type IListenToSitePermitSubscriptionVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IListenToSitePermitSubscription = {
  sitePermit?: Types.Maybe<
    Pick<Types.ISitePermitChangeEvent, 'id'> & {
      item?: Types.Maybe<IPermitModelFieldsFragment>
    }
  >
}

export const CommonPermitFieldsFragmentFragmentDoc = gql`
  fragment CommonPermitFieldsFragment on PermitFieldInterface {
    fieldId
    type
    ... on AttachmentPermitField {
      attachmentValues {
        fileName
        size
        url
      }
    }
    ... on ChecklistAnswerItemPermitField {
      checklistValues {
        checklistItemId
        answer
      }
    }
    ... on DateTimePermitField {
      dateTimeValues
    }
    ... on LocationPermitField {
      locationValues {
        id
        type
        levels
      }
    }
    ... on MeasurePermitField {
      measureValues {
        units
        value
      }
    }
    ... on StringPermitField {
      stringValues
    }
    ... on ObjectIdPermitField {
      objectIdValues
    }
    ... on MaterialFormField {
      materialValues {
        materialId
        procurementId
        quantity
        locationId
      }
    }
  }
`
export const PermitModelFieldsFragmentDoc = gql`
  fragment PermitModelFields on SitePermit {
    createdAt
    endDate
    id
    isClosure
    projectId
    startDate
    status
    threadId
    title
    typeId
    updatedAt
    code
    specificInspectionOptions {
      deadlineTime
      inspectionFrequency
      inspectionFrequencyType
      selectedDaysToRepeat
    }
    currentWorkflowStepId
    workflowStepLevel
    isAutoActivationEnabled
    fields {
      ...CommonPermitFieldsFragment
      ... on TablePermitField {
        tableValues {
          cells {
            ...CommonPermitFieldsFragment
          }
        }
      }
    }
    isDeleted
  }
  ${CommonPermitFieldsFragmentFragmentDoc}
`
export const GetSitePermitListDocument = gql`
  query GetSitePermitList($projectId: ObjectId!) {
    sitePermits(limit: 1000000, projectId: $projectId) {
      data {
        ...PermitModelFields
      }
    }
  }
  ${PermitModelFieldsFragmentDoc}
`

/**
 * __useGetSitePermitListQuery__
 *
 * To run a query within a React component, call `useGetSitePermitListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSitePermitListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSitePermitListQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetSitePermitListQuery(
  baseOptions: Apollo.QueryHookOptions<
    IGetSitePermitListQuery,
    IGetSitePermitListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    IGetSitePermitListQuery,
    IGetSitePermitListQueryVariables
  >(GetSitePermitListDocument, options)
}
export function useGetSitePermitListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    IGetSitePermitListQuery,
    IGetSitePermitListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    IGetSitePermitListQuery,
    IGetSitePermitListQueryVariables
  >(GetSitePermitListDocument, options)
}
export type GetSitePermitListQueryHookResult = ReturnType<
  typeof useGetSitePermitListQuery
>
export type GetSitePermitListLazyQueryHookResult = ReturnType<
  typeof useGetSitePermitListLazyQuery
>
export type GetSitePermitListQueryResult = Apollo.QueryResult<
  IGetSitePermitListQuery,
  IGetSitePermitListQueryVariables
>
export const SaveSitePermitsDocument = gql`
  mutation SaveSitePermits($permits: [PermitWithFieldsInput]!) {
    saveManySitePermits(permits: $permits) {
      ...PermitModelFields
    }
  }
  ${PermitModelFieldsFragmentDoc}
`
export type ISaveSitePermitsMutationFn = Apollo.MutationFunction<
  ISaveSitePermitsMutation,
  ISaveSitePermitsMutationVariables
>

/**
 * __useSaveSitePermitsMutation__
 *
 * To run a mutation, you first call `useSaveSitePermitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSitePermitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSitePermitsMutation, { data, loading, error }] = useSaveSitePermitsMutation({
 *   variables: {
 *      permits: // value for 'permits'
 *   },
 * });
 */
export function useSaveSitePermitsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ISaveSitePermitsMutation,
    ISaveSitePermitsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    ISaveSitePermitsMutation,
    ISaveSitePermitsMutationVariables
  >(SaveSitePermitsDocument, options)
}
export type SaveSitePermitsMutationHookResult = ReturnType<
  typeof useSaveSitePermitsMutation
>
export type SaveSitePermitsMutationResult =
  Apollo.MutationResult<ISaveSitePermitsMutation>
export type SaveSitePermitsMutationOptions = Apollo.BaseMutationOptions<
  ISaveSitePermitsMutation,
  ISaveSitePermitsMutationVariables
>
export const DeleteFormsDocument = gql`
  mutation DeleteForms($ids: [ObjectId]!) {
    softDeleteManyForms(ids: $ids)
  }
`
export type IDeleteFormsMutationFn = Apollo.MutationFunction<
  IDeleteFormsMutation,
  IDeleteFormsMutationVariables
>

/**
 * __useDeleteFormsMutation__
 *
 * To run a mutation, you first call `useDeleteFormsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFormsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFormsMutation, { data, loading, error }] = useDeleteFormsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useDeleteFormsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IDeleteFormsMutation,
    IDeleteFormsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IDeleteFormsMutation,
    IDeleteFormsMutationVariables
  >(DeleteFormsDocument, options)
}
export type DeleteFormsMutationHookResult = ReturnType<
  typeof useDeleteFormsMutation
>
export type DeleteFormsMutationResult =
  Apollo.MutationResult<IDeleteFormsMutation>
export type DeleteFormsMutationOptions = Apollo.BaseMutationOptions<
  IDeleteFormsMutation,
  IDeleteFormsMutationVariables
>
export const ListenToSitePermitDocument = gql`
  subscription ListenToSitePermit($projectId: ObjectId!) {
    sitePermit(projectId: $projectId) {
      item {
        ...PermitModelFields
      }
      id
    }
  }
  ${PermitModelFieldsFragmentDoc}
`

/**
 * __useListenToSitePermitSubscription__
 *
 * To run a query within a React component, call `useListenToSitePermitSubscription` and pass it any options that fit your needs.
 * When your component renders, `useListenToSitePermitSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListenToSitePermitSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useListenToSitePermitSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    IListenToSitePermitSubscription,
    IListenToSitePermitSubscriptionVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSubscription<
    IListenToSitePermitSubscription,
    IListenToSitePermitSubscriptionVariables
  >(ListenToSitePermitDocument, options)
}
export type ListenToSitePermitSubscriptionHookResult = ReturnType<
  typeof useListenToSitePermitSubscription
>
export type ListenToSitePermitSubscriptionResult =
  Apollo.SubscriptionResult<IListenToSitePermitSubscription>
