import { FirebaseError } from 'firebase/app'

import {
  IActivityInput,
  IBasemapInput,
  IDeliveryMaterial,
  IGlobeViewInput,
  IMutation,
  IOperationRuleInput,
  IPermitType,
  IProject,
  ISitemapInput,
  ISitemapItemInput,
  ISitemapSpecificItemDataCircleInput,
  ISitemapSpecificItemDataPolylineInput,
  ISitemapSpecificItemDataRectangleInput,
  IUpdateDeliveriesFieldsInput,
} from '~/client/graph'
import { SaveActivityFiltersSettingsDocument } from '~/client/graph/operations/generated/ActivityFiltersSettings.generated'
import {
  DeleteActivityPresetDocument,
  SaveActivityPresetDocument,
} from '~/client/graph/operations/generated/ActivityPresets.generated'
import { SaveAnalyticsSettingDocument } from '~/client/graph/operations/generated/AnalyticsSettings.generated'
import { SaveBasemapDocument } from '~/client/graph/operations/generated/Basemaps.generated'
import {
  ChangeDeliveriesStatusDocument,
  UpdateDeliveriesFieldsDocument,
  UpdateDeliveryDocument,
} from '~/client/graph/operations/generated/Deliveries.generated'
import {
  DeleteGlobeViewDocument,
  DeleteGlobeViewSpecificItemDataDocument,
  SaveGlobeViewDocument,
  SaveGlobeViewSpecificItemDataDocument,
} from '~/client/graph/operations/generated/GlobeViews.generated'
import { SaveHierarchyConfigurationsDocument } from '~/client/graph/operations/generated/HierarchyConfigurations.generated'
import { FillProjectWithDefaultMaterialsDocument } from '~/client/graph/operations/generated/Materials.generated'
import {
  DeleteManyOperationRulesDocument,
  SaveManyOperationRulesDocument,
} from '~/client/graph/operations/generated/OperationRules.generated'
import {
  DeletePermitTypesDocument,
  SavePermitTypesDocument,
} from '~/client/graph/operations/generated/PermitTypes.generated'
import { SavePresentationSettingsDocument } from '~/client/graph/operations/generated/PresentationSettings.generated'
import { SaveProjectsDocument } from '~/client/graph/operations/generated/Project.generated'
import { SaveProjectAddressesDocument } from '~/client/graph/operations/generated/ProjectAddress.generated'
import { SaveProjectColoringOptionsDocument } from '~/client/graph/operations/generated/ProjectColoringOptions.generated'
import { SaveProjectMaterialOptionsDocument } from '~/client/graph/operations/generated/ProjectMaterialOptions.generated'
import { SaveProjectStatusUpdateOptionsDocument } from '~/client/graph/operations/generated/ProjectStatusUpdateOptions.generated'
import { SaveProjectTypeOptionsDocument } from '~/client/graph/operations/generated/ProjectTypeOptions.generated'
import { SaveProjectVisitorOptionsDocument } from '~/client/graph/operations/generated/ProjectVisitorOptions.generated'
import {
  SaveActivityCodeRelationshipsDocument,
  SaveActivityDocument,
  UpdateActivitiesFieldsDocument,
  UploadExcelScheduleDocument,
  UploadScheduleDocument,
} from '~/client/graph/operations/generated/Schedule.generated'
import {
  DeleteManySitemapItemsDocument,
  SaveSitemapItemDocument,
} from '~/client/graph/operations/generated/SitemapItems.generated'
import {
  DeleteSitemapDocument,
  DeleteSitemapSpecificItemDataDocument,
  SaveSitemapDocument,
  SaveSitemapSpecificItemDataDocument,
} from '~/client/graph/operations/generated/Sitemaps.generated'
import { DeleteTeamDocument } from '~/client/graph/operations/generated/Teams.generated'
import { DeleteUserRoleDocument } from '~/client/graph/operations/generated/UserRoles.generated'
import { UploadUsersFromCsvDocument } from '~/client/graph/operations/generated/Users.generated'
import { SaveZoneMapThresholdsDocument } from '~/client/graph/operations/generated/ZoneMapThresholds.generated'
import DesktopRootStore from '~/client/src/desktop/stores/DesktopRoot.store'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { DeliveryPageMode } from '~/client/src/shared/components/Deliveries/DeliveriesView.store'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import ActivityPreset from '~/client/src/shared/models/ActivityPreset'
import Delivery from '~/client/src/shared/models/Delivery'
import HierarchyConfig from '~/client/src/shared/models/HierarchyConfig'
import Project from '~/client/src/shared/models/Project'
import { handleEventCallback } from '~/client/src/shared/stores/EventStore/commonEvents'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import InitialState from '~/client/src/shared/stores/InitialState'
import { isEmptyObj } from '~/client/src/shared/utils/util'

import ISaveProjectSuccessOptions from '../../interfaces/ISaveProjectSuccessOptions'

export default function (
  eventsStore: DesktopEventStore,
  rootStore: DesktopRootStore,
) {
  eventsStore.on(
    e.SAVE_BASEMAP,
    (state, basemap: IBasemapInput, callbackFn?: (id: string) => void) => {
      state.loading.set(e.SAVE_BASEMAP, true)

      return {
        graphMutation: {
          mutation: SaveBasemapDocument,
          variables: {
            basemap,
          },
          onSuccess: [e.SAVE_BASEMAP_SUCCESS, callbackFn as any],
          onError: [e.REQUEST_ERROR, e.SAVE_BASEMAP],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_BASEMAP_SUCCESS,
    (state, callbackFn: (id: string) => void, { saveBasemap }: IMutation) => {
      state.loading.set(e.SAVE_BASEMAP, false)

      return {
        dispatch: [e.RUN_CALLBACK, callbackFn, saveBasemap?.id],
      }
    },
  )

  eventsStore.on(
    e.SAVE_SITEMAP,
    (state, sitemap: ISitemapInput, callbackFn?: (id: string) => void) => {
      state.loading.set(e.SAVE_SITEMAP, true)

      return {
        graphMutation: {
          mutation: SaveSitemapDocument,
          variables: {
            sitemap,
          },
          onSuccess: [e.SAVE_SITEMAP_SUCCESS, callbackFn as any],
          onError: [e.REQUEST_ERROR, e.SAVE_SITEMAP],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_GLOBE_VIEW,
    (state, id: string, callbackFn?: (id: string) => void) => {
      state.loading.set(e.DELETE_GLOBE_VIEW, true)

      return {
        graphMutation: {
          mutation: DeleteGlobeViewDocument,
          variables: {
            id,
          },
          onSuccess: [e.DELETE_GLOBE_VIEW_SUCCESS, callbackFn as any],
          onError: [e.REQUEST_ERROR, e.DELETE_GLOBE_VIEW],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_GLOBE_VIEW_SUCCESS,
    (
      state,
      callbackFn: (id: string) => void,
      { deleteGlobeView }: IMutation,
    ) => {
      state.loading.set(e.DELETE_GLOBE_VIEW, false)
      return {
        dispatch: [e.RUN_CALLBACK, callbackFn, deleteGlobeView],
      }
    },
  )

  eventsStore.on(
    e.SAVE_GLOBE_VIEW,
    (state, globeView: IGlobeViewInput, callbackFn?: (id: string) => void) => {
      state.loading.set(e.SAVE_GLOBE_VIEW, true)

      return {
        graphMutation: {
          mutation: SaveGlobeViewDocument,
          variables: {
            globeView,
          },
          onSuccess: [e.SAVE_GLOBE_VIEW_SUCCESS, callbackFn as any],
          onError: [e.REQUEST_ERROR, e.SAVE_GLOBE_VIEW],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_GLOBE_VIEW_SUCCESS,
    (state, callbackFn: (id: string) => void, { saveGlobeView }: IMutation) => {
      state.loading.set(e.SAVE_GLOBE_VIEW, false)
      return {
        dispatch: [e.RUN_CALLBACK, callbackFn, saveGlobeView?.id],
      }
    },
  )

  eventsStore.on(
    e.DELETE_GLOBE_VIEW_ITEM_DATA,
    (state, id: string, callbackFn?: (id: string) => void) => {
      state.loading.set(e.DELETE_GLOBE_VIEW_ITEM_DATA_SUCCESS, true)

      return {
        graphMutation: {
          mutation: DeleteGlobeViewSpecificItemDataDocument,
          variables: {
            id,
          },
          onSuccess: [e.DELETE_GLOBE_VIEW_ITEM_DATA_SUCCESS, callbackFn as any],
          onError: [e.REQUEST_ERROR, e.DELETE_GLOBE_VIEW_ITEM_DATA],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_GLOBE_VIEW_ITEM_DATA_SUCCESS,
    (
      state,
      callbackFn: (id: string) => void,
      { deleteGlobeViewSpecificItemData }: IMutation,
    ) => {
      state.loading.set(e.DELETE_GLOBE_VIEW_ITEM_DATA_SUCCESS, false)
      return {
        dispatch: [e.RUN_CALLBACK, callbackFn, deleteGlobeViewSpecificItemData],
      }
    },
  )

  eventsStore.on(
    e.SAVE_GLOBE_VIEW_ITEM_DATA,
    (
      state,
      polylines: ISitemapSpecificItemDataPolylineInput,
      rectangles: ISitemapSpecificItemDataRectangleInput,
      circles: ISitemapSpecificItemDataCircleInput,
      callbackFn?: (id: string) => void,
    ) => {
      state.loading.set(e.SAVE_GLOBE_VIEW_ITEM_DATA, true)

      return {
        graphMutation: {
          mutation: SaveGlobeViewSpecificItemDataDocument,
          variables: {
            polylines,
            rectangles,
            circles,
          },
          onSuccess: [e.SAVE_GLOBE_VIEW_ITEM_DATA_SUCCESS, callbackFn as any],
          onError: [e.REQUEST_ERROR, e.SAVE_GLOBE_VIEW_ITEM_DATA],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_GLOBE_VIEW_ITEM_DATA_SUCCESS,
    (
      state,
      callbackFn: (id: string) => void,
      { saveGlobeViewSpecificItemData }: IMutation,
    ) => {
      state.loading.set(e.SAVE_GLOBE_VIEW_ITEM_DATA, false)
      return {
        dispatch: [e.RUN_CALLBACK, callbackFn, saveGlobeViewSpecificItemData],
      }
    },
  )

  eventsStore.on(
    e.DELETE_SITEMAP_ITEM_DATA,
    (state, id: string, callbackFn?: (id: string) => void) => {
      state.loading.set(e.DELETE_SITEMAP_ITEM_DATA_SUCCESS, true)

      return {
        graphMutation: {
          mutation: DeleteSitemapSpecificItemDataDocument,
          variables: {
            id,
          },
          onSuccess: [e.DELETE_SITEMAP_ITEM_DATA_SUCCESS, callbackFn as any],
          onError: [e.REQUEST_ERROR, e.DELETE_SITEMAP_ITEM_DATA],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_SITEMAP_ITEM_DATA_SUCCESS,
    (
      state,
      callbackFn: (id: string) => void,
      { deleteSitemapSpecificItemData }: IMutation,
    ) => {
      state.loading.set(e.DELETE_SITEMAP_ITEM_DATA_SUCCESS, false)
      return {
        dispatch: [e.RUN_CALLBACK, callbackFn, deleteSitemapSpecificItemData],
      }
    },
  )

  eventsStore.on(
    e.SAVE_SITEMAP_ITEM_DATA,
    (
      state,
      polylines: ISitemapSpecificItemDataPolylineInput,
      rectangles: ISitemapSpecificItemDataRectangleInput,
      circles: ISitemapSpecificItemDataCircleInput,
      callbackFn?: (id: string) => void,
    ) => {
      state.loading.set(e.SAVE_SITEMAP_ITEM_DATA, true)

      return {
        graphMutation: {
          mutation: SaveSitemapSpecificItemDataDocument,
          variables: {
            polylines,
            rectangles,
            circles,
          },
          onSuccess: [e.SAVE_SITEMAP_ITEM_DATA_SUCCESS, callbackFn as any],
          onError: [e.REQUEST_ERROR, e.SAVE_SITEMAP_ITEM_DATA],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_SITEMAP_ITEM_DATA_SUCCESS,
    (
      state,
      callbackFn: (id: string) => void,
      { saveSitemapSpecificItemData }: IMutation,
    ) => {
      state.loading.set(e.SAVE_SITEMAP_ITEM_DATA_SUCCESS, false)
      return {
        dispatch: [e.RUN_CALLBACK, callbackFn, saveSitemapSpecificItemData],
      }
    },
  )

  eventsStore.on(
    e.SAVE_SITEMAP_SUCCESS,
    (state, callbackFn: (id: string) => void, { saveSitemap }: IMutation) => {
      state.loading.set(e.SAVE_SITEMAP, false)

      return {
        dispatch: [e.RUN_CALLBACK, callbackFn, saveSitemap?.id],
      }
    },
  )

  eventsStore.on(
    e.DELETE_SITEMAP,
    (state, id: string, callbackFn?: () => void) => {
      state.loading.set(e.DELETE_SITEMAP, true)

      return {
        graphMutation: {
          mutation: DeleteSitemapDocument,
          variables: { id },
          onSuccess: [e.DELETE_SITEMAP_SUCCESS, callbackFn as any],
          onError: [e.REQUEST_ERROR, e.DELETE_SITEMAP],
        },
      }
    },
  )

  eventsStore.on(e.DELETE_SITEMAP_SUCCESS, (state, callback?: () => void) => {
    state.loading.set(e.DELETE_SITEMAP, false)

    return {
      dispatch: [e.RUN_CALLBACK, callback],
    }
  })

  eventsStore.on(
    e.SAVE_SITEMAP_ITEM,
    (
      state,
      sitemapItem: ISitemapItemInput,
      callbackFn?: (id: string) => void,
    ) => {
      state.loading.set(e.SAVE_SITEMAP_ITEM, true)

      return {
        graphMutation: {
          mutation: SaveSitemapItemDocument,
          variables: {
            sitemapItem,
          },
          onSuccess: [e.SAVE_SITEMAP_ITEM_SUCCESS, callbackFn as any],
          onError: [e.REQUEST_ERROR, e.SAVE_SITEMAP_ITEM],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_SITEMAP_ITEM_SUCCESS,
    (
      state,
      callbackFn: (id: string) => void,
      { saveSitemapItem }: IMutation,
    ) => {
      state.loading.set(e.SAVE_SITEMAP_ITEM, false)

      return {
        dispatch: [e.RUN_CALLBACK, callbackFn, saveSitemapItem?.id],
      }
    },
  )

  eventsStore.on(
    e.DELETE_SITEMAP_ITEMS,
    (state, ids: string[], callbackFn?: () => void) => {
      return {
        graphMutation: {
          mutation: DeleteManySitemapItemsDocument,
          variables: { ids },
          onSuccess: [e.RUN_CALLBACK, callbackFn as any],
          onError: [e.REQUEST_ERROR, e.DELETE_SITEMAP_ITEMS],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_MATERIAL_OPTIONS,
    (state, options, onSuccessCb, onErrorCb) => {
      const projectMaterialOptions = options || state.projectMaterialOptions

      return {
        graphMutation: {
          mutation: SaveProjectMaterialOptionsDocument,
          variables: { projectMaterialOptions },
          onSuccess: [e.RUN_CALLBACK, onSuccessCb],
          onError: [e.RUN_CALLBACK, onErrorCb],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_PROJECT_ADDRESSES,
    (
      state,
      projectAddresses,
      onSuccessCb,
      onErrorCb,
      shouldCompleteRequest,
    ) => {
      state.loading.set(e.SAVE_PROJECT_ADDRESSES, true)
      return {
        graphMutation: {
          mutation: SaveProjectAddressesDocument,
          variables: { projectAddresses },
          onSuccess: handleEventCallback(
            e.SAVE_PROJECT_ADDRESSES,
            shouldCompleteRequest,
            onSuccessCb,
          ),
          onError: handleEventCallback(
            e.SAVE_PROJECT_ADDRESSES,
            shouldCompleteRequest,
            onErrorCb,
          ),
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_PROJECT_TYPE_OPTIONS,
    (
      state,
      projectTypeOptions,
      onSuccessCb,
      onErrorCb,
      shouldCompleteRequest,
    ) => {
      state.loading.set(e.SAVE_PROJECT_TYPE_OPTIONS, true)
      return {
        graphMutation: {
          mutation: SaveProjectTypeOptionsDocument,
          variables: { projectTypeOptions },
          onSuccess: handleEventCallback(
            e.SAVE_PROJECT_TYPE_OPTIONS,
            shouldCompleteRequest,
            onSuccessCb,
          ),
          onError: handleEventCallback(
            e.SAVE_PROJECT_TYPE_OPTIONS,
            shouldCompleteRequest,
            onErrorCb,
          ),
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_PROJECT_SU_OPTIONS,
    (state, projectStatusUpdateOptions, onSuccessCb, onErrorCb) => {
      return {
        graphMutation: {
          mutation: SaveProjectStatusUpdateOptionsDocument,
          variables: { projectStatusUpdateOptions },
          onSuccess: [e.RUN_CALLBACK, onSuccessCb],
          onError: [e.RUN_CALLBACK, onErrorCb],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_PROJECT_COLORING_OPTIONS,
    (state, projectColoringOptions, onSuccessCb, onErrorCb) => {
      return {
        graphMutation: {
          mutation: SaveProjectColoringOptionsDocument,
          variables: { projectColoringOptions },
          onSuccess: [e.RUN_CALLBACK, onSuccessCb],
          onError: [e.RUN_CALLBACK, onErrorCb],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_PROJECT,
    (state, projectToSave: IProject | Project, successOptions, onErrorCb) => {
      state.loading.set(e.SAVE_PROJECT, true)

      const project = Project.toInput(projectToSave)

      return {
        graphMutation: {
          mutation: SaveProjectsDocument,
          variables: { projects: [project] },
          onSuccess: [e.SAVE_PROJECT_SUCCESS, successOptions as any],
          onError: [e.RUN_CALLBACK, onErrorCb],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_PROJECT_SUCCESS,
    (
      state,
      successOptions: ISaveProjectSuccessOptions,
      { saveManyProjects }: IMutation,
    ) => {
      state.loading.set(e.SAVE_PROJECT, false)

      const { onSuccessCb, hasTimezoneChanged } = successOptions

      const [savedProject] = saveManyProjects

      if (hasTimezoneChanged) {
        window.location.reload()
      }

      const { activeProject } = state
      if (activeProject.areIdsEquals(savedProject)) {
        activeProject.updateFromJson(savedProject)
        rootStore.projectsStore.setOne(activeProject)
      }

      return { dispatch: [e.RUN_CALLBACK, onSuccessCb, savedProject.id] }
    },
  )

  eventsStore.on(
    e.UPLOAD_PROJECT_MEMBERS,
    (state, fileContent, onSuccess, onError) => {
      if (!fileContent) {
        return
      }

      state.loading.set(e.UPLOAD_PROJECT_MEMBERS, true)

      return {
        graphMutation: {
          mutation: UploadUsersFromCsvDocument,
          variables: {
            uploadUsersFromCsv: {
              file: fileContent,
              projectId: state.activeProject.id,
            },
          },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.UPLOAD_PROJECT_MEMBERS,
            onSuccess,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.UPLOAD_PROJECT_MEMBERS,
            onError,
          ],
        },
      }
    },
  )

  eventsStore.on(e.CHANGE_DELIVERY_STATUS_SUCCESS, state => {
    state.delivery.compactView.highlightedDelivery = null
    state.delivery.compactView.mode = DeliveryPageMode.VIEW_FROM_ACTIVITY
    state.delivery.compactView.resetViewMode()
  })

  eventsStore.on(
    e.EDIT_BULK_DELIVERIES,
    (state, fields: IUpdateDeliveriesFieldsInput, onSuccessCb, onErrorCb) => {
      state.loading.set(e.EDIT_BULK_DELIVERIES, true)

      const timestamp = Date.now()
      state.timestampToEditingDeliveriesIdsMap[timestamp] = fields.deliveryIds
      fields.timezoneId = rootStore.projectDateStore.getClientTimezoneId()

      return {
        graphMutation: {
          mutation: UpdateDeliveriesFieldsDocument,
          variables: { fieldsData: fields },
          onSuccess: [e.EDIT_BULK_DELIVERIES_RESULT, onSuccessCb, timestamp],
          onError: [e.EDIT_BULK_DELIVERIES_RESULT, onErrorCb, timestamp],
        },
      }
    },
  )

  eventsStore.on(
    e.EDIT_BULK_DELIVERIES_RESULT,
    (state, callback, timestamp) => {
      if (callback) {
        callback()
      }

      delete state.timestampToEditingDeliveriesIdsMap[timestamp]

      if (isEmptyObj(state.timestampToEditingDeliveriesIdsMap)) {
        state.loading.set(e.EDIT_BULK_DELIVERIES, false)
      }
    },
  )

  eventsStore.on(
    e.UPDATE_BULK_DELIVERIES_STATUS,
    (
      state,
      deliveryIds: string[],
      nextStatus: DeliveryStatus,
      onSuccessCallback,
      onErrorCallback,
    ) => {
      state.loading.set(e.UPDATE_BULK_DELIVERIES_STATUS, true)

      const timestamp = Date.now()
      state.timestampToUpdatingDeliveriesIdsMap[timestamp] = deliveryIds

      return {
        graphMutation: {
          mutation: ChangeDeliveriesStatusDocument,
          variables: {
            statusData: {
              projectId: state.activeProject.id,
              deliveryIds,
              nextStatus,
            },
          },
          onSuccess: [
            e.UPDATE_BULK_DELIVERIES_STATUS_RESULT,
            onSuccessCallback,
            timestamp,
          ],
          onError: [
            e.UPDATE_BULK_DELIVERIES_STATUS_RESULT,
            onErrorCallback,
            timestamp,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.UPDATE_BULK_DELIVERIES_STATUS_RESULT,
    (state, callback, timestamp) => {
      const deliveriesIds = state.timestampToUpdatingDeliveriesIdsMap[timestamp]

      if (callback) {
        callback(deliveriesIds.length)
      }

      delete state.timestampToUpdatingDeliveriesIdsMap[timestamp]

      if (isEmptyObj(state.timestampToUpdatingDeliveriesIdsMap)) {
        state.loading.set(e.UPDATE_BULK_DELIVERIES_STATUS, false)
      }
    },
  )

  eventsStore.on(e.SAVE_PASSWORD_SUCCESS, () => {
    rootStore.common.displayDefaultView()

    return {
      dispatchN: [[e.INIT_APP_3], [e.SEND_WELCOME_EMAIL_TO_CURRENT_USER]],
    }
  })

  eventsStore.on(
    e.CHANGE_PASSWORD,
    (state, oldPassword, newPassword, onSuccessCb, onErrorCb) => {
      state.loading.set(e.CHANGE_PASSWORD, true)

      const { auth } = rootStore
      return {
        cmd: {
          cmd: {
            execute: () => {
              return auth
                .login(state.user.email, oldPassword)
                .then(() => auth.updatePassword(newPassword))
            },
          },
          onSuccess: [e.CHANGE_PASSWORD_RESULT, onSuccessCb],
          onError: [e.CHANGE_PASSWORD_RESULT, onErrorCb],
        },
      }
    },
  )

  eventsStore.on(
    e.CHANGE_PASSWORD_RESULT,
    (
      state,
      callback: (error?: FirebaseError) => any,
      error?: FirebaseError,
    ) => {
      state.loading.set(e.CHANGE_PASSWORD, false)

      if (callback) {
        callback(error || null)
      }
    },
  )

  /**
   * Keep track of the active project and active schedule id
   */
  eventsStore.on(e.ACTIVATE_PROJECT_SUCCESS, (state, project: IProject) => {
    state.loading.set(e.ACTIVATE_PROJECT, false)
    state.activeProject = Project.fromDto(project)

    rootStore.clearOutProjectData()
    rootStore.clearAllFilters()

    rootStore.common.displaySameViewWithUpdatedProjectId()

    eventsStore.terminateGraphSubscriptions()

    return {
      flow: {
        startWith: [[e.RESET_INPUT_STATUSES], [e.GET_AUTH_USER_PROJECT]],
        rules: [
          {
            when: 'on',
            event: e.GET_AUTH_USER_PROJECT_SUCCESS,
            dispatch: [e.LOAD_AND_LISTEN_TO_PROJECT_TYPE_OPTIONS],
          },
          {
            when: 'on',
            event: e.PROJECT_TYPE_OPTIONS_RECEIVED,
            dispatch: [e.INIT_APP_3],
            shouldTerminate: true,
          },
        ],
      },
    }
  })

  eventsStore.on(
    e.UPLOAD_SCHEDULE,
    (state, fileName, xerProjectId, file, callback) => {
      const { activeProject, user } = state
      const timezoneId = rootStore.projectDateStore.getClientTimezoneId()

      return {
        graphMutation: {
          mutation: UploadScheduleDocument,
          variables: {
            projectId: activeProject.id,
            userId: user.id,
            xerProjectId,
            file,
            fileName,
            timezoneId,
          },
          onSuccess: [e.RUN_CALLBACK, callback],
          onError: [e.REQUEST_ERROR, e.UPLOAD_SCHEDULE],
        },
      }
    },
  )

  eventsStore.on(
    e.UPLOAD_EXCEL_SCHEDULE,
    (
      state,
      fileName,
      fullFilePath,
      sheetData,
      shouldUpdateCurrentSchedule,
      callback,
    ) => {
      const { activeProject, user } = state
      const timezoneId = rootStore.projectDateStore.getClientTimezoneId()

      return {
        graphMutation: {
          mutation: UploadExcelScheduleDocument,
          variables: {
            projectId: activeProject.id,
            userId: user.id,
            fileName,
            fullFilePath,
            sheetData,
            timezoneId,
            shouldUpdateCurrentSchedule,
          },
          onSuccess: [e.RUN_CALLBACK, callback],
          onError: [e.REQUEST_ERROR, e.UPLOAD_EXCEL_SCHEDULE],
        },
      }
    },
  )

  eventsStore.on(e.SCHEDULE_LOADED, () => {
    rootStore.clearOutScheduleData()
    return {
      dispatch: [e.GET_SCHEDULE],
    }
  })

  eventsStore.on(e.RESET_ALL_FILTERS, () => {
    rootStore.initAllFilters()
  })

  eventsStore.on(e.SET_FILTER_SELECTION, () => {
    // only postEventCallback is needed
  })

  eventsStore.on(e.RESET_FILTER, () => {
    // only postEventCallback is needed
  })

  eventsStore.on(e.SAVE_SITEMAP_IMAGE, () => {
    // only postEventCallback is needed
  })

  eventsStore.on(e.SAVE_GLOBE_IMAGE, () => {
    // only postEventCallback is needed
  })

  eventsStore.on(e.SAVE_ACTIVITY, (state, activity: IActivityInput) => {
    return {
      graphMutation: {
        mutation: SaveActivityDocument,
        variables: { activity },
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR, e.SAVE_ACTIVITY],
      },
    }
  })

  eventsStore.on(
    e.SAVE_ACTIVITY_CODE_RELATIONSHIPS,
    (state, activityCodeRelationships) => {
      state.loading.set(e.SAVE_ACTIVITY_CODE_RELATIONSHIPS, true)
      return {
        graphMutation: {
          mutation: SaveActivityCodeRelationshipsDocument,
          variables: { activityCodeRelationships },
          onSuccess: [e.COMPLETE_REQUEST, e.SAVE_ACTIVITY_CODE_RELATIONSHIPS],
          onError: [e.REQUEST_ERROR, e.SAVE_ACTIVITY_CODE_RELATIONSHIPS],
        },
      }
    },
  )

  eventsStore.on(
    e.UPDATE_ACTIVITY_COMPANY_STATUS_WITH_NOTIFICATION,
    (_, ...args) => {
      const dispatch: any = [e.UPDATE_ACTIVITY_COMPANIES_STATUSES, ...args]
      return { dispatch }
    },
  )

  eventsStore.on(
    e.UPDATE_DELIVERY_MATERIALS,
    (
      state,
      delivery: Delivery,
      materials: IDeliveryMaterial[],
      onSuccessCallback,
      onErrorCallback,
    ) => {
      const { id: projectId } = state.activeProject

      return {
        graphMutation: {
          mutation: UpdateDeliveryDocument,
          variables: {
            delivery: {
              ...delivery.getDto(projectId),
              materials,
            },
            timezoneId: rootStore.projectDateStore.getClientTimezoneId(),
          },
          onSuccess: [e.RUN_CALLBACK, onSuccessCallback],
          onError: [e.RUN_CALLBACK, onErrorCallback],
        },
      }
    },
  )

  eventsStore.on(e.UPDATE_ACTIVITY_FILTERS_SETTINGS, state => {
    state.loading.set(e.UPDATE_ACTIVITY_FILTERS_SETTINGS, true)

    const { activityFiltersSettings, activeProject, activeScheduleId } = state

    return {
      graphMutation: {
        mutation: SaveActivityFiltersSettingsDocument,
        variables: {
          activityFiltersSettings: {
            ...activityFiltersSettings.toDto(),
            projectId: activeProject.id,
            scheduleId: activeScheduleId,
          },
        },
        onSuccess: [e.UPDATE_ACTIVITY_FILTERS_SETTINGS_SUCCESS],
        onError: [e.REQUEST_ERROR, e.UPDATE_ACTIVITY_FILTERS_SETTINGS],
      },
    }
  })

  eventsStore.on(
    e.UPDATE_ACTIVITY_FILTERS_SETTINGS_SUCCESS,
    (state, { saveActivityFiltersSettings }: IMutation) => {
      state.loading.set(e.UPDATE_ACTIVITY_FILTERS_SETTINGS, false)

      state.activityFiltersSettings.id = saveActivityFiltersSettings.id
      rootStore.activitiesStore.setActivityData()
    },
  )

  eventsStore.on(
    e.UPDATE_HIERARCHY_CONFIGURATION,
    (state, config: HierarchyConfig) => {
      config.projectId = config.projectId || state.activeProject.id
      return {
        graphMutation: {
          mutation: SaveHierarchyConfigurationsDocument,
          variables: {
            hierarchyConfigurations: config.toDto(),
          },
          onSuccess: [e.UPDATE_HIERARCHY_CONFIGURATION_SUCCESS],
          onError: [e.REQUEST_ERROR, e.UPDATE_HIERARCHY_CONFIGURATION],
        },
      }
    },
  )

  eventsStore.on(
    e.UPDATE_HIERARCHY_CONFIGURATION_SUCCESS,
    (state, { saveHierarchyConfigurations }: IMutation) => {
      const { hierarchyConfigurationStore } = rootStore

      if (hierarchyConfigurationStore.isDefaultConfigurationUsed) {
        hierarchyConfigurationStore.receiveConfiguration(
          saveHierarchyConfigurations,
        )
      }
    },
  )

  eventsStore.on(
    e.SAVE_PRESENTATION_SETTINGS,
    (state, settings, onSuccessCb, onErrorCb) => {
      const presentationSettings = Object.assign(
        state.presentationSettings,
        settings,
        {
          updatedBy: state.user.id,
          projectId: state.activeProject.id,
        },
      )

      return {
        graphMutation: {
          mutation: SavePresentationSettingsDocument,
          variables: { presentationSettings },
          onSuccess: [e.RUN_CALLBACK, onSuccessCb],
          onError: [e.RUN_CALLBACK, onErrorCb],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_PROJECT_VISITOR_OPTIONS,
    (
      state,
      projectVisitorOptions,
      onSuccessCb,
      onErrorCb,
      shouldCompleteRequest,
    ) => {
      state.loading.set(e.SAVE_PROJECT_VISITOR_OPTIONS, true)
      return {
        graphMutation: {
          mutation: SaveProjectVisitorOptionsDocument,
          variables: { projectVisitorOptions },
          onSuccess: handleEventCallback(
            e.SAVE_PROJECT_VISITOR_OPTIONS,
            shouldCompleteRequest,
            onSuccessCb,
          ),
          onError: handleEventCallback(
            e.SAVE_PROJECT_VISITOR_OPTIONS,
            shouldCompleteRequest,
            onErrorCb,
          ),
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_ZONEMAP_THRESHOLDS,
    (state, thresholds, onSuccessCb, onErrorCb) => {
      const zoneMapThresholds = thresholds || state.zoneMapThresholds

      return {
        graphMutation: {
          mutation: SaveZoneMapThresholdsDocument,
          variables: { zoneMapThresholds },
          onSuccess: [e.RUN_CALLBACK, onSuccessCb],
          onError: [e.RUN_CALLBACK, onErrorCb],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_ACTIVITY_PRESET,
    (state, id: string, name: string, activities: string[]) => {
      state.loading.set(e.SAVE_ACTIVITY_PRESET, true)

      const { user, activeProject } = state
      return {
        graphMutation: {
          mutation: SaveActivityPresetDocument,
          variables: {
            activityPreset: new ActivityPreset(
              id,
              name,
              activities,
              user.id,
              activeProject.id,
            ),
          },
          onSuccess: [e.COMPLETE_REQUEST, e.SAVE_ACTIVITY_PRESET],
          onError: [e.REQUEST_ERROR, e.SAVE_ACTIVITY_PRESET],
        },
      }
    },
  )

  eventsStore.on(e.DELETE_ACTIVITY_PRESET, (state, presetId: string) => {
    return {
      graphMutation: {
        mutation: DeleteActivityPresetDocument,
        variables: { presetId },
        onSuccess: [e.NO_EFFECT],
        onError: [e.REQUEST_ERROR],
      },
    }
  })

  eventsStore.on(e.HANDLE_SAME_PROJECT_SELECTION, () => {
    // only mobile event handler
    return
  })

  eventsStore.on(e.CALCULATE_DELIVERY_TREE, () => {
    // only mobile event handler
    return
  })

  eventsStore.on(
    e.DELETE_PROJECT_TEAM,
    (state, teamId: string, callback?: (id: string) => void) => {
      state.loading.set(e.DELETE_PROJECT_TEAM, true)

      return {
        graphMutation: {
          mutation: DeleteTeamDocument,
          variables: { teamId },
          onSuccess: [e.DELETE_PROJECT_TEAM_SUCCESS, teamId as any, callback],
          onError: [e.REQUEST_ERROR, e.DELETE_PROJECT_TEAM],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_PROJECT_TEAM_SUCCESS,
    (state, teamId: string, callback?: (id: string) => void) => {
      state.loading.set(e.DELETE_PROJECT_TEAM, false)

      return {
        dispatch: [e.RUN_CALLBACK, callback, teamId],
      }
    },
  )

  eventsStore.on(
    e.DELETE_PROJECT_ROLE,
    (state, userRoleId: string, callback?: (id: string) => void) => {
      state.loading.set(e.DELETE_PROJECT_ROLE, true)

      return {
        graphMutation: {
          mutation: DeleteUserRoleDocument,
          variables: { userRoleId },
          onSuccess: [
            e.DELETE_PROJECT_ROLE_SUCCESS,
            userRoleId as any,
            callback,
          ],
          onError: [e.REQUEST_ERROR, e.DELETE_PROJECT_ROLE],
        },
      }
    },
  )

  eventsStore.on(
    e.DELETE_PROJECT_ROLE_SUCCESS,
    (state, roleId: string, callback?: (id: string) => void) => {
      state.loading.set(e.DELETE_PROJECT_ROLE, false)

      return {
        dispatch: [e.RUN_CALLBACK, callback, roleId],
      }
    },
  )

  eventsStore.on(
    e.SAVE_PERMIT_TYPES,
    (state: InitialState, permitTypes: IPermitType[], callbackFn?) => {
      state.loading.set(e.SAVE_PERMIT_TYPES, true)

      return {
        graphMutation: {
          mutation: SavePermitTypesDocument,
          variables: { permitTypes, projectId: state.activeProject.id },
          onSuccess: [e.SAVE_PERMIT_TYPES_SUCCESS, callbackFn],
          onError: [e.REQUEST_ERROR, e.SAVE_PERMIT_TYPES],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_PERMIT_TYPES_SUCCESS,
    (state: InitialState, callbackFn, { saveManyPermitTypes }: IMutation) => {
      state.loading.set(e.SAVE_PERMIT_TYPES, false)

      saveManyPermitTypes?.forEach(type =>
        rootStore.permitTypesStore.receiveProjectSpecificType(type.id, type),
      )

      if (callbackFn) {
        callbackFn(saveManyPermitTypes)
      }
    },
  )

  eventsStore.on(
    e.REMOVE_PERMIT_TYPES,
    (state: InitialState, ids: string[]) => {
      state.loading.set(e.REMOVE_PERMIT_TYPES, true)

      if (!ids?.length) {
        return
      }

      return {
        graphMutation: {
          mutation: DeletePermitTypesDocument,
          variables: { ids },
          onSuccess: [e.REMOVE_PERMIT_TYPES_SUCCESS, ids],
          onError: [e.REQUEST_ERROR, e.REMOVE_PERMIT_TYPES],
        },
      }
    },
  )

  eventsStore.on(
    e.REMOVE_PERMIT_TYPES_SUCCESS,
    (
      state: InitialState,
      ids: string[],
      { softDeleteManyPermitTypes }: IMutation,
    ) => {
      state.loading.set(e.REMOVE_PERMIT_TYPES, false)

      if (softDeleteManyPermitTypes) {
        rootStore.permitTypesStore.markTypesAsDeleted(ids)
      }
    },
  )

  eventsStore.on(
    e.SAVE_MANY_OPERATION_RULES,
    (
      state: InitialState,
      operationRules: IOperationRuleInput[],
      callbackFn?,
    ) => {
      state.loading.set(e.SAVE_MANY_OPERATION_RULES, true)

      return {
        graphMutation: {
          mutation: SaveManyOperationRulesDocument,
          variables: { operationRules },
          onSuccess: [e.SAVE_MANY_OPERATION_RULES_SUCCESS, callbackFn],
          onError: [e.REQUEST_ERROR, e.SAVE_MANY_OPERATION_RULES],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_MANY_OPERATION_RULES_SUCCESS,
    (
      state: InitialState,
      callbackFn,
      { saveManyOperationRules }: IMutation,
    ) => {
      state.loading.set(e.SAVE_MANY_OPERATION_RULES, false)

      saveManyOperationRules?.forEach(dto =>
        rootStore.operationRulesStore.receiveOne(dto.id, dto),
      )

      if (callbackFn) {
        callbackFn(saveManyOperationRules)
      }
    },
  )

  eventsStore.on(e.DELETE_MANY_OPERATION_RULES, (state, ids: string[]) => {
    state.loading.set(e.DELETE_MANY_OPERATION_RULES, true)

    return {
      graphMutation: {
        mutation: DeleteManyOperationRulesDocument,
        variables: { ids },
        onSuccess: [e.DELETE_MANY_OPERATION_RULES_SUCCESS, ids],
        onError: [e.REQUEST_ERROR, e.DELETE_MANY_OPERATION_RULES],
      },
    }
  })

  eventsStore.on(
    e.DELETE_MANY_OPERATION_RULES_SUCCESS,
    (state, ids: string[], { deleteManyOperationRules }: IMutation) => {
      if (deleteManyOperationRules) {
        state.loading.set(e.DELETE_MANY_OPERATION_RULES, false)

        ids.forEach(id => rootStore.operationRulesStore.receiveOne(id, null))
      }
    },
  )

  eventsStore.on(
    e.EDIT_BULK_ACTIVITIES,
    (state, data, onSuccessCb, onErrorCb) => {
      state.loading.set(e.EDIT_BULK_ACTIVITIES, true)

      return {
        graphMutation: {
          mutation: UpdateActivitiesFieldsDocument,
          variables: data,
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.EDIT_BULK_ACTIVITIES,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.EDIT_BULK_ACTIVITIES,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.FILL_PROJECT_WITH_DEFAULT_MATERIALS,
    (
      state: InitialState,
      projectId: string,
      onSuccessCb: () => void,
      onErrorCb: () => void,
    ) => {
      state.loading.set(e.FILL_PROJECT_WITH_DEFAULT_MATERIALS, true)

      return {
        graphMutation: {
          mutation: FillProjectWithDefaultMaterialsDocument,
          variables: { projectId },
          onSuccess: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.FILL_PROJECT_WITH_DEFAULT_MATERIALS,
            onSuccessCb,
          ],
          onError: [
            e.COMPLETE_REQUEST_AND_RUN_CALLBACK,
            e.FILL_PROJECT_WITH_DEFAULT_MATERIALS,
            onErrorCb,
          ],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_ANALYTICS_SETTING,
    (state: InitialState, analyticsSettings, callback) => {
      state.loading.set(e.SAVE_ANALYTICS_SETTING, true)
      return {
        graphMutation: {
          mutation: SaveAnalyticsSettingDocument,
          variables: {
            analyticsSettings,
          },
          onSuccess: [e.SAVE_ANALYTICS_SETTING_SUCCESS, callback],
          onError: [e.REQUEST_ERROR],
        },
      }
    },
  )

  eventsStore.on(
    e.SAVE_ANALYTICS_SETTING_SUCCESS,
    (state, callback, { saveAnalyticsSettings }: IMutation) => {
      state.loading.set(e.SAVE_ANALYTICS_SETTING, false)

      return {
        dispatch: [e.RUN_CALLBACK, callback, saveAnalyticsSettings],
      }
    },
  )
}
