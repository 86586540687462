import { action, observable } from 'mobx'

import CalendarViewStore from '~/client/src/shared/stores/ui/CalendarView.store'

import CalendarEvent from '../../../models/CalendarEvent'

export default class CalendarPlaceholderStore extends CalendarViewStore {
  @observable public event: CalendarEvent
  @observable private eventDataSnapshot: any

  public get eventPlaceholder(): CalendarEvent {
    return this.event
  }

  @action.bound
  public setEventPlaceholder(event: CalendarEvent) {
    this.event = event
  }

  @action.bound
  public resetEventPlaceholder() {
    this.event = null
    this.eventDataSnapshot = null
  }

  @action.bound
  public setEventPlaceholderTime(startTime: Date, endTime: Date) {
    this.event.startDate = startTime
    this.event.endDate = endTime
  }

  @action.bound
  public setEventPlaceholderZone(zoneId: string) {
    this.event.zoneId = zoneId
  }

  @action.bound
  public setDraftEventData(event: any) {
    this.eventDataSnapshot = event
  }

  public get draftEventData(): any {
    return this.eventDataSnapshot
  }
}
