import * as React from 'react'

import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import MentionTriggerButton from '~/client/src/shared/components/ActionBarInputWIthMentions/components/MentionTriggerButton'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'
import DeliveryAssignmentsStore from '~/client/src/shared/stores/domain/DeliveryAssignments.store'
import DeliveryFollowingsStore from '~/client/src/shared/stores/domain/DeliveryFollowings.store'
import DeliveryActionBarStore from '~/client/src/shared/stores/ui/DeliveryActionBar.store'

import ActionBarInputWithMentions from '../ActionBarInputWIthMentions/ActionBarInputWithMentions'
import CameraUploadButton from '../CameraUploadButton/CameraUploadButton'

// translated

interface IProps {
  delivery: Delivery
  deliveryActionBarStore: DeliveryActionBarStore

  deliveryFollowingsStore?: DeliveryFollowingsStore
  deliveryAssignmentsStore?: DeliveryAssignmentsStore
}

@inject('deliveryFollowingsStore', 'deliveryAssignmentsStore')
@observer
export default class DeliveryMessagesActionBar extends React.Component<IProps> {
  public constructor(props: IProps) {
    super(props)

    this.store.entityId = props.delivery.id
  }

  private get store(): DeliveryActionBarStore {
    return this.props.deliveryActionBarStore
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { delivery: currentDelivery } = this.props

    if (currentDelivery !== prevProps.delivery) {
      this.store.entityId = currentDelivery.id
      this.store.fullReset()
    }
  }

  public componentWillUnmount() {
    this.store.fullReset()
  }

  public render() {
    const { delivery, deliveryAssignmentsStore, deliveryFollowingsStore } =
      this.props

    const {
      draftMessage,
      decodedDraftMessage,
      mentionTags,
      setDraftMessage,
      isMentionActionActive,
      activateMentionAction,
      deactivateMentionAction,
      triggerMentionActionActivation,
    } = this.store

    return (
      <div className="pa10 bt-light-grey slack-bar">
        <form onSubmit={this.send}>
          <div className="col">
            <ActionBarInputWithMentions
              onChange={setDraftMessage}
              entityId={delivery.id}
              value={draftMessage}
              plainText={decodedDraftMessage}
              mentions={mentionTags}
              followingsStore={deliveryFollowingsStore}
              assignmentsStore={deliveryAssignmentsStore}
              placeholder={Localization.translator.addANoteToThisDelivery}
              onSuggestionsPopupHide={deactivateMentionAction}
              onSuggestionsPopupShow={activateMentionAction}
            />

            <div className="row">
              <MentionTriggerButton
                className="no-grow"
                isActive={isMentionActionActive}
                onClick={triggerMentionActionActivation}
              />
              <div className="row x-end no-flex-children">
                {delivery && (
                  <CameraUploadButton
                    showWhenSelected={true}
                    store={this.store}
                  />
                )}
                <button
                  className={classList({
                    'slack-bar-send-btn': true,
                    disabled: this.shouldDisableButton,
                  })}
                  type="submit"
                  disabled={this.shouldDisableButton}
                >
                  {Localization.translator.send}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }

  private get shouldDisableButton(): boolean {
    const { isImageUploading, isEmptyMessage } = this.store
    return isEmptyMessage || isImageUploading
  }

  @action.bound
  private async send(evt: React.FormEvent<HTMLFormElement>) {
    evt.preventDefault()
    evt.stopPropagation()

    await this.store.replyToThread(this.props.delivery)
  }
}
