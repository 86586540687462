import * as React from 'react'

import { inject } from 'mobx-react'

import { TagType } from '~/client/src/shared/enums/TagType'
import { ITag } from '~/client/src/shared/models/Tag'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'

import UsersDirectory from '../../../../../../UsersDirectory/UsersDirectory'
import SitePermitCreationFormStore from '../../../../../SitePermitCreationForm.store'

// localization: no text to translate

interface IProps {
  store: SitePermitCreationFormStore

  userProjectsStore?: UserProjectsStore
}

const searchTagTypes = [TagType.User, TagType.Company]

@inject('userProjectsStore')
export default class FormUserPicker extends React.Component<IProps> {
  public render() {
    return (
      <UsersDirectory
        shouldSelectOnlyUsers
        shouldHideFilterBar
        shouldUseAllProjectMembers
        onUserRowClick={this.onUserClick}
        searchTypes={searchTagTypes}
        additionalBandsPredicate={this.companyBandPredicate}
        userPredicate={this.userPredicate}
      />
    )
  }

  private userPredicate = (userDto: User): boolean => {
    const { store, userProjectsStore } = this.props

    const {
      isRequesterPickerDisplayed,
      isAssignedToPickerDisplayed,
      isSubscriberPickerDisplayed,
      isInfoUserPickerDisplayed,
      editablePermit,
      selectedFieldValues,
    } = store

    if (isRequesterPickerDisplayed) {
      return !editablePermit?.requesterIds.includes(userDto.id)
    }

    if (isAssignedToPickerDisplayed) {
      return (
        !UserProject.isVisitor(userDto, userProjectsStore) &&
        !editablePermit?.assigneeIds.includes(userDto.id)
      )
    }

    if (isSubscriberPickerDisplayed) {
      return !editablePermit?.subscriberIds.includes(userDto.id)
    }

    if (isInfoUserPickerDisplayed) {
      return !selectedFieldValues.includes(userDto.id)
    }

    return true
  }

  private companyBandPredicate = (a: ITag, b: ITag): number => {
    if (a.type !== TagType.Company || b.type !== TagType.Company) {
      return 0
    }

    const { selectedPermitCompanyIds } = this.props.store
    if (!selectedPermitCompanyIds?.length) {
      return 0
    }

    if (selectedPermitCompanyIds.includes(a.id)) {
      return -1
    }

    if (selectedPermitCompanyIds.includes(b.id)) {
      return 1
    }
  }

  private onUserClick = ({ id }: User) => {
    this.props.store.changeSelectedFieldValue(id)
  }
}
