import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { NotificationType, StatusUpdateType } from '~/client/graph'
import FlagIcon from '~/client/src/shared/components/FlagIcon'
import * as Icons from '~/client/src/shared/components/Icons'
import { HGap } from '~/client/src/shared/components/Layout'
import RfiIcon from '~/client/src/shared/components/RfiIcon/RfiIcon'
import ScheduleCommentIcon from '~/client/src/shared/components/ScheduleCommentIcon'
import Text from '~/client/src/shared/components/Typography/Text'
import Activity from '~/client/src/shared/models/Activity'
import Delivery from '~/client/src/shared/models/Delivery'
import Flag from '~/client/src/shared/models/Flag'
import Rfi from '~/client/src/shared/models/Rfi'
import ScheduleComment from '~/client/src/shared/models/ScheduleComment'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import User from '~/client/src/shared/models/User'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import DeliveriesStore from '~/client/src/shared/stores/domain/Deliveries.store'
import FlagsStore from '~/client/src/shared/stores/domain/Flags.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import RfisStore from '~/client/src/shared/stores/domain/Rfis.store'
import ScheduleCommentsStore from '~/client/src/shared/stores/domain/ScheduleComments.store'
import StatusUpdatesStore from '~/client/src/shared/stores/domain/StatusUpdates.store'
import {
  isActivityChangedType,
  isAnnouncementType,
  isDeliveryType,
  isFlagType,
  isFollowingType,
  isPermitType,
  isRFIType,
  isReplyToThreadType,
  isScannerDeactivatedType,
  isScheduleCommentType,
  isStatusUpdateType,
} from '~/client/src/shared/types/NotificationTypes'

import Localization from '../../shared/localization/LocalizationManager'
import BaseNotification from '../models/Notification'
import SitePermit from '../models/Permit'
import AnnouncementsStore from '../stores/domain/Announcements.store'
import CompaniesStore from '../stores/domain/Companies.store'
import ScannersStore from '../stores/domain/Scanners.store'
import SitePermitsStore from '../stores/domain/SitePermits.store'
import ProjectDateStore from '../stores/ui/ProjectDate.store'
import { EMPTY_STRING } from '../utils/usefulStrings'
import DeliveryStatusIcon from './DeliveryIconByStatus'
import PermitTypeIcon from './PermitTypeIcon/PermitTypeIcon'
import WorkflowCardLocationLabel from './WorkflowCard/LocationLabel'

// translated

interface IProps {
  notification: BaseNotification
  hideActivityName?: boolean

  flagsStore?: FlagsStore
  rfisStore?: RfisStore
  scheduleCommentsStore?: ScheduleCommentsStore
  activitiesStore?: ActivitiesStore
  statusUpdatesStore?: StatusUpdatesStore
  deliveriesStore?: DeliveriesStore
  projectDateStore?: ProjectDateStore
  sitePermitsStore?: SitePermitsStore
  announcementsStore?: AnnouncementsStore
  permitTypesStore?: PermitTypesStore
  companiesStore?: CompaniesStore
  projectMembersStore?: ProjectMembersStore
  scannersStore?: ScannersStore
}

interface ISubtitles {
  text: string | JSX.Element
  isBold?: boolean
}

@inject(
  'flagsStore',
  'rfisStore',
  'scheduleCommentsStore',
  'activitiesStore',
  'statusUpdatesStore',
  'deliveriesStore',
  'projectDateStore',
  'sitePermitsStore',
  'permitTypesStore',
  'companiesStore',
  'projectMembersStore',
  'announcementsStore',
  'scannersStore',
)
@observer
export default class NotificationLabel extends React.Component<IProps> {
  public render() {
    const {
      notification,
      hideActivityName,
      flagsStore,
      rfisStore,
      scheduleCommentsStore,
      activitiesStore,
      statusUpdatesStore,
      deliveriesStore,
      sitePermitsStore,
      announcementsStore,
      projectDateStore: {
        getTimeIntervalToDisplay,
        getMonthDayAndTimeToDisplay,
      },
      permitTypesStore,
      scannersStore,
    } = this.props
    const { type, entityId } = notification

    let icon: JSX.Element
    let title = ''
    const subtitles: ISubtitles[] = []

    switch (true) {
      case isFlagType(type):
        const flag = flagsStore.byId.get(entityId) || Flag.none
        icon = <FlagIcon flag={flag} />
        title = isReplyToThreadType(type)
          ? Localization.translator.replyToActivityFlag
          : Localization.translator.activityFlag
        break

      case isRFIType(type):
        const rfi = rfisStore.byId.get(entityId) || Rfi.none
        icon = <RfiIcon rfi={rfi} />
        title = isReplyToThreadType(type)
          ? Localization.translator.replyToActivityRfi
          : Localization.translator.activityRfi
        break

      case isScheduleCommentType(type): {
        const sc =
          scheduleCommentsStore.byId.get(entityId) || ScheduleComment.none
        icon = <ScheduleCommentIcon scheduleComment={sc} />
        title = isReplyToThreadType(type)
          ? Localization.translator.replyToScheduleComment
          : Localization.translator.scheduleComment
        break
      }

      case isActivityChangedType(type): {
        const sc =
          scheduleCommentsStore.byId.get(entityId) || ScheduleComment.none
        icon = <ScheduleCommentIcon scheduleComment={sc} />
        title = Localization.translator.activityUpdated
        break
      }

      case isPermitType(type):
        const permit = sitePermitsStore.getFormById(entityId)
        if (!permit) {
          break
        }

        title = this.getPermitTitle(type)
        icon = isFollowingType(type) ? (
          <Icons.UnfilledStar />
        ) : (
          <PermitTypeIcon
            permitType={permit.getTypeOfPermitType(permitTypesStore)}
          />
        )
        this.addPermitSubtitles(permit, subtitles)
        break
      case isAnnouncementType(type):
        icon = <Icons.Megaphone />
        title = this.getAnnouncementTitle(type)
        const announcement = announcementsStore.byId.get(entityId)
        subtitles.push({
          text: announcement?.title,
          isBold: true,
        })
        subtitles.push({
          text: getTimeIntervalToDisplay(
            announcement?.startDate,
            announcement?.endDate,
            getMonthDayAndTimeToDisplay,
          ),
          isBold: false,
        })

        if (announcement?.location) {
          subtitles.push({
            text: (
              <WorkflowCardLocationLabel
                locations={[announcement.location]}
                isOneColor={false}
                shouldShowAsTag={true}
                shouldAddPadding
              />
            ),
            isBold: false,
          })
        }
        break
      case isFollowingType(type):
        icon = <Icons.UnfilledStar />
        switch (type) {
          case NotificationType.ActivityFollowed:
            title = Localization.translator.activityFollowed
            break
          case NotificationType.DeliveryFollowed:
            title = Localization.translator.deliveryFollowed
            break
          case NotificationType.PermitFollowed:
            title = Localization.translator.formFollowed
            break
        }
        break
      case isStatusUpdateType(type):
        const statusUpdate =
          statusUpdatesStore.byId.get(entityId) || StatusUpdate.none
        const activity =
          activitiesStore.byId.get(statusUpdate.activityP6Code) || Activity.none
        icon = <Icons.StatusUpdate />

        if (isReplyToThreadType(type)) {
          switch (statusUpdate.type) {
            case StatusUpdateType.Active:
              title = Localization.translator.replyToActivityUpdate
              break
            case StatusUpdateType.P6:
              title = Localization.translator.replyToScheduleUpdate
              break
            case StatusUpdateType.Bulk:
              title = Localization.translator.replyToBulkUpdate
              break

            default:
              title = Localization.translator.replyToActivityUpdate
          }
        } else {
          title = statusUpdate.typeCaption
        }

        if (!hideActivityName) {
          title += `: ${activity.code} ${activity.name}`
        }
        break

      case isDeliveryType(type):
        const delivery: Delivery = deliveriesStore.byId.get(entityId)
        if (!delivery) {
          return null
        }
        const {
          status,
          startDate,
          endDate,
          actualStartDate,
          actualEndDate,
          isFromConcreteDirect,
        } = delivery

        icon = isFromConcreteDirect ? (
          <Icons.ConcreteDirect className="row no-grow" />
        ) : (
          <DeliveryStatusIcon status={status} />
        )
        title = this.getDeliveryTitle(notification)

        subtitles.push({
          text: Localization.translator.planned,
          isBold: true,
        })
        subtitles.push({
          text: getTimeIntervalToDisplay(startDate, endDate),
          isBold: false,
        })
        subtitles.push({
          text: Localization.translator.actual,
          isBold: true,
        })
        subtitles.push({
          text: getTimeIntervalToDisplay(actualStartDate, actualEndDate),
          isBold: false,
        })
        break

      case type === NotificationType.ScheduleUpdated:
        icon = <Icons.CalendarGrey />
        title = Localization.translator.scheduleUpdated
        break

      case isScannerDeactivatedType(type):
        icon = <Icons.Search />
        title = Localization.translator.scannerDeactivated
        const scanner = scannersStore.getScannerById(entityId)
        if (!scanner) {
          break
        }

        subtitles.push({
          text: scanner.name,
          isBold: true,
        })
        subtitles.push({
          text: scanner.badgeName,
          isBold: false,
        })
        break

      default:
        return null
    }

    return (
      <>
        {this.renderLabel(title, icon, true)}
        {subtitles.map((subtitle, index) => (
          <React.Fragment key={index}>
            {this.renderLabel(subtitle.text, null, subtitle.isBold)}
          </React.Fragment>
        ))}
      </>
    )
  }

  private getAnnouncementTitle(type: NotificationType): string {
    switch (type) {
      case NotificationType.AnnouncementCreated:
        return Localization.translator.announcementHasBeenCreated
      case NotificationType.AnnouncementStarted:
        return Localization.translator.announcementDateArrived
      case NotificationType.AnnouncementFollowed:
        return Localization.translator.announcementFollowed
      default:
        throw new Error(type + ' is unhandled')
    }
  }

  private getPermitTitle(permitType: NotificationType): string {
    switch (permitType) {
      case NotificationType.PermitAccepted:
        return Localization.translator.formAccepted
      case NotificationType.PermitClosed:
        return Localization.translator.formClosed
      case NotificationType.PermitFinished:
        return Localization.translator.formFinished
      case NotificationType.PermitSubmitted:
        return Localization.translator.formSubmitted
      case NotificationType.PermitChanged:
        return Localization.translator.formChanged
      case NotificationType.PermitDenied:
        return Localization.translator.formDenied
      case NotificationType.PermitToInspect:
        return Localization.translator.formReadyToInspect
      case NotificationType.PermitActivated:
        return Localization.translator.formActivated
      case NotificationType.PermitReviewed:
        return Localization.translator.formReviewed
      case NotificationType.PermitFailed:
        return Localization.translator.formFailed
      case NotificationType.PermitPassed:
        return Localization.translator.formPassed
      case NotificationType.PermitBicInspected:
        return Localization.translator.formBicInspected
      case NotificationType.PermitDeleted:
        return Localization.translator.formDeleted
      case NotificationType.PermitFollowed:
        return Localization.translator.formFollowed
      default:
        throw new Error(permitType + ' is unhandled')
    }
  }

  private getDeliveryTitle(notification: BaseNotification): string {
    const { entityId, type, authorId } = notification
    const { companiesStore, deliveriesStore, projectMembersStore } = this.props
    const delivery = deliveriesStore.byId.get(entityId)
    if (!delivery) {
      return null
    }
    const companyName = companiesStore.getCompanyNameById(delivery.company)
    const deliveryName = delivery?.codeToDisplay(this.props.companiesStore)
    const author = projectMembersStore.getById(authorId)

    switch (type) {
      case NotificationType.DeliveryRequested:
        return Localization.translator.deliveryRequested
      case NotificationType.DeliveryRequestedByLocationClosure:
        return Localization.translator.deliveryRequestedByLocationClosure(
          deliveryName,
        )
      case NotificationType.DeliveryScheduled:
        return Localization.translator.deliveryScheduled
      case NotificationType.DeliveryOnsite:
        return Localization.translator.deliveryOnSite
      case NotificationType.DeliveryOnhold:
        return Localization.translator.deliveryOnHold
      case NotificationType.DeliveryPaused:
        return Localization.translator.deliveryPaused
      case NotificationType.DeliveryDelivering:
        return Localization.translator.delivering
      case NotificationType.DeliveryPassedInspection:
        return Localization.translator.deliveryPassedInspection
      case NotificationType.DeliveryFailedInspection:
        return Localization.translator.deliveryFailedInspection
      case NotificationType.DeliveryDone:
        return Localization.translator.deliveryDone
      case NotificationType.DeliveryIncompleteDone:
        return Localization.translator.deliveryIncompletelyDone
      case NotificationType.DeliveryUpdated:
        return Localization.translator.deliveryUpdated
      case NotificationType.DeliveryDeleted:
        return Localization.translator.deliveryDeletedBy(
          deliveryName,
          User.getFullName(author),
          companyName,
        )
      case NotificationType.DeliveryDenied:
        return Localization.translator.deliveryDenied
      case NotificationType.DeliveryCanceled:
        return Localization.translator.deliveryCanceled
      case NotificationType.DeliveryFollowed:
        return Localization.translator.deliveryFollowed

      default:
        throw new Error(type + ' is not added to translator')
    }
  }

  private renderLabel(
    text: string | JSX.Element,
    icon?: JSX.Element,
    isBold?: boolean,
  ): JSX.Element {
    const titleText = typeof text === 'string' ? text : EMPTY_STRING

    return (
      <div className="row x-start y-start mb5" title={titleText}>
        {!!icon && (
          <>
            <div className="no-grow col x-center" style={{ flexBasis: '28px' }}>
              {icon}
            </div>
            <HGap size="10" />
          </>
        )}
        {!icon && <HGap size="38" />}
        <Text
          bold={isBold}
          className={classList({
            'primary-blue uppercase': !!icon,
            'large ellipsis': true,
          })}
        >
          {text}
        </Text>
      </div>
    )
  }

  private addPermitSubtitles = (
    permit: SitePermit,
    subtitles: ISubtitles[],
  ) => {
    const {
      projectDateStore: {
        getTimeIntervalToDisplay,
        getMonthDayAndTimeToDisplay,
      },
      permitTypesStore,
      companiesStore,
    } = this.props

    const { getCompanyNameById } = companiesStore

    subtitles.push({
      text: permit.getCaption(permitTypesStore),
      isBold: true,
    })

    subtitles.push({
      text: getTimeIntervalToDisplay(
        permit.startDate,
        permit.endDate,
        getMonthDayAndTimeToDisplay,
      ),
      isBold: false,
    })

    subtitles.push({
      text: permit.companyIds?.map(id => getCompanyNameById(id)).join(', '),
      isBold: false,
    })

    if (permit.locations) {
      subtitles.push({
        text: (
          <WorkflowCardLocationLabel
            locations={permit.locations}
            isOneColor={false}
            shouldShowAsTag={true}
            shouldAddPadding
          />
        ),
        isBold: false,
      })
    }
  }
}
