import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { LocationType } from '~/client/graph'
import Checkbox from '~/client/src/shared/components/Checkbox'
import { HGap } from '~/client/src/shared/components/Layout'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import InitialState from '~/client/src/shared/stores/InitialState'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'

import { sortAttributes } from '../../utils/sortingFunctions'
import Location from './Location'

const siteLogistics = 'Site Logistics'
const siteLogisticsParent: LocationBase = {
  name: siteLogistics,
  id: siteLogistics,
} as LocationBase

interface IProps {
  selectedIds: string[]
  onChanged(selectedIds: string[]): void

  isSingleSelectionMode?: boolean
  allowedObjectIds?: string[]
  restrictedObjectIds?: string[]
  restrictedObjectTypes?: LocationType[]
  firstRowText?: string
  firstRowValue?: boolean
  firstRowAction?: () => void
  onSingleSelect?(attr: LocationBase): void

  state?: InitialState
  locationAttributesStore?: LocationAttributesStore
}

@inject('state', 'locationAttributesStore')
@observer
export default class LocationsSelectorByLBS extends React.Component<IProps> {
  @observable private selectedParents: LocationBase[] = []

  public render() {
    const parent = this.selectedParents[this.selectedParents.length - 1]
    const { firstRowText, firstRowAction, firstRowValue } = this.props

    return (
      <>
        {!!parent && (
          <div
            className="bt-palette-grey bb-palette-grey bg-palette-brand-lightest px12 py8 row y-center pointer"
            onClick={this.showPreviousParent}
          >
            <div className="no-grow pr10">
              <Icon icon={IconNames.CHEVRON_LEFT} />
            </div>
            <div className="text large light">{parent.name}</div>
          </div>
        )}
        {firstRowText && (
          <div
            className="bt-palette-grey bb-palette-grey bg-palette-brand-lightest px12 py8 row y-center y-start row px12 py8"
            key={firstRowText}
          >
            <Checkbox
              className={classList({ checked: firstRowValue })}
              isChecked={firstRowValue}
              onClick={firstRowAction}
            />
            <div className="row y-center pointer overflow-hidden">
              <div className="overflow-hidden">{firstRowText}</div>
            </div>
          </div>
        )}
        {this.displayedAttributes.map(dto => {
          const hasChildren = this.hasChildren(dto)
          const canBeChecked = this.canAttributeBeChecked(dto)
          const isItemSelected = canBeChecked && this.isAttributeSelected(dto)

          return (
            <div className="y-start row px12 py8" key={dto.id}>
              <div className="no-grow">
                {canBeChecked ? (
                  <Checkbox
                    className={classList({ checked: isItemSelected })}
                    isChecked={isItemSelected}
                    onClick={this.toggleCheckbox.bind(this, dto)}
                  />
                ) : (
                  <HGap size="28" />
                )}
              </div>
              <div
                className="row y-center pointer overflow-hidden"
                onClick={this.showParent.bind(this, dto)}
              >
                <div className="overflow-hidden">
                  <Location dto={dto} />
                </div>
                <div className="no-grow">
                  {hasChildren && <Icon icon={IconNames.CHEVRON_RIGHT} />}
                </div>
              </div>
            </div>
          )
        })}
        {!parent && (
          <div
            className="bt-palette-grey bb-palette-grey bg-palette-brand-lightest px12 py8 row y-center y-start row px12 py8"
            key={siteLogistics}
          >
            <div
              className="row y-center pointer overflow-hidden"
              onClick={this.showSiteLogistics}
            >
              <div className="overflow-hidden">{siteLogistics}</div>
              <div className="no-grow">
                {<Icon icon={IconNames.CHEVRON_RIGHT} />}
              </div>
            </div>
          </div>
        )}
      </>
    )
  }

  @computed
  private get displayedAttributes(): LocationBase[] {
    const parent = this.selectedParents[this.selectedParents.length - 1]

    if (parent?.id === siteLogistics) {
      return this.siteLogistics
    }

    if (parent) {
      return this.allAttributes.filter(dto => dto.isParent(parent))
    }

    return this.allAttributes.filter(
      dto => !dto.hasParent && dto.type === LocationType.Building,
    )
  }

  @computed
  private get siteLogistics(): LocationBase[] {
    return this.allAttributes.filter(
      dto => dto.type !== LocationType.Building && !dto.hasParent,
    )
  }

  @computed
  private get allAttributes(): LocationBase[] {
    return this.props.locationAttributesStore.getSortedAllAttributes(
      sortAttributes,
    )
  }

  private isAttributeSelected(attribute: LocationBase) {
    return this.props.selectedIds.includes(attribute.id)
  }

  private canAttributeBeChecked({ id, type: type }: LocationBase) {
    const { restrictedObjectTypes, restrictedObjectIds, allowedObjectIds } =
      this.props

    const hasRestrictedType = restrictedObjectTypes?.includes(type)
    const isRestricted = restrictedObjectIds?.includes(id)
    const isAllowed = !allowedObjectIds?.length || allowedObjectIds.includes(id)

    return isAllowed && !hasRestrictedType && !isRestricted
  }

  private hasChildren(attribute: LocationBase) {
    return this.allAttributes.some(dto => dto.isParent(attribute))
  }

  private toggleCheckbox(dto: LocationBase) {
    const { selectedIds, onChanged, onSingleSelect, isSingleSelectionMode } =
      this.props

    const isItemSelected = this.isAttributeSelected(dto)

    if (isSingleSelectionMode) {
      return onSingleSelect(!isItemSelected && dto)
    }

    if (isItemSelected) {
      onChanged(selectedIds.filter(id => id !== dto.id))
    } else {
      onChanged([...selectedIds, dto.id])
    }
  }

  private showParent(dto: LocationBase) {
    const hasChildren = this.hasChildren(dto)
    if (hasChildren) {
      this.selectedParents.push(dto)
    }
  }

  private showSiteLogistics = () => {
    this.selectedParents.push(siteLogisticsParent)
  }

  private showPreviousParent = () => {
    if (this.selectedParents.length) {
      this.selectedParents.pop()
    }
  }
}
