import { INotification } from '~/client/graph'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'

import { getTransitionPath } from '../../shared/constants/commonRoutes'
import Localization from '../../shared/localization/LocalizationManager'
import ProjectDateStore from '../../shared/stores/ui/ProjectDate.store'
import { isNotificationSupportedInEnv } from '../../shared/utils/util'
import desktopRoutes from '../constants/desktopRoutes'
import DesktopRootStore from './DesktopRoot.store'

// localization: translated

export interface IDesktopNotification {
  title: string
  options: INotificationOptions
}

interface INotificationAction {
  action: string
  title: string
  icon: string
}

interface INotificationOptions {
  body: string
  icon: string
  tag: string
  actions: INotificationAction[]
}

const NOTIFICATION_ICON = '/static/icons/notification-logo.png'

export function generateAndSendDesktopNotifications(
  unreadNotifications: INotification[],
  {
    activitiesStore,
    deliveriesStore,
    gatesStore,
    zonesStore,
    companiesStore,
    projectDateStore,
    statusUpdatesStore,
    state,
  }: DesktopRootStore,
) {
  const activityNotifications: IDesktopNotification[] = []
  const deliveryNotifications: IDesktopNotification[] = []
  unreadNotifications.forEach(({ entityId, activityObjectId }) => {
    const activity =
      activityObjectId && activitiesStore.getByDbId(activityObjectId)
    if (activity) {
      const { companies, code, startDate, finishDate } = activity
      const dateInterval = getDateInterval(
        startDate,
        finishDate(projectDateStore, statusUpdatesStore),
        projectDateStore,
      )
      activityNotifications.push({
        title:
          Localization.translator.activityUpdated +
          `: ${code}` +
          ` ${companies.join(', ')}`,
        options: {
          body: dateInterval,
          icon: NOTIFICATION_ICON,
          tag: Localization.translator.activities,
          actions: [
            {
              action: getTransitionPath(
                `${desktopRoutes.ACTIVITIES}?${code}`,
                state.activeOrInitProjectCode,
              ),
              title: Localization.translator.openActivity,
              icon: NOTIFICATION_ICON,
            },
          ],
        },
      })
    }

    const delivery = deliveriesStore.byId.get(entityId)
    if (delivery) {
      const { status, gate, zone, startDate, endDate } = delivery
      const gateName = gatesStore.byId.get(gate)?.name
      const zoneName = zonesStore.byId.get(zone)?.name

      const dateInterval = getDateInterval(startDate, endDate, projectDateStore)
      deliveryNotifications.push({
        title: delivery.getDeliveryCaption(companiesStore),
        options: {
          body: `${dateInterval}, ${gateName}, ${zoneName}`,
          icon: NOTIFICATION_ICON,
          tag: Localization.translator.deliveries,
          actions: [
            {
              action: getTransitionPath(
                `${desktopRoutes.DELIVERIES}?${entityId}`,
                state.activeOrInitProjectCode,
              ),
              title: Localization.translator.openDelivery,
              icon: getDeliveryIcon(status),
            },
          ],
        },
      })
    }
  })
  sendDesktopNotifications(deliveryNotifications)
  sendDesktopNotifications(activityNotifications)
}

function getDateInterval(
  startDate: Date | number,
  endDate: Date | number,
  projectDateStore: ProjectDateStore,
) {
  const { getPronounDateString, getTimeToDisplay } = projectDateStore
  const date = getPronounDateString(new Date(startDate))
  const startTime = getTimeToDisplay(startDate)
  const finishTime = getTimeToDisplay(endDate)
  return `${date}, ${startTime}-${finishTime}`
}

export function sendDesktopNotifications(
  notifications: IDesktopNotification[],
) {
  if (isNotificationSupportedInEnv()) {
    Notification.requestPermission(result => {
      if (result === 'granted') {
        navigator.serviceWorker.ready.then(registration => {
          notifications.forEach((notification, index) => {
            const title =
              notifications.length > 1
                ? notification.title +
                  `(${index + 1} of ${notifications.length})`
                : notification.title
            registration.showNotification(title, notification.options)
          })
        })
      }
    })
  }
}

export function getDeliveryIcon(status: DeliveryStatus) {
  switch (status) {
    case DeliveryStatus.Requested:
    case DeliveryStatus.Changed:
      return '/static/icons/Requested.png'
    case DeliveryStatus.FailedInspection:
    case DeliveryStatus.Denied:
    case DeliveryStatus.Paused:
      return '/static/icons/Inspection-Rejected.png'
    case DeliveryStatus.OnSite:
    case DeliveryStatus.Delivering:
    case DeliveryStatus.OnHold:
      return '/static/icons/Onsite.png'
    case DeliveryStatus.PassedInspection:
      return '/static/icons/Inspection-Accepted.png'
    case DeliveryStatus.Scheduled:
    case DeliveryStatus.Canceled:
      return '/static/icons/Scheduled.png'
    case DeliveryStatus.Done:
      return '/static/icons/Done.png'
  }
}
