import { action, observable } from 'mobx'

import { AnnouncementRelationType, SitePermitStatus } from '~/client/graph'

import PermitTypesStore from '../../stores/domain/PermitTypes.store'
import AnnouncementEditionFormStore from '../AnnouncementEditionForm/AnnouncementEditionForm.store'
import SitePermitCreationFormStore from './SitePermitCreationForm.store'

export default class PermitApprovalStore {
  @observable public shouldCreateAnnouncement = false

  public get isAnnouncementCreationAvailable(): boolean {
    const { isLastStep, existingPermit, template, workflowFieldsStore } =
      this.sitePermitFormStore
    const { isLate, isApprovalStep, isDoneOrDenied } = existingPermit
    const { areOptionalFieldsChanged, areRequiredFieldsChanged } =
      workflowFieldsStore

    return (
      !isDoneOrDenied &&
      !areOptionalFieldsChanged &&
      !areRequiredFieldsChanged &&
      !isLastStep &&
      isApprovalStep(template) &&
      !isLate
    )
  }

  public constructor(
    private readonly sitePermitFormStore: SitePermitCreationFormStore,
    private readonly announcementFormStore: AnnouncementEditionFormStore,
    private readonly permitTypesStore: PermitTypesStore,
    protected readonly closeDialog: () => void,
  ) {}

  @action.bound
  public toggleAnnouncement() {
    this.shouldCreateAnnouncement = !this.shouldCreateAnnouncement
  }

  public approvePermit = () => {
    if (this.sitePermitFormStore.isLastStep) {
      this.permitDone()
    } else {
      this.updatePermit(SitePermitStatus.Accepted)
    }

    if (this.shouldCreateAnnouncement) {
      this.updateAnnouncementDatesAndLocation()
      this.announcementFormStore.submitForm()
    }
  }

  public permitDone = () => {
    this.updatePermit(SitePermitStatus.Done)
    this.closeDialog()
  }

  public passBicInspection = () => {
    if (this.sitePermitFormStore.isLastStep) {
      this.permitDone()
    } else {
      this.updatePermit(SitePermitStatus.BicInspected)
    }
  }

  @action
  public updatePermit = (status: SitePermitStatus) => {
    const { isPermitValid, editablePermit, updatePermit } =
      this.sitePermitFormStore

    if (isPermitValid) {
      editablePermit.setStatus(status)
      updatePermit()
    }
  }

  private updateAnnouncementDatesAndLocation() {
    const {
      id,
      locations: [defaultLocation],
      startDate,
      endDate,
      getCaption,
    } = this.sitePermitFormStore.editablePermit

    const title = getCaption(this.permitTypesStore)
    this.announcementFormStore.applyTitle(title)
    this.announcementFormStore.applyRelationship(
      AnnouncementRelationType.Permit,
      id,
    )
    this.announcementFormStore.applyLocation(defaultLocation)
    this.announcementFormStore.applyDates(
      new Date(startDate),
      new Date(endDate),
    )
  }
}
