import { ProjectAccessType } from '~/client/graph'

import Localization from '../localization/LocalizationManager'

// TODO: move to graph to sync
// TODO: 'permitMaster' => 'formsMaster'
export enum ProjectResponsibilityTypes {
  DocMaster = 'docMaster',
  Inspector = 'inspector',
  PresentationUser = 'presentationUser',
  FormsMaster = 'permitMaster',
  ScanMaster = 'scanMaster',
  ActivityView = 'activityView',
  ActivityUpdate = 'activityUpdate',
  ActivityUpload = 'activityUpload',
  ActivityNote = 'activityNote',
}

export const projectAccessTypesList: ProjectAccessType[] =
  Object.values(ProjectAccessType)

export function getAccountTypeTranslate(value: ProjectAccessType) {
  switch (value) {
    case ProjectAccessType.Admin:
      return Localization.translator.projectAccessTypeValues.admin
    case ProjectAccessType.Member:
      return Localization.translator.projectAccessTypeValues.member
    case ProjectAccessType.Owner:
      return Localization.translator.projectAccessTypeValues.owner
    case ProjectAccessType.Visitor:
      return Localization.translator.projectAccessTypeValues.visitor
    default:
      throw new Error(value + ' is unhandled')
  }
}
