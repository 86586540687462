import { observable } from 'mobx'

import DateInterval from '~/client/src/shared/types/DateInterval'
import Guard from '~/client/src/shared/utils/Guard'

import ProjectDateStore from '../stores/ui/ProjectDate.store'

import Colors from '~/client/src/shared/theme.module.scss'

export interface ICalendarDates {
  startDate: Date
  endDate: Date
}

export enum CalendarEventLabelType {
  Default,
  Extended,
  Mobile,
  New,
  Preview,
}

export enum CalendarEventEntityType {
  Delivery = 'Delivery',
  Form = 'Form',
}

export const NEW_EVENT_DATA_ID = 'new-event'
const NEW_EVENT = {
  labelType: CalendarEventLabelType.New,
  dataId: NEW_EVENT_DATA_ID,
  className: 'selected-event',
}

export default class CalendarEvent {
  public static createNewEvent(
    startDate: Date,
    endDate: Date,
    projectDateStore: ProjectDateStore,
    entityType: CalendarEventEntityType,
  ) {
    return CalendarEvent.createEvent({
      startDate,
      endDate,
      entityType,
      ...NEW_EVENT,
      projectDateStore,
      styles: { backgroundColor: Colors.neutral0, color: Colors.neutral100 },
    })
  }

  public static createEvent({
    startDate,
    endDate,
    entityType,
    labelType,
    dataId,
    projectDateStore,
    className,
    styles,
    data,
    label,
    isActive,
  }: {
    startDate: Date
    endDate: Date
    entityType: CalendarEventEntityType
    labelType: CalendarEventLabelType
    dataId: string
    projectDateStore: ProjectDateStore
    className?: string
    styles?: any
    data?: any
    label?: string
    isActive?: boolean
  }): CalendarEvent {
    return new CalendarEvent(
      startDate,
      endDate,
      entityType,
      labelType,
      dataId,
      projectDateStore,
      className,
      styles,
      data,
      label,
      isActive,
    )
  }

  @observable public zoneId: string
  @observable public areaId: string
  @observable public buildingId: string
  @observable public gateId: string
  @observable public levelId: string
  @observable public routeId: string
  @observable public equipmentIds: string[]
  public dateInterval: DateInterval

  public constructor(
    startDate: Date,
    endDate: Date,
    public entityType: CalendarEventEntityType,
    public labelType: CalendarEventLabelType,
    public dataId: string,
    projectDateStore: ProjectDateStore,
    public className?: string,
    public styles?: any,
    public data?: any,
    public label?: string,
    public isActive?: boolean,
  ) {
    Guard.requireAll({ startDate, endDate, labelType, dataId })
    this.dateInterval = new DateInterval(startDate, endDate, projectDateStore)
  }

  public get startDate(): Date {
    return this.dateInterval.startDate
  }

  public set startDate(newDate: Date) {
    this.dateInterval.setStartDate(newDate)
  }

  public get endDate(): Date {
    return this.dateInterval.endDate
  }

  public set endDate(newDate: Date) {
    this.dateInterval.setEndDate(newDate)
  }

  public isEqual(other: CalendarEvent) {
    return this === other
  }

  public isIntersect(other: CalendarEvent) {
    return this.dateInterval.isIntersect(other.dateInterval)
  }

  public isInHour(date: Date, hour: number) {
    return this.dateInterval.isInHour(date, hour)
  }

  public get isNew(): boolean {
    return this.dataId === NEW_EVENT_DATA_ID
  }
}
