import * as React from 'react'

import { inject, observer } from 'mobx-react'

import FormStatusText from '~/client/src/shared/components/FormStatusText/FormStatusText'
import PermitTypeIcon from '~/client/src/shared/components/PermitTypeIcon/PermitTypeIcon'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import CalendarEvent from '~/client/src/shared/models/CalendarEvent'
import Permit from '~/client/src/shared/models/Permit'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import { INTERPUNCT } from '~/client/src/shared/utils/usefulStrings'

interface IProps {
  event: CalendarEvent

  permitTypesStore?: PermitTypesStore
  companiesStore?: CompaniesStore
  locationAttributesStore?: LocationAttributesStore
}

@inject('permitTypesStore', 'companiesStore', 'locationAttributesStore')
@observer
export default class FormMultiDayEventLabel extends React.Component<IProps> {
  public render() {
    const { event } = this.props

    if (event.isNew) {
      return Localization.translator.newForm
    }

    const { locationAttributesStore } = this.props

    const locationName = locationAttributesStore.getLocationBySiteLocationObj(
      this.form.locations[0],
    )?.name

    return (
      <>
        <PermitTypeIcon
          permitType={this.typeOfFormType}
          className="row no-grow event-icon form-type-icon mr4"
        />
        {this.companyName && (
          <>
            <span className="text-bold no-grow">{this.companyName}</span>
            <span className="px4 no-grow">{INTERPUNCT}</span>
          </>
        )}
        <span className="text light no-grow">{event.label}</span>
        <span className="px4 no-grow">{INTERPUNCT}</span>
        <span className="text light bold uppercase lp015 no-grow">
          {this.nameOfFormType}
        </span>
        <span className="px4 no-grow">{INTERPUNCT}</span>
        <FormStatusText
          status={this.form.status}
          workflowStepLevel={this.form.workflowStepLevel}
          className="relative text bold capitalize no-grow"
        />
        {locationName && (
          <>
            <span className="px4 no-grow">{INTERPUNCT}</span>
            {locationName}
          </>
        )}
      </>
    )
  }

  private get form(): Permit {
    return this.props.event.data
  }

  private get typeOfFormType() {
    return this.form.getTypeOfPermitType(this.props.permitTypesStore)
  }

  private get nameOfFormType() {
    return this.form.getNameOfPermitType(this.props.permitTypesStore)
  }

  private get companyName(): string {
    const [companyId] = this.form.companyIds

    return this.props.companiesStore.getCompanyNameById(companyId, null)
  }
}
