import * as React from 'react'

import { observer } from 'mobx-react'
import { toggleClass } from 'react-classlist-helper'

import {
  IConditionalField,
  IPermitTypeChecklist,
  IPermitTypeField,
  IRestrictedOption,
  PermitFieldType,
} from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import { restrictedTablePermitFieldTypes } from '~/client/src/shared/constants/PermitFieldTypeConstants'
import {
  MAX_PERMIT_TABLE_COLUMNS,
  PERMIT_TABLE_FIELDS_KEY,
} from '~/client/src/shared/constants/permitTypeFieldsConstants'

import FieldConfigurator from '../../FieldConfigurator/FieldConfigurator'
import FormFieldsModal from '../../FormFieldsModal'

interface IProps {
  field: IPermitTypeField
  allConditionalFields: IConditionalField[]

  addNewTableField(
    fieldId: string,
    key: string,
    index: number,
    fieldType: PermitFieldType,
  ): void
  updateTableField(
    fields: IPermitTypeField[],
    index: number,
    field: IPermitTypeField,
  ): void
  updateTableChecklist(
    fields: IPermitTypeField[],
    index: number,
    field: IPermitTypeField,
    checklist: IPermitTypeChecklist,
  ): void
  updateLocationRestrictions(
    fields: IPermitTypeField[],
    index: number,
    field: IPermitTypeField,
    restrictions: IRestrictedOption[],
  ): void
  removeTableField(
    fieldId: string,
    key: string,
    conditionalFieldId: string,
  ): void
}

const _addTableColumnField = 'Add table column field'

@observer
export default class TableFieldsConfigurator extends React.Component<IProps> {
  public render() {
    return (
      <div className="pb12 px20 ml10">
        <div
          className={toggleClass(
            'ba-dashed-palette-grey brada8',
            !!this.tableColumnFields.length,
          )}
        >
          {this.tableColumnFields.map((columnField, idx) => (
            <div key={columnField.id}>
              <div className="row x-center y-center table-field-row">
                <div className="mw20 mx4 row x-start y-center">
                  {this.isAddingFieldAllowed && (
                    <FormFieldsModal
                      addNewField={this.addNewTableField.bind(null, idx)}
                      restrictedFields={restrictedTablePermitFieldTypes}
                    >
                      <Icons.Add className="forms-table-add-icon pointer relative no-grow" />
                    </FormFieldsModal>
                  )}
                </div>
                <FieldConfigurator
                  field={columnField}
                  isMultipleToggleHidden={true}
                  onChange={this.updateTableField.bind(null, idx)}
                  onChecklistUpdate={this.updateTableChecklist.bind(null, idx)}
                  onLocationRestrictionsUpdate={this.updateLocationRestrictions.bind(
                    null,
                    idx,
                  )}
                />
                <Icons.CrossGrey
                  className="forms-table-del-icon text light pointer mx4 no-grow opacity5 row x-center y-center"
                  onMouseDown={this.removeTableField.bind(null, columnField)}
                />
              </div>
            </div>
          ))}
        </div>
        {!this.tableColumnFields.length && (
          <FormFieldsModal
            addNewField={this.addNewTableField.bind(null, -1)}
            restrictedFields={restrictedTablePermitFieldTypes}
          >
            <span className="pointer text large blue-highlight px10 ml15">
              {_addTableColumnField}
            </span>
          </FormFieldsModal>
        )}
      </div>
    )
  }

  private get tableColumnFields(): IPermitTypeField[] {
    const { allConditionalFields, field } = this.props

    const conditionalField = allConditionalFields?.find(
      cf => cf.fieldId === field.id && cf.key === PERMIT_TABLE_FIELDS_KEY,
    )

    return conditionalField?.values || []
  }

  private get isAddingFieldAllowed(): boolean {
    return this.tableColumnFields.length < MAX_PERMIT_TABLE_COLUMNS
  }

  private addNewTableField = (index: number, fieldType: PermitFieldType) => {
    if (!this.isAddingFieldAllowed) {
      return
    }

    const { addNewTableField, field } = this.props
    addNewTableField(field.id, PERMIT_TABLE_FIELDS_KEY, index, fieldType)
  }

  private updateTableField = (index: number, field: IPermitTypeField) => {
    this.props.updateTableField(this.tableColumnFields, index, field)
  }

  private updateTableChecklist = (
    index: number,
    field: IPermitTypeField,
    checklist: IPermitTypeChecklist,
  ) => {
    this.props.updateTableChecklist(
      this.tableColumnFields,
      index,
      field,
      checklist,
    )
  }

  private updateLocationRestrictions = (
    index: number,
    field: IPermitTypeField,
    restrictions: IRestrictedOption[],
  ) => {
    this.props.updateLocationRestrictions(
      this.tableColumnFields,
      index,
      field,
      restrictions,
    )
  }

  private removeTableField = (tableField: IPermitTypeField) => {
    const { removeTableField, field } = this.props
    removeTableField(field.id, PERMIT_TABLE_FIELDS_KEY, tableField.id)
  }
}
