import * as React from 'react'

import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { SitePermitStatus, WorkflowStepType } from '~/client/graph'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import PermitApprovalStore from '../../../PermitApproval.store'
import SitePermitCreationFormStore from '../../../SitePermitCreationForm.store'

// localization: translated

interface IProps {
  actionBarButtonsRenderer: (
    btnText: string,
    isPermitValid: boolean,
    status: SitePermitStatus,
    isDeny?: boolean,
    customBtnHandler?: () => void,
  ) => JSX.Element

  store: SitePermitCreationFormStore
  permitApprovalStore: PermitApprovalStore
}

interface IFormButtonConfig {
  caption: string
  status: SitePermitStatus
  isFailedStatus?: boolean
  isValidationIgnored?: boolean
  customBtnHandler?(): void
}

@observer
export default class CommonPermitButtons extends React.Component<IProps> {
  public render() {
    const { actionBarButtonsRenderer, store } = this.props
    const { isPermitValid, existingPermit, template } = store
    const { currentWorkflowStepId, isDoneOrDenied, isDenied } = existingPermit

    if (isDoneOrDenied) {
      return (
        <div className="row x-center my10">
          <span
            className={classList({
              'text center large bold lp015 grey-light': true,
              red: isDenied,
            })}
          >
            {Localization.translator.formIsDoneOrDenied(isDenied)}
          </span>
        </div>
      )
    }

    const currentStepType = template.stepsWithoutRecurring.find(
      s => s.id === currentWorkflowStepId,
    )?.type
    const buttonConfig = this.buttonsConfigByStepsMap[currentStepType]

    if (!buttonConfig?.length) return null

    return (
      <div className="row x-center">
        {buttonConfig.map((conf, idx) => (
          <React.Fragment key={idx}>
            {actionBarButtonsRenderer(
              conf.caption,
              conf.isValidationIgnored || isPermitValid,
              conf.status,
              conf.isFailedStatus,
              conf.customBtnHandler,
            )}
          </React.Fragment>
        ))}
      </div>
    )
  }

  private get buttonsConfigByStepsMap(): {
    [K in WorkflowStepType]: IFormButtonConfig[]
  } {
    const { permitApprovalStore, store } = this.props
    const { addBicInspection, isLastStep } = store
    const lastStepStasus = isLastStep && SitePermitStatus.Done

    return {
      [WorkflowStepType.Review]: [
        {
          caption: Localization.translator.approve_verb,
          status: lastStepStasus || SitePermitStatus.Reviewed,
        },
      ],
      [WorkflowStepType.Approval]: [
        {
          caption: Localization.translator.deny_verb,
          status: SitePermitStatus.Denied,
          isFailedStatus: true,
          isValidationIgnored: true,
        },
        {
          caption: Localization.translator.approve_verb,
          status: null,
          customBtnHandler: permitApprovalStore.approvePermit,
        },
      ],
      [WorkflowStepType.Start]: [
        {
          caption: Localization.translator.start_verb,
          status: lastStepStasus || SitePermitStatus.Active,
        },
      ],
      [WorkflowStepType.ReadyToInspect]: [
        {
          caption: Localization.translator.readyToInspect,
          status: lastStepStasus || SitePermitStatus.ToInspect,
        },
      ],
      [WorkflowStepType.Inspection]: [
        {
          caption: Localization.translator.fail,
          status: SitePermitStatus.Failed,
          isFailedStatus: true,
        },
        {
          caption: Localization.translator.pass_verb,
          status: SitePermitStatus.Passed,
        },
      ],
      [WorkflowStepType.BicInspection]: [
        {
          caption: Localization.translator.fail,
          status: null,
          isFailedStatus: true,
          isValidationIgnored: true,
          customBtnHandler: addBicInspection.bind(null, false),
        },
        {
          caption: isLastStep
            ? Localization.translator.passAndClose
            : Localization.translator.pass_verb,
          status: null,
          customBtnHandler: this.passBicInspection,
        },
      ],
      [WorkflowStepType.Finish]: [
        {
          caption: Localization.translator.done,
          status: SitePermitStatus.Done,
        },
      ],
      [WorkflowStepType.Request]: [],
      [WorkflowStepType.Submission]: [],
      [WorkflowStepType.RecurringInspection]: [],
    }
  }

  private passBicInspection = () => {
    const { store, permitApprovalStore } = this.props

    store.addBicInspection(true)
    permitApprovalStore.passBicInspection()
  }
}
