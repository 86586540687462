import KnownTranslatorKeys from '../localization/knownTranslatorKeys'
import { enumToList } from '../utils/converters'

enum CommonGroupingOption {
  NONE = 'None',
  STATUS = 'Status',
  LBS = 'LBS',
  COMPANY = 'Company',
  DATE = 'Date',
  RESPONSIBLE = 'Responsible',
}

enum LogisticsSpecificGroupingOption {
  APP = 'App',
}

enum FormsSpecificGroupingOption {
  TYPE = 'Type',
}

export type LogisticsGroupingOption =
  | CommonGroupingOption
  | LogisticsSpecificGroupingOption
  | FormsSpecificGroupingOption

export const LogisticsGroupingOption = {
  ...CommonGroupingOption,
  ...LogisticsSpecificGroupingOption,
  ...FormsSpecificGroupingOption,
}

export type FormsGroupingOption =
  | CommonGroupingOption
  | FormsSpecificGroupingOption

export const FormsGroupingOption = {
  ...CommonGroupingOption,
  ...FormsSpecificGroupingOption,
}

export type HUBGroupingOption =
  | CommonGroupingOption
  | LogisticsSpecificGroupingOption

export const HUBGroupingOption = {
  ...CommonGroupingOption,
  ...LogisticsSpecificGroupingOption,
}

export const hubGroupingOptionList = enumToList(HUBGroupingOption)
export const formsGroupingOptionList = enumToList(FormsGroupingOption)

export const getLogisticsGroupingOptionTranslatorKey = (
  id: string,
): KnownTranslatorKeys => {
  switch (id) {
    case CommonGroupingOption.NONE:
      return KnownTranslatorKeys.none
    case CommonGroupingOption.STATUS:
      return KnownTranslatorKeys.status
    case LogisticsSpecificGroupingOption.APP:
      return KnownTranslatorKeys.app
    case CommonGroupingOption.LBS:
      return KnownTranslatorKeys.lbs
    case CommonGroupingOption.DATE:
      return KnownTranslatorKeys.date
    case CommonGroupingOption.COMPANY:
      return KnownTranslatorKeys.company
    case CommonGroupingOption.RESPONSIBLE:
      return KnownTranslatorKeys.responsibleUser
    case FormsSpecificGroupingOption.TYPE:
      return KnownTranslatorKeys.type
    default:
      throw new Error(id + ' is unhandled')
  }
}

export default LogisticsGroupingOption
