import * as React from 'react'

import { Icon, Switch } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList, toggleClass } from 'react-classlist-helper'
import { AutoSizer, List } from 'react-virtualized'

import {
  IPermitTypeChecklist,
  IPermitTypeField,
  IRestrictedOption,
  PermitFieldType,
} from '~/client/graph'
import * as Icons from '~/client/src/shared/components/Icons'
import PermitFieldIcon from '~/client/src/shared/components/PermitFieldIcon/PermitFieldIcon'
import StruxhubBareEditableInput from '~/client/src/shared/components/StruxhubInputs/StruxhubBareEditableInput/StruxhubBareEditableInput'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import TooltipWrapper from '~/client/src/shared/components/TooltipWrapper/TooltipWrapper'
import {
  locationPermitFieldTypes,
  multiplePermitFieldTypes,
  selectOptionsPermitFieldTypes,
} from '~/client/src/shared/constants/PermitFieldTypeConstants'
import formFieldsCaptionMap from '~/client/src/shared/constants/formFieldsCaptionMap'
import Keys from '~/client/src/shared/enums/Keys'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import Checklist from '../Checklist/Checklist'
import FieldStateSelector from '../FieldStateSelector'
import SectionHeader from '../SectionHeader'
import FieldConfiguratorStore, { FieldState } from './FieldConfigurator.store'
import EditableTextField from './components/EditableTextField/EditableTextField'
import LocationRestrictions from './components/LocationRestrictions/LocationRestrictions'

interface IProps {
  field: IPermitTypeField
  onChange: (updatedField: IPermitTypeField) => void
  onChecklistUpdate: (
    field: IPermitTypeField,
    checklist: IPermitTypeChecklist,
  ) => void
  onLocationRestrictionsUpdate(
    field: IPermitTypeField,
    restrictedLocations: IRestrictedOption[],
  ): void

  isMultipleToggleHidden?: boolean
  isCollapsed?: boolean
  onCollapseToggle?(): void

  tableConfiguratorRenderer?(field: IPermitTypeField): JSX.Element
  materialSubFieldsRenderer?(field: IPermitTypeField): JSX.Element
}

const ICON_SIZE = 18
const OVERSCAN_ROW_COUNT = 6
const SELECT_OPTION_ROW_HEIGHT = 42
const SELECT_OPTIONS_MAX_HEIGHT = 200
const SELECT_OPTIONS_PADDING = 10

const _multiple = 'Multiple'
const checkboxForClosingAllLocations =
  'This is a checkbox for closing all selected locations'
const noteForClosingLocations = `Note: This field will be hidden on the form 
if your workflow does not have a Start or Approval step (with automatic activation).`
const _optionsToSelect = 'Options to select'
const _thereAreNoOptions =
  'There are no options, please add them using the input below'
const _addNewOption = 'Add a new option'
const _belowYouCanAddTableFields =
  'Below you can add table fields that will serve as table columns'

@observer
export default class FieldConfigurator extends React.Component<IProps> {
  private readonly store: FieldConfiguratorStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new FieldConfiguratorStore(props.field, props.onChange)
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { field, onChange } = this.props
    if (field !== prevProps.field) {
      this.store.setField(field)
    }
    if (onChange !== prevProps.onChange) {
      this.store.setOnChangeHandler(onChange)
    }
  }

  public render() {
    const { field, fieldState, updateCaption } = this.store

    if (
      field.type === PermitFieldType.Section ||
      field.type === PermitFieldType.Subsection
    ) {
      return this.sectionOrSubsectionField
    }

    if (field.type === PermitFieldType.Divider) {
      return this.dividerField
    }

    if (field.type === PermitFieldType.Text) {
      return this.textField
    }

    if (field.checklist) {
      return this.checklistField
    }

    return (
      <div className="relative overflow-hidden my12">
        <div className="row bg-palette-brand-lightest ba-light-input-border brada4 px15">
          <TooltipWrapper
            content={formFieldsCaptionMap[field.type]}
            className="row no-grow"
          >
            <PermitFieldIcon fieldName={field.type} />
          </TooltipWrapper>
          <StruxhubBareEditableInput
            className="row text large bold ellipsis"
            inputClassName="text large bold ba2-group-highlight brada3"
            value={field.caption}
            onChange={updateCaption}
          />
          {this.multipleToggle}
          <FieldStateSelector
            className={toggleClass(
              'not-allowed',
              !field.canEditIsMandatory && !field.canEditIsShown,
            )}
            isDisabled={!field.canEditIsMandatory && !field.canEditIsShown}
            fieldState={fieldState}
            onChange={this.handleSelectStateChange}
          />
        </div>
        {this.locationRestrictionOptions}
        {this.selectorOptions}
        {this.requestToCloseDescription}
        {this.tableConfigurator}
        {this.materialSubFieldsConfigurator}
      </div>
    )
  }

  private get sectionOrSubsectionField(): JSX.Element {
    const { field, updateCaption } = this.store

    const isSubsection = field.type === PermitFieldType.Subsection
    const icon = this.props.isCollapsed
      ? IconNames.CARET_RIGHT
      : IconNames.CARET_DOWN

    return (
      <SectionHeader
        header={field.caption}
        className={classList({
          'text small-header subsection-field h32': isSubsection,
          h36: !isSubsection,
        })}
        isSubHeader={true}
        isEditable={true}
        onEditChange={updateCaption}
        iconElement={
          <Icon
            icon={icon}
            size={ICON_SIZE}
            className="no-grow mr15 pointer"
            onClick={this.onCollapseClick}
          />
        }
      />
    )
  }

  private get dividerField(): JSX.Element {
    const { field, updateCaption } = this.store

    return (
      <div className="row py20 overflow-hidden">
        <hr className="ba-none bt-light-input-border min-width30" />
        <StruxhubBareEditableInput
          className="row text small light no-white-space-wrap bold lp15 uppercase w-fit-content mx10 ellipsis flex-grow3"
          inputClassName="text small light bold lp15 uppercase mx10 ba2-group-highlight brada3"
          value={field.caption}
          onChange={updateCaption}
        />
        <hr className="ba-none bt-light-input-border min-width30" />
      </div>
    )
  }

  private get textField(): JSX.Element {
    const { field, updateCaption } = this.store

    return (
      <div className="row px15 py12 ba-dashed-palette-grey brada8">
        <EditableTextField
          className="text large"
          value={field.caption}
          onChange={updateCaption}
        />
      </div>
    )
  }

  private get checklistField(): JSX.Element {
    const { onChange } = this.props
    const { field } = this.store
    const isQuestion = field.type === PermitFieldType.Question
    const checklistClassName = isQuestion ? 'my12' : 'mb30'

    return (
      <Checklist
        className={checklistClassName}
        field={field}
        isSingleMode={isQuestion}
        onChange={this.updateChecklist}
        onFieldChange={onChange}
      />
    )
  }

  private get selectorOptions(): JSX.Element {
    const {
      field,
      isAddSelectOptionShown,
      newSelectOption,
      addNewSelectOption,
    } = this.store

    if (!selectOptionsPermitFieldTypes.includes(field.type)) {
      return null
    }

    const selectOptions = field?.selectOptions || []

    return (
      <div className="col mt15 ml20">
        <span className="text large grey py10">{_optionsToSelect}</span>
        {!selectOptions.length && (
          <span className="text grey py10">{_thereAreNoOptions}</span>
        )}
        <div
          className={classList({
            'select-options-container': true,
            'ba-dashed-palette-grey brada8 my10': !!selectOptions.length,
          })}
          style={{
            padding: SELECT_OPTIONS_PADDING,
            maxHeight: SELECT_OPTIONS_MAX_HEIGHT + SELECT_OPTIONS_PADDING * 2,
          }}
        >
          <AutoSizer disableHeight={true}>
            {({ width }) => (
              <List
                data={selectOptions}
                width={width}
                height={Math.min(
                  SELECT_OPTIONS_MAX_HEIGHT,
                  SELECT_OPTION_ROW_HEIGHT * selectOptions.length,
                )}
                rowHeight={SELECT_OPTION_ROW_HEIGHT}
                rowCount={selectOptions.length}
                rowRenderer={this.renderSelectOption}
                scrollToAlignment="start"
                overscanRowCount={OVERSCAN_ROW_COUNT}
              />
            )}
          </AutoSizer>
        </div>
        {isAddSelectOptionShown && (
          <div className="row y-center new-select-option-input mw270">
            <StruxhubInput
              label={_addNewOption}
              isRequiredTextHidden={true}
              value={newSelectOption}
              onChange={this.handleSelectOptionChange}
              onValueReset={this.handleResetSelectOptionChange}
              onKeyDown={this.handleKeyPress}
            />
            <Icons.Add
              className="pt10 ml10 add-icon no-grow"
              onClick={addNewSelectOption}
            />
          </div>
        )}
      </div>
    )
  }

  public renderSelectOption = ({ key, style, index }: any): JSX.Element => {
    const props = { key, style }
    const { field, removeSelectOption } = this.store
    const option = (field.selectOptions || [])[index]

    return (
      <div
        {...props}
        className={classList({
          'row py10': true,
          'bt-light-input-border': !!index,
        })}
      >
        <span className="text grey-light extra-large ellipsis" title={option}>
          {option}
        </span>
        <Icon
          className="bg-grey-scale-light br-rounded pointer text white mr20"
          icon={IconNames.SMALL_CROSS}
          onClick={removeSelectOption.bind(null, index)}
        />
      </div>
    )
  }

  private get multipleToggle(): JSX.Element {
    const { field, toggleIsMultipleValue } = this.store

    if (
      this.props.isMultipleToggleHidden ||
      !multiplePermitFieldTypes.includes(field.type)
    ) {
      return null
    }

    const isDisabled = !field.canEditIsMandatory && !field.canEditIsShown

    return (
      <div
        className={classList({
          'row pl5 no-grow mx4': true,
          'not-allowed': !field.canEditIsMandatory && !field.canEditIsShown,
        })}
      >
        <div className="text large bold no-flex">{_multiple}</div>
        <Switch
          className="primary-blue-switch forms-multiple-toggle bp3-align-right no-outline-container ml5 no-grow"
          disabled={isDisabled}
          checked={field.isMultiple || false}
          onChange={toggleIsMultipleValue}
        />
      </div>
    )
  }

  private get requestToCloseDescription(): JSX.Element {
    const { field } = this.store

    if (field.type !== PermitFieldType.RequestToCloseLocations) {
      return null
    }

    return (
      <div className="col pl5 mt15 mx20">
        <span className="text large grey">
          {checkboxForClosingAllLocations}
        </span>
        <span className="mt5 text large grey bold">
          {noteForClosingLocations}
        </span>
      </div>
    )
  }

  private get tableConfigurator(): JSX.Element {
    const { field, tableConfiguratorRenderer } = this.props

    if (field.type !== PermitFieldType.Table) {
      return null
    }

    return (
      <>
        <div className="row pl5 m20 text large grey">
          {_belowYouCanAddTableFields}
        </div>
        {tableConfiguratorRenderer?.(field)}
      </>
    )
  }

  private get materialSubFieldsConfigurator(): JSX.Element {
    const { field, materialSubFieldsRenderer } = this.props

    if (field.type === PermitFieldType.Material) {
      return (
        <>
          <div className="row pl5 m20 text large grey">
            {
              Localization.translator.formMaterialSubFields
                .materialHasQuantityAndLocation
            }
          </div>
          {materialSubFieldsRenderer?.(field)}
        </>
      )
    }
  }

  private get locationRestrictionOptions(): JSX.Element {
    const { field } = this.props

    if (locationPermitFieldTypes.includes(field.type)) {
      return (
        <div className="mt15 ml20">
          <LocationRestrictions
            restrictedLocations={field.restrictedOptions}
            onChange={this.updateRestrictedLocations}
          />
        </div>
      )
    }
  }

  private handleSelectStateChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    this.store.changeFieldState(event.target.value as FieldState)
  }

  private handleSelectOptionChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    this.store.updateSelectOption(event.target.value)
  }

  private handleResetSelectOptionChange = () => {
    this.store.updateSelectOption('')
  }

  private handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === Keys.Enter && !event.shiftKey) {
      this.store.addNewSelectOption()
    }
  }

  private onCollapseClick = (event: React.SyntheticEvent) => {
    event.stopPropagation()
    this.props.onCollapseToggle?.()
  }

  private updateChecklist = (checklist: IPermitTypeChecklist) => {
    this.props.onChecklistUpdate(this.props.field, checklist)
  }

  private updateRestrictedLocations = (
    restrictedLocations: IRestrictedOption[],
  ) => {
    this.props.onLocationRestrictionsUpdate(
      this.props.field,
      restrictedLocations,
    )
  }
}
