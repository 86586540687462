import { WorkflowsFilterType } from '~/client/graph'

export const extendedWorkflowsFilterTypes = [
  WorkflowsFilterType.Location,
  WorkflowsFilterType.Status,
  WorkflowsFilterType.Company,
  WorkflowsFilterType.ResponsibleContact,
  WorkflowsFilterType.Type,
]

export const mapWorkflowFilterTypes = [
  WorkflowsFilterType.Location,
  WorkflowsFilterType.Company,
  WorkflowsFilterType.ResponsibleContact,
  WorkflowsFilterType.Type,
]
