import * as createUuid from 'uuid/v1'

import { IFileRequisites, UploadingType } from '~/client/graph'
import * as imageHelper from '~/client/src/shared/utils/image'

import UploadFileCommand from '../../commands/UploadFile/UploadFileCommand'
import GraphExecutorStore from './GraphExecutor.store'

export class FileUploadingStore {
  public constructor(private graphExecutorStore: GraphExecutorStore) {}

  public async uploadFile(
    file: Blob,
    fileType: UploadingType,
    fileName?: string,
  ): Promise<IFileRequisites[]> {
    if (!fileName) {
      fileName = createUuid()
    }

    if (fileType === UploadingType.Image || fileType === UploadingType.Avatar) {
      const degrees = await imageHelper.readOrientation(file, null, true)
      file = await imageHelper.rotateImage(file, degrees)
    }

    const isFilePublic =
      fileType === UploadingType.Image ||
      fileType === UploadingType.Pdf ||
      fileType === UploadingType.Avatar

    const command = new UploadFileCommand(
      file,
      this.graphExecutorStore,
      fileName,
      fileType,
      isFilePublic,
    )

    return command.execute()
  }
}
