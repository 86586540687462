import React from 'react'

import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable'

import './DraggableBar.scss'

export interface IProps {
  viewMode: ViewMode
  selectViewMode: (mode: ViewMode) => void

  headerContent?: () => JSX.Element
  isDraggingDisabled?: boolean
  bottomOffset?: number
  defaultHeight?: number
  className?: string
}

export enum ViewMode {
  Closed = 'Closed',
  Opened = 'Opened',
  Mixed = 'Mixed',
  Calendar = 'Calendar',
}

const UNSET_HEIGHT_ATTRIBUTE = 'unset'

const OPENED_VIEW_MODE_TOP_OFFSET = 20
const OPENED_VIEW_MODE_THRESHOLD = 60
const DEFAULT_BOTTOM_OFFSET = 220
const CLOSED_VIEW_MODE_BOTTOM_OFFSET = 15

@observer
export default class DraggableBar extends React.Component<IProps> {
  @observable private yOffset: number = null
  @observable private isDraggingMode: boolean = false

  private listRef: HTMLDivElement = null

  public componentDidUpdate(oldProps: IProps) {
    this.setListOffset(oldProps.viewMode)
  }

  public componentDidMount() {
    this.yOffset = this.props.isDraggingDisabled
      ? 0
      : this.props.defaultHeight || this.mixedModeOffset
  }

  public render() {
    const { children } = this.props

    return (
      <div
        ref={this.setListRef}
        style={{
          top: this.yOffset,
          height: this.listHeight,
        }}
        className="draggable-bar absolute bg-white full-width"
      >
        {this.renderBodyHeader()}
        <div className="col scrollable items-list">{children}</div>
      </div>
    )
  }

  private get listHeight(): string {
    if (this.props.isDraggingDisabled) {
      return '100%'
    }
    if (!this.listRef?.offsetParent || !this.yOffset) {
      return `${this.props.defaultHeight}px` || UNSET_HEIGHT_ATTRIBUTE
    }
    return `calc(100% - ${this.yOffset}px)`
  }

  private onDrag = (e: DraggableEvent, data: DraggableData) => {
    const { selectViewMode } = this.props
    const newPosition = this.yOffset + data.y

    switch (true) {
      case this.yOffset && newPosition < OPENED_VIEW_MODE_THRESHOLD:
        selectViewMode(ViewMode.Opened)
        return
      case this.listRef?.offsetParent &&
        newPosition >
          this.listRef.offsetParent.clientHeight -
            CLOSED_VIEW_MODE_BOTTOM_OFFSET:
        selectViewMode(ViewMode.Closed)
        return
      default:
        selectViewMode(ViewMode.Mixed)
        this.yOffset = newPosition
        break
    }
  }

  private renderBodyHeader(): JSX.Element {
    const { headerContent, className, isDraggingDisabled } = this.props
    const defaultClassName = `drag-handle ${
      !this.isDraggingMode ? 'not-dragging' : 'dragging'
    }`
    const headerClassName = `col x-center list-header ${className || ''}`

    return (
      <>
        <div className={headerClassName}>
          <div className="pa10 row full-width x-center relative">
            {!isDraggingDisabled && (
              <>
                <Draggable
                  axis="y"
                  defaultClassName={defaultClassName}
                  onStart={this.onDragStart}
                  onStop={this.onDragEnd}
                  onDrag={this.onDrag}
                  handle=".draggable-section"
                >
                  <div className="draggable-section transparent-header full-height" />
                </Draggable>
                <div className="bg-palette-brand-light scrollable-button no-grow brada5 draggable-section" />
              </>
            )}
          </div>
          {headerContent && (
            <div className="row full-width">{headerContent()}</div>
          )}
        </div>
      </>
    )
  }

  @action.bound
  private onDragStart() {
    this.isDraggingMode = true
  }

  @action.bound
  private onDragEnd() {
    this.isDraggingMode = false
  }

  private setListOffset = (previousViewMode?: ViewMode) => {
    if (this.props.viewMode !== previousViewMode) {
      switch (this.props.viewMode) {
        case ViewMode.Opened:
          this.yOffset = OPENED_VIEW_MODE_TOP_OFFSET
          return
        case ViewMode.Closed:
          this.yOffset =
            this.listRef?.offsetParent?.clientHeight -
            CLOSED_VIEW_MODE_BOTTOM_OFFSET
          return
        case ViewMode.Mixed:
          this.yOffset =
            !this.yOffset && this.listRef?.offsetParent
              ? this.mixedModeOffset
              : null
          return
      }
    }
  }

  private setListRef = (ref: HTMLDivElement) => {
    this.listRef = ref
  }

  private get mixedModeOffset(): number {
    const offset = this.props.bottomOffset || DEFAULT_BOTTOM_OFFSET
    return this.listRef?.offsetParent?.clientHeight - offset
  }
}
