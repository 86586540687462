import React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'
import { Marker, MarkerDragEvent } from 'react-map-gl'

import GlobeIcon from '~/client/src/shared/components/SitemapHelpers/components/drawnParts/GlobeIcon'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'

import ThemeMode from '../../../utils/ThemeModeManager'
import MapViewItemDrawnPart from '../models/SitemapItemDrawnPart'

import Colors from '~/client/src/shared/theme.module.scss'

interface IProps {
  item: MapViewItemBase
  isDraggable?: boolean
  onDragEnd?: (e: MarkerDragEvent) => void
  onDrag?: (e: MarkerDragEvent) => void
  onMarkerClick?: (item: MapViewItemBase) => void
  onMarkerDblClick?: (part: MapViewItemDrawnPart) => void
  renderMarkerPin?: (item: MapViewItemBase) => void
  onMarkerHover?: (id: string) => void
  isHighlighted?: boolean
  isSelected?: boolean
}

@observer
export default class GlobeViewMarker extends React.Component<IProps> {
  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onMouseEnter() {
    this.props.onMarkerHover?.(this.props.item.id)
  }

  @action.bound
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private onMouseLeave() {
    this.props.onMarkerHover?.(null)
  }

  public render() {
    const {
      item,
      item: {
        sitemapItem,
        globeViewItemProperties: { iconProperties, labelProperties },
      },
      isDraggable,
      onDrag,
      onDragEnd,
      renderMarkerPin,
      isSelected,
      isHighlighted,
    } = this.props
    if (
      !sitemapItem.coordinates ||
      (!iconProperties?.isDisplayed && !labelProperties?.isDisplayed)
    ) {
      return null
    }

    return (
      <Marker
        className="pointer"
        longitude={sitemapItem.coordinates.longitude}
        latitude={sitemapItem.coordinates.latitude}
        draggable={isDraggable}
        onDragEnd={onDragEnd}
        onDrag={onDrag}
      >
        <div
          className="col x-center y-center globe-item relative"
          style={{
            border:
              !labelProperties?.isDisplayed && isSelected
                ? `2px solid ${ThemeMode.getHEXColor(Colors.primary60)}`
                : 'none',
          }}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
        >
          {renderMarkerPin && renderMarkerPin(item)}
          {iconProperties?.isDisplayed && (
            <GlobeIcon
              onClick={this.onClick}
              onDblClick={this.onDblClick}
              item={item}
              isHighlighted={isHighlighted}
            />
          )}
          {labelProperties?.isDisplayed && (
            <div
              onClick={this.onClick}
              onDoubleClick={this.onLabelDblClick}
              className="text bold globe-item-text"
              style={{
                fontSize: labelProperties?.fontSize,
                color: labelProperties?.color || item.color,
                position: iconProperties?.isDisplayed ? 'absolute' : 'relative',
                border: isSelected
                  ? `2px solid ${ThemeMode.getHEXColor(Colors.primary60)}`
                  : 'none',
              }}
            >
              {iconProperties?.isDisplayed && (
                <div
                  className="px5"
                  style={{ minWidth: '27px', minHeight: '27px' }}
                />
              )}
              <div
                style={{
                  border: labelProperties?.isTextBoxDisplayed
                    ? `2px solid ${labelProperties?.color || item.color}`
                    : 'none',
                  backgroundColor: labelProperties?.isTextBoxDisplayed
                    ? 'white'
                    : 'transparent',
                  padding:
                    labelProperties.fontSize > 14
                      ? labelProperties.fontSize / 2 - 5
                      : '0px 2px 0px 2px',
                }}
              >
                {item.displayName}
              </div>
            </div>
          )}
        </div>
      </Marker>
    )
  }

  @action.bound
  private onClick(event): void {
    event.stopPropagation()
    this.props.onMarkerClick?.(this.props.item)
  }

  @action.bound
  private onDblClick(event): void {
    event.stopPropagation()
    this.props.onMarkerDblClick?.(MapViewItemDrawnPart.Icon)
  }

  @action.bound
  private onLabelDblClick(event): void {
    event.stopPropagation()
    this.props.onMarkerDblClick?.(MapViewItemDrawnPart.Label)
  }
}
