import { action, observable } from 'mobx'

import { AvailableCompanyOptionsByProjectCodeDocument } from '~/client/graph/operations/generated/Companies.generated'

import { FormFieldType } from '../../enums/FormFieldType'
import { LocalStorageKey } from '../../enums/LocalStorageKey'
import UserFieldId from '../../enums/UserFieldId'
import { INameValuePair } from '../../interfaces/INameValuePair'
import Localization from '../../localization/LocalizationManager'
import User from '../../models/User'
import {
  GET_VISITOR_USER_TOKEN,
  LOGIN_WITH_INVITE_KEY,
} from '../../stores/EventStore/eventConstants'
import BaseSignUpStore, {
  projectCodeKey,
} from '../../stores/ui/BaseSignUp.store'
import { Field } from '../../types/CustomFieldType'
import {
  NON_EMPTY_VALUE_PATTERN,
  VALID_EMAIL_PATTERN,
} from '../../utils/regExpPatterns'

export default class VisitorSignUpStore extends BaseSignUpStore {
  @observable private areOptionsLoading: boolean = false
  @observable private isTryingToLogin: boolean = false
  @observable private visitorCompanyOptions: INameValuePair[] = []

  public get fields(): Field[] {
    const fields: Field[] = [
      {
        id: UserFieldId.FirstName,
        type: FormFieldType.Text,
        value: this.getFieldValueById(UserFieldId.FirstName),
        label: Localization.translator.firstName,
        onChange: this.handleFieldChange.bind(this, UserFieldId.FirstName),
        onValueReset: this.handleValueReset.bind(this, UserFieldId.FirstName),
        pattern: NON_EMPTY_VALUE_PATTERN.source,
      },
      {
        id: UserFieldId.LastName,
        type: FormFieldType.Text,
        value: this.getFieldValueById(UserFieldId.LastName),
        label: Localization.translator.lastName,
        onChange: this.handleFieldChange.bind(this, UserFieldId.LastName),
        onValueReset: this.handleValueReset.bind(this, UserFieldId.LastName),
        pattern: NON_EMPTY_VALUE_PATTERN.source,
      },
      {
        id: UserFieldId.Company,
        type: FormFieldType.SelectOne,
        value: this.getFieldValueById(UserFieldId.Company),
        label: Localization.translator.company,
        options: this.companiesAsOptions,
        onChange: this.handleFieldChange.bind(this, UserFieldId.Company),
      },
      {
        id: UserFieldId.Email,
        type: FormFieldType.Email,
        value: this.getFieldValueById(UserFieldId.Email),
        label: Localization.translator.email_noun,
        onChange: this.handleFieldChange.bind(this, UserFieldId.Email),
        onValueReset: this.handleValueReset.bind(this, UserFieldId.Email),
        pattern: VALID_EMAIL_PATTERN.source,
      },
      {
        id: UserFieldId.PhoneNumber,
        type: FormFieldType.Phone,
        value: this.getFieldValueById(UserFieldId.PhoneNumber),
        label: Localization.translator.phoneNumber,
        onChange: this.handlePhoneFieldChange.bind(
          this,
          UserFieldId.PhoneNumber,
        ),
      },
    ]

    fields.forEach(field => {
      field.isValid = this.isFieldValid(field.id)
      field.isRequired = this.isFieldRequired(field.id)
    })

    return fields
  }

  public get isLoading(): boolean {
    const { loading } = this.appState
    return (
      loading.get(GET_VISITOR_USER_TOKEN) || loading.get(LOGIN_WITH_INVITE_KEY)
    )
  }

  public get shouldShowPreloader(): boolean {
    return this.areOptionsLoading || this.isTryingToLogin
  }

  public get companiesAsOptions(): INameValuePair[] {
    return [
      { name: User.getDefaultCompanyName(), value: '' },
      ...this.visitorCompanyOptions,
    ]
  }

  @action.bound
  public async requestOptions() {
    this.areOptionsLoading = true

    try {
      await this.loadCompanyOptionsForVisitor()
    } catch (e) {
      return alert(e)
    } finally {
      this.areOptionsLoading = false
    }

    this.resetInitialCompanyIfNeed()
  }

  public get initFieldValuesMap(): { [key: string]: string } {
    try {
      const visitorData = JSON.parse(
        localStorage.getItem(LocalStorageKey.VisitorData),
      )

      const isSameProject =
        visitorData[projectCodeKey] === this.appState.initProjectCode

      const companyId = isSameProject ? visitorData[UserFieldId.Company] : ''

      return {
        [UserFieldId.FirstName]: visitorData[UserFieldId.FirstName] || '',
        [UserFieldId.LastName]: visitorData[UserFieldId.LastName] || '',
        [UserFieldId.Company]: companyId || '',
        [UserFieldId.Email]: visitorData[UserFieldId.Email],
        [UserFieldId.PhoneNumber]: visitorData[UserFieldId.PhoneNumber],
      }
    } catch {
      return {}
    }
  }

  @action.bound
  public tryToLogin() {
    this.isTryingToLogin = true

    if (this.canSubmit) {
      // try login with previously entered credentials
      this.submit()
    } else {
      // try login in visitor-anonymous mode
      // maybe it is not required for visitors to create a user account on this project
      this.eventsStore.dispatch(
        GET_VISITOR_USER_TOKEN,
        { [projectCodeKey]: this.appState.initProjectCode },
        this.stopLoginAttempt,
      )
    }
  }

  @action.bound
  protected submit() {
    this.eventsStore.dispatch(
      GET_VISITOR_USER_TOKEN,
      {
        [projectCodeKey]: this.appState.initProjectCode,
        ...this.formattedFieldMapForSubmission,
      },
      this.handleSubmitError,
    )
  }

  @action.bound
  private handleSubmitError(error: Error) {
    this.stopLoginAttempt()
    this.setError(error.message)
  }

  @action.bound
  private stopLoginAttempt() {
    this.isTryingToLogin = false
  }

  private async loadCompanyOptionsForVisitor() {
    const res = await this.graphExecutorStore.query(
      AvailableCompanyOptionsByProjectCodeDocument,
      { projectCode: this.appState.initProjectCode },
    )

    if (res.data) {
      this.visitorCompanyOptions =
        res.data.companyOptions?.map(o => ({
          name: o.name,
          value: o.value,
        })) || []
    } else {
      throw (
        res.error ||
        new Error(
          Localization.translator.somethingWentWrongDuringAPIInteraction,
        )
      )
    }
  }

  private resetInitialCompanyIfNeed() {
    const companyId = this.getFieldValueById(UserFieldId.Company)

    if (
      companyId &&
      !this.visitorCompanyOptions.some(o => o.value === companyId)
    ) {
      this.setFieldValueById(UserFieldId.Company, '')
    }
  }
}
