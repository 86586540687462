import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { LocationType } from '~/client/graph'
import CustomWorkflows from '~/client/src/desktop/components/Filters/CustomWorkflowsFilters/CustomWorkflows'
import DesktopCustomWorkflowsFiltersStore from '~/client/src/desktop/components/Filters/CustomWorkflowsFilters/DesktopCustomWorkflowsFilters.store'
import DateSelector from '~/client/src/desktop/components/Filters/DateSelector/DateSelector'
import EntityNameFilter from '~/client/src/desktop/components/Filters/EntityNameFilter/EntityNameFilter'
import BaseHeaderBar from '~/client/src/desktop/components/HeaderBar/HeaderBar'
import ReportModal from '~/client/src/desktop/components/HeaderBar/components/ReportModal'
import { TwoMonthsDatePickerMode } from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import LogisticActionTypes, {
  getLogisticActionTypeDisplayName,
} from '~/client/src/desktop/enums/LogisticsActionTypes'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import DynamicOverflowListStore from '~/client/src/shared/components/DynamicOverflowList/DynamicOverflowList.store'
import * as Icons from '~/client/src/shared/components/Icons'
import LogisticsCreationListMenu from '~/client/src/shared/components/SitemapCards/LogisticsCreationListMenu'
import * as TagIcon from '~/client/src/shared/components/TagIcon'
import TooltipWrapper from '~/client/src/shared/components/TooltipWrapper/TooltipWrapper'
import WeatherWidget from '~/client/src/shared/components/WeatherWidget/WeatherWidget'
import { LogisticsFilterType } from '~/client/src/shared/enums/LogisticsFilterType'
import LogisticsGroupingOption, {
  formsGroupingOptionList,
  getLogisticsGroupingOptionTranslatorKey,
  hubGroupingOptionList,
} from '~/client/src/shared/enums/LogisticsGroupingOption'
import { LogisticsMapFilterType } from '~/client/src/shared/enums/LogisticsMapFilterType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LogisticsFilterStore from '~/client/src/shared/stores/LogisticsFilter.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import CustomWorkflowsListFiltersStore from '~/client/src/shared/stores/domain/CustomWorkflowsListFilter.store'
import LocationAttributesStore from '~/client/src/shared/stores/domain/LocationAttributes.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import WeatherForecastsStore from '~/client/src/shared/stores/domain/WeatherForecasts.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import { getLocationTypeDisplayName } from '~/client/src/shared/types/IHierarchyParent'
import {
  LOCATION_SEPARATOR,
  NO_VALUE,
} from '~/client/src/shared/utils/usefulStrings'

import LogisticsStore from '../../Logistics.store'
import LogisticsListStore from '../LogisticsList/LogisticsList.store'
import LogisticsMapStore from '../LogisticsMap/LogisticsMap.store'
import LogisticsHeaderBarStore from './LogisticsHeaderBar.store'
import LogisticsFilter from './components/BaseLogisticsFilters/LogisticsFilter'
import LogisticsGroupingOptions from './components/LogisticsGroupingOptions/LogisticsGroupingOptions'
import LogisticsGroupingOptionsStore from './components/LogisticsGroupingOptions/LogisticsGroupingOptions.store'
import LogisticsListFilterStore from './components/LogisticsListFilter/LogisticsListFilter.store'
import LogisticsMapFilterStore from './components/LogisticsListFilter/LogisticsMapFilter.store'
import PermitReportButton from './components/PermitReportButton'

interface IProps {
  logisticsStore: LogisticsStore
  logisticsListStore: LogisticsListStore
  logisticsMapStore: LogisticsMapStore
  projectsStore: ProjectsStore
  projectDateStore: ProjectDateStore
  sitePermitsStore: SitePermitsStore
  eventsStore?: DesktopEventStore
  locationAttributesStore?: LocationAttributesStore
  permitTypesStore?: PermitTypesStore
  companiesStore?: CompaniesStore
  tagsStore?: TagsStore
  weatherForecastsStore?: WeatherForecastsStore
  state?: DesktopInitialState

  isMapViewDisabled: boolean
  isPermitOnly?: boolean
  customWorkflowsListFiltersStore?: CustomWorkflowsListFiltersStore
  projectMembersStore?: ProjectMembersStore
}

const specificFilterTypes: string[] = ['locations']

type IAction = {
  actionType: LogisticActionTypes
  icon: any
  isShown(isAdminMode?: boolean, isFormsOnlyMode?: boolean): boolean
}

const secondaryActions: IAction[] = [
  {
    actionType: LogisticActionTypes.SUBSCRIBE,
    icon: <Icon icon={IconNames.STAR_EMPTY} />,
    isShown: () => true,
  },
  {
    actionType: LogisticActionTypes.ASSIGN,
    icon: <Icon icon={IconNames.STAR} />,
    isShown: isAdminMode => isAdminMode,
  },
  {
    actionType: LogisticActionTypes.DELETE,
    icon: <Icon icon={IconNames.TRASH} />,
    isShown: (isAdminMode, isFormsOnlyMode) => isAdminMode && isFormsOnlyMode,
  },
]

const isDeleteAction = (actionType: LogisticActionTypes): boolean =>
  actionType === LogisticActionTypes.DELETE

@inject(
  'state',
  'eventsStore',
  'locationAttributesStore',
  'permitTypesStore',
  'companiesStore',
  'tagsStore',
  'weatherForecastsStore',
  'sitePermitsStore',
  'customWorkflowsListFiltersStore',
  'projectMembersStore',
)
@observer
export default class LogisticsHeaderBar extends BaseHeaderBar<IProps> {
  protected store: LogisticsHeaderBarStore = null
  private readonly logisticsFilterStore: LogisticsListFilterStore = null
  private readonly logisticsMapFilterStore: LogisticsMapFilterStore = null
  private readonly logisticsGroupingOptionsStore: LogisticsGroupingOptionsStore
  private readonly dynamicOverflowListStore: DynamicOverflowListStore = null
  private desktopCustomWorkflowsFiltersStore: DesktopCustomWorkflowsFiltersStore =
    null
  private desktopMapCustomWorkflowsFiltersStore: DesktopCustomWorkflowsFiltersStore =
    null

  public constructor(props: IProps) {
    super(props)

    this.dynamicOverflowListStore = new DynamicOverflowListStore()

    const { eventsStore, isPermitOnly } = props
    const { logisticsFilters, formsFilters } = this.appState

    this.store = new LogisticsHeaderBarStore(
      props.eventsStore,
      props.logisticsStore,
      props.isMapViewDisabled,
      isPermitOnly,
    )

    const { onShowGroupByChanged, onShowChanged } = this.store

    this.logisticsFilterStore = new LogisticsListFilterStore(
      props.eventsStore,
      props.logisticsStore,
      props.logisticsListStore,
      onShowChanged,
      props.locationAttributesStore,
      props.permitTypesStore,
      props.tagsStore,
      props.companiesStore,
      this.dynamicOverflowListStore.enableRecalculation,
      isPermitOnly,
    )
    this.logisticsMapFilterStore = new LogisticsMapFilterStore(
      props.eventsStore,
      props.logisticsStore,
      props.logisticsMapStore,
      onShowChanged,
      props.locationAttributesStore,
      props.permitTypesStore,
      props.tagsStore,
      props.companiesStore,
      this.dynamicOverflowListStore.enableRecalculation,
      isPermitOnly,
    )
    this.logisticsGroupingOptionsStore = new LogisticsGroupingOptionsStore(
      eventsStore,
      onShowGroupByChanged,
      this.onGroupingChanged,
      isPermitOnly ? formsFilters : logisticsFilters,
      isPermitOnly ? formsGroupingOptionList : hubGroupingOptionList,
      isPermitOnly ? LogisticsGroupingOption.LBS : LogisticsGroupingOption.APP,
      eventsStore.appState.logisticsFiltersSettings.desktopFilterMap,
      getLogisticsGroupingOptionTranslatorKey,
    )

    this.desktopCustomWorkflowsFiltersStore =
      new DesktopCustomWorkflowsFiltersStore(
        this.logisticsFilterStore,
        props.logisticsStore,
        props.customWorkflowsListFiltersStore,
        props.companiesStore,
        props.projectMembersStore,
        props.permitTypesStore,
        props.eventsStore,
        this.getItemNameByLocationTypeAndId,
        this.appState.formsFilters,
      )
    this.desktopMapCustomWorkflowsFiltersStore =
      new DesktopCustomWorkflowsFiltersStore(
        this.logisticsMapFilterStore,
        props.logisticsStore,
        props.customWorkflowsListFiltersStore,
        props.companiesStore,
        props.projectMembersStore,
        props.permitTypesStore,
        props.eventsStore,
        this.getItemNameByLocationTypeAndId,
        this.appState.formsMapFilters,
      )
  }

  public componentDidMount() {
    this.props.weatherForecastsStore.fetchWeeklyForecast(Date.now())
    this.store.setInitialDateValue()
    this.store.loadReportTemplate()
  }

  public componentDidUpdate(prevProps: IProps) {
    if (this.props.isMapViewDisabled !== prevProps.isMapViewDisabled) {
      this.store.isMapViewDisabled = !this.store.isMapViewDisabled
    }
  }

  private get groupedBy() {
    const { groupingKey } = this.props.logisticsListStore
    return Localization.translator[
      getLogisticsGroupingOptionTranslatorKey(groupingKey)
    ]
  }

  private get isAdminUser() {
    return this.props.state.userActiveProjectSettings?.isSuperUser
  }

  protected renderModal() {
    const { startDate, endDate, isProjectCalendarModeActive } =
      this.props.logisticsStore
    const { additionalReportFiles, toggleAdditionalReportFiles } = this.store
    const { filterStoresByTypeMap } = this.logisticsFilterStore

    return (
      <ReportModal
        startDate={startDate}
        endDate={endDate}
        groupedBy={this.groupedBy}
        toggleModal={this.toggleModal}
        getIconByFilter={this.getIconByFilter}
        filterStoresByTypeMap={filterStoresByTypeMap}
        additionalReportFiles={additionalReportFiles}
        toggleAdditionalReportFiles={toggleAdditionalReportFiles}
        renderButton={this.renderReportButton}
        handleSpicificFilterType={this.handleSpecificFilterType}
        specificFilterTypes={specificFilterTypes}
        reportName={Localization.translator.formReport}
        isFullReport={isProjectCalendarModeActive}
      />
    )
  }

  protected renderLeftSection() {
    return (
      <div className="row x-start full-height left-bar no-grow">
        {this.isWeatherWidgetAvailable && this.weatherWidget}
      </div>
    )
  }

  protected renderCenterSection(): JSX.Element {
    const { logisticsStore, isPermitOnly } = this.props
    const {
      openPermitCreationForm,
      showCreationAndEditionAnnouncementForm,
      isCreationAvailable,
    } = logisticsStore

    return (
      <>
        {this.renderSearchFilter()}
        {this.renderMyEntitiesButton()}
        {this.renderGroupingOptions()}
        {this.renderStateFilter()}
        {isPermitOnly && this.renderCustomFilters()}
        <div className="row full-height no-grow">
          {this.renderDatePicker()}
          {isCreationAvailable && this.store.isCalendarViewMode && (
            <div className="no-grow relative">
              <LogisticsCreationListMenu
                openAnnouncementCreationForm={
                  showCreationAndEditionAnnouncementForm
                }
                openPermitCreationForm={openPermitCreationForm}
                isPermitOnlyMode={isPermitOnly}
                className="calendar-view ml15"
              />
            </div>
          )}
        </div>
      </>
    )
  }

  private renderCustomFilters(): JSX.Element {
    if (this.store.isMapViewMode) {
      return (
        <CustomWorkflows store={this.desktopMapCustomWorkflowsFiltersStore} />
      )
    }

    return <CustomWorkflows store={this.desktopCustomWorkflowsFiltersStore} />
  }

  protected renderRightSection() {
    const { logisticsStore, logisticsListStore, isPermitOnly } = this.props

    if (isPermitOnly && this.store.isListViewMode) {
      const { filterStoresByTypeMap } = this.logisticsFilterStore
      return (
        <PermitReportButton
          title={Localization.translator.createReport}
          className="ml10"
          logisticsStore={logisticsStore}
          logisticsListStore={logisticsListStore}
          toggleModal={this.toggleModal}
          shouldPreventCreation={true}
          getItemNameByLocationTypeAndId={this.getItemNameByLocationTypeAndId}
          filterStoresByTypeMap={filterStoresByTypeMap}
        />
      )
    }

    return super.renderRightSection()
  }

  private isOnlyFilterSelected = (
    filtersStore: LogisticsFilterStore,
    filterType: LogisticsFilterType | LogisticsMapFilterType,
    id: string,
  ) => {
    const filter = filtersStore.filterStoresByTypeMap[filterType]
    return filter.selectedOptions?.size === 1 && filter.selectedOptions.has(id)
  }

  private toggleFilterOnly = (
    filtersStore: LogisticsFilterStore,
    filterType: LogisticsFilterType | LogisticsMapFilterType,
    id?: string,
  ) => {
    if (!id) {
      return
    }
    const filterStore = filtersStore.filterStoresByTypeMap[filterType]
    if (this.isOnlyFilterSelected(filtersStore, filterType, id)) {
      filterStore.clickOnSelectAll()
    } else {
      filterStore.selectOptionsByIds([id])
    }
    filterStore.clickOnApply()
  }

  private renderMyEntitiesButton = () => {
    const myId = this.props.state.user?.id
    const filterStore = this.store.isListViewMode
      ? this.logisticsFilterStore
      : this.logisticsMapFilterStore
    const filterType = this.store.isListViewMode
      ? LogisticsFilterType.ResponsibleContact
      : LogisticsMapFilterType.ResponsibleContact
    return (
      <div
        onClick={() => this.toggleFilterOnly(filterStore, filterType, myId)}
        className={classList({
          'desktop-deliveries-top-bar-btn no-grow ml15': true,
          active: this.isOnlyFilterSelected(filterStore, filterType, myId),
        })}
      >
        {Localization.translator.my}
      </div>
    )
  }

  private get weatherWidget(): JSX.Element {
    const { isLoading, todayForecast, weatherUnits, hasProjectLocation } =
      this.props.weatherForecastsStore

    return (
      <>
        <WeatherWidget
          isLoading={isLoading}
          hasProjectLocation={hasProjectLocation}
          todayForecast={todayForecast}
          projectWeatherUnits={weatherUnits}
        />
        <div className="mx15 no-grow br-light-input-border h40" />
      </>
    )
  }

  private renderSearchFilter() {
    const { isMapViewMode, forceSearchClose, onShowSearchChanged } = this.store
    const {
      logisticsFilters,
      formsFilters,
      logisticsMapFilters,
      formsMapFilters,
    } = this.appState
    const listFilters = this.props.isPermitOnly
      ? formsFilters
      : logisticsFilters
    const mapFilters = this.props.isPermitOnly
      ? formsMapFilters
      : logisticsMapFilters

    return (
      <EntityNameFilter
        filters={isMapViewMode ? mapFilters : listFilters}
        forceClose={forceSearchClose}
        onShowChanged={onShowSearchChanged}
      />
    )
  }

  private renderGroupingOptions() {
    const { forceGroupByClose, isListViewMode } = this.store

    if (!isListViewMode) {
      return null
    }

    return (
      <LogisticsGroupingOptions
        store={this.logisticsGroupingOptionsStore}
        forceClose={forceGroupByClose}
      />
    )
  }

  protected renderActionBar(): JSX.Element {
    const {
      openPermitCreationForm,
      showCreationAndEditionAnnouncementForm,
      viewMode,
      isCreationAvailable,
    } = this.props.logisticsStore

    if (viewMode !== ViewModes.List) return null

    const { isSomeRowSelected, statusTitle } = this.props.logisticsListStore

    return (
      <div className="row pb10 px24 status-title">
        <div className="row x-between text large light">
          <div className="no-grow nowrap">{statusTitle}</div>
          {isSomeRowSelected && (
            <div className="row bl-light-cool-grey actions-container ml10 pl6">
              {secondaryActions.map(this.renderActionButton)}
            </div>
          )}
          {isCreationAvailable && (
            <div className="no-grow relative">
              <LogisticsCreationListMenu
                openAnnouncementCreationForm={
                  showCreationAndEditionAnnouncementForm
                }
                openPermitCreationForm={openPermitCreationForm}
                isPermitOnlyMode={this.props.isPermitOnly}
                className="list-view"
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  private renderActionButton = ({
    actionType,
    icon,
    isShown,
  }: IAction): JSX.Element => {
    if (!isShown(this.isAdminUser, this.props.isPermitOnly)) {
      return null
    }

    const isBtnEnabled = this.isActionEnabled(actionType)
    const actionTooltip = this.getActionTooltip(isBtnEnabled, actionType)

    return (
      <TooltipWrapper
        key={actionType}
        className="no-grow"
        content={actionTooltip}
        disabled={!actionTooltip}
      >
        <BaseActionButton
          icon={icon}
          className="mx4 gray-theme"
          shouldShowBorder={false}
          isEnabled={isBtnEnabled}
          title={getLogisticActionTypeDisplayName(actionType)}
          isActive={this.isActive(actionType)}
          onClick={this.setActionType.bind(null, actionType)}
        />
      </TooltipWrapper>
    )
  }

  private setActionType = (actionType: LogisticActionTypes) => {
    this.props.logisticsListStore.setActionType(actionType)
  }

  private isActive(actionType: LogisticActionTypes) {
    return actionType === this.props.logisticsListStore.activeActionType
  }

  private isActionEnabled = (actionType: LogisticActionTypes): boolean => {
    return (
      !isDeleteAction(actionType) ||
      !this.props.logisticsListStore.areSelectedWorkflowsDone
    )
  }

  private getActionTooltip = (
    isEnabled: boolean,
    actionType: LogisticActionTypes,
  ): string => {
    if (isEnabled || !isDeleteAction(actionType)) return null
    return Localization.translator.cannotDeleteDoneForms
  }

  private renderStateFilter() {
    const { isMapViewMode, logisticsForceCloseMap, viewMode } = this.store
    const { eventsStore } = this.props
    const {
      logisticsFilters,
      formsFilters,
      logisticsMapFilters,
      formsMapFilters,
    } = this.appState
    const listFilters = this.props.isPermitOnly
      ? formsFilters
      : logisticsFilters
    const mapFilters = this.props.isPermitOnly
      ? formsMapFilters
      : logisticsMapFilters

    if (isMapViewMode) {
      return (
        <LogisticsFilter
          store={this.logisticsMapFilterStore}
          forceCloseMap={logisticsForceCloseMap}
          dynamicOverflowListStore={this.dynamicOverflowListStore}
          eventsStore={eventsStore}
          filters={mapFilters}
          viewMode={viewMode}
        />
      )
    }

    return (
      <LogisticsFilter
        store={this.logisticsFilterStore}
        forceCloseMap={logisticsForceCloseMap}
        dynamicOverflowListStore={this.dynamicOverflowListStore}
        eventsStore={eventsStore}
        filters={listFilters}
        viewMode={viewMode}
      />
    )
  }

  private getIconByFilter(filterType: string) {
    switch (filterType) {
      case LogisticsFilterType.Status:
      case LogisticsFilterType.Type:
        return <Icons.GeneralForm className="no-grow row mr10" />
      case LogisticsFilterType.Location:
        return <Icons.Location className="no-grow row mr10" />
      case LogisticsFilterType.Company:
        return <Icons.Company className="no-grow row mr10" />
      case LogisticsFilterType.App:
        return <Icons.Zone className="no-grow row mr10" />
      case LogisticsFilterType.ResponsibleContact:
        return <Icons.User className="no-grow row mr10" />
    }
  }

  private renderDatePicker() {
    const {
      projectsStore,
      projectDateStore,
      logisticsStore: { dateFilters, selectedDatePickerMode },
    } = this.props
    const {
      isMapViewMode,
      datePickerStates,
      forceDatePickerClose,
      onShowDatePickerChanged,
    } = this.store

    return (
      <DateSelector
        filters={dateFilters}
        forceClose={forceDatePickerClose}
        shouldUseRelativePosition={false}
        onShowChanged={onShowDatePickerChanged}
        mode={selectedDatePickerMode}
        projectsStore={projectsStore}
        projectDateStore={projectDateStore}
        shouldUseOtherDatesForFullProjectMode={true}
        shouldShowTodayButton={true}
        shouldRenderDateModes={!isMapViewMode}
        datePickerModes={datePickerStates}
        onOptionModeClick={this.changeDatePickerMode}
        shouldHideTopButtons={true}
      />
    )
  }

  private renderReportButton = () => {
    if (!this.store.isListViewMode || !this.props.isPermitOnly) {
      return
    }

    const { filterStoresByTypeMap } = this.logisticsFilterStore
    const { logisticsStore, logisticsListStore } = this.props
    const { additionalReportFiles, isAnyFileTypeChecked } = this.store

    return (
      <PermitReportButton
        title={Localization.translator.createReport}
        className="ml15"
        logisticsStore={logisticsStore}
        logisticsListStore={logisticsListStore}
        shouldHideIcon={true}
        toggleModal={this.toggleModal}
        shouldInvertColors={true}
        getItemNameByLocationTypeAndId={this.getItemNameByLocationTypeAndId}
        filterStoresByTypeMap={filterStoresByTypeMap}
        additionalReportFiles={additionalReportFiles}
        isEnabled={isAnyFileTypeChecked}
      />
    )
  }

  private changeDatePickerMode = (mode: TwoMonthsDatePickerMode) => {
    this.props.logisticsStore.changeDatePickerMode(mode)
    this.dynamicOverflowListStore.enableRecalculation()
  }

  private get appState(): DesktopInitialState {
    return this.props.eventsStore.appState
  }

  private get isWeatherWidgetAvailable(): boolean {
    return !this.props.isPermitOnly || this.store.isMapViewMode
  }

  private getIconBySpecificFilter(filterType: string) {
    switch (filterType) {
      case LocationType.Area:
        return <TagIcon.Area className="no-grow row mr10" />
      case LocationType.Building:
        return <TagIcon.Building className="no-grow row mr10" />
      case LocationType.Zone:
        return <TagIcon.Zone className="no-grow row mr10" />
      case LocationType.Route:
        return <TagIcon.Route className="no-grow row mr10" />
      case LocationType.Level:
        return <TagIcon.Level className="no-grow row mr10" />
      case LocationType.LogisticsObject:
        return <TagIcon.LogisticsObject className="no-grow row mr10" />
      case LocationType.Integration:
        return <TagIcon.MaturixStation className="no-grow row mr10" />
      case LocationType.VerticalObject:
        return <TagIcon.Stairs className="no-grow row mr10" />
      case LocationType.OffloadingEquipment:
        return <TagIcon.Equipment className="no-grow row mr10" />
      case LocationType.Gate:
        return <TagIcon.Gate className="no-grow row mr10" />
    }
  }

  private handleSpecificFilterType = (
    filterType: string,
    baseIndex: number,
  ) => {
    const { filterStoresByTypeMap } = this.logisticsFilterStore
    const { filter } = filterStoresByTypeMap[filterType]
    const result: { [filterType: string]: string[] } = {}

    const keys: string[] = Array.from(filter.selectedFilterOptions.keys())
    keys.forEach(key => {
      const [type, id] = key.split(LOCATION_SEPARATOR)
      const itemName = this.getItemNameByLocationTypeAndId(
        type as LocationType,
        id,
      )

      if (!itemName) return

      if (result[type]) {
        result[type].push(itemName)
      } else {
        result[type] = [itemName]
      }
    })

    return Object.keys(result).map((resultFilter, index) => {
      const icon = this.getIconBySpecificFilter(resultFilter)
      return (
        <div key={resultFilter} className="row">
          <div className="text large orange mr20 no-grow">
            {`${baseIndex + index + 1}.`}
          </div>
          <div className="filters-row row">
            {icon}
            {getLocationTypeDisplayName(resultFilter as LocationType)}
          </div>
          <div className="x-end row filters-date-details lp15 end lp15 row text line-26 filters-row">
            {result[resultFilter].join(', ')}
          </div>
        </div>
      )
    })
  }

  private getItemNameByLocationTypeAndId = (
    type: LocationType,
    id: string,
  ): string => {
    let store
    switch (type) {
      case LocationType.Building:
        store = this.props.locationAttributesStore.buildingsStore
        break
      case LocationType.Zone:
        store = this.props.locationAttributesStore.zonesStore
        break
      case LocationType.Route:
        store = this.props.locationAttributesStore.routesStore
        break
      case LocationType.Gate:
        store = this.props.locationAttributesStore.gatesStore
        break
      case LocationType.OffloadingEquipment:
        store = this.props.locationAttributesStore.offloadingEquipmentsStore
        break
      case LocationType.LogisticsObject:
        store = this.props.locationAttributesStore.logisticsObjectsStore
        break
      case LocationType.VerticalObject:
        store = this.props.locationAttributesStore.verticalObjectsStore
        break
      case LocationType.Level:
        store = this.props.locationAttributesStore.levelsStore
        break
      case LocationType.Area:
        store = this.props.locationAttributesStore.areasStore
        break
    }

    return (store && store?.byId.get(id)?.name) || NO_VALUE
  }

  private onGroupingChanged = () => {
    this.dynamicOverflowListStore.enableRecalculation()
    this.props.logisticsListStore.resetCollapsing()
  }
}
