import LocationBase from './LocationObjects/LocationBase'

export enum LogisticItemApp {
  FORM = 'Form',
  CLOSURE = 'Closure',
  SENSOR = 'Sensor',
  ANNOUNCEMENT = 'Announcement',
  DELIVERY = 'Delivery',
  SCHEDULE = 'Schedule',
}

export default interface ILogisticItem {
  id: string
  entityId: string
  app: LogisticItemApp
  name: string
  locations: LocationBase[]
  status: string
  workflowStepLevel?: number
  type?: string
  companyIds?: string[]
  responsibleContactIds?: string[]
  startDate?: number
  endDate?: number
  isStatusLate?: boolean
  isOpened?: boolean
}
