import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import CommonDatePicker from '../CommonDatePicker/CommonDatePicker'
import { getDefaultStartAndFinishDates } from '../CommonDatePicker/CommonDatePickerHelper'
import MonthPicker from '../CommonDatePicker/MonthPicker/MonthPicker'
import Dimmer from '../Dimmer'
import StruxhubInput from '../StruxhubInputs/StruxhubInput'

import './PermitDatePicker.scss'

interface IProps {
  initialStartDate: number
  initialEndDate: number
  shouldShowTimePicker: boolean
  applyDates: (startDate: Date, endDate: Date) => void
  hideDatePicker: () => void

  isOneDayMode?: boolean
  maxRangeInYears?: number

  projectDateStore?: ProjectDateStore
}

const selectDate = (isOneDayMode: boolean) =>
  isOneDayMode ? 'Select date' : 'Select dates'
const maxDatesRangeInYearsIs = (maxRange: number) =>
  `Maximum date range exceeded. The maximum date range in years is - ${maxRange}`

const select = 'Select'
const start = 'Start'
const finish = 'Finish'
const nonWorkDay = 'Non-work day'
const nonWorkHours = 'Non-work hours'

const DEFAULT_TIME = '00:00'

@inject('projectDateStore')
@observer
export default class PermitDatePicker extends React.Component<IProps> {
  @observable private startDate: Date = null
  @observable private endDate: Date = null
  @observable private startTime: string = DEFAULT_TIME
  @observable private endTime: string = DEFAULT_TIME
  @observable private selectedMonth: Date = null
  @observable private isMonthPickerShown: boolean = false

  public constructor(props: IProps) {
    super(props)

    this.setInitialDates()
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { initialStartDate, initialEndDate } = this.props

    if (
      prevProps.initialStartDate !== initialStartDate ||
      prevProps.initialEndDate !== initialEndDate
    ) {
      this.setInitialDates()
    }
  }

  public render() {
    const {
      hideDatePicker,
      projectDateStore: { getMonthAndYearToDisplay },
      isOneDayMode,
      maxRangeInYears,
    } = this.props

    return (
      <>
        <Dimmer shown={true} onClick={hideDatePicker} />
        <div className="permit-date-picker absolute bg-white no-select col full-width overflow-auto">
          <div className="row y-center pt15 px12">
            <div className="no-grow ml8" onClick={hideDatePicker}>
              <Icon icon={IconNames.CROSS} className="close-icon pointer" />
            </div>
            <div className="text bold small-header center mr30">
              {selectDate(isOneDayMode)}
            </div>
          </div>
          <div className="row px16 my20">
            <div>
              <div onClick={this.showMonthPicker}>
                <span className="text bold extra-large line-extra-large lp015 primary">
                  {getMonthAndYearToDisplay(this.selectedMonth)}
                </span>
                <Icon icon={IconNames.CARET_DOWN} />
              </div>
              <MonthPicker
                shown={this.isMonthPickerShown}
                value={this.selectedMonth}
                onHide={this.hideMonthPicker}
                onChange={this.updateCurrentMonth}
              />
            </div>
            <div className="row x-end no-flex-children">
              <Icons.ArrowBack
                onClick={this.prevMonth}
                className="pointer mr5"
              />
              <Icons.ArrowForward
                onClick={this.nextMonth}
                className="pointer mr5"
              />
            </div>
          </div>
          <CommonDatePicker
            monthDate={this.selectedMonth}
            dateClickHandler={this.onDateClick}
            startDate={this.startDate}
            endDate={this.endDate}
            isOneDayMode={isOneDayMode}
          />
          {!isOneDayMode && (
            <>
              {!this.isDatesRangeValid && (
                <div className="ml5 text bold red large py5 px16">
                  {maxDatesRangeInYearsIs(maxRangeInYears)}
                </div>
              )}
              <div className="bt-light-grey row y-center mt10 py10 px16">
                {this.renderDateAndTimeFields(
                  'br-palette-brand-lighter pr8',
                  start,
                  this.startDate,
                  this.startTime,
                  this.onStartTimeChange,
                )}
                {this.renderDateAndTimeFields(
                  'pl8',
                  finish,
                  this.endDate,
                  this.endTime,
                  this.onEndTimeChange,
                )}
              </div>
            </>
          )}
          <div className="px16 mt5">
            <div
              onClick={this.applyDates}
              className={classList({
                'h40 brada4 bg-palette-blue-scale row x-center y-center text white extra-large bold':
                  true,
                'inactive-element': !this.areDatesValid,
              })}
            >
              {select}
            </div>
          </div>
        </div>
      </>
    )
  }

  @action.bound
  public onDateClick(_: Event, date: Date) {
    this.setDates(date)
  }

  @action.bound
  private setDates(date: Date) {
    const newDates = getDefaultStartAndFinishDates(
      date,
      this.startDate,
      this.endDate,
      this.props.isOneDayMode,
    )
    this.startDate = newDates.startDate
    this.endDate = newDates.endDate
  }

  private renderDateAndTimeFields(
    className: string,
    caption: string,
    date: Date,
    time: string,
    onTimeChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  ): JSX.Element {
    const { shouldShowTimePicker, projectDateStore } = this.props
    const { getWeekdayMonthDayAndYearToDisplay } = projectDateStore

    return (
      <div className={`col ${className}`}>
        <div className="text faded">{caption.toUpperCase()}</div>
        <div className="blue-highlight text large bold pb10 pt5">
          {getWeekdayMonthDayAndYearToDisplay(date) || '-'}
        </div>
        {shouldShowTimePicker && (
          <>
            <StruxhubInput
              type="time"
              value={time}
              onChange={onTimeChange}
              isMinimalisticMode={true}
            />
            <div className="ml5 text red large h20">
              {this.getNonWorkLabel(date, time)}
            </div>
          </>
        )}
      </div>
    )
  }

  private get areDatesValid(): boolean {
    return !!this.startDate && !!this.endDate && this.isDatesRangeValid
  }

  @computed
  private get isDatesRangeValid(): boolean {
    const {
      maxRangeInYears,
      projectDateStore: { endOfDay, addYears },
      isOneDayMode,
    } = this.props

    if (isOneDayMode || !this.startDate || !this.endDate || !maxRangeInYears) {
      return true
    }

    return (
      endOfDay(this.endDate) <=
      endOfDay(addYears(this.startDate, maxRangeInYears, true))
    )
  }

  private applyDates = () => {
    if (!this.areDatesValid) {
      return
    }
    const { applyDates, hideDatePicker } = this.props
    this.startDate = this.getDateTime(this.startDate, this.startTime)
    this.endDate = this.getDateTime(this.endDate, this.endTime)
    applyDates(this.startDate, this.endDate)
    hideDatePicker()
  }

  private onStartTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.startTime = event.target.value
  }

  private onEndTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.endTime = event.target.value
  }

  private getDateTime = (date: Date, timeToSet: string): Date => {
    const { getDashedFormattedDate, combineISODateTime } =
      this.props.projectDateStore
    return combineISODateTime(getDashedFormattedDate(date), timeToSet)
  }

  private getNonWorkLabel = (date: Date, time: string): string => {
    if (!date || !time) {
      return null
    }

    const { areTimesInsideWorkingHours, isWorkingDay } =
      this.props.projectDateStore

    const dateTime = this.getDateTime(date, time)

    const isNonWorkDay = !isWorkingDay(dateTime)
    const isNonWorkTime = !areTimesInsideWorkingHours(dateTime, dateTime)

    return isNonWorkDay ? nonWorkDay : isNonWorkTime && nonWorkHours
  }

  @action.bound
  private setInitialDates() {
    const { initialStartDate, initialEndDate, projectDateStore } = this.props

    this.startDate = new Date(initialStartDate)
    this.startTime = projectDateStore.getISOTime(this.startDate)
    this.endDate = new Date(initialEndDate)
    this.endTime = projectDateStore.getISOTime(this.endDate)

    this.selectedMonth = new Date(initialStartDate)
  }

  @action.bound
  private showMonthPicker() {
    this.isMonthPickerShown = true
  }

  @action.bound
  private hideMonthPicker() {
    this.isMonthPickerShown = false
  }

  @action.bound
  private prevMonth() {
    this.updateCurrentMonth(
      this.props.projectDateStore.addMonths(this.selectedMonth, -1),
    )
  }

  @action.bound
  private nextMonth() {
    this.updateCurrentMonth(
      this.props.projectDateStore.addMonths(this.selectedMonth, 1),
    )
  }

  @action.bound
  private updateCurrentMonth(month: Date) {
    this.selectedMonth = month
  }
}
