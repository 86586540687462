export const VALID_EMAIL_PATTERN =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const EMAIL_EXTRACTOR = /([\w\d._+-]+@[\w\d._-]+\.[\w\d_-]{2,})/gim
export const PHONE_NUMBER_EXTRACTOR =
  /\+?(\(?\d{1,4}\)?[ -.]?){1,2}([ -.]?\d+)+( \w+ \d+)?/gm
export const NON_EMPTY_VALUE_PATTERN = /\S+.*/
export const VALID_YOUTUBE_VIDEO_PATTERN =
  /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/
export const VALID_VIMEO_VIDEO_PATTERN =
  /(http|https)?:\/\/(www\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^/]*)\/videos\/|)(\d+)(?:|\/\?)/
export const URL_TO_PDF_ON_STORAGE_PATTERN = /\/pdfs%2F/i
export const NO_DIGITS_AND_SPACES_IN_START_PATTERN = /^[0-9 ]+/
export const WITHOUT_SPECIAL_CHARACTERS_PATTERN = /[^a-zA-Z0-9]/g
export const WITHOUT_SPECIAL_CHARACTERS_EXCEPT_DOT_DASH_PATTERN =
  /[^a-zA-Z0-9.-]/g
export const DOMAIN_VALIDATOR_PATTERN =
  /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/
export const DIGITS_PATTERN = /\d+/
export const TAG_REG_EXP = /(?!\[)([^[\]].+?)(?=\])/g
