import { NOOP } from './noop'

const { entries, assign, keys } = Object
/*
  Reverses the keys and values in an object.
  Assumes that the object values are keys.
 */
export const reverseHash = o =>
  entries(o).reduce((a, n) => assign(a, { [n[1] as any]: n[0] }), {})

/*
  Squashes a value at second-level depth into the top level.
  Assumes the top-level values are objects.
 */
export const squash = (o, s) =>
  keys(o).reduce((a, n) => assign(a, { [n]: o[n][s] }), {})

export function reduceByCategories<T>(
  arr: T[],
  itemMapper: (item: T) => any[],
): { [key: string]: any[] } {
  return (arr || []).reduce((acc, i) => {
    const [category, ...value] = itemMapper(i)
    ;(acc[category] || (acc[category] = [])).push(...value)
    return acc
  }, {})
}

export function mapFiltered<T, K>(
  arr: K[],
  mapper: (item: K) => T,
  filter: (mappedItem: T) => boolean = item => !!item,
) {
  return (arr || []).reduce((res, item) => {
    const mappedItem = mapper(item)
    if (filter(mappedItem)) {
      res.push(mappedItem)
    }
    return res
  }, [])
}

export function removeDuplicatesFromArrayByProp(
  array: any[],
  propName: string,
) {
  const usedPropValues = {}
  return array.filter(item => {
    const key = item[propName]
    // eslint-disable-next-line no-prototype-builtins
    return usedPropValues.hasOwnProperty(key)
      ? false
      : (usedPropValues[key] = true)
  })
}

export function areArraysEqual(firstArray: any[], secondArray: any[]) {
  if (firstArray.length !== secondArray.length) {
    return false
  }
  return (
    firstArray.filter((el, index) => {
      return firstArray[index] === secondArray[index]
    }).length === firstArray.length
  )
}

export type Primitives = string | number | boolean | null | undefined
export function isPrimitive(value: any): value is Primitives {
  return (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'boolean' ||
    value === null ||
    value === undefined
  )
}

/**
 * Returns the values that are not the same in both arrays (order is irrelevant)
 * Equal arrays return an empty array
 * @param firstArray Only primitives are supported
 * @param secondArray Only primitives are supported
 */
export function diffArrays<T extends Primitives>(
  firstArray: T[],
  secondArray: T[],
): T[] {
  if (!firstArray && !secondArray) {
    return []
  }
  if (!firstArray) {
    return secondArray
  }
  if (!secondArray) {
    return firstArray
  }

  return firstArray
    .filter(x => !secondArray.includes(x))
    .concat(secondArray.filter(x => !firstArray.includes(x)))
}

export function areObjectArraysEqual(
  firstArray: Array<{ [key: string]: any }>,
  secondArray: Array<{ [key: string]: any }>,
) {
  if (firstArray.length !== secondArray.length) {
    return false
  }

  return firstArray.every((el, idx) => areObjectsEqual(el, secondArray[idx]))
}

export function areObjectsEqual(
  obj1: { [key: string]: any },
  obj2: { [key: string]: any },
) {
  const obj1Keys = Object.keys(obj1)
  if (obj1Keys.length !== Object.keys(obj2).length) {
    return false
  }

  return obj1Keys.every(key => obj1[key] === obj2[key])
}

export function copyObjectArray<T>(array: T[]): T[] {
  return array.map(el => copyObject<T>(el))
}

export function copyArray<T>(array: T[]): T[] {
  return [...array]
}

export function copyObject<T>(object: { [key: string]: any }): T {
  return Object.assign({}, object) as T
}

export function copyObjectDeep<T>(object: { [key: string]: any }): T {
  return JSON.parse(JSON.stringify(object))
}

export function isEmptyObj(obj: any): boolean {
  return Object.keys(obj).length < 1
}

export function customDebounce(func, delay: number) {
  let clearTimer
  return (...params): any => {
    clearTimeout(clearTimer)
    clearTimer = setTimeout(() => func.apply(this, params), delay)
  }
}

/** Calls passed function only the first time in a given timeframe */
export function leadingDebounce(func, delay: number) {
  let clearTimer
  return (...params): any => {
    if (!clearTimer) {
      func.apply(this, params)
      clearTimer = setTimeout(() => {
        clearTimeout(clearTimer)
        clearTimer = undefined
      }, delay)
    }
  }
}

export function readFileAsTextAsync(file: File): Promise<string> {
  return new Promise(resolve => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as string)
    reader.readAsText(file)
  })
}

export function downloadFile(
  filename: string,
  fileUrl: string,
  customAttrs: Record<string, string> = {},
) {
  const a = Object.assign(document.createElement('a'), {
    download: filename,
    href: fileUrl,
    ...customAttrs,
  })

  a.click() // force download
}

export function range(
  count: number,
  itemCallback: (num: number) => any = NOOP,
) {
  return Array.from(Array(count).keys()).map(itemCallback)
}

export function getPercentCompleteFromRange(
  minPerc: number,
  maxPerc: number,
  currentItemsNum: number,
  maxItemNum: number,
): number {
  const percentComplete = (100 * currentItemsNum) / maxItemNum
  return (percentComplete * (maxPerc - minPerc)) / 100 + minPerc
}

export function getPropertyName<T = unknown>(
  expression: (instance: T) => any,
  options?: {
    isDeep: boolean
  },
): string {
  let propertyThatWasAccessed = ''
  const proxy: any = new Proxy({} as any, {
    get: function (_: any, prop: any) {
      if (options?.isDeep) {
        if (propertyThatWasAccessed) propertyThatWasAccessed += '.'

        propertyThatWasAccessed += prop
      } else {
        propertyThatWasAccessed = prop
      }
      return proxy
    },
  })
  expression(proxy)

  return propertyThatWasAccessed
}

/**
 * Returns a number in fixed-point notation if it's not Integer.
 * @param num Number
 * @param fractionDigits Number of digits after the decimal point. Default is 2.
 * Must be in the range 0 - 20, inclusive.
 */
export function getFixedNum(num: number, fractionDigits: number = 2): number {
  return Number.isInteger(num) ? num : parseFloat(num.toFixed(fractionDigits))
}

/**
 * Makes the browser beep. Requires speakers, duh.
 * @param frequencyHz Defaults to 800Hz
 * @param durationMs Defaults to 100ms
 */
export function beep(
  frequencyHz = 800,
  durationMs = 100,
  waveType: 'triangle' | 'sine' | 'square' | 'sawtooth' = 'sine',
) {
  const context = new AudioContext()
  const oscillator = context.createOscillator()
  oscillator.type = waveType
  oscillator.frequency.value = frequencyHz
  oscillator.connect(context.destination)

  // Beep
  oscillator.start()

  // Wait and stop
  setTimeout(function () {
    oscillator.stop()
  }, durationMs)
}

export function isNotificationSupportedInEnv(): boolean {
  return typeof Notification != 'undefined'
}
