import { action, observable } from 'mobx'

import { IPermitTypeField } from '~/client/graph'
import { MAX_SELECT_OPTIONS_COUNT } from '~/client/src/shared/constants/permitTypeFieldsConstants'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { copyObject } from '~/client/src/shared/utils/util'

export enum FieldState {
  Mandatory = 'mandatory',
  Optional = 'optional',
  Hidden = 'hidden',
}

export function getFieldStateTranslate(value: FieldState) {
  switch (value) {
    case FieldState.Hidden:
      return Localization.translator.fieldStates.hidden
    case FieldState.Mandatory:
      return Localization.translator.fieldStates.mandatory
    case FieldState.Optional:
      return Localization.translator.fieldStates.optional
    default:
      throw new Error(value + ' is unhandled')
  }
}

type FieldSettings = { isMandatory?: boolean; isShown?: boolean }

export default class FieldConfiguratorStore {
  public static getStateFromSettings({
    isMandatory,
    isShown,
  }: FieldSettings): FieldState {
    switch (true) {
      case isMandatory:
        return FieldState.Mandatory
      case isShown:
        return FieldState.Optional

      default:
        return FieldState.Hidden
    }
  }

  public static getSettingsFromState(fieldState: FieldState): FieldSettings {
    let isMandatory = false
    let isShown = false

    switch (fieldState) {
      case FieldState.Mandatory:
        isMandatory = true
      // eslint-disable-next-line no-fallthrough
      case FieldState.Optional:
        isShown = true
    }

    return { isShown, isMandatory }
  }

  @observable public field: IPermitTypeField = null
  @observable public newSelectOption: string = ''

  public constructor(
    field: IPermitTypeField,
    private onChange: (field: IPermitTypeField) => void,
  ) {
    this.setField(field)
  }

  public get fieldState(): FieldState {
    return FieldConfiguratorStore.getStateFromSettings(this.field)
  }

  public get isAddSelectOptionShown(): boolean {
    return (this.field.selectOptions || []).length < MAX_SELECT_OPTIONS_COUNT
  }

  @action.bound
  public setField(field: IPermitTypeField) {
    this.field = copyObject(field)
    this.updateSelectOption('')
  }

  @action.bound
  public setOnChangeHandler(onChange: (field: IPermitTypeField) => void) {
    this.onChange = onChange
  }

  @action.bound
  public changeFieldState(newFieldState: FieldState) {
    if (this.field.canEditIsMandatory || this.field.canEditIsShown) {
      this.onChange(
        Object.assign(
          this.field,
          FieldConfiguratorStore.getSettingsFromState(newFieldState),
        ),
      )
    }
  }

  @action.bound
  public updateCaption(newCaption: string) {
    if (!newCaption || this.field.caption === newCaption) {
      return
    }

    this.field.caption = newCaption

    this.onChange(this.field)
  }

  @action.bound
  public updateSelectOption(newValue: string) {
    this.newSelectOption = newValue
  }

  @action.bound
  public addNewSelectOption() {
    if (!this.isAddSelectOptionShown || !this.newSelectOption?.trim()) {
      return
    }

    if (!this.field.selectOptions) {
      this.field.selectOptions = []
    }

    const trimmedValue = this.newSelectOption.trim()

    if (!this.field.selectOptions.includes(trimmedValue)) {
      this.field.selectOptions.push(trimmedValue)

      this.onChange(this.field)
    }
  }

  @action.bound
  public removeSelectOption(index: number) {
    this.field.selectOptions?.splice(index, 1)
    this.onChange(this.field)
  }

  @action.bound
  public toggleIsMultipleValue() {
    if (this.field.canEditIsMandatory || this.field.canEditIsShown) {
      this.field.isMultiple = !this.field.isMultiple

      this.onChange(this.field)
    }
  }
}
