const EXIF_ROTATION_TRANSFORMS = {
  '-1': 'rotate(0deg)',
  '-2': 'rotate(0deg)',
  1: 'rotate(0deg)',
  3: 'rotate(180deg)',
  6: 'rotate(90deg)',
  8: 'rotate(270deg)',
}

const EXIF_ROTATION_DEGREES = {
  '-1': 0,
  '-2': 0,
  1: 0,
  3: 180,
  6: 90,
  8: 270,
}

const START_OF_JPEG_IMAGE = 0xffd8
const EXIF_MARKER = 0xffe1
const EXIF_HEADER = 0x45786966
const ORIENTATION_TAG = 0x0112
const INTEL_BYTE_ALIGN = 0x4949

export function readOrientation(
  file: any,
  callback: (rv: any) => void,
  inDegrees: boolean,
) {
  let cbPromise
  if (!callback) {
    let resolve
    cbPromise = new Promise(r => (resolve = r))
    callback = resolveValue => resolve(resolveValue)
  }

  const reader = new FileReader()
  // This is a but dimwitted, but leaving for now
  const mapper = inDegrees ? EXIF_ROTATION_DEGREES : EXIF_ROTATION_TRANSFORMS
  reader.readAsArrayBuffer(file)
  reader.onload = e => {
    if (hasNativeOrientation()) {
      return callback(mapper[-2])
    }

    const view = new DataView(e.target.result as ArrayBuffer)
    if (view.getUint16(0, false) !== START_OF_JPEG_IMAGE) {
      return callback(mapper[-2])
    }

    const length = view.byteLength
    let offset = 2
    while (offset < length) {
      const marker = view.getUint16(offset, false)
      offset += 2
      if (marker === EXIF_MARKER) {
        if (view.getUint32((offset += 2), false) !== EXIF_HEADER) {
          return callback(mapper[-1])
        }

        const little = view.getUint16((offset += 6), false) === INTEL_BYTE_ALIGN
        offset += view.getUint32(offset + 4, little)
        const tags = view.getUint16(offset, little)
        offset += 2
        for (let i = 0; i < tags; i++) {
          if (view.getUint16(offset + i * 12, little) === ORIENTATION_TAG) {
            return callback(mapper[view.getUint16(offset + i * 12 + 8, little)])
          }
        }
      } else if ((marker & 0xff00) !== 0xff00) {
        break
      } else {
        offset += view.getUint16(offset, false)
      }
    }

    return callback(mapper[-1])
  }

  if (cbPromise) {
    return cbPromise
  }
}

export function blobFromUrl(url: string) {
  let resolve
  const blobPromise = new Promise(r => {
    resolve = r
  })
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url)
  xhr.responseType = 'blob'
  xhr.onload = () => resolve(xhr.response)
  xhr.send()
  return blobPromise
}

export function rotateImage(
  file: any,
  degrees: number,
  desiredWidth?: number,
): Promise<Blob> {
  const source = new Image()
  const URL = window.URL.createObjectURL(file)
  source.src = URL

  return new Promise(resolve => {
    source.onload = () => {
      const widthHeightFlipped = degrees % 180 === 90
      if (!desiredWidth) {
        desiredWidth = widthHeightFlipped ? source.height : source.width
      }

      const scaledWidth = desiredWidth
      let scaledHeight = Math.floor(
        (scaledWidth / +source.width) * +source.height,
      )

      if (widthHeightFlipped) {
        scaledHeight = Math.floor(
          (scaledWidth / +source.height) * +source.width,
        )
      }

      const dest = document.createElement('canvas')
      dest.width = scaledWidth
      dest.height = scaledHeight
      const context = dest.getContext('2d')
      context.imageSmoothingEnabled = false
      context.save()

      if (widthHeightFlipped) {
        if (degrees === 90) {
          context.translate(scaledWidth, scaledHeight / scaledWidth)
        } else if (degrees === 270) {
          context.translate(scaledWidth / scaledHeight, scaledHeight)
        }

        context.rotate((Math.PI / 180) * degrees)
        context.drawImage(source, 0, 0, scaledHeight, scaledWidth)
      } else {
        context.translate(scaledWidth / 2, scaledHeight / 2)
        context.rotate((Math.PI / 180) * degrees)
        context.drawImage(
          source,
          -scaledWidth / 2,
          -scaledHeight / 2,
          scaledWidth,
          scaledHeight,
        )
      }

      context.restore()
      dest.toBlob(blob => resolve(blob), file.type)
    }
  })
}

export function hasNativeOrientation() {
  return window.CSS.supports('image-orientation', 'from-image')
}
