import { action } from 'mobx'

import * as e from '~/client/src/shared/stores/EventStore/eventConstants'

import commonRoutes, { getTransitionPath } from '../../constants/commonRoutes'
import { NOOP } from '../../utils/noop'
import InitialState from '../InitialState'
import RootStore from '../Root.store'

interface IDisplayViewParams {
  state?: any
  viewMode?: string
  action?: string
  id?: string
}

// TODO: REVISIT IT

// This sometimes doubles as both a UI store and a dump for unsorted actions
export default abstract class CommonStore {
  public navigationListeners = []
  public history = {
    location: { pathname: '', search: '', state: {}, hash: '', key: undefined },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    push: (route: string, state?: any) => null,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    replace: (route: string) => null,
    goBack: NOOP,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    listen: callback => {
      throw new Error('History not yet initialized.')
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    go: (n: number) => null,
  }

  // This will feel better as a function
  public awaitTransition = Promise.resolve()

  public constructor(
    protected readonly state: InitialState,
    protected readonly root: RootStore,
  ) {}

  public get selectedActivity() {
    return this.root.activitiesStore.selectedActivity
  }

  public initHistory(history) {
    this.history = history
    if (this.state.initialRoute) {
      this.history.push(this.state.initialRoute)
    }
  }

  public hideNavBar = NOOP

  public listenToNavigation(callback) {
    this.navigationListeners.push(callback)
  }

  public _displayView(route: string, params: IDisplayViewParams = {}) {
    this.root.eventsStore.dispatch(e.COLLECT_TELEMETRY, {
      event: {
        eventCategory: 'PageView',
        eventAction: 'Change',
        eventLabel: window.location.pathname.split('/')[2],
        eventValue: 1,
      },
    })
    if (!this.history) {
      return
    }

    const transitionPath = getTransitionPath(
      route,
      this.state.activeOrInitProjectCode,
    )

    const { state, viewMode, ...queryParams } = params
    const queryString = Object.entries(queryParams)
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      )
      .join('&')
    const transitionRoute = `${transitionPath}${
      queryString ? '?' + queryString : ''
    }${viewMode ? '#' + viewMode : ''}`

    const { pathname, search, hash } = this.history.location
    const currentRoute = pathname + search + hash

    if (currentRoute !== transitionRoute) {
      // Force a URL change to trigger a re-render. Must be before the push.
      if (viewMode && `#${viewMode}` !== hash) {
        window.location.hash = viewMode
      }

      this.history.push(transitionRoute, state)

      // Tells listeners that the current view is changing
      this.navigationListeners.forEach(callback => {
        callback(transitionRoute)
      })
    }
  }

  public displayDefaultView = () => {
    this._displayView('/')
  }

  public displayLoginView = () => {
    this._displayView(commonRoutes.LOGIN)
  }

  public displaySavePasswordView = () => {
    this._displayView(commonRoutes.SAVE_PASSWORD)
  }

  public displayInfoPage = (errorCode, payload) => {
    this._displayView(
      commonRoutes.INFO.replace(':code?', errorCode).replace(
        ':payload?',
        payload,
      ),
    )
  }

  public displayResetPasswordView(resetCode = '', state?: any) {
    this._displayView(
      commonRoutes.RESET_PASSWORD.replace(':resetCode', resetCode),
      { state },
    )
  }

  public displayHomeView = (params?: IDisplayViewParams) => {
    this._displayView(commonRoutes.HOME, params)
  }

  public displayFormsView = () => {
    this._displayView(commonRoutes.FORMS)
  }

  public displayFormTypesView = () => {
    this._displayView(commonRoutes.FORM_TYPES)
  }

  public toActivityDetails = () => {
    this.selectedActivity
      ? this.displayActivityDetailsView(this.selectedActivity.id)
      : this.displayActivityList()
  }

  public abstract displayDeliveriesView(viewMode?: string): void

  public abstract displayDeliveryDetailsView(id?: string): void

  public abstract openDeliveryView(): void

  public abstract activityDetailsBackClicked(): void

  public abstract displayActivityDetailsView(activityP6Code: string)

  public abstract displayActivityList(): void

  public abstract displayBulkStatusUpdateView(activity, company): void

  public abstract displayFlagView(flag): void

  public abstract displayScheduleCommentView(scheduleComment): void

  public abstract displayRfiView(rfi): void

  public abstract displayCategoryOfVarianceView(categoryOfVariance): void

  public abstract displaySafetyHazardView(safetyHazard): void

  public abstract displayStatusUpdateMessagesView(statusUpdate): void

  public abstract displayPhotoView(photo): void

  public abstract displayNotifications(): void

  // public abstract displayNotificationDetails(notification): void

  public abstract displayAuthSuccessRoute(isVisitorAuth?: boolean): void

  public abstract openChat(): void

  @action.bound
  public handleOpeningChatWithUser(userId: string) {
    this.state.resetIdOfUserDisplayedOnGlobalCard()
    this.state.setRecipientIdForChatInit(userId)
    this.openChat()
  }

  public abstract get defaultAvailablePage(): string
}
