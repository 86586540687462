import Localization from '../localization/LocalizationManager'
import { enumToList } from '../utils/converters'

enum CommonFilterType {
  Status = 'status',
  Location = 'location',
  Company = 'company',
  ResponsibleContact = 'responsibleContact',
}

enum HUBSpecificFilterType {
  App = 'app',
}

enum FormsSpecificFilterType {
  Type = 'type',
}

export type LogisticsFilterType =
  | CommonFilterType
  | FormsSpecificFilterType
  | HUBSpecificFilterType
export const LogisticsFilterType = {
  ...CommonFilterType,
  ...FormsSpecificFilterType,
  ...HUBSpecificFilterType,
}

export const FormsFilterType = {
  ...CommonFilterType,
  ...FormsSpecificFilterType,
}

export const HUBFilterType = {
  ...CommonFilterType,
  ...HUBSpecificFilterType,
}

export const getLogisticsFilterTypeCaption = (type: LogisticsFilterType) => {
  switch (type) {
    case LogisticsFilterType.Status:
      return Localization.translator.status
    case LogisticsFilterType.Location:
      return Localization.translator.location
    case LogisticsFilterType.Company:
      return Localization.translator.company
    case LogisticsFilterType.Type:
      return Localization.translator.type
    case LogisticsFilterType.App:
      return Localization.translator.app
    case LogisticsFilterType.ResponsibleContact:
      return Localization.translator.responsibleContact
    default:
      throw new Error(type + ' is unhandled')
  }
}

export const hubFilterTypes = enumToList(HUBFilterType)
export const formsFilterTypes = enumToList(FormsFilterType)
