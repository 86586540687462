import { getDescendants } from '@minoru/react-dnd-treeview'
import { action, computed, observable } from 'mobx'

import {
  IAttachment,
  IChecklistAnswerItem,
  IConditionalField,
  IPermitTypeField,
  IWorkflowStep,
  PermitFieldType,
} from '~/client/graph'
import { conditionalPermitFields } from '~/client/src/shared/constants/PermitFieldTypeConstants'
import {
  FORM_MATERIAL_FIELDS_KEY,
  MAX_CONDITIONAL_DEPTH_LEVEL,
  PERMIT_TABLE_FIELDS_KEY,
} from '~/client/src/shared/constants/permitTypeFieldsConstants'
import PermitChecklistConditionalKey from '~/client/src/shared/enums/PermitChecklistConditionalKey'
import QuestionnaireType from '~/client/src/shared/enums/QuestionnaireType'
import IPermitFieldByIdMapEntity from '~/client/src/shared/models/IPermitFieldByIdMapEntity'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import PermitField from '~/client/src/shared/models/PermitField'
import { getQuestionnaireOptionsByType } from '~/client/src/shared/utils/PermitInstructionsHelper'

import InitialState from '../../InitialState'

export interface IPermitFormField {
  id: string
  isValid: boolean
  isChanged?: boolean
  isRequired?: boolean
}

const getFieldValidationId = (
  id: string,
  index: number,
  tableRowIndex?: number,
) =>
  `${id}_${index || 0}${
    Number.isFinite(tableRowIndex) ? `_${tableRowIndex}` : ''
  }`

export default abstract class PermitFieldsBaseStore
  implements IPermitFieldsStore
{
  protected readonly hiddenFieldsState = observable(new Map<string, string[]>())
  protected readonly invalidFieldsState = observable(new Set<string>())

  public abstract get fields(): IPermitFormField[]
  public abstract get previousStepsFields(): IPermitFormField[]
  protected abstract get canIgnoreValidation(): boolean
  protected abstract get fieldIdsToIgnore(): string[]
  protected abstract get workflowSteps(): IWorkflowStep[]
  public abstract get editableEntity(): IPermitFieldByIdMapEntity
  public abstract get existingEntity(): IPermitFieldByIdMapEntity

  public constructor(private readonly state: InitialState) {}

  @computed
  public get availableWorkflowSteps(): IWorkflowStep[] {
    return this.workflowSteps.reduce((list, step) => {
      const filteredStep: IWorkflowStep = {
        id: step.id,
        type: step.type,
        fields: step.fields.filter(f => f.isShown && !this.isFieldHidden(f.id)),
        conditionalFields: step.conditionalFields,
        workflowRuleIds: [],
      }

      list.push(filteredStep)

      return list
    }, [] as IWorkflowStep[])
  }

  @computed
  public get areRequiredFieldsChanged(): boolean {
    if (!this.existingEntity || !this.editableEntity) {
      return false
    }

    return this.previousStepsFields?.some(
      ({ isChanged, isRequired }) => isChanged && isRequired,
    )
  }

  @computed
  public get areOptionalFieldsChanged(): boolean {
    if (!this.existingEntity || !this.editableEntity) {
      return false
    }

    return this.previousStepsFields?.some(
      ({ isChanged, isRequired }) => isChanged && !isRequired,
    )
  }

  @computed
  public get areAllFieldsValid(): boolean {
    return this.fields?.every(({ isValid }) => isValid)
  }

  @computed
  public get areChangedFieldsValid(): boolean {
    return this.previousStepsFields?.every(({ isValid }) => isValid)
  }

  @computed
  private get allHiddenFieldIds(): string[] {
    const fieldIds: string[] = []
    this.hiddenFieldsState.forEach(ids => fieldIds.push(...ids))
    return fieldIds
  }

  @computed
  private get conditionalFields(): IConditionalField[] {
    return this.availableWorkflowSteps.flatMap(s => s.conditionalFields) || []
  }

  public getFieldValues = <T>(
    typeFieldId: string,
    tableId?: string,
    tableRowIndex?: number,
    isExistingEntity?: boolean,
  ): T[] => {
    if (tableId) {
      return this.getTableFieldValues<T>(
        tableId,
        typeFieldId,
        tableRowIndex,
        isExistingEntity,
      )
    }

    const entity = isExistingEntity ? this.existingEntity : this.editableEntity
    return entity?.fieldsByIdMap[typeFieldId]?.values || []
  }

  public getAvailableConditionalFields = (
    typeField: IPermitTypeField,
    depthLevel: number = 0,
  ): IPermitTypeField[] => {
    if (depthLevel === MAX_CONDITIONAL_DEPTH_LEVEL) {
      return []
    }

    const availableConditionals: IPermitTypeField[] = []
    const conditionalsByField = this.getConditionalFields(typeField) || []

    conditionalsByField.forEach(field =>
      availableConditionals.push(
        field,
        ...this.getAvailableConditionalFields(field, depthLevel + 1),
      ),
    )

    return availableConditionals.filter(f => f.isShown)
  }

  public getAnswerByChecklistItemId = (
    typeField: IPermitTypeField,
    checklistItemId: string,
  ): IChecklistAnswerItem => {
    return this.getFieldValues<IChecklistAnswerItem>(typeField.id).find(
      item => item.checklistItemId === checklistItemId,
    )
  }

  public isFieldChanged = (
    fieldId: string,
    index?: number,
    tableId?: string,
    tableRowIndex?: number,
  ): boolean => {
    if (!this.existingEntity?.id) {
      return false
    }

    const idsToCheck = [fieldId, tableId]
    if (this.fieldIdsToIgnore.some(id => idsToCheck.includes(id))) {
      return false
    }

    const existingValues = this.getFieldValues<any>(
      fieldId,
      tableId,
      tableRowIndex,
      true,
    )
    const values = this.getFieldValues<any>(fieldId, tableId, tableRowIndex)

    if (
      (tableId && !Number.isFinite(tableRowIndex)) ||
      !Number.isFinite(index)
    ) {
      return JSON.stringify(existingValues) !== JSON.stringify(values)
    }

    return (
      JSON.stringify(existingValues[index]) !== JSON.stringify(values[index])
    )
  }

  public isFieldValid = (
    typeField: IPermitTypeField,
    index?: number,
    tableId?: string,
    tableRowIndex?: number,
  ): boolean => {
    if (this.hasInvalidState(typeField.id, index, tableRowIndex)) {
      return false
    }
    if (
      !typeField.isMandatory ||
      this.isAdminOrFormsMaster ||
      this.isValidationIgnored(typeField.id, index, tableId, tableRowIndex)
    ) {
      return true
    }

    if (tableId && !Number.isFinite(tableRowIndex)) {
      const tableRows = this.getTableRowsValues(tableId, typeField.id)
      return (
        tableRows.length &&
        tableRows.every(
          row =>
            row.length &&
            row.every(({ values }) =>
              this.areFieldValuesValid(typeField, values, index),
            ),
        )
      )
    }

    const fieldValues = this.getFieldValues<any>(
      typeField.id,
      tableId,
      tableRowIndex,
    )

    return this.areFieldValuesValid(typeField, fieldValues, index)
  }

  @action.bound
  public addAttachmentValue(
    attachment: IAttachment,
    typeField: IPermitTypeField,
  ) {
    const field = this.editableEntity.fieldsByIdMap[typeField.id]

    if (!field) {
      return this.changeFieldValue(typeField, 0, attachment)
    }

    field.values.push(attachment)
  }

  @action.bound
  public addNewFieldValue<T>(typeField: IPermitTypeField, initialValue: T) {
    if (!typeField || !this.editableEntity) {
      return
    }

    const { id, type } = typeField
    const { fieldsByIdMap, fields } = this.editableEntity
    const field = fieldsByIdMap[id]

    if (!field) {
      fields.push({
        fieldId: id,
        type,
        values: [initialValue, initialValue],
      })
      return
    }

    if (!field.values.length) {
      field.values.push(initialValue, initialValue)
    } else {
      field.values.push(initialValue)
    }
  }

  @action.bound
  public removeFieldValue(fieldId: string, index: number) {
    if (!fieldId || !this.editableEntity) {
      return
    }

    const field = this.editableEntity.fieldsByIdMap[fieldId]

    if (field?.values) {
      field.values.splice(index, 1)
    }
  }

  @action.bound
  public changeTableFieldValue<T>(
    tableId: string,
    rowIndex: number,
    typeField: IPermitTypeField,
    fieldIndex: number,
    newValue: T,
  ) {
    const { fieldsByIdMap, fields } = this.editableEntity
    const field = fieldsByIdMap[tableId]

    if (!field) {
      const tableField: PermitField = {
        fieldId: typeField.id,
        type: typeField.type,
        values: [newValue],
      }

      fields.push({
        fieldId: tableId,
        type: PermitFieldType.Table,
        values: [[tableField]],
      })
      return
    }

    const tableField = (field.values as PermitField[][])[rowIndex]?.find(
      f => f.fieldId === typeField.id,
    )

    if (!tableField) {
      field.values[rowIndex].push({
        fieldId: typeField.id,
        type: typeField.type,
        values: [newValue],
      })
      return
    }

    tableField.values[fieldIndex] = newValue
  }

  @action.bound
  public changeFieldValue<T>(
    typeField: IPermitTypeField,
    fieldIndex: number,
    newValue: T,
  ) {
    const { fieldsByIdMap, fields } = this.editableEntity
    const field = fieldsByIdMap[typeField.id]

    if (!field) {
      fields.push({
        fieldId: typeField.id,
        type: typeField.type,
        values: [newValue],
      })
      return
    }

    field.values[fieldIndex] = newValue
  }

  @action.bound
  public changeMultiSelectorValue(
    typeField: IPermitTypeField,
    newValue: string,
  ) {
    const field = this.editableEntity.fieldsByIdMap[typeField.id]

    if (!field) {
      return this.changeFieldValue(typeField, 0, newValue)
    }
    if (!newValue) {
      field.values = []
      return
    }

    const index = field.values.findIndex(v => v === newValue)

    if (index === -1) {
      field.values.push(newValue)
    } else {
      field.values = field.values.filter(v => v !== newValue)
    }
  }

  @action.bound
  public setChecklistAnswer(
    typeField: IPermitTypeField,
    checklistItemId: string,
    answer: boolean,
  ) {
    const field = this.editableEntity.fieldsByIdMap[typeField.id]

    if (!field) {
      return this.changeFieldValue(typeField, 0, {
        checklistItemId,
        answer,
      } as IChecklistAnswerItem)
    }

    const aItem = this.getAnswerByChecklistItemId(typeField, checklistItemId)
    if (aItem) {
      aItem.answer = answer
    } else {
      field.values.push({ checklistItemId, answer })
    }
  }

  @action.bound
  public changeValidationState(
    fieldId: string,
    fieldIndex: number,
    isValid: boolean,
    tableRowIndex?: number,
  ) {
    const fieldValidationId = getFieldValidationId(
      fieldId,
      fieldIndex,
      tableRowIndex,
    )

    if (isValid) {
      this.invalidFieldsState.delete(fieldValidationId)
    } else {
      this.invalidFieldsState.add(fieldValidationId)
    }
  }

  @action.bound
  public rearrangeInvalidState(fieldId: string, valueIndex: number) {
    if (!fieldId || !this.editableEntity) {
      return
    }

    this.changeValidationState(fieldId, valueIndex, true)

    this.getFieldValues<any>(fieldId).forEach((_, idx) => {
      if (idx <= valueIndex) {
        return
      }
      const existingValId = getFieldValidationId(fieldId, idx)
      if (this.invalidFieldsState.has(existingValId)) {
        this.invalidFieldsState.delete(existingValId)
        this.invalidFieldsState.add(getFieldValidationId(fieldId, idx - 1))
      }
    })
  }

  @action.bound
  public resetValidationState() {
    this.invalidFieldsState.clear()
  }

  @action.bound
  public toggleSection(id: string) {
    if (this.hiddenFieldsState.has(id)) {
      this.hiddenFieldsState.delete(id)
    } else {
      const idsToHide = getDescendants<IPermitTypeField>(
        this.availableWorkflowSteps
          .flatMap(s => s.fields)
          .map(f => ({
            id: f.id,
            parent: f.parentId,
            text: '',
          })) || [],
        id,
      ).map(node => node.id.toString())

      this.hiddenFieldsState.set(id, idsToHide)
    }
  }

  @action.bound
  public toggleStepSection(id: string) {
    if (this.hiddenFieldsState.has(id)) {
      this.hiddenFieldsState.delete(id)
    } else {
      this.hiddenFieldsState.set(id, [])
    }
  }

  @action.bound
  public expandAllSections() {
    this.hiddenFieldsState.clear()
  }

  public isFieldHidden = (id: string): boolean => {
    return this.allHiddenFieldIds.some(i => i === id)
  }

  public isSectionHidden = (id: string): boolean => {
    return this.hiddenFieldsState.has(id)
  }

  public getMaterialSubFields = (
    materialFieldId: string,
  ): IPermitTypeField[] => {
    return (
      this.conditionalFields
        .find(
          ({ fieldId, key }) =>
            fieldId === materialFieldId && key === FORM_MATERIAL_FIELDS_KEY,
        )
        ?.values?.filter(f => f.isShown) || []
    )
  }

  public getTableFields = (tableId: string): IPermitTypeField[] => {
    return (
      this.conditionalFields
        .find(
          ({ fieldId, key }) =>
            fieldId === tableId && key === PERMIT_TABLE_FIELDS_KEY,
        )
        ?.values?.filter(f => f.isShown) || []
    )
  }

  protected getFormFieldModel = (
    typeField: IPermitTypeField,
  ): IPermitFormField => {
    const isTable = typeField.type === PermitFieldType.Table
    return {
      id: typeField.id,
      isRequired: typeField.isMandatory,
      isChanged: !isTable && this.isFieldChanged(typeField.id),
      isValid: isTable ? true : this.isFieldValid(typeField),
    }
  }

  protected getTableFieldModels(
    tableField: IPermitTypeField,
  ): IPermitFormField[] {
    if (tableField.type !== PermitFieldType.Table) {
      return []
    }

    return this.getTableFields(tableField.id).map(field => ({
      id: field.id,
      isRequired: field.isMandatory,
      isChanged: this.isFieldChanged(field.id, null, tableField.id),
      isValid: this.isFieldValid(field, null, tableField.id),
    }))
  }

  private getConditionalFields(
    typeField: IPermitTypeField,
  ): IPermitTypeField[] {
    if (
      !this.editableEntity ||
      typeField.isMultiple ||
      !conditionalPermitFields.includes(typeField.type) ||
      !this.conditionalFields?.length
    ) {
      return
    }

    const conditionalFieldsByField = this.conditionalFields.filter(
      cf => cf.fieldId === typeField.id,
    )

    if (!conditionalFieldsByField.length) {
      return
    }

    const values = this.getFieldValues<any>(typeField.id)

    if (typeField.type === PermitFieldType.Question) {
      return this.getConditionalsForQuestion(
        typeField,
        values,
        conditionalFieldsByField,
      )
    }
    if (typeField.type === PermitFieldType.Checklist) {
      return this.getConditionalsForChecklist(
        typeField,
        values,
        conditionalFieldsByField,
      )
    }

    return conditionalFieldsByField.find(cf => cf.key === values?.[0])?.values
  }

  private getConditionalsForQuestion(
    typeField: IPermitTypeField,
    permitFieldValues: IChecklistAnswerItem[],
    conditionals: IConditionalField[],
  ): IPermitTypeField[] {
    const key = getQuestionnaireOptionsByType(
      typeField.checklist.list?.[0]?.questionnaireType as QuestionnaireType,
    ).find(opt => opt.value === permitFieldValues?.[0]?.answer)?.key

    return conditionals.find(cf => cf.key === key)?.values
  }

  private getConditionalsForChecklist(
    typeField: IPermitTypeField,
    permitFieldValues: IChecklistAnswerItem[],
    conditionals: IConditionalField[],
  ): IPermitTypeField[] {
    const isFullyChecked = typeField.checklist.list.every(
      cItem =>
        permitFieldValues?.find(v => v.checklistItemId === cItem.id)?.answer !==
        undefined,
    )

    if (!isFullyChecked) {
      return []
    }

    const areYesAnswerSelected = typeField.checklist.list.every(
      cItem =>
        !!permitFieldValues?.find(v => v.checklistItemId === cItem.id)?.answer,
    )
    const areNoOrNaAnswerSelected = typeField.checklist.list.every(cItem => {
      const item = permitFieldValues?.find(v => v.checklistItemId === cItem.id)
      if (!item) {
        return false
      }
      return item.answer === null || item.answer === false
    })

    if (areYesAnswerSelected) {
      return conditionals.find(
        cf => cf.key === PermitChecklistConditionalKey.AllYes,
      )?.values
    }

    if (areNoOrNaAnswerSelected) {
      return conditionals.find(
        cf => cf.key === PermitChecklistConditionalKey.AllNo,
      )?.values
    }

    return []
  }

  private getTableFieldValues<T>(
    tableId: string,
    fieldId: string,
    tableRowIndex?: number,
    isExistingEntity?: boolean,
  ): T[] {
    const entity = isExistingEntity ? this.existingEntity : this.editableEntity
    const tableFieldValues: PermitField[][] =
      entity.fieldsByIdMap[tableId]?.values || []

    return Number.isFinite(tableRowIndex)
      ? tableFieldValues[tableRowIndex]?.find(f => f.fieldId === fieldId)
          ?.values || []
      : tableFieldValues.flatMap(f =>
          f.filter(fld => fld.fieldId === fieldId).flatMap(tf => tf.values),
        )
  }

  private getTableRowsValues(
    tableId: string,
    fieldId: string,
    isExistingEntity?: boolean,
  ): PermitField[][] {
    const entity = isExistingEntity ? this.existingEntity : this.editableEntity
    const tableFieldValues: PermitField[][] =
      entity.fieldsByIdMap[tableId]?.values || []

    return tableFieldValues.map(f => f.filter(fld => fld.fieldId === fieldId))
  }

  private hasInvalidState(
    fieldId: string,
    index?: number,
    tableRowIndex?: number,
  ): boolean {
    if (Number.isFinite(index)) {
      return this.invalidFieldsState.has(
        getFieldValidationId(fieldId, index, tableRowIndex),
      )
    }

    return Array.from(this.invalidFieldsState.keys()).some(id =>
      id.includes(fieldId),
    )
  }

  private areFieldValuesValid(
    typeField: IPermitTypeField,
    fieldValues: any[],
    index?: number,
  ): boolean {
    if (typeField.checklist) {
      const checklistValues = fieldValues as IChecklistAnswerItem[]
      return typeField.checklist.list.every(
        cItem =>
          checklistValues?.find(v => v.checklistItemId === cItem.id)?.answer !==
          undefined,
      )
    }

    if (Number.isFinite(index)) {
      return !!fieldValues?.[index]
    }

    return fieldValues?.some(v => v)
  }

  private isValidationIgnored(
    fieldId: string,
    index?: number,
    tableId?: string,
    tableRowIndex?: number,
  ): boolean {
    return (
      this.canIgnoreValidation &&
      !this.fieldIdsToIgnore.includes(fieldId) &&
      !this.isFieldChanged(fieldId, index, tableId, tableRowIndex)
    )
  }

  private get isAdminOrFormsMaster(): boolean {
    return this.state.userActiveProjectSettings.isAdminOrFormsMaster
  }
}
