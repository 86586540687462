import BaseEventStore from '../../BaseEvents.store'
import IWorkerEffect from './IWorkerEffect'
import Worker from './background.worker.ts'

export default class WorkerProcessor {
  public process(store: BaseEventStore, effect: IWorkerEffect) {
    if (!store) {
      return
    }
    if (!effect) {
      return
    }

    const worker = new Worker()

    const data = {
      type: effect.type,
      data: effect.data,
    }

    delete effect.data
    this.listenToWorkerMessages(worker, store, effect)

    worker.postMessage(data)
  }

  private listenToWorkerMessages(
    worker: Worker,
    store: BaseEventStore,
    effect: IWorkerEffect,
  ) {
    if (!worker) {
      return
    }
    if (!effect) {
      return worker.terminate()
    }

    const onMessage = event => {
      worker.removeEventListener('message', onMessage)
      worker.terminate()

      const { isSuccess, result } = event.data
      const { onSuccess, onError } = effect
      if (isSuccess) {
        const [eventType, ...args] = onSuccess
        store.dispatch(eventType, ...args, result)
      } else {
        const [eventType, ...args] = onError
        store.dispatch(eventType, ...args)
      }
    }

    worker.addEventListener('message', onMessage)
  }
}
