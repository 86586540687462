import React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import {
  ActivityStatus,
  DeliveryStatus,
  SitePermitStatus,
} from '~/client/graph'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'

import ContentObjectModel from '../../models/ContentObjectModel'
import Delivery from '../../models/Delivery'
import Message from '../../models/Message'
import SitePermit from '../../models/Permit'
import StatusUpdate from '../../models/StatusUpdate'
import InitialState from '../../stores/InitialState'
import SitePermitAssignmentsStore from '../../stores/domain/SitePermitAssignments.store'
import SitePermitFollowingsStore from '../../stores/domain/SitePermitFollowings.store'
import BaseActionBarStore from '../../stores/ui/BaseActionBar.store'
import MessagesActionBar from './MessagesActionbar'

import './ActionBar.scss'

export type statusType = SitePermitStatus | DeliveryStatus | ActivityStatus

interface IProps {
  store: BaseActionBarStore

  applyStatusAndStep(status: statusType, stepId: string): void
  renderAnnouncementCheckbox(): JSX.Element
  renderButtons(
    renderStatusBtn: (
      btnText: string,
      isPermitValid: boolean,
      status: statusType,
      isDeny?: boolean,
      customBtnHandler?: () => void,
    ) => JSX.Element,
  ): JSX.Element
  renderStatusSelectMenu(
    setStatusAndStep: (status: statusType, stepId: string) => void,
  ): JSX.Element
  toggleStatusSelectMenu(): void

  isReadOnly: boolean
  entity:
    | ContentObjectModel<any>
    | Message
    | Delivery
    | StatusUpdate
    | SitePermit
  customRenderer?: (status: statusType, stepId: string) => JSX.Element
  informationalTextElement?: JSX.Element

  sitePermitAssignmentsStore?: SitePermitAssignmentsStore
  sitePermitFollowingsStore?: SitePermitFollowingsStore
  state?: InitialState
}

@inject('state', 'sitePermitFollowingsStore', 'sitePermitAssignmentsStore')
@observer
export default class ActionBar extends React.Component<IProps> {
  @observable private newStatus: statusType = null
  @observable private newStepId: string
  @observable private containerRef: HTMLDivElement

  public render() {
    const {
      entity,
      renderStatusSelectMenu,
      renderAnnouncementCheckbox,
      store,
      toggleStatusSelectMenu,
      renderButtons,
      customRenderer,
      isReadOnly,
      sitePermitAssignmentsStore,
      sitePermitFollowingsStore,
      informationalTextElement,
    } = this.props

    const { isFileUploading, isImageUploading } = store

    return (
      <div
        className={classList({
          'col form-action-bar pa10 bt-light-grey': true,
          'inactive-element': isFileUploading || isImageUploading,
        })}
      >
        {renderStatusSelectMenu(this.setStatusAndStep)}
        {!isReadOnly && (
          <div className="col mr5">
            {informationalTextElement}
            <div className="row">
              {this.isAdminOrFormsMaster && (
                <div
                  className="no-grow mr10 pointer"
                  onClick={toggleStatusSelectMenu}
                >
                  <Icon icon={IconNames.CHEVRON_UP} className="no-grow" />
                </div>
              )}
              {renderButtons(this.renderStatusBtn)}
            </div>
          </div>
        )}
        <div ref={this.setContainerRef}>
          <MessagesActionBar
            actionBarStore={store}
            entity={entity}
            setNewStatus={this.applyStatusAndStep}
            resetActions={this.resetActions}
            newStatus={this.newStatus}
            worfklowStepId={this.newStepId}
            customStatusRenderer={customRenderer}
            assignmentsStore={sitePermitAssignmentsStore}
            followingsStore={sitePermitFollowingsStore}
            containerRef={this.containerRef}
          />
          {renderAnnouncementCheckbox()}
        </div>
      </div>
    )
  }

  @action.bound
  private setContainerRef(ref: HTMLDivElement) {
    this.containerRef = ref
  }

  private applyStatusAndStep = () => {
    this.props.applyStatusAndStep(this.newStatus, this.newStepId)
  }

  private setStatusAndStep = (status: statusType, stepId: string): void => {
    this.newStatus = status
    this.newStepId = stepId
  }

  private resetActions = () => {
    this.newStatus = null
    this.newStepId = null
  }

  private renderStatusBtn = (
    btnText: string,
    isValid: boolean,
    status: statusType,
    isDeny?: boolean,
    customBtnHandler?: () => void,
  ): JSX.Element => {
    return (
      <BaseActionButton
        isActive={false}
        isEnabled={isValid}
        className={classList({
          'text large ellipsis m5': true,
          'red-theme': isDeny,
          'primary-theme-inverted': !isDeny,
          'inactive-element': !isValid,
        })}
        isGrow={true}
        title={btnText}
        onClick={this.onStatusClick.bind(this, status, customBtnHandler)}
      />
    )
  }

  private get isAdminOrFormsMaster(): boolean {
    return this.props.state.userActiveProjectSettings.isAdminOrFormsMaster
  }

  private onStatusClick(status: statusType, customBtnHandler?: () => void) {
    if (!customBtnHandler) {
      this.setStatusAndStep(status, null)
      return
    }

    this.resetActions()
    customBtnHandler()
  }
}
