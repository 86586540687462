import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import BaseObservableTable from '~/client/src/desktop/components/BaseObservableTable/BaseObservableTable'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import Checkbox from '~/client/src/shared/components/Checkbox'
import {
  ILWFCCategory,
  LWFCRowData,
} from '~/client/src/shared/components/ListWithFixedColumns/GroupedListWithFixedColumns'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import SelectButton from '~/client/src/shared/components/SelectButton/SelectButton'
import SelectionPopUp, {
  SelectionPopUpOption,
} from '~/client/src/shared/components/SelectionPopUp/SelectionPopUp'
import UsernameFromUid from '~/client/src/shared/components/UsernameFromUid'
import {
  InviteStatusCaption,
  getInviteStatusTranslate,
} from '~/client/src/shared/enums/InviteStatusCaption'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import canSendInviteToUser from '~/client/src/shared/utils/canSendInviteToUser'
import getCompanyTypeTranslate from '~/client/src/shared/utils/getCompanyTypeTranslate'
import { NOOP } from '~/client/src/shared/utils/noop'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import ProjectMembersListStore, { DataKeys } from './ProjectMembersList.store'

import './ProjectMembersList.scss'

// translated

const { EDIT, MENU } = IconNames

interface IProps {
  store: ProjectMembersListStore
  initialScrollToId: string
  onAddNewMember: (companyId: string) => void
  onEditMember: () => void
  onInviteMember: () => void
  onSetMemberAsNotInvited: () => void
  isMemberDetailsDialogOpen: boolean
  state?: DesktopInitialState
  eventsStore?: DesktopEventStore
  tagsStore?: TagsStore
  companiesStore?: CompaniesStore
  userProjectsStore?: UserProjectsStore
  projectMembersStore?: ProjectMembersStore
}

enum UserStatusActions {
  invite = 'invite',
  resetPassword = 'resetPassword',
  setNotInvited = 'setNotInvited',
}

function getUserStatusActionTranslate(value: UserStatusActions) {
  switch (value) {
    case UserStatusActions.invite:
      return Localization.translator.invite
    case UserStatusActions.resetPassword:
      return Localization.translator.resetPassword
    case UserStatusActions.setNotInvited:
      return Localization.translator.setNotInvited
    default:
      throw new Error(value + ' is unhandled')
  }
}

@inject(
  'state',
  'eventsStore',
  'tagsStore',
  'companiesStore',
  'userProjectsStore',
  'projectMembersStore',
)
@observer
export default class ProjectMembers extends React.Component<IProps> {
  public get store(): ProjectMembersListStore {
    return this.props.store
  }

  public componentDidMount() {
    this.store.scrollToInstance(this.props.initialScrollToId)
  }

  public render() {
    const { getTableWidth } = this.store

    return (
      <>
        {this.renderSelector()}
        <BaseObservableTable
          fixedWidth={getTableWidth()}
          store={this.store}
          autoSizerClassName="full-height full-width"
          valueRenderer={this.renderValue}
          renderMainCheckbox={this.renderMainCheckbox}
          renderCategorySecondaryCell={this.renderCategorySecondaryCell}
          renderCategoryLabel={this.renderCategoryLabel}
          handleCellClick={NOOP}
        />
      </>
    )
  }

  private renderMainCheckbox = (): JSX.Element => {
    const { isSelectorDisplayed, toggleSelector } = this.store

    return (
      <SelectButton onClick={toggleSelector} isEnabled={!isSelectorDisplayed} />
    )
  }

  private renderValue = (
    value: any,
    dataKey: string,
    data: LWFCRowData,
  ): JSX.Element => {
    switch (dataKey) {
      case DataKeys.NAME:
        return this.wrapNameWithElement(value)

      case DataKeys.CHECKBOX:
        return (
          <Checkbox
            onClick={this.checkboxClick.bind(null, data)}
            isCentered={true}
            isChecked={value}
          />
        )

      case DataKeys.STATUS:
        return this.wrapStatusWithElement(value, data[DataKeys.ID])

      case DataKeys.ROLES:
      case DataKeys.TEAMS:
      case DataKeys.TRADES:
        return (
          BaseObservableTable.renderCellWithTags(
            this.props.tagsStore,
            dataKey,
            value,
          ) || <>{NO_VALUE}</>
        )

      default:
        return null
    }
  }

  private checkboxClick = (data: LWFCRowData) => {
    this.store.resetHiddenSelection()
    this.store.userWithOpenDropdownId = ''
    this.store.toggleInstance(data[DataKeys.ID])
  }

  @action.bound
  private wrapStatusWithElement(value: string, userId: string) {
    const { isMemberDetailsDialogOpen, projectMembersStore } = this.props
    const { hiddenSelectedInstancesIds } = this.store

    const user = projectMembersStore.getById(userId)

    return (
      <div className="relative">
        {!isMemberDetailsDialogOpen &&
          hiddenSelectedInstancesIds.includes(userId) && (
            <MenuCloser closeMenu={this.toggleDropdown.bind(this, userId)}>
              <div className="options-holder">
                {Object.values(UserStatusActions).map(userStatusAction => {
                  return (
                    <div
                      className={classList({
                        'option pointer row y-center x-center': true,
                        'inactive-element': !this.isStatusActionActiveForUser(
                          userStatusAction,
                          user,
                        ),
                      })}
                      key={userStatusAction}
                      onClick={this.performUserStatusAction.bind(
                        this,
                        userStatusAction,
                        userId,
                      )}
                    >
                      {getUserStatusActionTranslate(userStatusAction)}
                    </div>
                  )
                })}
              </div>
            </MenuCloser>
          )}
        <div
          className={classList({
            'project-members-user-name full-width': true,
            'unclickable-element': !!hiddenSelectedInstancesIds.length,
          })}
          onClick={this.toggleDropdown.bind(this, userId)}
        >
          {(value && getInviteStatusTranslate(value as InviteStatusCaption)) ||
            '—'}
          <Icon
            icon={MENU}
            iconSize={14}
            className="project-members-user-name-edit-icon ml10"
          />
        </div>
      </div>
    )
  }

  private toggleDropdown = (userId: string, event: Event) => {
    event?.stopPropagation()

    // TODO: move to store
    this.store.userWithOpenDropdownId =
      this.store.userWithOpenDropdownId === userId ? '' : userId

    this.store.toggleHiddenInstance(userId)
  }

  private get selectionPopUpOptions(): SelectionPopUpOption[] {
    const {
      selectNotInvitedMembers,
      selectStatusRequestedMembers,
      handleSelectAll,
      handleUnselectAll,
    } = this.store
    return [
      {
        label: Localization.translator.selectNotInvited,
        onClick: selectNotInvitedMembers,
      },
      {
        label: Localization.translator.selectStatusRequested,
        onClick: selectStatusRequestedMembers,
      },
      {
        label: Localization.translator.selectAll_people,
        onClick: handleSelectAll,
      },
      {
        label: Localization.translator.clear,
        onClick: handleUnselectAll,
      },
    ]
  }

  private renderSelector() {
    const { isSelectorDisplayed } = this.store
    return (
      <MenuCloser
        closeMenu={this.store.toggleSelector}
        isOpen={isSelectorDisplayed}
      >
        <SelectionPopUp
          isDisplayed={isSelectorDisplayed}
          options={this.selectionPopUpOptions}
          className="selector-pop-up brada2 col pointer ba-light-cool-grey"
        />
      </MenuCloser>
    )
  }

  private renderCategoryLabel = (companyId: string) => {
    const company = this.props.companiesStore.getCompanyById(companyId)

    if (!company) {
      return <span className="ml10">{User.getDefaultCompanyName()}</span>
    }

    const { name } = company
    const companyTypeTags = this.props.companiesStore.getCompanyTypeTagsByIds(
      company.typeTags,
    )

    return (
      <div className="row no-grow">
        <span className="mx10">{name || User.getDefaultCompanyName()}</span>
        <span className="text light">
          (
          {companyTypeTags
            .map(tag => getCompanyTypeTranslate(tag.value))
            .join(',')}
          )
        </span>
      </div>
    )
  }

  private renderCategorySecondaryCell = (
    dataKey: string,
    category: ILWFCCategory,
  ) => {
    if (category.categoryId === UNASSIGNED) {
      return null
    }

    if (dataKey === DataKeys.EMAIL) {
      return (
        <div className="row company-contactor-group-cell">
          {this.renderAddNewMemberButton(category.categoryId)}
        </div>
      )
    }
  }

  @action.bound
  private wrapNameWithElement(userId: string) {
    return (
      <span
        className="project-members-user-name"
        onClick={this.showEditMemberDialog.bind(this, userId)}
      >
        {/* It looks like this is the only place where the lastName should be bold according to sorting logic */}
        <UsernameFromUid
          userId={userId}
          isClickable={false}
          withBoldLastName={true}
        />
        <Icon
          icon={EDIT}
          iconSize={14}
          className="project-members-user-name-edit-icon ml10"
        />
      </span>
    )
  }

  @action.bound
  private showEditMemberDialog(userId: string, e: any) {
    e.stopPropagation()

    this.store.userWithOpenDropdownId = ''
    this.store.setHiddenInstanceAsLastSelected(userId)

    this.props.onEditMember()
  }

  @action.bound
  private performUserStatusAction(
    userStatusAction: UserStatusActions,
    userId: string,
    event: React.MouseEvent<HTMLElement>,
  ) {
    event.stopPropagation()

    this.store.resetHiddenSelection()

    const { projectMembersStore, eventsStore } = this.props

    const user = projectMembersStore.getById(userId)

    switch (userStatusAction) {
      case UserStatusActions.invite:
        this.props.onInviteMember()
        return
      case UserStatusActions.resetPassword:
        this.store.userWithOpenDropdownId = ''
        eventsStore.dispatch(e.REQUEST_RESET_PASSWORD, user.email)
        return
      case UserStatusActions.setNotInvited:
        this.props.onSetMemberAsNotInvited()
        return
    }
  }

  private isStatusActionActiveForUser(
    userStatusAction: UserStatusActions,
    user: User,
  ) {
    const { userProjectsStore } = this.props

    switch (userStatusAction) {
      case UserStatusActions.invite:
        return canSendInviteToUser(user, userProjectsStore)

      case UserStatusActions.resetPassword:
      case UserStatusActions.setNotInvited:
        return UserProject.isAccepted(user, userProjectsStore)

      default:
        return false
    }
  }

  private renderAddNewMemberButton(companyId: string) {
    return (
      <div className="project-members-add-member-row">
        <div
          className="project-members-add-member-row-btn"
          onClick={this.handleAddNewMember.bind(this, companyId)}
        >
          <Icon
            icon={IconNames.ADD}
            iconSize={16}
            className="project-members-add-member-row-btn-icon"
          />
          <span className="project-members-add-member-row-btn-label">
            {Localization.translator.addNewProjectMember}
          </span>
        </div>
      </div>
    )
  }

  private handleAddNewMember(
    companyId: string,
    event: React.MouseEvent<HTMLElement>,
  ) {
    event.stopPropagation()
    this.props.onAddNewMember(companyId)
  }
}
