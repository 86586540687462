import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import ProjectSetUpPageStore from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPage.store'
import SetUpNavigationRow from '~/client/src/desktop/views/ProjectSetUp/components/SetUpSteps/SetUpNavigationRow'
import UserProfilePreview from '~/client/src/shared/components/UserProfilePreview/UserProfilePreview'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import ClientInfo from './ClientInfo'

interface ISetUpNavigationProps {
  projectSetUpPageStore: ProjectSetUpPageStore
}

@observer
export default class SetUpNavigation extends React.Component<ISetUpNavigationProps> {
  public render() {
    const store = this.props.projectSetUpPageStore
    const {
      availableAccountSettingsPages,
      projectSettingsPages,
      formsSettingsPages,
      usersSettingsPages,
      deliveriesSettingsPages,
      analyticsSettingsPages,
      integrationSettingsPages,
      materialsSettingsPages,
      isAdmin,
      isScanMaster,
      isSetUpPagesPanelHidden,
      currentUser,
      isDeliveriesDisabled,
      isIntegrationsEnabled,
      isFormsDisabled,
      isMaterialsDisabled,
      isAnalyticsDisabled,
      toggleSetUpPagesPanel,
    } = store

    const icon = isSetUpPagesPanelHidden
      ? IconNames.MENU
      : IconNames.DOUBLE_CHEVRON_LEFT

    return (
      <div className="project-setup-leftbar-holder col">
        <div
          className={classList({
            'project-setup-leftbar-header pa10 menu-toggle-icon-holder row no-grow navigation-group':
              true,
            'x-start hidden-panel': isSetUpPagesPanelHidden,
            'x-end': !isSetUpPagesPanelHidden,
          })}
        >
          <Icon
            onClick={toggleSetUpPagesPanel}
            icon={icon}
            className="menu-toggle-icon pointer"
          />
        </div>
        <div
          className={classList({
            'project-setup-leftbar overflow-y-auto x-scroll-hidden': true,
            short: isSetUpPagesPanelHidden,
          })}
        >
          <div className="navigation-group">
            <div
              className={classList({
                row: true,
                'x-end': !isSetUpPagesPanelHidden,
                'x-center': isSetUpPagesPanelHidden,
              })}
            ></div>
            <div className="navigation-group-header pb5 title">
              {Localization.translator.account}
            </div>
            <UserProfilePreview
              className="y-start py8 mr5"
              user={currentUser}
              isUserNameClickable={false}
            />
            {availableAccountSettingsPages.map(step => (
              <SetUpNavigationRow
                key={step.page}
                step={step}
                projectSetUpPageStore={store}
                isShortVersion={isSetUpPagesPanelHidden}
              />
            ))}
            {!isAdmin && this.renderScheduleSection()}
          </div>
          {isAdmin && (
            <>
              <div className="navigation-group">
                {this.renderTitle(Localization.translator.users)}
                {usersSettingsPages.map(step => (
                  <SetUpNavigationRow
                    key={step.page}
                    step={step}
                    projectSetUpPageStore={store}
                    isShortVersion={isSetUpPagesPanelHidden}
                  />
                ))}
              </div>
              <div className="navigation-group">
                {this.renderTitle(Localization.translator.project)}
                {projectSettingsPages.map(step => (
                  <SetUpNavigationRow
                    key={step.page}
                    step={step}
                    projectSetUpPageStore={store}
                    isShortVersion={isSetUpPagesPanelHidden}
                  />
                ))}
                {isIntegrationsEnabled &&
                  integrationSettingsPages.map(step => (
                    <SetUpNavigationRow
                      key={step.page}
                      step={step}
                      projectSetUpPageStore={store}
                      isShortVersion={isSetUpPagesPanelHidden}
                    />
                  ))}
              </div>
              {!isMaterialsDisabled && (
                <div className="navigation-group">
                  {this.renderTitle(Localization.translator.materials)}
                  {materialsSettingsPages.map(step => (
                    <SetUpNavigationRow
                      key={step.page}
                      step={step}
                      projectSetUpPageStore={store}
                      isShortVersion={isSetUpPagesPanelHidden}
                    />
                  ))}
                </div>
              )}
              {!isFormsDisabled && (
                <div className="navigation-group">
                  {this.renderTitle(Localization.translator.forms)}
                  {formsSettingsPages.map(step => (
                    <SetUpNavigationRow
                      key={step.page}
                      step={step}
                      projectSetUpPageStore={store}
                      isShortVersion={isSetUpPagesPanelHidden}
                    />
                  ))}
                </div>
              )}
              {!isDeliveriesDisabled && (
                <div className="navigation-group">
                  {this.renderTitle(Localization.translator.deliveries)}
                  {deliveriesSettingsPages.map(step => (
                    <SetUpNavigationRow
                      key={step.page}
                      step={step}
                      projectSetUpPageStore={store}
                      isShortVersion={isSetUpPagesPanelHidden}
                    />
                  ))}
                </div>
              )}
              {this.renderScheduleSection()}

              {!isAnalyticsDisabled && (
                <div className="navigation-group">
                  {this.renderTitle(Localization.translator.analytics)}
                  {analyticsSettingsPages.map(step => (
                    <SetUpNavigationRow
                      key={step.page}
                      step={step}
                      projectSetUpPageStore={store}
                      isShortVersion={isSetUpPagesPanelHidden}
                    />
                  ))}
                </div>
              )}
            </>
          )}
          {isScanMaster && this.renderScannerSection()}
          <div className="no-select client-info">
            <ClientInfo shouldShow={true} />
          </div>
        </div>
      </div>
    )
  }

  private renderTitle(
    title: string,
    className: string = 'navigation-group-header title row x-between',
  ) {
    return (
      <>
        <div className="bt-light-input-border navigation-group-header-separator" />
        <div className={className}>{title}</div>
      </>
    )
  }

  private renderScheduleSection() {
    const store = this.props.projectSetUpPageStore
    const {
      scheduleSettingsPages,
      scheduleLabsSettingsPages,
      isSetUpPagesPanelHidden,
      isTrackerDisabled,
      isActivityUploadAvailable,
    } = store

    return (
      <>
        {!isTrackerDisabled && isActivityUploadAvailable && (
          <>
            <div className="navigation-group">
              {this.renderTitle(Localization.translator.schedule)}
              {scheduleSettingsPages.map(step => (
                <SetUpNavigationRow
                  key={step.page}
                  step={step}
                  projectSetUpPageStore={store}
                  isShortVersion={isSetUpPagesPanelHidden}
                />
              ))}
            </div>
            <div className="navigation-group">
              {this.renderTitle(Localization.translator.struxhubLabs)}
              {scheduleLabsSettingsPages.map(step => (
                <SetUpNavigationRow
                  key={step.page}
                  step={step}
                  projectSetUpPageStore={store}
                  isShortVersion={isSetUpPagesPanelHidden}
                />
              ))}
            </div>
          </>
        )}
      </>
    )
  }

  private renderScannerSection() {
    const store = this.props.projectSetUpPageStore
    const { scannerSettingsPages, isSetUpPagesPanelHidden } = store

    return (
      <div className="navigation-group">
        {this.renderTitle(Localization.translator.qrScannersSetup)}
        {scannerSettingsPages.map(step => (
          <SetUpNavigationRow
            key={step.page}
            step={step}
            projectSetUpPageStore={store}
            isShortVersion={isSetUpPagesPanelHidden}
          />
        ))}
      </div>
    )
  }
}
