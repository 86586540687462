import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import HierarchyConfigurationStore from '~/client/src/shared/stores/domain/HierarchyConfiguration.store'

import ConfiguratorHeader from '../Utils/ConfiguratorHeader/ConfiguratorHeader'
import AddBandDialog from './components/AddBandDialog/AddBandDialog'
import BandConfigurationRow from './components/BandConfigurationRow/BandConfigurationRow'
import BandConfigurationSwitch from './components/BandConfigurationSwitch/BandConfigurationSwitch'

import '../Utils/CommonConfigurator.scss'

interface IBandsConfigurator {
  hierarchyConfigurationStore?: HierarchyConfigurationStore
}

const ADD_ICON_SIZE = 17

@inject('hierarchyConfigurationStore')
@observer
export default class BandsConfigurator extends React.Component<IBandsConfigurator> {
  @observable private shouldShowAddBandsDialog: boolean = false

  public render() {
    const {
      handleBandRemove,
      handleBandsAdd,
      handleBandsOrderChange,
      orderedAppliedBands,
      toggleAbilityUpdateByGC,
      canGCUpdateActivity,
      bandTypesToCaptions,
    } = this.props.hierarchyConfigurationStore

    return (
      <div className="row hierarchy-configurator">
        <AddBandDialog
          isOpen={this.shouldShowAddBandsDialog}
          onClose={this.hideAddBandDialog}
          onSubmit={handleBandsAdd}
        />
        <div className="col px50">
          <div className="text large center light pb10">
            {Localization.translator.selectControlBand}
          </div>
          <ConfiguratorHeader
            columnTitles={[
              Localization.translator.band,
              Localization.translator.companyTypes.GeneralContractorPrimary,
              Localization.translator.companyTypes.SubContractor,
            ]}
          />

          {orderedAppliedBands.map((bandType, index) => (
            <BandConfigurationRow
              key={index}
              index={index}
              bandType={bandType}
              onChange={handleBandsOrderChange}
              onRemove={handleBandRemove}
              typeToCaptionMap={bandTypesToCaptions}
            />
          ))}

          <div className="row pt15 bb-light-grey activities-section">
            <div
              className="row configurator-row-handle add opaque pb50"
              onClick={this.showAddBandDialog}
            >
              <Icon
                icon={IconNames.ADD}
                iconSize={ADD_ICON_SIZE}
                className="pointer"
              />
              <span className="pl5 text large primary-blue pointer">
                {Localization.translator.addBand}
              </span>
            </div>
            <div className="text large center">
              {Localization.translator.activities}
            </div>
            <BandConfigurationSwitch
              isChecked={canGCUpdateActivity}
              onChange={toggleAbilityUpdateByGC}
            />
            <BandConfigurationSwitch isDisabled={true} />
          </div>
        </div>
      </div>
    )
  }

  @action.bound
  private showAddBandDialog() {
    this.shouldShowAddBandsDialog = true
  }

  @action.bound
  private hideAddBandDialog() {
    this.shouldShowAddBandsDialog = false
  }
}
