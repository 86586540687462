import * as React from 'react'

import { observer } from 'mobx-react'

import Dimmer from '~/client/src/shared/components/Dimmer'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import './CompactActionsMenuModal.scss'

// localization: translated

interface IProps {
  isShown: boolean
  onHide(): void

  headerText?: string
  footerText?: string
  ignoredOutsideElementId?: string
}

@observer
export default class CompactActionsMenuModal extends React.Component<IProps> {
  public render() {
    const {
      isShown,
      onHide,
      headerText,
      footerText,
      ignoredOutsideElementId,
      children,
    } = this.props

    if (!isShown) return null

    return (
      <MenuCloser isOpen closeMenu={onHide} excludeId={ignoredOutsideElementId}>
        <Dimmer shown onClick={onHide} />
        <div className="compact-actions-menu-modal absolute overflow-hidden brada10 col mx10">
          <div className="col brada10 overflow-hidden">
            <div className="row py15 h40 bg-palette-brand-lightest bb-light-cool-grey">
              <div className="text small uppercase bold line-16 lp15 px10">
                {headerText || Localization.translator.actions}
              </div>
            </div>
            <div className="overflow-y-auto bg-white pointer" onClick={onHide}>
              {children}
            </div>
          </div>
          <div
            className="footer brada10 text blue large bg-white row x-center y-center mt10 pointer"
            onClick={onHide}
          >
            {footerText || Localization.translator.cancel}
          </div>
        </div>
      </MenuCloser>
    )
  }
}
