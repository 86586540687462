import MaturixStrengthUnit from '../../enums/MaturixStrengthUnit'
import MaturixTemperatureUnit from '../../enums/MaturixTemperatureUnit'
import { TagType } from '../../enums/TagType'
import { getBandTitleByTagType } from '../../utils/TagHelper'
import LanguageTreeType from '../LanguageTreeType'
import { brandName, brandShortName } from '../brand'
import countries from './countries'
import timezones from './timezones'

// узгодження числівника з іменником йопт
const ending = (x: number, for1: string, for234: string, forRest: string) => {
  const xMod100 = x % 100
  if (5 <= xMod100 && xMod100 <= 20) {
    return forRest
  }

  const xMod10 = x % 10
  if (xMod10 === 1) {
    return for1
  }

  if (2 <= xMod10 && xMod10 <= 4) {
    return for234
  }

  return forRest
}

// Деякі слова мають різну форму у називному і знахідному відмінках
const deliveriesNominative = (x: number) =>
  `Достав${ending(x, 'ка', 'ки', 'ок')}`
const deliveriesAccusative = (x: number) =>
  `Достав${ending(x, 'ку', 'ки', 'ок')}`
const plans = (x: number) => `План${ending(x, '', 'и', 'ів')}`
const users = (x: number) => `Член${ending(x, 'а', 'ів', 'ів')}`
const activitiesAccusative = (x: number) =>
  `Роб${ending(x, 'оту', 'оти', 'іт')}`
const formsAccusative = (x: number) => `Форм${ending(x, 'а', 'и', '')}`
const announcements = (x: number) => `Оголошен${ending(x, 'ня', 'ня', 'ня')}`
const sensors = (x: number) => `Сенсор${ending(x, '', 'а', 'ів')}`
const closures = (x: number) => `Кложур${ending(x, '', 'а', 'ів')}`
const workflows = (x: number) =>
  `Робоч${ending(x, 'ий', 'і', 'их')} процес${ending(x, '', 'и', 'ів')}`

const xHours = (x: number) => `${x} Годи${ending(x, 'на', 'ни', 'н')}`
const xUsers = (x: number) => `${x} користувач${ending(x, '', 'і', 'ів')}`
const xFiles = (x: number) => `${x} файл${ending(x, '', 'а', 'ів')}`
const xDates = (x: number) => `${x} Дат${ending(x, 'а', 'и', '')}`
const xForms = (x: number) => `${x} форм${ending(x, 'у', 'и', '')}`

const easterEggMode = true

const UkrainianTree: LanguageTreeType = {
  _brandName: brandName,
  _brandShortName: brandShortName,

  avatar: 'Аватарка',
  ok: 'Добре',
  cancel: 'Скасувати',
  open: 'Відкрити',
  opened: 'Відкрито',
  close: 'Закрити',
  closed: 'Закритий',
  contacts: 'Контакти',
  loading: 'Завантаження',
  language: 'Мова',
  notSpecified: 'Не вказано',
  default: 'За замовчуванням',
  done: 'Готово',
  save: 'Зберегти',
  apply: 'Застосувати',
  edit_verb: 'Редагувати',
  merge_verb: "Об'єднати",
  link_verb: "Прив'язати",
  re_link_verb: `Переприв'язати`,
  unlink_verb: `Від’єднати`,
  unlinking: 'Від’єднання',
  delete: 'Видалити',
  initials: 'Ініціали',
  invite: 'Запросити',
  search: 'Шукати',
  selectAll_people: 'Вибрати всіх',
  selectAll_items: 'Вибрати все',
  selectNone_people: 'Не вибрати нікого',
  selectNone_items: 'Не вибрати нічого',
  clearAll_items: 'Очистити все',
  clear: 'Очистити',
  uploading: 'Завантаження',
  noResults: 'Жодних результатів',
  add: 'Додати',
  update_verb: 'Оновити',
  error: easterEggMode ? 'Халепа' : 'Помилка',
  goBack: 'Повернутися',
  confirm: 'Підтвердити',
  select: 'Вибрати',
  selected: 'Вибрані',
  start_verb: 'Розпочати',
  finish_verb: 'Завершити',
  followers: 'Підписники',
  view_verb: 'Переглянути',
  download: 'Завантажити з сайту',
  downloadingStatus: {
    starting: 'Запуск',
    fetching: 'Отримання',
    compiling: 'Компіляція',
    generating: 'Генерація',
    downloading: 'Завантажит',
  },
  searchCompany: 'Шукати компанію',
  permit: 'Допустити',
  permitAll: 'Допустити всі',
  thereIsNoCompanies: 'Немає компаній',
  thereIsNoObjectOfSelectedType: "Немає об'єкта обраного типу",
  restrict: 'Обмежити',
  restrictAccess: 'Обмежити доступ',
  allCompanies: 'Всі компанії',
  addCompany: 'Додати компанію',
  addHours: 'Додати години',
  addNumber: 'Додати число',
  addDuration: 'Додати тривалість',
  closureHistory: 'Історія закриття',
  addClosureDate: 'Додати дату закриття',
  addSiteAnnouncement: 'Додати оголошення',
  locationClosure: 'Закриття локації',
  ifDeliveryHasTheFollowingTags: 'Якщо доставка має такі теги',
  ifEntityHasTheFollowingTags: (entityName: string) =>
    `Якщо ${entityName} має такі теги`,
  thenDoAction: 'Тоді виконувати дію',
  toUserTeamRoleOrCompany: 'Для користувача, команди, посади або компанії',
  addRule: 'Додати правило',
  shouldDeleteRule: 'Потрібно видалити правило',
  tag: 'Тег',
  searchForOrSelectTag: 'Шукайте або виберіть тег',
  addTag: 'Додати тег',
  addUserTeamCompany: 'Додати користувача, команду, компанію',
  tagCreation: 'Створення тега',
  deliveryStatus: 'Статус доставки',
  sendNotification: 'Надсилати сповіщення',
  notificationRules: 'Правила сповіщення',
  mention: 'Згадати',
  gotToDirectory: 'Перейти до каталогу',
  goToProjectHome: 'Перейти на головну сторінку проєкту',

  daily: 'Щодня',
  monSat: 'Пон-Суб',
  d_daysShort: 'д',
  yesterday: 'Учора',
  today: 'Сьогодні',
  tomorrow: 'Завтра',

  loggingIn: 'Вхід у систему',
  loggingOut: 'Вихід із системи',
  loginFailed: 'Помилка входу',
  login: 'Увійти',
  logInWith: 'Увійти за допомогою',
  logo: 'Логотип',
  procoreLogin: 'Увійти через Procore',
  changePassword: 'Змінити пароль',
  forgotPassword: 'Забули пароль?',
  reset: 'Скинути',
  resetPassword: 'Скинути пароль',
  logOut: 'Вийти',

  hideAll: 'Приховати всі',
  hideGroup: 'Приховати групу',
  hideObject: 'Приховати об’єкт',

  showAll: 'Показати всі',
  showGroup: 'Показати групу',
  showObject: 'Показати об’єкт',

  contactHelpAtMail: (email: string) => 'Звернутися по допомогу ' + email,
  contactHelp: 'Звернення по допомогу',

  userNotFound: 'Користувача не знайдено',
  noUserMatchingYourSearch: 'Жоден користувач не відповідає вашому пошуку',
  allUsersAlreadyAssigned: 'Всі користувачі вже призначені',
  noTagMatchingYourSearch: 'Жоден тег не відповідає вашому пошуку',

  emailCannotBeEmpty: 'Електронна адреса не може бути порожньою',
  enterYourEmailBelowAndStruxHubWillSendAResetLink: `Уведіть Вашу електронну адресу нижче, і ${brandName} надішле Вам посилання, за яким Ви зможете скинути пароль`,
  pleaseOpenTheLinkFromTheLetterAgain:
    'Будь ласка відкрийте посилання з листа знову',
  passwordResetLinkIsSentToMailbox:
    'Посилання для скидання паролю надіслано на поштову скриньку',
  invalidEmailError: 'Електронна адреса недійсна.',
  userNotFoundError:
    'Користувача з такою електроною адресой не знайдено. Можливо, користувач видалений.',
  enterANewPasswordBelow: 'Уведіть новий пароль нижче',
  setYourPassword: 'Задайте Ваш пароль',
  tooManyUnsuccessfulLoginAttempts: 'Забагато невдалих спроб входу.',
  passwordErrors: {
    weakPassword:
      'Пароль повинен містити щонайменше 8 символів, включаючи ' +
      'латинські малі та великі літери, цифри та принаймі 1 спеціальний символ !,@#$%&*?_~-',
    doNotMatch: 'Паролі не співпадають',
    empty: 'Пароль не може бути порожнім',
    invalid: 'Пароль є недійсним або користувач не має паролю',
  },

  chat: 'Чат',
  activities: 'Роботи',
  deliveries: 'Доставки',
  closures: 'Закоиття',
  sensors: 'Сенсори',
  logistics: "Логістичний об'єкт",
  logisticObject: 'Логістика',
  site: 'Сайт',
  home: 'Домашня сторінка',
  siteLogistics: 'Логістика сайту',
  materials: 'Матеріали',
  photos: 'Фото',
  analytics: 'Аналітика',
  analyticsSetting: 'Налаштування аналітика',
  showInReports: 'Показувати у репортi',
  dashboards: 'Приладові панелі',
  reports: 'Звіти',
  filedToGenerateReport: 'Не вдалося створити звіт',
  objectProperties: "Властивості об'єктів",
  notifications: 'Сповіщення',
  userSettings: 'Налаштування користувача',
  projectSettings: 'Налаштування проєкта',
  settings: 'Налаштування',
  messages: 'Повідомлення',

  signIn: 'Увійти',
  signUp: 'Зареєструватися',
  signUpTo: 'Зареєструватися у',
  signedInAs: 'Ви увійшли як',
  account: 'Обліковий запис',
  profile: 'Профіль',
  editProfile: 'Редагувати профіль',
  notificationPreferences: 'Налаштування сповіщень',
  administration: 'Адміністрування',
  projectDetails: 'Деталі проєкта',
  integrations: 'Інтеграції',
  userDirectory: 'Каталог користувачів',
  team: 'Команда',
  teams: 'Команди',
  defaultTeams: 'Команди за замовчуванням',
  presentations: 'Презентації',
  tracker: 'Трекер',
  uploadSchedule: 'Завантаження розкладу',
  filterBy: 'Відфільтрувати за',
  filter: 'Фільтрувати',
  filters: 'Фільтри',
  createNewFilter: 'Створити новий фільтр',
  addScheduleData: 'Дотати дані розкладу',
  editScheduleData: 'Редагувати дані розкладу',
  struxhubObject: `${brandName} об'єкт`,
  selectObject: "Оберіть об'єкт",
  selectWorkForm: 'Виберіть форму для роботи',
  hierarchies: 'Ієрархії',
  dataToSchedule: 'Дані до розкладу',
  searchUserOrGroup: 'Пошук Користувача або Групи',
  sitemaps: 'Мапи сайту',
  tool: 'Інструмент',
  workflow: 'Робочий процес',
  workflows: 'Робочі процеси',
  sitemapSetup: 'Налаштування мапи сайту',
  templateUpdating: 'Оновлення шаблону',

  publishTo: 'Опублікувати до',
  geoReferencing: "Географічно з'єднано",
  tags: 'Теги',
  permittedCompanies: 'Дозволені компанії',
  associatedForms: "Пов'язані форми",
  associatedAnnouncements: "Пов'язані оголошення",
  hoursOfOperation: 'Часи роботи',
  dateClosures: 'Дати закриття',
  maxBookingDuration: 'Максимальна тривалість бронювання',
  maxOverlappingBookings: 'Максимальна кількість бронювання',
  object: "Об'єкт",
  objects: "Об'єкти",
  objectName: "Ім'я об'єкта",
  objectNestedUnder: "Об'єкт розміщенно у",
  iconColor: 'Колір Іконки',
  image: 'Зображення',
  groupBy: 'Згрупувати за',
  basemap: easterEggMode ? 'Базована мапа' : 'Базова мапа',
  createNewView: 'Створити нову мапу',
  createNewTag: 'Створити новий тег',
  viewBasemap: 'Показати базову мапу',
  viewBookings: 'Показати бронювання',
  thereIsNoInstanceInTheSelectedCategory:
    'У вибраній категорії немає жодного екземпляра',
  name: "Ім'я",
  firstName: "Ім'я",
  lastName: 'Прізвище',
  phoneNumber: 'Телефон',
  globalRole: 'Загальна посада',
  employeeIDNumber: 'Ідентифікаційний номер працівника',
  company: 'Компанія',
  companyDirectory: 'Каталог компаній',
  common: 'Звичайні',
  myCompany: 'Моя компанія',
  companyType: 'Тип компанії',
  companyName: 'Назва компанії',
  user: 'Користувач',
  users: 'Користувачі',
  userTags: 'Теги користувачів',
  allUsers: 'Всі Користувачі',
  role: 'Посада',
  projectRole: 'Посада у проєкті',
  projectRoles: 'Посади у проєкті',
  projectTrades: 'Спеціальності у проєкті',
  address: 'Aдреса',
  email_noun: 'Електронна адреса',
  phone: 'Телефон',
  phoneVerification: 'Перевірка телефону',
  call_verb: 'Зателефонувати',
  email_verb: 'Написати листа',
  sendDirectMessageInStruxHub: `Відправити особисте повідомлення у ${brandName}`,
  password: 'Пароль',
  currentPassword: 'Поточний пароль',
  newPassword: 'Новий пароль',
  confirmNewPassword: 'Підтвердіть новий пароль',
  group: 'Група',
  reportDetails: 'Деталі репорту',
  createReport: 'Створити репорт',
  deliveryReport: 'Репорт по доставкам',
  formReport: 'Репорт по формам',
  materialReport: 'Звіт по матеріалам',
  aReportWillBeGeneratedWithTheAboveFilters:
    'Буде створено звіт із зазначеними вище фільтрами',
  aReportWillBeSentToYourEmailShortly:
    'Звіт буде надіслано на вашу електронну адресу найближчим часом',
  reportsTo: 'Звітує',
  reportOf: (type: string) => `${type} эвітує`,
  officeField: easterEggMode ? 'Маєток/Поле' : 'Офіс/Будмайданчик',
  accountType: 'Тип облікового запису',
  status: 'Статус',
  monitoringStatus: 'Статус Моніторингу',
  castStatus: 'Статус Касту',
  levelsToClose: 'Закриті поверхи',
  isDockMaster: 'Чи є начальником доку',
  isInspector: 'Чи є інспектором',
  unknownUser: 'Невідомий користувач',
  unknownDeletedObject: "Невідомий (видалений) Об'єкт",
  inviteStatus: 'Статус запрошення',
  inviteStatusValues: {
    Not_Invited: 'Не запрошений',
    Pending: 'В очікуванні',
    Accepted: 'Підтверджений',
    // service
    Not_Added: 'Не доданий',
    Added: 'Доданий',
    Not_Requested: 'Не запитано',
    Requested: 'Запрошений',
  },
  accountPosition: 'Позиція облікового запису',
  accountPositions: {
    office: easterEggMode ? 'Маєток' : 'Офіс',
    field: easterEggMode ? 'Поле' : 'Будмайданчик',
    management: easterEggMode ? 'Канцелярія' : 'Адміністрація',
  },
  projectAccessTypeValues: {
    admin: easterEggMode ? 'Управитель' : 'Адміністратор',
    member: easterEggMode ? 'Кріпак' : 'Учасник',
    owner: easterEggMode ? 'Пан' : 'Власник',
    visitor: easterEggMode ? 'Приїжджий' : 'Відвідувач',
  },

  modalInfoSections: {
    personalInformation: 'Персональні дані',
    companyInformation: 'Корпоративні відомості',
    projectInformation: 'Проєктні відомості',
    additionalInformation: 'Додаткові відомості',
    workflowPermissions: 'Дозволи',
    companyContactInfo: 'Корпоративні відомості',
    legalInfo: 'Юридична інформація',
    companyAddress: 'Aдреса компанії',
    users: 'Користувачі',
    qrCodeAccessAndBadges: 'Доступ до QR-коду та значки',
    globalInfo: `Загальна інформація (${brandShortName} рівень)`,
  },
  projectWorkflows: {
    deliveryBooking: 'Бронювання доставки',
    deliveryInspection: 'Перевірка доставки',
    scheduleComment: 'Керування розкладом',
    formsMaster: 'Головний по формам',
    scanMaster: 'Головний зі сканування',
    activityViewing: 'Переглянути роботи',
    activityUpdating: 'Оновити роботи',
    activityUploading: 'Завантажити роботи',
    activityNotes: 'Примітки щодо робіт',
  },
  permissions: {
    none: 'Без доступу',
    readOnly: 'Читання',
    standard: 'Стандарт',
    master: 'Майстер',
  },
  userProfileRoleNames: {
    admin: easterEggMode ? 'Управитель' : 'Адміністратор',
    owner: easterEggMode ? 'Пан' : 'Власник',
    docMaster: 'Начальник доку',
    inspector: 'Інспектор',
    presentationUser: 'Майстер презентації',
    formsMaster: 'Головний по формам',
    activityViewing: 'Переглянути роботи',
    activityUpdating: 'Оновити роботи',
    activityUploading: 'Завантажити роботи',
    activityNotes: 'Примітки щодо робіт',
  },
  companyTypes: {
    GeneralContractorPrimary: 'Генеральний підрядник (Первинний)',
    GeneralContractorSecondary: 'Генеральний підрядник (Вторинний)',
    Owner: 'Власник',
    SubContractor: 'Субпідрядник',
    TieredSubContractor: 'Багаторівневий субпідрядник',
  },
  saveProfile: 'Зберегти профіль',
  notificationSettings: 'Налаштування сповіщень',
  announcementNotifications: 'Сповіщення про анноунсмент',
  deliveryNotifications: 'Сповіщення про доставки',
  activityScheduleNotifications: 'Сповіщення про роботи',
  scheduleNotifications: 'Сповіщення про розкладу',
  trackerNotifications: 'Сповіщення Трекера',
  logisticsNotifications: 'Сповіщення логістики',
  formsNotifications: 'Сповіщення форм',
  formNotifications: 'Сповіщення форми',
  scannerNotifications: 'Сповіщення сканера',
  iWouldLikeToReceiveDeliveryTickets: 'Я хочу отримувати TTH',
  iWouldLikeReceiveMy: 'Я хочу отримувати мої',
  inApp: 'В додатку',
  sms: 'СМС',
  bySms: 'За допомогою СМС',
  byEmail: 'Електронною поштою',
  successfullyUpdated: 'Успішно оновлено',
  wrongPhoneNumberFormat: 'Некоректний формат номера телефону',
  userProfileDescriptions: {
    name: `Ваше ім'я буде використовуватися коли Ви спілкуєтесь з іншими учасниками команди у ${brandName}.`,
    projectRoles:
      'Ваші ролі використовуються для забезпечення відповідності сповіщень та робочих процесів, в яких ви берете участь. ' +
      'Подальша кастомізація можлива у розділі Уподобання в Налаштуваннях.',
    notifications:
      `Додатки ${brandName} надсилатимуть вам сповіщення про роботу, учасником якої ви є. ` +
      'Ці сповіщення з’являються в додатку поруч із піктограмою дзвінка у верхньому правому куті сторінки, ' +
      'якщо ви ввели свій номер телефону у своєму профілі за допомогою SMS-повідомлень, надісланих на ваш телефон, ' +
      'а деякі зведення сповіщень можуть бути надіслані на вашу електронну адресу. ' +
      'Ви можете вимкнути будь-яку з цих опцій нижче, щоб більше не отримувати сповіщення цим методом.',
  },
  whitelistedDomains: 'Домени в білому списку',

  projectAdminSummary: 'Резюме адміністратора проєкта',
  lastUpdated: 'Востаннє оновлено',
  dateByName: (date: string, name: string) => date + ', ' + name,
  uploadProjectSchedule: 'Завантажте розклад проєкта',
  fileUploaded: 'Завантажено файл: ',
  totalActivities: 'Усього робіт',
  totalUsers: 'Усього користувачів',
  uploadProcurementPlan: 'Завантажте план закупівель',
  bpAssignedToCompanies: (amount: number, totalAmount: number) =>
    amount + ' з ' + totalAmount + ' бід пакетів доручено компаніям.',
  downloadCurrentValuesAsCsv: (toLink: (aText: string) => any) => [
    toLink('Завантажити'),
    ' поточні значення як csv файл.',
  ],
  uploadTeamList: 'Завантажте список членів команди',
  currentTeamMembers: (amount: number) =>
    'Членів команди на даний момент: ' + amount,
  emptyTagTypes: (tags: TagType[]) =>
    `Деякі з необхідних тегів типу (${tags
      .map(tag => getBandTitleByTagType(tag))
      .toString()}) порожні для деяких компаній. Для них будуть встановлені значення за замовчуванням.`,
  adminSummaryDescriptions: {
    projectDetails:
      'Встановіть назву та місцезнаходження вашого проєкта, так що Ви його зможете легко знайти у селекторі проєктів',
    projectSchedule: `Завантажуйте ваш оновлений розклад із P6 так часто як забажаєте. Не хвилюйтеся — Ви не втратите жодних данних у ${brandName}.`,
    procurementPlan:
      'Завантажуйте ваш оновлений план закупівель у міру того, як збільшується кількість субпідрядників у проєкті ' +
      'та уточнюйте очікувані значення для кожного бід пакета.',
    teamList:
      'Завантажуйте список членів команди у міру того, як нові члени приєднуються до проєкта. ' +
      "Включення необов'язкової інформації, такої як внутрішня приналежність до відділу / команди, " +
      'полегшує призначення ресурсів на роботи.',
  },
  integrationsSetupDescriptions: {
    invalidToken: 'Не валідний Токен',
  },

  project: 'Проєкт',
  notificationsRulesSetupDescriptions: {
    helperText1: (entityType: string) =>
      'Правило надсилатиме сповіщення списку користувачів про всі майбутні ' +
      entityType +
      ' з набором визначених тегів',
    helperText2:
      'Теги можна використовувати окремо або комбінувати з булевими операторами. Наприклад:',
  },

  selectProject: 'Оберіть проєкт',
  selectBookingTime: 'Виберіть час бронювання',
  newProject: 'Новий проєкт',
  projectName: 'Назва проєкту',
  projectCode: 'Код проєкту',
  projectLocation: 'Місцезнаходження проєкту',
  projectLogo: 'Лого проєкту',
  streetAddress: 'Адреса вулиці',
  city: 'Місто',
  state_region: 'Штат',
  zipCode: 'Індекс',
  country: 'Країна',
  projectTimeSettingsAndCalendar: 'Налаштування часу проєкту і календар',
  QRCodes: 'QR коди',
  scanStations: 'Станції сканування',
  projectAdmins: 'Адміни проєкту',
  setTheTimeZoneForThisProject: 'Вкажіть часовий пояс для цього проєкту',
  projectTimeZone: 'Часовий пояс проєкту',
  dateFormat: 'Формат часу',
  maturixProjectName: 'Назва проєкту Maturix',
  maturixTemperatureUnit: 'Одиниці виміру температури Maturix',
  maturixStrengthUnit: 'Одиниці виміру сили Maturix',
  maturixStations: 'Станції Maturix',
  undefinedSection: 'Невизначена секція',
  setTheDateTimeFormatForThisProject:
    'Вкажіть формат дати і часу для цього проєкту',
  localTimezone: 'Місцевий часовий пояс',
  us: 'США',
  international: 'Міжнародний',
  defaultWorkingDays: 'Робочі дні за замовчуванням',
  customProjectClosures: 'Спеціальні неробочі дні для проєкту',
  holidaysAndSiteClosures: 'Свята й спеціальні неробочі дні для проєкту',
  addCustomProjectClosure: 'Додати спеціальні неробочі дні для проєкту',
  addYourNameAndCompanyToAccess: 'Додайте своє ім’я та компанію для доступу',
  forTheFuture: easterEggMode ? 'Як Бог дасть' : 'На майбутнє',
  projectType: 'Тип проєкту',
  apps: 'Додатки',
  visitors: 'Відвідувачі',
  admins: 'Адміни',
  theListOfProjectsAdministrators: 'Список адміністраторів проєкта',
  addNew: 'Додати новий',
  addNewAdmin: 'Додати нового адміна',
  addNewContact: 'Додати новий контакт',
  contactsVisibleToVisitors: 'Контакти, котрі видно відвідувачам',
  defaultWorkingHours: 'Робочі години за замовчуванням',
  requireVisitorsToCreateAccount:
    'Вимагати від відвідувачів створювати обліковий запис',
  allowVisitorsToRequestAForm: 'Дозволити відвідувачам робити запит на форму',
  limitUserDirectoryForVisitors:
    'Обмежити каталог користувачів для відвідувачів',
  struxHubHome: `Головна сторінка ${brandName}`,
  struxHubForms: `Форми у ${brandName}`,
  bookDemo: 'Замовити демо',
  app: 'Додаток',
  myAndAppWithEmptySpaces: 'Мій    Додаток',
  showInNavigation: 'Показувати у панелі навігації',
  setAsDefaultLandingPage: 'Встановити цільовою сторінкою за замовчуванням',
  projectDetailsDescriptions: {
    projectName: `Це назва Вашої робочої області у ${brandName}. Ви завжди зможете змінити назву пізніше`,
    projectNameError:
      'Назва не може бути пустою і має містити лише літери, чиста, пробіли, _ , -',
    projectCodeError:
      'Код проекту не може бути порожнім і не повинен містити жодних спеціальних символів. Максимальна довжина коду проекту становить 5 символів',
    location: `Місцезнаходження проєкта буде використовуватися для ідентифікації проєкта у Вашому портфоліо у ${brandName}.`,
    workDays:
      'Ви можете вказати спеціальні робочі дні на проєкті. Ці неробочі дні не будуть ' +
      'враховуватися у підрахунках загального флоату або тривалостей. Якщо робота зазначена ' +
      'у неробочі дні, вона буде відстежуватися.',
    projectType:
      `Можливості проєкта у ${brandName} можуть бути увімкнені тут. Кожен модуль ` +
      'вибранний нижче вмикає додаткові рівні функціональності та ' +
      'взаємодій користувача. У багатьох випадках модулі доповняльні, ' +
      'наприклад дозволити відстеження Доставок та Матеріалів для ' +
      'кожної запланованої Роботи. Один з модулів повинен бути увімкненим.',
    requireToCreateAccountTooltip:
      `Скануючи QR код ‘Головна сторінка ${brandName}’ (див. нижче), ` +
      'відвідувачів запитають увести ім’я, прізвище та електронну адресу.',
    limitedUserDirectoryTooltip:
      'Якщо каталог користувачів обмежено, вказані користувачі або команди ' +
      'можуть бути зазначені нижче як контакти.',
    allowToCreateFormTooltip:
      `Скануючи QR код ‘Форми ${brandName}’ (див. нижче), ` +
      'відвідувачі будуть направлені до списка форм.',
    projectCodeTooltip:
      'Код проекту доступний лише для читання і не може бути змінений після створення проекту',
    changeProjectLocationWarning:
      'УВАГА: Часовий пояс проєкта відрізняється від часового поясу розташування проєкта',
  },
  countryValues: countries,
  timezoneValues: timezones,
  timezoneWithOffsets: (name: string, offsets: string[]) =>
    `${name} (GMT ${offsets.join('/')})`,

  addCustomProjectClosures: 'Додати спеціальні неробочі дні для проєкта',
  closureName: 'Назва інтервала',
  closureStartDate: 'Дата початку закриття',
  closureEndDate: 'Дата закінчення закриття',

  loadingTeamMembers: 'Завантажуються члени команди',
  loadingMonitoringData: 'Завантажуються данні моніторингу',
  xTeamMembersSelected: (x: number) =>
    `Вибрано ${x} член${ending(x, 'а', 'ів', 'ів')} команди`,
  xCompaniesSelected: (x: number) =>
    `Вибрано ${x} компан${ending(x, 'а', 'ів', 'ів')}`,
  importExportTeamMembers: 'Імпортувати/Експортувати членів команди',
  teamMembersDropdownStates: {
    downloadTeamMembers: 'Завантажити членів команди у .csv',
    uploadTeamMembers: 'Додати членів команди із .csv',
    viewCompanyDirectory: 'Переглянути адресну книгу компанії',
    connectToProcore: 'Підключитися до списку команди у Procore',
  },
  selectNotInvited: 'Вибрати не запрошених',
  selectStatusRequested: 'Виберіть СТАТУС Запитано',
  setNotInvited: 'Відкликати запрошення',
  addNewProjectMember: 'Додати нового члена команди',
  uploadYourUpdatedProjectMemberListCsvFile:
    'Оновіть список членів команди із csv файла.',
  orDragYourFileHere: 'Або перетягніть Ваш файл сюди.',
  uploadYourScheduleFileInP6XerOrExcelXlsxXlsmFormat:
    'Завантажте Ваш розклад у P6 (.xer) або Excel (.xlsx, .xlsm) форматі',
  xIsUploaded: (x: string) => `${x} завантажено`,
  sendInviteLinkToUser: 'Надіслати посилання із запрошенням користувачу',
  addUser: 'Додати користувача',
  editUsers: (x: number) => `Редагувати користувач${x === 1 ? 'а' : 'ів'}`,
  userValidationErrors: {
    firstNameRequired: "Поле ім'я є обов'язковим",
    lastNameRequired: "Поле прізвище є обов'язковим",
    emailRequired: "Поле електронна адреса є обов'язковим",
    invalidEmail: 'Електронна адреса недійсна',
    invalidPhone: 'Номер телефону не дійсний',
    emailIsTaken: 'Інший член команди має таку електронну адресу',
    phoneIsTaken: 'Інший член команди має такий самий номер телефону',
    memberWithSuchEmailIsAlreadyAdded:
      'Користувач з такою електронною адресою вже є у проєкті',
    memberWithSuchPhoneNumberIsAlreadyAdded:
      'Користувач з таким номером телефону вже є у проєкті',
    companyRequired: "Поле компанія є обов'язковим",
  },

  companyValidationErrors: {
    nameRequired: "Поле з ім'ям компанії є обов'язковим",
    emailRequired: "Електронна адреса компанії є обов'язковим",
    invalidEmail: 'Електронна адреса компанії недійсна',
    invalidPhone: 'Номер телефону не дійсний',
    emailIsTaken: 'Інша компанія має таку електронну адресу',
    somethingFilledIncorrectly: 'Щось заповнено неправильно',
    companyTypeIsRequired: "Тип компанії є обов'язковим",
    codeRequired: "Код компанії є обов'язковим",
    codeIncorrectLength: 'Довжина коду має бути між 3 і 5 символами',
    codeIsNotUnique:
      'Компанія з таким кодом вже існує, поміняйте код, щоб зробити його унікальним',
  },
  companyLinkingErrors: {
    anErrorOccurredWhileLinking: 'Під час зв’язування сталася помилка',
    anErrorOccurredWhileUnlinking: 'Під час від’єднання сталася помилка',
    anErrorOccurredWhileFetchingGlobalStorage:
      'Під час отримання даних з глобального сховища сталася помилка',
  },
  companyMerging: {
    confirmation: (sourceCompaniesText: string, targetCompanyText: string) =>
      `Ви підтверджуєте злиття ${sourceCompaniesText} із ${targetCompanyText}?`,
  },

  somethingWentWrongDuringAPIInteraction:
    'Щось пішло не так під час взаємодії з API',

  confirmDeactivationOfTheFollowingXCompanies: (x: number) =>
    `Підтвердити деактивацію наступн${ending(x, 'ої', 'их', 'их')} ` +
    `${x} компан${ending(x, 'ії', 'ій', 'ій')}?`,
  confirmActivationOfTheFollowingXCompanies: (x: number) =>
    `Підтвердити активацію наступн${ending(x, 'ої', 'их', 'их')} ` +
    `${x} компан${ending(x, 'ії', 'ій', 'ій')}?`,
  confirmDeletionOfTheFollowingXAnnouncements: (x: number) =>
    `Підтвердити видалення наступн${ending(x, 'ого', 'их', 'их')} ` +
    `${x} оголошен${ending(x, 'ня', 'ь', 'ь')}?`,
  confirmDeletionOfTheFollowingXProjectMembers: (x: number) =>
    `Підтвердити видалення наступн${ending(x, 'ого', 'их', 'их')} ` +
    `${x} член${ending(x, 'у', 'ів', 'ів')} команди?`,
  confirmInvitationOfTheFollowingXProjectMembers: (x: number) =>
    `Підтвердити запрошення для наступн${ending(x, 'ого', 'их', 'их')} ` +
    `${x} член${ending(x, 'у', 'ів', 'ів')} команди?`,
  confirmSettingAsNotInvitedOfTheFollowingXProjectMembers: (x: number) =>
    `Підтвердити відкликання запрошення для ` +
    `наступн${ending(x, 'ого', 'их', 'их')} ` +
    `${x} член${ending(x, 'у', 'ів', 'ів')} команди?`,
  confirmInvitation: 'Підтвердити запрошення',
  confirmSettingUsersAsNotInvited: 'Підтвердити відкликання запрошення',
  confirmDeletion: 'Підтвердити видалення',
  confirmActivation: 'Підтвердити активацію',
  confirmDeactivation: 'Підтвердити деактивацію',
  confirmDeletionOfTheFollowingXPlannedManpower: (x: number) =>
    `Підтвердити видалення наступн${ending(x, 'ої', 'их', 'их')} ` +
    `${x} одиниц${ending(x, 'і', 'ь', 'ь')} робочої сили?`,
  confirmDeletionOfTheFollowingXBidPackages: (x: number) =>
    `Підтвердити видалення наступн${ending(x, 'ого', 'их', 'их')} ` +
    `${x} бід пакет${ending(x, 'у', 'ів', 'ів')}?`,
  confirmDeletionOfActivities: (x: number) =>
    `Підтвердити видалення ${x} обран${ending(x, 'ої', 'их', 'их')} роб${ending(
      x,
      'оти',
      'іт',
      'іт',
    )}?`,
  selectProcoreProject: 'Вибрати проєкт з Procore',
  selectProcoreCompany: 'Вибрати компанію з Procore',
  noUnit: 'Жодної одиниці виміру',
  noCompanies: 'Жодної компанії',
  noDirectoryAccess: 'Без доступу до списка користувачів',
  xUsers,
  xUsersShowing: (x: number) =>
    `${x} користувач${ending(x, '', 'і', 'ів')} показано`,
  xTeams: (x: number) => `${x} команд${ending(x, 'а', 'и', '')}`,
  addXUsers: (x: number) => {
    if (!x) {
      return 'Додати'
    }
    // 'Add 1 user' or 'Add x users'
    return `Додати ${x} користувач${ending(x, 'а', 'ів', 'ів')}`
  },
  xFiles,

  multiLevelScreen: 'Мапи всіх поверхів',
  levelsViewsWithZoneSummary: 'Мапа поверха із резюме зони',
  levelsViewsWithCompanySummary: 'Мапа поверха із резюме компанії',
  gantt: 'Гантт',
  presentationMode: 'Режим презентації',
  play: 'Відтворити',
  stop: 'Зупинити',
  turnOn: 'Увімкнути',
  presentationScreens: 'Сторінки презентації',
  xMinutes: (x: number) => `${x} хвилин${ending(x, 'у', 'и', '')}`,
  order_noun: 'Порядковий номер',
  order_number: (x: number) => `${x}ий`,
  display: 'Показувати',
  presentationModeDescriptions: {
    logisticsEntity: 'Відкрити оголошення',
    multiLevelScreen:
      'Показує хід виконання робіт та продуктивність робочої сили порівнюючи ' +
      'фактичні дані із запланованими для всіх поверхів будівлі. ' +
      'Прокручує сторінку, щоб показати всі поверхи.',
    levelsViewsWithZoneSummary:
      'Проходить через кожен поверх будівлі, ' +
      'показуючи хід виконання робіт та продуктивність робочої сили для кожної зони. ' +
      'Прокручує сторінку, щоб показати дані для кожної зони.',
    levelsViewsWithCompanySummary:
      'Проходить через кожен поверх будівлі, ' +
      'показуючи сукупну продуктивність компанії. ' +
      'Прокручує сторінку, щоб показати дані для кожної компанії.',
    gantt:
      'Показує гантт діаграму для поточного періоду. ' +
      'Прокручує сторінку, щоб показати всі роботи.',
    deliveries: 'Показує тижневий календар доставок для поточного тижня.',
    logistics: 'Показує мапу логістики',
    confirmText:
      'Режим презентації вийде із системи та встановить цей пристрій в режим відтворення. ' +
      'Щоб вийти з режиму презентації, перейдіть у налаштування. ' +
      'Вам знадобиться увійти в систему повторно після того, як завершиться режим презентації.',
    header:
      'Увімкнення режиму презентації здійснить вихід із системи, ' +
      'і запустить цей пристрій у режимі тільки для огляду',
    periodOfInactivity:
      'Презентація запуститься автоматично після періоду бездіяльності в',
    stop:
      'Припинення режиму презентації здійснить вихід ' +
      'до сторінки входу, де Ви зможете увійти у Ваші проєкти.',
  },

  include: 'Включати',
  formConfiguration: 'Конфігурація форми',
  saveConfiguration: 'Зберігти конфігурацію',
  preTaskChecklist: 'Контрольний список дій передуючих завданню',
  requireDailyInspections: 'Потребувати щоденної перевірки',
  inspectionFrequency: 'Періодичність перевірок',
  deadLineForRecordingAnInspection: 'Кінцевий термін запису інспекції',
  checklist: 'Контрольний список',
  instructions: 'Інструкції',
  xOptions: (x: number) => `${x} опці${ending(x, 'я', 'ії', 'ій')}`,
  NA: 'Не застосовується',
  remove: 'Видалити',
  typeOfList: 'Тип списку',
  addAdditionalItem: 'Додати елемент',
  enterInstruction: 'Введіть інструкцію',
  enterTheXDigitCodeSentTo: () => `Введіть цифровий код, на який надіслано на`,
  areYouSureWantToDeleteInstructionItem:
    'Ви впевнені, що хочете видалити елемент інструкції?',
  instructionListTypes: {
    enumerated: 'Нумерований',
    bulleted: 'Маркований',
    none: 'Ні той, ні інший',
  },
  fieldStates: {
    mandatory: 'Обов’язкове',
    optional: 'Необов’язкове',
    hidden: 'Приховане',
  },

  revertingProjectData: 'Повернення до попередніх даних проєкта',
  loadingUpdatingProject: 'Завантажується/Оновлюється проєкт',
  downloadProjectDataCsv: 'Завантажити дані проєкта у .csv',
  exploreYourScheduleInStruxHub: `Переглянути Ваш розклад у ${brandName}`,
  scheduleIsNotUploaded: 'Розклад не завантажено',
  gettingXerProjects: 'Отримання проєктів',
  gettingExcelSheets: 'Отримання аркушів excel',
  parsingTheScheduleFileStarted: 'Обробку файла розкладу розпочато',
  readingScheduleFile: 'Читаємо файл розкладу',
  extractingScheduleData: 'Витягуємо дані розкладу',
  xEntriesInTheFile: (x: number) =>
    `${x} запис${ending(x, '', 'и', 'ів')} у файлі`,
  savingScheduleDataCollections: 'Збереження колекцій даних розкладу',
  xOfYEntriesSaved: (x: number, y: number) =>
    `${x} з ${y} запис${ending(y, 'а', 'ів', 'ів')} збережено`,
  recalculatingStatusUpdates: 'Перераховуємо статуси робіт',
  savingSchedule: 'Збереження розкладу',
  uploadingScheduleFailedAt:
    'Завантаження розкладу завершилося невдало на кроці',
  uploadingScheduleDone: 'Завантаження розкладу завершено',
  seeGanttInStruxHub: `Дивитися Гантт у ${brandName}`,
  noActivitiesFound: 'Жодних робіт не знайдено',
  continueToNextStep: 'Продовжити до наступного кроку',
  thereAreNoProjectsInProvidedFile: 'У наданому файлі немає проєктів',
  thereAreNoInstancesToDisplay: (instancesName: string = 'Екземплярів') =>
    `Немає ${instancesName.toLowerCase()} для відображення`,
  scheduleUpdateSummary: 'Резюме оновлення розкладу',
  current: 'На даний момент',
  upload_noun: 'Завантаження',
  fileName: 'Назва файла',
  dateUploaded: 'Дата завантаження',
  uploadScheduleDescriptions: {
    warning: (delta: number) =>
      `${delta} Роб${ending(delta, 'ота', 'оти', 'іт')}, ` +
      `як${ending(delta, 'а', 'і', 'і')} ` +
      `відсутн${ending(delta, 'а', 'і', 'і')} ` +
      `в оновленому графіку, буде видалено. Усе, що пов'язано ` +
      `із ц${ending(delta, 'ією', 'ими', 'ими')} ${delta} ` +
      `Роб${ending(delta, 'отою', 'отами', 'отами')}, ` +
      `буде також видалено із ${brandName}.`,

    chooseProject:
      'У цьому P6 xer файлі знаходяться дані з декількох проєктів. ' +
      'Будь ласка оберіть проєкт для імпортування. Дані цього проєкта мають ' +
      'пасувати до тих даних, що містяться в інших джерелах ' +
      '(напр. План закупівель, Список членів команди).',

    chooseSheet:
      'Вибраний файл містить декілька листів. ' +
      'Будь ласка оберіть лист для імпорту.',

    mergeData: 'Оновити поточний розклад.',

    mapFields:
      'Будь ласка виберіть, які поля будуть використовуватися для кожного стовпчика',
  },
  activityId: 'ID роботи',
  activityUniqueId: 'Унікальний ID роботи',
  activityName: 'Назва роботи',
  activityCode: 'Код роботи',
  activityStatus: 'Статус роботи',
  activityPercentComplete: 'Відсоток виконання роботи',
  plannedActivityStart: 'Запланований початок роботи',
  plannedActivityFinish: 'Запланований кінець роботи',
  actualActivityStart: 'Актуальний початок роботи',
  actualActivityFinish: 'Актуальний кінець роботи',
  resourceId: 'ID ресурса',
  resourceName: 'Назва ресурса',
  trade: 'Торгівля',
  assignee: 'Призначена особа',
  primarySafetyHazard: 'Первинна загроза безпеки',
  secondarySafetyHazard: 'Вторинна загроза безпеки',
  categoryOfVariance: 'Категорія варіації',
  categoryOfVarianceOpened: 'Категорію варіації відкрито',
  activityCodeTypes: 'Типи кодів робіт',
  resources: 'Ресурси',
  uploadAnother: 'Завантажити інший',
  next: 'Далі',
  saveAndViewUpdatedGantt: 'Зберегти та переглянути оновлений Гантт',
  lastUpload: 'Востаннє завантажено',
  companies: 'Компанії',
  targetCompany: 'Цільова компанія',
  activitiesWithTheFollowingActivityCodesAreExcluded:
    'Роботи з наступними кодами робіт виключені',
  activityCodesSelected: 'Вибрано коди робіт',
  scheduleRestoringConfirmation: 'Підтверження відновлення розкладу',
  doYouConfirmRestoringFileFromDate: (file: string, wrappedDate) => [
    `Чи Ви підтверджуєте повернення ${file} від `,
    wrappedDate,
    '?',
  ],
  doYouWantToSaveChanges: 'Ви хочете зберегти зміни?',
  doYouAlreadyHaveAnAccount: 'У вас вже є обліковий запис?',
  uploadHistory: 'Історія завантажень',
  restore: 'Відновити',
  chooseProject: 'Оберіть проєкт',
  chooseSheet: 'Оберіть лист',
  mapFields: 'Поставити відповідність полів',
  xActivities: (x: number) => `${x} Роб${ending(x, 'ота', 'оти', 'іт')}`,
  xHours,
  xLocations: (x: number) => `${x} Локац${ending(x, 'ія', 'ії', 'ій')}`,
  xColumns: (x: number) => `${x} Стовпчик${ending(x, '', 'и', 'ів')}`,
  cancelUpload: 'Відмінити завантаження',
  selectProjectMembersLinkedToX: (x: string, isPlural: boolean) =>
    `Виберіть член${isPlural ? 'ів' : 'а'} команди, ` +
    `як${isPlural ? 'і' : 'ий'} належ${
      isPlural ? 'а' : 'и'
    }ть до категорії ${x}`,
  selectActivityCodesLinkedToX: (x: string, isPlural: boolean) =>
    `Виберіть код${isPlural ? 'и' : ''} роб${isPlural ? 'іт' : 'оти'}, ` +
    `як${isPlural ? 'і' : 'ий'} належ${
      isPlural ? 'а' : 'и'
    }ть до категорії ${x}`,
  selectionOf1ProjectMemberIsPermitted: `Дозволяється вибрати 1 члена команди.`,
  selectionOf1ActivityCodeIsPermitted: `Дозволяється вибрати 1 код роботи.`,
  multipleSelectionsArePermitted: `Дозволяється множинний вибір.`,
  excludeSectionsOfTheScheduleOptional: `Виключити розділи розкладу (необов'язково)`,
  activityFilterDialogDescriptions: {
    hiddenCodes:
      'Якщо Ви бажаєте приховати деякі розділи розкладу, ' +
      'відмітьте коди робіт, які відповідають роботам, які ви бажаєте приховати.',
  },
  only: 'Тільки це',
  noCompany: 'Без компанії',
  noMessage: 'Без повідомлення',
  submit_verb: 'Затвердити',
  showAllXActivities: (x: number) =>
    `Показати всі ${x} роб${ending(x, 'оту', 'оти', 'іт')}`,
  showXOfYActivities: (x: number, y: number) =>
    `Показати ${x} з ${y} роб${ending(y, 'оти', 'іт', 'іт')}`,
  verification: 'Перевірка',
  xActivitiesAdded: (x: number, wrappedX) => [
    wrappedX,
    ` роб${ending(x, 'ота', 'оти', 'іт')} додано.`,
  ],
  seeDetails: 'Переглянути деталі',
  xTypeActivityCodesAdded: (x: number, wrappedX, type: string) => [
    wrappedX,
    ` код${ending(x, '', 'и', 'ів')} ` +
      `роб${ending(x, 'оти', 'іт', 'іт')} типу ${type} додано.`,
  ],

  activityFilters: 'Фільтри робіт',
  scheduleFilters: 'Фільтри розкладу',
  customFilter: 'Фільтр користувача',
  filterNameCannotBeEmpty: 'Назва фільтра не може бути порожньою',
  suchFilterNameIsAlreadyTaken: 'Фільтр з такою назвою вже є',
  struxHubData: `Дані зі ${brandName}`,
  scheduleData: 'Дані з розкладу',
  members: 'Члени',
  projectMembers: 'Члени проєкта',
  activityCodes: 'Коди робіт',
  projectMembersSelected: 'Вибрано членів проєкта',
  renameFilterOptional: "Переіменувати фільтр (необов'язково)",
  filterName: 'Назва фільтра',
  xCompaniesIdentified: (x: number) =>
    `${x} Компані${ending(x, 'ю', 'ї', 'й')} ідентифіковано`,
  xCompaniesShowing: (x: number) =>
    `${x} Компані${ending(x, 'ю', 'ї', 'й')} показано`,
  xCompanies: (x: number) => `${x} компані${ending(x, 'ю', 'ї', 'й')}`,
  activityCodeSelected: 'Вибрано код роботи',
  responsibleUser: 'Відповідальна Особа',
  responsibilityCode: 'Код відповідальності',
  responsibilityDescription: 'Опис відповідальності',
  contractN: 'Контракт #',
  plannedValueS: 'Запланована вартість ($)',
  uploadAnUpdatedXlsxFile: 'Завантажити оновлений файл xlsx',
  downloadCurrentListAsXlsxFile: 'Отримати поточний список у файлі xlsx',
  addNewEntry: 'Додати новий запис',
  addNewUser: 'Додати нового користувача',
  previous: 'Попередньо',
  activityFiltersDescriptions: {
    confirmDeletionOfX: (x: string) =>
      `Ви упевнені, що бажаєте видалити фільтр ${x}?`,
    title:
      `${brandName} трансформує Ваші коди робіт та дані P6 у зручні ` +
      'фільтри для Ваших команд, що дає змогу легко знаходити свої роботи ' +
      'у веб та мобільному сайтах. Ви можете налаштувати ці фільтри нижче ' +
      'та оновлювати їх будь-якої миті.',
    selectProjectMembers:
      'Виберіть члена(ів) команди, якого(их) Ви бажаєте ' +
      'додати в цей фільтр. Дозволяється множинний вибір.',
    selectActivityCodes:
      'Виберіть код(и) роботи(іт), якого(их) Ви бажаєте ' +
      'додати в цей фільтр. Дозволяється множинний вибір.',
    company:
      'Цей фільтр дозволяє користувачам вибирати ' +
      'компанії, які відповідають за роботи у Вашому проєкті.',
    level:
      'Цей фільтр дозволяє користувачам вибирати ' + 'поверхи Вашої будівлі.',
    zone:
      'Цей фільтр дозволяє користувачам вибирати ' +
      'різноманітні зони кожного поверха будівлі.',
    activityCode:
      'Цей фільтр дозволяє користувачам вибирати ' +
      'додаткові коди робіт, які ви бажаєте відслідковувати.',
    projectMember:
      'Цей фільтр дозволяє користувачам вибирати ' +
      'членів команди, яких ви бажаєте відслідковувати.',
    associateCompanies:
      'Будь ласка, вкажіть, як саме Ви асоціюєте компанії ' +
      'з Вашими роботами у розкладі.',
    resourceCompanies:
      'Назви компаній вказані у полі Ресурс у .xer файлі. ' +
      'Зауважте: це є значенням за замовчуванням, якщо Ваш розклад містить Ресурси.',
    bpCompanies:
      'Назви компаній вказані в окремому файлі xlsx та ' +
      'асоціюються з роботами за допомогою кодів робіт.',
    bpStep1:
      'Виберіть код роботи у Вашому файлі .xer, ' +
      'за яким слід асоціювати відповідальні компанії.',
    bpStep2:
      'Завантажте файл excel, який містить щонайменше 2 колонки ' +
      'Код відповідальності та Компанія.',
  },

  trackingHierarchy: 'Ієрархія у трекері',
  defaultHierarchies: 'Ієрархії за замовчуванням',
  defineBandsEnPermissionsForYourTrackingHierarchy:
    'Визначте ланки та дозволи для Вашої ієрархії у трекері',
  setupDefaultHierarchiesForDifferentViews:
    'Налаштувати ієрархії за замовчуванням для різних виглядів',
  selectBands: 'Вибрати ланки',
  selectControlBand: 'Вибрати управляючу ланку',
  band: 'Ланка',
  addBand: 'Додати ланку',
  activityUpdatesOn: 'Оновлення робіт увімкнено',
  activityUpdatesOff: 'Оновлення робіт вимкнено',
  view_noun: 'Вигляд',
  wbs: 'ІСР', // ієрархічна структура робіт
  webGantt: 'Гантт на вебі',
  webSitemap: 'Мапа на вебі',
  mobile: 'Мобільний',
  tablet: 'Планшет',
  hierarchyPreview: 'Попередній перегляд ієрархії',
  unassigned: 'Непризначене',
  photo: 'Фото',
  completed: 'Завершено',
  startEarly: 'Розпочати раніше',
  PComplete: '% Виконання',
  unassignedActivity: 'Непризначена робота',
  trackingHierarchyDescriptions: {
    title:
      'Налаштуйте Вашу ієрархію трекера нижче, щоб розпочати відслідковувати Ваш розклад ' +
      'Вашою командою з будмайданчика, Вашими субпідрядниками або і тими, і тими.',
  },
  editCompany: 'Редагувати компанію',
  addDataToSchedule: 'Додати дані до розкладу',
  plannedCompanyManpower: 'Запланована робоча сила компанії',
  dataToScheduleDescriptions: {
    title:
      `За допомогою ${brandName} Ви Маєте можливість швидко додавати дані ` +
      'до Вашого розкладу, імпортуючи їх з файлів excel',
  },

  formField: 'Поле форми',
  showField_question: 'Показати поле?',
  mandatory_question: "Обов'язкове?",
  fieldShown: 'Поле показано',
  mandatory: "Обов'язкове",
  addNewListElement: 'Додати новий елемент до списка',
  formPreview: 'Попередній перегляд форми',

  setThresholdForDDays: 'Задати поріг для ∆ Днів',
  setThresholdForDManpower: 'Задати поріг для ∆ Робочої сили',
  onTrack: 'За планом',
  watch: 'Увага',
  behind: 'Позаду',
  dDays: '∆ Днів',
  days: 'Дні',
  percent: 'Відсоток',
  number: 'Число',
  daysBehind: 'Днів позаду',
  daysAhead: 'Днів попереду',
  XBehind: '% позаду',
  XAhead: '% попереду',
  under: 'Нижче',
  over: 'Вище',
  XUnder: '% Нижче',
  XOver: '% Вище',
  dManpower: '∆ Робочої сили',
  sitemapsDescriptions: {
    title:
      `За допомогою ${brandName} Ви маєте можливість легко з'єднати ` +
      'Ваш розклад з Вашими мапами. ' +
      'Нижче Ви можете налаштувати порогові значення для метрик ∆ Днів та ' +
      '∆ Робочої сили, які використовуються для візуалізації прогресу у розкладі ' +
      'та поточній робочій силі відповідно.',
    configure: 'Налаштовуйте порогові значення для Розкладу і робочої сили',
  },

  inbox: 'Вхідні',
  unread_2form: 'Непрочитане',
  read_2form: 'Прочитане',
  archived: 'Архівоване',
  requested: 'Запитане',
  changed: 'Змінене',
  scheduled: 'Заплановане',
  onSite: 'На місці',
  denied: 'Відхилено',
  passedInspection: 'Перевірку пройдено',
  failedInspection: 'Перевірку не пройдено',
  done_plural: 'Виконані',
  flags: 'Прапори',
  formSubmitted: 'Форму подано',
  formAccepted: 'Форму схвалено',
  formFinished: 'Форму завершено',
  formClosed: 'Форму закрито',
  formChanged: 'Форму змінено',
  formDenied: 'Форму відхилено',
  formReadyToInspect: 'Форма готова до інспектування',
  formActivated: 'Форму активовано',
  formReviewed: 'Форму оглянуто',
  formFailed: 'Форма не пройдена',
  formPassed: 'Форма пройдена',
  formBicInspected: 'Форма BIC інспектована',
  formDeleted: 'Форма видалена',
  formFollowed: 'Підписка на форму',
  rfis: 'Запити інформації',
  scheduleComments: 'Коментарі щодо розкладу',
  onTime: 'За розкладом',
  late: 'Із запізненням',
  performance: 'Продуктивність',
  parameters: 'Параметри',
  onTimeWindowHr: 'Вікно за розкладом (год)',
  showLess: 'Показати менше',
  showMore: 'Показати більше',
  all_items: 'Все',
  none: 'Нічого',
  unArchive: 'Розархівувати',
  archive: 'Заархівувати',
  read: 'Прочитати',
  unread: 'Позначити непрочитаним',
  wwpColoring: 'Забарвлення ТРБ', // тижневого робочого плану
  useCSIMaterialCategories: 'Використовувати категорії матеріалів від ІБС', // Інститут будівельних специфікацій
  noNotificationsPassedFilters: 'Жодних сповіщень за вказаними фільтрами',
  replyToActivityFlag: 'Відповісти на прапор',
  activityFlag: 'Робочий прапор',
  replyToActivityRfi: 'Відповісти на Запит інформації',
  activityRfi: 'Робочий запит інформації',
  replyToScheduleComment: 'Відповісти на коментар щодо розкладу',
  replyToCategoryOfVariance: 'Відповісти на Категорію варіації',
  replyToSafetyHazard: 'Відповісти на Загрозу безпеки',
  scheduleComment: 'Коментар щодо розкладу',
  activityUpdate: 'Оновлення роботи',
  scheduleUpdate: 'Оновлення розкладу',
  safetyHazard: 'Загроза безпеки',
  safetyHazardOpened: 'Загрозу безпеки відкрито',
  bulkEdit: 'Масове редагування',
  bulkUpdate: 'Масове оновлення',
  replyToActivityUpdate: 'Відповісти на оновлення роботи',
  replyToScheduleUpdate: 'Відповісти на коментар щодо розкладу',
  replyToBulkUpdate: 'Відповісти на масове оновлення',
  activityFollowed: 'Робота відслідковується',
  deliveryFollowed: 'Доставка відслідковується',
  deliveryRequested: 'Додано запит на доставку',
  deliveryRequestedByLocationClosure: (id: string) =>
    `На доставку (${id}) вплинуло закриття локації, тому її потрібно перенести`,
  deliveryScheduled: 'Доставка додана до розкладу',
  deliveryOnSite: 'Доставка на місці',
  deliveryPassedInspection: 'Доставка пройшла перевірку',
  deliveryFailedInspection: 'Доставка не пройшла перевірку',
  deliveryInspected: 'Доставка перевірена',
  deliveryDone: 'Доставка виконана',
  deliveryIncompletelyDone: 'Доставка неповністю виконана',
  deliveryUpdated: 'Доставка оновлена',
  deliveryDeleted: 'Доставка видалена',
  deliveryDeletedBy: (id: string, user: string, company: string) =>
    `Доставка (${id}) видалена by ${user} at ${company}`,
  deliveryDenied: 'Доставка відхилена',
  deliveryCanceled: 'Доставка скасована',
  deliveryOnHold: 'Доставка відкладена',
  deliveryPaused: 'Доставку призупинено',
  updateMaterials: 'Оновити матеріали',
  materialsLoading: 'Завантажуються матеріали',
  xMaterialsShowing: (count: number) =>
    `${count} Матеріал${ending(count, '', 'и', 'ів')} показано`,
  planned: 'Заплановане',
  actual: 'Фактичне',
  scannerDeactivated: 'Сканер деактивовано',
  scheduleUpdated: 'Розклад оновлено',
  passed: 'Пройдено',
  failed: 'Не пройдено',
  scheduling: 'Планування',
  schedule: 'Запланувати',
  schedule_noun: 'Розклад',
  pass_verb: 'Пройти перевірку',
  change_verb: 'Змінити',
  deny_verb: 'Відмінити',
  accept: 'Прийняти',
  fail: 'Відмовити',
  incompletelyDone: 'Неповністю виконано',
  deleted: 'Видалено',
  deactivated: 'Деактивовано',
  activate: 'Активувати',
  deactivate: 'Деактивувати',
  canceled: 'Скасовано',
  siteNotReady: 'Місце не готове',
  materialProblem: 'Проблема з матеріалом',
  laborIssue: 'Питання праці',
  qualityIssue: 'Питання якості',
  clarificationNeeded: 'Потрібні пояснення',
  incorrectSpecification: 'Неточна специфікація',
  materialQuestion: 'Питання матерілу',
  superintendentNeeded: 'потрібен наглядач',
  plusXMore: (x: number) => `+ ${x} ще`,
  submittalInReview: 'Розглядається',
  submittalComplete: 'Розглянуто',
  inFabrication: 'Виготовляється',
  fabricationComplete: 'Виготовлено',
  inStorage: 'На зберіганні',
  installed: 'Встановлено',
  delivery: 'Доставка',
  newRequest: 'Новий запит',
  deleteDelivery: 'Видалити доставку',
  yesDeleteDelivery: 'Так, видалити доставку',
  cancelDelivery: 'Скасувати доставку',
  yesCancelDelivery: 'Так, скасувати доставку',
  changeDate: 'Змінити дату',
  yesMoveTheDate: 'Так, посунути дату',
  deliveryRequestIsNotValid: 'Запит на доставку не дійсний',
  datePassedDeadline: 'Дата вийшла за кінцевий термін',
  requestAnyway: 'Зробити запит все одно',
  requestAccessTo: 'Запросити доступ до',
  thisRequestHasPassedTheDeadline: 'Цей запит вийшов за кінцевий термін',
  noDeadline: 'Немає кінцевого терміну',
  twentyFourHours: '24 години',
  fortyEightHours: '48 години',
  seventyTwoHours: '72 години',
  fourDays: '4 дні',
  fiveDays: '5 днів',
  oneWeek: '1 тиждень',
  twoWeeks: '2 тижні',
  dateAndTime: 'Дата і час',
  startDate: 'Дата початку',
  finishDate: 'Дата закінчення',
  addFinishDate: 'Додати дату закінчення',
  removeFinish: 'Видалити дату закінчення',
  startTime: 'Час початку',
  endTime: 'Час закінчення',
  selectTime: 'Вибрати час',
  location: 'Розташування',
  locationFrom: 'З локації',
  locationTo: 'До локації',
  formType: 'Тип Форми',
  contact: 'Контакт',
  offloadingEquipment: 'Розвантажувальне обладнання',
  vehicles: 'Транспортні засоби',
  material: 'Матеріал',
  materialDeliveryLocation: 'Місце доставки матеріалу',
  materialsCategory: 'Категорія матеріалів',
  inspectionRequired: "Перевірка обов'язкова",
  supplierAndDriver: 'Постачальник & водій',
  anySpecialInstructions: 'Будь-які особливі вказівки?',
  deliverySitemap: 'Мапа доставки',
  gate: 'Ворота',
  area: 'Ділянка',
  fromToDelivery: 'Внутрішня Доставка',
  workArea: 'Робоча Ділянка',
  installationZone: 'Установочна зона',
  deliveryVehicleType: 'Тип транспортного засобу для доставки',
  numberOfVehicles: 'Кількість транспортних засобів',
  numberOfVehicleLicensePlate: 'Номерний знак транспортного засобу',
  equipment: 'Обладнання',
  sharedResources: 'Спільні Ресурси',
  units: 'Одиниці виміру',
  inspector: 'Контролер',
  vendorEmails: 'Електронна адреса продавця',
  driverMobilePhoneNumbers: 'Номер телефону водія',
  specialInstructions: 'Особливі вказівки',
  linkedActivity: "Пов'язана робота",
  customActionButton: 'Кнопка спеціальної дії',
  building: 'Будівля',
  route: 'Маршрут',
  staging: 'Тимчасове сховище',
  interiorPath: 'Внутрішній Шлях',
  interiorDoor: 'Внутрішні Двері',
  bookingDate: 'Дата бронювання',
  requesterCompany: 'Компанія запитувача',
  duration: 'Тривалість',
  onsiteContact: 'Місцевий контакт',
  onsiteContacts: 'Місцевий контакт',
  category: 'Категорія',
  materialNote: 'Примітка матеріалу',
  unnamedMaterial: 'Матеріал без назви',
  required: "Обов'язково",
  optional: "Необов'язково",
  multipleSelection: 'Множинний вибір',
  adding: 'Додавання',
  findOrAddNewEntry: 'Знайти або додати новий запис',
  findEntry: 'Знайти запис',
  yes: 'Так',
  no: 'Ні',
  unscheduledDelivery: 'Незапланована доставка',
  uploadASitemapToShowGatesAndAreas:
    'Завантажити мапу, щоб показати Ворота і Ділянки',
  uploadAViewToShowGatesAndAreas:
    'Завантажити вигляд, щоб показати Ворота і Ділянки',
  uploadASitemapToShowBuilding: 'Завантажити мапу, щоб показати Будівлю',
  buildingAccessMap: 'Мапа доступу до будівлі',
  uploadASitemapToShowGates: 'Завантажити мапу, щоб показати Ворота',
  gateAccessMap: 'Мапа доступу до Воріт',
  uploadASitemapToShowEquipment: 'Завантажити мапу, щоб показати Обладнання',
  equipmentAccessMap: 'Мапа доступу до Обладнання',
  zoneAccessMap: 'Мапа доступу до зон',
  uploadASitemapToShowAreas: 'Завантажити мапу, щоб показати Ділянки',
  routeAccessMap: 'Мапа доступу до маршруту',
  uploadASitemapToShowRoute: 'Завантажити мапу, щоб показати Маршрут',
  deliveryWorkflow: 'Робочий процес доставки',
  workflowMenu: 'Меню робочого процесу',
  addANoteToThisDelivery: 'Дадати нотатку до цієї доставки',
  send: 'Відправити',
  deliveryDescriptions: {
    confirmDelete:
      'Видалення цієї доставки призведе до видалення цієї доставки ' +
      "та усіх пов'язаних нотаток та даних з проєкта.",
    confirmDelete2: 'Робіть це тільки якщо доставка була помилково створена.',
    confirmCancel: `Скасовані поставки залишаться в календарі, але будуть позначені як "Скасовані".`,
    confirmCancel2: 'Скасувати цю доставку?',
    dateChange: (days: number) =>
      `Запит має бути створений щонайменше за ${days} ` +
      `робоч${ending(days, 'ий', 'і', 'их')} ` +
      `д${ending(days, 'ень', 'ні', 'нів')} ` +
      `від дати та часу, яку Ви вказуєте`,
    nonWorkTimeDateChange: (days: number) =>
      `Будь ласка змініть дату чи час цієї доставки. Запит має бути створений щонайменше за ${days} ` +
      `робоч${ending(days, 'ий', 'і', 'их')} ` +
      `д${ending(days, 'ень', 'ні', 'нів')} ` +
      `від дати та часу, яку Ви вказуєте та у робочий час.`,
    maximumDeliveriesAreScheduled:
      'Для цієї локації вже заплановано максимальну кількість доставок',
  },
  loginInfoPageDescriptions: {
    letsGetLoggedIntoStruxHub: `Давай увійдемо в ${brandName}`,
    resendMyInvitationLink: 'Надіслати посилання на моє запрошення',
    expiredInvitation:
      'Ваше запрошення було надіслано кілька днів тому, воно старе, і з міркувань безпеки нам потрібно повторно надіслати вам нове посилання.',
    newInvitationInfo: (hours: number) =>
      `Нове посилання буде дійсним протягом ${hours} Годи${ending(
        hours,
        'ни',
        'н',
        'н',
      )}, і ви отримаєте його на свою поштову скриньку як тільки натиснете на кнопку`,
    ifYouHaveQuestionsOrNeedSupport:
      'Якщо у вас є запитання або потрібна підтримка',
    youCanAlwaysReachUsAt: 'Ви завжди можете зв’язатися з нами за поштою',
    inviteTokenHasInvalidFormat: 'Некоректний формат запрощеня',
    inviteResendingConfirmation:
      'Ваше запрошення надіслано повторно. Перевірте свою електронну пошту та перейдіть за цим посиланням',
    sorryForSecurity: 'Вибачте за клопоти, але знаєте... безпека.',
  },

  assignResponsibilitiesConfirmation: "Підтвердження призначення обов'язків",
  assignResponsibilities: "Призначити обов'язки",
  assignToActivities: 'Призначити на роботи',
  groupByTeam: 'Групувати за командами',
  noTeam: '[без команди]',
  hashActivities: '# Робіт',
  rfi: 'Запит інформації',
  flag: 'Прапор',
  reply: 'Відповісти',
  unspecifiedType: '(Невказаний тип)',
  contractsOrRevisions: 'Контракти/Ревізії',
  priorWorkNotComplete: 'Попередня робота не завершена',
  informationNotAvailable: 'Інформація не доступна',
  unclearTaskDescription: 'Опис завдання не зрозумілий',
  staffNotAvailable: 'Персонал не доступний',
  materialsNotAvailable: 'Матеріал не доступний',
  noInformationAvailableOnThisMonitoring:
    'Немає доступної інформації по даному моніторингу',
  conditionsOfSatisfactions: 'Умови виконання',
  taskSequenceChange: 'Зміна порядку завдань',
  incorrectTimeEstimate: 'Неправильна оцінка термінів',
  offProjectDemands: 'Позапроєктні вимоги',
  unforeseenConditions: 'Непередбачені умови',
  weather: 'Погода',
  elevatedWork: 'Робота на висоті',
  materialHandling: 'Обробка матеріалів',
  groundDistribution: 'Наземна доставка',
  craneOrHeavyLift: 'Кран / Підйомник',
  confinedSpace: 'Простір з обмеженим доступом',
  mobileEquipment: 'Мобільне спорядження',
  deliver: 'Доставити',
  loto: 'LOTO', // хз шо це
  hotWork: 'Гарячі роботи',
  hazardousMaterial: 'Небезпечні матеріали',
  highVoltage: 'Висока напруга',
  highDecibels: 'Висока гучність',
  lifting: 'Підйом',
  closeQuarters: 'Тісне приміщення (< 6 футів)',
  rfiOpened: 'Запит інформації відкрито',
  flagOpened: 'Прапор відкрито',
  scheduleCommentOpened: 'Коментар щодо розкладу відкрито',
  addANote: 'Додати нотатку',
  start_noun: 'Початок',
  end_noun: 'Закінчення',
  forDate: (date: string) => `На ${date}`,
  addANoteToThisStatusUpdate: 'Додати нотатку до Оновлення статусу',
  chooseADeliveryTime: 'Обрати час доставки',
  allZones: 'Всі ділянки',
  allEquipment: 'Все обладнання',
  selectDate: 'Вибрати дату',
  selectField: 'Вибрати поле',
  selectDateAndTime: 'Вибрати дату & час',
  jumpToDate: 'Перейти до дати',
  jumpToMap: 'Перейти до мапи',
  newDelivery: 'Нова доставка',
  newForm: 'Нова форма',
  pending: 'Очікується',
  confirmChange: 'Підтвердити зміну',
  okToProceed: 'Згодні продовжувати?',
  proceed: 'Продовжити',
  actualStart: 'Фактичний початок',
  updated: 'Оновлено',
  updating: 'Оновлення',
  approved: 'Підтверджено',
  missing: 'Відсутнє',
  actualFinish: 'Фактичний кінець',
  xDaysUpdated: (x: number) =>
    `${x} д${ending(x, 'ень', 'ні', 'нів')} оновлено!`,
  copyToMissing: 'Скопіювати у відсутні',
  copy: 'Скопіювати',
  copiedToClipboard: 'Скопійовано в буфер обміну',
  copyLinkToDelivery: 'Скопіювати посилання на доставку',
  checkInUnscheduledDelivery: 'Зареєструвати позапланову доставку',
  paste: 'Вставити',
  estimateMissing: 'Оцінити відсутні',
  activityDetails: 'Деталі роботи',
  assigned: 'Призначено',
  followed: 'Відслідковується',
  following: 'Відслідковується',
  notFollowing: 'Не відслідковується',
  predecessors: 'Попередні роботи',
  successors: 'Наступні роботи',
  viewTeam: 'Переглянути команду',
  dates: 'Дати',
  plannedStart: 'Запланований початок',
  plannedEnd: 'Заплановане закінчення',
  actualEnd: 'Фактичний початок',
  activityLog: 'Журнал роботи',
  when: 'Коли',
  date: 'Дата',
  time: 'Час',
  where: 'Де',
  min_shortMinutes: 'хв',
  hr_shortHours: 'год',
  requester: 'Запитувач',
  subscriber: 'Абонент',
  onsiteContactPerson: 'Контактна особа на місці',
  onsiteContactPersons: 'Контактна особа на місці',
  vehicle: 'Транспортний засіб',
  quantity: 'Кількість',
  inspection: 'Перевірка',
  thisDeliveryWasNotScheduledInAdvance:
    'Ця доставка не була запланована заздалегідь',
  thisDeliveryWasScheduledForNonWorkTime:
    'Ця доставка була запланована на не робочий день чи час',
  sitemap: 'Мапа',
  flagAnIssue: 'Позначити проблему',
  openAnRfi: 'Створити запит інформації',
  requestADelivery: 'Створити запит на доставку',
  reason: 'Причина',
  addReasonForFlag: 'Додати причину для прапора',
  addReasonForRfi: 'Додати причину для запиту інформації',
  addReasonForCategoryOfVariance: 'Додати причину для Категорії варіації',
  addReasonForSafetyHazard: 'Додати причину Загрози безпеки',
  addANoteToThisActivityLog: 'Додати нотатку до цього журналу роботи',
  uploadingImage: 'Завантажується зображення',
  ganttDescriptions: {
    assignXUsersToYActivities: (x: number, y: number) =>
      `Призначення наступн${ending(x, 'ого', 'их', 'их')} ${x} ` +
      `член${ending(x, 'а', 'ів', 'ів')} команди на ` +
      `${y} вибран${ending(y, 'у', 'і', 'их')} ` +
      `Роб${ending(y, 'оту', 'оти', 'іт')}`,
  },
  actualDatesDescriptions: {
    newStartDateAfterOld: (
      newDate: string,
      oldDate: string,
      wrapper: (x: string) => any,
    ) => [
      'Встановлення ',
      wrapper(newDate),
      ' фактичною датою початку відмінить ',
      wrapper(oldDate),
      ` в якості дати початку і проігнорує всі оновлення до ${newDate}.`,
    ],
    newStartDateBeforeOld: (
      newDate: string,
      oldDate: string,
      wrapper: (x: string) => any,
    ) => [
      'Встановлення ',
      wrapper(newDate),
      ' фактичною датою початку відмінить ',
      wrapper(oldDate),
      " в якості дати початку без втрати всіх пов'язаних даних.",
    ],
    newEndDateAfterOld: (
      newDate: string,
      oldDate: string,
      wrapper: (x: string) => any,
    ) => [
      'Встановлення ',
      wrapper(newDate),
      ' фактичною датою закінчення відмінить ',
      wrapper(oldDate),
      ' в якості дати закінчення і проігнорує значення % виконання.',
    ],
    newEndDateBeforeOld: (
      newDate: string,
      oldDate: string,
      wrapper: (x: string) => any,
    ) => [
      'Встановлення ',
      wrapper(newDate),
      ' фактичною датою закінчення відмінить ',
      wrapper(oldDate),
      ` в якості дати закінчення і проігнорує всі оновлення після ${newDate}.`,
    ],
  },

  offloadingEquipmentMaps: (x: number) =>
    `Мап${x === 1 ? 'а' : 'и'} розвантажувального обладнання`,

  restrictedXLocations: (x: number) =>
    `Обмежено ${x} локац${ending(x, 'ію', 'ії', 'ій')}`,
  allLocationObjectsDisplayOnForm: 'Усі локації відображаються на формі',
  restrictLocations: 'Обмежити локації',
  selectRestrictedLocations: 'Виберіть обмежені локації',

  level: 'Поверх',
  zone: 'Зона',
  siteLocation: 'Локація сайту',
  verticalObject: "Вертикальний об'єкт",
  allIcons: 'Всі піктограми',
  manpower: 'Робоча сила',
  manPower: 'Робоча сила',
  description: 'Опис',
  code: 'Код',
  codeVerifying: 'Код перевіряється',
  codeResending: 'Код повторно надсилається',
  resendCode: 'Надіслати код повторно',
  contract: 'Контракт',
  plannedValue: 'Запланована вартість',
  bidStatus: 'Бід статус',

  street: 'Вулиця',
  satellite: 'Супутник',
  layers: 'Шари',
  showAllProjectsMarkers: 'Показувати маркери усіх проєктів',
  useMapAnimations: 'Використовувати анімації мапи',
  terrain: 'Ландшафт',
  transit: 'Транзит',
  traffic: 'Трафік',
  more: 'Більше',
  mapType: 'Тип мапи',
  mapDetails: 'Деталі мапи',
  showPlan: 'Показувати план',
  showWorkflows: 'Показати робочі процеси',

  version: 'Версія',
  revision: 'Ревізія',
  build: 'Сбірка',

  viewName: "Ім'я виду",
  viewImage: 'Зображення виду',

  deliveryIdNotProvided: 'Ідентифікатор відправлення не надано',
  sendingDeliveryTicket: 'Відправляю ТТН водію',
  ticketSuccessfullySent: 'ТТН усіпшно відправлено',
  failedToSendTicket: 'Не вдаллося надіслати ТТН',
  invalidDeliveryTicket: 'ТТН недійсна',
  deliveryDetails: 'Деталі доставки',
  onSiteLogistics: 'Логістика місцевості',

  showAtEntryGate: 'Покажіть біля вхідних воріт',
  showAtX: (location: string) => `Покажіть біля ${location}`,
  deliveryTicket: 'ТТН',
  hasDelivery: 'Має доставку',
  duplicate_verb: 'Дублювати',
  createFromToDelivery:
    'Створіти доставку Від-До. Поточна доставка повинна бути позначена ≥ [На місці]',
  duplicateCurrentDelivery: 'Дублювати поточну доставку',
  unsubscribeToDelivery: 'Скасувати підписку на доставку',
  subscribeToDelivery: 'Підписатися на доставку',

  requiredFieldCannotBeEmpty: 'Обов’язкове поле не може бути порожнім',
  fieldShouldBeUnique: 'Поле має бути унікальним',
  pleaseCorrectErrors: 'Будь ласка виправте помилки',
  pleaseFillAllFields: 'Будь ласка, заповніть усі поля',
  companiesUpdated: 'Компанії оновлено',
  companyAdded: 'Компанію додано',

  forms: 'Форми',
  formWorkflows: 'Робочі процеси форми',
  formTypes: 'Типи форми',

  deliveryWorkflows: 'Робочі процеси доставки',
  deliveryLocations: 'Локації доставки',
  deliveryCards: 'Картки доставки',

  scheduleUpload: 'Завантаження розкладу',
  scheduleColumns: 'Колонки розкладу',
  struxhubLabs: `${brandName} лабораторія`,

  mapSetup: 'Налаштування мапи',

  mobileConfiguration: 'Конфігурація моб. версії',
  additionalDataUpload: 'Завантаження додаткових даних',
  onTimeThresholds: 'Своєчасні пороги',
  qrScannersSetup: 'Налаштування сканерів QR кодів',

  businessPhone: 'Номер телефону компанії',
  businessEmail: 'Електронна адреса компанії',
  responsibles: 'Відповідальні',
  responsibleContact: 'Відповідальний',
  dba: 'Здійснює діяльність під найменуванням',
  licenseNumber: 'Номер ліцензії',

  deliveryId: 'Ідентифікатор доставки',
  bookingTime: 'Час бронювання',
  enterVerificationCodeFromSMS: 'Введіть код підтвердження з SMS',
  getDirections: 'Отримати направлення',

  userInviteMessages: {
    oneUserWasInvitedToProject: (userName: string) =>
      `${userName} запрошено до проекту`,
    manyUsersWereInvitedToProject: (count: number) =>
      `${xUsers(count)} були запрошені до проекту`,
  },

  userEmailLinking: {
    linkYourEmailAndSetPassword:
      'Зв’яжіть свою електронну адресу та встановіть пароль',
    weakPassword: 'Ненадійний пароль. Перевірте допустимий формат',
    emailHasBeenLinkedSuccessfully: `Електронну пошту успішно підв'язано до облікового запису`,
  },

  thereIsNoProject: `Для вашого облікового запису користувача немає проекту. Будь ласка, зв'яжіться з адміністратором`,

  repeatOn: 'Повторювати в',

  operatorValues: {
    isNot: 'НЕ Є',
    is: 'Є',
    and: 'І',
    or: 'АБО',
    for: 'ДЛЯ',
    assign: 'ПРИЗНАЧИТИ',
    if: 'ЯКЩО',
  },

  anyLocation: 'Будь-яка локація',
  anyCompany: 'Будь-яка компанія',
  selectCompany: 'Оберіть компанію',
  xObjectsSelected: (x: number) =>
    `Вибрано ${x} об'єкт${ending(x, '', 'и', 'ів')}`,
  selectLocation: 'Оберіть локацію',
  approval: 'Схвалення',
  firewatch: 'Пожежний нагляд', // у перекладі не впевнена
  noRules: 'Жодного правила',
  rule: 'Правило',

  xOfY: (x: number, y: number) => `${x} із ${y}`,
  options: 'Опції',
  selectActivityGroups: 'Вибрати групи робіт',
  numberOfBands: 'Кількість ланок',
  lbs: 'ІСЛ', // Ієрархічна структура локацій
  workBreakdownStructureWbs: 'Ієрархічна структура робіт (ІСР)',
  locationBreakdownStructureLbs: 'Ієрархічна структура локацій (ІСЛ)',
  groupByOptions: 'Групувати за опціями',
  showEmptyBandsLevels: 'Показати порожні рівні ланок',
  customGroupCreation: 'Створення спеціальної групи',
  bandX: (x: number) => `Ланка ${x}`,
  custom: 'Інше',
  groupByX: (x: string) => `Групування - ${x}`,

  bookingId: 'ІН бронювання',
  responsibleCompany: 'Відповідальна компанія',
  bookingDateEnTimeOffset: (offset: string) =>
    `Дата і час бронювання (${offset})`,
  durHmm_durationShort: 'Трив. (г:хх)',
  locationStatus: 'Статус локації',
  closureStart: 'Початок закриття',
  closureFinish: 'Завершення закриття',
  permittedCompany: 'Дозволена компанія',
  closureWorkflow: 'Робочий процес закриття',
  updateForm: 'Оновити Форму',
  formStatus: 'Статус форми',
  formStart: 'Початок дії форми',
  formFinish: 'Кінець дії форми',
  materialName: 'Назва матеріала',
  productCategory: 'Категорія продукта',
  deliveryMaterialNotes: 'Нотатки по матеріалу доставки',
  vendor: 'Продавець',
  quantityOnsite: 'Наявна кількість',
  storageArea: 'Місце зберігання',
  requiredOnsiteDate: `Обо'язкова дата доставки`,
  nextDelivery: 'Наступна доставка',
  deliveryBookingDate: 'Дата замовлення доставки',
  bookedQuantity: 'Заброньована кількість',
  recordLastUpdated: 'Запис востаннє оновлено',
  numObjects: `# Об'єктів`,
  showOn: 'Показати для',
  noShow: 'Не відображається',
  moved: 'Переміщено',
  otherReason: 'Інша причина',
  mostPertinentDefault: 'Найдоречніше (за замовчуванням)',
  zones: 'Зони',
  gates: 'Ворота',
  routes: 'Маршрути',
  areas: 'Ділянка',
  levels: 'Поверхи',
  totalX: (x: number) => `Загалом ${x}`,
  deliveriesEnEquipmentNeedsPerHour: 'Потреби доставок та обладнання за годину',
  resetFilters: 'Скинути фільтри',
  saved_activities: 'Збережені',
  since: 'Від',
  lastP6Update: 'Останнє оновлення з P6',
  lastWeek: 'Останній тиждень',
  lastMonth: 'Останній місяць',
  last6Week: 'Останні 6 тижнів',
  dateRange: 'Проміжок часу',
  dateRangeForRecentlyUpdatedActivities:
    'Проміжок часу для нещодавно оновлених робіт',
  myTasks: 'Мої завдання',
  recentlyUpdated_activities: 'Нещодавно оновлені',
  activitiesWithSpecificStatus: 'Роботи з конкретним статусом',
  actualizedFromSchedule_activities: 'Актуалізовані з розкладу',
  activitiesWithOpenScheduleComment:
    'Роботи з відкритим коментарем щодо розкладу',
  notCompleted_activities: 'Не завершені',
  criticalPathActivities: 'Роботи з критичної послідовності',
  flaggedActivities: 'Позначені роботи',
  lateActivities: 'Роботи із запізненням',
  activitiesWithOpenRFI: 'Роботи з відкритим запитом інформації',
  activitiesWithDeliveries: 'Роботи з доставками',
  activityStatusValues: {
    NotStarted: 'Не розпочата',
    InProgress: 'У процесі',
    Completed: 'Завершена',
    DueToday: 'На сьогодні',
    DueTomorrow: 'На завтра',
    LateFinish: 'Пізно завершена',
    LateStart: 'Пізно розпочата',
    StartsToday: 'Починається сьогодні',
    Done: 'Виконана',
    Incomplete: 'Незавершена',
  },
  numberOfActivities: 'Кількість робіт',
  seeXActivities: (x: number) =>
    `Переглянути ${x} роб${ending(x, 'оту', 'оти', 'іт')}`,
  type: 'Тип',
  assigners: 'Цеденти', // це гугл так сказав
  items: 'Предмети',
  projectMember: 'Член проєкта',
  disableFilter: 'Вимкнути фільтр',
  totalDeliveries: 'Усього доставок',
  totalSitemaps: 'Усього мап сайту',
  totalObjects: `Усього об'єктів`,
  totalForms: 'Усього форм',
  totalClosures: 'Усього закриттів',
  totalSensors: 'Усього сенсорів',
  seeXUsers: (x: number) =>
    `Переглянути ${x} користувач${ending(x, '', 'a', 'ів')}`,
  totalLogistics: 'Усього воркфлов',
  totalMaterials: 'Усього матеріалів',
  totalReports: 'Усього репортiв',
  seeXDeliveries: (x: number) =>
    `Переглянути ${x} достав${ending(x, 'ку', 'ки', 'ок')}`,
  seeXObjects: (x: number) =>
    `Переглянути ${x} об'єкт${ending(x, '', 'и', 'ів')}`,
  seeXSitemaps: (x: number) =>
    `Переглянути ${x} мап${ending(x, 'у', 'и', '')} сайту`,
  seeXForms: (x: number) => `Переглянути ${xForms(x)}`,
  seeXClosures: (x: number) =>
    `Переглянути ${x} закритт${ending(x, 'я', 'я', 'ів')}`,
  seeXSensors: (x: number) =>
    `Переглянути ${x} сенсор${ending(x, '', 'а', 'ів')}`,
  seeXLogistics: (x: number) =>
    `Переглянути ${x} воркфлов${ending(x, 'я', 'я', 'ів')}`,
  seeXMaterials: (x: number) =>
    `Переглянути ${x} матеріал${ending(x, '', 'и', 'ів')}`,
  seeXReports: (x: number) =>
    `Переглянути ${x} репорт${ending(x, '', 'а', 'ів')}`,
  currentLookahead: 'Поточний період',
  fullProject: 'Увесь проєкт',
  numberOfDeliveries: 'Кількість доставок',
  numberOfWorkflows: 'Кількість робочих процесів',
  savedFilters: 'Saved Filters',
  saved_deliveries: 'Saved',
  xDeliveries: (x: number) => `${x} ${deliveriesNominative(x)}`,
  xWorkflows: (x: number) => `${x} ${workflows(x)}`,
  xPlans: (x: number) => `${x} ${plans(x)}`,
  savedActivitySets: 'Збережені набори робіт',
  thereAreNoSavedActivityPresets: 'Немає жодного збереженого набору робіт',
  xForms,
  xFilters: (x: number) => `${x} фільтр${ending(x, '', 'и', 'ів')}`,
  thereAreNoSavedFilters: 'Немає жодного збереженого фільтру',
  makePublic: 'Зробити загальнодоступним',
  workPackageName: 'Назва робочого пакета',
  selectedFilters: 'Вибрані фільтри',
  share: 'Поділитися',
  saveCurrentFilters: 'Зберегти поточні фільтри',
  saveANewCustomFilter: 'Зберегти новий фільтр користувача',
  editACustomFilter: 'Редагувати фільтр користувача',

  oneDay: 'Один день',
  oneDayDefaultToday: 'Один день за замовчуванням сьогодні',
  week: 'Тиждень',
  fixedWeek: 'Фіксований тиждень',
  fixedTwoWeeks: 'Фіксовані 2 тижні',
  fixedThreeWeeks: 'Фіксовані 3 тижні',
  threeWeeks: '3 тижні',
  fourWeeks: '4 тижні',
  fixedFourWeeks: 'Фіксовані 4 тижні',
  month: 'Місяць',
  fixedSixWeeks: 'Фіксовані 6 тижнів',
  sixWeeks: '6 тижнів',
  threeMonth: '3 місяці',
  year: 'Рік',
  other: 'Інше',

  xDateToYDate: (xDate: string, yDate: string) => `${xDate} по ${yDate}`,
  assignUsersOrCategories: 'Призначити користувачів або категорії',
  searchUserCompanyTeamOrRole: 'Шукати користувача, компанію, команду або роль',
  successfullyUpdatedExistingAssignments:
    'Успішно оновлено існуючі призначення',
  successfullyUpdatedExistingAssignmentsByApp: (app: string) =>
    `Успішно оновлено існуючі ${app} призначення`,
  successfullyDeletedAssignments: 'Успішно видалено призначення',
  successfullyDeletedAssignmentsByApp: (app: string) =>
    `Успішно видалено ${app} призначення`,
  newAnnouncement: 'Нове оголошення',
  editingAnnouncement: 'Редагування оголошення',
  announcements: 'Оголошення',
  announcementHasBeenCreated: 'Оголошення додано',
  announcementDateArrived: 'Дата оголошення настала',
  announcementNotFound: 'Оголошення не знайдено',

  approve_verb: 'Підтвердити',
  readyToInspect: 'Готово до перевірки',
  toInspect: 'До перевірки',
  notInUse: 'Не використовується',
  everyXDaysInspection: (x: number) =>
    `Перевірка ${
      x === 1
        ? 'щодня'
        : `кож${ending(x, 'ен', 'ні', 'ні')} ` +
          `${x} д${ending(x, 'ень', 'ні', 'нів')}`
    }`,
  everyXWeeksInspection: (x: number) =>
    `Перевірка ${
      x === 1
        ? 'щотижня'
        : `кож${ending(x, 'ен', 'ні', 'ні')} ` +
          `${x} тиж${ending(x, 'день', 'ні', 'нів')}`
    }`,
  everyXMonthsInspection: (x: number) =>
    `Перевірка ${
      x === 1
        ? 'щомісяця'
        : `кож${ending(x, 'ен', 'ні', 'ні')} ` +
          `${x} місяц${ending(x, 'ь', 'і', 'ів')}`
    }`,
  addInspection: 'Додати інспекцію',
  finish_noun: 'Завершити',
  legend: 'Легенда',
  startFinishState: 'Початковий/Кінцевий стан',
  intermediateState: 'Проміжний стан',
  action: 'Дія',
  ballInCourtStep: 'Етап зони відповідальності',
  active: 'Активний',
  struxhubActivityX: (code: string) => `Робота ${code} у ${brandName}`,
  openActivity: 'Відкрити роботу',
  openDelivery: 'Відкрити доставку',
  loadingPresentationMode: 'Завантажується режим презентації',

  xOfYSelected: (x: number, y: number) => `Вибрано ${x} з ${y}`,
  xDeliveriesShowing: (x: number) =>
    `Показано ${x} достав${ending(x, 'ка', 'ки', 'ок')}`,
  assign_verb: 'Призначити',
  subscribe_verb: 'Підписатися',
  new: 'Нове',
  completedTotal: 'Завершено/Усього',
  searchByBookingIdOrSubscribers: 'Пошук за ID бронювання або підписниками',
  my: 'Мої',
  me: 'Мої',
  full_period: 'Повний',
  day: 'День',
  calendarView: 'У вигляді калердарю',
  ganttView: 'у вигляді Гантт',
  listView: 'У вигляді списка',
  mapView: 'У вигляді мапи',
  dur_duration: 'Трив',
  loc_locations: 'Лок',
  equip_equipment: 'Облад',
  mat_materials: 'Мат',
  vend_vendor: 'Прод',
  a_actualFlag: 'А',
  unspecified: 'Невказано',

  yesUnsubscribe: 'Так, відписатися',
  deliveriesListDescriptions: {
    subscribeConfirmMassage: (x: number, y: number) =>
      `Підписатися на [${x}] ` +
      `непідписан${ending(x, 'у', 'і', 'их')} ` +
      `достав${ending(x, 'ку', 'ки', 'ок')} ` +
      `з [${y}] вибран${ending(y, 'ої', 'их', 'их')} ` +
      `достав${ending(y, 'ки', 'ок', 'ок')}?`,

    unsubscribeConfirmMessage: (x: number) =>
      `Ви вже підписалися на ${x > 1 ? 'всі' : ''} [${x}] ` +
      `вибран${ending(x, 'у', 'і', 'их')} ` +
      `достав${ending(x, 'ку', 'ки', 'ок')}.\n\n` +
      'Можливо, Ви хотіли б відписатися?',
  },
  logisticsListDescriptions: {
    subscribeConfirmMassage: (x: number, y: number) =>
      `Підписатися на [${x}] ` +
      `непідписан${ending(x, 'у', 'і', 'их')} ` +
      `допусти${ending(x, 'ку', 'ки', 'ок')} ` +
      `з [${y}] вибран${ending(y, 'ої', 'их', 'их')} ` +
      `достав${ending(y, 'ки', 'ок', 'ок')}?`,
    unsubscribeConfirmMessage: (x: number) =>
      `Ви вже підписалися на ${x > 1 ? 'всі' : ''} [${x}] ` +
      `вибран${ending(x, 'у', 'і', 'их')} ` +
      `допусти${ending(x, 'ку', 'ки', 'ок')}.\n\n` +
      'Можливо, Ви хотіли б відписатися?',
    areYouWantToDelete:
      'Ви впевнені, що бажаєте видалити всі ці вибрані робочі процеси?',
    deletionConfirmMsg: (selectedCount: number) =>
      `Ви вибрали - ${selectedCount} ${workflows(selectedCount)}`,
  },
  sitemapsDataIsLoading: 'Завантажуються дані для мапів сайту',
  editingConfirmation: 'Підтвердження редагування',
  selectDataColumn: 'Оберіть колонку даних',
  addFieldForEditing: 'Додати поле для редагування',
  deliveryBulkEditorDescriptions: {
    warningMessagePart1:
      'Жодна з обраних доставок не може бути змінена ' +
      'згідно з Вашими дозволами облікового запису',
    warningMessagePart2: 'Оберіть інші або зверніться до адміністратора',
  },
  failedToEdit: 'Сталася помилка редагування',
  editedXFieldsOnYDeliveries: (x: number, y: number) =>
    `Редаговано ${x} пол${ending(x, 'е', 'я', 'ів')} ` +
    `у ${y} достав${ending(y, 'ці', 'ках', 'ках')}`,
  editXDeliveries_question: (x: number) =>
    `Редагувати ${x} достав${ending(x, 'ку', 'ки', 'ок')}?`,
  onlyXOfYSelectedDeliveriesCanBeChanged: (x: number, y: number) =>
    `Тільки [${x}] з [${y}] ` +
    `вибран${ending(y, 'ої', 'их', 'их')} ` +
    `достав${ending(y, 'ки', 'ок', 'ок')} ` +
    `можна змінити, продовжити?`,
  editXFieldsOnYDeliveries: (x: number, y: number) =>
    `Редагувати ${x} пол${ending(x, 'е', 'я', 'ів')} ` +
    `у ${y} достав${ending(y, 'ці', 'ках', 'ках')}`,
  updateXDeliveries: (x: number) =>
    `Оновити ${x} достав${ending(x, 'ку', 'ки', 'ок')}`,
  failedToUpdate: 'Сталася помилка оновлення',
  statusUpdated: 'Статус оновлено',
  statusUpdatedOnXDeliveries: (x: number) =>
    `Статус оновлено для ${x} достав${ending(x, 'ки', 'ок', 'ок')}`,
  updateDeliveryStatus: 'Оновити статус доставки',
  loadingDeliveriesData: 'Завантажуються дані доставок',
  loadingFormsData: 'Завантажуються дані форм',
  loadingLogisticsData: 'Завантажуються дані логістики',
  form: 'Форма',
  log: 'Журнал',

  weekOfEndDate: 'Тиждень кінцевої дати',
  companySummary: 'Резюме компанії',
  percentDone: '% Виконаного',
  percentOnTime: '% За розкладом',
  trucks: 'Вантажівки',
  avgTrucksPerDelivery: 'Сер. кіл-ть вантажівок / Доставка',
  deliveryGantt: 'Гантт графік доставок',
  projectSummary: 'Резюме проєкта',
  completionRatePercent: 'Коефіцієнт завершення (%)',
  completionRate: 'Коефіцієнт завершення',
  onTimeRatePercent: 'Коефіцієнт своєчасності (%)',
  onTimeRate: 'Коефіцієнт своєчасності',
  unplanned: 'Незаплановане',
  unplannedRate: 'Коефіцієнт незапланованого',
  onTimeStart: 'Початок за розкладом',
  lateOnTime: 'Із запізненням/За розкладом',
  weekly: 'Щотижня',
  monthly: 'Щомісяця',
  onTimeLate: 'За розкладом/Із запізненням',
  earnedDuration: 'Накопичена тривалість',
  show_verb: 'Показати',
  showActive: 'Показати активне',
  loadingProjectData: 'Завантажуються дані проєкта',
  updateToday: 'Оновлено сьогодні',
  critical: 'Критичне',
  deltaDays: '∆ днів',
  sumSlip: '∑ просковзування',
  openIssues: 'Відкриті питання',
  plannedToday: 'Заплановано на сьогодні',
  actualToday: 'Актуально на сьогодні',
  deltaManpowerToday: '∆ Роб. сили на сьогодні',
  deltaManpowerTrend: 'Тренд ∆ Роб. сили',
  soon: 'Скоро',
  numberOfPicks: 'Кількість вибірок',
  repeatDelivery: 'Повторювати доставку',
  every_plural: 'Кожні',
  endsOn: 'Закінчується',
  thisDelivery: 'Цю доставку',
  thisAndFutureDeliveries: 'Цю та наступні доставки',
  allDeliveries: 'Усі доставки',
  everyXDays: (x: number) =>
    `${
      x === 1
        ? 'Щодня'
        : `Кож${ending(x, 'ен', 'ні', 'ні')} ` +
          `${x} д${ending(x, 'ень', 'ні', 'нів')}`
    }`,
  everyXWeeks: (x: number) =>
    `${
      x === 1
        ? 'Щотижня'
        : `Кож${ending(x, 'ен', 'ні', 'ні')} ` +
          `${x} тиж${ending(x, 'день', 'ні', 'нів')}`
    }`,
  everyXMonths: (x: number) =>
    `${
      x === 1
        ? 'Щомісяця'
        : `Кож${ending(x, 'ен', 'ні', 'ні')} ` +
          `${x} місяц${ending(x, 'ь', 'і', 'ів')}`
    }`,
  xDays: (x: number) => `Д${ending(x, 'ень', 'ні', 'нів')}`,
  xWeeks: (x: number) => `Тиж${ending(x, 'день', 'ні', 'нів')}`,
  xMonths: (x: number) => `Місяц${ending(x, 'ь', 'і', 'ів')}`,
  xMaterial: (x: number) => `Матеріал #${x}`,
  addMaterial: 'Додати матеріал',
  forecast: 'Прогноз',
  multipleEntries: {
    add_x: (x: string) => `Додати ${x}`,
    paste: 'You can paste multiple entries',
    typeOrPaste: 'You can type or paste multiple entries',
    repeatedValue: 'Duplicate entry',
  },

  locationControls: 'Управління локаціями',
  addDailyClosure: 'Додати щоденне закриття',

  searchCompanyOrCompanyType: 'Шукати компанію або тип компанії',

  newActivity: 'Нова робота',
  cast: 'Каст',
  castName: 'Назва касту',
  monitoringName: 'Назва моніторінгу',
  deviceId: 'ID Пристрою',
  device: 'Пристрій',
  lastMaturity: 'Остання зрілість',
  lastReading: 'Останнє зчитування',
  elapsedTime: 'Пройшло часу (гг:мм)',
  maxElapsedTime: 'Максимальний Пройшло часу (мин)',
  lastTemperature: (temperatureUnit: MaturixTemperatureUnit) =>
    `Остання температура (${UkrainianTree.temperatureUnit(temperatureUnit)})`,
  lastStrength: (strengthUnit: MaturixStrengthUnit) =>
    `Остання сила (${UkrainianTree.strengthUnit(strengthUnit)})`,
  temperatureWithUnit: (temperatureUnit: MaturixTemperatureUnit) =>
    `Температура (${UkrainianTree.temperatureUnit(temperatureUnit)})`,
  strengthWithUnit: (strengthUnit: MaturixStrengthUnit) =>
    `Сила (${UkrainianTree.strengthUnit(strengthUnit)})`,
  temperatureUnit: (temperatureUnit: MaturixTemperatureUnit): string => {
    switch (temperatureUnit) {
      case MaturixTemperatureUnit.CELCIUS:
        return 'Цельсій'
      case MaturixTemperatureUnit.FAHRENHEIT:
        return 'Фаренгейт'
    }
  },
  strengthUnit: (strengthUnit: MaturixStrengthUnit): string => {
    switch (strengthUnit) {
      case MaturixStrengthUnit.KGPERCMSQ:
        return 'кг/см²'
      case MaturixStrengthUnit.MPA:
        return 'МПа'
      case MaturixStrengthUnit.NPERMMSQ:
        return 'Н/мм²'
      case MaturixStrengthUnit.PSI:
        return 'Ф/дюйм²'
    }
  },

  unconfirmed: 'Непідтверджено',
  confirmed: 'Підтверджено',
  delivering: 'Доставляється',
  paused: 'Призупинено',
  onHold: 'На утриманні',

  ticketed: 'Оформленно',
  recalled: 'Відкликано',
  enRoute: 'В дорозі',
  pouring: 'Розливання',
  delivered: 'Доставлено',

  orderSummary: 'Підсумок замовлення',
  payloadSummary: 'Підсумок корисного навантаження',

  orderStatus: 'Статус замовлення',
  totalVolume: 'Загальний обсяг',
  deliveryLocation: 'Місце доставки',
  mixType: 'Тип суміші',
  slump: 'Спад',
  spacingOrUnloadTime: 'Інтервал або час розвантаження',
  pouringMode: 'Розливний режим',
  elementsToPour: 'Елементи для заливки',
  extraCharge: 'Додатковий збір',
  orderedBy: 'Хто замовив',

  load: 'Навантаження',
  total: 'Всього',
  timeOnSite: 'Час на сайті',
  ticket: 'Квиток',
  volumeLoaded: 'Обсяг завантаженного',
  totalOrderVolume: 'Загальний обсяг замовлення',
  additive: 'Добавка',
  jobTimes: 'Час роботи',
  startLoad: 'Початок завантаження',
  beginPouring: 'Починаю заливати',
  washing: 'Промивання',
  totalTimeOnSite: 'Загальний час перебування на сайті',
  inXHoursAndXMin: (hours: number, minutes: number) =>
    `Через ${hours}г ${minutes}хв`,
  xHoursAndXMinLate: (hours: number, minutes: number) =>
    `${hours}г ${minutes}хв запізнення`,
  inXMin: (minutes: number) => `Через ${minutes} хв`,
  xMinLate: (minutes: number) => `${minutes} хв запізнення`,
  isAboutToArrive: ' Ось-ось прибуде',

  projected_time: 'Прогнозований',
  recurringDelivery: 'Регулярна',
  endingXDate: (date: string) => `Окончание ${date}`,
  isQuestionMandatory: "Питання обов'язкове?",
  remainingDuration: 'Залишкова тривалість',
  baseUrl: 'Базова Url',
  siteId: 'ID Майданчика',
  isActive: 'Чи активно',
  syncStartDate: 'Дата початку синхронизації',
  notUniqueActivityCodeMessage: (code: string) =>
    `Активність з кодом  [${code}] вже існує. Спробуйте інше значення`,
  activityWasUpdated: 'Роботу оновлено',
  activityUpdated: 'Роботу оновлено',
  editXFieldsOnYActivities: (x: number, y: number) =>
    `Редагувати ${x} пол${ending(x, 'е', 'я', 'ів')} ` +
    `у ${y} робо${ending(y, 'ті', 'тах', 'тах')}`,
  editedXFieldsOnYActivities: (x: number, y: number) =>
    `Редаговано ${x} пол${ending(x, 'е', 'я', 'ів')} ` +
    `у ${y} робо${ending(y, 'ті', 'тах', 'тах')}`,
  editXActivities_question: (x: number) =>
    `Редагувати ${x} робо${ending(x, 'ті', 'тах', 'тах')}?`,
  onlyXOfYSelectedActivitiesCanBeChanged: (x: number, y: number) =>
    `Тільки [${x}] з [${y}] ` +
    `вибран${ending(y, 'ої', 'их', 'их')} ` +
    `роб${ending(y, 'оти', 'біт', 'біт')} ` +
    `можна змінити, продовжити?`,
  p6Viewer: 'Перегляд P6',
  scannerApp: 'Додаток сканера',
  activitySetName: 'Назва набору робіт',
  editActivitySet: 'Редагування набору робіт',
  includeXFile: (ext: string) => `Додайте файл .${ext}`,
  includeDetailedEachForm: 'Додайте детальний перегляд кожної форми',
  usersSubscribedTo: {
    deliveries: (uCount: number, iCount: number) =>
      `Підписано ${uCount} ${users(uCount)} на ${iCount} ${deliveriesAccusative(
        iCount,
      )}`,
    activities: (uCount: number, iCount: number) =>
      `Підписано ${uCount} ${users(uCount)} на ${iCount} ${activitiesAccusative(
        iCount,
      )}`,
    announcements: (uCount: number, iCount: number) =>
      `Підписано ${uCount} ${users(uCount)} на ${iCount} ${announcements(
        iCount,
      )}`,
    forms: (uCount: number, iCount: number) =>
      `Підписано ${uCount} ${users(uCount)} на ${iCount} ${formsAccusative(
        iCount,
      )}`,
    sensors: (uCount: number, iCount: number) =>
      `Підписано ${uCount} ${users(uCount)} на ${iCount} ${sensors(iCount)}`,
    closures: (uCount: number, iCount: number) =>
      `Підписано ${uCount} ${users(uCount)} на ${iCount} ${closures(iCount)}`,
  },
  usersUnSubscribedFrom: {
    deliveries: (uCount: number, iCount: number) =>
      `Скасовано підписку ${uCount} ${users(
        uCount,
      )} на ${iCount} ${deliveriesAccusative(iCount)}`,
    activities: (uCount: number, iCount: number) =>
      `Скасовано підписку ${uCount} ${users(
        uCount,
      )} на ${iCount} ${activitiesAccusative(iCount)}`,
    announcements: (uCount: number, iCount: number) =>
      `Скасовано підписку ${uCount} ${users(
        uCount,
      )} на ${iCount} ${announcements(iCount)}`,
    forms: (uCount: number, iCount: number) =>
      `Скасовано підписку ${uCount} ${users(
        uCount,
      )} на ${iCount} ${formsAccusative(iCount)}`,
    sensors: (uCount: number, iCount: number) =>
      `Скасовано підписку ${uCount} ${users(uCount)} на ${iCount} ${sensors(
        iCount,
      )}`,
    closures: (uCount: number, iCount: number) =>
      `Скасовано підписку ${uCount} ${users(uCount)} на ${iCount} ${closures(
        iCount,
      )}`,
  },
  scannerName: 'Назва сканера',
  scanMaster: 'Майстер сканування',
  scanStationReport: 'Звіт станції сканування',
  materialTrackingReport: 'Звіт про відстеження матеріалів',
  truckSize: 'Розмір вантажівки',
  cancelationReason: 'Причина скасування',
  responsibleCompanies: 'Відповідальні компанії',
  noDataAvailable: 'Немає даних',
  last7Days: 'Останні 7 днів',
  last30Days: 'Останні 30 днів',
  last120Days: 'Останні 120 днів',
  last365Days: 'Останні 365 днів',
  entireProject: 'Весь проєкт',
  frequencyAndPeriod: 'Частота та період',
  activityDataLinking: {
    disableLinkingQuestion: (field: string) =>
      `Зв'язність з '${field}' буде вимкнено. Бажаєте продовжити?`,
    enableLinkingQuestion: (fieldValue: string, externalFieldValue: string) =>
      `Бажаєте зв'язати '${fieldValue}' з зовнішнім '${externalFieldValue}'? Це може вплинути на інші роботи.`,
    linkingIsDisabled: `Зв'язність даних вимкнена`,
    linkingIsEnabled: `Зв'язність даних увімкнена`,
    linkingDisabled: `Зв'язність даних вимкнено`,
    linkingEnabled: `Зв'язність даних увімкнено`,
    editWarning: `Зміни в зв'язності даних можуть вплинути на інші дії`,
    bulkEditWarning: `Зв'язність даних буде вимкнено для редагованих полів`,
  },

  materialsUpload: 'Завантаження матеріалів',
  plannedQuantity: 'Планова кількість',

  materialsUploading: {
    uploadMaterials: 'Завантажте матеріали',
    goToTheMaterialsPage: 'Перейти на сторінку Матеріали',
    uploadYourMaterialsFile:
      'Завантажте файл матеріалів у форматі Excel (.xlsx, .xlsm).',
    thereAreNoSheetsError:
      'У наданому файлі Excel немає аркушів або під час считування файлу сталася помилка',
    materialCategoryLvl1: 'Категорія матеріалу (Рівень 1)',
    materialLvl2: 'Матеріал (Рівень 2)',
    materialDescription: 'Опис матеріалу',
    materialPlannedQuantity: 'Планова кількість матеріалу',
    startingTheUpload: 'Початок завантаження',
    readingMaterialsFile: 'Cчитування файлу матеріалів',
    extractingMaterialsData: 'Вилучення даних матеріалів',
    removingOldData: 'Видалення старих даних',
    savingMaterialsData: 'Збереження даних про матеріали',
    finishingUpload: 'Завершення завантаження',
    uploadOfMaterialsFailedAt: 'Не вдалося завантажити матеріали під час',
    uploadWasSuccessful: 'Завантаження пройшло успішно',
    pleaseSelectSheetToUpload: (isMultiple: boolean) =>
      `${
        isMultiple ? 'Поточний файл містить кілька аркушів. ' : ''
      }Виберіть аркуш із файлу для імпорту.`,
    makeSureLocationNamesUnique:
      'Переконайтеся, що назви локацій у StruxHub унікальні',
  },

  materialsUploadHistory: {
    currentMaterials: 'Поточні матеріали',
    projectUsesDefaultMaterials:
      'У проєкті використовуються стандартні матеріали',
    latestUpload: 'Останнє завантаження',
    materialsRestoringConfirmation: 'Підтвердження відновлення матеріалів',
    totalMaterialCategoriesAndMaterials: 'Всього категорій і матеріалів',
    restoreDefaultMaterials: 'Відновити стандартні матеріали',
    doYouConfirmRestoreToDefaults:
      'Ви підтверджуєте відновлення стандартних матеріалів?',
    restoredSuccessfully: 'Успішно відновлено',
    failedToRestore: 'Не вдалося відновити',
  },

  materialsUploadConfirm: {
    confirmDataMerge: 'Підтвердити злиття даних',
    confirmDescription1: `Завантаження цього файлу оновить усі змінені значення для існуючих матеріалів
      і створить нові записи для нових матеріалів.`,
    confirmDescription2:
      'Якщо ви хочете видалити всі існуючі дані матеріалів і замінити їх даними у файлі завантаження,',
    confirmDescription3: 'натисніть тут',
    replaceDescription:
      'Ви справді бажаєте видалити всі наявні дані матеріалів і замінити їх даними у файлі завантаження?',
  },

  collapseAll: 'Згорнути все',
  expandAll: 'Розгорнути все',

  myUserProfile: 'Профіль мого користувача',
  addTeammates: 'Додати користувачів',
  addSummaryColumn: 'Додайте колонку підсумків',
  configureColumns: 'Налаштувати стовпці',
  summaryColumns: 'Підсумкові колонки',
  receipt: 'Розписка',
  summary: 'Резюме',

  remainingQuantity: 'Кількість, що залишилася',

  materialsInDeliveryFormWarning: {
    onlyXMaterialsForForm: (x: number) =>
      `Наразі для однієї форми доставки доступно лише ${x} матеріал${ending(
        x,
        '',
        'и',
        'ів',
      )}. Якщо це обмеження, зверніться до`,
    andWeCanTalk: 'і ми зможемо це обговорити',
  },

  deliverySuccessfullyCreated: 'Доставка успішно створена',

  xDates,

  materialDescription: 'Опис матеріалу',
  thisGroupAlreadyExists: 'Ця група вже існує',

  upload: 'Завантажити',
  totalBooked: 'Всього заброньовано',
  totalDelivered: 'Всього доставлено',
  doneDeliveries: 'Виконані поставки',

  xVendors: (x: number) => `${x} Продав${ending(x, 'ець', 'ці', 'ців')}`,
  thisWeek: 'Цей тиждень',
  thisMonth: 'Цей місяць',

  youCannotChangeDelivery:
    'Ви не можете змінювати доставку, коли вона має цей статус',
  plannedInstallLocation: 'Запланована локація інсталяції',
  plannedDeliveryLocation: 'Заплановане локація доставки',
  currentLocation: 'Поточна локація',
  procurementID: 'Ідентифікатор закупівлі',
  id_short: 'ID',
  readyToInstallQuantity: 'Кількість готова до встановлення',
  totalOnSiteQuantity: 'Загальна наявна кількість',
  toMoveQuantity: 'Кількість для переміщення',

  formMaterialSubFields: {
    materialHasQuantityAndLocation:
      'Матеріал має поля Кількість і Локація, які ви також можете додати/редагувати нижче.',
    clickToEnableQuantity: 'Натисніть тут, якщо ви хочете ввімкнути Кількість.',
    clickToEnableLocation: 'Натисніть тут, якщо ви хочете ввімкнути Локацію.',
  },
  newFormType: 'Новий тип Форми',
  materialTransferId: 'Ідентифікатор транспортування матеріалу',
  materialTransferDate: 'Дата транспортування матеріалу',
  newTransfer: 'Нове транспортування',
  doneTransfers: 'Виконані траспортування',
  totalTransferred: 'Всього транспортовано',
  xTransfers: (x: number) => `${x} Транспортуван${ending(x, 'ня', 'ня', 'ь')}`,

  youCanSelectMultipleOptions: 'Ви можете вибрати декілька варіантів',
  updateStatus: 'Оновити статус',

  review_verb: 'Оглянути',
  reviewed: 'Переглянуто',
  bicInspected: 'BIC Перевірено',
  activated: 'Активовано',
  finished: 'Завершено',
  submitted: 'Подано',
  passAndClose: 'Пройти і закрити',
  formIsDoneOrDenied: (isDenied: boolean) =>
    `Форма ${isDenied ? 'відхилена' : 'виконана'}`,
  stopped: 'Зупинено',
  request_noun: 'Запит',
  review_noun: 'Огляд',
  bicInspection: 'BIC Перевірка',
  recurringInspection: 'Періодична перевірка',
  notifyUserIfWorkflowHasTags:
    'Повідомляти користувача, якщо робочий процес має такі теги',
  thisWorkflowStepCanBePerformed:
    'Цей крок робочого циклу можуть виконувати такі користувачі',
  xRules: (x: number) => `${x} правил${ending(x, 'о', 'а', '')}`,
  step: 'Крок',
  bicUser: 'BIC користувач',
  formSection: 'Розділ форми',
  actions: 'Дії',
  frequency: 'Частота',
  deadline: 'Кінцевий термін',
  automaticStart: 'Автоматичний запуск',
  addStep: 'Додати крок',

  workflowConfDescr: {
    mustContainApproval:
      'Робочий процес із кроком Запиту повинен містити крок Схвалення',
    cannotBeTheLastStep: (stepName: string) =>
      `${stepName} не може бути останнім кроком, додайте ще один або видаліть цей.`,
    workflowStartsOnDate:
      'Робочий процес запускається автоматично в дату початку.',
    automaticallyStart: 'Автоматично запускати робочий процес у дату початку',
    replaceWithStep: (stepType: string): string =>
      `Замінити кроком ${stepType}`,
    replaceWithStart: 'Замінити кроком Початок',
    deleteWorkflowStep: 'Видалити крок робочого процесу',
    removeStepDialogMsg: 'Ви бажаєте видалити цей крок робочого процесу?',
    toastUnsavedMsg: 'Внесені вами зміни було скинуто',
    recurringHasConstr: 'Крок періодичної інспекції має обмеження:',
    toggleDialogMsg: (isManualStart: boolean): string =>
      `Змінити його на крок ${
        isManualStart ? 'Ручний Запуск' : 'Автоматичний Запуск'
      }?`,
    selectStepMsg: 'Виберіть крок робочого процесу, щоб додати/змінити поля',
  },
  workflowConfTooltips: {
    bicBlockFirstLine: `Можна призначити одного або кількох користувачів крокам робочого процесу BIC.`,
    bicBlockSecondLine: `Якщо ви хочете призначити цим користувачам певні сфери роботи, визначте правило на основі бажаного набору тегів.`,
  },
  workflowTableText: {
    seeDescr: 'Перегляньте опис кроків',
    stepCategory: 'Категорія кроку робочого процесу',
    workflowStep: 'Крок робочого процесу',
    constraints: 'Обмеження',
    bicText: `Чи можна вказати ball-in-court користувача?`,
    multipleText: 'Чи можна мати кілька екземплярів у робочому процесі?',
    submissionDescr:
      'Початок кроку робочого процесу для надсилання форми, яка не потребує подальшого затвердження.',
    submissionConstr: 'Може лише ініціювати робочий процес.',
    requestDescr: 'Початок кроку робочого процесу для запиту схвалення.',
    requestConstr:
      'Може лише ініціювати робочий процес. Наступний крок має бути Затвердження.',
    reviewDescr:
      'Загальний крок просування робочого циклу, який можна використовувати для попередніх схвалень.',
    approvalDescr:
      'Схвалення запиту на робочий процес, який можна призначити певному(им) користувачу(ам).',
    approvalConstr: 'Попередній крок має бути Запит.',
    startDescr:
      'Запуск робочого процесу, ініційований натисканням кнопки «Розпочати».',
    autoStartDescr:
      'Початок робочого циклу, який автоматично запускається в дату початку робочого циклу.',
    readyToInspectDescr:
      'Проміжний крок робочого процесу, який використовується для сповіщення інспекторів.',
    inspectionDescr: 'Загальний крок огляду.',
    bicInspectionDescr:
      'Крок перевірки, який можна призначити конкретному користувачеві.',
    recurringDescr: 'Періодичні перевірки з визначеною періодичністю.',
    recurringConstr:
      'Повинен безпосередньо передувати крок запуску або автоматичного запуску.',
    finishDescr:
      'Завершення робочого процесу позначається натисканням кнопки «Готово».',
  },
  workflowSteps: 'Кроки робочого процесу',
  workflowDiagram: 'Схема робочого процесу',
  workflowStepConfigurator: 'Конфігуратор кроків робочого процесу',
  restoreToLastSavedState: 'Відновити останній збережений стан',
  endOfTheDay: 'Кінець дня',
  everyX_withoutNum: (x: number) => `Кож${ending(x, 'ен', 'ні', 'ні')}`,
  submission: 'Подання',
  initiation: 'Ініціація',

  deleteForm: 'Видалити форму',
  formDeletionDialogDescr: {
    confirmDelete: `Видалення цієї форми призведе до видалення форми
    та всіх пов’язаних приміток і даних із проекту.`,
    confirmDelete2: 'Робіть це, лише якщо запит форми був помилковим.',
  },
  emailPdfReport: 'Звіт у форматі PDF електронною поштою',
  unsubscribe: 'Відписатися',
  followFormMenuDescr: {
    toReceive: 'Щоб отримувати сповіщення про робочий процес',
    toStop: 'Щоб припинити отримання сповіщень про робочий процес',
  },
  formDeleteMenuDescr: {
    isDone: 'Форма завершена, ви не можете її видалити',
    onlyFor: `Доступно лише для адміністраторів,
      майстрів форм, запитувачів, призначених`,
  },
  configureTableColumns: 'Налаштувати стовпці таблиці',
  searchProperties: 'Пошук властивостей',
  shownInTable: 'Показано в таблиці',
  hiddenInTable: 'Сховано в таблиці',
  freezeColumns: 'Закріпити стовпці вище',
  restoreDefaults: 'Відновити стандартні налаштування',
  announcementFollowed: 'Оголошення відслідковується',
  successfullyDeletedForms: (x: number) =>
    `Успішно видалено ${x} ${formsAccusative(x)}`,
  failedToDelete: 'Не вдалося видалити',
  cannotDeleteDoneForms: 'Неможливо видалити завершені форми',
  getMobileNativeApp: 'Отримати додаток',
  getMobileNativeApp_long: 'Отримати мобільний додаток StruxHub',
  getAppOnGoogle: 'Get it on Google Play',
  getAppOnApple: 'Download on the App Store',
  oneTimePopup: {
    title: 'Завантажте наш новий мобільний додаток!',
    body: 'Отримуйте оновлення в реальному часі та виконуйте більше роботи в полі з StruxHub.',
    dismiss: 'Добре, зрозуміло!',
  },

  exitScanner: 'Вийти зі сканера',
  deleteScanner: 'Видалити сканер',
  editScannerText: 'Редагувати сканер',
  shouldDeleteScanner: 'Потрібно видалити сканер',
  activateScanner: 'Активувати сканер',
  allowedUsers: 'Дозволені користувачі',
  badgeDescription: 'Опис Бейджа',
  makeItTimed:
    'Зробіть це сканером із часовим діапазоном для користувачів. ' +
    'Scan-Start : Scan-Stop = Total Time',
  limitAccess:
    "Обмежте доступ для певних користувачів, команд або компаній із 'Бейджем'",
  endRideConfirm: 'Ви впевнені, що хочете завершити поїздку?',
  changeModeConfirm: 'Ви впевнені, що хочете змінити режим поїздки?',
  isScannerTimedByDay:
    'Таймер автоматично ЗАКІНЧУЄТЬСЯ після закінчення робочого дня',
  endRide: 'Закінчити поїздку',
  scanTo: 'Сканувати до',
  totalPassengers: 'Всього Пасажирів',
  scannerActive: 'Cканер Активний',
  pauseScanner: 'Призупинити сканер',
  resumeScanner: 'Відновити сканер',
  endRideForAll: 'Завершити поїздку для всіх',
  workflowCategory: 'Категорія робочого процесу',
  workflowName: 'Назва робочого процесу',
  undo: 'Скасувати',
  redo: 'Повторити',
  icon: 'Значок',
  showInApp: 'Показати в Додатку',
  blockRequestsForNonWorkTimes: 'Блокувати запити на неробочий час',
  general: 'Загальне',
  steps: 'Кроки',
  views: 'Види',
  confirmation: 'Підтвердження',
  deletionOfDefaultWorkflows:
    'Видалення робочих процесів за замовчуванням або матеріалів заборонено',
  theFollowingEntitiesWereUpdated: 'Наступні сутності були оновлені',
  globeViews: 'Зображення Шарів',
  whiteboards: 'Дошки',
}

export default UkrainianTree
