enum Keys {
  ArrowUp = 'ArrowUp',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  ArrowDown = 'ArrowDown',
  Enter = 'Enter',
  Escape = 'Escape',
  Ctrl = 'Ctrl',
}

export default Keys
