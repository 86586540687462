import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { ISiteLocation } from '~/client/graph'

import { IAppConfig } from '../../../Config'
import Localization from '../../../localization/LocalizationManager'
import LocationBase from '../../../models/LocationObjects/LocationBase'
import SitePermit from '../../../models/Permit'
import Sitemap from '../../../models/Sitemap'
import EventsStore from '../../../stores/EventStore/Events.store'
import { DOWNLOAD_REPORT_FILE } from '../../../stores/EventStore/eventConstants'
import BuildingsStore from '../../../stores/domain/Buildings.store'
import SitemapItemsStore from '../../../stores/domain/SitemapItems.store'
import SitemapsStore from '../../../stores/domain/Sitemaps.store'
import TagsStore from '../../../stores/domain/Tags.store'
import ProjectDateStore from '../../../stores/ui/ProjectDate.store'
import SitePermitCreationFormStore from '../SitePermitCreationForm.store'

// localization: translated

interface IProps {
  store: SitePermitCreationFormStore

  className?: string
  iconSize?: number

  projectDateStore?: ProjectDateStore
  eventsStore?: EventsStore
  tagsStore?: TagsStore
  configuration?: IAppConfig
  sitemapItemsStore?: SitemapItemsStore
  sitemapsStore?: SitemapsStore
  buildingsStore?: BuildingsStore
}

const FORM_TEMPLATE_ID = 'form_view_report'

@inject(
  'projectDateStore',
  'eventsStore',
  'tagsStore',
  'configuration',
  'sitemapItemsStore',
  'sitemapsStore',
  'buildingsStore',
)
@observer
export default class PermitPdfReportButton extends React.Component<IProps> {
  public render() {
    const { className, iconSize } = this.props
    return (
      <div
        className={classList({
          'row pointer': true,
          [className]: !!className,
        })}
        onClick={this.submitReport}
      >
        <Icon
          icon={IconNames.PRINT}
          size={iconSize}
          className="text dark-gray no-grow mr10"
        />
        <span className="text extra-large">
          {Localization.translator.emailPdfReport}
        </span>
      </div>
    )
  }

  private submitReport = () => {
    const { projectDateStore, configuration, store, eventsStore } = this.props
    const { getClientTimezoneId, getMonthAndDayToDisplay } = projectDateStore
    const now = Date.now()
    const { TENANT_ID } = configuration
    const {
      editablePermit,
      template,
      setIsReportDownloading,
      resetIsReportDownloading,
    } = store
    const { activeProject } = eventsStore.appState

    setIsReportDownloading()

    const currentProjectDate = getMonthAndDayToDisplay(now)

    const data = {
      timezoneId: getClientTimezoneId(),
      dateTimeFormat: activeProject.dateTimeFormat,
      projectId: activeProject.id,
      materialsUploadId: activeProject.materialsUploadId,
      logoUrl: activeProject.logoUrl,
      permit: editablePermit.getReportDto(),
      overviewMaps: this.getSitemapImagesForReport(editablePermit.locations),
      qrCodeLink: this.qrCodeLink,
    }
    const filename = `${TENANT_ID}_${
      activeProject.name
    }_${currentProjectDate}${Localization.translator.reportOf(template.name)}`

    this.props.eventsStore.dispatch(
      DOWNLOAD_REPORT_FILE,
      FORM_TEMPLATE_ID,
      [{ fileId: '', fileName: `${filename}.pdf` }],
      Localization.translator.reportOf(template.name),
      data,
      data.projectId,
      now,
      now,
      data.timezoneId,
      true,
      false,
      resetIsReportDownloading,
    )
  }

  private getSitemapImagesForReport(
    selectedLocations: ISiteLocation[],
  ): string[] {
    const selectedSitemapItems = this.props.sitemapItemsStore.list.filter(
      item => selectedLocations?.some(({ id }) => id === item.assignedId),
    )

    return this.permitsSitemaps
      .filter(s => {
        if (s.isProjectOverviewMap) {
          return true
        }

        const assignedAttributesIds: string[] = this.getAssignedAttributesIds(
          s.id,
        )
        return selectedSitemapItems.some(item =>
          assignedAttributesIds.includes(item.assignedId),
        )
      })
      .map(s => s.filledImage)
  }

  private getAssignedAttributesIds(sitemapId: string): string[] {
    const attributes = this.attributesBySitemaps[sitemapId]
    if (attributes) {
      return attributes
    }

    const { firstBuilding } = this.props.buildingsStore
    return firstBuilding ? [firstBuilding.id] : []
  }

  private get permitsSitemaps(): Sitemap[] {
    const { maps } = this.props.eventsStore.appState.logistics.configurations
    return this.props.sitemapsStore.list.filter(s =>
      maps.some(map => map.sitemapId === s.id),
    )
  }

  private get attributesBySitemaps(): { [sitemapId: string]: string[] } {
    return this.props.tagsStore.allLocationTags.reduce((map, tag) => {
      const attribute = tag as LocationBase

      if (!attribute) {
        return map
      }

      this.permitsSitemaps.forEach(sitemap => {
        if (attribute.isSitemapAssigned(sitemap.id)) {
          if (map[sitemap.id]) {
            map[sitemap.id].push(attribute.id)
          } else {
            map[sitemap.id] = [attribute.id]
          }
        }
      })
      return map
    }, {})
  }

  private get qrCodeLink(): string {
    const { activeProject } = this.props.eventsStore.appState
    const { editablePermit } = this.props.store

    return SitePermit.getDirectLinkToInstance(
      activeProject.code,
      editablePermit.id,
    )
  }
}
