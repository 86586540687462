import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import { getTransitionPath } from '~/client/src/shared/constants/commonRoutes'

import DesktopInitialState from '../../../stores/DesktopInitialState'
import NavBarStore from '../NavBar.store'

// localization: no display text to translate

interface INavBarItemProps {
  isHovering?: boolean
  isUnclickable?: boolean
  isDisabled?: boolean
  shouldShowIcon?: boolean
  route?: string
  routeLabel?: string
  key?: string
  navBarStore: NavBarStore
  onClickHandler?: (event: React.SyntheticEvent) => void
  isActive?: boolean

  state?: DesktopInitialState
  className?: string
}

@inject('state')
@observer
export class NavBarItem extends React.Component<INavBarItemProps> {
  public static defaultProps = {
    isHovering: true,
  }

  public render() {
    const {
      isHovering,
      isUnclickable,
      onClickHandler,
      routeLabel,
      shouldShowIcon,
      children,
      isDisabled,
      isActive,
      className,
    } = this.props

    return (
      <div
        className={classList({
          'navbar-item px15 nowrap': true,
          'row x-center': shouldShowIcon,
          nohover: !isHovering,
          active: isActive || this.isActive,
          'unclickable-element': isUnclickable,
          'inactive-element': isDisabled,
          [className]: !!className,
        })}
        onClick={onClickHandler || this.onClickDefault}
      >
        {children}
        {routeLabel}
        {shouldShowIcon && (
          <Icons.DownArrow
            className="bp3-icon ml5 icon-w11 icon-grey"
            element="span"
          />
        )}
      </div>
    )
  }

  private onClickDefault = (evt: React.SyntheticEvent) => {
    evt.stopPropagation()

    this.props.navBarStore.displayView(this.props.route)
  }

  private get isActive() {
    const { route, navBarStore, state } = this.props
    const { location } = navBarStore

    return (
      route &&
      location.pathname.includes(
        getTransitionPath(route, state.activeOrInitProjectCode),
      )
    )
  }
}
