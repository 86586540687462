import React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import { NotificationFilters } from '~/client/src/shared/stores/InitialState'
import NotificationsStore from '~/client/src/shared/stores/domain/Notifications.store'

import DesktopInitialState from '../../../stores/DesktopInitialState'
import DesktopEventStore from '../../../stores/EventStore/DesktopEvents.store'
import DesktopCommonStore from '../../../stores/ui/DesktopCommon.store'

// translated

interface IProps {
  filter: NotificationFilters
  color?: string
  eventsStore?: DesktopEventStore
  state?: DesktopInitialState
  common?: DesktopCommonStore
  notificationsStore: NotificationsStore
}

@inject('eventsStore', 'state', 'common')
@observer
export default class NotificationsFilter extends React.Component<IProps> {
  private get filterTitle() {
    const { filter } = this.props
    switch (filter) {
      case NotificationFilters.UNREAD:
        return Localization.translator.unread_2form

      case NotificationFilters.READ:
        return Localization.translator.read_2form

      case NotificationFilters.ARCHIVED:
        return Localization.translator.archived

      case NotificationFilters.DELIVERIES_REQUESTED:
        return Localization.translator.requested

      case NotificationFilters.DELIVERIES_CHANGED:
        return Localization.translator.changed

      case NotificationFilters.DELIVERIES_SCHEDULED:
        return Localization.translator.scheduled

      case NotificationFilters.DELIVERIES_ON_SITE:
        return Localization.translator.onSite

      case NotificationFilters.DELIVERIES_ON_HOLD:
        return Localization.translator.onHold

      case NotificationFilters.DELIVERIES_DELIVERING:
        return Localization.translator.delivering

      case NotificationFilters.DELIVERIES_PAUSED:
        return Localization.translator.paused

      case NotificationFilters.DELIVERIES_CANCELED:
        return Localization.translator.canceled

      case NotificationFilters.DELIVERIES_PASSED_INSPECTION:
        return Localization.translator.passedInspection

      case NotificationFilters.DELIVERIES_FAILED_INSPECTION:
        return Localization.translator.failedInspection

      case NotificationFilters.DELIVERIES_DONE:
        return Localization.translator.done_plural

      case NotificationFilters.FLAGS:
        return Localization.translator.flags

      case NotificationFilters.RFIS:
        return Localization.translator.rfis

      case NotificationFilters.SCHEDULE_COMMENTS:
        return Localization.translator.scheduleComments

      case NotificationFilters.ACTIVITY_CHANGED:
        return Localization.translator.changed

      case NotificationFilters.FORMS:
        return Localization.translator.forms

      case NotificationFilters.DELIVERIES_ON_TIME:
        return Localization.translator.onTime

      case NotificationFilters.DELIVERIES_LATE:
        return Localization.translator.late

      case NotificationFilters.DELIVERIES_DENIED:
        return Localization.translator.denied

      case NotificationFilters.FOLLOWED:
        return Localization.translator.followed

      case NotificationFilters.FORM_ACCEPTED:
        return Localization.translator.approved

      case NotificationFilters.FORM_CLOSED:
        return Localization.translator.done_plural

      case NotificationFilters.FORM_FINISHED:
        return Localization.translator.finished

      case NotificationFilters.FORM_SUBMITTED:
        return Localization.translator.requested

      case NotificationFilters.FORM_CHANGED:
        return Localization.translator.changed

      case NotificationFilters.FORM_DENIED:
        return Localization.translator.denied

      case NotificationFilters.FORM_TO_INSPECT:
        return Localization.translator.readyToInspect

      case NotificationFilters.FORM_ACTIVATED:
        return Localization.translator.activated

      case NotificationFilters.FORM_REVIEWED:
        return Localization.translator.reviewed

      case NotificationFilters.FORM_FAILED:
        return Localization.translator.failed

      case NotificationFilters.FORM_PASSED:
        return Localization.translator.passed

      case NotificationFilters.FORM_BIC_INSPECTED:
        return Localization.translator.bicInspected

      case NotificationFilters.FORM_DELETED:
        return Localization.translator.deleted

      default:
        throw new Error(filter + ' is not added to translator')
    }
  }

  public render() {
    const { filter, color } = this.props

    return (
      <div
        onClick={this.showNotificationByType.bind(this, filter)}
        className={classList({
          'row y-center notifications-filter': true,
          active: this.isActive,
        })}
      >
        {color && this.notificationsFilterIcon(color)}
        <div
          className={classList({
            'text pr10 large': true,
            faded: !this.isActive,
          })}
        >
          {this.filterTitle}
        </div>
      </div>
    )
  }

  private get isActive() {
    const { state, filter } = this.props
    return state.notificationsStatusFilters.includes(filter)
  }

  private notificationsFilterIcon(color: string) {
    const className = 'notification-filter-icon brada4 mr5 ' + color
    return <div className={className} />
  }

  private showNotificationByType = (type: NotificationFilters) => {
    this.props.state.notificationsStatusFilters = [type]
    this.props.notificationsStore.notificationsStatusFilters = [type]
    this.props.common.displayNotifications()
  }
}
