import * as React from 'react'

import { FileInput, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import MentionTriggerButton from '~/client/src/shared/components/ActionBarInputWIthMentions/components/MentionTriggerButton'
import CompactActionsMenuModal from '~/client/src/shared/components/CompactActionsMenuModal/CompactActionsMenuModal'
import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'

import ContentObjectModel from '../../models/ContentObjectModel'
import Message from '../../models/Message'
import SitePermit from '../../models/Permit'
import StatusUpdate from '../../models/StatusUpdate'
import BaseAssignmentsStore from '../../stores/BaseAssignments.store'
import BaseFollowingsStore from '../../stores/BaseFollowings.store'
import BaseActionBarStore from '../../stores/ui/BaseActionBar.store'
import ActionBarInput from '../ActionBarInput/ActionBarInput'
import ActionBarInputWithMentions from '../ActionBarInputWIthMentions/ActionBarInputWithMentions'
import CameraUploadButton from '../CameraUploadButton/CameraUploadButton'
import ImagePreview from '../ImagePreview/ImagePreview'
import { statusType } from './ActionBar'

import './MessagesActionbar.scss'

const status = 'Status'
const addToLog = 'ADD TO LOG'
const addPhoto = 'Add Photo'
const attachFile = 'Attach file'

type entityType =
  | ContentObjectModel<any>
  | Message
  | Delivery
  | StatusUpdate
  | SitePermit

interface IProps {
  entity: entityType
  actionBarStore: BaseActionBarStore

  setNewStatus: () => void
  resetActions: () => void
  isSendButtonDisabled?: boolean

  newStatus?: statusType
  worfklowStepId?: string
  followingsStore?: BaseFollowingsStore
  assignmentsStore?: BaseAssignmentsStore
  customStatusRenderer?: (status: statusType, stepId: string) => JSX.Element
  containerRef?: HTMLDivElement
}

@observer
export default class MessagesActionBar extends React.Component<IProps> {
  @observable private isPlusMenuShown: boolean = false
  public constructor(props: IProps) {
    super(props)

    this.store.entityId = props.entity.id
  }

  private get store(): BaseActionBarStore {
    return this.props.actionBarStore
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { entity } = this.props

    if (entity !== prevProps.entity) {
      this.store.entityId = entity.id
      this.store.fullReset()
    }
  }

  public componentWillUnmount() {
    this.store.fullReset()
  }

  public render() {
    const {
      entity,
      assignmentsStore,
      followingsStore,
      newStatus,
      containerRef,
    } = this.props

    const {
      draftMessage,
      decodedDraftMessage,
      mentionTags,
      setDraftMessage,
      isMentionActionActive,
      activateMentionAction,
      deactivateMentionAction,
      triggerMentionActionActivation,
      previewImage,
      file,
    } = this.store

    const shouldEnableMentions = !!followingsStore && !!assignmentsStore
    const className =
      'mention-input brada24 bg-white slack-bar-input no-resize full-width'
    const shouldShowSendButton =
      !!file || !!draftMessage || !!newStatus || !!previewImage

    return (
      <>
        {this.plusMenu}
        <form onSubmit={this.send}>
          <div className="row py10">
            {this.renderPlusButton()}
            {shouldEnableMentions ? (
              <div
                className={classList({
                  [className]: !!className,
                  'pa10 mx15': true,
                })}
              >
                {this.statusChangePreview}
                {this.imagePreview}
                {this.filePreview}
                <ActionBarInputWithMentions
                  onChange={setDraftMessage}
                  entityId={entity.id}
                  value={draftMessage}
                  plainText={decodedDraftMessage}
                  mentions={mentionTags}
                  className={className}
                  followingsStore={followingsStore}
                  assignmentsStore={assignmentsStore}
                  placeholder={Localization.translator.addANote}
                  onSuggestionsPopupHide={deactivateMentionAction}
                  onSuggestionsPopupShow={activateMentionAction}
                  parentRef={containerRef}
                />
              </div>
            ) : (
              <div className={className}>
                {this.statusChangePreview}
                {this.imagePreview}
                {this.filePreview}
                <ActionBarInput
                  value={this.store.draftMessage}
                  onChange={this.setDraftMessage}
                  placeholder={Localization.translator.addANote}
                  className="slack-bar-input no-resize full-width"
                />
              </div>
            )}
            <div className="row no-grow">
              {shouldShowSendButton ? (
                <button
                  className="slack-bar-send-btn brada24 no-grow bg-blue-bright pa10"
                  type="submit"
                >
                  <Icons.SendMessage />
                </button>
              ) : (
                <>
                  {shouldEnableMentions && (
                    <MentionTriggerButton
                      className="no-grow mr10"
                      isActive={isMentionActionActive}
                      onClick={triggerMentionActionActivation}
                    />
                  )}
                  <div className="row x-end no-flex-children">
                    {entity && (
                      <>
                        <CameraUploadButton
                          showWhenSelected={true}
                          store={this.store}
                        />
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </form>
      </>
    )
  }

  private renderPlusButton() {
    return (
      <div className="no-grow pointer" onClick={this.togglePlusMenu}>
        <Icon icon={IconNames.PLUS} className="no-grow" />
      </div>
    )
  }

  @action.bound
  private async send(evt: React.FormEvent<HTMLFormElement>) {
    const { entity, setNewStatus, resetActions, newStatus } = this.props
    evt.preventDefault()
    evt.stopPropagation()
    if (newStatus) {
      setNewStatus?.()
      resetActions?.()
    }
    await this.store.replyToThread(entity)
  }

  private setDraftMessage = (evt: React.FormEvent<HTMLInputElement>) => {
    const text = evt.currentTarget.value
    this.store.setDraftMessage(text, text, [])
  }

  public get imagePreview() {
    if (!this.store.previewImage) {
      return
    }

    return (
      <ImagePreview
        removeImage={this.store.resetPreviewImage}
        className="preview-image-wrapper image-preview-holder relative"
      >
        <img src={this.store.previewImage} className="center" />
      </ImagePreview>
    )
  }

  public get filePreview() {
    if (!this.store.file) {
      return
    }

    return (
      <div className="row no-grow">
        <div className="row w-fit-content relative bg-palette-brand-lightest ba-black brada4 pa5">
          <div
            onClick={this.store.resetFile}
            style={{ top: -5, right: -5, zIndex: 2 }}
            className="absolute brada24 bg-dark row x-center"
          >
            <Icon className="remove-icon" icon={IconNames.SMALL_CROSS} />
          </div>
          <Icons.PdfUpload className="pdf-icon" />
          {this.store.file.name}
        </div>
      </div>
    )
  }

  public get statusChangePreview(): JSX.Element {
    const { resetActions, customStatusRenderer, newStatus, worfklowStepId } =
      this.props
    if (!newStatus) {
      return
    }

    return (
      <div className="row no-grow mb4">
        <div className="row no-grow relative bg-palette-brand-lightest ba-black brada4 pa5">
          <div
            onClick={resetActions}
            style={{ top: -5, right: -5, zIndex: 2 }}
            className="absolute brada24 bg-dark row x-center"
          >
            <Icon className="remove-icon" icon={IconNames.SMALL_CROSS} />
          </div>
          <span className="no-grow mr5">{status}</span>
          <Icon icon={IconNames.ARROW_RIGHT} />
          <div className="no-grow">
            {customStatusRenderer
              ? customStatusRenderer(newStatus, worfklowStepId)
              : newStatus}
          </div>
        </div>
      </div>
    )
  }

  private get plusMenu(): JSX.Element {
    return (
      <CompactActionsMenuModal
        isShown={this.isPlusMenuShown}
        onHide={this.togglePlusMenu}
        headerText={addToLog}
      >
        <div className="common-row row">
          <CameraUploadButton
            showWhenSelected={true}
            store={this.store}
            className="camera-upload-button-container row mr10"
            text={addPhoto}
            shouldUseBlueIcon={true}
            onChange={this.togglePlusMenu}
          />
        </div>
        <div className="common-row row">
          <FileInput
            disabled={false}
            className="attachment-upload-button full-height"
            inputProps={{
              onChange: this.uploadAttachment,
              accept: '.pdf',
              className: 'bare-file-input',
            }}
            text={
              <div className="row full-height">
                <Icons.AttachFile className="pdf-icon mr10 no-grow row x-center mw20" />
                <span className="text extra-large">{attachFile}</span>
              </div>
            }
          />
        </div>
      </CompactActionsMenuModal>
    )
  }

  private uploadAttachment = file => {
    this.store.uploadAttachment(file)
    this.togglePlusMenu()
  }

  private togglePlusMenu = () => {
    this.isPlusMenuShown = !this.isPlusMenuShown
  }
}
