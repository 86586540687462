import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import DeliveryActionBarStore from '~/client/src/shared/stores/ui/DeliveryActionBar.store'

import DeliveryDetailsStore from '../DeliveryDetails.store'

// translated

export interface IProps {
  onCreationCb: (deliveryId: string) => void
  deliveryDetailsStore?: DeliveryDetailsStore
  deliveryActionBarStore: DeliveryActionBarStore
}

@inject('deliveryDetailsStore')
@observer
export default class DeliveryActionButtons extends React.Component<IProps> {
  public render() {
    const {
      deliveryDetailsStore: {
        isSubmitButtonEnabled,
        isAnyDialogOpened,
        displayedDelivery,
        currentStatusActions,
        areFieldsChanged,
        isFromConcreteDirect,
      },
      deliveryActionBarStore: { isMenuPopupShown, showMenuPopup },
    } = this.props

    const isSubmitButtonVisible =
      (!isFromConcreteDirect || areFieldsChanged) &&
      (!displayedDelivery || displayedDelivery.canChange)
    const isStatusChangeBtnVisible =
      !!displayedDelivery && !isFromConcreteDirect
    const isActionButtonShown =
      !isFromConcreteDirect || displayedDelivery?.isPending

    return (
      !isMenuPopupShown && (
        <div className="row bt-light-grey px10 py15">
          <div className="row">
            {isSubmitButtonVisible && this.renderSubmitButton()}
            {isActionButtonShown &&
              currentStatusActions.map(
                (action, idx) =>
                  !action.isHidden && (
                    <div
                      key={idx}
                      onClick={action.onClick}
                      className={classList({
                        'action-button': true,
                        disabled:
                          action.isDisabled ||
                          isSubmitButtonEnabled ||
                          isAnyDialogOpened,
                      })}
                    >
                      {action.actionTitle}
                    </div>
                  ),
              )}
            {displayedDelivery && displayedDelivery.isDone && (
              <div className="text bold large center">
                {Localization.translator.done}
              </div>
            )}
            {displayedDelivery && displayedDelivery.isCanceled && (
              <div className="text bold large center">
                {Localization.translator.canceled}
              </div>
            )}
          </div>
          {isStatusChangeBtnVisible && (
            <div className="no-grow px10">
              <Icons.Add className="add-button row" onClick={showMenuPopup} />
            </div>
          )}
        </div>
      )
    )
  }

  private renderSubmitButton(): JSX.Element {
    const {
      submitTitle,
      isSubmitButtonEnabled,
      changeDelivery,
      setNewDeliveryStatus,
      areFieldsChanged,
      isDenyButtonEnabled,
      isAnyDialogOpened,
      onButtonClick,
    } = this.props.deliveryDetailsStore

    const submitButtonAction = areFieldsChanged
      ? onButtonClick.bind(
          null,
          changeDelivery.bind(null, this.props.onCreationCb),
          submitTitle,
        )
      : setNewDeliveryStatus.bind(this, DeliveryStatus.Denied)
    const isSubmitButtonDisabled = areFieldsChanged
      ? !isSubmitButtonEnabled
      : !isDenyButtonEnabled

    return (
      <div
        onClick={submitButtonAction}
        className={classList({
          'action-button': true,
          disabled: isSubmitButtonDisabled || isAnyDialogOpened,
        })}
      >
        {submitTitle}
      </div>
    )
  }
}
