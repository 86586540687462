import * as React from 'react'

import { observer } from 'mobx-react'

import { IPermitTypeField, PermitFieldType } from '~/client/graph'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'

import SitePermitCreationFormStore from '../SitePermitCreationForm.store'
import PermitFormAdditionalDateField from './PermitFormFields/PermitFormAdditionalDateField'
import PermitFormChecklistField from './PermitFormFields/PermitFormChecklistField'
import PermitFormCloseLocationsField from './PermitFormFields/PermitFormCloseLocationsField'
import PermitFormCompanyField from './PermitFormFields/PermitFormCompanyField'
import PermitFormDividerField from './PermitFormFields/PermitFormDividerField'
import PermitFormFileUploadField from './PermitFormFields/PermitFormFileUploadField'
import PermitFormInputField from './PermitFormFields/PermitFormInputField'
import PermitFormInstructionsField from './PermitFormFields/PermitFormInstructionsField'
import PermitFormLocationField from './PermitFormFields/PermitFormLocationFIeld'
import PermitFormMaterialField from './PermitFormFields/PermitFormMaterialField'
import PermitFormMeasureField from './PermitFormFields/PermitFormMeasureField'
import PermitFormPhoneNumberField from './PermitFormFields/PermitFormPhoneNumberField'
import PermitFormRequesterField from './PermitFormFields/PermitFormRequesterField'
import PermitFormSectionField from './PermitFormFields/PermitFormSectionField'
import PermitFormSelectField from './PermitFormFields/PermitFormSelectField'
import PermitFormStartEndDatesField from './PermitFormFields/PermitFormStartEndDatesField'
import PermitFormSubsectionField from './PermitFormFields/PermitFormSubsectionField'
import PermitFormTableField from './PermitFormFields/PermitFormTableField'
import PermitFormTextAreaField from './PermitFormFields/PermitFormTextAreaField'
import PermitFormTextField from './PermitFormFields/PermitFormTextField'
import PermitFormUserField from './PermitFormFields/PermitFormUserField'

const fieldToComponentMap = {
  [PermitFieldType.Company]: PermitFormCompanyField,
  [PermitFieldType.Requester]: PermitFormRequesterField,
  [PermitFieldType.Assignee]: PermitFormUserField,
  [PermitFieldType.Subscriber]: PermitFormUserField,
  [PermitFieldType.User]: PermitFormUserField,
  [PermitFieldType.StartFinishDates]: PermitFormStartEndDatesField,
  [PermitFieldType.AdditionalDate]: PermitFormAdditionalDateField,
  [PermitFieldType.Time]: PermitFormInputField,
  [PermitFieldType.Location]: PermitFormLocationField,
  [PermitFieldType.RequestToCloseLocations]: PermitFormCloseLocationsField,
  [PermitFieldType.File]: PermitFormFileUploadField,
  [PermitFieldType.InputText]: PermitFormInputField,
  [PermitFieldType.InputNumber]: PermitFormInputField,
  [PermitFieldType.Email]: PermitFormInputField,
  [PermitFieldType.PhoneNumber]: PermitFormPhoneNumberField,
  [PermitFieldType.InputTextArea]: PermitFormTextAreaField,
  [PermitFieldType.Measure]: PermitFormMeasureField,
  [PermitFieldType.Select]: PermitFormSelectField,
  [PermitFieldType.Checklist]: PermitFormChecklistField,
  [PermitFieldType.Question]: PermitFormChecklistField,
  [PermitFieldType.Divider]: PermitFormDividerField,
  [PermitFieldType.Instructions]: PermitFormInstructionsField,
  [PermitFieldType.Text]: PermitFormTextField,
  [PermitFieldType.Section]: PermitFormSectionField,
  [PermitFieldType.Subsection]: PermitFormSubsectionField,
  [PermitFieldType.Table]: PermitFormTableField,
  [PermitFieldType.LocationInformational]: PermitFormLocationField,
  [PermitFieldType.Material]: PermitFormMaterialField,
}

interface IProps {
  typeField: IPermitTypeField

  store: SitePermitCreationFormStore
  fieldsStore: IPermitFieldsStore

  shouldHideResponsible?: boolean
  isViewMode?: boolean
}

@observer
export default class PermitFormFieldWrapper extends React.Component<IProps> {
  public render() {
    const { typeField, store, fieldsStore, isViewMode, shouldHideResponsible } =
      this.props

    if (!typeField?.isShown) {
      return null
    }

    const Component = fieldToComponentMap[typeField.type.toString()]

    return (
      <>
        <Component
          typeField={typeField}
          store={store}
          fieldsStore={fieldsStore}
          isViewMode={isViewMode}
          shouldHideResponsible={shouldHideResponsible}
        />
        {this.conditionalFields}
      </>
    )
  }

  private get conditionalFields(): JSX.Element[] {
    const { typeField, store, fieldsStore, isViewMode, shouldHideResponsible } =
      this.props
    const conditionalFields =
      fieldsStore.getAvailableConditionalFields(typeField)

    return conditionalFields?.map(conditionalField => {
      if (!conditionalField?.isShown) {
        return null
      }

      const Component = fieldToComponentMap[conditionalField.type.toString()]

      return (
        <Component
          key={conditionalField.id}
          typeField={conditionalField}
          store={store}
          fieldsStore={fieldsStore}
          isViewMode={isViewMode}
          shouldHideResponsible={shouldHideResponsible}
        />
      )
    })
  }
}
