import PermitType from '~/client/src/shared/models/PermitType'

/**
 * * There are other ways to implement Undo/Redo
 * * like the Command pattern, this is a simple approach
 * * so I placed it here just to avoid the usage of it in other places
 * * until we establish the common approach for our app
 */
export default class FormTypeStateHistory {
  private states: PermitType[]
  private currentStateIdx: number

  public constructor(
    initialValue: PermitType,
    private readonly setFormType: (formType: PermitType) => void,
  ) {
    this.reInit(initialValue)
  }

  public reInit = (initialValue: PermitType) => {
    this.states = [initialValue.getDeepCopy()]
    this.currentStateIdx = 0
  }

  public captureState = (updatedType: PermitType) => {
    this.states.splice(
      ++this.currentStateIdx,
      this.states.length,
      updatedType.getDeepCopy(),
    )
  }

  public undo = () => {
    if (this.hasUndo) {
      this.currentStateIdx--

      this.updateCurrentState()
    }
  }

  public undoToInitial = () => {
    if (this.states[0]) {
      this.currentStateIdx = 0

      this.updateCurrentState()
    }
  }

  public redo = () => {
    if (this.hasRedo) {
      this.currentStateIdx++

      this.updateCurrentState()
    }
  }

  public resetToInitial = () => {
    if (this.states.length > 1) {
      this.states = [this.states[0]]
      this.currentStateIdx = 0

      this.updateCurrentState()
    }
  }

  public get hasUndo(): boolean {
    return !!this.states[this.currentStateIdx - 1]
  }

  public get hasRedo(): boolean {
    return !!this.states[this.currentStateIdx + 1]
  }

  private updateCurrentState() {
    this.setFormType(this.states[this.currentStateIdx].getDeepCopy())
  }
}
