import { computed, observable } from 'mobx'

import { IReportTemplate } from '~/client/graph'
import HeaderBarStore from '~/client/src/desktop/components/HeaderBar/HeaderBar.store'
import {
  IModeSettings,
  getModeSettings,
} from '~/client/src/desktop/components/HeaderBar/components/ViewModeToggle/ViewModeToggle'
import { TwoMonthsDatePickerMode } from '~/client/src/desktop/components/TwoMonthsDatePicker/TwoMonthsDatePicker.store'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import { getReportFileTypeLabel } from '~/client/src/shared/enums/ReportFileTypes'
import { LOAD_REPORT_TEMPLATE } from '~/client/src/shared/stores/EventStore/eventConstants'
import UIFilterInfo from '~/client/src/shared/stores/substates/UIFilterInfo'
import { FORMS_REPORT_TEMPLATE_ID } from '~/client/src/shared/utils/usefulStrings'

import LogisticsStore from '../../Logistics.store'

export default class LogisticsHeaderBarStore extends HeaderBarStore {
  @observable public isMapViewDisabled: boolean = false

  private listDatePickerStates = [
    TwoMonthsDatePickerMode.ONE_DAY,
    TwoMonthsDatePickerMode.WEEK,
    TwoMonthsDatePickerMode.FLOATING_THREE_WEEKS,
    TwoMonthsDatePickerMode.MONTH,
    TwoMonthsDatePickerMode.SIX_WEEKS,
    TwoMonthsDatePickerMode.THREE_MONTHS,
    TwoMonthsDatePickerMode.YEAR,
    TwoMonthsDatePickerMode.PROJECT,
  ]

  private calendarDatePickerStates = [
    TwoMonthsDatePickerMode.ONE_DAY,
    TwoMonthsDatePickerMode.FIXED_WEEK,
    TwoMonthsDatePickerMode.MONTH,
  ]

  public constructor(
    private readonly eventsStore: DesktopEventStore,
    private readonly logisticsStore: LogisticsStore,
    isMapViewDisabled: boolean,
    private readonly isPermitOnly?: boolean,
  ) {
    super(eventsStore.appState)

    this.isMapViewDisabled = isMapViewDisabled

    this.viewState = isPermitOnly
      ? eventsStore.appState.formsList
      : eventsStore.appState.logisticsList
  }

  public get logisticsForceCloseMap(): { [filterType: string]: boolean } {
    if (this.isMapViewMode) {
      const { fieldsMap: mapFieldsMap } = this.isPermitOnly
        ? this.state.formsMapFilters
        : this.state.logisticsMapFilters
      return this.getForceCloseMap(mapFieldsMap)
    }

    const { fieldsMap } = this.isPermitOnly
      ? this.state.formsFilters
      : this.state.logisticsFilters

    return this.getForceCloseMap(fieldsMap)
  }

  private getForceCloseMap(fieldsMap: { [filterType: string]: UIFilterInfo }): {
    [filterType: string]: boolean
  } {
    return Object.keys(fieldsMap).reduce((map, filterType) => {
      map[filterType] = this.openedPopup && this.openedPopup !== filterType
      return map
    }, {})
  }

  public get isCalendarViewMode(): boolean {
    return this.viewMode === ViewModes.Calendar
  }

  public get isMapViewMode(): boolean {
    return this.viewMode === ViewModes.Map
  }

  public get isListViewMode(): boolean {
    return this.viewMode === ViewModes.List
  }

  @computed
  public get datePickerStates() {
    switch (this.viewMode) {
      case ViewModes.Calendar:
        return this.calendarDatePickerStates
      case ViewModes.List:
        return this.listDatePickerStates
      default:
        return []
    }
  }

  public get viewModes(): IModeSettings[] {
    return [
      getModeSettings(ViewModes.Calendar, this.isCalendarDisabled),
      getModeSettings(ViewModes.List),
      getModeSettings(ViewModes.Map, this.isMapViewDisabled),
    ]
  }

  public setInitialDateValue() {
    this.logisticsStore.setInitialDateValue()
  }

  public loadReportTemplate = (): void => {
    this.eventsStore.dispatch(
      LOAD_REPORT_TEMPLATE,
      FORMS_REPORT_TEMPLATE_ID,
      this.reportTemplateReceived,
    )
  }

  private reportTemplateReceived = (reportTemplate: IReportTemplate): void => {
    this.additionalReportFiles = reportTemplate.files.map((file, i) => ({
      value: file.fileId,
      format: file.fileType,
      name: file.name,
      label: getReportFileTypeLabel(file.fileId) || file.name,
      isChecked: i === 0,
    }))
  }

  private get isCalendarDisabled(): boolean {
    if (!this.isPermitOnly) return true

    return this.state.isFormsDisabled
  }
}
