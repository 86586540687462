import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { observable } from 'mobx'
import { observer } from 'mobx-react'
import { Circle, Line, Rect } from 'react-konva'

import { IPosition } from '~/client/graph'
import MapViewItemBase from '~/client/src/shared/components/SitemapHelpers/models/MapViewItemBase'
import SitemapPolyLineProperties from '~/client/src/shared/components/SitemapHelpers/models/SitemapPolyLineProperties'
import {
  MAX_RELATIVE_POSITION,
  sitemapMousePointTo,
  validatePosition,
} from '~/client/src/shared/utils/SitemapCalculationHelpers'
import ThemeMode from '~/client/src/shared/utils/ThemeModeManager'

import MapViewItemsSetupStore from '../../stores/MapViewItemsSetup.store'

import Colors from '~/client/src/shared/theme.module.scss'

const MIN_POINT_RADIUS = 5
const POINT_STROKE_WIDTH = 2

const DELETE_KEY_CODE = 46
const BACKSPACE_KEY_CODE = 8

const DEFAULT_CURSOR = 'default'
const CROSSHAIR_CURSOR = 'url(/static/cursors/draw.png), crosshair'
const ADD_POINT_CURSOR = 'url(/static/cursors/add-point.png), copy'
const CLOSE_SHAPE_CURSOR = 'url(/static/cursors/close-shape.png), auto'

const CLOSE_SHAPE_OFFSET = 15

const INPUTS_NODE_NAMES = ['INPUT', 'TEXTAREA']

const MAC = 'MAC'

const DEGREE_IN_RADIANS = Math.PI / 180
const CONSTRAIN_ANGLE_STEP = 45
const { getHEXColor } = ThemeMode

interface IProps {
  store: MapViewItemsSetupStore
  item: MapViewItemBase
  containerHeight: number
  containerWidth: number

  onModifyStart: () => void
  onModifyEnd: () => void
}

@observer
export default class PolyLineEditor extends React.Component<IProps> {
  @observable private activePoint: IPosition = null
  @observable private hoveredLineMidPosition: IPosition = null

  public UNSAFE_componentWillMount() {
    this.selectDefaultPoint()
    document.addEventListener('keydown', this.onKeyDown)
  }

  public componentWillUnmount() {
    document.removeEventListener('keydown', this.onKeyDown)
  }

  private get polyline(): SitemapPolyLineProperties {
    const { shapeProperties } = this.props.item.sitemapItemProperties
    return shapeProperties as SitemapPolyLineProperties
  }

  public render() {
    const { containerHeight, containerWidth, onModifyEnd, onModifyStart } =
      this.props
    const pointRadius = Math.max(MIN_POINT_RADIUS, this.polyline.lineWidth)
    const { points } = this.polyline

    return (
      <>
        <Rect
          width={containerWidth}
          height={containerHeight}
          onClick={this.onBackdropClick}
          onMouseEnter={this.handleBackdropMouseEnter}
          onMouseLeave={this.handleBackdropMouseLeave}
          x={0}
          y={0}
        />
        {points.map(this.renderHoveringLine)}
        {points.map((point, idx) => (
          <Circle
            key={idx}
            x={(point.x * containerWidth) / MAX_RELATIVE_POSITION}
            y={(point.y * containerHeight) / MAX_RELATIVE_POSITION}
            radius={pointRadius}
            fill={getHEXColor(Colors.neutral100)}
            stroke={getHEXColor(
              point === this.activePoint ? Colors.neutral0 : null,
            )}
            strokeWidth={POINT_STROKE_WIDTH}
            onClick={this.onPointClick.bind(this, point)}
            onMouseEnter={this.handlePointMouseEnter.bind(this, idx)}
            onMouseLeave={this.handlePointMouseLeave}
            onMouseDown={onModifyStart}
            onMouseUp={onModifyEnd}
            draggable={true}
            onDragMove={this.onPointDragMove.bind(this, point)}
            onDragEnd={this.onPointDragEnd}
            dragBoundFunc={this.getDragBoundsFunction(
              containerWidth,
              containerHeight,
            )}
          />
        ))}
        {this.hoveredLineMidPosition && (
          <Circle
            x={
              (this.hoveredLineMidPosition.x * containerWidth) /
              MAX_RELATIVE_POSITION
            }
            y={
              (this.hoveredLineMidPosition.y * containerHeight) /
              MAX_RELATIVE_POSITION
            }
            radius={pointRadius}
            fill={getHEXColor(Colors.neutral100)}
            strokeWidth={POINT_STROKE_WIDTH}
            listening={false}
          />
        )}
      </>
    )
  }

  private renderHoveringLine = (point: IPosition, idx: number) => {
    let nextPoint = this.polyline.points[idx + 1]
    if (!nextPoint && this.polyline.isClosed) {
      nextPoint = this.polyline.points[0]
    }
    if (!nextPoint) {
      return null
    }

    const pointPx = this.getPixelsPosition(point)
    const nextPointPx = this.getPixelsPosition(nextPoint)

    const centerPosition = {
      x: (point.x + nextPoint.x) / 2,
      y: (point.y + nextPoint.y) / 2,
    }

    return (
      <Line
        key={idx}
        points={[pointPx.x, pointPx.y, nextPointPx.x, nextPointPx.y]}
        strokeWidth={this.polyline.lineWidth * 2}
        stroke="transparent"
        onClick={this.addMidPoint.bind(this, centerPosition, idx + 1)}
        onMouseEnter={this.handleLineMouseEnter.bind(this, centerPosition)}
        onMouseLeave={this.handleLineMouseLeave}
      />
    )
  }

  private handleLineMouseEnter(centerPosition: IPosition) {
    this.hoveredLineMidPosition = centerPosition
    document.body.style.cursor = ADD_POINT_CURSOR
  }

  private handleLineMouseLeave = () => {
    this.hoveredLineMidPosition = null
    document.body.style.cursor = DEFAULT_CURSOR
  }

  private addMidPoint(
    point: IPosition,
    idx: number,
    evt: KonvaEventObject<MouseEvent>,
  ) {
    this.polyline.addPoint(point, idx)
    evt.cancelBubble = true
    this.props.store.addCurrentStateToHistory()
  }

  private handleBackdropMouseEnter = () => {
    if (!this.polyline.isClosed) {
      document.body.style.cursor = CROSSHAIR_CURSOR
    }
  }

  private handleBackdropMouseLeave = () => {
    document.body.style.cursor = DEFAULT_CURSOR
  }

  private handlePointMouseEnter(idx: number) {
    if (!this.polyline.isClosed && this.polyline.isValidForClosing() && !idx) {
      document.body.style.cursor = CLOSE_SHAPE_CURSOR
    }
  }

  private handlePointMouseLeave = () => {
    document.body.style.cursor = DEFAULT_CURSOR
  }

  private onPointClick(point: IPosition, event: KonvaEventObject<MouseEvent>) {
    event.cancelBubble = true

    if (
      !this.polyline.isClosed &&
      point === this.polyline.points[0] &&
      this.polyline.isValidForClosing()
    ) {
      this.polyline.closeShape()
      this.activePoint = null
      return
    }

    this.activePoint = point
  }

  private onPointDragMove(
    point: IPosition,
    event: KonvaEventObject<DragEvent>,
  ) {
    const stage = event.currentTarget.getStage()

    const { containerWidth, containerHeight } = this.props
    const mousePointTo = sitemapMousePointTo(
      stage,
      containerWidth,
      containerHeight,
    )
    const { points } = this.polyline
    const closestPoint = points.find(
      (p, idx) =>
        (points[idx - 1] && points[idx - 1] === point) ||
        (points[idx + 1] && points[idx + 1] === point),
    )

    if (!event.evt.shiftKey || !closestPoint) {
      const position = this.getRelativePosition(mousePointTo)
      point.x = position.x
      point.y = position.y
      return
    }

    const basePoint = this.getPixelsPosition(closestPoint)
    const constrainedPointPx = this.getConstrainedPoint(mousePointTo, basePoint)
    const constrainedPoint = this.getRelativePosition(constrainedPointPx)
    point.x = constrainedPoint.x
    point.y = constrainedPoint.y

    event.currentTarget.x(constrainedPointPx.x)
    event.currentTarget.y(constrainedPointPx.y)
  }

  private onPointDragEnd = (event: KonvaEventObject<DragEvent>) => {
    event.cancelBubble = true
    this.props.store.addCurrentStateToHistory()
    this.props.onModifyEnd()
  }

  private getDragBoundsFunction(maxWidth: number, maxHeight: number) {
    return (pos: { x: number; y: number }) => ({
      x: Math.max(0, Math.min(pos.x, maxWidth)),
      y: Math.max(0, Math.min(pos.y, maxHeight)),
    })
  }

  private onBackdropClick = (evt: KonvaEventObject<MouseEvent>) => {
    if (
      this.polyline.isClosed &&
      !this.props.item.sitemapItemProperties.hasMultipleParts
    ) {
      return
    }

    evt.cancelBubble = true
    if (this.polyline.isClosed) {
      this.props.store.deselectMapViewItemDrawnPart()
      return
    }

    const stage = evt.currentTarget.getStage()

    const { containerWidth, containerHeight } = this.props
    const stageWidth = stage.width()
    const stageHeight = stage.height()

    if (!stageWidth || !stageHeight) {
      return
    }

    const mousePosition = sitemapMousePointTo(
      stage,
      containerWidth,
      containerHeight,
    )

    const firstPointDistance =
      this.getDistanceToFirstPointInPixels(mousePosition)

    if (firstPointDistance && firstPointDistance <= CLOSE_SHAPE_OFFSET) {
      this.polyline.closeShape()
      this.activePoint = null
      this.props.store.addCurrentStateToHistory()
      return
    }

    const { points } = this.polyline
    const lastPoint = points[points.length - 1]

    let mousePointTo = sitemapMousePointTo(
      stage,
      containerWidth,
      containerHeight,
    )

    if (evt.evt.shiftKey && lastPoint) {
      const basePoint = this.getPixelsPosition(lastPoint)
      mousePointTo = this.getConstrainedPoint(mousePointTo, basePoint)
    }

    const relativePosition = validatePosition(
      this.getRelativePosition(mousePointTo),
    )

    const point = this.polyline.addPoint(relativePosition)
    this.activePoint = point
    this.props.store.addCurrentStateToHistory()
  }

  private onKeyDown = (event: KeyboardEvent) => {
    // @ts-ignore: nodeName exists
    if (INPUTS_NODE_NAMES.includes(event.target && event.target.nodeName)) {
      return
    }

    switch (event.keyCode) {
      // Backspace === Delete on MacOS
      case BACKSPACE_KEY_CODE:
        const isMac = navigator.platform.toUpperCase().includes(MAC)
        if (!isMac) {
          break
        }
      // eslint-disable-next-line no-fallthrough
      case DELETE_KEY_CODE:
        this.deleteActivePoint()
        break
    }
  }

  private selectDefaultPoint() {
    const { points, isClosed } = this.polyline
    if (!isClosed) {
      this.activePoint = points[points.length - 1]
    }
  }

  private deleteActivePoint() {
    const { store } = this.props

    if (this.activePoint && this.polyline.points.length > 1) {
      this.polyline.deletePoints(point => this.activePoint === point)
      this.selectDefaultPoint()
      this.props.store.addCurrentStateToHistory()
      return
    }

    store.showDeleteConfirmationDialog(store.selectedMapViewItem)
  }

  private getDistanceToFirstPointInPixels(p: IPosition) {
    if (!this.polyline.isValidForClosing()) {
      return
    }

    const { containerHeight, containerWidth } = this.props
    const { x, y } = this.polyline.points[0]

    const pointX = (x * containerWidth) / MAX_RELATIVE_POSITION
    const pointY = (y * containerHeight) / MAX_RELATIVE_POSITION

    return Math.sqrt(Math.pow(p.x - pointX, 2) + Math.pow(p.y - pointY, 2))
  }

  private getPixelsPosition(position: IPosition) {
    const { containerWidth, containerHeight } = this.props

    return {
      x: (position.x * containerWidth) / MAX_RELATIVE_POSITION,
      y: (position.y * containerHeight) / MAX_RELATIVE_POSITION,
    }
  }

  private getRelativePosition(position: IPosition) {
    const { containerWidth, containerHeight } = this.props

    return {
      x: (position.x / containerWidth) * MAX_RELATIVE_POSITION,
      y: (position.y / containerHeight) * MAX_RELATIVE_POSITION,
    }
  }

  private getConstrainedPoint(point: IPosition, basePoint: IPosition) {
    const radiansAngle = Math.atan2(
      point.y - basePoint.y,
      point.x - basePoint.x,
    )
    const angle = radiansAngle / DEGREE_IN_RADIANS
    const constrainedAngle =
      Math.round(angle / CONSTRAIN_ANGLE_STEP) * CONSTRAIN_ANGLE_STEP

    const distance = Math.sqrt(
      Math.pow(point.x - basePoint.x, 2) + Math.pow(point.y - basePoint.y, 2),
    )

    return {
      x:
        basePoint.x + distance * Math.cos(constrainedAngle * DEGREE_IN_RADIANS),
      y:
        basePoint.y + distance * Math.sin(constrainedAngle * DEGREE_IN_RADIANS),
    }
  }
}
