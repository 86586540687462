import * as React from 'react'

import { Icon, Spinner } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, computed } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import { PermitFieldType } from '~/client/graph'
import PermitFieldIcon from '~/client/src/shared/components/PermitFieldIcon/PermitFieldIcon'
import FileType from '~/client/src/shared/enums/FileType'

import { getWorkflowStepLevel } from '../../constants/formStatusesTags'
import Localization from '../../localization/LocalizationManager'
import { getWorkflowStepDisplayName } from '../../localization/enumDisplayTexts'
import EventsStore from '../../stores/EventStore/Events.store'
import { SHOW_FULLSCREEN_PREVIEW } from '../../stores/EventStore/eventConstants'
import { FileUploadingStore } from '../../stores/domain/FileUploading.store'
import MessagesStore from '../../stores/domain/MessagesStore/Messages.store'
import PhotosStore from '../../stores/domain/Photos.store'
import SitePermitFollowingsStore from '../../stores/domain/SitePermitFollowings.store'
import TagsStore from '../../stores/domain/Tags.store'
import ThreadsStore from '../../stores/domain/ThreadsStore/Threads.store'
import UserProjectsStore from '../../stores/domain/UserProjects.store'
import { NOOP } from '../../utils/noop'
import BaseActionButton from '../BaseActionButton/BaseActionButton'
import FileInputBase from '../FileInput/FileInput'
import NonWorkTimeConfirmDialog from '../NonWorkTimeConfirmDialog/NonWorkTimeConfirmDialog'
import PermitLogActionBarStore from '../PermitLogActionBar.store'
import PermitTypeIcon from '../PermitTypeIcon/PermitTypeIcon'
import StruxhubInput from '../StruxhubInputs/StruxhubInput'
import StruxhubTextValueSelector from '../StruxhubInputs/StruxhubSelector/StruxhubTextValueSelector'
import Tabs from '../Tabs/Tabs'
import SitePermitCreationFormStore, {
  FormDetailsTab,
} from './SitePermitCreationForm.store'
import FormLogContent from './components/FormLogContent'
import InspectionPopupForm from './components/InspectionPopupForm/InspectionPopupForm'
import PermitContentPickers from './components/PermitContentPickers/PermitContentPickers'
import PermitFormFieldWrapper from './components/PermitFormFieldWrapper'
import PermitInspectionsSection from './components/PermitInspectionsSection'

import './SitePermitCreationFormContent.scss'

interface IProps {
  isReadonly: boolean
  store: SitePermitCreationFormStore
  FileInputType: typeof FileInputBase
  shouldHideResponsible?: boolean
  onPermitClose?: () => void
  isViewMode?: boolean
  toggleMode?: (event: React.MouseEvent<HTMLDivElement>) => void
  isTypeReadOnly?: boolean

  eventsStore?: EventsStore
  permitLogActionBarStore?: PermitLogActionBarStore
  threadsStore?: ThreadsStore
  messagesStore?: MessagesStore
  photosStore?: PhotosStore
  fileUploadingStore?: FileUploadingStore
  tagsStore?: TagsStore
  userProjectsStore?: UserProjectsStore
  sitePermitFollowingsStore?: SitePermitFollowingsStore
}

const formType = 'Form type'
const _title = 'Title'
const noAvailableTemplates = 'No available templates'
const editForm = 'Edit form'
const formEntityName = 'Form'

const WARNING_ICON_SIZE = 20
const STEP_ICON_SIZE = 20

@inject(
  'eventsStore',
  'threadsStore',
  'messagesStore',
  'photosStore',
  'fileUploadingStore',
  'tagsStore',
  'userProjectsStore',
  'sitePermitFollowingsStore',
)
@observer
export default class SitePermitCreationFormContent extends React.Component<IProps> {
  private readonly actionBarStore: PermitLogActionBarStore

  private readonly clearPostEventCallback: () => void = NOOP

  private containerRef: HTMLDivElement
  private workflowStepRef: HTMLDivElement

  public constructor(props: IProps) {
    super(props)

    this.actionBarStore =
      props.permitLogActionBarStore ||
      new PermitLogActionBarStore(
        props.eventsStore,
        props.threadsStore,
        props.messagesStore,
        props.photosStore,
        props.fileUploadingStore,
        props.userProjectsStore,
        props.tagsStore,
        props.sitePermitFollowingsStore,
      )

    this.clearPostEventCallback = props.eventsStore.addPostEventCallback(
      props.store.onPermitUpdated,
    )
  }

  public componentWillUnmount() {
    this.props.eventsStore.appState.preSelectedPermitTypeId = null
    this.clearPostEventCallback()
  }

  public componentDidMount() {
    const { setScrollToBottomFn, markPermitAsNonWorkTimeIfNeed } =
      this.props.store

    setScrollToBottomFn(this.scrollToBottom)

    Promise.resolve().then(() => this.scrollToBottom())

    markPermitAsNonWorkTimeIfNeed()
  }

  public render() {
    const { store, isReadonly } = this.props
    const {
      isNonWorkTimesModalOpened,
      shouldBlockOnNonWorkTimes,
      formDetailTabs,
      activeDetailsTab,
      switchActiveTab,
    } = store

    return (
      <>
        <InspectionPopupForm isReadOnly={isReadonly} store={store} />
        <NonWorkTimeConfirmDialog
          isShown={isNonWorkTimesModalOpened}
          onHide={this.onHideNonWorkTimeDialog}
          onApply={this.onApplyNonWorkTimesModal}
          shouldBlockOnNonWorkTimes={shouldBlockOnNonWorkTimes}
          entityName={formEntityName}
        />
        <PermitContentPickers store={store} />
        {this.isExistingForm && (
          <Tabs
            tabObjects={formDetailTabs}
            activeTabId={activeDetailsTab}
            onTabClick={switchActiveTab}
            isFlexible
            className="form-details-tabs y-end pt8 bg-palette-brand-lightest"
            tabClassName="tab h36 bradt8 relative bg-palette-brand-lightest bb-palette-brand-lighter"
            tabTextClassName="tab-text text light large bold lp015 center"
          />
        )}
        <div
          className="permit-form-fields full-height scrollable relative"
          ref={this.setContainerRef}
        >
          {this.tabContent}
        </div>
      </>
    )
  }

  private get tabContent(): JSX.Element {
    const { template, activeDetailsTab } = this.props.store

    if (!template) {
      return (
        <span className="row x-center text large bold pt24">
          <Icon
            icon={IconNames.WARNING_SIGN}
            size={WARNING_ICON_SIZE}
            className="text orange mr10"
          />
          {noAvailableTemplates}...
        </span>
      )
    }
    if (activeDetailsTab === FormDetailsTab.LOG) {
      return this.logContent
    }
    if (activeDetailsTab === FormDetailsTab.FORM) {
      return this.formFields
    }
    return null
  }

  private get logContent(): JSX.Element {
    const { store, FileInputType } = this.props

    if (store.isLogDataLoading) {
      return (
        <div className="full-height full-width row x-center y-center">
          <Spinner />
        </div>
      )
    }
    return (
      <FormLogContent
        store={store}
        actionBarStore={this.actionBarStore}
        FileInputType={FileInputType}
        openImagePreview={this.openImagePreview}
      />
    )
  }

  private get formFields(): JSX.Element {
    const { store, isReadonly, isViewMode, shouldHideResponsible } = this.props
    const { workflowFieldsStore, template } = store
    const {
      availableWorkflowSteps,
      editableEntity,
      existingEntity,
      isSectionHidden,
      toggleStepSection,
    } = workflowFieldsStore
    const { stepsWithoutRecurring } = template
    const { currentWorkflowStepId, isDoneOrDenied } =
      existingEntity || editableEntity

    return (
      <>
        <div className="col my10 px16">
          {this.renderType()}
          {this.renderTitle()}
        </div>

        {availableWorkflowSteps.map((step, idx) => {
          const isCurrentStep = currentWorkflowStepId === step.id
          const isStepCompleted = !isCurrentStep || isDoneOrDenied
          const isViewModeEnabled = isViewMode && !isCurrentStep
          const isStepCollapsed = isSectionHidden(step.id)
          const hasFields = !!step.fields.length
          const workflowStepLevel = getWorkflowStepLevel(
            step.id,
            stepsWithoutRecurring,
          )

          return (
            <div
              key={step.id}
              className="col mb10 brada8 mx8 ba-grey-80 overflow-hidden"
              ref={this.setWorkflowStepRef.bind(null, step.id)}
            >
              <div className="row h40 px16 bg-grey-92">
                {hasFields && (
                  <Icon
                    icon={
                      isStepCollapsed
                        ? IconNames.CHEVRON_RIGHT
                        : IconNames.CHEVRON_DOWN
                    }
                    size={STEP_ICON_SIZE}
                    className="mr8 pointer no-select"
                    onClick={toggleStepSection.bind(null, step.id)}
                  />
                )}
                <span className="text extra-large bold line-24">
                  {`${Localization.translator.step} ${
                    idx + 1
                  }: ${getWorkflowStepDisplayName(
                    step.type,
                    workflowStepLevel,
                  )}`}
                </span>
                {isStepCompleted && (
                  <Icon
                    icon={IconNames.TICK}
                    size={STEP_ICON_SIZE}
                    className="ml5 text blue-brand"
                  />
                )}
                {!isStepCompleted && (
                  <span className="text large bold orange line-extra-large no-grow nowrap ml5">
                    {Localization.translator.current}
                  </span>
                )}
              </div>
              {!isStepCollapsed && (
                <div
                  className={classList({
                    'col fields-section px16': true,
                    my10: hasFields,
                  })}
                >
                  {step.fields.map(typeField => (
                    <PermitFormFieldWrapper
                      key={typeField.id}
                      typeField={typeField}
                      store={store}
                      fieldsStore={store.workflowFieldsStore}
                      shouldHideResponsible={shouldHideResponsible}
                      isViewMode={isReadonly || isViewModeEnabled}
                    />
                  ))}
                </div>
              )}
            </div>
          )
        })}

        <div className="row no-grow x-end my10">
          {this.renderEditFormButton()}
        </div>

        {this.isExistingForm && (
          <div className="col mb10">
            <PermitInspectionsSection store={store} />
          </div>
        )}
      </>
    )
  }

  private renderEditFormButton = (): JSX.Element => {
    const { isViewMode, isReadonly, toggleMode } = this.props

    if (!isViewMode || isReadonly) {
      return
    }

    return (
      <BaseActionButton
        className="primary-theme mx20"
        title={editForm}
        icon={<Icon icon={IconNames.EDIT} className="row no-grow" />}
        onClick={toggleMode}
        isEnabled
      />
    )
  }

  private renderType = (): JSX.Element => {
    const { store, isReadonly, isTypeReadOnly } = this.props
    const { editableEntity, template, permitTypeField } =
      store.workflowFieldsStore
    const { id: permitId } = editableEntity

    if (isTypeReadOnly || permitId || isReadonly) {
      const icon = (
        <PermitTypeIcon
          permitType={template?.type}
          className="row permit-type-icon no-grow mr15 pa3"
        />
      )

      const element = (
        <div className="text large ellipsis">{template?.name}</div>
      )

      return this.renderViewModeRow(formType, icon, element)
    }

    return (
      <StruxhubTextValueSelector
        className="fields-section"
        label={formType}
        isRequired={permitTypeField.isRequired}
        isValid={permitTypeField.isValid}
        isChanged={permitTypeField.isChanged}
        customIconElement={
          <PermitTypeIcon
            permitType={template?.type}
            className="row no-grow mr10"
          />
        }
        value={template?.name}
        onClick={store.showFormTypeSelector}
      />
    )
  }

  private renderTitle(): JSX.Element {
    const { store, isReadonly, isViewMode } = this.props
    const { titleField, editableEntity } = store.workflowFieldsStore
    const { title: permitTitle } = editableEntity

    if (isReadonly || isViewMode) {
      const icon = <PermitFieldIcon fieldName={PermitFieldType.InputText} />
      const element = (
        <div className="text large ellipsis">{permitTitle || '-'}</div>
      )

      return this.renderViewModeRow(_title, icon, element)
    }

    return (
      <StruxhubInput
        isRequired={titleField.isRequired}
        isChanged={titleField.isChanged}
        isValid={titleField.isValid}
        label={_title}
        value={permitTitle}
        onChange={this.onTitleChange}
        onValueReset={this.onTitleReset}
        className="fields-section"
      />
    )
  }

  private renderViewModeRow = (
    title: string,
    icon: JSX.Element,
    element: JSX.Element | JSX.Element[],
    extraIcon?: JSX.Element,
  ): JSX.Element => {
    if (!element) {
      return
    }

    return (
      <div className="row no-grow y-start py12">
        {icon}
        <div className="col pr6 overflow-hidden">
          <div className="text light large pb4 w-max-content">{title}</div>
          <div>{element}</div>
        </div>
        {extraIcon}
      </div>
    )
  }

  @computed
  private get isExistingForm(): boolean {
    return !!this.props.store.editablePermit?.id
  }

  @action.bound
  private openImagePreview(ev: React.MouseEvent<HTMLElement>, url: string) {
    ev.stopPropagation()
    ev.preventDefault()

    this.props.eventsStore.dispatch(
      SHOW_FULLSCREEN_PREVIEW,
      [{ fileUrl: url, fileType: FileType.Image }],
      0,
    )
  }

  private setContainerRef = (ref: HTMLDivElement) => {
    this.containerRef = ref
  }

  private setWorkflowStepRef = (stepId: string, element: HTMLDivElement) => {
    const { currentWorkflowStepId } = this.props.store.editablePermit

    if (currentWorkflowStepId === stepId) {
      this.workflowStepRef = element
    }
  }

  private onTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.store.editablePermit.title = event.target.value
  }

  private onTitleReset = () => {
    this.props.store.editablePermit.title = ''
  }

  private scrollToBottom = () => {
    if (!this.isExistingForm || !this.containerRef) {
      return
    }

    const { activeDetailsTab, editablePermit } = this.props.store
    if (
      !editablePermit.isDoneOrDenied &&
      activeDetailsTab === FormDetailsTab.FORM &&
      this.workflowStepRef
    ) {
      this.workflowStepRef.scrollIntoView(true)
      return
    }

    const { scrollHeight, clientHeight } = this.containerRef
    this.containerRef.scrollTop = scrollHeight - clientHeight
  }

  private onHideNonWorkTimeDialog = () => {
    const { store, onPermitClose } = this.props

    store?.closeNonWorkTimeModal()
    onPermitClose?.()
  }

  private onApplyNonWorkTimesModal = () => {
    const {
      shouldBlockOnNonWorkTimes,
      closeNonWorkTimeModal,
      showNonWorkTimeLabel,
      blockSubmitAndShowNonWorkLabel,
    } = this.props.store

    closeNonWorkTimeModal()

    if (shouldBlockOnNonWorkTimes) {
      return blockSubmitAndShowNonWorkLabel()
    }

    showNonWorkTimeLabel()
  }
}
