import * as React from 'react'

import { inject, observer } from 'mobx-react'

import Localization from '~/client/src/shared/localization/LocalizationManager'

import UserFieldId from '../../enums/UserFieldId'
import EventsStore from '../../stores/EventStore/Events.store'
import GraphExecutorStore from '../../stores/domain/GraphExecutor.store'
import BaseSignUp from '../BaseSignUp/BaseSignUp'
import { Loader } from '../Loader'
import VisitorSignUpStore from './VisitorSignUp.store'

interface IProps {
  eventsStore?: EventsStore
  graphExecutorStore?: GraphExecutorStore
}

const optionsFieldsIds = [UserFieldId.Email, UserFieldId.PhoneNumber]

@inject('eventsStore', 'graphExecutorStore')
@observer
export default class VisitorSignUp extends React.Component<IProps> {
  private readonly store: VisitorSignUpStore = null

  public constructor(props: IProps) {
    super(props)

    this.store = new VisitorSignUpStore(
      props.eventsStore,
      props.graphExecutorStore,
      optionsFieldsIds,
    )
  }

  public componentDidMount() {
    this.store.tryToLogin()
    this.store.requestOptions()
  }

  public render() {
    return this.store.shouldShowPreloader ? (
      <Loader />
    ) : (
      <BaseSignUp store={this.store} header={this.renderHeader()} />
    )
  }

  private renderHeader = (): JSX.Element => {
    return (
      <>
        <div className="text header pb8">
          {Localization.translator.addYourNameAndCompanyToAccess}
        </div>
        <div className="text huge bold">{this.store.initProjectName}</div>
      </>
    )
  }
}
