import { FirebaseApp } from 'firebase/app'
import { Auth, getAuth } from 'firebase/auth'
import { FirebaseStorage, getStorage } from 'firebase/storage'

import { createGraphClient } from '~/client/graph'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import DesktopRootStore from '~/client/src/desktop/stores/DesktopRoot.store'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import registerDesktopEvents from '~/client/src/desktop/stores/EventStore/desktopEvents'
import Configuration, { IAppConfig } from '~/client/src/shared/Config'
import ApiAuthService from '~/client/src/shared/services/ApiAuthService'
import FirebaseAuthService from '~/client/src/shared/services/FirebaseAuthService'
import EffectsProcessor from '~/client/src/shared/stores/EventStore/EffectsProcessors/EffectsProcessor'
import FlowProcessor from '~/client/src/shared/stores/EventStore/EffectsProcessors/FlowProcessor/FlowProcessor'
import registerCommonEvents from '~/client/src/shared/stores/EventStore/commonEvents'

import CommonContainer from '../shared/CommonContainer'
import ProcoreService from '../shared/services/ProcoreService'
import GraphProcessor from '../shared/stores/EventStore/EffectsProcessors/GraphProcessors/GraphProcessor'
import WorkerProcessor from '../shared/stores/EventStore/EffectsProcessors/WorkerProcessor/WorkerProcessor'
import GraphExecutor from '../shared/utils/GraphExecutor'

export default class Container extends CommonContainer {
  private configuration: IAppConfig = Configuration
  private $auth: Auth
  private $storage: FirebaseStorage
  private $rootStore: DesktopRootStore
  private initialState: DesktopInitialState
  protected eventsStore: DesktopEventStore
  private firebaseAuthService: FirebaseAuthService
  private apiAuthService: ApiAuthService
  private procoreService: ProcoreService
  private graphExecutor: GraphExecutor

  public constructor(private app: FirebaseApp, private isTestEnv: boolean) {
    super()

    this.app = app

    this.initialState = new DesktopInitialState()

    this.procoreService = new ProcoreService(this.initialState)

    this.firebaseAuthService = new FirebaseAuthService(this.auth)

    const getValidToken = this.firebaseAuthService.getValidAccessToken

    this.initialState.graphClient = createGraphClient(getValidToken)

    this.graphExecutor = new GraphExecutor(this.initialState.graphClient)

    this.apiAuthService = new ApiAuthService(
      this.initialState,
      this.graphExecutor,
    )

    this.eventsStore = new DesktopEventStore(
      new EffectsProcessor(
        getValidToken,
        new FlowProcessor(),
        new WorkerProcessor(),
        new GraphProcessor(this.initialState, this.graphExecutor),
      ),
      this.initialState,
    )

    registerCommonEvents(this.eventsStore, this.rootStore)
    registerDesktopEvents(this.eventsStore, this.rootStore)

    this.registerGlobalEventListeners()
  }

  private get auth() {
    if (!this.$auth) {
      this.$auth = getAuth(this.app)
    }

    return this.$auth
  }

  private get storage() {
    if (!this.$storage) {
      this.$storage = getStorage(this.app)
    }

    return this.$storage
  }

  public get rootStore() {
    if (!this.$rootStore) {
      this.$rootStore = new DesktopRootStore(
        this.configuration,
        this.initialState,
        this.storage,
        this.eventsStore,
        this.firebaseAuthService,
        this.apiAuthService,
        this.procoreService,
        this.graphExecutor,
        this.isTestEnv,
      )
    }

    return this.$rootStore
  }
}
