import * as React from 'react'

import {
  DragLayerMonitorProps,
  DropOptions,
  NodeModel,
  PlaceholderRenderParams,
  Tree as ReactDndTree,
  RenderParams,
  TreeMethods,
  useDragOver,
} from '@minoru/react-dnd-treeview'
import { observer } from 'mobx-react'

import { IPermitTypeField, IWorkflowStep } from '~/client/graph'
import ConfirmDialog from '~/client/src/shared/components/ConfirmDialog/ConfirmDialog'
import PermitFieldIcon from '~/client/src/shared/components/PermitFieldIcon/PermitFieldIcon'
import formFieldsCaptionMap from '~/client/src/shared/constants/formFieldsCaptionMap'

import FormFieldsModal from '../FormFieldsModal'
import FormConfiguratorStore, { TREE_ROOT_ID } from './FormConfigurator.store'
import FormFieldConfigurator from './components/FormFieldConfigurator'

interface IProps {
  workflowStep: IWorkflowStep
  isInitialFormStep: boolean
  isMaterialTransfer: boolean

  onWorkflowStepChange(workflowStep: IWorkflowStep): void
}

const DROP_TARGET_OFFSET = 10
const DEPTH_PADDING = 30

const addField = '+ Add a field'
const remove = 'Remove'
const areYouSureWantToDeleteTheField =
  'Are you sure you want to delete the field?'

@observer
export default class FormConfigurator extends React.Component<IProps> {
  private readonly store: FormConfiguratorStore
  private treeRef: TreeMethods

  public constructor(props: IProps) {
    super(props)

    this.store = new FormConfiguratorStore(
      props.workflowStep,
      props.isInitialFormStep,
      props.isMaterialTransfer,
      props.onWorkflowStepChange,
    )
  }

  public componentDidMount() {
    Promise.resolve().then(() => {
      this.treeRef?.open(this.store.openedFieldIds)
    })
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    const { workflowStep, isInitialFormStep, isMaterialTransfer } = this.props

    if (workflowStep !== prevProps.workflowStep) {
      this.store.setWorkflowStep(workflowStep)

      Promise.resolve().then(() => {
        this.treeRef?.open(this.store.openedFieldIds)
      })
    }
    if (isInitialFormStep !== prevProps.isInitialFormStep) {
      this.store.setIsInitialFormStep(isInitialFormStep)
    }
    if (isMaterialTransfer !== prevProps.isMaterialTransfer) {
      this.store.setIsMaterialTransfer(isMaterialTransfer)
    }
  }

  public render() {
    const {
      fields,
      addNewField,
      onChangeFields,
      treeData,
      isRemoveDialogShown,
      hideRemoveDialog,
      removeField,
      restrictedFields,
    } = this.store

    return (
      <>
        <ConfirmDialog
          doneTitle={remove}
          onDoneClicked={removeField}
          isOpen={isRemoveDialogShown}
          onCancelClicked={hideRemoveDialog}
        >
          {areYouSureWantToDeleteTheField}
        </ConfirmDialog>
        {!fields?.length && (
          <div className="row x-center y-start mb24">
            <FormFieldsModal
              addNewField={addNewField}
              restrictedFields={restrictedFields}
            >
              <div className="pointer text extra-large bold blue-highlight px20 my20">
                {addField}
              </div>
            </FormFieldsModal>
          </div>
        )}
        <ReactDndTree
          ref={this.setTreeElement}
          classes={{
            root: 'relative pb40 ma0',
            draggingSource: 'opacity3',
            placeholder: 'relative',
            dropTarget: 'bg-palette-brand-lightest brada4',
          }}
          rootId={TREE_ROOT_ID}
          tree={treeData}
          sort={false}
          insertDroppableFirst={false}
          dropTargetOffset={DROP_TARGET_OFFSET}
          canDrop={this.canDropField}
          render={this.renderNodeRow}
          placeholderRender={this.renderPlaceholder}
          dragPreviewRender={this.renderDragPreview}
          onDrop={onChangeFields}
        />
      </>
    )
  }

  private renderNodeRow = (
    node: NodeModel<IPermitTypeField>,
    { depth, isOpen, onToggle }: RenderParams,
  ): JSX.Element => {
    const onCollapseClick = this.onCollapseClick.bind(
      null,
      onToggle,
      node.id,
      !isOpen,
    )
    const dragOverProps = useDragOver(node.id, isOpen, onCollapseClick)

    return (
      <div
        style={{
          paddingInlineStart: depth * DEPTH_PADDING,
        }}
        {...dragOverProps}
      >
        <FormFieldConfigurator
          depth={depth}
          field={node.data}
          store={this.store}
          isCollapsed={!isOpen}
          onCollapseToggle={onCollapseClick}
        />
      </div>
    )
  }

  private renderPlaceholder = (
    _node: NodeModel<IPermitTypeField>,
    { depth }: PlaceholderRenderParams,
  ): JSX.Element => {
    return (
      <div
        className="absolute bg-color-blue-brand drag-placeholder brada4"
        style={{ left: depth * DEPTH_PADDING }}
      />
    )
  }

  private renderDragPreview = ({
    item,
  }: DragLayerMonitorProps<IPermitTypeField>): JSX.Element => {
    const { type, caption } = item.data

    return (
      <div className="row bg-grey brada10 mw160 h36 px10 text large bold">
        <PermitFieldIcon fieldName={type} />
        <span className="text-ellipsis">
          {caption || formFieldsCaptionMap[type]}
        </span>
      </div>
    )
  }

  private canDropField = (
    tree: NodeModel<IPermitTypeField>[],
    options: DropOptions<IPermitTypeField>,
  ): boolean => {
    return this.store.canDropField(
      tree,
      options.dragSource,
      options.dropTargetId?.toString(),
      options.dropTarget,
    )
  }

  private setTreeElement = (ref: TreeMethods) => {
    this.treeRef = ref
  }

  private onCollapseClick = (
    onToggle: () => void,
    fieldId: string,
    isCollapsed: boolean,
  ) => {
    this.store.collapseField(fieldId, isCollapsed)
    onToggle()
  }
}
