import { action, observable } from 'mobx'

import {
  ActivityStatus,
  CategoryName,
  IPresentationSettings,
  RecentlyUpdatedMode,
  WorkflowsFilterType,
} from '~/client/graph'
import BarState from '~/client/src/desktop/enums/SideBarState'
import ViewModes from '~/client/src/desktop/enums/ViewModes'
import { defaultPresentationScreens } from '~/client/src/desktop/interfaces/IPresentationScreen'
import {
  IActivitiesViewState,
  IViewState,
} from '~/client/src/desktop/interfaces/IViewState'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import DeliveryGroupingOption from '~/client/src/shared/enums/DeliveryGroupingOption'
import SitemapFilterType from '~/client/src/shared/enums/SitemapFilterType'
import InitialState, {
  IFilters,
  ISharedFilters,
  NotificationFilters,
  defaultPresentationInactivityDuration,
} from '~/client/src/shared/stores/InitialState'

import FormReportFilterType from '../../shared/enums/FormReportFilterType'
import FormReportGroupingOption from '../../shared/enums/FormReportGroupingOption'
import { FormTypeFilterType } from '../../shared/enums/FormTypeFilterType'
import { FormTypeGroupingOption } from '../../shared/enums/FormTypeGroupingOption'
import { LogisticsFilterType } from '../../shared/enums/LogisticsFilterType'
import LogisticsGroupingOption from '../../shared/enums/LogisticsGroupingOption'
import { LogisticsMapFilterType } from '../../shared/enums/LogisticsMapFilterType'
import MaterialsFilterType from '../../shared/enums/MaterialsFilterType'
import MaterialsGroupingOption from '../../shared/enums/MaterialsGroupingOption'
import MemberFilterType from '../../shared/enums/MemberFilterType'
import ObjectFilterType from '../../shared/enums/ObjectsFilterType'
import ScanStationFilterType from '../../shared/enums/ScanStationFilterType'
import ScanStationGroupingOption from '../../shared/enums/ScanStationGroupingOption'
import KnownTranslatorKeys from '../../shared/localization/knownTranslatorKeys'
import UIFilterInfo from '../../shared/stores/substates/UIFilterInfo'
import { copyObjectArray, isEmptyObj } from '../../shared/utils/util'
import GroupingOption from '../enums/GroupingOptions'
import IPresentationDisplayedPage from '../interfaces/IPresentationDisplayedPage'
import DeliveryFiltersSettingState from './substates/DeliveryFiltersSettingState'
import FormReportFiltersSettingState from './substates/FormReportFilterSettingState'
import FormTypesFiltersSettingState from './substates/FormTypesFiltersSettingState'
import LogisticsFiltersSettingState from './substates/LogisticsFiltersSettingState'
import MaterialsFiltersSettingState from './substates/MaterialsFilterSettingState'
import ScanStationFiltersSettingState from './substates/ScanStationFilterSettingState'

export interface IBandOption {
  id: string
  name?: string
  nameTraslatorKey?: KnownTranslatorKeys
  shortName?: string
  num?: number
  disabled?: boolean
  bands?: string[]
  deletionIndex?: number
  canDelete?: boolean
}

const defaultHomeViewMode = ViewModes.Map
const defaultFormsViewMode = ViewModes.List
const defaultDeliveriesViewMode = ViewModes.Calendar

export enum SidebarComponentName {
  None = 'None',
  Chat = 'Chat',
  UserDirectory = 'UserDirectory',
}

export default class DesktopInitialState extends InitialState {
  @observable public activeSideBarComponent: SidebarComponentName =
    SidebarComponentName.None
  @observable public appliedCategoryFilters: CategoryName[] = []

  public pushNotificationEntityId = ''
  @observable public notificationsStatusFilters: NotificationFilters[] = [
    NotificationFilters.INBOX,
  ]

  @observable
  public presentationSettings: IPresentationSettings = {
    screens: defaultPresentationScreens,
    inactivityDuration: defaultPresentationInactivityDuration,
    updatedAt: null,
    updatedBy: null,
    projectId: null,
    id: null,
  }

  @observable public currentPresentationPage: IPresentationDisplayedPage = null

  public activityList = observable<IActivitiesViewState>({
    selectedCompany: null,
    shouldShowResponibilitiesPanel: false,
    isActivityDetailsPanelDisplayed: false,
    shouldShowBulkEditPanel: false,
    viewMode: ViewModes.Gantt,
    sortingDataKey: null,
  })

  public materialsList = observable<IViewState>({
    viewMode: ViewModes.List,
    sortingDataKey: null,
  })

  public reportsList = observable<IViewState>({
    viewMode: ViewModes.List,
    sortingDataKey: null,
  })

  public deliveriesList = observable<IViewState>({
    viewMode: defaultDeliveriesViewMode,
    sortingDataKey: null,
  })

  public logisticsList = observable<IViewState>({
    viewMode: defaultHomeViewMode,
    sortingDataKey: null,
  })

  public formsList = observable<IViewState>({
    viewMode: defaultFormsViewMode,
    sortingDataKey: null,
  })

  public filters = observable<IDesktopFilters>({
    searchKey: '',
    selectedActivityStatusMode: ActivityStatus.InProgress,
    selectedRecentlyUpdatedMode: RecentlyUpdatedMode.LastP6Update,
    recentlyUpdatedStartDate: null,
    recentlyUpdatedEndDate: null,
    appliedCustomFilterId: null,
    showEmptyBands: false,
    locationsMap: {},
    selectedBandsOption: {
      id: GroupingOption.None,
      nameTraslatorKey: KnownTranslatorKeys.none,
      num: 0,
      disabled: false,
      bands: [GroupingOption.None],
    },
    selectedDeliveryBandsOption: {
      id: DeliveryGroupingOption[FieldIds.DATE],
      nameTraslatorKey: KnownTranslatorKeys.none,
      num: 0,
      disabled: false,
      bands: [DeliveryGroupingOption[FieldIds.DATE]],
    },
    selectedDeliveryReportBandsOption: {
      id: DeliveryGroupingOption[FieldIds.DATE],
      nameTraslatorKey: KnownTranslatorKeys.none,
      num: 0,
      disabled: false,
      bands: [DeliveryGroupingOption[FieldIds.DATE]],
    },
    selectedMaterialBandsOption: {
      id: MaterialsGroupingOption.NONE,
      nameTraslatorKey: KnownTranslatorKeys.none,
      num: 1,
      disabled: false,
      bands: [MaterialsGroupingOption.NONE],
    },
  })

  public logisticsFilters = observable<IFilters>({
    searchKey: '',
    groupingKey: LogisticsGroupingOption.APP,
    fieldsMap: {
      [LogisticsFilterType.Status]: new UIFilterInfo(),
      [LogisticsFilterType.Location]: new UIFilterInfo(),
      [LogisticsFilterType.Company]: new UIFilterInfo(),
      [LogisticsFilterType.App]: new UIFilterInfo(),
      [LogisticsFilterType.ResponsibleContact]: new UIFilterInfo(),
    },
  })

  public logisticsMapFilters = observable<IFilters>({
    searchKey: '',
    fieldsMap: {
      [LogisticsMapFilterType.Location]: new UIFilterInfo(),
      [LogisticsMapFilterType.Company]: new UIFilterInfo(),
      [LogisticsMapFilterType.App]: new UIFilterInfo(),
      [LogisticsMapFilterType.ResponsibleContact]: new UIFilterInfo(),
    },
  })

  public formsMapFilters = observable<IFilters>({
    searchKey: '',
    fieldsMap: {
      [LogisticsMapFilterType.Location]: new UIFilterInfo(),
      [LogisticsMapFilterType.Company]: new UIFilterInfo(),
      [LogisticsMapFilterType.Type]: new UIFilterInfo(),
      [LogisticsMapFilterType.ResponsibleContact]: new UIFilterInfo(),
    },
  })

  public formsFilters = observable<IFilters>({
    searchKey: '',
    groupingKey: LogisticsGroupingOption.TYPE,
    fieldsMap: {
      [WorkflowsFilterType.Status]: new UIFilterInfo(),
      [WorkflowsFilterType.Location]: new UIFilterInfo(),
      [WorkflowsFilterType.Company]: new UIFilterInfo(),
      [WorkflowsFilterType.Type]: new UIFilterInfo(),
      [WorkflowsFilterType.ResponsibleContact]: new UIFilterInfo(),
    },
  })

  public materialFilters = observable<IFilters>({
    searchKey: '',
    groupingKey: MaterialsGroupingOption.NONE,
    fieldsMap: {
      [MaterialsFilterType.MATERIAL]: new UIFilterInfo(),
      [MaterialsFilterType.COMPANY]: new UIFilterInfo(),
      [MaterialsFilterType.STATUS]: new UIFilterInfo(),
      [MaterialsFilterType.PLANNED_INSTALL_LOCATION]: new UIFilterInfo(),
      [MaterialsFilterType.PLANNED_DELIVERY_LOCATION]: new UIFilterInfo(),
      [MaterialsFilterType.CURRENT_LOCATION]: new UIFilterInfo(),
    },
  })

  public scanStationFilters = observable<IFilters>({
    searchKey: '',
    groupingKey: ScanStationGroupingOption.DAY,
    fieldsMap: {
      [ScanStationFilterType.SCANNER]: new UIFilterInfo(),
      [ScanStationFilterType.COMPANY]: new UIFilterInfo(),
    },
  })

  public formReportFilters = observable<IFilters>({
    searchKey: '',
    groupingKey: FormReportGroupingOption.NONE,
    fieldsMap: {
      [FormReportFilterType.COMPANY]: new UIFilterInfo(),
      [FormReportFilterType.STATUS]: new UIFilterInfo(),
      [FormReportFilterType.LOCATION]: new UIFilterInfo(),
    },
  })

  public formTypesFilters = observable<IFilters>({
    searchKey: '',
    groupingKey: FormTypeGroupingOption.CATEGORY,
    fieldsMap: {
      [FormTypeFilterType.WORKFLOW_CATEGORY]: new UIFilterInfo(),
      [FormTypeFilterType.APP]: new UIFilterInfo(),
    },
  })

  public objectFilters = observable<IFilters>({
    searchKey: '',
    iconKey: '',
    timeKey: {
      start: null,
      end: null,
    },
    dateKey: {
      start: null,
      end: null,
    },
    fieldsMap: {
      [ObjectFilterType.Company]: new UIFilterInfo(),
    },
  })

  public teamMembersFilters = observable<IFilters>({
    searchKey: '',
    fieldsMap: {
      [MemberFilterType.AccountType]: new UIFilterInfo(),
      [MemberFilterType.InviteStatus]: new UIFilterInfo(),
    },
  })

  public companiesFilters = observable<IFilters>({
    searchKey: '',
  })

  public sitemapFilters = observable<IFilters>({
    searchKey: '',
    fieldsMap: {
      [SitemapFilterType.Items]: new UIFilterInfo(),
      [SitemapFilterType.Tags]: new UIFilterInfo(),
    },
    groupingKey: GroupingOption.Lbs,
  })

  public globeFilters = observable<IFilters>({
    searchKey: '',
  })

  @observable public timestampToUpdatingDeliveriesIdsMap: {
    [key: number]: string[]
  } = {}

  @observable public timestampToEditingDeliveriesIdsMap: {
    [key: number]: string[]
  } = {}

  public displayedContentObjectId: string
  @observable public sideBarState = BarState.Default

  @observable public deliveryFiltersSettings = new DeliveryFiltersSettingState()
  @observable public deliveryReportFiltersSettings =
    new DeliveryFiltersSettingState()
  @observable
  public logisticsFiltersSettings = new LogisticsFiltersSettingState()
  @observable
  public formsFiltersSettings = new LogisticsFiltersSettingState()

  @observable public materialFiltersSettings =
    new MaterialsFiltersSettingState()

  @observable public scanStationFilterSettings =
    new ScanStationFiltersSettingState()
  @observable public formReportFilterSettings =
    new FormReportFiltersSettingState()

  @observable public formTypesFilterSettings =
    new FormTypesFiltersSettingState()

  public updatePresentationSettings(_source: IPresentationSettings) {
    const source =
      _source && !isEmptyObj(_source)
        ? this.getSettingsCopy(_source)
        : this.getDefaultPresentationSettings()
    const { screens, inactivityDuration } = source
    source.inactivityDuration =
      inactivityDuration || defaultPresentationInactivityDuration
    source.screens = screens || defaultPresentationScreens

    if (screens) {
      defaultPresentationScreens.forEach(defaultScreen => {
        if (!screens?.some(({ key }) => key === defaultScreen.key)) {
          source.screens.push(
            Object.assign({}, defaultScreen, { order: screens.length }),
          )
        }
      })
    }

    this.presentationSettings = source
  }

  public getDefaultPresentationSettings(): IPresentationSettings {
    return {
      screens: defaultPresentationScreens,
      inactivityDuration: defaultPresentationInactivityDuration,
      updatedAt: null,
      updatedBy: null,
      projectId: this.activeProject.id,
      id: null,
    }
  }

  @action.bound
  public hideChat() {
    this.activeSideBarComponent = SidebarComponentName.None
  }

  @action.bound
  public openChat() {
    this.activeSideBarComponent = SidebarComponentName.Chat
  }

  @action.bound
  public toggleChat() {
    this.activeSideBarComponent =
      this.activeSideBarComponent === SidebarComponentName.Chat
        ? SidebarComponentName.None
        : SidebarComponentName.Chat
  }

  @action.bound
  public toggleSideUserDirectory() {
    this.activeSideBarComponent =
      this.activeSideBarComponent === SidebarComponentName.UserDirectory
        ? SidebarComponentName.None
        : SidebarComponentName.UserDirectory
  }

  private getSettingsCopy({
    inactivityDuration,
    screens,
    updatedAt,
    updatedBy,
    projectId,
    id,
  }: IPresentationSettings) {
    return {
      screens: copyObjectArray(screens),
      inactivityDuration,
      updatedAt,
      updatedBy,
      projectId,
      id,
    }
  }
}

export interface IDesktopFilters extends IFilters, ISharedFilters {
  appliedCustomFilterId: string
  showEmptyBands: boolean
  locationsMap: {
    [filterType: string]: UIFilterInfo
  }
  selectedBandsOption: IBandOption
  selectedDeliveryBandsOption: IBandOption
  selectedDeliveryReportBandsOption: IBandOption
  selectedMaterialBandsOption: IBandOption
}
