import { action } from 'mobx'

import CalendarViewStore, {
  DEFAULT_TIME_INTERVAL,
} from '~/client/src/shared/stores/ui/CalendarView.store'

import CalendarEvent, {
  CalendarEventEntityType,
  NEW_EVENT_DATA_ID,
} from '../../models/CalendarEvent'
import CompaniesStore from '../../stores/domain/Companies.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'

export default class CalendarDayViewStore extends CalendarViewStore {
  private initialStartDate: Date

  public constructor(
    protected projectDateStore: ProjectDateStore,
    protected companiesStore: CompaniesStore,
    private entityType: CalendarEventEntityType,
    private entityDuration?: number,
    private areMultiEventsShownApart?: boolean,
  ) {
    super(projectDateStore, companiesStore)
  }

  public get timeInterval() {
    return this.entityDuration || DEFAULT_TIME_INTERVAL
  }

  public getActiveDateEvents = (events: CalendarEvent[]): CalendarEvent[] => {
    const { isSameDay } = this.projectDateStore
    if (this.areMultiEventsShownApart) {
      events = events.filter(e => isSameDay(e.startDate, e.endDate))
    }

    if (this.isNewEventMode) {
      if (!this.areMultiEventsShownApart) {
        return [...events, this.getNewEvent(this.entityType)]
      } else if (
        isSameDay(this.editableEventStartDate, this.editableEventEndDate)
      ) {
        return [...events, this.getNewEvent(this.entityType)]
      }
    }

    return events
  }

  public getActiveMultiDayEvents = (
    events: CalendarEvent[],
  ): CalendarEvent[] => {
    const { isSameDay } = this.projectDateStore

    const _events = events.filter(e => !isSameDay(e.startDate, e.endDate))

    if (
      this.isNewEventMode &&
      !isSameDay(this.editableEventStartDate, this.editableEventEndDate)
    ) {
      return [..._events, this.getNewEvent(this.entityType)]
    }

    return _events
  }

  @action.bound
  public activateNewEventMode(initialDate: Date) {
    this.editableEventStartDate = this.initialStartDate = initialDate
    this.editableEventEndDate = this.getEndDate(this.editableEventStartDate)
    this.editableEventDataId = NEW_EVENT_DATA_ID
    this.setNewEventMode()
  }

  @action.bound
  public updateEditableEventStartDate(minutesDelta: number) {
    this.editableEventStartDate = this.projectDateStore.addMinutes(
      this.initialStartDate,
      minutesDelta,
    )

    this.validateEditableEventStartDate(this.initialStartDate)
  }
}
