import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'

import SlackBarStore from '~/client/src/shared/stores/ui/SlackBar.store'

import './ImagePreview.scss'

interface IImagePreview {
  slackBarStore?: SlackBarStore
  className?: string
  removeImage?: () => void
}

@inject('slackBarStore')
@observer
export default class ImagePreview extends React.Component<IImagePreview> {
  public store: SlackBarStore

  public constructor(props: IImagePreview) {
    super(props)
    this.store = props.slackBarStore
  }

  public componentWillUnmount() {
    this.store.hidePreview()
  }

  public render() {
    const { className = 'preview-image-wrapper' } = this.props
    return (
      <div className={className}>
        {this.renderRemoveImage()}
        <div className="preview-image-container col y-center">
          <div>{this.props.children}</div>
        </div>
      </div>
    )
  }

  private renderRemoveImage() {
    const { removeImage } = this.props
    if (removeImage) {
      return (
        <div
          onClick={removeImage}
          style={{ top: 0, right: 0, zIndex: 2 }}
          className="absolute brada24 bg-dark row x-center"
        >
          <Icon className="remove-icon" icon={IconNames.SMALL_CROSS} />
        </div>
      )
    }
  }
}
