import { PermitFieldType } from '~/client/graph'

const formFieldsCaptionMap = {
  [PermitFieldType.InputText]: 'Text input',
  [PermitFieldType.InputTextArea]: 'Text input (Multiple lines)',
  [PermitFieldType.InputNumber]: 'Number input',
  [PermitFieldType.StartFinishDates]: 'Form Start & Finish',
  [PermitFieldType.AdditionalDate]: 'Date (Informational)',
  [PermitFieldType.Time]: 'Time',
  [PermitFieldType.Company]: 'Company',
  [PermitFieldType.Requester]: 'Requester',
  [PermitFieldType.Assignee]: 'Assigned to',
  [PermitFieldType.Subscriber]: 'Additional contact',
  [PermitFieldType.User]: 'User (Informational)',
  [PermitFieldType.Location]: 'Location',
  [PermitFieldType.RequestToCloseLocations]: 'Request to close locations',
  [PermitFieldType.Select]: 'Dropdown',
  [PermitFieldType.Measure]: 'Measure (Number + unit)',
  [PermitFieldType.Email]: 'Email',
  [PermitFieldType.PhoneNumber]: 'Phone number',
  [PermitFieldType.Instructions]: 'List',
  [PermitFieldType.Checklist]: 'Checklist',
  [PermitFieldType.Question]: 'Question',
  [PermitFieldType.Divider]: 'Divider line',
  [PermitFieldType.Section]: 'Section',
  [PermitFieldType.Subsection]: 'Subsection',
  [PermitFieldType.Text]: 'Plain text',
  [PermitFieldType.File]: 'Add file or photo',
  [PermitFieldType.Table]: 'Table',
  [PermitFieldType.LocationInformational]: 'Location (Informational)',
  [PermitFieldType.Material]: 'Material',
}

export default formFieldsCaptionMap
