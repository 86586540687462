import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { inject, observer } from 'mobx-react'
import { Mention, MentionsInput } from 'react-mentions'

import Localization from '~/client/src/shared/localization/LocalizationManager'
import { ITag } from '~/client/src/shared/models/Tag'
import { NOOP } from '~/client/src/shared/utils/noop'

import BaseAssignmentsStore from '../../stores/BaseAssignments.store'
import BaseFollowingsStore from '../../stores/BaseFollowings.store'
import InitialState from '../../stores/InitialState'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import TagsStore from '../../stores/domain/Tags.store'
import UserProjectsStore from '../../stores/domain/UserProjects.store'
import BaseActionButton from '../BaseActionButton/BaseActionButton'
import Dimmer from '../Dimmer'
import UsersDirectory from '../UsersDirectory/UsersDirectory'
import ActionBarInputWithMentionsStore, {
  ICustomMention,
  IMention,
  MENTION_CHAR,
} from './ActionBarInputWithMentions.store'
import MentionRow from './components/MentionRow'

import './ActionBarInputWithMentions.scss'

import { getElementHeightByRef } from '../../utils/RefHelpers'

const DEFAULT_BOTTOM_VALUE = 97
const BOTTOM_OFFSET = 10

interface IActionBarInput {
  value: string
  plainText: string
  mentions: ITag[]
  onChange: (value: string, text?: string, mentions?: ITag[]) => void
  onSuggestionsPopupShow?: () => void
  onSuggestionsPopupHide?: () => void
  placeholder?: string
  className?: string
  entityId: string
  assignmentsStore: BaseAssignmentsStore
  followingsStore: BaseFollowingsStore
  parentRef?: HTMLDivElement

  state?: InitialState
  tagsStore?: TagsStore
  projectMembersStore?: ProjectMembersStore
  userProjectsStore?: UserProjectsStore
}

const iconSize = 20

@inject('state', 'tagsStore', 'projectMembersStore', 'userProjectsStore')
@observer
export default class ActionBarInputWithMentions extends React.Component<IActionBarInput> {
  public static defaultProps = {
    className: 'mention-input mb10 no-resize full-width',
    onSuggestionsPopupShow: NOOP,
    onSuggestionsPopupHide: NOOP,
  }

  private readonly store: ActionBarInputWithMentionsStore = null
  private textarea: HTMLInputElement
  private suggestionsPortal: HTMLDivElement

  public constructor(props: IActionBarInput) {
    super(props)

    this.store = new ActionBarInputWithMentionsStore(
      props.state,
      props.assignmentsStore,
      props.followingsStore,
      props.projectMembersStore,
      props.userProjectsStore,
      props.entityId,
      props.tagsStore,
      props.onSuggestionsPopupShow,
      props.onSuggestionsPopupHide,
      props.onChange,
      props.value,
      props.plainText,
      props.mentions,
    )
  }

  public componentDidUpdate(prevProps: Readonly<IActionBarInput>) {
    const { value, plainText, mentions } = this.props

    if (
      value !== this.store.currentValue ||
      plainText !== this.store.currentPlainText
    ) {
      this.store.setValues(value, plainText, mentions)
    }

    const shouldTriggerMentionMenu =
      this.textarea &&
      value !== prevProps.value &&
      value[value.length - 1] === MENTION_CHAR

    if (shouldTriggerMentionMenu) {
      this.textarea.focus()

      // hack for mobile devices to set cursor at the end of focused input
      const plainValue = this.textarea.value
      this.textarea.value = ''
      this.textarea.value = plainValue
    }

    if (!value && this.textarea) {
      this.textarea.style.height = 'auto'
    }
  }

  public render() {
    const { value, placeholder, className } = this.props
    const { getSuggestions } = this.store

    return (
      <>
        {this.renderEmbeddedUserDirectory()}
        {this.renderSuggestionsPopup()}
        <div
          className="mention-input-container"
          onMouseDown={e => e.stopPropagation()}
        >
          <MentionsInput
            inputRef={this.setTextareaRef}
            value={value}
            onChange={this.handleChange}
            className={className}
            placeholder={placeholder}
            suggestionsPortalHost={this.suggestionsPortal}
          >
            <Mention
              trigger={MENTION_CHAR}
              data={getSuggestions}
              renderSuggestion={this.renderOptionRow}
              className="mention-input__mention"
              appendSpaceOnAdd={true}
            />
          </MentionsInput>
        </div>
      </>
    )
  }

  private setTextareaRef = ref => {
    this.textarea = ref
  }

  private setSuggestionsPortalRef = ref => {
    this.suggestionsPortal = ref
  }

  private renderOptionRow = (entry: ICustomMention): JSX.Element => {
    const { showSuggestionsPopup, hideSuggestionsPopup } = this.store

    return (
      <MentionRow
        entry={entry}
        onMount={showSuggestionsPopup}
        onUnmount={hideSuggestionsPopup}
      />
    )
  }

  private renderSuggestionsPopup() {
    const { isSuggestionsPopupOpen, showUserDirectory } = this.store
    const bottomString = `${this.bottomValue}px`
    return (
      <>
        {isSuggestionsPopupOpen && (
          <Dimmer
            shown={true}
            onClick={NOOP}
            style={{ bottom: bottomString }}
          />
        )}
        <div
          className="custom-suggestions-popup"
          style={{ bottom: bottomString }}
        >
          {isSuggestionsPopupOpen && (
            <div className="row py12 relative">
              <Icon icon={IconNames.CROSS} className="absolute close-handler" />
              <div className="text large bold center">
                {Localization.translator.contacts}
              </div>
              <span
                className="absolute text large blue-highlight action-handler pointer"
                onMouseDown={showUserDirectory}
              >
                {Localization.translator.gotToDirectory}
              </span>
            </div>
          )}
          <div ref={this.setSuggestionsPortalRef} />
        </div>
      </>
    )
  }

  private get bottomValue() {
    const height = getElementHeightByRef(this.props.parentRef)
    return height ? BOTTOM_OFFSET + height : DEFAULT_BOTTOM_VALUE
  }

  private renderEmbeddedUserDirectory() {
    const {
      shouldUserDirectoryShow,
      hideUserDirectory,
      hasUseDirectorySelectedItems,
      onChangeUserDirectorySelection,
      mentionFromDirectory,
    } = this.store

    return (
      shouldUserDirectoryShow && (
        <div className="embedded-user-directory col absolute full-height full-width">
          <div className="row pa12 bg-white">
            <div className="text blue-highlight">
              <Icon
                icon={IconNames.CHEVRON_LEFT}
                iconSize={iconSize}
                onClick={hideUserDirectory}
              />
            </div>
            <BaseActionButton
              onClick={mentionFromDirectory}
              className="no-grow bold scale-blue-theme"
              title={Localization.translator.mention}
              isEnabled={hasUseDirectorySelectedItems}
            />
          </div>
          <UsersDirectory
            isSelectionMode={true}
            onItemSelect={onChangeUserDirectorySelection}
            className="scrollable"
          />
        </div>
      )
    )
  }

  private handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    newValue: string,
    newPlainTextValue: string,
    mentions: IMention[],
  ) => {
    this.store.change(newValue, newPlainTextValue, mentions)
  }
}
