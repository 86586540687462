import { action, computed, observable } from 'mobx'

import {
  IWorkflowStep,
  PermitFieldType,
  SitePermitStatus,
  WorkflowStepType,
} from '~/client/graph'
import ProjectDateStore, {
  isAfter,
} from '~/client/src/shared//stores/ui/ProjectDate.store'
import IPermitFieldDto from '~/client/src/shared/models/IPermitFieldDto'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import SitePermit from '~/client/src/shared/models/Permit'
import PermitType from '~/client/src/shared/models/PermitType'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import SitePermitsStore from '~/client/src/shared/stores/domain/SitePermits.store'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'
import { areArraysEqual } from '~/client/src/shared/utils/util'

import { getWorkflowStepLevel } from '../../../constants/formStatusesTags'
import PermitFieldsBaseStore, {
  IPermitFormField,
} from './PermitFieldsBase.store'

enum AdditionalPermitFields {
  Type = 'type',
  Title = 'title',
  SpecificInspectionOptions = 'spectific-inspection-options',
}

const getInitFormStatus = (
  formType: PermitType,
  isLastStep?: boolean,
): SitePermitStatus => {
  if (isLastStep || formType?.workflowSteps?.length === 1) {
    return SitePermitStatus.Done
  }
  if (formType?.initialStep?.type === WorkflowStepType.Submission) {
    return SitePermitStatus.Submitted
  }
  return SitePermitStatus.Requested
}

export default class PermitWorkflowFieldsStore
  extends PermitFieldsBaseStore
  implements IPermitFieldsStore
{
  @observable public editablePermitId: string
  @observable private newPermit: SitePermit = null

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly permitTypesStore: PermitTypesStore,
    private readonly companiesStore: CompaniesStore,
    private readonly sitePermitsStore?: SitePermitsStore,
  ) {
    super(eventsStore.appState)
  }

  @computed
  public get editableEntity(): SitePermit {
    if (this.editablePermitId) {
      const permit = this.sitePermitsStore.getFormById(this.editablePermitId)
      return permit?.copy()
    }

    return this.newPermit
  }

  @computed
  public get existingEntity(): SitePermit {
    if (this.editablePermitId) {
      return this.sitePermitsStore.getFormById(this.editablePermitId)
    }
  }

  public get template(): PermitType {
    return this.permitTypesStore.getPermitTypeById(this.editableEntity?.typeId)
  }

  public get permitTypeField(): IPermitFormField {
    const { typeId } = this.editableEntity
    return {
      id: AdditionalPermitFields.Type,
      isChanged: !!this.existingEntity && typeId !== this.existingEntity.typeId,
      isRequired: true,
      isValid: !!typeId,
    }
  }

  public get titleField(): IPermitFormField {
    const { title } = this.editableEntity
    return {
      id: AdditionalPermitFields.Title,
      isChanged: !!this.existingEntity && title !== this.existingEntity?.title,
      isRequired: false,
      isValid: true,
    }
  }

  public get inspectionOptionsField(): IPermitFormField {
    const { specificInspectionOptions } = this.editableEntity

    const areInspectionOptionsChanged =
      specificInspectionOptions?.deadlineTime !==
        this.existingEntity?.specificInspectionOptions?.deadlineTime ||
      specificInspectionOptions?.inspectionFrequency !==
        this.existingEntity?.specificInspectionOptions?.inspectionFrequency ||
      specificInspectionOptions?.inspectionFrequencyType !==
        this.existingEntity?.specificInspectionOptions
          ?.inspectionFrequencyType ||
      !areArraysEqual(
        specificInspectionOptions?.selectedDaysToRepeat || [],
        this.existingEntity?.specificInspectionOptions?.selectedDaysToRepeat ||
          [],
      )

    return {
      id: AdditionalPermitFields.SpecificInspectionOptions,
      isChanged: areInspectionOptionsChanged,
      isValid:
        !specificInspectionOptions ||
        (!!specificInspectionOptions?.deadlineTime &&
          !!specificInspectionOptions?.inspectionFrequency &&
          !!specificInspectionOptions?.inspectionFrequencyType),
    }
  }

  public get requestToCloseField(): IPermitFormField {
    const { isClosure } = this.editableEntity

    const isRequired = this.workflowSteps[0].fields?.find(
      f => f.type === PermitFieldType.RequestToCloseLocations,
    )?.isMandatory

    return {
      id: PermitFieldType.RequestToCloseLocations,
      isChanged:
        !!this.existingEntity && isClosure !== this.existingEntity?.isClosure,
      isRequired,
      isValid: true,
    }
  }

  public get isRequestToCloseShown(): boolean {
    if (!this.template) return false

    const { hasStartStep, hasApprovalStep, isApprovalLast } = this.template
    return (
      hasStartStep ||
      (hasApprovalStep &&
        !isApprovalLast &&
        this.editableEntity?.isAutoActivationEnabled)
    )
  }

  public get datesField(): IPermitFormField {
    const { startDate, endDate } = this.editableEntity

    const areDatesMandatory = this.workflowSteps[0].fields?.find(
      f => f.type === PermitFieldType.StartFinishDates,
    )?.isMandatory

    const areDatesChanged =
      !!this.existingEntity &&
      (startDate !== this.existingEntity?.startDate ||
        endDate !== this.existingEntity?.endDate)

    return {
      id: PermitFieldType.StartFinishDates,
      isChanged: areDatesChanged,
      isRequired: areDatesMandatory,
      isValid: !!(startDate && endDate) || !areDatesMandatory,
    }
  }

  protected get canIgnoreValidation(): boolean {
    return true
  }

  @computed
  protected get fieldIdsToIgnore(): string[] {
    const { currentWorkflowStepId } = this.existingEntity || this.editableEntity
    const currentStep = this.workflowSteps.find(
      s => s.id === currentWorkflowStepId,
    )
    const currentStepFieldIds = currentStep?.fields?.map(({ id }) => id) || []
    const currentStepCondFieldIds =
      currentStep?.conditionalFields?.flatMap(({ values }) =>
        values.map(v => v.id),
      ) || []

    return currentStepFieldIds.concat(currentStepCondFieldIds)
  }

  @computed
  public get previousStepsFields(): IPermitFormField[] {
    return this.fields.filter(f => !this.fieldIdsToIgnore.includes(f.id))
  }

  @computed
  public get fields(): IPermitFormField[] {
    const permitFields = this.workflowSteps
      .flatMap(s => s.fields)
      ?.reduce((fieldsList, typeField) => {
        if (!typeField.isShown) {
          return fieldsList
        }

        if (typeField.type === PermitFieldType.StartFinishDates) {
          fieldsList.push(this.datesField)
          return fieldsList
        }
        if (typeField.type === PermitFieldType.RequestToCloseLocations) {
          if (this.isRequestToCloseShown) {
            fieldsList.push(this.requestToCloseField)
          }
          return fieldsList
        }

        fieldsList.push(this.getFormFieldModel(typeField))

        if (typeField.type === PermitFieldType.Table) {
          fieldsList.push(...(this.getTableFieldModels(typeField) || []))
        }

        this.getAvailableConditionalFields(typeField).forEach(f => {
          fieldsList.push(this.getFormFieldModel(f))
          if (f.type === PermitFieldType.Table) {
            fieldsList.push(...(this.getTableFieldModels(f) || []))
          }
        })

        return fieldsList
      }, [] as IPermitFormField[])

    return [
      this.permitTypeField,
      this.titleField,
      this.inspectionOptionsField,
      ...(permitFields || []),
    ]
  }

  @computed
  protected get workflowSteps(): IWorkflowStep[] {
    if (!this.template) {
      return []
    }

    const { stepsWithoutRecurring, isInspectionType } = this.template
    const { currentWorkflowStepId, isActive } =
      this.existingEntity || this.editableEntity

    const currentStepIndex = stepsWithoutRecurring.findIndex(
      s => s.id === currentWorkflowStepId,
    )

    if (currentStepIndex === -1) {
      return stepsWithoutRecurring.slice(0, 1)
    }

    if (
      isInspectionType &&
      !this.isLastInspectionDay &&
      isActive(this.template)
    ) {
      return stepsWithoutRecurring.slice(0, currentStepIndex)
    }

    return stepsWithoutRecurring.slice(0, currentStepIndex + 1)
  }

  public get enabledPermitTypes(): PermitType[] {
    return this.permitTypesStore.enabledTypes
  }

  public get actualPermiTypes(): PermitType[] {
    return this.permitTypesStore.actualTypes
  }

  public get isLastInspectionDay(): boolean {
    const { isSameDay } = this.projectDateStore
    const { endDate } = this.editableEntity
    const now = Date.now()

    return (
      this.template?.isInspectionType &&
      (isSameDay(endDate, now) || isAfter(now, endDate))
    )
  }

  @action.bound
  public setDefaultSitePermit(
    permitToEditId?: string,
    typeId?: string,
    isLastStep?: boolean,
    prepopulatedFields?: IPermitFieldDto[],
  ) {
    if (permitToEditId) {
      this.editablePermitId = permitToEditId
      return
    }

    const { addDays, projectWorkingHours } = this.projectDateStore
    const {
      user,
      isVisitorMode,
      activeProject,
      preSelectedPermitTypeId,
      userActiveProjectSettings,
    } = this.eventsStore.appState
    const { id: projectId } = activeProject
    const { startTime, endTime } = projectWorkingHours

    let endDate = endTime

    if (projectWorkingHours.startTime > projectWorkingHours.endTime) {
      endDate = addDays(endDate, 1)
    }

    const initPermitType =
      this.actualPermiTypes.find(({ id }) => typeId === id) ||
      this.actualPermiTypes.find(({ id }) => preSelectedPermitTypeId === id) ||
      this.enabledPermitTypes[0]

    const company = this.companiesStore.getCompanyById(
      userActiveProjectSettings?.companyId,
      true,
    )

    const initialPermitFields: IPermitFieldDto[] = prepopulatedFields || []

    const typeInitialFields = initPermitType?.initialStep?.fields || []
    const initialStepId = isLastStep
      ? initPermitType?.lastStep?.id
      : initPermitType?.initialStep?.id

    if (initPermitType?.id && company) {
      initialPermitFields.push(
        ...typeInitialFields
          .filter(({ type }) => type === PermitFieldType.Company)
          .map(f => ({
            fieldId: f.id,
            type: f.type,
            objectIdValues: [company.id],
          })),
      )
    }
    if (initPermitType?.id && !isVisitorMode) {
      initialPermitFields.push(
        ...typeInitialFields
          .filter(({ type }) => type === PermitFieldType.Assignee)
          .map(f => ({
            fieldId: f.id,
            type: f.type,
            objectIdValues: [user.id],
          })),
      )
    }
    if (initPermitType?.id) {
      initialPermitFields.push(
        ...typeInitialFields
          .filter(({ type }) => type === PermitFieldType.Requester)
          .map(f => ({
            fieldId: f.id,
            type: f.type,
            objectIdValues: [user.id],
          })),
      )
    }

    this.newPermit = new SitePermit(
      null,
      initPermitType?.id,
      false,
      getInitFormStatus(initPermitType, isLastStep),
      projectId,
      startTime.getTime(),
      endDate.getTime(),
      EMPTY_STRING,
      initialStepId,
      initialStepId
        ? getWorkflowStepLevel(initialStepId, initPermitType.workflowSteps)
        : 0,
      initPermitType?.isAutoActivationEnabled,
      null,
      null,
      null,
      null,
      null,
      initialPermitFields,
    )
  }
}
