import { IObservableObject, action, computed, observable } from 'mobx'

import {
  IWorkflowsFilter,
  LocationType,
  WorkflowsFilterType,
} from '~/client/graph'
import { CustomFilterDialogModes } from '~/client/src/shared/enums/CustomFilterDialogModes'
import {
  extendedWorkflowsFilterTypes,
  mapWorkflowFilterTypes,
} from '~/client/src/shared/enums/WorkflowsFilterType'
import Permit from '~/client/src/shared/models/Permit'
import { IFilters } from '~/client/src/shared/stores/InitialState'
import LogisticsFilterStore from '~/client/src/shared/stores/LogisticsFilter.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import CustomWorkflowsListFiltersStore from '~/client/src/shared/stores/domain/CustomWorkflowsListFilter.store'
import PermitTypesStore from '~/client/src/shared/stores/domain/PermitTypes.store'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import CustomWorkflowsFilter from '~/client/src/shared/types/CustomWorkflowFilter'
import { UNASSIGNED } from '~/client/src/shared/utils/ZoneLevelLocationConstants'
import {
  LOCATION_SEPARATOR,
  NO_VALUE,
} from '~/client/src/shared/utils/usefulStrings'

import DesktopEventStore from '../../../stores/EventStore/DesktopEvents.store'
import LogisticsStore from '../../../views/Logistics/Logistics.store'
import DesktopSavedFiltersStore from '../SavedFilters/DesktopSavedFilters.store'

// localization: no display text to translate

export default class DesktopCustomWorkflowsFiltersStore extends DesktopSavedFiltersStore {
  @observable public customFilterInstance: CustomWorkflowsFilter = null

  public constructor(
    private readonly logisticsFilterStore: LogisticsFilterStore,
    private readonly logisticsStore: LogisticsStore,
    private readonly customWorkflowsListFiltersStore: CustomWorkflowsListFiltersStore,
    private readonly companiesStore: CompaniesStore,
    private readonly projectMembersStore: ProjectMembersStore,
    private readonly permitTypesStore: PermitTypesStore,
    protected readonly eventsStore: DesktopEventStore,
    protected readonly getItemNameByLocationTypeAndId: (
      type: LocationType,
      id: string,
    ) => string,
    protected readonly filters: IFilters & IObservableObject,
    protected readonly onShowChanged?: (isShown: boolean) => void,
  ) {
    super(eventsStore, onShowChanged)
  }

  @computed
  private get enabledFilterTypes(): string[] {
    const { filterInfoMap } = this.eventsStore.appState.formsFiltersSettings
    return Object.keys(filterInfoMap)
  }

  public get customFilters(): CustomWorkflowsFilter[] {
    return this.customWorkflowsListFiltersStore.list
  }

  public get selectedCustomFilter(): CustomWorkflowsFilter {
    const filterId = this.selectedCustomFilterId || this.appliedCustomFilterId
    return this.customWorkflowsListFiltersStore.byId.get(filterId)
  }

  public getWorkflowsCountByCustomFilter = (
    filter?: CustomWorkflowsFilter,
  ): number => {
    const selectedFilter = filter || this.customFilterInstance
    return (
      this.workflowsInSelectedFilters(selectedFilter.filtersByFilterType)
        ?.length || 0
    )
  }

  @computed
  public get filtersCount(): number {
    if (!this.customFilterInstance) {
      return 0
    }

    const { filtersByFilterType: workflowsFiltersByFilterType } =
      this.customFilterInstance
    return workflowsFiltersByFilterType.reduce((sum, filters) => {
      if (!filters.values) {
        return sum
      }
      return sum + filters.values.length
    }, 0)
  }

  @computed
  public get filtersDescriptions(): string[] {
    if (!this.customFilterInstance) {
      return []
    }
    const { filtersByFilterType: workflowsFiltersByFilterType } =
      this.customFilterInstance

    const filtersDescriptions = []
    workflowsFiltersByFilterType
      .filter(filter => {
        return filter.values && filter.values.length
      })
      .map(filter => {
        filtersDescriptions.push(
          (
            this.getSelectedFilterValues(filter.type, filter.values, true) || []
          ).join(', '),
        )
      })
    return filtersDescriptions
  }

  @action.bound
  public deleteCustomFilter(): void {
    if (!this.selectedCustomFilter) {
      return
    }

    this.customWorkflowsListFiltersStore.removeOne(this.selectedCustomFilter.id)
    if (this.selectedCustomFilter.id === this.appliedCustomFilterId) {
      this.resetCustomFilter()
      this.resetAllFilters()
    }

    this.closeSavedFilters()
  }

  @action.bound
  public editCustomFilter(): void {
    if (!this.selectedCustomFilter) {
      return
    }
    this.customFilterInstance = this.selectedCustomFilter
    this.editableFilterName = this.selectedCustomFilter.name
    this.editableFilterIsPublic = this.selectedCustomFilter.isPublic
    this.filterDialogMode = CustomFilterDialogModes.Edit
    this.shouldSaveFilterDialogShow = true
  }

  @action.bound
  public saveCustomFilter(): void {
    this.customFilterInstance.name = this.editableFilterName
    this.customFilterInstance.isPublic = this.editableFilterIsPublic

    this.customWorkflowsListFiltersStore.saveOne(this.customFilterInstance)
    this.closeSavedFilters()
  }

  @action.bound
  public onSaveFiltersClicked(): void {
    const codeFilterValuesByTypeMap: Map<string, string[]> = new Map()

    this.enabledFilterTypes.forEach(filterType => {
      codeFilterValuesByTypeMap.set(
        filterType,
        this.getSelectedFilterValues(filterType),
      )
    })
    this.createCustomFilterInstance(codeFilterValuesByTypeMap)
    this.showSaveFilterDialog()
  }

  @action.bound
  public applyCustomFilter(): void {
    const { filtersByFilterType: workflowsFiltersByFilterType } =
      this.selectedCustomFilter

    workflowsFiltersByFilterType.forEach(({ type, values = [] }) => {
      if (this.isFilterTypeRelevantForViewMode(type)) {
        this.applyFilterValuesForWorkflowsList(type, values)
      }
    })

    this.logisticsFilterStore.syncFilters()
    this.closeSavedFilters()
    this.eventsStore.appState.filters.appliedCustomFilterId =
      this.selectedCustomFilterId
  }

  public getSelectedFilterValues(
    filterType: string,
    filterValues?: string[],
    isDescription?: boolean,
  ): string[] {
    const { fieldsMap } = this.filters
    if (filterType === WorkflowsFilterType.Status) {
      return this.getFilterValuesFromWorkflowsList(filterType)
    }

    const values =
      filterValues ||
      Array.from(fieldsMap[filterType].selectedFilterOptions.keys())

    return !isDescription
      ? values
      : this.getFilterValueDescription(filterType, values)
  }

  public getFilterValueDescription(type: string, values: string[]): string[] {
    switch (type) {
      case WorkflowsFilterType.Status:
        return values
      case WorkflowsFilterType.Company:
        return values.map(
          value => this.companiesStore.getCompanyNameById(value) || value,
        )
      case WorkflowsFilterType.ResponsibleContact:
        return values.map(
          value => this.projectMembersStore.getById(value)?.fullName || value,
        )
      case WorkflowsFilterType.Type:
        return values.map(
          value =>
            this.permitTypesStore.getLastUpdatedTypeByType(value)?.name ||
            value,
        )
      case WorkflowsFilterType.Location:
        return values
          .filter(key => key !== UNASSIGNED)
          .map(key => {
            const [type, id] = key.split(LOCATION_SEPARATOR)
            const itemName = this.getItemNameByLocationTypeAndId(
              type as LocationType,
              id,
            )
            return itemName
          })
      default:
        return values || [NO_VALUE]
    }
  }

  protected createCustomFilterInstance(
    codeFilterValuesByTypeMap: Map<string, string[]>,
  ): void {
    const codesByType: IWorkflowsFilter[] = []
    codeFilterValuesByTypeMap.forEach((codeIds, typeId) => {
      codesByType.push({
        values: (codeIds || []).map(code => code || UNASSIGNED),
        type: typeId as WorkflowsFilterType,
      })
    })
    this.customFilterInstance =
      this.customWorkflowsListFiltersStore.createFromValues(
        codesByType,
        this.editableFilterIsPublic,
        [],
      )
  }

  private getFilterValuesFromWorkflowsList(
    filterType: WorkflowsFilterType,
  ): string[] {
    if (this.selectedCustomFilter) {
      return this.selectedCustomFilter.filtersByFilterType.find(
        value => value.type === filterType,
      ).values
    }
    return Array.from(
      this.eventsStore.appState.formsFilters.fieldsMap[
        filterType
      ].selectedFilterOptions.keys(),
    )
  }

  private applyFilterValuesForWorkflowsList(
    type: WorkflowsFilterType,
    values: string[] = [],
  ): void {
    const filterStore = this.logisticsFilterStore.filterStoresByTypeMap[type]
    if (!filterStore) {
      return
    }

    filterStore.clickOnSelectAll()
    Array.from(filterStore.filter.selectedFilterOptions.keys()).forEach(
      option => {
        if (values && !values.includes(option)) {
          filterStore.selectedOptions.delete(option)
        }
      },
    )
    filterStore.clickOnApply()
  }

  private workflowsInSelectedFilters = (
    workflowsFiltersByFilterType?: IWorkflowsFilter[],
  ): Permit[] => {
    const { formsInPeriodInterval } = this.logisticsStore
    const relevantFilters = workflowsFiltersByFilterType.filter(fieldType =>
      this.isFilterTypeRelevantForViewMode(fieldType.type),
    )

    return formsInPeriodInterval.filter(workflow => {
      return relevantFilters.every(filterType =>
        this.isWorkflowInFilterType(workflow, filterType),
      )
    })
  }

  private isWorkflowInFilterType = (
    workflow: Permit,
    { type, values }: IWorkflowsFilter,
  ): boolean => {
    switch (type) {
      case WorkflowsFilterType.Status:
        if (!values.includes(workflow.status)) {
          return false
        }
        break
      case WorkflowsFilterType.Company:
        if (
          (!workflow.companyIds.length && !values.includes(UNASSIGNED)) ||
          (workflow.companyIds.length &&
            !values.some(v => workflow.companyIds.includes(v)))
        ) {
          return false
        }
        break
      case WorkflowsFilterType.ResponsibleContact:
        if (!values.some(v => workflow.assigneeIds.includes(v))) {
          return false
        }
        break
      case WorkflowsFilterType.Type:
        const permitType = this.permitTypesStore.getPermitTypeById(
          workflow.typeId,
        )
        if (
          (!permitType && !values.includes(UNASSIGNED)) ||
          (permitType && !values.includes(permitType.type))
        ) {
          return false
        }
        break
      case WorkflowsFilterType.Location:
        if (
          (!workflow.locations.length && !values.includes(UNASSIGNED)) ||
          (workflow.locations.length &&
            !values.some(v =>
              workflow.locations.some(l => {
                const [type, id] = v.split(LOCATION_SEPARATOR)
                return l.type === type && l.id === id
              }),
            ))
        ) {
          return false
        }
        break
    }

    return true
  }

  private isFilterTypeRelevantForViewMode(type: WorkflowsFilterType): boolean {
    if (this.logisticsStore.isCalendarViewMode) {
      return mapWorkflowFilterTypes.includes(type)
    }

    return extendedWorkflowsFilterTypes.includes(type)
  }
}
