import { gql } from '@apollo/client'
import * as Apollo from '@apollo/client'

import * as Types from '../../types.generated'

const defaultOptions = {}
export type ICheckUserByPhoneNumberQueryVariables = Types.Exact<{
  phoneNumber: Types.Scalars['String']
}>

export type ICheckUserByPhoneNumberQuery = Pick<
  Types.IQuery,
  'checkPhoneNumber'
>

export type IGetPresentationUserTokenMutationVariables = Types.Exact<{
  projectId: Types.Scalars['ObjectId']
}>

export type IGetPresentationUserTokenMutation = Pick<
  Types.IMutation,
  'getPresentationUserToken'
>

export type IGetVisitorUserTokenMutationVariables = Types.Exact<{
  visitor: Types.IVisitorUserInput
}>

export type IGetVisitorUserTokenMutation = Pick<
  Types.IMutation,
  'getVisitorUserToken'
>

export type IResendInviteKeyMutationVariables = Types.Exact<{
  inviteKey: Types.Scalars['String']
  projectCode: Types.Scalars['String']
}>

export type IResendInviteKeyMutation = Pick<Types.IMutation, 'resendInviteKey'>

export const CheckUserByPhoneNumberDocument = gql`
  query CheckUserByPhoneNumber($phoneNumber: String!) {
    checkPhoneNumber(phoneNumber: $phoneNumber)
  }
`

/**
 * __useCheckUserByPhoneNumberQuery__
 *
 * To run a query within a React component, call `useCheckUserByPhoneNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckUserByPhoneNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckUserByPhoneNumberQuery({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useCheckUserByPhoneNumberQuery(
  baseOptions: Apollo.QueryHookOptions<
    ICheckUserByPhoneNumberQuery,
    ICheckUserByPhoneNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    ICheckUserByPhoneNumberQuery,
    ICheckUserByPhoneNumberQueryVariables
  >(CheckUserByPhoneNumberDocument, options)
}
export function useCheckUserByPhoneNumberLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ICheckUserByPhoneNumberQuery,
    ICheckUserByPhoneNumberQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    ICheckUserByPhoneNumberQuery,
    ICheckUserByPhoneNumberQueryVariables
  >(CheckUserByPhoneNumberDocument, options)
}
export type CheckUserByPhoneNumberQueryHookResult = ReturnType<
  typeof useCheckUserByPhoneNumberQuery
>
export type CheckUserByPhoneNumberLazyQueryHookResult = ReturnType<
  typeof useCheckUserByPhoneNumberLazyQuery
>
export type CheckUserByPhoneNumberQueryResult = Apollo.QueryResult<
  ICheckUserByPhoneNumberQuery,
  ICheckUserByPhoneNumberQueryVariables
>
export const GetPresentationUserTokenDocument = gql`
  mutation GetPresentationUserToken($projectId: ObjectId!) {
    getPresentationUserToken(projectId: $projectId)
  }
`
export type IGetPresentationUserTokenMutationFn = Apollo.MutationFunction<
  IGetPresentationUserTokenMutation,
  IGetPresentationUserTokenMutationVariables
>

/**
 * __useGetPresentationUserTokenMutation__
 *
 * To run a mutation, you first call `useGetPresentationUserTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetPresentationUserTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getPresentationUserTokenMutation, { data, loading, error }] = useGetPresentationUserTokenMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetPresentationUserTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IGetPresentationUserTokenMutation,
    IGetPresentationUserTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IGetPresentationUserTokenMutation,
    IGetPresentationUserTokenMutationVariables
  >(GetPresentationUserTokenDocument, options)
}
export type GetPresentationUserTokenMutationHookResult = ReturnType<
  typeof useGetPresentationUserTokenMutation
>
export type GetPresentationUserTokenMutationResult =
  Apollo.MutationResult<IGetPresentationUserTokenMutation>
export type GetPresentationUserTokenMutationOptions =
  Apollo.BaseMutationOptions<
    IGetPresentationUserTokenMutation,
    IGetPresentationUserTokenMutationVariables
  >
export const GetVisitorUserTokenDocument = gql`
  mutation GetVisitorUserToken($visitor: VisitorUserInput!) {
    getVisitorUserToken(visitor: $visitor)
  }
`
export type IGetVisitorUserTokenMutationFn = Apollo.MutationFunction<
  IGetVisitorUserTokenMutation,
  IGetVisitorUserTokenMutationVariables
>

/**
 * __useGetVisitorUserTokenMutation__
 *
 * To run a mutation, you first call `useGetVisitorUserTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetVisitorUserTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getVisitorUserTokenMutation, { data, loading, error }] = useGetVisitorUserTokenMutation({
 *   variables: {
 *      visitor: // value for 'visitor'
 *   },
 * });
 */
export function useGetVisitorUserTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IGetVisitorUserTokenMutation,
    IGetVisitorUserTokenMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IGetVisitorUserTokenMutation,
    IGetVisitorUserTokenMutationVariables
  >(GetVisitorUserTokenDocument, options)
}
export type GetVisitorUserTokenMutationHookResult = ReturnType<
  typeof useGetVisitorUserTokenMutation
>
export type GetVisitorUserTokenMutationResult =
  Apollo.MutationResult<IGetVisitorUserTokenMutation>
export type GetVisitorUserTokenMutationOptions = Apollo.BaseMutationOptions<
  IGetVisitorUserTokenMutation,
  IGetVisitorUserTokenMutationVariables
>
export const ResendInviteKeyDocument = gql`
  mutation ResendInviteKey($inviteKey: String!, $projectCode: String!) {
    resendInviteKey(inviteKey: $inviteKey, projectCode: $projectCode)
  }
`
export type IResendInviteKeyMutationFn = Apollo.MutationFunction<
  IResendInviteKeyMutation,
  IResendInviteKeyMutationVariables
>

/**
 * __useResendInviteKeyMutation__
 *
 * To run a mutation, you first call `useResendInviteKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInviteKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInviteKeyMutation, { data, loading, error }] = useResendInviteKeyMutation({
 *   variables: {
 *      inviteKey: // value for 'inviteKey'
 *      projectCode: // value for 'projectCode'
 *   },
 * });
 */
export function useResendInviteKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    IResendInviteKeyMutation,
    IResendInviteKeyMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    IResendInviteKeyMutation,
    IResendInviteKeyMutationVariables
  >(ResendInviteKeyDocument, options)
}
export type ResendInviteKeyMutationHookResult = ReturnType<
  typeof useResendInviteKeyMutation
>
export type ResendInviteKeyMutationResult =
  Apollo.MutationResult<IResendInviteKeyMutation>
export type ResendInviteKeyMutationOptions = Apollo.BaseMutationOptions<
  IResendInviteKeyMutation,
  IResendInviteKeyMutationVariables
>
