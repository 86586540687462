import * as React from 'react'

import { inject, observer } from 'mobx-react'

import { IPermitTypeField, ISiteLocation, LocationType } from '~/client/graph'
import HierarchyChains from '~/client/src/shared/components/HierarchyChains'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import StruxhubAttributeSelector from '~/client/src/shared/components/StruxhubInputs/StruxhubSelector/StruxhubAttributeSelector'
import IPermitFieldsStore from '~/client/src/shared/models/IPermitFieldsStore'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { copyObject } from '~/client/src/shared/utils/util'

import VerticalObjectLevelsSelector from '../../../VerticalObjectLevelsSelector/VerticalObjectLevelsSelector'
import SitePermitCreationFormStore from '../../SitePermitCreationForm.store'
import PermitBaseFormField from './PermitBaseFormField'
import PermitFieldRemoveIcon from './PermitFieldRemoveIcon'

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean

  store: SitePermitCreationFormStore
  fieldsStore: IPermitFieldsStore

  tableId?: string
  tableRowIndex?: number

  tagsStore?: TagsStore
}

@inject('tagsStore')
@observer
export default class PermitFormLocationField extends React.Component<IProps> {
  public render() {
    const { isViewMode, typeField, fieldsStore, tableId, tableRowIndex } =
      this.props
    const { caption, isMandatory, isMultiple } = typeField
    const { isFieldChanged } = fieldsStore

    return (
      <PermitBaseFormField
        typeField={typeField}
        isViewMode={isViewMode}
        viewModeElements={this.viewModeElements}
        onAddFieldClick={this.addNewFieldValue}
      >
        {this.fieldValues.map((location, index) => (
          <div key={`${location?.id}_${index}`} className="relative">
            <div className="row y-center">
              <StruxhubAttributeSelector
                className="overflow-hidden"
                label={caption}
                isRequired={isMandatory}
                isChanged={isFieldChanged(
                  typeField.id,
                  index,
                  tableId,
                  tableRowIndex,
                )}
                isLabelHidden={!!index}
                isHelperTextCondensed={
                  isMultiple || location?.type === LocationType.VerticalObject
                }
                onClick={this.openLocationPicker.bind(this, index)}
                value={location?.id || ''}
              />
              {isMultiple && !!index && (
                <PermitFieldRemoveIcon
                  index={index}
                  onClick={this.removeValue}
                />
              )}
            </div>
            {location?.type === LocationType.VerticalObject && (
              <VerticalObjectLevelsSelector
                verticalObjectId={location.id}
                selectedLevelIds={location.levels || []}
                onChange={this.onLocationLevelsChange.bind(this, index)}
              />
            )}
          </div>
        ))}
      </PermitBaseFormField>
    )
  }

  private get viewModeElements(): JSX.Element[] {
    const { tagsStore } = this.props

    return this.fieldValues
      .map((loc, idx) => {
        const location = tagsStore.getTag(loc?.type, loc?.id) as LocationBase

        if (!location) {
          return null
        }

        return (
          <div key={`${location.id}_${idx}`} className="overflow-hidden">
            <SitemapAttributeTag
              shouldShowAsTag={false}
              dataObject={location}
              className="row"
              contentContainerClassName="text large ellipsis"
            >
              {location.name}
            </SitemapAttributeTag>
            <HierarchyChains
              className="text light ml20"
              hierarchyChains={location.getHierarchyChains(
                tagsStore.tagStoreByTagTypeMap,
              )}
            />
          </div>
        )
      })
      .filter(l => l)
  }

  private get fieldValues(): ISiteLocation[] {
    const { typeField, fieldsStore, tableId, tableRowIndex } = this.props
    const values = fieldsStore.getFieldValues<ISiteLocation>(
      typeField.id,
      tableId,
      tableRowIndex,
    )
    return values?.length ? values : [{ id: null }]
  }

  private openLocationPicker(fieldIndex: number) {
    const { store, typeField, tableId, tableRowIndex } = this.props
    store.setSelectedField(typeField, fieldIndex, tableId, tableRowIndex)
  }

  private addNewFieldValue = () => {
    if (!this.isTableField) {
      const { fieldsStore, typeField } = this.props
      fieldsStore.addNewFieldValue(typeField, {})
    }
  }

  private removeValue = (index: number) => {
    if (!this.isTableField) {
      const { fieldsStore, typeField } = this.props
      fieldsStore.removeFieldValue(typeField.id, index)
    }
  }

  private onLocationLevelsChange(index: number, levels: string[]) {
    const location = copyObject<ISiteLocation>(this.fieldValues[index])
    location.levels = [...levels]

    const { fieldsStore, typeField, tableId, tableRowIndex } = this.props
    const { changeFieldValue, changeTableFieldValue } = fieldsStore

    if (this.isTableField) {
      changeTableFieldValue(tableId, tableRowIndex, typeField, index, location)
      return
    }

    changeFieldValue(typeField, index, location)
  }

  private get isTableField(): boolean {
    return !!this.props.tableId
  }
}
