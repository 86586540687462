import * as React from 'react'

import { observer } from 'mobx-react'

import { IPermitTypeField, PermitFieldType } from '~/client/graph'
import { multiplePermitFieldTypes } from '~/client/src/shared/constants/PermitFieldTypeConstants'
import { NO_VALUE } from '~/client/src/shared/utils/usefulStrings'

import PermitFieldIcon from '../../../PermitFieldIcon/PermitFieldIcon'

interface IProps {
  typeField: IPermitTypeField
  isViewMode: boolean
  viewModeElements: JSX.Element[]
  onAddFieldClick?(): void
}

const addField = (fieldCaption: string): string => `+ Add ${fieldCaption}`

@observer
export default class PermitBaseFormField extends React.Component<IProps> {
  public render() {
    const { isViewMode, typeField, children, onAddFieldClick } = this.props

    if (isViewMode) {
      return this.viewMode
    }

    return (
      <>
        {children}
        {this.isMultiple && (
          <span
            className="pointer text end large blue-highlight ellipsis px10 as-end mb15 mw300"
            title={typeField.caption}
            onClick={onAddFieldClick}
          >
            {addField(typeField.caption)}
          </span>
        )}
      </>
    )
  }

  private get viewMode(): JSX.Element {
    const { typeField, viewModeElements } = this.props

    return (
      <div className="row no-grow y-start py12">
        <PermitFieldIcon fieldName={typeField.type} />
        <div className="col pr6 overflow-hidden">
          <div
            className="text light large pb4 ellipsis"
            title={typeField.caption}
          >
            {typeField.caption}
          </div>
          <div>{viewModeElements?.length ? viewModeElements : NO_VALUE}</div>
        </div>
      </div>
    )
  }

  private get isMultiple(): boolean {
    const { type, isMultiple } = this.props.typeField

    return (
      multiplePermitFieldTypes
        .filter(f => f !== PermitFieldType.Select)
        .includes(type) && isMultiple
    )
  }
}
