import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import { IContactsVisibleToVisitors } from '~/client/graph'
import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import Checkbox from '~/client/src/shared/components/Checkbox'
import SitemapAttributeTag from '~/client/src/shared/components/SitemapAttributeTag/SitemapAttributeTag'
import UsersDirectory from '~/client/src/shared/components/UsersDirectory/UsersDirectory'
import { TagType } from '~/client/src/shared/enums/TagType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import { ITag } from '~/client/src/shared/models/Tag'
import ProjectMembersStore from '~/client/src/shared/stores/domain/ProjectMembers.store'
import TagsStore from '~/client/src/shared/stores/domain/Tags.store'
import { mapFiltered, reduceByCategories } from '~/client/src/shared/utils/util'

export interface IProps {
  contacts: IContactsVisibleToVisitors
  onApply: (newContacts: IContactsVisibleToVisitors) => void

  tagsStore?: TagsStore
  projectMembersStore?: ProjectMembersStore
}

const contactKeyToTagTypeMap = {
  users: TagType.User,
  teams: TagType.Team,
  defaultTeams: TagType.DefaultTeam,
}

@inject('tagsStore', 'projectMembersStore')
@observer
export default class VisitorContactsPicker extends React.Component<IProps> {
  @observable private selectedTags: Map<string, ITag> = new Map()
  @observable private isTeamBandExpanded: boolean = true

  private selectedUserIds: string[] = null

  public componentDidMount() {
    this.init(this.props.contacts)
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    if (this.props.contacts !== prevProps.contacts) {
      this.init(this.props.contacts)
    }
  }

  public render() {
    const users = mapFiltered(this.props.contacts?.users, id =>
      this.projectMembersStore.getById(id),
    )

    return (
      <div className="col full-height">
        <div className="row pa10 x-end">
          <BaseActionButton
            isEnabled={true}
            className="scale-blue-theme"
            title={Localization.translator.apply}
            onClick={this.handleApply}
          />
        </div>
        <UsersDirectory
          className="scrollable"
          canBandsBeCollapsed={true}
          shouldHideFilterBar={true}
          isSelectionMode={true}
          shouldSelectOnlyUsers={true}
          selectedUsers={users}
          onItemSelect={this.handleUsersSelect}
        >
          {this.renderTeamBand()}
        </UsersDirectory>
      </div>
    )
  }

  private renderTeamBand = (): JSX.Element => {
    const { tagsStore } = this.props
    const { tagListsWithDefaultsTagsMap } = tagsStore

    const allTeams = tagListsWithDefaultsTagsMap[TagType.Team]

    return (
      <div className="col">
        <div className="row band-row bg-grey-scale-light bb-palette-grey bt-palette-grey px12 py8">
          <Icon
            icon={
              this.isTeamBandExpanded
                ? IconNames.CARET_DOWN
                : IconNames.CARET_UP
            }
            onClick={this.toggleTeamBand}
            iconSize={UsersDirectory.tagIconSize}
            className="text white mr8 pointer"
          />
          <span className="text large ellipsis white">
            {Localization.translator.teams}
          </span>

          <span className="text white no-grow nowrap">
            ({Localization.translator.xTeams(allTeams.length)})
          </span>
        </div>
        {this.isTeamBandExpanded &&
          allTeams.map(tag => (
            <div
              key={tag.id}
              className="row pa12 bt-palette-brand-lighter pointer"
            >
              <Checkbox
                className="as-start"
                isChecked={!!this.selectedTags.get(tag.id)}
                onClick={this.handleTagToggle.bind(null, tag)}
              />
              <SitemapAttributeTag
                className="no-flex"
                contentContainerClassName="text large ellipsis"
                dataObject={tag as LocationAttributeBase}
                shouldShowAsTag={true}
                iconSize={UsersDirectory.tagIconSize}
              >
                <span>{tag.name}</span>
              </SitemapAttributeTag>
            </div>
          ))}
      </div>
    )
  }

  @action
  private init(contacts: IContactsVisibleToVisitors) {
    this.selectedUserIds = null

    const { getTagsByIds } = this.props.tagsStore

    const allTags = Object.keys(contacts || {}).reduce((acc, contactKey) => {
      const tags = getTagsByIds(
        contactKeyToTagTypeMap[contactKey],
        contacts[contactKey],
      )

      return acc.concat(...tags)
    }, [])

    allTags.forEach(t => this.selectedTags.set(t.id, t))
  }

  @action.bound
  public handleTagToggle(tag: ITag) {
    if (this.selectedTags.get(tag.id)) {
      this.selectedTags.delete(tag.id)
    } else {
      this.selectedTags.set(tag.id, tag)
    }
  }

  @action.bound
  private handleUsersSelect(userTags: ITag[]) {
    this.selectedUserIds = userTags.map(ut => ut.id)
  }

  @action.bound
  private toggleTeamBand() {
    this.isTeamBandExpanded = !this.isTeamBandExpanded
  }

  @action.bound
  private handleApply() {
    const tagIdsByType = reduceByCategories(
      [...this.selectedTags.values()],
      (item: ITag) => [item.type, item.id],
    )

    this.props.onApply({
      users: this.selectedUserIds || tagIdsByType[TagType.User],
      teams: tagIdsByType[TagType.Team],
      defaultTeams: tagIdsByType[TagType.DefaultTeam],
    })
  }

  private get projectMembersStore(): ProjectMembersStore {
    return this.props.projectMembersStore
  }
}
