import * as React from 'react'

import { action } from 'mobx'
import { observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import * as Icons from '~/client/src/shared/components/Icons'

import BaseActionBarStore from '../../stores/ui/BaseActionBar.store'

import './CameraUploadButton.scss'

interface IProps {
  clickWhenReady?: boolean
  showWhenSelected?: boolean
  store: BaseActionBarStore
  className?: string
  text?: string
  shouldUseBlueIcon?: boolean
  onChange?: () => void
}

@observer
export default class CameraUploadButton extends React.Component<IProps> {
  public fileInput: HTMLInputElement

  @action.bound
  public promptForImage() {
    const { resetPreviewImage, hideMenuPopup } = this.props.store

    resetPreviewImage()
    hideMenuPopup()

    this.fileInput.click()
  }

  public componentDidMount() {
    const { store } = this.props

    if (store.promptForImageRequested) {
      setTimeout(() => {
        this.promptForImage()
        store.promptForImageRequested = false
      }, 0)
    }
  }

  @action.bound
  public handleImageInputChange = () => {
    const { onChange, store } = this.props
    store.setPreviewImageFromInput(this.fileInput)
    onChange?.()
  }

  @action.bound
  public ref(name: string) {
    return ref => {
      if (ref) {
        this[name] = ref
      }
    }
  }

  public render() {
    const {
      store,
      showWhenSelected,
      className = 'camera-upload-button-container',
      text,
      shouldUseBlueIcon,
    } = this.props
    const { fileImage, shouldShowPreview } = store
    const hasImage = !!fileImage

    const imageBtn = {
      active: hasImage,
      'camera-upload-button row': true,
      hidden: !showWhenSelected && shouldShowPreview,
      blue: shouldUseBlueIcon,
    }

    return (
      <div className={className}>
        <input
          onChange={this.handleImageInputChange}
          onClick={this.removePreviousValue}
          className="hidden-input"
          ref={this.ref('fileInput')}
          type="file"
          accept="image/*"
        />
        <button
          type="button"
          className={classList(imageBtn)}
          onClick={this.promptForImage}
        >
          <Icons.CameraBadge className="no-grow mr10 row" />
          {text && <span className="text extra-large left">{text}</span>}
        </button>
      </div>
    )
  }

  private removePreviousValue = e => {
    e.target.value = null
  }
}
