import { action, observable } from 'mobx'

import Project from '~/client/src/shared/models/Project'
import { LOGOUT } from '~/client/src/shared/stores/EventStore/eventConstants'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import desktopRoutes from '../../constants/desktopRoutes'
import DesktopInitialState from '../../stores/DesktopInitialState'
import DesktopEventStore from '../../stores/EventStore/DesktopEvents.store'
import DesktopCommonStore from '../../stores/ui/DesktopCommon.store'
import PresentationModeStore from '../../stores/ui/PresentationMode.store'

// localization: translated

export default class NavBarStore {
  @observable public shouldShowSettingsMenu: boolean = false
  @observable public isPresentationStopped: boolean = false

  public constructor(
    private readonly common: DesktopCommonStore,
    private readonly eventsStore: DesktopEventStore,
    private readonly projectsStore: ProjectsStore,
    private readonly state: DesktopInitialState,
    private readonly presentationModeStore: PresentationModeStore,
    private readonly projectDateStore: ProjectDateStore,
  ) {}

  @action.bound
  public selectProject(project: Project) {
    this.common.displayActivityList()
    this.projectsStore.selectProject(project.id)
  }

  @action.bound
  public clickOnSettings() {
    if (!this.state.isPresentationMode) {
      this.shouldShowSettingsMenu = !this.shouldShowSettingsMenu
      return
    }
    this.showStopPresentationDialog()
  }

  @action.bound
  public clickOnProjectSetup() {
    this.displayView(desktopRoutes.PROJECT_SETUP)
    this.clickOnSettings()
  }

  @action.bound
  public clickOnUserSettings() {
    this.displayView(desktopRoutes.USER_PROFILE)
    this.clickOnSettings()
  }

  @action.bound
  public clickOnNotifications() {
    if (!this.state.isPresentationMode) {
      this.displayView(desktopRoutes.NOTIFICATIONS)
    }
  }

  @action.bound
  public logoutHandler() {
    this.hideStopPresentationDialog()
    this.presentationModeStore.cancelPresentation()
    this.state.hideChat()
    this.displayView(desktopRoutes.LOGIN)
    this.eventsStore.dispatch(LOGOUT)
  }

  @action.bound
  public showStopPresentationDialog() {
    this.isPresentationStopped = true
  }

  @action.bound
  public hideStopPresentationDialog() {
    this.isPresentationStopped = false
  }

  @action.bound
  public displayView(route: string) {
    this.common._displayView(route)
  }

  public get isProjectSelectorDisabled() {
    return (
      this.hasNoProjects ||
      this.state.isPresentationMode ||
      this.state.isVisitorMode
    )
  }

  public get isTrackerDisabled() {
    return this.state.isTrackerDisabled
  }

  public get isDeliveriesDisabled() {
    return this.state.isDeliveriesDisabled
  }

  public get isFormsDisabled() {
    return this.state.isFormsDisabled
  }

  public get isMaterialsDisabled() {
    return this.state.isMaterialsDisabled
  }

  public get isPhotosDisabled() {
    return this.state.isPhotosDisabled
  }

  public get isAnalyticsDisabled() {
    return this.state.isAnalyticsDisabled
  }

  public get isLogisticsDisabled() {
    return this.state.isLogisticsDisabled
  }

  public get isProjectSelectorDisplayed() {
    return this.common.isProjectSelectorDisplayed
  }

  public get projects() {
    return this.projectsStore.list
  }

  public get activeProject() {
    return this.projectsStore.activeProject
  }

  public get hasNoProjects() {
    return this.projects && this.projects.length === 0
  }

  public get shouldShowProjectClock() {
    return this.projectDateStore.isProjectTimezoneDifferent
  }

  public get user() {
    return this.state.user
  }

  public get location() {
    return this.common.history.location
  }

  public get unreadNotificationsCount() {
    return this.state.unreadNotificationsCount
  }
}
