import { action, observable } from 'mobx'

import { DefaultLandingPage } from '~/client/graph'
import BarState from '~/client/src/desktop/enums/SideBarState'
import DesktopRootStore from '~/client/src/desktop/stores/DesktopRoot.store'
import {
  getURLParam,
  isDeliveryInfoPageRequested,
  isProjectBasedPath,
  projectBased,
} from '~/client/src/shared/constants/commonRoutes'
import Activity from '~/client/src/shared/models/Activity'
import CategoryOfVariance from '~/client/src/shared/models/CategoryOfVariance'
import Flag from '~/client/src/shared/models/Flag'
import Photo from '~/client/src/shared/models/Photo'
import Rfi from '~/client/src/shared/models/Rfi'
import SafetyHazard from '~/client/src/shared/models/SafetyHazard'
import ScheduleComment from '~/client/src/shared/models/ScheduleComment'
import StatusUpdate from '~/client/src/shared/models/StatusUpdate'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'

import desktopRoutes from '../../constants/desktopRoutes'
import DesktopInitialState from '../DesktopInitialState'

// localization: no display text to translate

export default class DesktopCommonStore extends CommonStore {
  @observable public isProjectSelectorDisplayed = true

  public constructor(
    protected state: DesktopInitialState,
    protected root: DesktopRootStore,
  ) {
    super(state, root)
  }

  public displayAuthSuccessRoute = (isVisitorAuth?: boolean) => {
    const { shouldOmitFormTypes, isVisitorMode } = this.state
    const isVisitor = isVisitorAuth || isVisitorMode

    switch (true) {
      case isVisitor && shouldOmitFormTypes:
        this.displayHomeView()
        break
      case isVisitor:
        this.displayFormTypesView()
        break
      default:
        this.displayDefaultView()
    }
  }

  public get viewToDisplay(): string {
    const {
      isDeliveriesDisabled,
      isPhotosDisabled,
      isLogisticsDisabled,
      isFormsDisabled,
      isMaterialsDisabled,
      isTrackerDisabled,
      isAnalyticsDisabled,
    } = this.state

    if (this.defaultAvailablePage) {
      return this.defaultAvailablePage
    }

    switch (true) {
      case !isLogisticsDisabled:
        return desktopRoutes.HOME
      case !isDeliveriesDisabled:
        return desktopRoutes.DELIVERIES
      case !isFormsDisabled:
        return desktopRoutes.FORMS
      case !isTrackerDisabled:
        return desktopRoutes.ACTIVITIES
      case !isMaterialsDisabled:
        return desktopRoutes.MATERIALS
      case !isPhotosDisabled:
        return desktopRoutes.PHOTOS
      case !isAnalyticsDisabled:
        return desktopRoutes.ANALYTICS
    }
  }

  public displayActivitiesView() {
    this._displayView(desktopRoutes.ACTIVITIES)
  }

  public displayNotifications() {
    this._displayView(desktopRoutes.NOTIFICATIONS)
  }

  public displayDeliveriesView() {
    this._displayView(desktopRoutes.DELIVERIES)
  }

  public openDeliveryView() {
    this.state.sideBarState = BarState.DeliveryCalendar
  }

  // TODO: [_displayDeliveryDetailsView] vs.[displayDeliveryDetailsView]
  public _displayDeliveryDetailsView = (deliveryId: string) => {
    this._displayView(
      desktopRoutes.DELIVERY_DETAILS.replace(
        getURLParam(desktopRoutes.DELIVERY_DETAILS),
        deliveryId,
      ),
    )
  }

  public displayDeliveryDetailsView() {
    this.state.sideBarState = BarState.DeliveryDetails
  }

  @action.bound
  public activityDetailsBackClicked() {
    if (this.state.sideBarState === BarState.Default) {
      this.root.activitiesStore.select('')
      return this.displayActivityList()
    }

    this.toActivityDetails()
  }

  @action.bound
  public displayActivityList() {
    this.state.displayedContentObjectId = null
    this.state.sideBarState = BarState.Default
    this.state.activityList.isActivityDetailsPanelDisplayed = false
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public displayActivityDetailsView(activityId: string) {
    this.state.displayedContentObjectId = null
    this.state.sideBarState = BarState.Default
  }

  public displayFlagView(flag: Flag) {
    this.state.displayedContentObjectId = flag.id
    this.state.sideBarState = BarState.Flag
  }

  public displayRfiView(rfi: Rfi) {
    this.state.displayedContentObjectId = rfi.id
    this.state.sideBarState = BarState.Rfi
  }

  public displayCategoryOfVarianceView(categoryOfVariance: CategoryOfVariance) {
    this.state.displayedContentObjectId = categoryOfVariance.id
    this.state.sideBarState = BarState.CategoryOfVariance
  }

  public displaySafetyHazardView(safetyHazard: SafetyHazard) {
    this.state.displayedContentObjectId = safetyHazard.id
    this.state.sideBarState = BarState.SafetyHazard
  }

  public displayScheduleCommentView(scheduleComment: ScheduleComment) {
    this.state.displayedContentObjectId = scheduleComment.id
    this.state.sideBarState = BarState.ScheduleComment
  }

  public displayPhotoView(photo: Photo) {
    this.state.displayedContentObjectId = photo.id
    this.state.sideBarState = BarState.Photo
  }

  public displayStatusUpdateMessagesView(statusUpdate: StatusUpdate) {
    this.state.displayedContentObjectId = statusUpdate.id
    this.state.sideBarState = BarState.StatusUpdateMessages
  }

  public displayBulkStatusUpdateView(
    activity: Activity,
    selectedCompany: string,
  ) {
    this.root.activitiesStore.select(activity.code)
    this.state.activityList.selectedCompany = selectedCompany
    this.state.sideBarState = BarState.BulkStatusUpdate
  }

  public displaySameViewWithUpdatedProjectId() {
    if (!isProjectBasedPath()) {
      return
    }

    const path = '/' + location.pathname.split('/').slice(3).join('/')
    const route = projectBased(path)

    this._displayView(route + location.search)
  }

  @action.bound
  public openChat() {
    this.state.openChat()
  }

  public get defaultAvailablePage(): string {
    const {
      isDeliveriesDisabled,
      isPhotosDisabled,
      isLogisticsDisabled,
      isFormsDisabled,
      isMaterialsDisabled,
      isTrackerDisabled,
      isAnalyticsDisabled,
      activeProject: { defaultLandingPage = DefaultLandingPage.Home },
    } = this.state

    if (isDeliveryInfoPageRequested()) {
      return location.pathname
    }

    switch (true) {
      case defaultLandingPage === DefaultLandingPage.Home &&
        !isLogisticsDisabled:
        return desktopRoutes.HOME
      case defaultLandingPage === DefaultLandingPage.Deliveries &&
        !isDeliveriesDisabled:
        return desktopRoutes.DELIVERIES
      case defaultLandingPage === DefaultLandingPage.Forms && !isFormsDisabled:
        return desktopRoutes.FORMS
      case defaultLandingPage === DefaultLandingPage.Materials &&
        !isMaterialsDisabled:
        return desktopRoutes.MATERIALS
      case defaultLandingPage === DefaultLandingPage.Photos &&
        !isPhotosDisabled:
        return desktopRoutes.PHOTOS
      case defaultLandingPage === DefaultLandingPage.Analytics &&
        !isAnalyticsDisabled:
        return desktopRoutes.ANALYTICS
      case defaultLandingPage === DefaultLandingPage.Tracker &&
        !isTrackerDisabled:
        return desktopRoutes.ACTIVITIES
      default:
        return desktopRoutes.USER_PROFILE
    }
  }
}
