import * as React from 'react'

import { Icon, Radio } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'

import * as Icons from '~/client/src/shared/components/Icons'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import './PermissionsGrid.scss'

interface IProps {
  value: { [key in Workflow]: Permission }
  onChange: (workflow: Workflow, permission: Permission) => void
}

enum Tool {
  Deliveries = 'deliveries',
  Forms = 'forms',
  P6Viewer = 'p6Viewer',
  ScannerApp = 'Scanner App',
}

function getToolTranslate(value: Tool) {
  switch (value) {
    case Tool.Deliveries:
      return Localization.translator.deliveries
    case Tool.Forms:
      return Localization.translator.forms
    case Tool.P6Viewer:
      return Localization.translator.p6Viewer
    case Tool.ScannerApp:
      return Localization.translator.scannerApp
    default:
      throw new Error(value + ' is unhandled')
  }
}

export enum Workflow {
  DeliveryBooking = 'deliveryBooking',
  DeliveryInspection = 'deliveryInspection',
  Forms = 'forms',
  ScannerApp = 'scannerApp',
  ActivityViewing = 'activityViewing',
  ActivityUpdating = 'activityUpdating',
  ActivityUploading = 'activityUploading',
  ActivityNotes = 'activityNotes',
}

function getWorkflowTranslate(value: Workflow) {
  switch (value) {
    case Workflow.DeliveryBooking:
      return Localization.translator.projectWorkflows.deliveryBooking
    case Workflow.DeliveryInspection:
      return Localization.translator.projectWorkflows.deliveryInspection
    case Workflow.Forms:
      return Localization.translator.projectWorkflows.formsMaster
    case Workflow.ScannerApp:
      return Localization.translator.projectWorkflows.scanMaster
    case Workflow.ActivityViewing:
      return Localization.translator.projectWorkflows.activityViewing
    case Workflow.ActivityUpdating:
      return Localization.translator.projectWorkflows.activityUpdating
    case Workflow.ActivityUploading:
      return Localization.translator.projectWorkflows.activityUploading
    case Workflow.ActivityNotes:
      return Localization.translator.projectWorkflows.activityNotes
    default:
      throw new Error(value + ' is unhandled')
  }
}

export enum Permission {
  None = 'none',
  ReadOnly = 'readOnly',
  Standard = 'standard',
  Master = 'master',
}

function getPermissionTranslate(value: Permission) {
  switch (value) {
    case Permission.Master:
      return Localization.translator.permissions.master
    case Permission.None:
      return Localization.translator.permissions.none
    case Permission.ReadOnly:
      return Localization.translator.permissions.readOnly
    case Permission.Standard:
      return Localization.translator.permissions.standard
    default:
      throw new Error(value + ' is unhandled')
  }
}

interface IPermissionConfiguration {
  tool: Tool
  icon: JSX.Element
  workflows: Workflow[]
}

const configuration: IPermissionConfiguration[] = [
  {
    tool: Tool.Deliveries,
    icon: <Icons.Truck className="row pt5" />,
    workflows: [Workflow.DeliveryBooking, Workflow.DeliveryInspection],
  },
  {
    tool: Tool.P6Viewer,
    icon: <Icons.NotificationSchedule className="row pt5" />,
    workflows: [
      Workflow.ActivityViewing,
      Workflow.ActivityUpdating,
      Workflow.ActivityUploading,
      Workflow.ActivityNotes,
    ],
  },
  {
    tool: Tool.Forms,
    icon: <Icons.GeneralForm className="row pt5" />,
    workflows: [Workflow.Forms],
  },
  {
    tool: Tool.ScannerApp,
    icon: <Icon icon={IconNames.BARCODE} className="row pt5" />,
    workflows: [Workflow.ScannerApp],
  },
]

export default class PermissionsGrid extends React.Component<IProps> {
  public static defaultProps = {
    value: {},
  }

  public render() {
    const { value, onChange } = this.props

    return (
      <div className="col permissions-grid mx24">
        <div className="row text bold uppercase lp05">
          <div className="tool-cell">{Localization.translator.tool}</div>
          <div className="workflow-cell">
            {Localization.translator.workflow}
          </div>

          {Object.values(Permission).map(perm => (
            <div key={perm} className="text center">
              {getPermissionTranslate(perm)}
            </div>
          ))}
        </div>

        <div className="my12 ba-none bb-brand-dark as-stretch" />

        {configuration.map(({ tool, workflows, icon }) => {
          return (
            <React.Fragment key={tool}>
              <div className="row">
                <div className="row no-grow tool-cell">
                  <div className="tool-icon-wrapper br-rounded mr8">{icon}</div>
                  <div className="no-grow">{getToolTranslate(tool)}</div>
                </div>

                <div className="col">
                  {workflows.map((workflow, i) => {
                    const isLast = i === workflows.length - 1
                    return (
                      <React.Fragment key={workflow}>
                        <div className="row">
                          <div className="workflow-cell">
                            {getWorkflowTranslate(workflow)}
                          </div>
                          <div>
                            <Radio disabled={true} />
                          </div>
                          <div>
                            <Radio disabled={true} />
                          </div>
                          <div>
                            <Radio
                              onChange={onChange.bind(
                                null,
                                workflow,
                                Permission.Standard,
                              )}
                              checked={
                                !value[workflow] ||
                                value[workflow] === Permission.Standard
                              }
                            />
                          </div>
                          <div>
                            <Radio
                              onChange={onChange.bind(
                                null,
                                workflow,
                                Permission.Master,
                              )}
                              checked={value[workflow] === Permission.Master}
                            />
                          </div>
                        </div>
                        {!isLast && (
                          <div className="my12 ba-none bb-light-grey as-stretch" />
                        )}
                      </React.Fragment>
                    )
                  })}
                </div>
              </div>
              <div className="my12 ba-none bb-light-grey as-stretch" />
            </React.Fragment>
          )
        })}
      </div>
    )
  }
}
