import * as React from 'react'

import { observer } from 'mobx-react'

import { ISiteLocation, PermitFieldType } from '~/client/graph'
import LocationPicker from '~/client/src/shared/components/LocationPicker/LocationPicker'
import LocationBase from '~/client/src/shared/models/LocationObjects/LocationBase'
import { NOOP } from '~/client/src/shared/utils/noop'

import SitePermitCreationFormStore from '../../../../../SitePermitCreationForm.store'

// localization: no text to translate

interface IProps {
  store: SitePermitCreationFormStore
}

@observer
export default class FormLocationPicker extends React.Component<IProps> {
  public render() {
    const {
      resetSelectedField,
      selectedField,
      selectedFieldIndex,
      selectedFieldValues,
      selectedSubField,
    } = this.props.store

    const isMaterialSubField = selectedField.type === PermitFieldType.Material

    const selectedLocationId = isMaterialSubField
      ? selectedFieldValues[selectedFieldIndex]?.locationId
      : selectedFieldValues[selectedFieldIndex]?.id

    const restrictedLocationsIds =
      (selectedSubField || selectedField).restrictedOptions?.map(
        ({ id }) => id,
      ) || []
    const selectedIds = selectedLocationId ? [selectedLocationId] : []

    return (
      <LocationPicker
        className="full-height overflow-hidden relative"
        isTitleHidden
        isSingleSelectionMode
        shouldScrollToSelected
        selectedIds={selectedIds}
        restrictedObjectIds={restrictedLocationsIds}
        onSingleSelect={this.onItemSelect}
        onChange={NOOP}
        onApplyChanges={resetSelectedField}
      />
    )
  }

  private onItemSelect = (locationAttr: LocationBase) => {
    if (locationAttr) {
      const newValue: ISiteLocation = {
        id: locationAttr.id,
        type: locationAttr.type,
        levels: null,
      }
      this.props.store.changeSelectedFieldValue(newValue)
    }
  }
}
