import * as React from 'react'

import { action, computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DeliveryWorkflowCard from '~/client/src/shared/components/ExpandableWorkflowCard/components/DeliveryWorkflowCard'
import PermitCard from '~/client/src/shared/components/SitemapCards/PermitCard'
import CalendarEvent, {
  CalendarEventEntityType,
} from '~/client/src/shared/models/CalendarEvent'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'

import DesktopCalendarColumn from '../../DesktopCalendarColumn'
import DeliveryMultiDayEventLabel from './DeliveryMultiDayEventLabel'
import FormMultiDayEventLabel from './FormMultiDayEventLabel'

import './MultiDayEventsBar.scss'

interface IProps {
  events: CalendarEvent[]
  calendarColumns: DesktopCalendarColumn[]
  onEventClicked: (event: CalendarEvent) => void
  setRef: (ref: HTMLDivElement) => void

  projectDateStore?: ProjectDateStore
}

@inject('projectDateStore')
@observer
export default class MultiDayEventsBar extends React.Component<IProps> {
  @observable private hoveredEventId: string = ''

  private clientX: number

  public render() {
    const {
      setRef,
      events,
      calendarColumns,
      onEventClicked,
      projectDateStore: { isSameDay, isWithinDateInterval },
    } = this.props

    if (!events?.length) return null

    return (
      <div className="md-events-bar row nowrap" ref={setRef}>
        {calendarColumns.map((column, i) => {
          const isStartOfInterval = i === 0
          const isEndOfInterval = i === calendarColumns.length - 1

          return (
            <div
              key={column.date.getTime()}
              className="md-events-bar-item bl-light-grey bb-light-grey x-axis-column-width"
            >
              {events.map(event => {
                const isInDateInterval = isWithinDateInterval(
                  event.startDate,
                  event.endDate,
                  column.date,
                )

                if (!isInDateInterval) {
                  return (
                    <div
                      key={event.dataId}
                      className="md-events-bar-item-event bg-white relative z-index-1"
                    />
                  )
                }

                const isEventStartDate = isSameDay(event.startDate, column.date)
                const isEventEndDate = isSameDay(event.endDate, column.date)

                const shouldAddPixelToWidth =
                  !isEventEndDate && !isEndOfInterval
                const shouldLabelBeRendered =
                  isEventStartDate || isStartOfInterval
                const isEventBeingHovered = this.hoveredEventId === event.dataId

                const eventLength =
                  this.eventLengthByDataIdMap[event.dataId] || 1

                return (
                  <React.Fragment key={event.dataId}>
                    {isEventBeingHovered &&
                      shouldLabelBeRendered &&
                      !event.isNew && (
                        <div
                          style={{ left: `${this.clientX}px` }}
                          className="absolute z-index-10"
                        >
                          <div className="relative unclickable-element card-holder">
                            {this.renderEntityCard(event)}
                          </div>
                        </div>
                      )}

                    <div
                      className={classList({
                        'bg-white relative': true,
                        'opaque-underlay': shouldAddPixelToWidth,
                        'z-index-1': shouldLabelBeRendered,
                      })}
                      onMouseEnter={this.setHoveredEvent.bind(
                        null,
                        event.dataId,
                      )}
                      onMouseLeave={this.resetHoveredEvent}
                    >
                      <div
                        style={event.styles}
                        className={classList({
                          'md-events-bar-item-event row nowrap text white relative pointer':
                            true,
                          'brls4 ml5': isEventStartDate,
                          'brrs4 mr5': isEventEndDate,
                          'mr5 view-range end':
                            isEndOfInterval && !isEventEndDate,
                          'ml5 view-range start':
                            isStartOfInterval && !isEventStartDate,

                          'br-none': !isEventEndDate && !isEndOfInterval,
                          'bl-none': !isEventStartDate && !isStartOfInterval,
                          active:
                            event.isActive ||
                            (isEventBeingHovered && !event.isNew),
                        })}
                        onClick={onEventClicked.bind(null, event)}
                      >
                        {shouldLabelBeRendered && (
                          <div
                            style={{ width: `${100 * eventLength}%` }}
                            className="label-container row pl5 absolute overflow-hidden"
                          >
                            {this.renderLabel(event)}
                          </div>
                        )}
                      </div>
                    </div>
                  </React.Fragment>
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }

  private renderLabel(event: CalendarEvent) {
    switch (event.entityType) {
      case CalendarEventEntityType.Form:
        return <FormMultiDayEventLabel event={event} />
      case CalendarEventEntityType.Delivery:
        return <DeliveryMultiDayEventLabel event={event} />

      default:
        return null
    }
  }

  @computed
  private get eventLengthByDataIdMap(): { [key: string]: number } {
    const { events, projectDateStore, calendarColumns } = this.props

    const viewIntervalStartTimestamp = calendarColumns.at(0).date.getTime()
    const viewIntervalEndTimestamp = calendarColumns.at(-1).date.getTime()

    return events.reduce((acc, e) => {
      acc[e.dataId] = projectDateStore.countDaysToDate(
        Math.max(e.startDate.getTime(), viewIntervalStartTimestamp),
        Math.min(e.endDate.getTime(), viewIntervalEndTimestamp),
        true,
      )

      return acc
    }, {} as any)
  }

  @action.bound
  private setHoveredEvent(eventDataId: string, e: React.MouseEvent) {
    this.clientX = e.clientX
    this.hoveredEventId = eventDataId
  }

  @action.bound
  private resetHoveredEvent() {
    this.hoveredEventId = ''
  }

  private renderEntityCard(event: CalendarEvent) {
    switch (event.entityType) {
      case CalendarEventEntityType.Delivery:
        return (
          <DeliveryWorkflowCard
            className="calendar-day-view-event new highlighted-event unclickable-element"
            delivery={event.data}
            eventStyles={event.styles}
            isCalendarTooltip
          />
        )

      case CalendarEventEntityType.Form:
        return (
          <PermitCard
            className="brada8 inline-block vertical-align-middle no-select bg-white unclickable-element absolute beautiful-shadow"
            permit={event.data}
            key={event.data?.id}
          />
        )
    }
  }
}
