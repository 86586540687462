import { SitePermitStatus, WorkflowStepType } from '~/client/graph'

export enum WorkflowStepAction {
  REQUEST = 'Request',
  SUBMIT = 'Submit',
  CHANGE = 'Change',
  DENY = 'Deny',
  APPROVE = 'Approve',
  START = 'Start',
  REVIEW = 'Review',
  READY_TO_INSPECT = 'Ready to inspect',
  FAIL = 'Fail',
  PASS = 'Pass',
  BIC_PASS = 'BIC Pass',
  ADD_INSPECTION = 'Add inspection',
  FINISH = 'Finish',
}

export const workflowActionsByStepsMap: {
  [K in WorkflowStepType]: WorkflowStepAction[]
} = {
  [WorkflowStepType.Request]: [WorkflowStepAction.REQUEST],
  [WorkflowStepType.Submission]: [WorkflowStepAction.SUBMIT],
  [WorkflowStepType.Review]: [
    WorkflowStepAction.CHANGE,
    WorkflowStepAction.REVIEW,
  ],
  [WorkflowStepType.Approval]: [
    WorkflowStepAction.CHANGE,
    WorkflowStepAction.DENY,
    WorkflowStepAction.APPROVE,
  ],
  [WorkflowStepType.Start]: [
    WorkflowStepAction.CHANGE,
    WorkflowStepAction.START,
  ],
  [WorkflowStepType.ReadyToInspect]: [
    WorkflowStepAction.CHANGE,
    WorkflowStepAction.READY_TO_INSPECT,
  ],
  [WorkflowStepType.Inspection]: [
    WorkflowStepAction.CHANGE,
    WorkflowStepAction.FAIL,
    WorkflowStepAction.PASS,
  ],
  [WorkflowStepType.RecurringInspection]: [
    WorkflowStepAction.CHANGE,
    WorkflowStepAction.ADD_INSPECTION,
  ],
  [WorkflowStepType.BicInspection]: [
    WorkflowStepAction.CHANGE,
    WorkflowStepAction.BIC_PASS,
  ],
  [WorkflowStepType.Finish]: [
    WorkflowStepAction.CHANGE,
    WorkflowStepAction.FINISH,
  ],
}

export const formStatusByWorkflowActionMap: {
  [K in WorkflowStepAction]: SitePermitStatus
} = {
  [WorkflowStepAction.REQUEST]: SitePermitStatus.Requested,
  [WorkflowStepAction.SUBMIT]: SitePermitStatus.Submitted,
  [WorkflowStepAction.CHANGE]: SitePermitStatus.Changed,
  [WorkflowStepAction.DENY]: SitePermitStatus.Denied,
  [WorkflowStepAction.APPROVE]: SitePermitStatus.Accepted,
  [WorkflowStepAction.START]: SitePermitStatus.Active,
  [WorkflowStepAction.REVIEW]: SitePermitStatus.Reviewed,
  [WorkflowStepAction.READY_TO_INSPECT]: SitePermitStatus.ToInspect,
  [WorkflowStepAction.FAIL]: SitePermitStatus.Failed,
  [WorkflowStepAction.PASS]: SitePermitStatus.Passed,
  [WorkflowStepAction.BIC_PASS]: SitePermitStatus.BicInspected,
  [WorkflowStepAction.ADD_INSPECTION]: null,
  [WorkflowStepAction.FINISH]: SitePermitStatus.Done,
}
