import { action, computed, observable } from 'mobx'

import {
  IInspectionOptions,
  IWorkflowStep,
  WorkflowStepType,
} from '~/client/graph'
import { ITabObject } from '~/client/src/shared/components/Tabs/Tabs'
import { getWorkflowStepLevel } from '~/client/src/shared/constants/formStatusesTags'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { getWorkflowStepDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'
import PermitType from '~/client/src/shared/models/PermitType'
import { showErrorToast } from '~/client/src/shared/utils/toaster'

import FormTypeStateHistory from './FormTypeStateHistory'

const forbiddenLastSteps = [
  WorkflowStepType.RecurringInspection,
  WorkflowStepType.Start,
]

enum FormTabSection {
  GENERAL = 'General',
  STEPS = 'Steps',
  FORMS = 'Forms',
  VIEWS = 'Views',
  CONFIRMATION = 'Confirmation',
}

export default class FormTypeConfiguratorStore {
  @observable public formType: PermitType
  @observable public selectedWorkflowStepId: string
  @observable public activeFormTab: string = FormTabSection.GENERAL

  private formStateHistory: FormTypeStateHistory

  public constructor(
    formType: PermitType,
    private readonly saveFormType: (updatedFormType: PermitType) => void,
  ) {
    this.init(formType)
  }

  @action.bound
  public init(formType: PermitType) {
    this.formType = formType.getDeepCopy()

    this.resetWorkflowStepIfNeed()

    this.formStateHistory = new FormTypeStateHistory(formType, this.setFormType)
  }

  @action.bound
  public setSelectedWorkflowStepId(selectedWorkflowStepId: string) {
    this.selectedWorkflowStepId = selectedWorkflowStepId
  }

  @action.bound
  public changeGeneralProps(
    name: string,
    isShownInApp: boolean,
    shouldBlockOnNonWorkTimes: boolean,
  ) {
    this.formType.name = name
    this.formType.isEnabled = isShownInApp
    this.formType.shouldBlockOnNonWorkTimes = shouldBlockOnNonWorkTimes

    this.formStateHistory.captureState(this.formType)
  }

  @action.bound
  public changeWorkflowSettings(
    workflowSteps: IWorkflowStep[],
    inspectionOptions: IInspectionOptions,
    isAutoActivationEnabled: boolean,
    isImmediateSave?: boolean,
  ) {
    this.formType.workflowSteps = workflowSteps
    this.formType.inspectionOptions = inspectionOptions
    this.formType.isAutoActivationEnabled = isAutoActivationEnabled

    if (isImmediateSave && !this.hasUndo) {
      return this.saveChanges()
    }

    this.formStateHistory.captureState(this.formType)
  }

  @action.bound
  public changePermitTypeWorkflowStep(workflowStep: IWorkflowStep) {
    const index = this.formType.workflowSteps.findIndex(
      s => s.id === workflowStep.id,
    )

    if (index === -1) {
      return
    }

    this.formType.workflowSteps[index] = workflowStep

    this.formStateHistory.captureState(this.formType)
  }

  @action.bound
  public changeActiveFormTab(newTab: FormTabSection) {
    if (this.activeFormTab === newTab) return

    this.showToastErrorIfNeed()
    this.formStateHistory.resetToInitial()

    this.activeFormTab = newTab
  }

  @action.bound
  public saveChanges() {
    if (!this.isAllowedToSave) return

    this.formType.name = this.formType.name.trim()
    this.saveFormType(this.formType)
  }

  public selectStepAndGoToForms = (stepId: string) => {
    this.setSelectedWorkflowStepId(stepId)
    this.changeActiveFormTab(FormTabSection.FORMS)
  }

  public showToastErrorIfNeed = () => {
    if (this.hasUndo) {
      showErrorToast(Localization.translator.workflowConfDescr.toastUnsavedMsg)
    }
  }

  public undoChangesToInitial = () => {
    this.formStateHistory.undoToInitial()
  }

  public undoChanges = () => {
    this.formStateHistory.undo()
  }

  public redoChanges = () => {
    this.formStateHistory.redo()
  }

  public get formConfiguratorTabs(): Array<ITabObject<FormTabSection>> {
    return [
      {
        title: Localization.translator.general,
        page: FormTabSection.GENERAL,
      },
      {
        title: Localization.translator.steps,
        page: FormTabSection.STEPS,
      },
      {
        title: Localization.translator.forms,
        page: FormTabSection.FORMS,
      },
      {
        title: Localization.translator.views,
        page: FormTabSection.VIEWS,
        isDisabled: true,
      },
      {
        title: Localization.translator.confirmation,
        page: FormTabSection.CONFIRMATION,
        isDisabled: true,
      },
    ]
  }

  public get activeTabTitle(): string {
    return this.formConfiguratorTabs.find(t => t.page === this.activeFormTab)
      ?.title
  }

  public get workflowSteps(): IWorkflowStep[] {
    return this.formType.workflowSteps
  }

  public get shouldBlockOnNonWorkTimes(): boolean {
    return this.formType.shouldBlockOnNonWorkTimes
  }

  public get isGeneralTabSelected(): boolean {
    return this.activeFormTab === FormTabSection.GENERAL
  }

  public get isStepsTabSelected(): boolean {
    return this.activeFormTab === FormTabSection.STEPS
  }

  public get isFormsTabSelected(): boolean {
    return this.activeFormTab === FormTabSection.FORMS
  }

  @computed
  public get selectedWorkflowStep(): IWorkflowStep {
    return this.formType.workflowSteps.find(
      s => s.id === this.selectedWorkflowStepId,
    )
  }

  @computed
  public get isInitialStepSelected(): boolean {
    return this.selectedWorkflowStep.id === this.formType.initialStep.id
  }

  @computed
  public get hasRequestStep(): boolean {
    return this.workflowSteps.some(s => s.type === WorkflowStepType.Request)
  }

  @computed
  public get hasApprovalStep(): boolean {
    return this.workflowSteps.some(s => s.type === WorkflowStepType.Approval)
  }

  @computed
  public get warningMessage(): string {
    const { mustContainApproval, cannotBeTheLastStep } =
      Localization.translator.workflowConfDescr

    if (this.hasRequestStep && !this.hasApprovalStep) {
      return mustContainApproval
    }
    if (this.isLastStepForbidden) {
      const stepLevel = getWorkflowStepLevel(
        this.lastWorkflowStep?.id,
        this.workflowSteps,
      )
      const stepName = getWorkflowStepDisplayName(
        this.lastWorkflowStep?.type,
        stepLevel,
      )
      return cannotBeTheLastStep(stepName)
    }
  }

  @computed
  public get isAllowedToSave(): boolean {
    return (
      !!this.formType.name?.trim() &&
      !this.isLastStepForbidden &&
      (!this.hasRequestStep || this.hasApprovalStep)
    )
  }

  public get hasUndo(): boolean {
    return this.formStateHistory.hasUndo
  }

  public get hasRedo(): boolean {
    return this.formStateHistory.hasRedo
  }

  private get isLastStepForbidden(): boolean {
    return forbiddenLastSteps.includes(this.lastWorkflowStep?.type)
  }

  private get lastWorkflowStep(): IWorkflowStep {
    return this.formType.lastStep
  }

  @action.bound
  private setFormType(formType: PermitType) {
    this.formType = formType
  }

  private resetWorkflowStepIfNeed = () => {
    if (!this.selectedWorkflowStep?.id) {
      this.setSelectedWorkflowStepId(this.formType.initialStep.id)
    }
  }
}
