import * as React from 'react'

import { KonvaEventObject } from 'konva/types/Node'
import { action, computed, observable } from 'mobx'
import { MobXProviderContext, Provider, inject, observer } from 'mobx-react'

import BaseMapView from '~/client/src/shared/components/BaseMapView/BaseMapView'
import MapLayersMenu from '~/client/src/shared/components/MapLayersSelector/components/MapLayersMenu'
import SitemapItems, {
  MAX_PERCENT,
} from '~/client/src/shared/components/SitemapHelpers/components/SitemapItems'
import ICanvasImageCache from '~/client/src/shared/interfaces/ITextboxesCache'
import SitePermit from '~/client/src/shared/models/Permit'
import Permit from '~/client/src/shared/models/Permit'

import Activity from '../../models/Activity'
import Announcement from '../../models/Announcement'
import Delivery from '../../models/Delivery'
import GlobeView from '../../models/GlobeView'
import { LocationIntegrationType } from '../../models/LocationObjects/LocationIntegration'
import Sitemap from '../../models/Sitemap'
import EventsStore from '../../stores/EventStore/Events.store'
import ActivitiesStore from '../../stores/domain/Activities.store'
import BasemapsStore from '../../stores/domain/Basemaps.store'
import CompaniesStore from '../../stores/domain/Companies.store'
import GlobeViewsStore from '../../stores/domain/GlobeViews.store'
import PermitTypesStore from '../../stores/domain/PermitTypes.store'
import SitemapsStore from '../../stores/domain/Sitemaps.store'
import BaseLogisticsStore from '../../stores/ui/BaseLogistics.store'
import CommonStore from '../../stores/ui/Common.store'
import DeliverySitemapPinStore from '../../stores/ui/DeliverySitemapPin.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import DeliveryDetailsStore from '../DeliveryDetails/DeliveryDetails.store'
import { ViewMode } from '../DraggableBar/DraggableBar'
import KonvaWorkflowActivityPin from '../Konva/KonvaWorkflowActivityPin'
import KonvaWorkflowAnnouncementPin from '../Konva/KonvaWorkflowAnnouncementPin'
import KonvaWorkflowDeliveriesPin from '../Konva/KonvaWorkflowDeliveriesPin'
import KonvaWorkflowPermitPin from '../Konva/KonvaWorkflowPermitPin'
import MapBoxViewerStore from '../MapBoxEditor/MapBoxViewer.store'
import MenuCloser from '../MenuCloser'
import CompactAnnouncementCard from '../SitemapCards/CompactAnnouncementCard'
import LogisticsMapSwipeableCards from '../SitemapCards/LogisticsSwipeableCards'
import SitemapActivitiesDraggableModal from '../SitemapDraggableModalWrapper/components/SitemapActivitiesDraggableModal'
import SitemapDeliveriesDraggableModal from '../SitemapDraggableModalWrapper/components/SitemapDeliveriesDraggableModal'
import SitemapLocationModal from '../SitemapDraggableModalWrapper/components/SitemapLocationModal'
import SitemapMaturixDraggableModal from '../SitemapDraggableModalWrapper/components/SitemapMaturixStationDraggableModal'
import SitemapPermitsDraggableModal from '../SitemapDraggableModalWrapper/components/SitemapPermitsDraggableModal'
import SitemapElementsWrapper from '../SitemapElementsWrapper'
import GlobeViewActivityMapPin from '../SitemapHelpers/components/GlobePins/GlobeViewActivityMapPin'
import GlobeViewAnnouncementMapPin from '../SitemapHelpers/components/GlobePins/GlobeViewAnnouncementMapPin'
import GlobeViewDeliveryMapPin from '../SitemapHelpers/components/GlobePins/GlobeViewDeliveryMapPin'
import GlobeViewPermitMapPin from '../SitemapHelpers/components/GlobePins/GlobeViewPermitMapPin'
import GlobeViewItems from '../SitemapHelpers/components/GlobeViewItems'
import GlobeViewPlans from '../SitemapHelpers/components/GlobeViewPlans'
import MapViewItemBase from '../SitemapHelpers/models/MapViewItemBase'
import LogisticsMapViewSetUpStore from './LogisticsMapViewSetUp.store'

const SAME_POSITION_OFFSET = 20
const OFFSET_Y = 4

interface IProps {
  textboxesCache?: ICanvasImageCache
  store: LogisticsMapViewSetUpStore
  logisticsStore: BaseLogisticsStore
  mapBoxViewerStore: MapBoxViewerStore

  areAnnouncementsHidden?: boolean
  arePermitsHidden?: boolean
  areDeliveriesHidden?: boolean
  areActivitiesHidden?: boolean
  areMonitoringsHidden?: boolean

  toggleAnnouncementsHiddenState: () => void
  toggleDeliveriesHiddenState: () => void
  togglePermitsHiddenState: () => void
  toggleMonitoringsHiddenState: () => void
  renderShowOnMenu?: () => JSX.Element

  onPinClick?: (announcement: Announcement) => void
  openPermit?: (permit: SitePermit) => void
  openAnnouncement?: (announcement: Announcement) => void
  openDelivery?: (deliveryId: string) => void
  openActivity?: (code: string) => void
  deleteAnnouncement?: (announcement: Announcement) => void

  eventsStore?: EventsStore
  common?: CommonStore
  sitemapsStore?: SitemapsStore
  basemapsStore?: BasemapsStore
  projectDateStore?: ProjectDateStore
  deliveryDetailsStore?: DeliveryDetailsStore
  companiesStore?: CompaniesStore
  permitTypesStore?: PermitTypesStore
  activitiesStore?: ActivitiesStore

  shouldUseFullHeight?: boolean

  isCompactMode?: boolean
  isLayersMenuShown?: boolean
  toggleLayersMenu?: () => void

  openSiteDelivery?: (delivery: Delivery) => void
  selectViewMode?: (mode: ViewMode) => void
  shouldRenderSwipeableCards?: boolean

  globeViewsStore?: GlobeViewsStore

  isPermitOnly?: boolean
  globe?: GlobeView
  sitemap?: Sitemap
}

interface IPinOffset {
  r?: number
  l?: number
}
interface IPinsOffset {
  delivery: IPinOffset
  activity: IPinOffset
  permit: IPinOffset
}

@inject(
  'eventsStore',
  'common',
  'basemapsStore',
  'projectDateStore',
  'deliveryDetailsStore',
  'companiesStore',
  'permitTypesStore',
  'activitiesStore',
  'sitemapsStore',
  'globeViewsStore',
)
@observer
export default class LogisticsMapView extends React.Component<IProps> {
  public static contextType = MobXProviderContext
  @observable public swipeableItemIndex: number = 0
  @observable public swipeableItemId: string = null
  @observable private deliverySitemap: BaseMapView
  private readonly pinStore: DeliverySitemapPinStore = null

  @observable private containerRef: HTMLElement = null

  public constructor(props: IProps) {
    super(props)

    const { mapBoxViewerStore, eventsStore } = props
    this.pinStore = new DeliverySitemapPinStore(eventsStore.appState)

    mapBoxViewerStore.setViewportFromAddress()
    mapBoxViewerStore.setDefaultMapMode()
  }

  public componentDidMount(): void {
    if (this.props.shouldRenderSwipeableCards) {
      this.swipeableItemIndex = 0
      this.swipeableItemId = this.allItems?.[this.swipeableItemIndex]?.id
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>): void {
    const {
      store,
      areActivitiesHidden,
      shouldRenderSwipeableCards,
      areAnnouncementsHidden,
      areDeliveriesHidden,
      arePermitsHidden,
    } = this.props

    if (
      shouldRenderSwipeableCards &&
      (areActivitiesHidden !== prevProps.areActivitiesHidden ||
        areAnnouncementsHidden !== prevProps.areAnnouncementsHidden ||
        areDeliveriesHidden !== prevProps.areDeliveriesHidden ||
        arePermitsHidden !== prevProps.arePermitsHidden)
    ) {
      this.swipeableItemIndex = 0
      this.swipeableItemId = this.allItems?.[this.swipeableItemIndex]?.id
    }

    if (this.shouldShowAnnouncementCards && store.leftOffset === undefined) {
      this.calculateAnnouncementCardOffset()
    }
  }

  public render() {
    const {
      openPermit,
      openSiteDelivery,
      openActivity,
      openAnnouncement,
      shouldRenderSwipeableCards,
      isLayersMenuShown,
      toggleDeliveriesHiddenState,
      toggleAnnouncementsHiddenState,
      togglePermitsHiddenState,
      toggleMonitoringsHiddenState,
      areDeliveriesHidden,
      areAnnouncementsHidden,
      arePermitsHidden,
      areMonitoringsHidden,
      toggleLayersMenu,
      mapBoxViewerStore,
    } = this.props

    return (
      <div
        className="full-height full-width relative logistics-map-container"
        ref={this.setContainerRef}
      >
        {isLayersMenuShown && (
          <div className="layers-container">
            <MenuCloser closeMenu={toggleLayersMenu}>
              <MapLayersMenu
                store={mapBoxViewerStore}
                isLogisticsView={!shouldRenderSwipeableCards}
                toggleDeliveriesHiddenState={toggleDeliveriesHiddenState}
                toggleAnnouncementsHiddenState={toggleAnnouncementsHiddenState}
                togglePermitsHiddenState={togglePermitsHiddenState}
                toggleMonitoringsHiddenState={toggleMonitoringsHiddenState}
                areDeliveriesHidden={areDeliveriesHidden}
                areAnnouncementsHidden={areAnnouncementsHidden}
                arePermitsHidden={arePermitsHidden}
                areMonitoringsHidden={areMonitoringsHidden}
                onClose={toggleLayersMenu}
                className="compact-view"
              />
            </MenuCloser>
          </div>
        )}
        {this.renderSitemap()}
        {shouldRenderSwipeableCards ? (
          <LogisticsMapSwipeableCards
            announcements={this.displayedAnnouncements}
            activities={this.displayedActivities}
            deliveries={this.availableDeliveries}
            permits={this.displayedPermits}
            openSitePermit={openPermit}
            swipeableItemId={this.swipeableItemId}
            showPreviousItem={this.showPreviousItem}
            showNextItem={this.showNextItem}
            openDelivery={openSiteDelivery}
            openAnnouncement={openAnnouncement}
            openActivity={openActivity}
          />
        ) : (
          this.renderCards
        )}
      </div>
    )
  }

  private get renderCards(): JSX.Element {
    const {
      openPermit,
      openActivity,
      deleteAnnouncement,
      logisticsStore: { startDate },
      openDelivery,
      deliveryDetailsStore: { displayedDelivery },
      shouldRenderSwipeableCards,
      activitiesStore: { selection: displayedActivityId },
      isPermitOnly,
    } = this.props

    if (shouldRenderSwipeableCards) {
      return
    }

    const {
      selectedAnnouncements = [],
      selectedPermits = [],
      selectedActivities,
      leftOffset,
      topOffset,
      arePermitsSelected,
      areActivitiesSelected,
      selectedAttribute,
      selectedDeliveries,
      areDeliveriesSelected,
      shouldShowSelectedAttribute,
      displayedPermitId,
      deselectAll,
    } = this.props.store

    const shouldShowDeliveryModal =
      !isPermitOnly && areDeliveriesSelected && !!this.containerRef
    const shouldShowActivityModal =
      !isPermitOnly && areActivitiesSelected && !!this.containerRef
    const shouldShowPermitModal = arePermitsSelected && !!this.containerRef

    return (
      <>
        {this.shouldShowAnnouncementCards && (
          <CompactAnnouncementCard
            topOffset={topOffset}
            leftOffset={leftOffset}
            announcements={selectedAnnouncements}
            showAnnouncement={this.openAnnouncement}
            deleteAnnouncement={deleteAnnouncement}
            containerRef={this.containerRef}
            openPermit={openPermit}
          />
        )}
        {shouldShowPermitModal && (
          <SitemapPermitsDraggableModal
            item={selectedAttribute}
            containerRef={this.containerRef}
            selectedSitePermits={selectedPermits}
            selectedPermitId={displayedPermitId}
            openPermit={openPermit}
            onClose={deselectAll}
            topOffset={topOffset}
            leftOffset={leftOffset}
          />
        )}
        {shouldShowActivityModal && (
          <SitemapActivitiesDraggableModal
            item={selectedAttribute}
            containerRef={this.containerRef}
            selectedActivities={selectedActivities}
            selectedActivityId={displayedActivityId}
            currentViewDate={startDate}
            openActivity={openActivity}
            onClose={deselectAll}
            topOffset={topOffset}
            leftOffset={leftOffset}
          />
        )}
        {shouldShowSelectedAttribute &&
          (selectedAttribute.dataObject?.is(
            LocationIntegrationType.MaturixStation,
          ) ? (
            <SitemapMaturixDraggableModal
              item={selectedAttribute}
              containerRef={this.containerRef}
              onClose={deselectAll}
              topOffset={topOffset}
              leftOffset={leftOffset}
            />
          ) : (
            <SitemapLocationModal
              item={selectedAttribute}
              topOffset={topOffset}
              leftOffset={leftOffset}
              openPermit={openPermit}
              currentDate={startDate}
              containerRef={this.containerRef}
              onClose={deselectAll}
            />
          ))}
        {shouldShowDeliveryModal && (
          <SitemapDeliveriesDraggableModal
            onClose={deselectAll}
            item={selectedAttribute}
            containerRef={this.containerRef}
            onDeliveryClick={openDelivery}
            activeDeliveryId={displayedDelivery?.id}
            onFilterResetClick={deselectAll}
            relatedDeliveries={selectedDeliveries}
            topOffset={topOffset}
            leftOffset={leftOffset}
          />
        )}
      </>
    )
  }

  @action.bound
  private openAnnouncement(announcement: Announcement): void {
    const {
      openAnnouncement,
      store: { selectedAnnouncements },
    } = this.props
    selectedAnnouncements.splice(
      selectedAnnouncements.findIndex(x => x.id === announcement.id),
      1,
    )
    openAnnouncement(announcement)
  }

  private renderSitemap(): JSX.Element {
    const {
      areAnnouncementsHidden,
      arePermitsHidden,
      areDeliveriesHidden,
      areMonitoringsHidden,
      shouldUseFullHeight,
      toggleDeliveriesHiddenState,
      toggleAnnouncementsHiddenState,
      togglePermitsHiddenState,
      toggleMonitoringsHiddenState,
      renderShowOnMenu,
      basemapsStore,
      store: { sitemapUrl, deselectAll },
      mapBoxViewerStore,
      eventsStore: { appState },
      globe,
      sitemap,
      isCompactMode,
    } = this.props
    const { setViewport } = mapBoxViewerStore

    return (
      <>
        <BaseMapView
          globe={globe}
          selectedSitemap={sitemap}
          globeSitemaps={globe?.sitemaps}
          sitemapUrl={sitemapUrl}
          isDraggable={true}
          shouldUseFullHeight={shouldUseFullHeight}
          onWhiteboardClick={deselectAll}
          onTouch={this.onSitemapTouch}
          mapBoxViewerStore={mapBoxViewerStore}
          showOnMenu={renderShowOnMenu}
          setViewport={setViewport}
          basemapsStore={basemapsStore}
          toggleAnnouncementsHiddenState={toggleAnnouncementsHiddenState}
          toggleDeliveriesHiddenState={toggleDeliveriesHiddenState}
          togglePermitsHiddenState={togglePermitsHiddenState}
          toggleMonitoringsHiddenState={toggleMonitoringsHiddenState}
          isLogisticsView={true}
          arePermitsHidden={arePermitsHidden}
          areAnnouncementsHidden={areAnnouncementsHidden}
          areDeliveriesHidden={areDeliveriesHidden}
          areMonitoringsHidden={areMonitoringsHidden}
          projectAddress={appState.projectAddress}
          isCompactMode={isCompactMode}
          ref={this.setDeliverySitemap}
        >
          {({ width, height }) => {
            if (!this.deliverySitemap) {
              return null
            }
            return (
              <Provider {...this.context}>
                {globe
                  ? this.renderGlobeViewChildren()
                  : this.renderSitemapChildren(width, height)}
              </Provider>
            )
          }}
        </BaseMapView>
      </>
    )
  }

  private setDeliverySitemap = ref => {
    this.deliverySitemap = ref
  }

  @computed
  private get attrsToDeliveryMap() {
    return this.props.store.getClosestAttrIdToDeliveriesMap(
      this.availableDeliveries,
      this.sortedMapViewItems,
    )
  }

  @computed
  private get attrsToActivityMap() {
    return this.props.store.getClosestAttrIdToActivitiesMap(
      this.availableActivities,
      this.sortedMapViewItems,
    )
  }

  private renderMarkerPin = (item: MapViewItemBase) => {
    const {
      areAnnouncementsHidden,
      arePermitsHidden,
      areActivitiesHidden,
      areDeliveriesHidden,
      store: {
        selectedAttribute,
        areActivitiesSelected,
        areDeliveriesSelected,
        arePermitsSelected,
        areAnnouncementsSelected,
      },
      isPermitOnly,
    } = this.props

    const {
      shouldShowPin,
      shouldShowPinAsDone,
      shouldShowPinAsAssigned,
      shouldShowPinAsCanceled,
    } = this.pinStore
    const {
      eventsStore: {
        appState: { user },
      },
      permitTypesStore,
    } = this.props

    const deliveries =
      !isPermitOnly && !areDeliveriesHidden
        ? this.attrsToDeliveryMap[item.id]
        : []
    const announcements =
      !isPermitOnly && !areAnnouncementsHidden
        ? this.displayedAnnouncementsMap[item.id]
        : []
    const permits = !arePermitsHidden ? this.displayedPermitsMap[item.id] : []
    const activities =
      !isPermitOnly && !areActivitiesHidden
        ? this.attrsToActivityMap[item.id]
        : []

    if (
      !activities?.length &&
      !permits?.length &&
      !announcements?.length &&
      (!deliveries?.length || !shouldShowPin(item, deliveries))
    ) {
      return null
    }

    const { id: sitemapItemId } = item
    const isPinSelected = selectedAttribute?.id === sitemapItemId
    const isActivityPinSelected =
      (areActivitiesSelected && isPinSelected) ||
      activities?.some(
        a => a.id === this.swipeableItemId || this.isActivityDisplayed(a.code),
      )
    const isDeliveryPinSelected =
      (areDeliveriesSelected && isPinSelected) ||
      deliveries?.some(
        d => d.id === this.swipeableItemId || this.isDeliveryDisplayed(d.id),
      )
    const isAnnouncementPinSelected =
      (areAnnouncementsSelected && isPinSelected) ||
      announcements?.some(
        a =>
          a.id === this.swipeableItemId || this.isAnnouncementDisplayed(a.id),
      )
    const isPermitPinSelected =
      (arePermitsSelected && isPinSelected) ||
      permits?.some(
        p => p.id === this.swipeableItemId || this.isPermitDisplayed(p.id),
      )
    const positions = this.calculatePositions(
      deliveries?.length,
      announcements?.length,
      activities?.length,
      permits?.length,
    )

    return (
      <>
        {!!activities?.length && (
          <GlobeViewActivityMapPin
            isDone={activities.some(a => a.isDone)}
            isAssigned={activities.some(a => a.requesterId === user.id)}
            isSelected={isActivityPinSelected}
            isCanceled={false}
            itemsCount={activities.length}
            onClick={this.selectActivities.bind(null, activities, item)}
            left={positions.activity.l}
            right={positions.activity.r}
          />
        )}
        {!!deliveries?.length && (
          <GlobeViewDeliveryMapPin
            isDone={shouldShowPinAsDone(deliveries)}
            isAssigned={shouldShowPinAsAssigned(deliveries, user)}
            isSelected={isDeliveryPinSelected}
            isCanceled={shouldShowPinAsCanceled(deliveries)}
            itemsCount={deliveries.length}
            onClick={this.selectDeliveries.bind(null, deliveries, item)}
            left={positions.delivery.l}
            right={positions.delivery.r}
          />
        )}
        {!!permits?.length && (
          <GlobeViewPermitMapPin
            isDone={permits?.every(p => p.isDoneOrDenied)}
            isAssigned={permits?.some(p => p.isAssignedToUser(user))}
            isSelected={isPermitPinSelected}
            isCanceled={false}
            itemsCount={permits.length}
            permitType={permits[0]?.getTypeOfPermitType(permitTypesStore)}
            shouldRenderCircle={permits.length > 1}
            onClick={this.selectPermits.bind(null, permits, item)}
            left={positions.permit.l}
            right={positions.permit.r}
          />
        )}
        {!!announcements?.length && (
          <GlobeViewAnnouncementMapPin
            isDone={false}
            isAssigned={false}
            isSelected={isAnnouncementPinSelected}
            isCanceled={false}
            itemsCount={announcements.length}
            onClick={this.selectAnnouncements.bind(null, announcements, item)}
          />
        )}
      </>
    )
  }

  // calculates positions for pins on globe
  private calculatePositions(
    d: number,
    a: number,
    ac: number,
    p: number,
  ): IPinsOffset {
    const permit: IPinOffset = {
      r: a > 0 ? 10 : null,
    }
    const delivery: IPinOffset = {
      l: a > 0 || p > 0 ? 10 : null,
    }
    let left = null
    let right = null
    switch (true) {
      case !a && !d && !p:
        break
      case !!a && !!d && !!p:
        left = 20
        break
      case !!d && (!!a || !!p):
        right = 10
        break
      default:
        left = 10
        break
    }
    const activity: IPinOffset = {
      l: left,
      r: right,
    }
    const offsets: IPinsOffset = {
      permit,
      delivery,
      activity,
    }

    return offsets
  }

  @action.bound
  private selectAnnouncements(
    ann: Announcement[],
    item: MapViewItemBase,
    event,
  ) {
    event.stopPropagation()
    if (this.props.shouldRenderSwipeableCards) {
      this.swipeableItemId = ann[0]?.id
      this.swipeableItemIndex = this.allItems.findIndex(
        item => item.id === ann[0]?.id,
      )
    }
    this.props.store.selectAnnouncements(ann, null, null, item)
  }

  @action.bound
  private selectDeliveries(
    deliveries: Delivery[],
    item: MapViewItemBase,
    event,
  ) {
    event.stopPropagation()
    if (this.props.shouldRenderSwipeableCards) {
      this.swipeableItemId = deliveries[0]?.id
      this.swipeableItemIndex = this.allItems.findIndex(
        item => item.id === deliveries[0]?.id,
      )
    }
    this.props.store.selectDeliveries(deliveries, item)
  }

  @action.bound
  private selectActivities(
    activities: Activity[],
    item: MapViewItemBase,
    event,
  ) {
    event.stopPropagation()
    if (this.props.shouldRenderSwipeableCards) {
      this.swipeableItemId = activities[0]?.id
      this.swipeableItemIndex = this.allItems.findIndex(
        item => item.id === activities[0]?.id,
      )
    }
    this.props.store.selectActivities(activities, item)
  }

  @action.bound
  private selectPermits(permits: Permit[], item: MapViewItemBase, event) {
    event.stopPropagation()
    if (this.props.shouldRenderSwipeableCards) {
      this.swipeableItemId = permits[0]?.id
      this.swipeableItemIndex = this.allItems.findIndex(
        item => item.id === permits[0]?.id,
      )
    }
    this.props.store.selectPermits(permits, item)
  }

  private renderGlobeViewChildren = () => {
    const { mapBoxViewerStore } = this.props
    const { displayedGlobeViewItems } = mapBoxViewerStore

    return (
      <>
        <GlobeViewPlans
          mapBoxViewerStore={mapBoxViewerStore}
          sitemapWithBasemaps={mapBoxViewerStore.sitemapWithBasemapsOnGlobe}
        />
        <GlobeViewItems
          items={displayedGlobeViewItems || []}
          onSelectItem={this.selectSitemapItem}
          mapBoxViewerStore={mapBoxViewerStore}
          renderMarkerPin={this.renderMarkerPin}
        />
      </>
    )
  }

  private selectSitemapItem = (
    item: MapViewItemBase,
    y?: number,
    x?: number,
  ) => {
    const { selectItem } = this.props.store

    if (!item.isDataLess) {
      selectItem(item, y, x)
    }
  }

  private renderSitemapChildren = (width: number, height: number) => {
    const {
      textboxesCache,
      areAnnouncementsHidden,
      arePermitsHidden,
      areActivitiesHidden,
      areDeliveriesHidden,
      isPermitOnly,
      store: {
        selectItem,
        getClosestAttrIdToDeliveriesMap,
        displayedSitemapItems,
      },
    } = this.props

    const attrsToDeliveryMap = getClosestAttrIdToDeliveriesMap(
      this.availableDeliveries,
      this.sortedMapViewItems,
    )

    return (
      <>
        <SitemapItems
          className="unclickable-element"
          items={displayedSitemapItems || []}
          containerWidth={width}
          containerHeight={height}
          textboxesCache={textboxesCache}
          onSelectItem={selectItem}
        />
        <SitemapElementsWrapper>
          {!arePermitsHidden &&
            this.displayedPermits.map(
              this.renderPermit.bind(this, width, height),
            )}
          {!isPermitOnly &&
            !areAnnouncementsHidden &&
            this.displayedAnnouncements.map(
              this.renderAnnouncement.bind(this, width, height),
            )}
          {!isPermitOnly &&
            !areActivitiesHidden &&
            this.displayedActivities.map(
              this.renderActivity.bind(this, width, height),
            )}
          {!isPermitOnly &&
            !areDeliveriesHidden &&
            this.sortedMapViewItems.map(item =>
              this.renderWorkflowPin(
                item,
                width,
                height,
                null,
                attrsToDeliveryMap,
              ),
            )}
        </SitemapElementsWrapper>
      </>
    )
  }

  public get viewport() {
    return this.props.mapBoxViewerStore.viewport
  }

  private onSitemapTouch = () => {
    this.props.store.deselectAll()
    this.selectMapViewMode()
    this.swipeableItemId = null
  }

  private renderAnnouncement(
    width: number,
    height: number,
    announcement: Announcement,
    idx: number,
  ) {
    const {
      arePermitsHidden,
      shouldRenderSwipeableCards,
      store: { getAnnouncementLocationPosition, getPermitLocationPositions },
    } = this.props

    const location = getAnnouncementLocationPosition(announcement)

    if (!location) {
      return
    }
    const samePositionPermits = this.displayedPermits.filter(p => {
      return getPermitLocationPositions(p).some(
        l => l.x === location.x && l.y === location.y,
      )
    })
    const samePositionAnnouncements = this.displayedAnnouncements.filter(a => {
      const announcementPosition = getAnnouncementLocationPosition(a)
      return (
        announcementPosition.x === location.x &&
        announcementPosition.y === location.y
      )
    })

    const samePositionIdx = samePositionAnnouncements.indexOf(announcement)

    if (samePositionIdx > 0) {
      return
    }
    const text =
      samePositionAnnouncements.length > 1
        ? samePositionAnnouncements.length.toString()
        : announcement.datesLabel
    const x =
      (width * location.x) / MAX_PERCENT +
      samePositionIdx * SAME_POSITION_OFFSET
    const y = (height * location.y) / MAX_PERCENT

    const PIN_OFFSET =
      !arePermitsHidden && samePositionPermits ? SAME_POSITION_OFFSET : 0
    const isSelected = shouldRenderSwipeableCards
      ? samePositionAnnouncements.some(n => this.swipeableItemId === n.id)
      : this.isAnnouncementDisplayed(announcement.id)

    return (
      <KonvaWorkflowAnnouncementPin
        text={text}
        isSelected={isSelected}
        isCanceled={false}
        shouldRenderCircle={samePositionAnnouncements.length > 1}
        key={idx + 1}
        x={x + PIN_OFFSET}
        y={y + OFFSET_Y}
        onClick={this.onAnnouncementPinClick.bind(
          this,
          samePositionAnnouncements,
          y,
          x + PIN_OFFSET,
        )}
        onTouchEnd={this.onAnnouncementPinTouch.bind(
          this,
          samePositionAnnouncements,
          y,
          x + PIN_OFFSET,
        )}
      />
    )
  }

  private renderPermit(width: number, height: number, permit: SitePermit) {
    const {
      shouldRenderSwipeableCards,
      permitTypesStore,
      eventsStore: { appState },
    } = this.props
    const { getPermitLocationPositions, getRelatedItems } = this.props.store

    const permitType = permit?.getTypeOfPermitType(permitTypesStore)
    const items = getRelatedItems(permit.locations)

    if (!items) {
      return
    }

    return items.map((item, lIndex) => {
      const { position } = item.sitemapItemProperties.iconProperties
      const samePositionPermits = this.displayedPermits.filter(p => {
        return getPermitLocationPositions(p).some(
          l => l.x === position.x && l.y === position.y,
        )
      })

      const samePositionIdx = samePositionPermits.indexOf(permit)

      if (samePositionIdx > 0) {
        return
      }
      const text =
        samePositionPermits.length > 1
          ? samePositionPermits.length.toString()
          : permit.code
      const x =
        (width * position.x) / MAX_PERCENT +
        samePositionIdx * SAME_POSITION_OFFSET
      const y = (height * position.y) / MAX_PERCENT

      const isSelected = shouldRenderSwipeableCards
        ? samePositionPermits.some(p => this.swipeableItemId === p.id)
        : this.isPermitDisplayed(permit.id)

      const shouldShowPinAsDone = samePositionPermits.every(
        p => p.isDoneOrDenied,
      )
      const shouldShowPinAsAssigned = samePositionPermits.some(p =>
        p.isAssignedToUser(appState.user),
      )

      return (
        <KonvaWorkflowPermitPin
          text={text}
          isDone={shouldShowPinAsDone}
          isAssigned={shouldShowPinAsAssigned}
          isSelected={isSelected}
          isCanceled={false}
          shouldRenderCircle={samePositionPermits.length > 1}
          key={permit.id + lIndex}
          x={x}
          y={y}
          offsetY={OFFSET_Y}
          permitType={permitType}
          onTouchEnd={this.onPermitPillTouch.bind(
            this,
            samePositionPermits,
            item,
          )}
          onClick={this.onPermitPillClick.bind(this, samePositionPermits, item)}
        />
      )
    })
  }

  private renderActivity(width: number, height: number, activity: Activity) {
    const {
      shouldRenderSwipeableCards,
      arePermitsHidden,
      areAnnouncementsHidden,
      areDeliveriesHidden,
    } = this.props
    const {
      getLocationPositions,
      getRelatedItems,
      getPermitLocationPositions,
      getAnnouncementLocationPosition,
    } = this.props.store

    const items = getRelatedItems(activity.locations)

    if (!items) {
      return
    }

    return items.map((item, lIndex) => {
      const { position } = item.sitemapItemProperties.iconProperties
      const samePositionActivities = this.displayedActivities.filter(a => {
        return getLocationPositions(a.locations).includes(position)
      })

      const samePositionIdx = samePositionActivities.indexOf(activity)

      if (samePositionIdx > 0) {
        return
      }

      const samePositionPermits =
        !arePermitsHidden &&
        this.displayedPermits.some(permit => {
          const permitLocation = getPermitLocationPositions(permit)[0]
          return (
            permitLocation.x === position.x && position.y === permitLocation.y
          )
        })

      const samePositionAnnouncements =
        !areAnnouncementsHidden &&
        this.displayedAnnouncements.some(announcement => {
          const announcementLocation =
            getAnnouncementLocationPosition(announcement)
          return (
            announcementLocation.x === position.x &&
            position.y === announcementLocation.y
          )
        })

      const text =
        samePositionActivities.length > 1
          ? samePositionActivities.length.toString()
          : activity.code

      let additionalXOffset = 0
      if (!samePositionPermits) {
        additionalXOffset = 0
      } else if (!samePositionAnnouncements) {
        additionalXOffset = SAME_POSITION_OFFSET
      } else if (areDeliveriesHidden) {
        additionalXOffset = -SAME_POSITION_OFFSET
      } else {
        additionalXOffset = -SAME_POSITION_OFFSET + -SAME_POSITION_OFFSET
      }

      const additionalYOffset = samePositionPermits ? OFFSET_Y : 0
      const x = (width * position.x) / MAX_PERCENT + additionalXOffset
      const y = (height * position.y) / MAX_PERCENT + additionalYOffset

      const isSelected = shouldRenderSwipeableCards
        ? samePositionActivities.some(a => this.swipeableItemId === a.code)
        : this.isActivityDisplayed(activity.code)

      return (
        <KonvaWorkflowActivityPin
          text={text}
          isSelected={isSelected}
          isCanceled={false}
          shouldRenderCircle={samePositionActivities.length > 1}
          key={activity.code + lIndex}
          x={x}
          y={y}
          offsetY={OFFSET_Y}
          onTouchEnd={this.onActivityPillTouch.bind(
            this,
            samePositionActivities,
            item,
          )}
          onClick={this.onActivityPillClick.bind(
            this,
            samePositionActivities,
            item,
          )}
        />
      )
    })
  }

  private isAnnouncementDisplayed(id: string): boolean {
    const { selectedAnnouncements = [] } = this.props.store
    return selectedAnnouncements.some(announcement => announcement.id === id)
  }

  private isPermitDisplayed(id: string): boolean {
    const { selectedPermits = [] } = this.props.store
    return (
      selectedPermits.some(permit => permit.id === id) ||
      this.props.store.displayedPermitId === id
    )
  }

  private isDeliveryDisplayed(id: string): boolean {
    const { selectedDeliveries = [] } = this.props.store
    return (
      selectedDeliveries.some(delivery => delivery.id === id) ||
      this.props.deliveryDetailsStore.displayedDelivery?.id === id
    )
  }

  private isActivityDisplayed(code: string): boolean {
    const {
      store: { selectedActivities = [] },
      activitiesStore: { selectedActivity },
    } = this.props
    return (
      selectedActivities.some(activity => activity.code === code) ||
      selectedActivity?.code === code
    )
  }

  private onAnnouncementPinTouch(
    announcements: Announcement[],
    y: number,
    x: number,
    event: KonvaEventObject<TouchEvent>,
  ) {
    const { shouldRenderSwipeableCards, store } = this.props
    if (shouldRenderSwipeableCards) {
      this.swipeableItemId = announcements[0]?.id
      this.swipeableItemIndex = this.allItems.findIndex(
        item => item.id === announcements[0]?.id,
      )
    }
    this.selectMapViewMode()
    store.selectAnnouncements(announcements, y, x)
    event.cancelBubble = true
  }

  private onAnnouncementPinClick(
    announcements: Announcement[],
    y: number,
    x: number,
    event: KonvaEventObject<MouseEvent>,
  ) {
    if (this.props.onPinClick) {
      this.props.onPinClick(announcements[0])
    }
    this.props.store.selectAnnouncements(announcements, y, x)
    this.swipeableItemIndex = this.allItems.findIndex(
      currentItem => currentItem.id === announcements[0]?.id,
    )
    event.cancelBubble = true
  }

  private onPermitPillTouch(
    permits: SitePermit[],
    item: MapViewItemBase,
    event: KonvaEventObject<TouchEvent>,
  ) {
    const { shouldRenderSwipeableCards, store } = this.props

    if (shouldRenderSwipeableCards) {
      this.swipeableItemId = permits[0]?.id
      this.swipeableItemIndex = this.allItems.findIndex(
        item => item.id === permits[0]?.id,
      )
    }
    this.selectMapViewMode()

    store.selectPermits(permits, item)

    event.cancelBubble = true
  }

  private onPermitPillClick(
    permits: SitePermit[],
    item: MapViewItemBase,
    event: KonvaEventObject<MouseEvent>,
  ) {
    this.props.store.selectPermits(permits, item)
    event.cancelBubble = true
  }

  private onActivityPillTouch(
    activities: Activity[],
    item: MapViewItemBase,
    event: KonvaEventObject<TouchEvent>,
  ) {
    const { shouldRenderSwipeableCards, store } = this.props

    if (shouldRenderSwipeableCards) {
      this.swipeableItemId = activities[0]?.code
      this.swipeableItemIndex = this.allItems.findIndex(
        item => item.id === activities[0]?.code,
      )
    }

    this.selectMapViewMode()

    store.selectActivities(activities, item)

    event.cancelBubble = true
  }

  private onActivityPillClick(
    activities: Activity[],
    item: MapViewItemBase,
    event: KonvaEventObject<MouseEvent>,
  ) {
    this.props.store.selectActivities(activities, item)
    event.cancelBubble = true
  }

  private onDeliveryPillTouch = (
    deliveries: Delivery[],
    sitemapItem: MapViewItemBase,
    event: KonvaEventObject<TouchEvent>,
  ) => {
    const { shouldRenderSwipeableCards, store } = this.props

    if (shouldRenderSwipeableCards) {
      this.swipeableItemId = deliveries[0]?.id
      this.swipeableItemIndex = this.allItems.findIndex(
        item => item.id === deliveries[0]?.id,
      )
    }

    this.selectMapViewMode()

    store.selectDeliveries(deliveries, sitemapItem)

    event.cancelBubble = true
  }

  private onDeliveryPillClick = (
    deliveries: Delivery[],
    item: MapViewItemBase,
    event: KonvaEventObject<MouseEvent>,
  ) => {
    this.props.store.selectDeliveries(deliveries, item)
    event.cancelBubble = true
  }

  @action.bound
  private setContainerRef(ref: HTMLElement) {
    this.containerRef = ref
  }

  private renderWorkflowPin(
    item: MapViewItemBase,
    cWidth: number,
    cHeight: number,
    listGroupingKey: string,
    attrIdToDeliveriesMap: { [attrId: string]: Delivery[] },
  ): JSX.Element {
    const {
      arePermitsHidden,
      areAnnouncementsHidden,
      areActivitiesHidden,
      companiesStore,
      shouldRenderSwipeableCards,
      eventsStore: { appState },
    } = this.props

    const {
      getPinLabel,
      shouldShowPin,
      getPinPosition,
      shouldRenderCircle,
      getPositionByDisplayedProperties,
      shouldShowPinAsDone,
      shouldShowPinAsAssigned,
      shouldShowPinAsCanceled,
    } = this.pinStore

    const deliveries = attrIdToDeliveriesMap[item.id] || []

    if (!shouldShowPin(item, deliveries, listGroupingKey)) {
      return null
    }

    const {
      getLocationPositions,
      getPermitLocationPositions,
      getAnnouncementLocationPosition,
    } = this.props.store
    const { x, y } = getPinPosition(item, cWidth, cHeight)

    const deliveryPosition = getPositionByDisplayedProperties(item)

    const samePositionPermits =
      !arePermitsHidden &&
      this.displayedPermits.some(permit => {
        const permitLocation = getPermitLocationPositions(permit)[0]
        return (
          permitLocation.x === deliveryPosition.x &&
          deliveryPosition.y === permitLocation.y
        )
      })

    const samePositionAnnouncements =
      !areAnnouncementsHidden &&
      this.displayedAnnouncements.some(announcements => {
        const announcementLocation =
          getAnnouncementLocationPosition(announcements)
        return (
          announcementLocation.x === deliveryPosition.x &&
          deliveryPosition.y === announcementLocation.y
        )
      })

    const samePositionActivities =
      !areActivitiesHidden &&
      this.displayedActivities.some(activity => {
        const locations = activity.locations
        const activityPositions = getLocationPositions(locations)
        return activityPositions.some(
          pos => pos.x === deliveryPosition.x && deliveryPosition.y === pos.y,
        )
      })

    const PIN_OFFSET =
      samePositionPermits || samePositionAnnouncements || samePositionActivities
        ? -SAME_POSITION_OFFSET
        : 0

    const isSelected = shouldRenderSwipeableCards
      ? deliveries.some(n => this.swipeableItemId === n.id)
      : this.isDeliveryDisplayed(deliveries[0]?.id)

    return (
      <KonvaWorkflowDeliveriesPin
        key={item.id}
        isDone={shouldShowPinAsDone(deliveries)}
        isAssigned={shouldShowPinAsAssigned(deliveries, appState.user)}
        isSelected={isSelected}
        isCanceled={shouldShowPinAsCanceled(deliveries)}
        text={getPinLabel(deliveries, companiesStore)}
        shouldRenderCircle={shouldRenderCircle(deliveries)}
        x={x + PIN_OFFSET}
        y={y + OFFSET_Y}
        onTouchEnd={this.onDeliveryPillTouch.bind(this, deliveries, item)}
        onClick={this.onDeliveryPillClick.bind(this, deliveries, item)}
      />
    )
  }

  @computed
  private get sortedMapViewItems(): MapViewItemBase[] {
    const {
      store,
      store: { globeViewControlStore, mapBoxViewerStore },
    } = this.props
    const { displayedGlobeViewItems } = mapBoxViewerStore
    return this.pinStore.getItemsByPinLocationOption(
      globeViewControlStore.selectedGlobeViewId
        ? displayedGlobeViewItems
        : store.displayedSitemapItems,
    )
  }

  @computed
  private get availableDeliveries(): Delivery[] {
    const {
      logisticsStore: { deliveriesInPeriodInterval },
      mapBoxViewerStore: { displayedGlobeViewItems: displayedGlobeItems },
      store,
      globe,
      areDeliveriesHidden,
    } = this.props

    if (areDeliveriesHidden) {
      return []
    }

    const displayedAttrsIds = (
      globe ? displayedGlobeItems : store.displayedSitemapItems
    ).map(si => si.id)
    return deliveriesInPeriodInterval.filter(delivery =>
      displayedAttrsIds.some(attrId => delivery.isRelatedAttr(attrId)),
    )
  }

  @computed
  private get availableActivities(): Activity[] {
    const {
      projectDateStore: { isWithinDateInterval },
      logisticsStore: { allActivities, startDate },
      mapBoxViewerStore: { displayedGlobeViewItems: displayedGlobeItems },
      globe,
      store,
      areActivitiesHidden,
    } = this.props

    if (areActivitiesHidden) {
      return []
    }

    const availableActivities = allActivities.filter(a => {
      return (
        startDate &&
        isWithinDateInterval(a.startDate, a.plannedEndDate, startDate)
      )
    })

    const displayedAttrsIds = (
      globe ? displayedGlobeItems : store.displayedSitemapItems
    ).map(si => si.id)
    return availableActivities.filter(activity =>
      displayedAttrsIds.some(attrId =>
        activity.locations.some(l => l.id === attrId),
      ),
    )
  }

  @computed
  public get displayedPermits(): SitePermit[] {
    const {
      logisticsStore: { formsInPeriodInterval },
      globe,
      store,
      mapBoxViewerStore: { displayedGlobeViewItems: displayedGlobeItems },
      arePermitsHidden,
    } = this.props

    if (arePermitsHidden) {
      return []
    }

    return formsInPeriodInterval.filter(permit => {
      const permitLocationId = permit.locations?.[0]?.id

      return (
        (globe ? displayedGlobeItems : store.displayedSitemapItems).some(
          sitemapItem => permitLocationId === sitemapItem.id,
        ) || !!store.findParentItemByAttrId(permitLocationId)
      )
    })
  }

  @computed
  public get displayedAnnouncements(): Announcement[] {
    const {
      logisticsStore: { announcementsInPeriodInterval },
      store,
      globe,
      mapBoxViewerStore: { displayedGlobeViewItems: displayedGlobeItems },
      areAnnouncementsHidden,
    } = this.props

    if (areAnnouncementsHidden) {
      return []
    }

    return announcementsInPeriodInterval.filter(announcement => {
      const announcementLocationId = announcement?.location?.id

      return (
        (globe ? displayedGlobeItems : store.displayedSitemapItems).some(
          sitemapItem => announcementLocationId === sitemapItem.id,
        ) || !!store.findParentItemByAttrId(announcementLocationId)
      )
    })
  }

  @computed
  public get displayedActivities(): Activity[] {
    const {
      store,
      logisticsStore: { activitiesInPeriodInterval },
      mapBoxViewerStore: { displayedGlobeViewItems: displayedGlobeItems },
      globe,
      areActivitiesHidden,
    } = this.props

    if (areActivitiesHidden) {
      return []
    }

    return activitiesInPeriodInterval.filter(activity =>
      activity.locations.some(
        l =>
          (globe ? displayedGlobeItems : store.displayedSitemapItems).some(
            sitemapItem => l.id === sitemapItem.id,
          ) || !!store.findParentItemByAttrId(l.id),
      ),
    )
  }

  @computed
  public get displayedAnnouncementsMap(): {
    [locatonId: string]: Announcement[]
  } {
    return this.displayedAnnouncements.reduce((acc, ann) => {
      if (ann.location) {
        if (acc[ann.location.id]) {
          acc[ann.location.id].push(ann)
        } else {
          acc[ann.location.id] = [ann]
        }
      }
      return acc
    }, {})
  }

  @computed
  public get displayedPermitsMap(): {
    [locatonId: string]: Permit[]
  } {
    return this.displayedPermits.reduce((acc, p) => {
      if (p.locations?.length) {
        if (acc[p.locations[0].id]) {
          acc[p.locations[0].id].push(p)
        } else {
          acc[p.locations[0].id] = [p]
        }
      }
      return acc
    }, {})
  }

  private get shouldShowAnnouncementCards(): boolean {
    const { shouldRenderSwipeableCards, isPermitOnly } = this.props

    const { shouldShowAnnouncementsList } = this.props.store
    return (
      !isPermitOnly &&
      !shouldRenderSwipeableCards &&
      shouldShowAnnouncementsList &&
      !!this.containerRef
    )
  }

  private showPreviousItem = () => {
    if (this.swipeableItemIndex) {
      this.swipeableItemIndex--
      this.swipeableItemId = this.allItems[this.swipeableItemIndex].id
    }
  }

  private showNextItem = () => {
    if (this.swipeableItemIndex + 1 < this.allItems.length) {
      this.swipeableItemIndex++
      this.swipeableItemId = this.allItems[this.swipeableItemIndex].id
    }
  }

  private get allItems() {
    return [
      ...this.displayedAnnouncements,
      ...this.displayedPermits,
      ...this.availableDeliveries,
      ...this.displayedActivities,
    ]
  }

  private selectMapViewMode() {
    const { selectViewMode } = this.props
    if (selectViewMode) {
      selectViewMode(ViewMode.Closed)
    }
  }

  private calculateAnnouncementCardOffset() {
    this.props.store.setAnnouncementCardOffset(this.containerRef, this.pinStore)
  }
}
