import { SitePermitStatus, WorkflowStepType } from '~/client/graph'

const formStatusesByStepsMap: { [K in WorkflowStepType]: SitePermitStatus[] } =
  {
    [WorkflowStepType.Request]: [
      SitePermitStatus.Requested,
      SitePermitStatus.Changed,
    ],
    [WorkflowStepType.Submission]: [
      SitePermitStatus.Submitted,
      SitePermitStatus.Changed,
    ],
    [WorkflowStepType.Review]: [SitePermitStatus.Reviewed],
    [WorkflowStepType.Approval]: [
      SitePermitStatus.Denied,
      SitePermitStatus.Accepted,
    ],
    [WorkflowStepType.Start]: [SitePermitStatus.Active],
    [WorkflowStepType.ReadyToInspect]: [SitePermitStatus.ToInspect],
    [WorkflowStepType.Inspection]: [
      SitePermitStatus.Failed,
      SitePermitStatus.Passed,
    ],
    [WorkflowStepType.RecurringInspection]: [],
    [WorkflowStepType.BicInspection]: [SitePermitStatus.BicInspected],
    [WorkflowStepType.Finish]: [SitePermitStatus.Done],
  }

export default formStatusesByStepsMap
