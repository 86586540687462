import * as React from 'react'

import { Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import { computed, observable } from 'mobx'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import { arrayMove } from 'react-sortable-hoc'

import * as Icons from '~/client/src/shared/components/Icons'
import InitialState from '~/client/src/shared/stores/InitialState'

import GlobeView from '../../models/GlobeView'
import Sitemap from '../../models/Sitemap'
import EventsStore from '../../stores/EventStore/Events.store'
import EventTypes from '../../stores/EventStore/eventTypes'
import GlobeViewsStore from '../../stores/domain/GlobeViews.store'
import PermitTypesStore from '../../stores/domain/PermitTypes.store'
import SitemapsStore from '../../stores/domain/Sitemaps.store'
import WeatherForecastsStore from '../../stores/domain/WeatherForecasts.store'
import ProjectDateStore from '../../stores/ui/ProjectDate.store'
import CompactMapViewPicker from '../CompactSitemapPicker/CompactMapViewPicker'
import FileInputBase from '../FileInput/FileInput'
import MapBoxViewerStore from '../MapBoxEditor/MapBoxViewer.store'
import LogisticsCreationListMenu from '../SitemapCards/LogisticsCreationListMenu'
import WeatherLabel from '../WeatherLabel/WeatherLabel'
import PinchZoomPan from './PinchZoomPan'

import './MapViewsGallery.scss'

const MIN_SCALE = 1
const MAX_SCALE = 8

export interface IMapConfiguration {
  globeViewId?: string
  sitemapId?: string
}

export interface IProps {
  eventName: EventTypes

  selectGlobe: (globe: GlobeView) => void
  mapIds: IMapConfiguration[]
  globeViewsStore?: GlobeViewsStore
  selectedGlobeViewId: string
  selectSitemap: (sitemap: Sitemap) => void
  selectedWhiteboardId: string
  sitemapsStore?: SitemapsStore
  openPermitCreationForm?: () => void
  openAnnouncementCreationForm?: () => void
  state?: InitialState
  onHide?: () => void
  isHideButtonDisplayed?: boolean
  renderMapViewComponent?: () => JSX.Element
  toggleLayersMenu?: () => void
  shouldDisableZoom?: boolean
  shouldShowCreateNewButton?: boolean
  eventsStore?: EventsStore
  permitTypesStore?: PermitTypesStore
  shouldDisableSwap?: boolean
  areArrowsLeftSided?: boolean
  currentDate?: Date
  shouldUseFullHeight?: boolean
  shouldIncreasedIcons?: boolean
  isCompactMode?: boolean
  weatherForecastsStore?: WeatherForecastsStore
  projectDateStore?: ProjectDateStore
  mapBoxViewerStore?: MapBoxViewerStore
  isPermitOnly?: boolean

  hasFiltersBar?: boolean

  renderCreateButton?: () => JSX.Element
  FileInputType: typeof FileInputBase
}

@inject(
  'state',
  'sitemapsStore',
  'eventsStore',
  'permitTypesStore',
  'weatherForecastsStore',
  'projectDateStore',
  'globeViewsStore',
)
@observer
export default class MapViewsGallery extends React.Component<IProps> {
  @observable public isGallerySwipingDisabled: boolean = false
  @observable public isSelectMenuShown: boolean = false
  @observable public searchKey: string = ''

  public constructor(props) {
    super(props)
  }

  public componentDidMount() {
    const { currentDate, weatherForecastsStore } = this.props
    if (currentDate) {
      weatherForecastsStore.fetchWeeklyForecast(currentDate)
    }
  }

  public componentDidUpdate(prevProps: IProps) {
    const { currentDate, weatherForecastsStore } = this.props

    if (!!currentDate && prevProps.currentDate !== currentDate) {
      weatherForecastsStore.fetchWeeklyForecast(currentDate)
    }
  }

  public componentWillUnmount(): void {
    this.props.mapBoxViewerStore.resetMapboxInfo()
  }

  @computed
  private get currentMapIndex() {
    const index = this.mapIds?.findIndex(
      mapId =>
        mapId.globeViewId === this.props.selectedGlobeViewId ||
        mapId.sitemapId === this.props.selectedWhiteboardId,
    )
    return index === -1 ? null : index
  }

  @computed
  private get mapIds(): IMapConfiguration[] {
    const { byId: globeViewById } = this.props.globeViewsStore
    const { byId: sitemapById } = this.props.sitemapsStore
    return this.props.mapIds.filter(id => {
      if (id.globeViewId) {
        return !!globeViewById.get(id.globeViewId)
      }
      if (id.sitemapId) {
        const sitemap = sitemapById.get(id.sitemapId)
        return !!sitemap && !sitemap.isReferenced
      }
      return false
    })
  }

  public render() {
    const {
      isHideButtonDisplayed,
      onHide,
      shouldUseFullHeight,
      FileInputType,
      shouldShowCreateNewButton,
      openAnnouncementCreationForm,
      openPermitCreationForm,
      isPermitOnly,
      selectedGlobeViewId,
      selectedWhiteboardId,
      isCompactMode,
    } = this.props

    return (
      <>
        {this.isSelectMenuShown && (
          <CompactMapViewPicker
            onClose={this.toggleSelectMenu}
            onGlobeApply={this.selectGlobe}
            onWhiteboardApply={this.selectWhiteboard}
            selectedGlobeId={selectedGlobeViewId}
            selectedWhiteboardId={selectedWhiteboardId}
            mapIds={this.mapIds}
            reassignMapOrders={this.reassignMapsOrders}
            FileInputType={FileInputType}
            isCompact={isCompactMode}
          />
        )}

        <div
          className={classList({
            'sitemaps relative': true,
            'full-height': shouldUseFullHeight,
          })}
        >
          <div
            className={classList({
              'sitemaps-gallery absolute full-height full-width': true,
              'select-menu-opened': this.isSelectMenuShown,
            })}
          >
            {this.renderViewSelectMenu()}
            {isHideButtonDisplayed && (
              <Icons.Cross className="cross-icon" onClick={onHide} />
            )}

            {shouldShowCreateNewButton && (
              <LogisticsCreationListMenu
                openAnnouncementCreationForm={openAnnouncementCreationForm}
                openPermitCreationForm={openPermitCreationForm}
                isPermitOnlyMode={isPermitOnly}
              />
            )}

            {this.renderItem()}
          </div>
        </div>
      </>
    )
  }

  private renderViewSelectMenu() {
    const {
      isCompactMode,
      shouldIncreasedIcons,
      weatherForecastsStore: { weatherByDaysList, weatherUnits },
      projectDateStore,
      currentDate,
      globeViewsStore,
      sitemapsStore,
      selectedGlobeViewId,
      selectedWhiteboardId,
      toggleLayersMenu,
    } = this.props
    const isGlobeMode = !!selectedGlobeViewId
    const selectedMapName = isGlobeMode
      ? globeViewsStore.byId.get(selectedGlobeViewId)?.name
      : sitemapsStore.byId.get(selectedWhiteboardId)?.name

    const selectedMapIndex =
      this.mapIds.findIndex(
        m =>
          m.globeViewId === selectedGlobeViewId ||
          m.sitemapId === selectedWhiteboardId,
      ) + 1

    const weatherForecast = weatherByDaysList.find(w =>
      projectDateStore.isSameDay(w.date, currentDate),
    )

    return (
      <div
        className={classList({
          'absolute row items-holder': true,
          'top-offset': shouldIncreasedIcons,
          'full-width row x-between px10 left-offset': isCompactMode,
          mt40: this.props.hasFiltersBar,
        })}
      >
        {isCompactMode ? (
          <>
            <div className="no-grow row">
              <div
                className="mr10 br-rounded no-grow bg-white pa5 row x-center y-center map-icon"
                onClick={this.toggleSelectMenu}
              >
                <Icons.Sitemap className="no-grow" />
              </div>
              <div className="brada20 no-grow bg-white row x-center y-center weather-block">
                <WeatherLabel
                  className="px8 no-grow"
                  forecast={weatherForecast}
                  projectWeatherUnits={weatherUnits}
                  shouldHideProbabilityOfPrecipitation={true}
                  shouldHideWind={true}
                />
              </div>
            </div>
            {isGlobeMode && (
              <div
                className="mr10 br-rounded no-grow bg-white pa5 row x-center y-center map-icon small"
                onClick={() => toggleLayersMenu?.()}
              >
                <Icons.MoreLayerStylesSmall className="no-grow" />
              </div>
            )}
          </>
        ) : (
          <>
            <div
              className="text white-back current-map-name large text-ellipsis pa5 row mw-fit-content mr20"
              onClick={this.toggleSelectMenu}
            >
              <Icons.Sitemap className="no-grow" />
              <span className="ml5">{selectedMapName}</span>
            </div>

            <div className="text white-back arrows-holder large text-ellipsis pa5 mw-fit-content row">
              <div className="no-grow pl5" onClick={this.selectPrevMap}>
                <Icon icon={IconNames.CHEVRON_LEFT} />
              </div>
              <div className="text center">
                {selectedMapIndex} / {this.mapIds.length}
              </div>
              <div
                className="row reverse pr5 no-grow"
                onClick={this.selectNextMap}
              >
                <Icon icon={IconNames.CHEVRON_RIGHT} />
              </div>
            </div>
          </>
        )}
      </div>
    )
  }

  private toggleSelectMenu = () => {
    this.isSelectMenuShown = !this.isSelectMenuShown
  }

  private reassignMapsOrders = ({ oldIndex, newIndex }) => {
    const { eventsStore, eventName, sitemapsStore, globeViewsStore } =
      this.props

    if (oldIndex !== newIndex) {
      const indexedMapIds = arrayMove(this.mapIds, oldIndex, newIndex)

      const orderedMaps = indexedMapIds.map(
        ({ sitemapId, globeViewId }, index) => {
          return {
            sitemapId,
            globeViewId,
            order: index,
          }
        },
      )
      eventsStore.dispatch(eventName, {
        maps: orderedMaps,
      })
    } else {
      const globe = globeViewsStore.byId.get(this.mapIds[oldIndex].globeViewId)
      if (globe) {
        this.selectGlobe(globe)
      }
      const sitemap = sitemapsStore.byId.get(this.mapIds[oldIndex].sitemapId)
      if (sitemap) {
        this.selectWhiteboard(sitemap)
      }
    }

    this.onSlide(newIndex)
  }

  private selectGlobe = (globe: GlobeView) => {
    this.selectGlobeImageById(globe.id)
    this.isSelectMenuShown = false
  }

  private selectWhiteboard = (whiteboard: Sitemap) => {
    this.selectWhiteboardImageById(whiteboard.id)
    this.isSelectMenuShown = false
  }

  private selectGlobeImageById = (id: string) => {
    const index = this.mapIds.findIndex(mapId => mapId.globeViewId === id)
    this.onSlide(index)
  }

  private selectWhiteboardImageById = (id: string) => {
    const index = this.mapIds.findIndex(mapId => mapId.sitemapId === id)
    this.onSlide(index)
  }

  private onSlide = (currentIndex: number) => {
    const { selectSitemap, selectGlobe, sitemapsStore, globeViewsStore } =
      this.props
    if (this.currentMapIndex !== currentIndex) {
      const mapId = this.getMapIdByIndex(currentIndex)

      const globe = globeViewsStore.byId.get(mapId?.globeViewId)
      if (globe) {
        selectGlobe(globe)
      }
      const sitemap = sitemapsStore.byId.get(mapId?.sitemapId)
      if (sitemap) {
        selectSitemap(sitemap)
      }
    }
  }

  private getMapIdByIndex(index: number): IMapConfiguration {
    return this.mapIds[index]
  }

  private renderItem = () => {
    if (this.props.shouldDisableZoom) {
      return this.renderImage()
    }

    return (
      <PinchZoomPan
        maxScale={MAX_SCALE}
        minScale={MIN_SCALE}
        zoomButtons={false}
        position="center"
        doubleTapBehavior="zoom"
        onScaleChanged={this.onScaleChanged}
      >
        {this.renderImage()}
      </PinchZoomPan>
    )
  }

  private renderImage = () => {
    const { renderMapViewComponent } = this.props

    if (renderMapViewComponent) {
      return (
        <div className="full-width full-height selected-sitemap">
          {renderMapViewComponent()}
        </div>
      )
    }

    return <div />
  }

  private onScaleChanged = (scale: number) => {
    this.isGallerySwipingDisabled = scale > MIN_SCALE
  }

  private selectNextMap = () => {
    if (this.currentMapIndex === this.mapIds.length - 1) {
      this.onSlide(0)
      return
    }

    this.onSlide(this.currentMapIndex + 1)
  }

  private selectPrevMap = () => {
    if (this.currentMapIndex === 0) {
      this.onSlide(this.mapIds.length - 1)
      return
    }

    this.onSlide(this.currentMapIndex - 1)
  }
}
