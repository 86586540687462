import * as React from 'react'

import { classList } from 'react-classlist-helper'

import { SitePermitStatus } from '~/client/graph'
import { getFormStatusDisplayName } from '~/client/src/shared/localization/enumDisplayTexts'

interface IProps {
  status: SitePermitStatus
  workflowStepLevel: number
  className?: string
  shouldAlignStatusToEnd?: boolean
}

export default class FormStatusText extends React.Component<IProps> {
  public render() {
    const { status, workflowStepLevel, className, shouldAlignStatusToEnd } =
      this.props

    const statusName = getFormStatusDisplayName(status, workflowStepLevel)

    return (
      <div
        className={classList({
          [className]: !!className,
          'light-blue': [
            SitePermitStatus.Requested,
            SitePermitStatus.Submitted,
            SitePermitStatus.Changed,
          ].includes(status),
          blue: [SitePermitStatus.Accepted, SitePermitStatus.Reviewed].includes(
            status,
          ),
          yellow: [
            SitePermitStatus.ToInspect,
            SitePermitStatus.Passed,
          ].includes(status),
          orange: [SitePermitStatus.Active].includes(status),
          red: [
            SitePermitStatus.Denied,
            SitePermitStatus.Failed,
            SitePermitStatus.Deleted,
          ].includes(status),
          'success-green': [SitePermitStatus.BicInspected].includes(status),
          end: shouldAlignStatusToEnd,
          grey: [SitePermitStatus.Done, SitePermitStatus.Finished].includes(
            status,
          ),
        })}
        title={statusName}
      >
        {statusName}
      </div>
    )
  }
}
