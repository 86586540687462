import { action, computed, observable } from 'mobx'

import {
  CalendricalType,
  DefaultCompanyType,
  DeliveryType,
  IConcreteDirectPayload,
  IDelivery,
  IDeliveryAttribute,
  IDeliveryConfigurations,
  IDeliveryMaterial,
  ILocationClosureInterval,
  IMaterialProcurementData,
  ISiteLocation,
  LocationType,
  NonWorkTimesBlockTypeEnum,
  UploadingType,
} from '~/client/graph'
import { ISaveDeliveryVehicleTypesMutation } from '~/client/graph/operations/generated/DeliveryVehicleTypes.generated'
import { ISaveDeliveryUnitsMutation } from '~/client/graph/operations/generated/DelliveryUnits.generated'
import CalendarPlaceholderStore from '~/client/src/shared/components/CalendarDayView/components/CalendarPlaceholder.store'
import * as Icons from '~/client/src/shared/components/Icons'
import DeliveryStatus from '~/client/src/shared/constants/DeliveryStatus'
import DeliveryControlTypes from '~/client/src/shared/enums/DeliveryControlTypes'
import DeliveryDetailsSections, {
  LocationSection,
} from '~/client/src/shared/enums/DeliveryDetailsSections'
import FieldIds, {
  getFromSectionAnalogFieldId,
} from '~/client/src/shared/enums/DeliveryFieldIds'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Delivery from '~/client/src/shared/models/Delivery'
import IDeliveryControl, {
  IDeliveryControlOption,
  IDeliveryControlOptionCard,
  IDeliveryControlState,
} from '~/client/src/shared/models/IDeliveryControl'
import User from '~/client/src/shared/models/User'
import EventsStore from '~/client/src/shared/stores/EventStore/Events.store'
import { DELETE_DELIVERIES } from '~/client/src/shared/stores/EventStore/eventConstants'
import ActivitiesStore from '~/client/src/shared/stores/domain/Activities.store'
import CompaniesStore from '~/client/src/shared/stores/domain/Companies.store'
import DeliveryAssignmentsStore from '~/client/src/shared/stores/domain/DeliveryAssignments.store'
import DeliveryStatusChangesStore from '~/client/src/shared/stores/domain/DeliveryStatusChanges.store'
import DeliveryUnitsStore from '~/client/src/shared/stores/domain/DeliveryUnits.store'
import DeliveryVehicleTypesStore from '~/client/src/shared/stores/domain/DeliveryVehicleTypes.store'
import MaterialCategoryStore from '~/client/src/shared/stores/domain/MaterialCategories.store'
import MaterialsStore from '~/client/src/shared/stores/domain/Materials.store'
import MessagesStore from '~/client/src/shared/stores/domain/MessagesStore/Messages.store'
import PhotosStore from '~/client/src/shared/stores/domain/Photos.store'
import ThreadsStore from '~/client/src/shared/stores/domain/ThreadsStore/Threads.store'
import CommonStore from '~/client/src/shared/stores/ui/Common.store'
import DeliveryActionBarStore from '~/client/src/shared/stores/ui/DeliveryActionBar.store'
import DeliveryFilterStore from '~/client/src/shared/stores/ui/DeliveryFilter.store'
import IMsDateInterval from '~/client/src/shared/types/IMsDateInterval'
import {
  NO_DEADLINE_OPTION,
  getDeadlineOptionText,
} from '~/client/src/shared/utils/DeadlineOptions'
import Guard from '~/client/src/shared/utils/Guard'
import getDeliveryCopyWithNewStatus from '~/client/src/shared/utils/getDeliveryCopyWithNewStatus'
import {
  IPhoneNumber,
  getFormattedPhoneNumberForSubmission,
  getFormattedPhoneNumbersForSubmission,
  getRawPhoneNumbers,
  isPhoneNumber,
  isPhoneNumberValid,
  isPhoneNumberValidForSubmission,
} from '~/client/src/shared/utils/phoneNumberHelpers'
import {
  EMAIL_EXTRACTOR,
  PHONE_NUMBER_EXTRACTOR,
  VALID_EMAIL_PATTERN,
} from '~/client/src/shared/utils/regExpPatterns'

import getSortIndexByCompanyType, {
  getSortIndexForVendorField,
  sortCompaniesByTypeAndNamePredicate,
} from '../../constants/companyType'
import { deliveryFieldIdToModelPropertyNameMap as fieldIdToPropName } from '../../constants/fieldIdToDeliveryPropertyMap'
import { TagType } from '../../enums/TagType'
import CalendarEvent from '../../models/CalendarEvent'
import Company from '../../models/Company'
import ConcreteDirectOrder from '../../models/ConcreteDirectOrder'
import GlobeView from '../../models/GlobeView'
import LocationAttributeBase from '../../models/LocationObjects/LocationAttributeBase'
import LocationBase from '../../models/LocationObjects/LocationBase'
import OffloadingEquipment from '../../models/LocationObjects/OffloadingEquipment'
import Material from '../../models/Material'
import RecurringDeliveriesSettings from '../../models/RecurringDeliveriesSettings'
import ActivitiesDeliveryControlSearchPredicate from '../../models/SearchPredicate/ActivitiesDeliveryControlSearchPredicate'
import DeliveryControlSearchPredicateBase from '../../models/SearchPredicate/DeliveryControlSearchPredicateBase'
import Sitemap from '../../models/Sitemap'
import UserProject from '../../models/UserProject'
import ClosuresStore from '../../stores/domain/Closures.store'
import ConcreteDirectOrdersStore from '../../stores/domain/ConcreteDirectStores/ConcreteDirectOrders.store'
import ConcreteDirectPayloadsStore from '../../stores/domain/ConcreteDirectStores/ConcreteDirectPayloads.store'
import ConstraintsStore from '../../stores/domain/Constraints.store'
import DeliveriesStore from '../../stores/domain/Deliveries.store'
import DeliveryFollowingsStore from '../../stores/domain/DeliveryFollowings.store'
import DeliveryRestrictionsStore, {
  RestrictionAttributeKey,
} from '../../stores/domain/DeliveryRestrictions.store'
import { FileUploadingStore } from '../../stores/domain/FileUploading.store'
import GlobeViewsStore from '../../stores/domain/GlobeViews.store'
import GraphExecutorStore from '../../stores/domain/GraphExecutor.store'
import LocationAttributesStore from '../../stores/domain/LocationAttributes.store'
import ProjectMembersStore from '../../stores/domain/ProjectMembers.store'
import RecurringDeliveriesSettingsStore from '../../stores/domain/RecurringDeliveriesSettings.store'
import SitemapItemsStore from '../../stores/domain/SitemapItems.store'
import SitemapsStore from '../../stores/domain/Sitemaps.store'
import TagsStore, {
  deliveryRelatedLocationTagTypes,
} from '../../stores/domain/Tags.store'
import UserProjectsStore from '../../stores/domain/UserProjects.store'
import { DEFAULT_TIME_INTERVAL } from '../../stores/ui/CalendarView.store'
import ProjectDateStore, {
  DEFAULT_ISO_DATE_STRING,
  MILLISECONDS_IN_HOUR,
  START_OF_DAY,
  differenceInMilliseconds,
  isAfter,
  isBefore,
  isWithinRange,
  millisecondsToTime,
} from '../../stores/ui/ProjectDate.store'
import { getBandTitleByTagType } from '../../utils/TagHelper'
import { getFloorFromLevel } from '../../utils/floorFromLevelName'
import { EMPTY_STRING } from '../../utils/usefulStrings'
import {
  Primitives,
  areArraysEqual,
  customDebounce,
  diffArrays,
  leadingDebounce,
} from '../../utils/util'
import CalendarDayViewStore from '../CalendarDayView/CalendarDayView.store'
import DeliveriesViewStore from '../Deliveries/DeliveriesView.store'

const closed = 'Closed'
const onlyOpen = 'Only Open'
const reservedFor = 'Reserved for'
const timeLimitForItem = (name: string, duration: number) =>
  `Time limit for this ${name} is ${duration} hour(s)`
const thisDeliveryWasRequestedForNonWorkTime =
  'This delivery was requested for a non-work time'
const deliveryDateTimeIsBlocked = 'Delivery Date-time is Blocked'
const nonWorkDay = 'Non-work day'
const nonWorkHours = 'Non-work hours'
const noCompany = 'No company'
const enterValidEmailAddress = 'Enter a valid email address'

const RADIX = 10
const EMPTY_VALUE = ''

const COMPANY_SEPARATOR = '*'
interface IStatusAction {
  onClick: () => void
  isDisabled?: boolean
  isHidden?: boolean
  isActive?: boolean
  actionTitle: string
}
export interface IStatusStep {
  statuses: DeliveryStatus[]
  getTitle: () => string
}

export enum DeliveryDetailsMode {
  CREATE,
  CHANGE,
  VIEW,
  NONE,
}

export enum DeliveryDetailsActions {
  SHOW_FORM = 'show-form',
  CHANGE_INSIDE_BOOKING_DEADLINE = 'change-inside-booking-deadline',
  SIMPLIFIED_FORM_FILLING = 'simplified-form-filling',
  DELETE_DELIVERY = 'delete-delivery',
  SCHEDULE_DELIVERY = 'schedule-delivery',
  SHOW_DATE_CONFIRM_DIALOG = 'show-date-confirm-dialog',
  SHOW_NON_WORK_TIMES_DIALOG = 'show-non-work-times-dialog',
  MARK_REQUEST_AS_UNSCHEDULED = 'mark_request_as_unscheduled',
  DUPLICATE_DELIVERY = 'duplicate-delivery',
  CREATE_FROM_TO_DELIVERY = 'create-from-to-delivery',
  SELECT_RESTRICTED_ATTRS = 'select-restricted-attrs',
  CANCEL_DELIVERY = 'cancel-delivery',
}

export enum DeliveryDetailsTab {
  Form = 'form',
  Log = 'log',
}

export interface IMovedEventInfo {
  event: CalendarEvent
  startDate: Date
  endDate: Date
}

export type TimeFieldTypes = FieldIds.START_TIME | FieldIds.END_TIME

export const MIN_ISO_DATE = '1900-01-01'
export const MAX_ISO_DATE = '9999-12-31'
export const MIN_RECURRING_FREQUENCY = 1
export const MAX_RECURRING_DAYS_FREQUENCY = 20
export const MAX_RECURRING_WEEKS_FREQUENCY = 8
const MIN_MULTI_VALUE_COUNT = 1
export const MAX_MULTI_VALUE_COUNT = 300
export const FIELDS_AND_NOTE_SEPARATOR = '\\&iexcl;\\'
export const FIELD_LINES_SEPARATOR = '\\&brvbar;\\'
export const FIELD_VALUES_SEPARATOR = '\\&amp;\\'

const THRESHOLD_DEADLINE_HOURS = 1
const DEFAULT_MODE = DeliveryDetailsMode.NONE
const DEFAULT_TAB = DeliveryDetailsTab.Form

export const getFieldId = (fieldId: FieldIds, index: number) =>
  index ? `${fieldId}_${index}` : fieldId

const getFieldCategoryId = (
  fieldId: FieldIds,
  categoryId: string,
  index?: number,
) => `${getFieldId(fieldId, index)}_${categoryId}`

const dateFieldIds = [FieldIds.DATE, FieldIds.END_DATE]
const timeFieldIds = [FieldIds.START_TIME, FieldIds.END_TIME]

export default class DeliveryDetailsStore {
  public deliveryActionBarStore: DeliveryActionBarStore = null
  public deliveryRestrictionsStore: DeliveryRestrictionsStore = null

  public readonly deliveriesViewStore: DeliveriesViewStore = null
  private readonly deliveryFilterStore: DeliveryFilterStore = null

  public readonly statusSteps: IStatusStep[] = [
    {
      statuses: [
        DeliveryStatus.Requested,
        DeliveryStatus.Changed,
        DeliveryStatus.Denied,
      ],
      getTitle: () => Localization.translator.deliveryRequested,
    },
    {
      statuses: [DeliveryStatus.Scheduled],
      getTitle: () => Localization.translator.deliveryScheduled,
    },
    {
      statuses: [DeliveryStatus.OnSite],
      getTitle: () => Localization.translator.deliveryOnSite,
    },
    {
      statuses: [
        DeliveryStatus.FailedInspection,
        DeliveryStatus.PassedInspection,
      ],
      getTitle: () => Localization.translator.deliveryInspected,
    },
    {
      statuses: [
        DeliveryStatus.Done,
        DeliveryStatus.IncompleteDone,
        DeliveryStatus.Canceled,
      ],
      getTitle: () => Localization.translator.deliveryDone,
    },
  ]
  @observable public fieldsState = new Map<string, IDeliveryControlState>()
  @observable public initialArrayValues = new Map<FieldIds, Primitives[]>()
  @observable public mode: DeliveryDetailsMode = DEFAULT_MODE
  @observable public latestDeliveryByProjectAndUser: Delivery
  @observable public shouldScrollToBottom: boolean = false
  @observable public isDateChangeConfirmModalOpen: boolean = false
  @observable public isDateMoveConfirmModalOpen: boolean = false
  @observable public isDeleteConfirmModalOpen: boolean = false
  @observable public isCancelConfirmModalOpen: boolean = false
  @observable public isWorkflowModalOpen: boolean = false
  @observable public isGlobesGalleryShown: boolean = false
  @observable public isNonWorkTimesModalOpened: boolean = false
  @observable public isAddVendorTagModalOpened: boolean = false
  @observable public shouldBlockSubmitButton: boolean = false
  @observable public activeDetailsTab: DeliveryDetailsTab = DEFAULT_TAB
  @observable public isEditRecurringDialogShown: boolean = false

  @observable public recurringDialogTitle: string = null
  @observable public recurringDialogApplyFn: () => void = null
  @observable public recurringRelatedIds: string[] = []
  @observable public isRecurringDateModalOpened: boolean = false

  @observable public isSelectDateModalOpen: boolean = false
  @observable public selectedTimeControl: TimeFieldTypes = null

  private logPhotoUrls: { url: string; thumbUrl: string } = null

  public selectedVendor: string = null

  @observable private selectedFieldId: FieldIds = null
  @observable private selectedFieldIndex: number = null
  private movedEventInfo: IMovedEventInfo = null

  @observable private materialsSectionsCount: number = 1
  @observable private removedMaterials: Set<number> = new Set()

  @observable private _displayedDelivery: Delivery = null

  @observable private fieldCategoryCollapsingMap: Map<string, boolean> =
    new Map() // fieldId_categoryId : isCollapsed

  @action.bound
  public reinitFieldCategoryCollapsingMap(data: []) {
    try {
      this.fieldCategoryCollapsingMap = new Map(data)
    } catch {
      // to ensure that the map will be re-initialized even if data from localStorage is corrupted
      this.fieldCategoryCollapsingMap = new Map()
    }
  }

  public get displayedDelivery(): Delivery {
    return this._displayedDelivery
  }

  public set displayedDelivery(d: Delivery) {
    const { isMenuPopupShown, hideMenuPopup } = this.deliveryActionBarStore
    this._displayedDelivery = d
    if (isMenuPopupShown) {
      hideMenuPopup()
    }
  }

  public constructor(
    private readonly eventsStore: EventsStore,
    private readonly activitiesStore: ActivitiesStore,
    private readonly sitemapsStore: SitemapsStore,
    private readonly deliveryVehicleTypesStore: DeliveryVehicleTypesStore,
    private readonly deliveryUnitsStore: DeliveryUnitsStore,
    private readonly deliveryStatusChangesStore: DeliveryStatusChangesStore,
    private readonly calendarPlaceholderStore: CalendarPlaceholderStore,
    private readonly projectDateStore: ProjectDateStore,
    private readonly materialCategoryStore: MaterialCategoryStore,
    private readonly materialsStore: MaterialsStore,
    public readonly companiesStore: CompaniesStore,
    private readonly deliveriesStore: DeliveriesStore,
    threadsStore: ThreadsStore,
    messagesStore: MessagesStore,
    photosStore: PhotosStore,
    private readonly closuresStore: ClosuresStore,
    private readonly tagsStore: TagsStore,
    private readonly sitemapItemsStore: SitemapItemsStore,
    private fileUploadingStore: FileUploadingStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly projectMembersStore: ProjectMembersStore,
    graphExecutorStore: GraphExecutorStore,
    deliveryFollowingsStore: DeliveryFollowingsStore,
    constraintsStore: ConstraintsStore,
    private readonly locationAttributesStore: LocationAttributesStore,
    private readonly commonStore: CommonStore,
    private readonly deliveryAssignmentsStore: DeliveryAssignmentsStore,
    private readonly recurringSettingsStore: RecurringDeliveriesSettingsStore,
    private readonly concreteDirectOrdersStore: ConcreteDirectOrdersStore,
    private readonly concreteDirectPayloadsStore: ConcreteDirectPayloadsStore,
    private readonly globeViewsStore: GlobeViewsStore,
  ) {
    Guard.requireAll({
      eventsStore,
      locationAttributesStore,
      deliveryVehicleTypesStore,
      deliveryUnitsStore,
      deliveryStatusChangesStore,
      tagsStore,
      projectMembersStore,
      commonStore,
      deliveryAssignmentsStore,
    })

    this.deliveryActionBarStore = new DeliveryActionBarStore(
      deliveriesStore,
      eventsStore,
      threadsStore,
      messagesStore,
      photosStore,
      fileUploadingStore,
      userProjectsStore,
      tagsStore,
      deliveryFollowingsStore,
    )

    this.deliveryRestrictionsStore = new DeliveryRestrictionsStore(
      eventsStore.appState,
      locationAttributesStore,
      tagsStore,
      graphExecutorStore,
      constraintsStore,
    )

    this.deliveryFilterStore = new DeliveryFilterStore(
      eventsStore,
      deliveriesStore,
      true,
      locationAttributesStore,
      deliveryFollowingsStore,
      this.deliveryAssignmentsStore,
      this.companiesStore,
      projectMembersStore,
    )

    this.deliveriesViewStore = new DeliveriesViewStore(
      deliveriesStore,
      eventsStore,
      this.activitiesStore,
      this,
      this.commonStore,
      this.sitemapsStore,
      this.globeViewsStore,
      this.projectDateStore,
      locationAttributesStore,
      this.deliveryFilterStore,
      this.sitemapItemsStore,
      deliveryFollowingsStore,
      this.deliveryAssignmentsStore,
      this.companiesStore,
      deliveryVehicleTypesStore,
      this.materialCategoryStore,
      this.materialsStore,
      true,
      this.userProjectsStore,
      projectMembersStore,
      true,
    )

    this.onMultivalueFieldBlur = leadingDebounce(
      this.onMultivalueFieldBlur,
      300,
    )

    this.saveFieldCategoriesCollapsingState = customDebounce(
      this.saveFieldCategoriesCollapsingState,
      2000,
    )
  }

  @action.bound
  public switchActiveTab(newActiveTab: DeliveryDetailsTab) {
    this.activeDetailsTab = newActiveTab
  }

  @computed
  public get isFromConcreteDirect(): boolean {
    return this.displayedDelivery?.isFromConcreteDirect
  }

  @computed
  public get selectedConcreteDirectOrder(): ConcreteDirectOrder {
    if (this.isFromConcreteDirect) {
      return this.concreteDirectOrdersStore.getOrderById(
        this.displayedDelivery.concreteDirectOrderId,
      )
    }
  }

  @computed
  public get orderPayloads(): IConcreteDirectPayload[] {
    if (this.isFromConcreteDirect) {
      return this.concreteDirectPayloadsStore.getPayloadsByOrderId(
        this.displayedDelivery.concreteDirectOrderId,
      )
    }
  }

  @computed
  public get selectedField(): IDeliveryControl {
    return this.fields.find(({ id, index, isMultiValueInput }) => {
      if (id !== this.selectedFieldId) {
        return
      }

      const isNormalSelected =
        id === this.selectedFieldId &&
        (!this.selectedFieldIndex || index === this.selectedFieldIndex)
      const isMultiValueSelected =
        id === this.selectedFieldId &&
        isMultiValueInput &&
        this.selectedFieldIndex != undefined
      return isNormalSelected || isMultiValueSelected
    })
  }

  @computed
  public get sortedSelectedFieldOptions(): IDeliveryControlOption[] {
    if (!this.selectedField || !this.selectedField.options?.length) {
      return []
    }

    const { newOptionName, options, searchPredicate } = this.selectedField

    const filteredOptions = options
      .slice()
      .filter(option =>
        searchPredicate ? searchPredicate.test(option, newOptionName) : true,
      )

    if (this.selectedField.isGroupedOptions) {
      return filteredOptions
    }

    return filteredOptions.sort((o1, o2) => {
      if (o1.sortByHierarchyChains && o2.sortByHierarchyChains) {
        return o1.sortByHierarchyChains(o1, o2)
      }

      if (!o1.value) {
        return -1
      }

      if (!o2.value) {
        return 1
      }

      return o1.title.toLowerCase().localeCompare(o2.title.toLowerCase())
    })
  }

  public get isFromToModeEnabled(): boolean {
    return this.getFieldStateValue(FieldIds.FROM_TO_SWITCH)
  }

  @computed
  public get hasMaterialProcurementIds(): boolean {
    return this.materialsStore.hasProcurementIds
  }

  @computed
  public get fields(): IDeliveryControl[] {
    const {
      delivery: { hiddenFields },
      isTrackerDisabled,
    } = this.appState

    return [
      this.dateField,
      this.finishDateField,
      this.startTimeField,
      this.endTimeField,
      this.durationField,
      this.isRecurringOptionsShown && this.recurringOptionsField,
      this.weatherForecastField,
      this.lateRequestField,
      this.nonWorkTimeField,
      this.unscheduledRequestField,

      this.isFromToModeEnabled && this.fromToSwitch,

      // LOCATIONS
      !hiddenFields[FieldIds.BUILDING] && this.buildingField,
      !hiddenFields[FieldIds.ZONE] && this.zoneField,
      !hiddenFields[FieldIds.STAGING] && this.stagingField,
      !hiddenFields[FieldIds.LEVEL] && this.levelField,
      !hiddenFields[FieldIds.AREA] && this.areaField,
      !hiddenFields[FieldIds.ROUTE] && this.routeField,
      !hiddenFields[FieldIds.GATE] && this.gateField,
      !hiddenFields[FieldIds.INTERIOR_PATH] && this.interiorPathField,
      !hiddenFields[FieldIds.INTERIOR_DOOR] && this.interiorDoorField,
      !hiddenFields[FieldIds.INSTALLATION_ZONE] && this.installationZoneField,
      // ---

      this.siteMapsField,
      this.globesField,

      this.requesterField,
      !hiddenFields[FieldIds.COMPANY] && this.companyField,
      !hiddenFields[FieldIds.ON_SITE_CONTACTS] &&
        this.onSiteContactPersonFields,

      ...(!hiddenFields[FieldIds.MATERIALS_SECTION] ? this.materialFields : []),

      ...(this.isFromToModeEnabled
        ? [
            !hiddenFields[FieldIds.BUILDING] && this.fromBuildingField,
            !hiddenFields[FieldIds.ZONE] && this.fromZoneField,
            !hiddenFields[FieldIds.STAGING] && this.fromStagingField,
            !hiddenFields[FieldIds.LEVEL] && this.fromLevelField,
            !hiddenFields[FieldIds.AREA] && this.fromAreaField,
            !hiddenFields[FieldIds.INSTALLATION_ZONE] &&
              this.fromInstallationZoneField,
          ]
        : [
            !hiddenFields[FieldIds.TRUCK_SIZE] && this.deliveryVehicleTypeField,
            !hiddenFields[FieldIds.TRUCK_LICENSE_PLATE] &&
              this.truckLicensePlateField,
            !hiddenFields[FieldIds.TRUCK_NUMBER] && this.numberOfVehiclesField,
            !hiddenFields[FieldIds.DRIVER_PHONE_NUMBERS] &&
              this.driverPhoneNumberFields,
            !hiddenFields[FieldIds.VENDOR] && this.vendorCompanyField,
            !hiddenFields[FieldIds.VENDOR_EMAILS] && this.vendorEmailFields,
            ...(!hiddenFields[FieldIds.INSPECTION_SECTION]
              ? [this.isInspectionField, this.inspectorField]
              : []),
          ]),

      !hiddenFields[FieldIds.OFFLOADING_EQUIPMENT] &&
        this.offloadingEquipmentField,
      !hiddenFields[FieldIds.NUMBER_OF_EQUIPMENT_PICKS] &&
        this.numberOfEquipmentPicksField,

      !hiddenFields[FieldIds.NOTE] && this.noteField,
      !isTrackerDisabled &&
        !hiddenFields[FieldIds.ACTIVITY_ID] &&
        this.activityIdField,
    ].filter(f => !!f)
  }

  public showSitemapsGallery = () => {
    this.isGlobesGalleryShown = true
  }

  public hideSitemapsGallery = () => {
    this.isGlobesGalleryShown = false
  }

  public onDeleteAction = () => {
    if (!this.canEnableRecurringDialog) {
      return this.toggleDeleteConfirmModal()
    }

    this.onButtonClick(this.deleteDelivery, Localization.translator.delete)
  }

  @computed
  public get isRecurringOptionsChanged(): boolean {
    return this.isRecurringOptionsShown && this.recurringOptionsField.isChanged
  }

  @computed
  public get areFollowingRecurringsSelected(): boolean {
    return (
      !!this.recurringRelatedIds?.length &&
      this.recurringRelatedIds.length ===
        this.followingRecurringDeliveries.length
    )
  }

  @computed
  public get overlappedAttrByRecurringDeliveries(): LocationAttributeBase {
    if (
      this.userProject.isSuperUser ||
      !this.displayedDelivery?.recurringSettingId
    ) {
      return null
    }

    const { getISOTime, getDashedFormattedDate } = this.projectDateStore
    const { startDate: selectedStart, endDate: selectedEnd } =
      this.selectedDates || {}
    const firstRecurringDeliveryStart = this.allRecurringDeliveries.reduce(
      (start, curr) => (start < curr.startDate ? start : curr.startDate),
      selectedStart.getTime(),
    )
    const isoStartTime = getISOTime(selectedStart)
    const isoEndTime = getISOTime(selectedEnd)
    const isoDate = getDashedFormattedDate(
      this.areFollowingRecurringsSelected
        ? selectedStart
        : firstRecurringDeliveryStart,
    )
    const { startDate, endDate } = this.getDates(
      isoDate,
      isoDate,
      isoStartTime,
      isoEndTime,
    )
    const recurringDates = this.getRecurringDeliveryDates(
      startDate?.getTime(),
      endDate?.getTime(),
    )

    if (!recurringDates?.length) {
      return null
    }

    const dto = this.getDeliveryDtoFromFields()
    const delivery = Delivery.fromDto(dto)

    const building =
      this.buildingOptions.some(o => delivery.isBuildingId(o.value)) &&
      this.locationAttributesStore.buildingsStore.byId.get(delivery.building)
    const zone =
      this.getZonesOptions().some(o => delivery.isZoneId(o.value)) &&
      this.locationAttributesStore.zonesStore.byId.get(delivery.zone)
    const gate =
      this.getGatesOptions().some(o => delivery.isGateId(o.value)) &&
      this.locationAttributesStore.gatesStore.byId.get(delivery.gate)
    const level =
      this.getLevelOptions().some(o => delivery.isLevelId(o.value)) &&
      this.locationAttributesStore.levelsStore.byId.get(delivery.level)
    const equipments = delivery.offloadingEquipmentIds
      .map(
        id =>
          this.offloadingEquipmentOptions.some(o => id === o.value) &&
          this.locationAttributesStore.offloadingEquipmentsStore.byId.get(id),
      )
      .filter(e => e)
    const area =
      this.getAreaOptions().some(o => delivery.isArealId(o.value)) &&
      this.locationAttributesStore.areasStore.byId.get(delivery.area)
    const route =
      this.getRouteOptions().some(o => delivery.isRouteId(o.value)) &&
      this.locationAttributesStore.routesStore.byId.get(delivery.route)

    const deliveryLocations: Array<{
      propName: string
      item: LocationAttributeBase
    }> = [
      { propName: FieldIds.BUILDING, item: building },
      { propName: FieldIds.ZONE, item: zone },
      { propName: FieldIds.GATE, item: gate },
      { propName: FieldIds.LEVEL, item: level },
      ...equipments.map(eq => ({
        propName: FieldIds.OFFLOADING_EQUIPMENT,
        item: eq,
      })),
      { propName: FieldIds.AREA, item: area },
      { propName: FieldIds.ROUTE, item: route },
    ].filter(
      i =>
        !!i.item &&
        (this.isRecurringOptionsChanged || this.isFieldChanged(i.propName)),
    )

    return deliveryLocations.find(
      ({ propName, item }) =>
        !!item.maxOverlappingDeliveries &&
        item.maxOverlappingDeliveries <=
          this.getDeliveriesCountFromDates(
            fieldIdToPropName[propName],
            item.id,
            recurringDates,
          ),
    )?.item
  }

  @computed
  public get isRecurringOptionsShown(): boolean {
    if (!isAfter(this.selectedDates?.endDate, Date.now())) {
      return false
    }

    return (
      this.isCreateModeActive || !!this.displayedDelivery?.recurringSettingId
    )
  }

  public get calendarStore(): CalendarDayViewStore {
    return this.deliveriesViewStore.calendarStore
  }

  public get allRecurringDeliveries(): Delivery[] {
    if (!this.displayedDelivery?.recurringSettingId) {
      return []
    }

    const now = Date.now()

    return this.deliveriesStore.availableDeliveries.filter(
      d =>
        d.recurringSettingId === this.displayedDelivery.recurringSettingId &&
        isAfter(d.endDate, now),
    )
  }

  public get followingRecurringDeliveries(): Delivery[] {
    return this.allRecurringDeliveries.filter(
      d =>
        d.startDate > this.selectedDates.startDate.getTime() ||
        this.displayedDelivery.id === d.id,
    )
  }

  public get canEnableRecurringDialog(): boolean {
    return (
      this.isRecurringOptionsShown &&
      !this.isOnlyDateChanged &&
      !!this.displayedDelivery?.recurringSettingId &&
      this.allRecurringDeliveries.length > 1
    )
  }

  @computed
  public get deliveriesGlobes(): GlobeView[] {
    const { maps } = this.appState.delivery.configurations
    return this.globeViewsStore.list.filter(g =>
      maps.some(map => map.globeViewId === g.id),
    )
  }

  @computed
  public get deliveriesSitemaps(): Sitemap[] {
    const { maps } = this.appState.delivery.configurations
    return this.sitemapsStore.list.filter(s =>
      maps.some(map => map.sitemapId === s.id),
    )
  }

  public get hasDeliveriesSitemaps(): boolean {
    return !!this.deliveriesGlobes.length
  }

  @computed
  public get displayedDeliveryFields() {
    if (!this.displayedDelivery) {
      return {}
    }
    const deliveryFields = this.displayedDelivery.toFields(
      this.projectDateStore,
    )

    return Object.assign(deliveryFields, this.displayedDeliveryMaterialsFields)
  }

  @computed
  public get displayedDeliveryMaterialsFields(): { [fieldId: string]: string } {
    return this.getMaterialsAsFields(this.displayedDelivery?.materials)
  }

  @action.bound
  public toggleDeleteConfirmModal() {
    this.isDeleteConfirmModalOpen = !this.isDeleteConfirmModalOpen
  }

  @action.bound
  public toggleCancelConfirmModal() {
    this.isCancelConfirmModalOpen = !this.isCancelConfirmModalOpen
  }

  @action.bound
  public selectCalendarPeriod(date: Date) {
    this.deliveriesViewStore.selectDatePeriod(date)
  }

  public loadRecurringDeliveriesSettings = () => {
    if (this.isRecurringOptionsShown) {
      this.recurringSettingsStore.loadRecurringSetting(
        this.displayedDelivery?.recurringSettingId,
      )
    }
  }

  public loadAndListenCdData = () => {
    if (this.isFromConcreteDirect) {
      this.concreteDirectOrdersStore.loadAndListenOrder(
        this.displayedDelivery?.concreteDirectOrderId,
      )
      this.concreteDirectPayloadsStore.loadAndListenPayloads(
        this.displayedDelivery?.concreteDirectOrderId,
      )
    }
  }

  public terminateCdData = () => {
    this.concreteDirectOrdersStore.terminateSubscription()
    this.concreteDirectPayloadsStore.terminateSubscription()
    this.concreteDirectOrdersStore.clearData()
    this.concreteDirectPayloadsStore.clearData()
  }

  public updateDeliveryWithMaterials = (
    deliveryMaterials: IDeliveryMaterial[],
    index: number = 0,
  ) => {
    if (this.isMaterialsSectionHidden) {
      return
    }

    // Reset previous values to default ones
    this.updateMaterialFieldsValues(index)
    this.setMaterialsSectionsCount(index + deliveryMaterials.length)

    const prepopulatedMatFields = this.getMaterialsAsFields(
      deliveryMaterials,
      index,
    )

    this.updateMaterialFieldsValues(index, prepopulatedMatFields)

    if (this.isCreateModeActive) {
      this.updateFieldsByCachedValues()
    }
  }

  @action.bound
  public onButtonClick(onClick: () => void, title: string) {
    this.clearRecurringRelatedIds()

    if (!this.canEnableRecurringDialog) {
      return onClick()
    }

    this.showEditRecurringDialog()
    this.setRecurringDialogApplyBtnFn(onClick)

    this.recurringDialogTitle = title
  }

  @action.bound
  public showEditRecurringDialog() {
    if (this.canEnableRecurringDialog) {
      this.isEditRecurringDialogShown = true
    }
  }

  @action.bound
  public hideEditRecurringDialog() {
    this.isEditRecurringDialogShown = false
    this.recurringDialogTitle = null
  }

  @action.bound
  public setRecurringDialogApplyBtnFn(applyFn: () => void) {
    this.recurringDialogApplyFn = applyFn
  }

  @action.bound
  public setRecurringRelatedIds(shouldApplyToAllDeliveries?: boolean) {
    if (!this.displayedDelivery?.recurringSettingId) {
      return
    }

    this.recurringRelatedIds = (
      shouldApplyToAllDeliveries
        ? this.allRecurringDeliveries
        : this.followingRecurringDeliveries
    ).map(d => d.id)
  }

  @action.bound
  public clearRecurringRelatedIds() {
    this.recurringRelatedIds = []
  }

  @action.bound
  public toggleWorkflowModal() {
    this.isWorkflowModalOpen = !this.isWorkflowModalOpen
  }

  @action.bound
  public hideWorkflowModal() {
    this.isWorkflowModalOpen = false
  }

  @action.bound
  public openDateChangeConfirmModalIfNeed() {
    this.isDateChangeConfirmModalOpen =
      this.isInsideDeadline &&
      !this.wasAlreadyMarkedAsLateRequest &&
      this.hasUserPermissions(DeliveryDetailsActions.SHOW_DATE_CONFIRM_DIALOG)
  }

  @action.bound
  public showSelectDateModal() {
    this.isSelectDateModalOpen = true
  }

  @action.bound
  public closeSelectDateModal() {
    this.isSelectDateModalOpen = false
    this.isRecurringDateModalOpened = false
  }

  @action.bound
  public showRecurringDateModal() {
    this.showSelectDateModal()
    this.isRecurringDateModalOpened = true
  }

  @action.bound
  public openNonWorkTimeModalIfNeed() {
    this.isNonWorkTimesModalOpened =
      !this.isNonWorkTimesHidden &&
      !this.wasAlreadyMarkedAsNonWorkTime &&
      !this.isInsideWorkTime &&
      this.hasUserPermissions(DeliveryDetailsActions.SHOW_NON_WORK_TIMES_DIALOG)
  }

  @action.bound
  public closeNonWorkTimeModal() {
    this.isNonWorkTimesModalOpened = false
  }

  @computed
  public get dateSelectorStartDate(): number {
    return this.isRecurringDateModalOpened
      ? this.recurringOptionsField.value?.endDate
      : this.displayedDelivery?.startDate
  }

  @computed
  public get dateSelectorEndDate(): number {
    return this.isRecurringDateModalOpened
      ? this.recurringOptionsField.value?.endDate
      : this.displayedDelivery?.endDate
  }

  public get shouldShowUnscheduledControl() {
    return (
      !this.isDoneDelivery &&
      !this.isCanceledDelivery &&
      (this.shouldBeMarkedAsLateRequest ||
        this.wasAlreadyMarkedAsLateRequest) &&
      this.hasUserPermissions(
        DeliveryDetailsActions.MARK_REQUEST_AS_UNSCHEDULED,
      )
    )
  }

  private get canUnblockSubmitBtn(): boolean {
    if (this.shouldBlockLateRequesting && this.shouldBlockOnNonWorkTimes) {
      return (
        !this.wasAlreadyMarkedAsLateRequest &&
        !this.wasAlreadyMarkedAsNonWorkTime
      )
    }

    if (!this.shouldBlockLateRequesting && this.shouldBlockOnNonWorkTimes) {
      return !this.wasAlreadyMarkedAsNonWorkTime
    }

    if (this.shouldBlockLateRequesting && !this.shouldBlockOnNonWorkTimes) {
      return !this.wasAlreadyMarkedAsLateRequest
    }

    return true
  }

  public get wasAlreadyMarkedAsLateRequest() {
    return !!this.lateRequestField.value
  }

  public get wasAlreadyMarkedAsNonWorkTime() {
    return !!this.nonWorkTimeField.value
  }

  public get areNonWorkAndDeadlineModalsOpened(): boolean {
    return this.isDateChangeConfirmModalOpen && this.isNonWorkTimesModalOpened
  }

  public get isAnyDialogOpened(): boolean {
    return (
      this.isDateChangeConfirmModalOpen ||
      this.isNonWorkTimesModalOpened ||
      this.isDeleteConfirmModalOpen ||
      this.isCancelConfirmModalOpen ||
      this.isWorkflowModalOpen
    )
  }

  public get nonWorkTimeAndDeadlineTitle(): string {
    return this.areNonWorkAndDeadlineModalsOpened && deliveryDateTimeIsBlocked
  }

  public get nonWorkTimeAndDeadlineMessage(): string {
    const { deadlineInterval } = this.deliveryConfigurations

    return (
      this.areNonWorkAndDeadlineModalsOpened &&
      Localization.translator.deliveryDescriptions.nonWorkTimeDateChange(
        deadlineInterval,
      )
    )
  }

  public checkIfDeliveryAttributesValid = (checkIfAvailable = true) => {
    this.checkIfSelectedObjectOpened(this.buildingField, checkIfAvailable)
    this.checkIfSelectedObjectOpened(this.routeField, checkIfAvailable)
    this.checkIfSelectedObjectOpened(this.areaField, checkIfAvailable)
    this.checkIfSelectedObjectOpened(this.gateField, checkIfAvailable)
    this.checkIfSelectedObjectOpened(this.zoneField, checkIfAvailable)
    this.checkIfSelectedObjectOpened(this.levelField, checkIfAvailable)
    this.checkIfSelectedObjectOpened(
      this.deliveryVehicleTypeField,
      checkIfAvailable,
    )

    this.checkIfSelectedEquipmentOpened(checkIfAvailable)

    this.updateFieldStateValue(
      FieldIds.SITE_MAPS,
      this.sitemapsDefaultValue.map(s => s.filledImage),
    )
    this.updateFieldStateValue(
      FieldIds.GLOBES,
      this.globesDefaultValue.map(g => g.filledImage),
    )
  }

  @action.bound
  public closeDateChangeConfirmModal() {
    this.isDateChangeConfirmModalOpen = false
  }

  @action.bound
  public showLateRequestLabel() {
    this.updateFieldStateValue(FieldIds.LATE_REQUEST, true)
  }

  @action.bound
  public hideLateRequestLabel() {
    this.updateFieldStateValue(FieldIds.LATE_REQUEST, false)
  }

  @action.bound
  public showNonWorkTimeLabel() {
    this.updateFieldStateValue(FieldIds.NON_WORK_TIMES, true)
  }

  @action.bound
  public hideNonWorkTimeLabel() {
    this.updateFieldStateValue(FieldIds.NON_WORK_TIMES, false)
  }

  @action.bound
  public blockSubmitAndShowNonWorkLabel() {
    this.blockSubmitButton()
    this.showNonWorkTimeLabel()
  }

  @action.bound
  public blockSubmitButton() {
    this.shouldBlockSubmitButton = true
  }

  @action.bound
  public unblockSubmitButton() {
    this.shouldBlockSubmitButton = false
  }

  @action.bound
  public moveDateForward() {
    const {
      combineISODateTime,
      addDays,
      addWorkingDays,
      addHours,
      countDaysToDate,
      getHours,
      getDashedFormattedDate,
    } = this.projectDateStore

    const selectedStartDate = combineISODateTime(
      this.dateField.value,
      this.startTimeField.value,
    )
    const now = new Date()

    const targetHoursDate = addHours(now, THRESHOLD_DEADLINE_HOURS)
    const deltaInHours = getHours(targetHoursDate) - getHours(selectedStartDate)

    const totalDaysToAdd =
      this.deliveryConfigurations.deadlineInterval + (deltaInHours > 0 ? 1 : 0)

    const targetStartDate = addWorkingDays(targetHoursDate, totalDaysToAdd)
    const deltaInDays = countDaysToDate(selectedStartDate, targetStartDate)

    const newStartDate = addHours(
      addDays(selectedStartDate, deltaInDays),
      deltaInHours,
    )

    this.onDateTimeChange(FieldIds.DATE, getDashedFormattedDate(newStartDate))
  }

  @action.bound
  public updateFieldsByCachedValues() {
    if (this.latestDeliveryByProjectAndUser) {
      const { onSiteContactPersonIds, vendorEmails, vendor } =
        this.latestDeliveryByProjectAndUser

      const newContactsFromCache: string[] = [
        ...(onSiteContactPersonIds || this.onSiteContactPersonFields.value),
      ].filter(id =>
        this.projectMembersStore.hasUserAccessToActiveProjectById(id),
      )

      this.updateFieldStateValue(
        FieldIds.ON_SITE_CONTACTS,
        newContactsFromCache,
      )

      const newVendorEmailsFromCache: string[] = [
        ...(vendorEmails || this.vendorEmailFields.value),
      ]

      this.updateFieldStateValue(
        FieldIds.VENDOR_EMAILS,
        newVendorEmailsFromCache,
      )
      this.updateFieldStateValue(
        FieldIds.VENDOR,
        vendor || this.vendorCompanyField.value,
      )
    }
  }

  public updateMaterialsRelatedFields = () => {
    this.fields.forEach(field => {
      const value =
        this.displayedDeliveryMaterialsFields[getFieldId(field.id, field.index)]

      if (value) {
        this.updateFieldStateValue(field.id, value, field.index)
      }
    })
  }

  @computed
  public get isExpandedSelectDateMode(): boolean {
    const { startDate, endDate } = this.selectedDates || {}

    if (!startDate || !endDate) {
      return false
    }

    return !this.projectDateStore.isSameDay(startDate, endDate)
  }

  @computed
  public get isInspectionRequired(): boolean {
    return !!Number(this.isInspectionField.value)
  }

  public get canDeleteDelivery(): boolean {
    if (
      !this.displayedDelivery ||
      this.isDeliveryDeleted ||
      this.isDoneDelivery ||
      this.isFromConcreteDirect ||
      !this.canChangeDeliveryInBookingDeadline
    ) {
      return false
    }

    return this.hasUserPermissions(DeliveryDetailsActions.DELETE_DELIVERY)
  }

  public get shouldShowMoreMenu(): boolean {
    return (
      !!this.displayedDelivery &&
      this.hasUserPermissions(DeliveryDetailsActions.SHOW_FORM)
    )
  }

  public get canUserInteractWithFields(): boolean {
    return (
      !this.displayedDelivery ||
      (this.hasUserPermissions(DeliveryDetailsActions.SHOW_FORM) &&
        !this.isCanceledDelivery &&
        !this.isDoneDelivery)
    )
  }

  public get canChangeDeliveryInBookingDeadline(): boolean {
    return this.hasUserPermissions(
      DeliveryDetailsActions.CHANGE_INSIDE_BOOKING_DEADLINE,
    )
  }

  public get canCancelDelivery(): boolean {
    if (!this.displayedDelivery || this.displayedDelivery.isCanceled) {
      return false
    }

    return this.hasUserPermissions(DeliveryDetailsActions.CANCEL_DELIVERY)
  }

  public get isDeliveryDeleted(): boolean {
    if (!this.displayedDelivery) {
      return false
    }

    return this.displayedDelivery.status === DeliveryStatus.Deleted
  }

  public get isCanceledDelivery(): boolean {
    return this.displayedDelivery?.isCanceled
  }

  public get isDoneDelivery(): boolean {
    return this.displayedDelivery?.isDone
  }

  public get canDuplicateDelivery(): boolean {
    if (
      !this.displayedDelivery ||
      this.isDeliveryDeleted ||
      this.isCanceledDelivery ||
      this.isFromConcreteDirect
    ) {
      return false
    }

    return this.hasUserPermissions(DeliveryDetailsActions.DUPLICATE_DELIVERY)
  }

  public get canCreateFromToDelivery(): boolean {
    if (
      this.appState.delivery.hiddenFields[FieldIds.FROM_TO_SWITCH] ||
      this.isFromToModeEnabled ||
      !this.displayedDelivery ||
      this.isDeliveryDeleted ||
      this.isFromConcreteDirect ||
      // Delivery status not equal Done, OnSite or PassedInspection
      (!this.isDoneDelivery &&
        this.displayedDelivery.status !== DeliveryStatus.OnSite &&
        this.displayedDelivery.status !== DeliveryStatus.PassedInspection)
    ) {
      return false
    }

    return this.hasUserPermissions(
      DeliveryDetailsActions.CREATE_FROM_TO_DELIVERY,
    )
  }

  public get requesterId(): string {
    return this.appState.user?.id || null
  }

  public get userCompanyId(): string {
    const company = this.companiesStore.getCompanyById(
      this.userProject.companyId,
      true,
    )

    return company?.id || ''
  }

  public get deliveryConfigurations(): IDeliveryConfigurations {
    return (
      this.appState.delivery.configurations || ({} as IDeliveryConfigurations)
    )
  }

  public get defaultDeliveryDuration() {
    return this.deliveryConfigurations.deliveryDuration || DEFAULT_TIME_INTERVAL
  }

  public get shouldBlockOnNonWorkTimes(): boolean {
    return (
      this.deliveryConfigurations?.nonWorkTimesBlockType ===
      NonWorkTimesBlockTypeEnum.Block
    )
  }

  public get isNonWorkTimesHidden(): boolean {
    return (
      this.deliveryConfigurations.nonWorkTimesBlockType ===
      NonWorkTimesBlockTypeEnum.Hide
    )
  }

  public get shouldBlockLateRequesting(): boolean {
    return this.deliveryConfigurations?.shouldBlockLateRequesting
  }

  public get isCancelationReasonHidden(): boolean {
    return this.appState.delivery.hiddenFields[FieldIds.CANCELATION_REASON]
  }

  public get isCancelationReasonMandatory(): boolean {
    return this.appState.delivery.mandatoryFields[FieldIds.CANCELATION_REASON]
  }

  public getCompanyById = (companyId: string): Company => {
    return this.companiesStore.getCompanyById(companyId)
  }

  public updateDurationField = () => {
    const isoStartDate = this.getValue(FieldIds.DATE)
    const isoEndDate = this.getValue(FieldIds.END_DATE)
    const startTime = this.getValue(FieldIds.START_TIME)
    const endTime = this.getValue(FieldIds.END_TIME)

    const { startDate, endDate } = this.getDates(
      isoStartDate,
      isoEndDate,
      startTime,
      endTime,
    )

    const duration = differenceInMilliseconds(endDate, startDate)
    this.updateFieldStateValue(FieldIds.DURATION, millisecondsToTime(duration))
  }

  @computed
  public get selectedDates() {
    const startTime = this.getFieldStateValue(FieldIds.START_TIME)
    const endTime = this.getFieldStateValue(FieldIds.END_TIME)
    const isoStartDate = this.getFieldStateValue(FieldIds.DATE)
    const isoEndDate = this.getFieldStateValue(FieldIds.END_DATE)

    if (isoStartDate && startTime && endTime) {
      return this.getDates(isoStartDate, isoEndDate, startTime, endTime)
    }
  }

  @computed
  public get selectedTimes() {
    const { setHours, convertFromProjectDate, getHoursMinutesFromISOTime } =
      this.projectDateStore

    const deliveryStartTime: string = this.getFieldStateValue(
      FieldIds.START_TIME,
      EMPTY_VALUE,
    )
    const deliveryEndTime: string = this.getFieldStateValue(
      FieldIds.END_TIME,
      EMPTY_VALUE,
    )

    const now = new Date()
    let startTime: Date
    let endTime: Date

    if (deliveryStartTime) {
      const [hours, minutes] = getHoursMinutesFromISOTime(deliveryStartTime)
      startTime = convertFromProjectDate(setHours(now, hours, minutes))
    }

    if (deliveryEndTime) {
      const [hours, minutes] = getHoursMinutesFromISOTime(deliveryEndTime)
      endTime = convertFromProjectDate(setHours(now, hours, minutes))
    }

    return { startTime, endTime }
  }

  private getZonesOptions(buildingFieldId: string = this.buildingField.value) {
    const { list, byId } = this.locationAttributesStore.zonesStore
    let zones = this.setClosedOptions(
      list.slice(),
      fieldIdToPropName[FieldIds.ZONE],
      this.sortByParentAndName,
    )
    zones = this.deliveryRestrictionsStore.filterZonesByBuilding(
      buildingFieldId,
      zones,
    )

    if (
      this.displayedDelivery &&
      (this.displayedDelivery.isDone ||
        this.projectDateStore.isBeforeToday(
          this.displayedDelivery.startDate,
        )) &&
      !zones.some(zone => this.displayedDelivery.isZoneId(zone.id)) &&
      byId.get(this.displayedDelivery.zone)
    ) {
      zones.push(byId.get(this.displayedDelivery.zone))
    }

    return this.getDropDownOptions({
      list: zones,
      isDefaultOptionHidden: false,
      hasRelatedAttribute: true,
    })
  }

  @computed
  private get offloadingEquipmentOptions() {
    const { byId } = this.locationAttributesStore.offloadingEquipmentsStore
    const filteredEquipments = this.filterEquipmentsByLevel(
      this.levelField.value,
    )

    let equipments = this.setClosedOptions(
      filteredEquipments.slice(),
      fieldIdToPropName[FieldIds.OFFLOADING_EQUIPMENT],
      this.sortByParentAndName,
    )

    equipments =
      this.deliveryRestrictionsStore.filterAttributesByZoneAndBuilding(
        equipments,
        RestrictionAttributeKey.RestrictedEquipment,
        this.buildingField.value,
        this.zoneField.value,
      )

    if (
      !this.displayedDelivery ||
      (!this.displayedDelivery.isDone &&
        !this.projectDateStore.isBeforeToday(this.displayedDelivery.startDate))
    ) {
      return this.getDropDownOptions({
        list: equipments,
        isDefaultOptionHidden: false,
        hasRelatedAttribute: true,
      })
    }
    this.displayedDelivery.offloadingEquipmentIds.forEach(equipmentId => {
      if (
        !equipments.some(equipment => equipment.id === equipmentId) &&
        byId.get(equipmentId)
      ) {
        equipments.push(byId.get(equipmentId))
      }
    })

    return this.getDropDownOptions({
      list: equipments,
      isDefaultOptionHidden: false,
      hasRelatedAttribute: true,
    })
  }

  private getGatesOptions(
    buildingFieldId: string = this.buildingField.value,
    zoneFieldId: string = this.zoneField.value,
  ) {
    const { list, byId } = this.locationAttributesStore.gatesStore
    let gatesList = this.setClosedOptions(
      list.slice(),
      fieldIdToPropName[FieldIds.GATE],
      this.sortByParentAndName,
    )

    gatesList =
      this.deliveryRestrictionsStore.filterAttributesByZoneAndBuilding(
        gatesList,
        RestrictionAttributeKey.RestrictedGates,
        buildingFieldId,
        zoneFieldId,
      )

    if (
      this.displayedDelivery &&
      (this.displayedDelivery.isDone ||
        this.projectDateStore.isBeforeToday(
          this.displayedDelivery.startDate,
        )) &&
      !gatesList.some(gate => this.displayedDelivery.isGateId(gate.id)) &&
      byId.get(this.displayedDelivery.gate)
    ) {
      gatesList.push(byId.get(this.displayedDelivery.gate))
    }

    return this.getDropDownOptions({
      list: gatesList,
      isDefaultOptionHidden: false,
      hasRelatedAttribute: true,
    })
  }

  private getStagingOptions(
    buildingFieldId: string = this.buildingField.value,
    zoneFieldId: string = this.zoneField.value,
  ) {
    const { list, byId } = this.locationAttributesStore.stagingsStore
    let stagingList = this.setClosedOptions(
      list.slice(),
      fieldIdToPropName[FieldIds.STAGING],
      this.sortByParentAndName,
    )

    stagingList =
      this.deliveryRestrictionsStore.filterAttributesByZoneAndBuilding(
        stagingList,
        RestrictionAttributeKey.RestrictedStagings,
        buildingFieldId,
        zoneFieldId,
      )

    if (
      this.displayedDelivery &&
      (this.displayedDelivery.isDone ||
        this.projectDateStore.isBeforeToday(
          this.displayedDelivery.startDate,
        )) &&
      !stagingList.some(staging =>
        this.displayedDelivery.isStagingId(staging.id),
      ) &&
      byId.get(this.displayedDelivery.staging)
    ) {
      stagingList.push(byId.get(this.displayedDelivery.staging))
    }

    return this.getDropDownOptions({
      list: stagingList,
      isDefaultOptionHidden: false,
      hasRelatedAttribute: true,
    })
  }

  private getInteriorPathOptions(
    buildingFieldId: string = this.buildingField.value,
    zoneFieldId: string = this.zoneField.value,
  ) {
    const { list, byId } = this.locationAttributesStore.interiorPathsStore
    let interiorPathsList = this.setClosedOptions(
      list.slice(),
      fieldIdToPropName[FieldIds.INTERIOR_PATH],
      this.sortByParentAndName,
    )

    interiorPathsList =
      this.deliveryRestrictionsStore.filterAttributesByZoneAndBuilding(
        interiorPathsList,
        RestrictionAttributeKey.RestrictedInteriorPaths,
        buildingFieldId,
        zoneFieldId,
      )

    if (
      this.displayedDelivery &&
      (this.displayedDelivery.isDone ||
        this.projectDateStore.isBeforeToday(
          this.displayedDelivery.startDate,
        )) &&
      !interiorPathsList.some(item =>
        this.displayedDelivery.isInteriorPathId(item.id),
      ) &&
      byId.get(this.displayedDelivery.interiorPath)
    ) {
      interiorPathsList.push(byId.get(this.displayedDelivery.interiorPath))
    }

    return this.getDropDownOptions({
      list: interiorPathsList,
      isDefaultOptionHidden: false,
      hasRelatedAttribute: true,
    })
  }

  private getInteriorDoorOptions(
    buildingFieldId: string = this.buildingField.value,
    zoneFieldId: string = this.zoneField.value,
  ) {
    const { list, byId } = this.locationAttributesStore.interiorDoorsStore
    let interiorDoorList = this.setClosedOptions(
      list.slice(),
      fieldIdToPropName[FieldIds.INTERIOR_DOOR],
      this.sortByParentAndName,
    )

    interiorDoorList =
      this.deliveryRestrictionsStore.filterAttributesByZoneAndBuilding(
        interiorDoorList,
        RestrictionAttributeKey.RestrictedInteriorDoors,
        buildingFieldId,
        zoneFieldId,
      )

    if (
      this.displayedDelivery &&
      (this.displayedDelivery.isDone ||
        this.projectDateStore.isBeforeToday(
          this.displayedDelivery.startDate,
        )) &&
      !interiorDoorList.some(item =>
        this.displayedDelivery.isInteriorDoorId(item.id),
      ) &&
      byId.get(this.displayedDelivery.interiorDoor)
    ) {
      interiorDoorList.push(byId.get(this.displayedDelivery.interiorDoor))
    }

    return this.getDropDownOptions({
      list: interiorDoorList,
      isDefaultOptionHidden: false,
      hasRelatedAttribute: true,
    })
  }

  private get buildingOptions() {
    const { list, byId } = this.locationAttributesStore.buildingsStore
    const buildingsList = this.setClosedOptions(
      list.slice(),
      fieldIdToPropName[FieldIds.BUILDING],
      this.sortByParentAndName,
    )

    if (
      this.displayedDelivery &&
      (this.displayedDelivery.isDone ||
        this.projectDateStore.isBeforeToday(
          this.displayedDelivery.startDate,
        )) &&
      !buildingsList.some(building =>
        this.displayedDelivery.isBuildingId(building.id),
      ) &&
      byId.get(this.displayedDelivery.building)
    ) {
      buildingsList.push(byId.get(this.displayedDelivery.building))
    }

    return this.getDropDownOptions({
      list: buildingsList,
      isDefaultOptionHidden: false,
      hasRelatedAttribute: true,
    })
  }

  private getLevelOptions(
    buildingFieldId: string = this.buildingField.value,
    zoneFieldId: string = this.zoneField.value,
  ) {
    const { list, byId } = this.locationAttributesStore.levelsStore

    let levels = this.setClosedOptions(
      list.slice(),
      fieldIdToPropName[FieldIds.LEVEL],
      this.sortByHighestParent,
    )
    levels = this.deliveryRestrictionsStore.filterAttributesByZoneAndBuilding(
      levels,
      RestrictionAttributeKey.RestrictedLevels,
      buildingFieldId,
      zoneFieldId,
    )

    if (
      this.displayedDelivery &&
      (this.displayedDelivery.isDone ||
        this.projectDateStore.isBeforeToday(
          this.displayedDelivery.startDate,
        )) &&
      !levels.some(level => this.displayedDelivery.isLevelId(level.id)) &&
      byId.get(this.displayedDelivery.level)
    ) {
      levels.push(byId.get(this.displayedDelivery.level))
    }

    return this.getDropDownOptions({
      list: levels,
      isDefaultOptionHidden: false,
      hasRelatedAttribute: true,
    })
  }

  private getAreaOptions(
    buildingFieldId: string = this.buildingField.value,
    zoneFieldId: string = this.zoneField.value,
    levelFieldId: string = this.levelField.value,
  ) {
    const { list, byId } = this.locationAttributesStore.areasStore

    let areas = this.setClosedOptions(
      list.slice(),
      fieldIdToPropName[FieldIds.AREA],
      this.sortAreas,
    )
    areas = this.deliveryRestrictionsStore.filterAttributesByZoneAndBuilding(
      areas,
      RestrictionAttributeKey.RestrictedAreas,
      buildingFieldId,
      zoneFieldId,
    )
    areas = this.filterAreasBySelectedLevel(
      areas as IDeliveryControlOption[],
      levelFieldId,
    )

    if (
      this.displayedDelivery &&
      (this.displayedDelivery.isDone ||
        this.projectDateStore.isBeforeToday(
          this.displayedDelivery.startDate,
        )) &&
      !areas.some(area => this.displayedDelivery.isArealId(area.id)) &&
      byId.get(this.displayedDelivery.area)
    ) {
      areas.push(byId.get(this.displayedDelivery.area))
    }

    return this.getDropDownOptions({
      list: areas,
      isDefaultOptionHidden: false,
      hasRelatedAttribute: true,
    })
  }

  private getRouteOptions(
    buildingFieldId = this.buildingField.value,
    zoneFieldId = this.zoneField.value,
  ) {
    const { list, byId } = this.locationAttributesStore.routesStore
    let routesList = this.setClosedOptions(
      list.slice(),
      fieldIdToPropName[FieldIds.ROUTE],
      this.sortByParentAndName,
    )

    routesList =
      this.deliveryRestrictionsStore.filterAttributesByZoneAndBuilding(
        routesList,
        RestrictionAttributeKey.RestrictedRoutes,
        buildingFieldId,
        zoneFieldId,
      )

    if (
      this.displayedDelivery &&
      (this.displayedDelivery.isDone ||
        this.projectDateStore.isBeforeToday(
          this.displayedDelivery.startDate,
        )) &&
      !routesList.some(route => this.displayedDelivery.isRouteId(route.id)) &&
      byId.get(this.displayedDelivery.route)
    ) {
      routesList.push(byId.get(this.displayedDelivery.route))
    }

    return this.getDropDownOptions({
      list: routesList,
      isDefaultOptionHidden: false,
      hasRelatedAttribute: true,
    })
  }

  @computed
  private get deliveryVehicleTypeOptions() {
    const { list, byId } = this.deliveryVehicleTypesStore
    const vehicleTypes = list.slice()

    if (!this.displayedDelivery) {
      return this.getDropDownOptions({
        list: vehicleTypes,
        isDefaultOptionHidden: true,
      })
    }

    const canAddOption =
      this.displayedDelivery &&
      (this.displayedDelivery.isDone ||
        this.projectDateStore.isBeforeToday(this.displayedDelivery.startDate) ||
        this.displayedDelivery.isFromConcreteDirect)

    const isTypeAlreadyInList = vehicleTypes.some(type =>
      this.displayedDelivery.isVehicleTypeId(type.id),
    )

    const doesTypeExist = byId.has(this.displayedDelivery.truckSize)

    if (canAddOption && !isTypeAlreadyInList && doesTypeExist) {
      vehicleTypes.push(byId.get(this.displayedDelivery.truckSize))
    }

    return this.getDropDownOptions({
      list: vehicleTypes,
      isDefaultOptionHidden: true,
    })
  }

  @computed
  private get deliveryUnitsOptions() {
    const { list, getInstanceById } = this.deliveryUnitsStore
    const units = list.slice()

    const shouldShowDeletedUnits =
      this.displayedDelivery &&
      (this.displayedDelivery.isDone ||
        this.projectDateStore.isBeforeToday(this.displayedDelivery.startDate))

    if (
      shouldShowDeletedUnits ||
      this.displayedDelivery?.isFromConcreteDirect
    ) {
      this.displayedDelivery.materials?.forEach(m => {
        const unit = getInstanceById(m.unitId, true)
        if (unit && !units.some(u => u.id === unit?.id)) {
          units.push(unit)
        }
      })
    }

    return this.getDropDownOptions({ list: units, isDefaultOptionHidden: true })
  }

  @action.bound
  public deleteDelivery() {
    if (!this.userProject.isSuperUser) {
      this.recurringRelatedIds = this.recurringRelatedIds.filter(id => {
        const delivery = this.deliveriesStore.byId.get(id)
        return (
          delivery?.isRequester(this.userProject.userId) && delivery?.isPending
        )
      })
    }

    this.eventsStore.dispatch(
      DELETE_DELIVERIES,
      [this.displayedDelivery.id].concat(this.recurringRelatedIds),
    )
    this.isDeleteConfirmModalOpen = false
  }

  @action.bound
  public resetChangedDateAndTime() {
    const dateValue = this.projectDateStore.getDashedFormattedDate(
      this.displayedDelivery.startDate,
    )
    const startTime = this.projectDateStore.getISOTime(
      this.displayedDelivery.startDate,
    )
    const endDateValue = this.projectDateStore.getDashedFormattedDate(
      this.displayedDelivery.endDate,
    )
    const endTime = this.projectDateStore.getISOTime(
      this.displayedDelivery.endDate,
    )

    this.updateFieldStateValue(FieldIds.DATE, dateValue)
    this.updateFieldStateValue(FieldIds.START_TIME, startTime)
    this.updateFieldStateValue(FieldIds.END_DATE, endDateValue)
    this.updateFieldStateValue(FieldIds.END_TIME, endTime)
  }

  public isSectionComplete = (section: DeliveryDetailsSections) => {
    return this.fields
      .filter(field => field.section === section && field.isRequired)
      .every(
        ({ value, isValid }) =>
          !!value && !!Object.keys(value).length && isValid,
      )
  }

  public get isMultipleEquipmentAllowed() {
    return this.eventsStore.appState.delivery.configurations
      .isMultipleEquipmentAllowed
  }

  public getDeliveryDtoFromFields = (): IDelivery => {
    const startTime = this.getValue(FieldIds.START_TIME)
    const endTime = this.getValue(FieldIds.END_TIME)
    const isoStartDate = this.getValue(FieldIds.DATE)
    const isoEndDate = this.getValue(FieldIds.END_DATE)

    const { startDate, endDate } = this.getDates(
      isoStartDate,
      isoEndDate,
      startTime,
      endTime,
    )

    const status = this.displayedDelivery
      ? this.displayedDelivery.status
      : DeliveryStatus.Requested

    const driverPhoneNumbers = getFormattedPhoneNumbersForSubmission(
      getRawPhoneNumbers(this.getValue(FieldIds.DRIVER_PHONE_NUMBERS)),
    )
    const selectedEquipments =
      this.getValue(FieldIds.OFFLOADING_EQUIPMENT) || []

    const offloadingEquipments = selectedEquipments?.map(id => ({ id }))

    return {
      startDate: startDate.getTime(),
      endDate: endDate.getTime(),
      status,
      type: this.getValue(FieldIds.FROM_TO_SWITCH)
        ? DeliveryType.FromTo
        : DeliveryType.Regular,
      locationsTo: this.getLocationsBySection(LocationSection.LOCATION_TO),
      installationZone: this.getValue(FieldIds.INSTALLATION_ZONE),
      locationsFrom: this.getLocationsBySection(LocationSection.LOCATION_FROM),
      fromInstallationZone: this.getValue(FieldIds.FROM_INSTALLATION_ZONE),

      company: this.getValue(FieldIds.COMPANY) || null,
      onSiteContactPersonIds: this.getValue(FieldIds.ON_SITE_CONTACTS) || [],
      truckSize: this.getValue(FieldIds.TRUCK_SIZE) || null,
      truckLicensePlate: this.getValue(FieldIds.TRUCK_LICENSE_PLATE),
      truckNumber: this.getValue(FieldIds.TRUCK_NUMBER),
      vendor: this.getValue(FieldIds.VENDOR) || null,
      vendorEmails: this.getValue(FieldIds.VENDOR_EMAILS),
      driverPhoneNumbers,
      isInspectionRequired: !!parseInt(
        this.getValue(FieldIds.IS_INSPECTION_REQUIRED),
        RADIX,
      ),
      offloadingEquipments,
      activityId: this.getValue(FieldIds.ACTIVITY_ID) || null,
      userId: this.requesterId,
      projectId: this.appState.activeProject.id,
      id: this.displayedDelivery?.id,
      isLateRequest: this.shouldBeMarkedAsLateRequest,
      isUnscheduledRequest: this.getValue(FieldIds.UNSCHEDULED_REQUEST),
      threadId: this.displayedDelivery?.threadId,
      sitemapUrls: this.getValue(FieldIds.SITE_MAPS),
      globeViewUrls: this.getValue(FieldIds.GLOBES),

      inspector: this.getValue(FieldIds.INSPECTOR),
      note: this.getValue(FieldIds.NOTE),
      numberOfEquipmentPicks: this.getValue(FieldIds.NUMBER_OF_EQUIPMENT_PICKS),

      recurringSettingId:
        !!this.displayedDelivery?.id &&
        this.displayedDelivery.recurringSettingId,
    }
  }

  private getLocationsBySection(section: LocationSection): ISiteLocation[] {
    const getSectionFieldId = (fieldId: FieldIds): any =>
      section === LocationSection.LOCATION_FROM
        ? getFromSectionAnalogFieldId(fieldId)
        : fieldId

    const getLocation = (type: LocationType, fieldId: FieldIds) => ({
      type: type,
      id: this.getValue(getSectionFieldId(fieldId)),
    })

    return [
      getLocation(LocationType.Building, FieldIds.BUILDING),
      getLocation(LocationType.Zone, FieldIds.ZONE),
      getLocation(LocationType.Area, FieldIds.AREA),
      getLocation(LocationType.Gate, FieldIds.GATE),
      getLocation(LocationType.Level, FieldIds.LEVEL),
      getLocation(LocationType.Route, FieldIds.ROUTE),
      getLocation(LocationType.Staging, FieldIds.STAGING),
      getLocation(LocationType.InteriorDoor, FieldIds.INTERIOR_DOOR),
      getLocation(LocationType.InteriorPath, FieldIds.INTERIOR_PATH),
    ].filter(l => !!l.id)
  }

  private get isDeliveryInBookingDeadlineWindow(): boolean {
    if (
      (this.displayedDelivery.status === DeliveryStatus.Requested ||
        this.displayedDelivery.status === DeliveryStatus.Scheduled) &&
      this.appState.delivery.configurations.deadlineInterval !==
        NO_DEADLINE_OPTION
    ) {
      return this.displayedDelivery.isLateRequest
    }

    return false
  }

  private get shouldBeMarkedAsLateRequest(): boolean {
    if (this.isCreateModeActive) {
      return this.isInsideDeadline
    }

    if (this.areStartDateTimeChanged && !this.wasDeliveryScheduled) {
      return this.isInsideDeadline
    }

    return !this.isFromConcreteDirect && this.displayedDelivery.isLateRequest
  }

  private get wasDeliveryScheduled(): boolean {
    if (!this.displayedDelivery) {
      return false
    }

    const { getStatusChangesForDelivery } = this.deliveryStatusChangesStore
    const deliveryStatuses = getStatusChangesForDelivery(
      this.displayedDelivery.id,
    )

    return (
      deliveryStatuses &&
      deliveryStatuses.some(s => s.status === DeliveryStatus.Scheduled)
    )
  }

  @action.bound
  public async changeDelivery(onCreationCb?: (deliveryId: string) => void) {
    this.deliveryActionBarStore.decodedDraftMessage =
      this.getChangedFieldsMessage(
        this.deliveryActionBarStore.decodedDraftMessage,
      )

    await this.onFormSubmit(onCreationCb)

    this.deliveryActionBarStore.reset()
  }

  @action.bound
  public async onFormSubmit(onCreationCb?: (deliveryId: string) => void) {
    const deliveryDto = this.getDeliveryDtoFromFields()
    const delivery = Delivery.fromDto(deliveryDto)

    if (!delivery.canChange || !this.isSubmitButtonEnabled) {
      return
    }

    this.blockSubmitButton()

    try {
      await this.setMaterialFromFields(delivery)

      switch (this.mode) {
        case DeliveryDetailsMode.CREATE:
          return await this.handleDeliveryCreate(delivery, onCreationCb)

        case DeliveryDetailsMode.CHANGE:
        case DeliveryDetailsMode.VIEW:
          await this.handleDeliveryUpdate(
            delivery,
            this.areRequiredFieldsChanged,
          )
      }
    } finally {
      this.unblockSubmitButton()
    }
  }

  public setMaterialFromFields = async (delivery: IDelivery | Delivery) => {
    const materials = await Array.from(
      Array(this.materialsSectionsCount).keys(),
    ).reduce(async (promiseArray, colIdx) => {
      const idx = colIdx + 1
      const materialsArr = await promiseArray

      const categoryId = this.getValue(FieldIds.MATERIAL_CATEGORY, idx)
      const materialNote = this.getValue(FieldIds.MATERIAL_NOTE, idx)

      if (!categoryId && !this.isCSICategoryDisabled && !materialNote) {
        return materialsArr
      }

      const materialId = this.getValue(FieldIds.MATERIAL, idx)

      const material = await this.materialsStore.createMaterialIfNotExists(
        materialId,
        categoryId,
      )

      if (!material?.id) {
        return materialsArr
      }

      const procurementId =
        this.getValue(FieldIds.MATERIAL_PROCUREMENT_ID, idx) || null
      const unitId = this.getValue(FieldIds.MATERIAL_UNITS, idx) || null
      const quantity = this.getValue(FieldIds.MATERIAL_QUANTITY, idx) || 0
      const locationId = this.getValue(FieldIds.MATERIAL_LOCATION, idx) || null

      const deliveryMaterial = {
        materialId: material.id,
        procurementId,
        quantity: Number(quantity),
        note: materialNote,
        unitId,
        locationId,
      }

      const hasDuplicate = materialsArr.some(dm =>
        this.areObjectsEqual(dm, deliveryMaterial),
      )

      if (!hasDuplicate) {
        materialsArr.push(deliveryMaterial)
      }

      return materialsArr
    }, Promise.resolve([] as IDeliveryMaterial[]))

    Delivery.updateMaterials(delivery, materials)
  }

  @action.bound
  public setNewDeliveryMode() {
    this.displayedDelivery = null
    this.mode = DeliveryDetailsMode.CREATE
    this.prepareView()
  }

  @action.bound
  public setViewMode(delivery: Delivery) {
    this.displayedDelivery = delivery
    this.mode = DeliveryDetailsMode.VIEW
    this.prepareView()
  }

  @action.bound
  public setViewModeWithNewMaterial(delivery: Delivery) {
    this.setViewMode(delivery)
    this.increaseMaterialsSectionsCount()
    this.activeDetailsTab = DeliveryDetailsTab.Form
  }

  @action.bound
  public setChangeMode(delivery: Delivery) {
    this.displayedDelivery = delivery
    this.mode = DeliveryDetailsMode.CHANGE
    this.prepareView()
  }

  public get isCreateModeActive() {
    return this.mode === DeliveryDetailsMode.CREATE
  }

  public get isViewModeActive() {
    return this.mode === DeliveryDetailsMode.VIEW
  }

  public get isDefaultModeActive() {
    return this.mode === DEFAULT_MODE
  }

  @action.bound
  public prepareView() {
    this.hideSitemapsGallery()
    this.setMaterialsSectionsCount(this.displayedDelivery?.materials?.length)
    this.removedMaterials.clear()

    this.closeSelectDateModal()
    if (!this.isCreateModeActive) {
      this.updateMaterialsRelatedFields()
      this.activeDetailsTab = this.displayedDelivery?.isPending
        ? DeliveryDetailsTab.Form
        : DeliveryDetailsTab.Log
    }

    switch (this.mode) {
      case DeliveryDetailsMode.CREATE:
        this.activeDetailsTab = DeliveryDetailsTab.Form
        this.deliveryActionBarStore.hideMenuPopup()
        this.displayedDelivery = null
        this.resetMaterialsSectionsCount()
        this.clearFieldsState()
        break
      case DeliveryDetailsMode.CHANGE:
      case DeliveryDetailsMode.VIEW:
        this.setPrepopulatedFields(this.displayedDeliveryFields)
    }
  }

  public openMoveDeliveryDates = (eventInfo: IMovedEventInfo) => {
    this.movedEventInfo = eventInfo
    this.isDateMoveConfirmModalOpen = true
  }

  public hideMoveDeliveryDates = () => {
    this.isDateMoveConfirmModalOpen = false
    this.calendarPlaceholderStore.resetEventPlaceholder()
  }

  public moveDeliveryDates = (backClicked: () => void) => {
    if (!this.movedEventInfo) {
      return
    }

    const { event, startDate, endDate } = this.movedEventInfo
    this.displayedDelivery = event.data
    this.setNewDeliveryDates(
      startDate.getTime(),
      endDate.getTime(),
      backClicked,
    )
    this.isDateMoveConfirmModalOpen = false
  }

  @action.bound
  public setPrepopulatedFields(fields: { [key: string]: any }) {
    this.updateFieldStateValue(
      FieldIds.FROM_TO_SWITCH,
      !!fields?.[FieldIds.FROM_TO_SWITCH],
    )

    this.fields.forEach(field => {
      let value =
        fields[getFieldId(field.id, field.index)] || field.defaultValue

      switch (field.id) {
        case FieldIds.SITE_MAPS:
        case FieldIds.GLOBES:
          value = fields[field.id]
          break
        case FieldIds.LATE_REQUEST:
          value = !this.isCreateModeActive && this.shouldBeMarkedAsLateRequest
          break
        case FieldIds.NON_WORK_TIMES:
          value = !this.isCreateModeActive && !this.isInsideWorkTime
      }
      this.updateFieldStateValue(field.id, value, field.index)
    })

    this.checkIfProcurementIdsOpened()
    this.saveCopyOfInitialArrayValues()

    this.unblockSubmitButton()

    this.openNonWorkTimeModalIfNeed()
    this.openDateChangeConfirmModalIfNeed()

    this.setInspectionFieldValue()
    this.updateDurationField()
  }

  @action.bound
  private saveCopyOfInitialArrayValues() {
    this.initialArrayValues = this.fields
      .filter(field => field.isMultiValueInput)
      .reduce((acc, field) => {
        const fieldValue = this.getValue(field.id)
        acc.set(field.id, [...fieldValue] || field.value)
        return acc
      }, new Map<FieldIds, any[]>())
  }

  @action.bound
  public increaseMaterialsSectionsCount() {
    if (this.canIncreaseMaterialsCount) {
      this.setMaterialsSectionsCount(this.materialsSectionsCount + 1)
    }
  }

  private isMaterialRemoved(index) {
    return this.removedMaterials.has(index)
  }

  @action.bound
  public handleMaterialSectionClick(index: number) {
    if (!index) {
      return
    }
    this.isMaterialRemoved(index)
      ? this.restoreMaterial(index)
      : this.removeMaterial(index)
  }

  @action.bound
  private restoreMaterial(index: number) {
    this.updateFieldStateValue(FieldIds.MATERIALS_SECTION, false, index)
    this.removedMaterials.delete(index)
  }

  public get canRemoveMaterials(): boolean {
    return this.materialsSectionsCount > this.removedMaterials.size + 1
  }

  @action.bound
  private removeMaterial(index: number) {
    if (!this.canRemoveMaterials) {
      return
    }
    if (this.displayedDelivery?.materials?.length >= index) {
      this.removedMaterials.add(index)
    } else {
      this.removeUnsavedMaterial(index)
    }
  }

  @action.bound
  private removeUnsavedMaterial(index: number) {
    const filteredMaterialFieldsWithShift = this.materialFields
      .filter(field => field.index > index)
      .reduce(
        (a, v) => ({ ...a, [getFieldId(v.id, v.index - 1)]: v.value }),
        {},
      )
    this.updateMaterialFieldsValues(index - 1, filteredMaterialFieldsWithShift)
    this.setMaterialsSectionsCount(this.materialsSectionsCount - 1)
  }

  @action.bound
  public resetMaterialsSectionsCount() {
    this.materialsSectionsCount = MIN_MULTI_VALUE_COUNT
  }

  @action.bound
  public setNowDates() {
    const startDate = new Date()
    const endDate = this.projectDateStore.addMinutes(
      new Date(),
      this.defaultDeliveryDuration,
    )
    const dateValue = this.projectDateStore.getDashedFormattedDate(startDate)
    const startTime = this.projectDateStore.getISOTime(startDate)
    const endDateValue = this.projectDateStore.getDashedFormattedDate(endDate)
    const endTime = this.projectDateStore.getISOTime(endDate)

    this.updateFieldStateValue(FieldIds.DATE, dateValue)
    this.updateFieldStateValue(FieldIds.START_TIME, startTime)
    this.updateFieldStateValue(FieldIds.END_DATE, endDateValue)
    this.updateFieldStateValue(FieldIds.END_TIME, endTime)
  }

  @action.bound
  public duplicateDelivery() {
    const prePopulatedFields = this.displayedDeliveryFields
    const materialsCount = this.displayedDelivery?.materials?.length
    this.removeDateAndLateRequestFromFields(prePopulatedFields)

    this.setNewDeliveryMode()
    this.setMaterialsSectionsCount(materialsCount)
    this.setPrepopulatedFields(prePopulatedFields)
    this.moveDateForward()
  }

  @action.bound
  public createFromToDelivery() {
    const prePopulatedFields = this.displayedDeliveryFields
    const materialsCount = this.displayedDelivery?.materials?.length
    this.removeDateAndLateRequestFromFields(prePopulatedFields)

    this.prepareFromToCreationFields(prePopulatedFields)

    this.setNewDeliveryMode()
    this.setMaterialsSectionsCount(materialsCount)
    this.setPrepopulatedFields(prePopulatedFields)
    this.setNowDates()
    this.swapFromAndToSectionValues()
  }

  @computed
  public get canIncreaseMaterialsCount(): boolean {
    return (
      !this.isFromConcreteDirect &&
      this.materialsSectionsCount < MAX_MULTI_VALUE_COUNT
    )
  }

  public canIncreaseMultiValueCount(fieldId: FieldIds): boolean {
    const stateValue = this.getFieldStateValue(fieldId, [])
    return stateValue.length < MAX_MULTI_VALUE_COUNT
  }

  @computed
  public get areAllRequiredFieldsFilled(): boolean {
    return this.requiredFields.every(
      field => field.value && Object.keys(field.value).length !== 0,
    )
  }

  @computed
  public get areAllFieldsValid(): boolean {
    return this.fields.every(field => field.isValid)
  }

  @computed
  public get isOnlyDateChanged(): boolean {
    if (!this.displayedDelivery) {
      return false
    }

    return (
      this.dateField.isChanged &&
      this.fields
        .filter(({ id, isUtility }) => id !== FieldIds.DATE && !isUtility)
        .every(({ isChanged }) => !isChanged)
    )
  }

  @computed
  public get areFieldsChanged(): boolean {
    if (!this.displayedDelivery) {
      return true
    }

    return this.fields.some(
      ({ isUtility, isChanged }) => !isUtility && isChanged,
    )
  }

  @computed
  public get areRequiredFieldsChanged(): boolean {
    if (!this.displayedDelivery) {
      return false
    }

    return this.fields.some(
      ({ isUtility, isChanged, isRequired }) =>
        !isUtility && isRequired && isChanged,
    )
  }

  @computed
  public get areOptionalFieldsChanged(): boolean {
    if (!this.displayedDelivery) {
      return false
    }

    return this.fields.some(
      ({ isUtility, isChanged, isRequired }) =>
        !isUtility && !isRequired && isChanged,
    )
  }

  public get areStartDateTimeChanged(): boolean {
    if (!this.displayedDelivery) {
      return true
    }

    return this.dateField.isChanged || this.startTimeField.isChanged
  }

  @action.bound
  public cancelDelivery(reason?: string) {
    if (reason) {
      this.deliveryActionBarStore.decodedDraftMessage = reason
      this.displayedDelivery.cancellationReason = reason
    }

    this.setNewDeliveryStatus(DeliveryStatus.Canceled)
    this.isCancelConfirmModalOpen = false
    this.deliveryActionBarStore.hideMenuPopup()
  }

  @action.bound
  public setNewDeliveryStatus(newStatus: DeliveryStatus) {
    if (
      !this.displayedDelivery ||
      !this.displayedDelivery.canUserChangeStatus(newStatus, this.userProject)
    ) {
      return
    }

    const updatedDelivery = getDeliveryCopyWithNewStatus(
      this.displayedDelivery,
      newStatus,
    )

    this.handleDeliveryUpdate(updatedDelivery, true)
  }

  @action.bound
  public async setNewDeliveryDates(
    startDate: number,
    endDate: number,
    backClicked: () => void,
  ) {
    if (
      this.userProject.isSuperUser ||
      this.mode === DeliveryDetailsMode.CREATE ||
      (this.isDeliveryMovable &&
        this.displayedDelivery.userId === this.userProject.userId)
    ) {
      const updatedDelivery = Object.assign(this.displayedDelivery.getCopy(), {
        startDate,
        endDate,
        updatedAt: Date.now(),
      }) as Delivery

      await this.handleDeliveryUpdate(updatedDelivery, true)
    }

    backClicked()
  }

  public addNewFieldOption = (field: IDeliveryControl) => {
    const { addOption, newOptionName } = field
    if (this.isNewFieldOptionValid(field)) {
      addOption(newOptionName, field.index)
      this.onNewOptionClear(field.id, field.index)
    }
  }

  @action.bound
  public toggleUnscheduledDelivery() {
    this.onToggleButtonChange(FieldIds.UNSCHEDULED_REQUEST)
  }

  public get isUnscheduledDelivery(): boolean {
    return !!this.getValue(FieldIds.UNSCHEDULED_REQUEST)
  }

  @action.bound
  public onFieldValueChange(
    fieldId: FieldIds,
    value: any,
    fieldIndex?: number,
  ) {
    switch (fieldId) {
      case FieldIds.SITE_MAPS:
      case FieldIds.GLOBES:
        break
      case FieldIds.DATE:
      case FieldIds.START_TIME:
      case FieldIds.END_DATE:
      case FieldIds.END_TIME:
        this.onDateTimeChange(fieldId, value)
        break
      case FieldIds.MATERIAL_CATEGORY:
        this.onMaterialCategoryChange(fieldId, value, fieldIndex)
        break
      case FieldIds.MATERIAL:
        this.onMaterialChange(fieldId, value, fieldIndex)
        break
      case FieldIds.MATERIAL_PROCUREMENT_ID:
        this.onProcurementIdChange(fieldId, value, fieldIndex)
        break
      case FieldIds.UNSCHEDULED_REQUEST:
        this.onToggleButtonChange(fieldId)
        break
      case FieldIds.FROM_TO_SWITCH:
        this.onToggleButtonChange(fieldId)
        this.swapFromAndToSectionValues()
        break
      case FieldIds.OFFLOADING_EQUIPMENT:
        this.handleMultiValueFieldChange(fieldId, value, fieldIndex, true)
        break
      case FieldIds.BUILDING:
      case FieldIds.ZONE:
        this.onCommonChange(fieldId, value)
        this.checkIfDeliveryAttributesValid()
        break
      case FieldIds.AREA:
      case FieldIds.GATE:
      case FieldIds.ROUTE:
        this.onCommonChange(fieldId, value)
        this.updateFieldStateValue(
          FieldIds.SITE_MAPS,
          this.sitemapsDefaultValue.map(s => s.filledImage),
        )
        this.updateFieldStateValue(
          FieldIds.GLOBES,
          this.globesDefaultValue.map(s => s.filledImage),
        )
        break
      case FieldIds.LEVEL:
        this.onLevelFieldChange(fieldId, value)
        this.updateFieldStateValue(
          FieldIds.SITE_MAPS,
          this.sitemapsDefaultValue.map(s => s.filledImage),
        )
        this.updateFieldStateValue(
          FieldIds.GLOBES,
          this.globesDefaultValue.map(s => s.filledImage),
        )
        break
      case FieldIds.RECURRING_OPTIONS:
        this.onCommonChange(fieldId, value)
        this.checkIfDeliveryAttributesValid()
        break

      case FieldIds.ON_SITE_CONTACTS:
        this.handleMultiValueFieldChange(fieldId, value, fieldIndex, true)
        break
      case FieldIds.VENDOR:
        this.handleVendorFieldChange(value)
        break
      case FieldIds.DRIVER_PHONE_NUMBERS:
      case FieldIds.VENDOR_EMAILS:
        this.handleMultiValueFieldChange(fieldId, value, fieldIndex)
        break

      default:
        this.onCommonChange(fieldId, value, fieldIndex)
    }
  }

  @action.bound
  private handleMultiValueFieldChange(
    fieldId: FieldIds,
    value: string | IPhoneNumber,
    fieldIndex?: number,
    isTextEntryComplete: boolean = false,
  ) {
    const stateValue = this.getFieldStateValue(fieldId, [])
    const currentValues = Array.isArray(stateValue) ? stateValue : [stateValue]

    if (!isTextEntryComplete) {
      return this.updateMultiValueField(
        fieldId,
        currentValues,
        value,
        fieldIndex,
        false,
      )
    }

    // Only split the user's input after they've finished typing
    const values = this.splitValues(fieldId, value)

    // use the current index once, but avoid the temp index of -1 altogether
    let fieldIndexOnce = fieldIndex !== -1 ? fieldIndex : undefined

    values.forEach(v => {
      // Prevent duplicates and empty inputs
      if (
        (fieldIndex == undefined || fieldIndex === -1) &&
        (!v || DeliveryDetailsStore.isMultiFieldValuePresent(currentValues, v))
      )
        return

      this.updateMultiValueField(
        fieldId,
        currentValues,
        v,
        fieldIndexOnce,
        true,
      )
      fieldIndexOnce = undefined // next ones will be added rather than updated
    })
  }

  public static isMultiFieldValuePresent = (
    state: unknown[],
    value: unknown,
    skipIndex?: number,
  ): boolean => {
    return state.some((stateValue, index) => {
      if (index === skipIndex) {
        return false
      }
      if (stateValue === value) {
        return true
      }

      // Compare them as phone numbers, special case
      const formattedState = isPhoneNumber(stateValue)
        ? getFormattedPhoneNumberForSubmission(stateValue.phoneNumber)
        : stateValue
      const formattedInput = isPhoneNumber(value)
        ? getFormattedPhoneNumberForSubmission(value.phoneNumber)
        : value

      return formattedState === formattedInput
    })
  }

  private updateMultiValueField<T extends string | IPhoneNumber>(
    fieldId: FieldIds,
    existingValue: T[],
    value: T,
    fieldIndex: number,
    isEntryComplete: boolean = false,
  ): void {
    try {
      const hasIndex = fieldIndex != null
      const hasValue = !!value

      // Update value if it doesn't already exist
      if (
        hasIndex &&
        hasValue &&
        (!isEntryComplete ||
          (isEntryComplete &&
            !DeliveryDetailsStore.isMultiFieldValuePresent(
              existingValue,
              value,
            )))
      ) {
        existingValue[fieldIndex] = value
      }

      // Remove value
      // (either empty or duplicate value)
      else if (
        (fieldIndex != -1 && hasIndex && !hasValue) ||
        (hasIndex &&
          isEntryComplete &&
          DeliveryDetailsStore.isMultiFieldValuePresent(
            existingValue,
            value,
            fieldIndex,
          ))
      ) {
        existingValue.splice(fieldIndex, 1)
      }

      // Add new value
      else if (
        isEntryComplete &&
        !hasIndex &&
        hasValue &&
        this.canIncreaseMultiValueCount(fieldId) &&
        this.isFieldValid(fieldId, value)
      ) {
        existingValue.push(value)
        delete existingValue[-1]
      }

      // Remove invalid value
      else if (
        fieldIndex != -1 &&
        isEntryComplete &&
        hasIndex &&
        hasValue &&
        !this.isFieldValid(fieldId, value)
      ) {
        existingValue.splice(fieldIndex, 1)
      }

      // Update temp field
      else if (!isEntryComplete) {
        existingValue[-1] = value
        this.onCommonChange(fieldId, existingValue, -1)
        return
      }

      // Clear temp field
      else {
        delete existingValue[-1]
      }

      this.onCommonChange(fieldId, existingValue)
    } catch (e) {
      console.error('Error saving multivalue update', e)
    }
  }

  /** Generic validator for different formats */
  public isFieldValid = (
    fieldId: FieldIds,
    value: string | IPhoneNumber,
  ): boolean => {
    switch (fieldId) {
      case FieldIds.VENDOR_EMAILS:
        return this.isEmailValid(value as string)
      case FieldIds.DRIVER_PHONE_NUMBERS:
        return this.isPhoneNumberValid(value)
      case FieldIds.ON_SITE_CONTACTS:
      default:
        return true
    }
  }

  /** Split string into an array, omitting empty values.
   * If the input is empty, return an empty array.
   * For non-string object formats, just return them in an array.
   */
  public splitValues<T>(fieldId: FieldIds, value: T): string[] | T[] {
    if (!value) {
      return [undefined]
    }
    if (Array.isArray(value)) return value

    if (typeof value === 'string') {
      switch (fieldId) {
        case FieldIds.VENDOR_EMAILS:
          return value
            .split(EMAIL_EXTRACTOR)
            .filter(v => v.match(VALID_EMAIL_PATTERN))
        case FieldIds.DRIVER_PHONE_NUMBERS:
          return getFormattedPhoneNumbersForSubmission(
            value.match(PHONE_NUMBER_EXTRACTOR) as string[],
          )
      }
    }

    // Handle phone number objects where the number field has been emptied
    if (FieldIds.DRIVER_PHONE_NUMBERS === fieldId && isPhoneNumber(value)) {
      const pn = value as IPhoneNumber
      return isPhoneNumberValid(pn.phoneNumber, pn.country)
        ? [pn]
        : ([undefined] as any[])
    }

    return [value]
  }

  @action.bound
  public onMultivalueFieldBlur(fieldId, value, fieldIndex) {
    this.handleMultiValueFieldChange(fieldId, value, fieldIndex, true)
  }

  @action.bound
  public onNewOptionChange(
    fieldId: FieldIds,
    value: string,
    fieldIndex?: number,
  ) {
    this.updateFieldStateNewOptionName(fieldId, value, fieldIndex)
  }

  @action.bound
  public onNewOptionClear(fieldId: FieldIds, fieldIndex?: number) {
    this.updateFieldStateNewOptionName(fieldId, EMPTY_VALUE, fieldIndex)
  }

  @action.bound
  public resetToDefaultMode() {
    this.mode = DEFAULT_MODE
    this.displayedDelivery = null
    this.clearFieldsState()
    this.hideWorkflowModal()
  }

  public clearFieldsState = () => {
    this.fieldsState.clear()
  }

  @action.bound
  public notifyUserAboutNotValidAttrs() {
    // TODO: Design of correct UX interaction in this point
    alert(`Some delivery attributes were changed. Please make new selections.`)
  }

  @action.bound
  public setSelectedFieldId(fieldId: FieldIds, fieldIndex?: number) {
    this.selectedFieldId = fieldId
    this.selectedFieldIndex = fieldIndex
  }

  @action.bound
  public clearSelectedField() {
    if (this.selectedField?.newOptionName) {
      this.onNewOptionClear(this.selectedFieldId, this.selectedFieldIndex)
    }

    this.selectedFieldId = null
    this.selectedFieldIndex = null
  }

  private isFieldSelected(fieldId: FieldIds, fieldIndex?: number): boolean {
    const isSelected = this.selectedFieldId === fieldId
    return fieldIndex
      ? isSelected && this.selectedFieldIndex === fieldIndex
      : isSelected
  }

  public get isSubmitButtonEnabled(): boolean {
    return (
      !this.shouldBlockSubmitButton &&
      this.areAllFieldsValid &&
      this.areAllRequiredFieldsFilled &&
      this.areFieldsChanged &&
      (!this.displayedDelivery || this.displayedDelivery.canChange)
    )
  }

  public get isDeliveryMovable(): boolean {
    return (
      !this.shouldBlockSubmitButton &&
      this.areAllFieldsValid &&
      this.areAllRequiredFieldsFilled &&
      (!this.displayedDelivery || this.displayedDelivery.canChange)
    )
  }

  public get isDenyButtonEnabled(): boolean {
    return (
      !this.areFieldsChanged &&
      this.displayedDelivery &&
      !this.displayedDelivery.isDenied &&
      !this.displayedDelivery.isDone &&
      this.userProject.isSuperUser
    )
  }

  public get userProject() {
    return this.appState.userActiveProjectSettings
  }

  public get submitTitle(): string {
    const { save, change_verb, submit_verb, deny_verb } =
      Localization.translator
    if (!this.displayedDelivery) {
      return submit_verb
    }

    if (this.areRequiredFieldsChanged) {
      return change_verb
    }

    if (this.areOptionalFieldsChanged) {
      return save
    }

    return deny_verb
  }

  @computed
  public get currentStatusActions(): IStatusAction[] {
    if (!this.displayedDelivery) {
      return []
    }
    const currentStepIdx = this.statusSteps.findIndex(({ statuses }) =>
      statuses.includes(this.displayedDelivery.status),
    )
    const nextStep = this.statusSteps[currentStepIdx + 1]
    if (nextStep) {
      return this.getStatusStepActions(nextStep)
    }
    return []
  }

  public getStatusStepActions = ({
    statuses,
  }: IStatusStep): IStatusAction[] => {
    if (!this.displayedDelivery) {
      return []
    }
    switch (true) {
      case statuses.includes(DeliveryStatus.Requested):
        return [
          {
            isActive: this.displayedDelivery.isDenied,
            actionTitle: this.getStatusActionTitle(DeliveryStatus.Denied),
            onClick: this.setNewDeliveryStatus.bind(
              this,
              DeliveryStatus.Denied,
            ),
            isDisabled: !this.isDenyButtonEnabled,
          },
          {
            actionTitle: this.getStatusActionTitle(DeliveryStatus.Requested),
            onClick: this.onFormSubmit,
            isDisabled: !this.isSubmitButtonEnabled,
          },
        ]
      case statuses.includes(DeliveryStatus.Done):
        const doneStatus = this.displayedDelivery.isInspectionFailed
          ? DeliveryStatus.IncompleteDone
          : DeliveryStatus.Done
        return [this.getActionFromStatus(doneStatus)]
      default:
        return statuses.map(this.getActionFromStatus)
    }
  }

  public hasUserPermissions = (
    act: DeliveryDetailsActions,
    targetDelivery?: Delivery,
  ): boolean => {
    const { userActiveProjectSettings: userProject, user } = this.appState

    if (!userProject) {
      return false
    }

    const delivery = targetDelivery || this.displayedDelivery

    switch (act) {
      case DeliveryDetailsActions.SHOW_FORM:
        return (
          this.isCreateModeActive ||
          userProject.isSuperUser ||
          user.isDeliveryRequester(delivery)
        )
      case DeliveryDetailsActions.CHANGE_INSIDE_BOOKING_DEADLINE:
        if (userProject.isSuperUser || !this.displayedDelivery) {
          return true
        }

        return !this.isDeliveryInBookingDeadlineWindow
      case DeliveryDetailsActions.SHOW_DATE_CONFIRM_DIALOG:
      case DeliveryDetailsActions.SHOW_NON_WORK_TIMES_DIALOG:
        // never show for DM and ADMIN
        if (userProject.isSuperUser) {
          return false
        }

        return (
          this.isCreateModeActive ||
          (!this.isCreateModeActive && this.areStartDateTimeChanged)
        )
      case DeliveryDetailsActions.DELETE_DELIVERY:
        return (
          userProject.isSuperUser ||
          (user.isDeliveryRequester(delivery) && delivery?.isPending)
        )
      case DeliveryDetailsActions.SCHEDULE_DELIVERY:
      case DeliveryDetailsActions.SIMPLIFIED_FORM_FILLING:
      case DeliveryDetailsActions.MARK_REQUEST_AS_UNSCHEDULED:
      case DeliveryDetailsActions.SELECT_RESTRICTED_ATTRS:
        return userProject.isSuperUser
      case DeliveryDetailsActions.DUPLICATE_DELIVERY:
      case DeliveryDetailsActions.CREATE_FROM_TO_DELIVERY:
        return (
          this.isViewModeActive ||
          userProject.isSuperUser ||
          user.isDeliveryRequester(delivery)
        )
      case DeliveryDetailsActions.CANCEL_DELIVERY:
        return delivery.canUserChangeStatus(
          DeliveryStatus.Canceled,
          userProject,
        )
      default:
        return false
    }
  }

  public getFieldName = (id: FieldIds) => {
    return this.appState.getDeliveryFieldName(id)
  }

  public updateRecurringOptions = () => {
    this.updateFieldStateValue(
      FieldIds.RECURRING_OPTIONS,
      this.existingRecurringSetting,
    )
  }

  public getActionFromStatus = (status: DeliveryStatus): IStatusAction => {
    return {
      actionTitle: this.getStatusActionTitle(status),
      onClick: this.setNewDeliveryStatus.bind(this, status),
      isActive: this.displayedDelivery.isStatusPassed(status),
      isDisabled:
        !this.displayedDelivery.canUserChangeStatus(status, this.userProject) ||
        this.shouldBlockSubmitButton,
      isHidden:
        status === DeliveryStatus.Scheduled &&
        !this.hasUserPermissions(DeliveryDetailsActions.SCHEDULE_DELIVERY),
    }
  }

  private get appState() {
    return this.eventsStore.appState
  }

  private isNewFieldOptionValid(field: IDeliveryControl): boolean {
    const { options, newOptionName } = field
    return newOptionName && !options.some(o => o.title === newOptionName)
  }

  private getStatusActionTitle(status: DeliveryStatus): string {
    const {
      change_verb,
      accept,
      onSite,
      pass_verb: pass,
      fail,
      done,
      deny_verb: deny,
      canceled,
      onHold,
      paused,
      delivering,
    } = Localization.translator
    switch (status) {
      case DeliveryStatus.Requested:
      case DeliveryStatus.Changed:
        return change_verb
      case DeliveryStatus.Scheduled:
        return accept
      case DeliveryStatus.Denied:
        return deny
      case DeliveryStatus.OnSite:
        return onSite
      case DeliveryStatus.PassedInspection:
        return pass
      case DeliveryStatus.FailedInspection:
        return fail
      case DeliveryStatus.IncompleteDone:
      case DeliveryStatus.Done:
        return done
      case DeliveryStatus.Canceled:
        return canceled
      case DeliveryStatus.OnHold:
        return onHold
      case DeliveryStatus.Paused:
        return paused
      case DeliveryStatus.Delivering:
        return delivering

      default:
        throw new Error(status + ' is not added to translator')
    }
  }

  private setInspectionFieldValue() {
    const { mandatoryFields } = this.eventsStore.appState.delivery

    if (!this.getFieldStateValue(FieldIds.IS_INSPECTION_REQUIRED)) {
      const value = mandatoryFields[FieldIds.IS_INSPECTION_REQUIRED] ? '1' : '0'
      this.updateFieldStateValue(FieldIds.IS_INSPECTION_REQUIRED, value)
    }
  }

  private async uploadPhotoToLog(): Promise<{ url: string; thumbUrl: string }> {
    const { fileImageCopy } = this.deliveryActionBarStore

    const [fileFullUrl, fileThumbUrl] =
      await this.fileUploadingStore.uploadFile(
        fileImageCopy,
        UploadingType.Image,
        fileImageCopy.name,
      )
    return { url: fileFullUrl.fileURL, thumbUrl: fileThumbUrl.fileURL }
  }

  private get isEventPlaceholderActive(): CalendarEvent {
    return (
      this.calendarPlaceholderStore &&
      this.calendarPlaceholderStore.eventPlaceholder
    )
  }

  private get isInsideDeadline(): boolean {
    const { deadlineInterval } = this.deliveryConfigurations

    if (deadlineInterval === NO_DEADLINE_OPTION || !this.deliveryStartDate) {
      return false
    }

    const endDeadlineTime = this.projectDateStore.addWorkingDays(
      new Date(),
      deadlineInterval,
    )

    return isBefore(this.deliveryStartDate, endDeadlineTime)
  }

  private get deliveryStartDate(): Date {
    const startDate = this.getValue(FieldIds.DATE)

    if (!startDate) {
      return null
    }

    if (this.isCreateModeActive || this.areFieldsChanged) {
      const { combineISODateTime } = this.projectDateStore
      return combineISODateTime(startDate, this.getValue(FieldIds.START_TIME))
    }

    return new Date(this.displayedDelivery.startDate)
  }

  private updateEventPlaceholder() {
    if (this.isEventPlaceholderActive) {
      const isoStartDate: string = this.getValue(FieldIds.DATE)
      const isoEndDate: string = this.getValue(FieldIds.END_DATE)
      const startTime = this.getValue(FieldIds.START_TIME)
      const endTime = this.getValue(FieldIds.END_TIME)

      const { startDate, endDate } = this.getDates(
        isoStartDate,
        isoEndDate,
        startTime,
        endTime,
      )

      this.calendarPlaceholderStore.setEventPlaceholderTime(startDate, endDate)
    }
  }

  private setDraftDelivery() {
    if (!this.isEventPlaceholderActive) {
      return
    }

    const draftDelivery = Delivery.fromDto(this.getDeliveryDtoFromFields())
    this.calendarPlaceholderStore.setDraftEventData(draftDelivery)
  }

  private getDates(
    ISOStartDate: string,
    ISOEndDate: string,
    startTime: string,
    endTime: string,
  ) {
    const { combineISODateTime } = this.projectDateStore
    const startDate = combineISODateTime(ISOStartDate, startTime)
    let endDate = combineISODateTime(ISOEndDate || ISOStartDate, endTime)

    if (isBefore(endDate, startDate)) {
      endDate = this.projectDateStore.addDays(endDate, 1)
    }

    return {
      startDate,
      endDate,
    }
  }

  private resetLogHandles() {
    this.logPhotoUrls = null
    this.deliveryActionBarStore.shouldShowPreview = false
    this.deliveryActionBarStore.reset()
  }

  private get logMessage() {
    const { decodedDraftMessage: text } = this.deliveryActionBarStore

    const shouldAddMessage = this.logPhotoUrls || text

    return shouldAddMessage ? { ...this.logPhotoUrls, text } : null
  }

  private async handleDeliveryCreate(
    draftDelivery: Delivery, // without id and bookingId
    customPostAction?: (deliveryId: string) => void,
  ) {
    try {
      const recurringSetting = this.getValue(
        FieldIds.RECURRING_OPTIONS,
      ) as RecurringDeliveriesSettings

      const deliveryId = await this.deliveriesStore.createDelivery(
        draftDelivery,
        this.isRecurringOptionsShown && recurringSetting,
      )

      if (!deliveryId) {
        throw new Error('Delivery ID is missed')
      }

      let refToDeliveryInCollection = this.deliveriesStore.byId.get(deliveryId)
      if (!refToDeliveryInCollection) {
        // To make sure that the newly created delivery is in the collection
        // (before a message arrives from the socket)
        Object.assign(draftDelivery, { id: deliveryId })
        this.deliveriesStore.setOne(deliveryId, draftDelivery)
        refToDeliveryInCollection = draftDelivery
      }

      // To prevent the case in which the user switched to an existing delivery
      // without waiting for the completion of the request
      if (this.isCreateModeActive) {
        customPostAction?.(deliveryId)
        this.createDeliveryPostAction(refToDeliveryInCollection)
      }
    } catch (err) {
      if (err.message === 'ATTRIBUTES_ARE_NOT_VALID') {
        return this.notifyUserAboutNotValidAttrs()
      }

      throw err
    } finally {
      this.calendarPlaceholderStore.resetEventPlaceholder()
    }
  }

  private updateDeliveryPostAction = (shouldAddChangeStatus: boolean) => {
    if (!this.displayedDelivery?.isPending) {
      this.scrollToBottom()
    }

    if (shouldAddChangeStatus) {
      this.resetLogHandles()

      if (this.deliveryActionBarStore.mentionTags.length) {
        this.deliveryActionBarStore.subscribeMentionedUsers()
      }
    }
  }

  private createDeliveryPostAction = (delivery: Delivery) => {
    this.scrollToBottom()
    this.setChangeMode(delivery)
  }

  private async handleDeliveryUpdate(
    delivery: Delivery,
    shouldAddChangeStatus: boolean,
  ) {
    if (this.deliveryActionBarStore.fileImage) {
      this.logPhotoUrls = await this.uploadPhotoToLog()
      this.deliveryActionBarStore.hidePreview()
    }

    const recurringSetting = this.getValue(
      FieldIds.RECURRING_OPTIONS,
    ) as RecurringDeliveriesSettings

    if (this.isRecurringOptionsChanged && !this.recurringRelatedIds?.length) {
      this.recurringRelatedIds = [delivery.id]
    }

    await this.deliveriesStore.updateDelivery(
      delivery,
      this.logMessage,
      shouldAddChangeStatus,
      recurringSetting,
      this.recurringRelatedIds,
    )

    this.clearRecurringRelatedIds()
    this.updateDeliveryPostAction(shouldAddChangeStatus)
  }

  private scrollToBottom() {
    this.shouldScrollToBottom = true
  }

  @computed
  private get dateField(): IDeliveryControl {
    const id = FieldIds.DATE
    const { startDate } = this.selectedDates || {}
    const value = startDate
      ? this.projectDateStore.getDashedFormattedDate(startDate)
      : EMPTY_VALUE

    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.DATE,
      value,
      hints: [this.getFieldName(id), Localization.translator.startDate],
      isRequired: true,
      section: DeliveryDetailsSections.DATE_AND_TIME,
      isValid: this.isDateValid(value),
      isDisabled: this.isFromConcreteDirect,
      isChanged: this.isFieldChanged(id),
      additionalHelperText: this.getNonWorkDayMessage(),
      inline: this.isExpandedSelectDateMode ? 2 : 0,
      onClick: this.showSelectDateModal,
    }

    return field
  }

  @computed
  private get finishDateField(): IDeliveryControl {
    const id = FieldIds.END_DATE
    const { endDate } = this.selectedDates || {}
    const value = endDate
      ? this.projectDateStore.getDashedFormattedDate(endDate)
      : EMPTY_VALUE

    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.DATE,
      inline: 2,
      value,
      hints: [this.getFieldName(id)],
      isRequired: false,
      section: DeliveryDetailsSections.DATE_AND_TIME,
      isValid: true,
      isDisabled: this.isFromConcreteDirect,
      isChanged: this.isFieldChanged(id),
      additionalHelperText: this.getNonWorkDayMessage(),
      isHidden: !this.isExpandedSelectDateMode,
      onClick: this.showSelectDateModal,
    }

    return field
  }

  private isDateValid = (value: any): boolean => {
    return isWithinRange(
      new Date(value),
      new Date(MIN_ISO_DATE),
      new Date(MAX_ISO_DATE),
    )
  }

  private getStartOrEndDate = (shouldReturnEndDate?: boolean): Date => {
    const isoStartDate = this.getFieldStateValue(FieldIds.DATE, EMPTY_VALUE)
    const isoEndDate = this.getFieldStateValue(FieldIds.END_DATE, EMPTY_VALUE)
    const deliveryStartTime = this.getFieldStateValue(
      FieldIds.START_TIME,
      EMPTY_VALUE,
    )
    const deliveryEndTime = this.getFieldStateValue(
      FieldIds.END_TIME,
      EMPTY_VALUE,
    )

    const timeToCheck = shouldReturnEndDate
      ? deliveryEndTime
      : deliveryStartTime

    if (!isoStartDate || !timeToCheck) {
      return null
    }

    const { startDate, endDate } = this.getDates(
      isoStartDate,
      isoEndDate,
      deliveryStartTime,
      deliveryEndTime,
    )

    return shouldReturnEndDate ? endDate : startDate
  }

  private getNonWorkDayMessage = (): string => {
    const startDate = this.getStartOrEndDate()

    if (!startDate) {
      return null
    }

    return !this.projectDateStore.isWorkingDay(startDate) && nonWorkDay
  }

  @computed
  private get startTimeField(): IDeliveryControl {
    const id = FieldIds.START_TIME
    const value = this.getFieldStateValue(id, EMPTY_VALUE)

    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.TIME,
      inline: 2,
      value,
      hints: [this.getFieldName(id)],
      isRequired: true,
      section: DeliveryDetailsSections.DATE_AND_TIME,
      isValid: this.isTimeValid(),
      isDisabled: this.isFromConcreteDirect,
      isChanged: this.isFieldChanged(id),
      additionalHelperText: this.getNonWorkStartTimeMsg(),
      onClick: this.changeSelectedTimeControl,
    }

    return field
  }

  @computed
  private get endTimeField(): IDeliveryControl {
    const id = FieldIds.END_TIME
    const value = this.getFieldStateValue(id, EMPTY_VALUE)

    const duration =
      Localization.translator.duration +
      ': ' +
      this.getFieldStateValue(FieldIds.DURATION, START_OF_DAY)

    const timeOrDurationText = this.getNonWorkEndTimeMsg() || duration
    const helperText = this.isFromConcreteDirect
      ? Localization.translator.projected_time +
        (timeOrDurationText ? `, ${timeOrDurationText}` : EMPTY_STRING)
      : timeOrDurationText

    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.TIME,
      inline: 2,
      value,
      hints: [this.getFieldName(id)],
      isRequired: true,
      isDisabled: this.isFromConcreteDirect,
      section: DeliveryDetailsSections.DATE_AND_TIME,
      isValid: this.isTimeValid(),
      isChanged: this.isFieldChanged(id),
      additionalHelperText: helperText,
      onClick: this.changeSelectedTimeControl,
    }
    return field
  }

  @action.bound
  private changeSelectedTimeControl(field: FieldIds) {
    if (field === FieldIds.START_TIME || field === FieldIds.END_TIME) {
      this.selectedTimeControl = field
      return
    }

    this.disableTimeControlSelection()
  }

  @action.bound
  public disableTimeControlSelection() {
    this.selectedTimeControl = null
  }

  private isTimeValid = () => {
    const { startDate, endDate } = this.getDates(
      this.getFieldStateValue(FieldIds.DATE, EMPTY_VALUE),
      this.getFieldStateValue(FieldIds.END_DATE, EMPTY_VALUE),
      this.getFieldStateValue(FieldIds.START_TIME, EMPTY_VALUE),
      this.getFieldStateValue(FieldIds.END_TIME, EMPTY_VALUE),
    )

    const endDateMs = endDate.getTime()
    const startDateMs = startDate.getTime()
    return !isBefore(endDate, startDate) && startDateMs !== endDateMs
  }

  private getNonWorkStartTimeMsg = (): string => {
    const startDate = this.getStartOrEndDate()
    return !this.isDateInsideWorkingHours(startDate) && nonWorkHours
  }

  private getNonWorkEndTimeMsg = (): string => {
    const endDate = this.getStartOrEndDate(true)
    return !this.isDateInsideWorkingHours(endDate) && nonWorkHours
  }

  private isDateInsideWorkingHours = (date: Date): boolean => {
    if (!date) {
      return true
    }

    const { areTimesInsideWorkingHours, isWorkingDay } = this.projectDateStore
    return isWorkingDay(date) && areTimesInsideWorkingHours(date, date)
  }

  @computed
  private get durationField(): IDeliveryControl {
    const id = FieldIds.DURATION
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.TEXT,
      inline: 2,
      value: this.getFieldStateValue(id, START_OF_DAY),
      hints: [this.getFieldName(id)],
      isReadOnly: true,
      isRequired: true,
      isUtility: true,
      isValid: true,
      isChanged: false,
      section: DeliveryDetailsSections.DATE_AND_TIME,
      isHidden: true,
    }

    return field
  }

  @computed
  private get recurringOptionsField(): IDeliveryControl {
    const id = FieldIds.RECURRING_OPTIONS
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.TOGGLE,
      value: this.getFieldStateValue(id),
      hints: [],
      isValid: this.isRecurringOptionsValid(),
      isChanged: this.isFieldChanged(id),
      section: DeliveryDetailsSections.DATE_AND_TIME,
    }

    return field
  }

  private isRecurringOptionsValid(): boolean {
    const setting = this.getFieldStateValue(
      FieldIds.RECURRING_OPTIONS,
    ) as RecurringDeliveriesSettings

    if (!setting) {
      return true
    }

    const { frequency, frequencyType, endDate, selectedDays } = setting

    if (!frequencyType || frequencyType === CalendricalType.Month) {
      return false
    }

    if (frequencyType === CalendricalType.Day) {
      return (
        frequency > 0 && frequency <= MAX_RECURRING_DAYS_FREQUENCY && !!endDate
      )
    }

    return (
      frequency > 0 &&
      frequency <= MAX_RECURRING_WEEKS_FREQUENCY &&
      !!endDate &&
      !!selectedDays?.length
    )
  }

  @computed
  private get lateRequestField(): IDeliveryControl {
    const id = FieldIds.LATE_REQUEST
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.LABEL,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.lateLabelHint],
      isReadOnly: true,
      isRequired: false,
      isUtility: true,
      section: DeliveryDetailsSections.DATE_AND_TIME,
      isChanged: false,
      isValid: true,
    }
    return field
  }

  private get lateLabelHint() {
    const { deadlineInterval } = this.deliveryConfigurations

    if (this.isCreateModeActive) {
      return `This delivery was not requested ${getDeadlineOptionText(
        deadlineInterval,
      )} in advance`
    }

    return 'This delivery was not requested in advance'
  }

  @computed
  private get nonWorkTimeField(): IDeliveryControl {
    const id = FieldIds.NON_WORK_TIMES
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.LABEL,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [thisDeliveryWasRequestedForNonWorkTime],
      isReadOnly: true,
      isRequired: false,
      isUtility: true,
      section: DeliveryDetailsSections.DATE_AND_TIME,
      isChanged: false,
      isValid: true,
    }
    return field
  }

  @computed
  private get unscheduledRequestField(): IDeliveryControl {
    const id = FieldIds.UNSCHEDULED_REQUEST
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.TOGGLE_BUTTON,
      value: this.getFieldStateValue(id, false),
      hints: [EMPTY_VALUE],
      isRequired: false,
      defaultValue: false,
      section: DeliveryDetailsSections.DATE_AND_TIME,
      isValid: true,
      isHidden: true,
      isChanged: this.isFieldChanged(id),
    }

    return field
  }

  private get weatherForecastField(): IDeliveryControl {
    const id = FieldIds.WEATHER_FORECAST

    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.WEATHER_FORECAST,
      value: null,
      hints: [EMPTY_VALUE],
      isRequired: false,
      defaultValue: false,
      section: DeliveryDetailsSections.DATE_AND_TIME,
      isValid: true,
    }

    return field
  }

  @computed
  private get zoneField(): IDeliveryControl {
    const id = FieldIds.ZONE
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      options: this.getZonesOptions(),
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: this.isFromToModeEnabled
        ? DeliveryDetailsSections.LOCATION_TO
        : DeliveryDetailsSections.LOCATION,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  @computed
  private get stagingField(): IDeliveryControl {
    const id = FieldIds.STAGING
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      options: this.getStagingOptions(),
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: this.isFromToModeEnabled
        ? DeliveryDetailsSections.LOCATION_TO
        : DeliveryDetailsSections.LOCATION,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  @computed
  private get interiorDoorField(): IDeliveryControl {
    const id = FieldIds.INTERIOR_DOOR
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      options: this.getInteriorDoorOptions(),
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: this.isFromToModeEnabled
        ? DeliveryDetailsSections.LOCATION_TO
        : DeliveryDetailsSections.LOCATION,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  @computed
  private get interiorPathField(): IDeliveryControl {
    const id = FieldIds.INTERIOR_PATH
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      options: this.getInteriorPathOptions(),
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: this.isFromToModeEnabled
        ? DeliveryDetailsSections.LOCATION_TO
        : DeliveryDetailsSections.LOCATION,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  @computed
  private get areaField(): IDeliveryControl {
    const id = FieldIds.AREA
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      options: this.getAreaOptions(),
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: this.isFromToModeEnabled
        ? DeliveryDetailsSections.LOCATION_TO
        : DeliveryDetailsSections.LOCATION,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  @computed
  private get gateField(): IDeliveryControl {
    const id = FieldIds.GATE
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      options: this.getGatesOptions(),
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: this.isFromToModeEnabled
        ? DeliveryDetailsSections.LOCATION_TO
        : DeliveryDetailsSections.LOCATION,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  @computed
  private get fromToSwitch(): IDeliveryControl {
    const id = FieldIds.FROM_TO_SWITCH
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.CHECKBOX,
      value: this.getFieldStateValue(id, false),
      hints: [this.getFieldName(id)],
      isRequired: false,
      section: this.isFromToModeEnabled
        ? DeliveryDetailsSections.LOCATION_FROM
        : DeliveryDetailsSections.LOCATION,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, false),
      isDisabled: true,
      isHidden: !this.getFieldStateValue(id),
    }

    return field
  }

  @computed
  private get buildingField(): IDeliveryControl {
    const id = FieldIds.BUILDING
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      options: this.buildingOptions,
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: this.isFromToModeEnabled
        ? DeliveryDetailsSections.LOCATION_TO
        : DeliveryDetailsSections.LOCATION,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  @computed
  private get levelField(): IDeliveryControl {
    const id = FieldIds.LEVEL
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      options: this.getLevelOptions(),
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: this.isFromToModeEnabled
        ? DeliveryDetailsSections.LOCATION_TO
        : DeliveryDetailsSections.LOCATION,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  @computed
  private get routeField(): IDeliveryControl {
    const id = FieldIds.ROUTE
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      options: this.getRouteOptions(),
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: this.isFromToModeEnabled
        ? DeliveryDetailsSections.LOCATION_TO
        : DeliveryDetailsSections.LOCATION,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  @computed
  private get installationZoneField(): IDeliveryControl {
    const id = FieldIds.INSTALLATION_ZONE
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.TEXT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      section: this.isFromToModeEnabled
        ? DeliveryDetailsSections.LOCATION_TO
        : DeliveryDetailsSections.LOCATION,
      isValid: true,
      isChanged: this.isFieldChanged(id),
    }

    return field
  }

  @computed
  private get fromBuildingField(): IDeliveryControl {
    const id = FieldIds.FROM_BUILDING
    const parentId = FieldIds.BUILDING
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(parentId)],
      isRequired: this.isFieldRequired(parentId),
      options: this.buildingOptions,
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: DeliveryDetailsSections.LOCATION_FROM,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  @computed
  private get fromLevelField(): IDeliveryControl {
    const id = FieldIds.FROM_LEVEL
    const parentId = FieldIds.LEVEL
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(parentId)],
      isRequired: this.isFieldRequired(parentId),
      options: this.getLevelOptions(
        this.fromBuildingField.value,
        this.fromZoneField.value,
      ),
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: DeliveryDetailsSections.LOCATION_FROM,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  @computed
  private get fromZoneField(): IDeliveryControl {
    const id = FieldIds.FROM_ZONE
    const parentId = FieldIds.ZONE
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(parentId)],
      isRequired: this.isFieldRequired(parentId),
      options: this.getZonesOptions(this.fromBuildingField.value),
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: DeliveryDetailsSections.LOCATION_FROM,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  @computed
  private get fromStagingField(): IDeliveryControl {
    const id = FieldIds.FROM_STAGING
    const parentId = FieldIds.STAGING
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(parentId)],
      isRequired: this.isFieldRequired(parentId),
      options: this.getStagingOptions(
        this.fromBuildingField.value,
        this.fromZoneField.value,
      ),
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: DeliveryDetailsSections.LOCATION_FROM,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  @computed
  private get fromAreaField(): IDeliveryControl {
    const id = FieldIds.FROM_AREA
    const parentId = FieldIds.AREA
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(parentId)],
      isRequired: this.isFieldRequired(parentId),
      options: this.getAreaOptions(
        this.fromBuildingField.value,
        this.fromZoneField.value,
        this.fromLevelField.value,
      ),
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: DeliveryDetailsSections.LOCATION_FROM,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  @computed
  private get fromInstallationZoneField(): IDeliveryControl {
    const id = FieldIds.FROM_INSTALLATION_ZONE
    const parentId = FieldIds.INSTALLATION_ZONE
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.TEXT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(parentId)],
      isRequired: this.isFieldRequired(parentId),
      section: DeliveryDetailsSections.LOCATION_FROM,
      isValid: true,
      isChanged: this.isFieldChanged(id),
    }

    return field
  }

  @computed
  private get vendorCompanyField(): IDeliveryControl {
    const id = FieldIds.VENDOR

    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.COMPANY_SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      defaultValue: EMPTY_VALUE,
      hints: [this.getFieldName(id)],
      options: this.vendorCompanyFieldOptions,
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      isGroupedOptions: true,
      isRequired: this.isFieldRequired(id),
      section: DeliveryDetailsSections.SUPPLIER,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
      canBeExtended: true,
      addOption: this.addVendorCompany,
    }

    return field
  }

  @computed
  private get companyField(): IDeliveryControl {
    const id = FieldIds.COMPANY
    const defaultValue = this.isFromConcreteDirect
      ? EMPTY_STRING
      : this.userCompanyId

    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.COMPANY_SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      defaultValue,
      hints: [this.getFieldName(id)],
      options: this.companyFieldOptions,
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      isGroupedOptions: true,
      isRequired: this.isFieldRequired(id),
      section: DeliveryDetailsSections.CONTACT,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  @computed
  public get companyFieldOptions(): IDeliveryControlOption[] {
    const companyTypeBands = this.tagsStore.tagListsByTagTypeMap[
      TagType.CompanyType
    ]
      .slice()
      .sort(
        (a, b) =>
          getSortIndexByCompanyType(a.name) - getSortIndexByCompanyType(b.name),
      )

    return companyTypeBands.reduce(
      (list, companyType) => {
        const companiesByType =
          this.companiesStore.availableSortedCompanies.filter(c =>
            c.typeTags.includes(companyType.id),
          )

        if (!companiesByType.length) {
          return list
        }

        list.push({
          isCategoryLabel: true,
          value: companiesByType.length.toString(),
          title: companyType.name,
        })
        list.push(
          ...companiesByType.map(c => {
            return {
              isCompanyValue: true,
              value: c.id,
              title: c.name,
            }
          }),
        )

        return list
      },
      [
        {
          value: EMPTY_VALUE,
          title: EMPTY_VALUE,
        },
      ] as IDeliveryControlOption[],
    )
  }

  @computed
  public get vendorCompanyFieldOptions(): IDeliveryControlOption[] {
    const companyTypeBands = this.tagsStore.tagListsByTagTypeMap[
      TagType.CompanyType
    ]
      .slice()
      .sort(
        (a, b) =>
          getSortIndexForVendorField(a.name) -
          getSortIndexForVendorField(b.name),
      )

    return companyTypeBands.reduce(
      (list, companyType) => {
        const companiesByType =
          this.companiesStore.availableSortedCompanies.filter(c =>
            c.typeTags.includes(companyType.id),
          )

        if (!companiesByType.length) {
          return list
        }

        list.push({
          isCategoryLabel: true,
          value: companiesByType.length.toString(),
          title: companyType.name,
        })
        list.push(
          ...companiesByType.map(c => {
            return {
              isCompanyValue: true,
              value: c.id,
              title: c.name,
            }
          }),
        )

        return list
      },
      [
        {
          value: EMPTY_VALUE,
          title: EMPTY_VALUE,
        },
      ] as IDeliveryControlOption[],
    )
  }

  @computed
  private get onSiteContactPersonFields(): IDeliveryControl {
    const id = FieldIds.ON_SITE_CONTACTS
    const companyId: string = this.getFieldStateValue(FieldIds.COMPANY)
    const value = this.getFieldStateValue(id, [])

    const field: IDeliveryControl = {
      id,
      index: this.selectedFieldIndex,
      isMultiValueInput: true,
      type: DeliveryControlTypes.USER_SELECT,
      value: value,
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      section: DeliveryDetailsSections.CONTACT,
      options: this.onSiteContactPersonsOptions,
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      isGroupedOptions: true,
      getCategoryLabelIcon: Icons.Company,
      firstCompanyId: companyId,
      isValid: true,
      isChanged:
        !!this.displayedDelivery &&
        this.areFieldValueDifferent(id, this.initialArrayValues.get(id)),
      newOptionName: this.getFieldStateNewOptionName(
        id,
        EMPTY_VALUE,
        this.selectedFieldIndex,
      ),
    }

    return field
  }

  @computed
  private get onSiteContactPersonsOptions(): IDeliveryControlOption[] {
    return Object.keys(this.usersByCompaniesMap)
      .sort(this.getCompanyPredicate(this.userCompanyId))
      .reduce((list, key) => {
        const users = this.usersByCompaniesMap[key]
        const keys = key.split(COMPANY_SEPARATOR)

        const companyName = keys[1] || noCompany

        list.push({
          isCategoryLabel: true,
          value: users.length.toString(),
          title: companyName,
        })
        list.push(
          ...users.map(u => ({
            isUserValue: true,
            value: u.id,
            title: User.getFullNameToDisplay(u, this.userProjectsStore),
          })),
        )

        return list
      }, [] as IDeliveryControlOption[])
  }

  private getCompanyPredicate = (firstCompanyId: string) => {
    return (a: string, b: string) => {
      const aKeys = a.split(COMPANY_SEPARATOR)
      const bKeys = b.split(COMPANY_SEPARATOR)

      const companyAId = aKeys[0]
      const companyBId = bKeys[0]

      if (companyAId === firstCompanyId) {
        return -1
      }

      if (companyBId === firstCompanyId) {
        return 1
      }

      return sortCompaniesByTypeAndNamePredicate(
        companyAId,
        companyBId,
        this.getCompanyById,
        this.companiesStore.getCompanyTypeTagsByIds,
      )
    }
  }

  private get usersByCompaniesMap(): { [company: string]: User[] } {
    return this.projectMembersStore.list.reduce((map, user) => {
      const companyId = UserProject.getCompanyId(user, this.userProjectsStore)
      const companyFullName = this.companiesStore.getCompanyFullName(companyId)

      const fullCompanyName = companyFullName || noCompany

      const mapKey = `${
        companyId || noCompany
      }${COMPANY_SEPARATOR}${fullCompanyName}`

      if (map[mapKey] && !map[mapKey].includes(user)) {
        map[mapKey].push(user)
      } else {
        map[mapKey] = [user]
      }

      return map
    }, {} as { [company: string]: User[] })
  }

  @computed
  private get offloadingEquipmentField(): IDeliveryControl {
    const id = FieldIds.OFFLOADING_EQUIPMENT
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.MULTI_COMBOBOX,
      index: this.selectedFieldIndex,
      canBeExtended: this.canFieldBeExtendedAccordingToConfig(id),
      value: this.getFieldStateValue(id, []),
      defaultValue: [],
      hints: [this.getFieldName(id)],
      options: this.offloadingEquipmentOptions,
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: DeliveryDetailsSections.OFFLOADING_EQUIPMENT,
      addOption: this.addOffloadingEquipment,
      isRequired: this.isFieldRequired(id),
      newOptionName: this.getFieldStateNewOptionName(
        id,
        EMPTY_VALUE,
        this.selectedFieldIndex,
      ),
      isValid: true,
      isMultiValueInput: true,
      isChanged:
        !!this.displayedDelivery &&
        this.areFieldValueDifferent(id, this.initialArrayValues.get(id)),
    }

    return field
  }

  @computed
  private get numberOfEquipmentPicksField(): IDeliveryControl {
    const id = FieldIds.NUMBER_OF_EQUIPMENT_PICKS
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.NUMBER,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      defaultValue: EMPTY_VALUE,
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      section: DeliveryDetailsSections.OFFLOADING_EQUIPMENT,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      min: 0,
    }

    return field
  }

  @action.bound
  private async addOffloadingEquipment(name: string) {
    if (name) {
      const { id } =
        await this.locationAttributesStore.offloadingEquipmentsStore.createFromName(
          name,
        )
      this.onEquipmentFieldChange(
        this.offloadingEquipmentField.id,
        id,
        this.offloadingEquipmentField.index,
      )
    }
  }

  @computed
  private get deliveryVehicleTypeField(): IDeliveryControl {
    const id = FieldIds.TRUCK_SIZE
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.SELECT,
      canBeExtended: this.canFieldBeExtendedAccordingToConfig(id),
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
      defaultValue: EMPTY_VALUE,
      hints: [this.getFieldName(id)],
      options: this.deliveryVehicleTypeOptions,
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      getOptionsIcon: Icons.Truck,
      isRequired: this.isFieldRequired(id),
      isDisabled: this.isFromConcreteDirect,
      section: DeliveryDetailsSections.VEHICLES,
      addOption: this.addTruck,
      isValid: true,
      isChanged: this.isFieldChanged(id),
    }

    return field
  }

  private canFieldBeExtendedAccordingToConfig = (id: FieldIds) => {
    return !this.deliveryConfigurations.unextendableFields.includes(id)
  }

  @action.bound
  private addTruck(name: string) {
    if (name) {
      this.deliveryVehicleTypesStore.createFromName(
        name,
        this.postAddTruckSuccessCb.bind(this, FieldIds.TRUCK_SIZE),
      )
    }
  }

  @action.bound
  private postAddTruckSuccessCb(
    fieldId: FieldIds,
    { saveManyDeliveryVehicleTypes }: ISaveDeliveryVehicleTypesMutation,
  ) {
    const [deliveryVehicleType] = saveManyDeliveryVehicleTypes
    this.onCommonChange(fieldId, deliveryVehicleType.id)
  }

  @computed
  private get numberOfVehiclesField(): IDeliveryControl {
    const id = FieldIds.TRUCK_NUMBER
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.NUMBER,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      defaultValue: EMPTY_VALUE,
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      section: DeliveryDetailsSections.VEHICLES,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      min: 0,
    }

    return field
  }

  @computed
  private get truckLicensePlateField(): IDeliveryControl {
    const id = FieldIds.TRUCK_LICENSE_PLATE
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.TEXT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      defaultValue: EMPTY_VALUE,
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      section: DeliveryDetailsSections.VEHICLES,
      isValid: true,
      isChanged: this.isFieldChanged(id),
    }

    return field
  }

  @computed
  private get materialFields(): IDeliveryControl[] {
    const { hiddenFields } = this.appState.delivery
    const shouldIncludeTitle = this.materialsSectionsCount > 1
    const areMaterialsEnabled =
      !this.isCSICategoryDisabled || !this.isCSISubCategoryDisabled

    return Array.from(Array(this.materialsSectionsCount).keys()).reduce(
      (fields, colIdx) => {
        const idx = colIdx + 1

        let materialFields = []
        if (!this.isMaterialRemoved(idx)) {
          materialFields = [
            !this.isCSICategoryDisabled && this.getMaterialCategoryField(idx),
            /*
              please do not return !this.isCSISubCategoryDisabled here,
              use the isHidden prop inside the field instead
            */
            this.getMaterialField(idx),
            ...((areMaterialsEnabled && [
              this.getMaterialProcurementField(idx),
              this.getMaterialInstallLocationField(idx),
            ]) ||
              []),
            ...((!hiddenFields[FieldIds.MATERIAL_QUANTITY] && [
              this.getQuantityField(idx),
              this.getUnitsField(idx),
            ]) ||
              []),
            !hiddenFields[FieldIds.MATERIAL_LOCATION] &&
              this.getMaterialLocationField(idx),
            !hiddenFields[FieldIds.MATERIAL_NOTE] &&
              this.getMaterialNoteField(idx),
          ].filter(f => f)
        }

        const titleField = shouldIncludeTitle
          ? this.getMaterialSectionSubTitleField(idx)
          : []
        fields = fields.concat(titleField, materialFields)

        return fields
      },
      [],
    )
  }

  private getMaterialSectionSubTitleField(index: number): IDeliveryControl {
    const id = FieldIds.MATERIALS_SECTION
    const field: IDeliveryControl = {
      id,
      index,
      type: DeliveryControlTypes.LABEL,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [],
      section: DeliveryDetailsSections.MATERIALS,
      isRemoved: this.isMaterialRemoved(index),
      isReadOnly: true,
      isRequired: false,
      isChanged: this.isMaterialRemoved(index),
      isValid: true,
    }

    return field
  }

  private getMaterialCategoryField = (index: number): IDeliveryControl => {
    const id = FieldIds.MATERIAL_CATEGORY
    const field: IDeliveryControl = {
      id,
      index,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE, index),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE, index),
      hints: [this.getFieldName(id)],
      options: this.materialCategoriesOptions,
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: DeliveryDetailsSections.MATERIALS,
      isRequired: this.isFieldRequired(id),
      isDisabled: this.isFromConcreteDirect,
      isValid: this.isMaterialSectionValid(index),
      isChanged: this.isFieldChanged(id, index),
    }

    return field
  }

  private getMaterialField = (index: number): IDeliveryControl => {
    const id = FieldIds.MATERIAL
    const field: IDeliveryControl = {
      id,
      index,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE, index),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE, index),
      hints: [this.getFieldName(id)],
      options: this.getMaterialOptions(index),
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      section: DeliveryDetailsSections.MATERIALS,
      isRequired: this.isFieldRequired(id),
      isDisabled: this.isFromConcreteDirect,
      isHidden: this.isFromConcreteDirect || this.isCSISubCategoryDisabled,
      isValid: this.isMaterialSectionValid(index),
      isChanged: this.isFieldChanged(id, index),
    }

    return field
  }

  private getMaterialProcurementField = (index: number): IDeliveryControl => {
    const id = FieldIds.MATERIAL_PROCUREMENT_ID
    const field: IDeliveryControl = {
      id,
      index,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE, index),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE, index),
      hints: [this.getFieldName(id)],
      searchHints: [
        Localization.translator.id_short,
        Localization.translator.plannedInstallLocation,
      ],
      options: this.getMaterialProcurementOptions(index),
      section: DeliveryDetailsSections.MATERIALS,
      isRequired: this.isProcurementIdRequired(index),
      isDisabled: this.isFromConcreteDirect || !this.hasMaterialProcurementIds,
      isHidden: this.isFromConcreteDirect || !this.hasMaterialProcurementIds,
      isValid: this.isMaterialSectionValid(index),
      isChanged: this.isFieldChanged(id, index),
      searchPredicate: new DeliveryControlSearchPredicateBase(),
    }

    return field
  }

  private getMaterialInstallLocationField = (
    index: number,
  ): IDeliveryControl => {
    const id = FieldIds.MATERIAL_INSTALL_LOCATION
    const material = this.materialsStore.getInstanceById(
      this.getFieldStateValue(FieldIds.MATERIAL, null, index),
    )
    const locationId = material?.getProcurementDataById(
      this.getFieldStateValue(FieldIds.MATERIAL_PROCUREMENT_ID, null, index),
    )?.installLocationId
    const location = this.locationAttributesStore.getById(locationId)

    const field: IDeliveryControl = {
      id,
      index,
      type: DeliveryControlTypes.LOCATION_LABEL,
      value: location,
      hints: [Localization.translator.plannedInstallLocation],
      section: DeliveryDetailsSections.MATERIALS,
      isReadOnly: true,
      isUtility: true,
      isValid: true,
      isHidden: this.isFromConcreteDirect || !this.hasMaterialProcurementIds,
      searchPredicate: new DeliveryControlSearchPredicateBase(),
    }

    return field
  }

  private getMaterialNoteField = (index: number): IDeliveryControl => {
    const id = FieldIds.MATERIAL_NOTE
    const field: IDeliveryControl = {
      id,
      index,
      type: DeliveryControlTypes.LINKIFIED_TEXT_AREA,
      value: this.getFieldStateValue(id, EMPTY_VALUE, index),
      hints: [this.getFieldName(id)],
      section: DeliveryDetailsSections.MATERIALS,
      isRequired: this.isFieldRequired(id),
      isDisabled: this.isFromConcreteDirect,
      isValid: this.isMaterialSectionValid(index),
      isChanged: this.isFieldChanged(id, index),
    }

    return field
  }

  private getQuantityField = (index: number): IDeliveryControl => {
    const id = FieldIds.MATERIAL_QUANTITY
    const field: IDeliveryControl = {
      id,
      index,
      type: DeliveryControlTypes.NUMBER,
      inline: 2,
      value: this.getFieldStateValue(id, EMPTY_VALUE, index),
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      isDisabled: this.isFromConcreteDirect,
      section: DeliveryDetailsSections.MATERIALS,
      isValid: this.isMaterialSectionValid(index),
      isChanged: this.isFieldChanged(id, index),
      min: 0,
    }

    return field
  }

  private getUnitsField = (index: number): IDeliveryControl => {
    const id = FieldIds.MATERIAL_UNITS
    const field: IDeliveryControl = {
      id,
      index,
      type: DeliveryControlTypes.SELECT,
      canBeExtended: true,
      inline: 2,
      value: this.getFieldStateValue(id, EMPTY_VALUE, index),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE, index),
      defaultValue: EMPTY_VALUE,
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(FieldIds.MATERIAL_QUANTITY),
      isDisabled: this.isFromConcreteDirect,
      options: this.deliveryUnitsOptions,
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      getOptionsIcon: Icons.Measure,
      section: DeliveryDetailsSections.MATERIALS,
      addOption: this.addUnit,
      isValid: this.isMaterialSectionValid(index),
      isChanged: this.isFieldChanged(id, index),
    }

    return field
  }

  private getMaterialLocationField = (index: number): IDeliveryControl => {
    const id = FieldIds.MATERIAL_LOCATION
    const field: IDeliveryControl = {
      id,
      index,
      type: DeliveryControlTypes.SELECT,
      section: DeliveryDetailsSections.MATERIALS,

      hints: [this.getFieldName(id)],

      value: this.getFieldStateValue(id, EMPTY_VALUE, index),

      isGroupedOptions: true,
      areCategoriesCollapsible: true,
      options: this.getMaterialLocationsOptions(id, index),
      searchPredicate: new DeliveryControlSearchPredicateBase(),

      isRequired: this.isFieldRequired(id),
      isDisabled: this.isFromConcreteDirect,
      isValid: this.isMaterialSectionValid(index),
      isChanged: this.isFieldChanged(id, index),

      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE, index),
    }

    return field
  }

  private getMaterialLocationsOptions(
    fieldId: FieldIds,
    fieldIndex: number,
  ): IDeliveryControlOption[] {
    const fieldSearchKey = this.getFieldStateNewOptionName(
      fieldId,
      EMPTY_VALUE,
      fieldIndex,
    )

    return deliveryRelatedLocationTagTypes.reduce((options, locationType) => {
      const list = this.tagsStore.tagListsByTagTypeMap[locationType]

      if (!list?.length) return options

      const categoryId = locationType
      const isCategoryCollapsed = this.fieldCategoryCollapsingMap.get(
        getFieldCategoryId(fieldId, categoryId),
      )

      options.push({
        categoryId,
        isCategoryLabel: true,
        value: list.length.toString(),
        title: getBandTitleByTagType(locationType),
        isCategoryCollapsed,
      })

      if (
        isCategoryCollapsed &&
        !fieldSearchKey &&
        this.isFieldSelected(fieldId, fieldIndex)
      )
        return options

      const categoryOptions = this.tagsStore.tagListsByTagTypeMap[
        locationType
      ].map((l: LocationAttributeBase) => ({
        value: l.id,
        title: l.name,
        relatedAttribute: l,
        hierarchyChains: l.getHierarchyChains(
          this.tagsStore.tagStoreByTagTypeMap,
        ),
        hierarchyChainsObjects: l.getHierarchyChainsObjects(
          this.tagsStore.tagStoreByTagTypeMap,
        ),
      }))

      options.push(...categoryOptions.sort(this.sortByParentAndName))

      return options
    }, [] as IDeliveryControlOption[])
  }

  @computed
  private get materialCategoriesOptions() {
    const { selectedCategoriesWithoutCd, selectedCategoriesWithCd } =
      this.materialCategoryStore
    return this.getDropDownOptions({
      list: this.displayedDelivery?.isFromConcreteDirect
        ? selectedCategoriesWithCd
        : selectedCategoriesWithoutCd,
      isDefaultOptionHidden: true,
    })
  }

  private get isCSICategoryDisabled(): boolean {
    return this.eventsStore.appState.projectMaterialOptions?.isCSIDisabled
  }

  private get isCSISubCategoryDisabled(): boolean {
    return this.eventsStore.appState.projectMaterialOptions
      ?.isCSISubCategoriesDisabled
  }

  private getMaterialOptions = (fieldIndex: number) => {
    const materialNameKey = 'productName'

    const { hasInstanceById: hasCategoryById } = this.materialCategoryStore
    const { getInstanceById: getMaterialById } = this.materialsStore

    const list = this.materialsStore.selectedMaterials.reduce((res, m) => {
      if (hasCategoryById(m.categoryId) && m.productName) {
        res.push(m)
      }

      return res
    }, [] as Material[])

    if (this.displayedDelivery?.isFromConcreteDirect) {
      this.displayedDelivery.materialIds?.forEach(id => {
        if (!list.some(m => m.id === id)) {
          list.push(getMaterialById(id))
        }
      })
    }

    const selectedCategoryId = this.getFieldStateValue(
      FieldIds.MATERIAL_CATEGORY,
      null,
      fieldIndex,
    )

    if (!hasCategoryById(selectedCategoryId)) {
      return this.getDropDownOptions({
        list: list,
        isDefaultOptionHidden: false,
        hasRelatedAttribute: false,
        nameKey: materialNameKey,
      })
    }

    const categoryMaterials = list.filter(
      m => m.categoryId === selectedCategoryId,
    )

    return this.getDropDownOptions({
      list: categoryMaterials,
      isDefaultOptionHidden: false,
      hasRelatedAttribute: false,
      nameKey: materialNameKey,
    })
  }

  private getMaterialProcurementOptions = (
    fieldIndex: number,
  ): IDeliveryControlOption[] => {
    const { selectedMaterials, getInstanceById: getMaterialById } =
      this.materialsStore

    const category = this.materialCategoryStore.getInstanceById(
      this.getFieldStateValue(FieldIds.MATERIAL_CATEGORY, null, fieldIndex),
    )
    const material = getMaterialById(
      this.getFieldStateValue(FieldIds.MATERIAL, null, fieldIndex),
    )

    return selectedMaterials.reduce((res, m) => {
      if (category?.hasProcurementIds && m.categoryId !== category.id) {
        return res
      }
      if (material?.hasProcurementIds && m.id !== material.id) {
        return res
      }

      m.procurementDataList?.forEach(pr =>
        res.push({
          value: pr.procurementId,
          title: pr.procurementId,
          cardValues: this.getProcurementCardOptions(pr, m),
        }),
      )
      return res
    }, [] as IDeliveryControlOption[])
  }

  private getProcurementCardOptions = (
    procurementData: IMaterialProcurementData,
    material: Material,
  ): IDeliveryControlOptionCard[] => {
    const installLocation = this.locationAttributesStore.getById(
      procurementData.installLocationId,
    )
    const deliveryLocation = this.locationAttributesStore.getById(
      procurementData.deliveryLocationId,
    )

    return [
      {
        title: Localization.translator.procurementID,
        value: procurementData.procurementId,
        hasBoldFont: true,
      },
      {
        title: this.getFieldName(FieldIds.MATERIAL_CATEGORY),
        value: this.materialCategoryStore.getCategoryNameById(
          material.categoryId,
        ),
      },
      {
        title: this.getFieldName(FieldIds.MATERIAL),
        value: material.productName,
      },
      {
        title: Localization.translator.description,
        value: procurementData.description,
      },
      {
        title: Localization.translator.plannedInstallLocation,
        value: installLocation?.name,
        location: installLocation,
        isSearchable: true,
      },
      {
        title: Localization.translator.plannedDeliveryLocation,
        value: deliveryLocation?.name,
        location: deliveryLocation,
      },
      {
        title: Localization.translator.plannedQuantity,
        value: procurementData.plannedQuantity?.toString(),
      },
    ]
  }

  private isProcurementIdRequired = (idx: number): boolean => {
    const selectedCategoryId = this.getFieldStateValue(
      FieldIds.MATERIAL_CATEGORY,
      null,
      idx,
    )
    const selectedMaterialId = this.getFieldStateValue(
      FieldIds.MATERIAL,
      null,
      idx,
    )

    return (
      this.materialCategoryStore.getInstanceById(selectedCategoryId)
        ?.hasProcurementIds ||
      this.materialsStore.getInstanceById(selectedMaterialId)?.hasProcurementIds
    )
  }

  @action.bound
  private addUnit(name: string, fieldIndex?: number) {
    if (name) {
      this.deliveryUnitsStore.createFromName(
        name,
        this.postAddUnitSuccessCb.bind(
          this,
          FieldIds.MATERIAL_UNITS,
          fieldIndex,
        ),
      )
    }
  }

  @action.bound
  public addVendorCompany(name: string, fieldIndex?: number) {
    this.companiesStore.addVendorCompanyByName(name, id =>
      this.updateFieldStateValue(FieldIds.VENDOR, id, fieldIndex),
    )
  }

  @action.bound
  private handleVendorFieldChange(value: string) {
    if (!value) {
      return this.updateFieldStateValue(FieldIds.VENDOR, EMPTY_VALUE)
    }
    const vendorCompanyTypeTag = this.tagsStore.tagListsByTagTypeMap[
      TagType.CompanyType
    ].find(tag => tag.name === DefaultCompanyType.Vendor)

    const company = this.companiesStore.getCompanyById(value)

    if (company.typeTags.includes(vendorCompanyTypeTag.id)) {
      this.updateFieldStateValue(FieldIds.VENDOR, value)
    } else {
      this.isAddVendorTagModalOpened = true
      this.selectedVendor = value
    }
  }

  @action.bound
  public confirmAddVendorTag() {
    this.companiesStore.addVendorTagToCompany(this.selectedVendor, id =>
      this.updateFieldStateValue(FieldIds.VENDOR, id),
    )
    this.closeAddVendorTagModal()
  }

  @action.bound
  public closeAddVendorTagModal() {
    this.isAddVendorTagModalOpened = false
    this.selectedVendor = null
  }

  @action
  private postAddUnitSuccessCb(
    fieldId: FieldIds,
    fieldIndex: number,
    { saveManyDeliveryUnits }: ISaveDeliveryUnitsMutation,
  ) {
    const [deliveryUnit] = saveManyDeliveryUnits
    this.onCommonChange(fieldId, deliveryUnit.id, fieldIndex)
  }

  private isMaterialSectionValid = (index: number): boolean => {
    const materialFieldsIds = [FieldIds.MATERIAL_CATEGORY]
    const areAllValuesEmpty = materialFieldsIds.every(
      id => !this.getFieldStateValue(id, null, index),
    )
    const areAllValuesFilled = materialFieldsIds.every(id =>
      this.getFieldStateValue(id, null, index),
    )
    return areAllValuesEmpty || areAllValuesFilled
  }

  @computed
  private get isInspectionField(): IDeliveryControl {
    const id = FieldIds.IS_INSPECTION_REQUIRED
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.TOGGLE,
      value: this.getFieldStateValue(id, '0'),
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      isDisabled: !this.isInspectionToggleable,
      section: DeliveryDetailsSections.INSPECTION_REQUIRED,
      isValid: true,
      isChanged: this.isFieldChanged(id),
    }

    return field
  }

  private get isInspectionToggleable() {
    return !this.appState.delivery.mandatoryFields[
      FieldIds.IS_INSPECTION_REQUIRED
    ]
  }

  @computed
  private get inspectorField(): IDeliveryControl {
    const id = FieldIds.INSPECTOR
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.USER_SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      defaultValue: EMPTY_VALUE,
      hints: [this.getFieldName(id)],
      isRequired: false,
      options: this.deliveryInspectorsOptions,
      searchPredicate: new DeliveryControlSearchPredicateBase(),
      isGroupedOptions: true,
      getCategoryLabelIcon: Icons.Company,
      section: DeliveryDetailsSections.INSPECTION_REQUIRED,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  @computed
  private get deliveryInspectorsOptions() {
    return Object.keys(this.usersByCompaniesMap)
      .sort(this.getCompanyPredicate(this.userCompanyId))
      .reduce((list, key) => {
        const users = this.usersByCompaniesMap[key].filter(user =>
          UserProject.isInspector(user, this.userProjectsStore),
        )

        if (!users.length) {
          return list
        }

        const keys = key.split(COMPANY_SEPARATOR)

        const companyName = keys[1] || noCompany

        list.push({
          isCategoryLabel: true,
          value: users.length.toString(),
          title: companyName,
        })
        list.push(
          ...users.map(u => ({
            isUserValue: true,
            value: u.id,
            title: User.getFullNameToDisplay(u, this.userProjectsStore),
          })),
        )

        return list
      }, [] as IDeliveryControlOption[])
  }

  @computed
  private get vendorEmailFields(): IDeliveryControl {
    const id = FieldIds.VENDOR_EMAILS
    const value: string[] = this.getFieldStateValue(id, [])
    const isValid = value.every(v => this.isEmailValid(v))

    const field: IDeliveryControl = {
      id,
      index: this.selectedFieldIndex,
      isMultiValueInput: true,
      type: DeliveryControlTypes.EMAIL,
      value,
      defaultValue: [],
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      section: DeliveryDetailsSections.SUPPLIER,
      isValid,
      isChanged:
        !!this.displayedDelivery &&
        this.areFieldValueDifferent(id, this.initialArrayValues.get(id)),
      validationMessage: enterValidEmailAddress,
    }

    return field
  }

  private isEmailValid(value: string): boolean {
    return !value || VALID_EMAIL_PATTERN.test(value.toLowerCase())
  }

  private filterAreasBySelectedLevel(
    areas: IDeliveryControlOption[],
    levelFieldId: string,
  ) {
    const level =
      this.locationAttributesStore.levelsStore.byId.get(levelFieldId)

    if (!level) {
      return areas
    }

    return areas.filter(area => {
      return (
        !area.hierarchyChainsObjects?.length ||
        area.hierarchyChainsObjects?.some(
          object =>
            object.id === level.id && object.type === LocationType.Level,
        )
      )
    })
  }

  @computed
  private get driverPhoneNumberFields(): IDeliveryControl {
    const id = FieldIds.DRIVER_PHONE_NUMBERS
    const state = this.getFieldStateValue(id, [])
    const value = Array.isArray(state) ? state : [state]
    const isValid = value.every(v => this.isPhoneNumberValid(v))

    const field: IDeliveryControl = {
      id,
      isMultiValueInput: true,
      index: this.selectedFieldIndex,
      type: DeliveryControlTypes.TEL,
      value,
      defaultValue: [],
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      section: this.isFromToModeEnabled
        ? DeliveryDetailsSections.CONTACT
        : DeliveryDetailsSections.SUPPLIER,
      isChanged:
        !!this.displayedDelivery &&
        this.areFieldValueDifferent(id, this.initialArrayValues.get(id)),
      isValid,
    }

    return field
  }

  private isPhoneNumberValid(
    phoneNumber: string | IPhoneNumber,
    format?: string,
    dialCode?: string,
  ): boolean {
    const obj = isPhoneNumber(phoneNumber)
      ? phoneNumber
      : { phoneNumber, country: { format, dialCode } }

    return isPhoneNumberValidForSubmission(obj.phoneNumber, obj.country)
  }

  @computed
  private get noteField(): IDeliveryControl {
    const id = FieldIds.NOTE
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.TEXTAREA,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(id)],
      isRequired: this.isFieldRequired(id),
      section: DeliveryDetailsSections.SPECIAL_INSTRUCTIONS,
      isValid: true,
      isChanged: this.isFieldChanged(id),
    }

    return field
  }

  @computed
  private get activityIdField(): IDeliveryControl {
    const id = FieldIds.ACTIVITY_ID
    const { activeScheduleId } = this.eventsStore.appState

    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.SELECT,
      value: this.getFieldStateValue(id, EMPTY_VALUE),
      hints: [this.getFieldName(id)],
      options: this.activitiesOptions,
      searchPredicate: new ActivitiesDeliveryControlSearchPredicate(),
      getOptionsIcon: Icons.Gantt,
      isRequired: this.isFieldRequired(id),
      section: DeliveryDetailsSections.SPECIAL_INSTRUCTIONS,
      isValid: true,
      isChanged: this.isFieldChanged(id),
      isDisabled: !activeScheduleId,
      newOptionName: this.getFieldStateNewOptionName(id, EMPTY_VALUE),
    }

    return field
  }

  private get activityOptionExtraFields() {
    return activity => ({
      code: activity.code,
    })
  }

  @computed
  private get activitiesOptions() {
    return this.getDropDownOptions({
      list: this.activitiesStore.list,
      isDefaultOptionHidden: false,
      getExtraFields: this.activityOptionExtraFields,
    })
  }

  @computed
  private get siteMapsField(): IDeliveryControl {
    const id = FieldIds.SITE_MAPS
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.IMAGE,
      value: this.sitemapsDefaultValue.map(s => s.filledImage),
      defaultValue: this.sitemapsDefaultValue.map(s => s.filledImage),
      hints: this.sitemapsDefaultValue.map(s => s.name),
      defaultHint: Localization.translator.uploadASitemapToShowGatesAndAreas,
      isRequired: false,
      section: this.isFromToModeEnabled
        ? DeliveryDetailsSections.LOCATION_TO
        : DeliveryDetailsSections.LOCATION,
      isValid: true,
      isUtility: true,
      isReadOnly: true,
      isChanged: this.isFieldChanged(id),
    }

    return field
  }

  @computed
  private get globesField(): IDeliveryControl {
    const id = FieldIds.GLOBES
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.IMAGE,
      value: this.globesDefaultValue.map(s => s.filledImage),
      defaultValue: this.globesDefaultValue.map(s => s.filledImage),
      hints: this.globesDefaultValue.map(s => s.name),
      defaultHint: Localization.translator.uploadAViewToShowGatesAndAreas,
      isRequired: false,
      section: this.isFromToModeEnabled
        ? DeliveryDetailsSections.LOCATION_TO
        : DeliveryDetailsSections.LOCATION,
      isValid: true,
      isUtility: true,
      isReadOnly: true,
      isChanged: this.isFieldChanged(id),
    }

    return field
  }

  private get globesDefaultValue(): GlobeView[] {
    const selectedSitemapItems = this.sitemapItemsStore.list.filter(
      item =>
        item.assignedId === this.buildingField.value ||
        item.assignedId === this.zoneField.value ||
        item.assignedId === this.gateField.value ||
        item.assignedId === this.routeField.value ||
        item.assignedId === this.areaField.value ||
        item.assignedId === this.levelField.value ||
        Object.keys(this.offloadingEquipmentField.value).some(
          id => id === item.assignedId,
        ),
    )

    return this.deliveriesGlobes.filter(g => {
      if (g.isProjectOverviewGlobe) {
        return true
      }

      const assignedAttributesIds: string[] =
        this.getGlobeAssignedAttributesIds(g.id)
      return selectedSitemapItems.some(item =>
        assignedAttributesIds.includes(item.assignedId),
      )
    })
  }

  private get sitemapsDefaultValue(): Sitemap[] {
    const selectedSitemapItems = this.sitemapItemsStore.list.filter(
      item =>
        item.assignedId === this.buildingField.value ||
        item.assignedId === this.zoneField.value ||
        item.assignedId === this.gateField.value ||
        item.assignedId === this.routeField.value ||
        item.assignedId === this.areaField.value ||
        item.assignedId === this.levelField.value ||
        Object.keys(this.offloadingEquipmentField.value).some(
          id => id === item.assignedId,
        ),
    )

    return this.deliveriesSitemaps.filter(s => {
      if (s.isProjectOverviewMap) {
        return true
      }

      const assignedAttributesIds: string[] = this.getAssignedAttributesIds(
        s.id,
      )
      return selectedSitemapItems.some(item =>
        assignedAttributesIds.includes(item.assignedId),
      )
    })
  }

  @computed
  private get requesterField(): IDeliveryControl {
    const id = FieldIds.REQUESTER
    const field: IDeliveryControl = {
      id,
      type: DeliveryControlTypes.TEXT,
      value: this.requesterId,
      hints: ['Requester Name'],
      isRequired: true,
      isReadOnly: true,
      isHidden: true,
      section: DeliveryDetailsSections.OTHER,
      isValid: true,
      isChanged: false,
      isUtility: true,
    }

    return field
  }

  private isFieldChanged(fieldId: FieldIds, fieldIndex?: number): boolean {
    if (!this.displayedDelivery) {
      return false
    }

    this.displayedDeliveryFields[FieldIds.RECURRING_OPTIONS] =
      this.existingRecurringSetting

    return this.areFieldValueDifferent(
      fieldId,
      this.displayedDeliveryFields[getFieldId(fieldId, fieldIndex)],
      fieldIndex,
    )
  }

  private areFieldValueDifferent(
    fieldId: FieldIds,
    value: any,
    fieldIndex?: number,
  ) {
    const fieldValue = this.getFieldStateValue(fieldId, null, fieldIndex)
    switch (fieldId) {
      case FieldIds.SITE_MAPS:
      case FieldIds.GLOBES:
        return !areArraysEqual(value || [], fieldValue || [])
      case FieldIds.OFFLOADING_EQUIPMENT:
        return diffArrays(fieldValue, value).length > 0
      case FieldIds.REQUESTER:
        return false
      case FieldIds.RECURRING_OPTIONS:
        return !this.areObjectsEqual(fieldValue || {}, value || {})

      case FieldIds.VENDOR_EMAILS:
      case FieldIds.ON_SITE_CONTACTS:
        return diffArrays(fieldValue, value).length > 0

      case FieldIds.DRIVER_PHONE_NUMBERS:
        const formattedPhoneNumbers = getFormattedPhoneNumbersForSubmission(
          getRawPhoneNumbers(fieldValue),
        )
        return diffArrays(formattedPhoneNumbers, value).length > 0

      default:
        if (!fieldValue && !value) {
          return false
        }
        return fieldValue !== value
    }
  }

  private areObjectsEqual(object1: object, object2: object) {
    if (object1 && object2) {
      const object1Keys = Object.keys(object1)
      if (object1Keys.length !== Object.keys(object2).length) {
        return false
      }
      return object1Keys.every(
        key =>
          (!object1[key] && !object2[key]) || object1[key] === object2[key],
      )
    }
    return false
  }

  private onDateTimeChange(fieldId: FieldIds, newValue: string) {
    const currentValue = this.getFieldStateValue(fieldId)
    this.updateFieldStateValue(fieldId, newValue || currentValue)

    this.updateDurationField()
    this.checkIfDeliveryAttributesValid()
    this.updateEventPlaceholder()

    if (this.isInsideWorkTime) {
      this.hideNonWorkTimeLabel()
    }

    if (!this.isInsideDeadline) {
      this.hideLateRequestLabel()
    }

    if (this.canUnblockSubmitBtn) {
      this.unblockSubmitButton()
    }

    this.openNonWorkTimeModalIfNeed()
    this.openDateChangeConfirmModalIfNeed()
  }

  private checkIfSelectedObjectOpened(
    field: IDeliveryControl,
    checkIfAvailable?: boolean,
  ) {
    const { value, options, id, index } = field

    if (
      !options
        .filter(o => !checkIfAvailable || !o.isDisabled)
        .some(o => o.value === value)
    ) {
      this.updateFieldStateValue(id, EMPTY_VALUE, index)
    }
  }

  private checkIfSelectedEquipmentOpened(checkIfAvailable?: boolean) {
    const { value, options } = this.offloadingEquipmentField
    const optionValues = options
      .filter(o => !checkIfAvailable || !o.isDisabled)
      .map(o => o.value)
    const intersection = value.filter(v => optionValues.includes(v))

    if (!intersection.length) {
      this.updateFieldStateValue(FieldIds.OFFLOADING_EQUIPMENT, [])
      return
    }

    if (intersection.length !== value.length) {
      this.updateFieldStateValue(FieldIds.OFFLOADING_EQUIPMENT, intersection)
    }
  }

  private checkIfProcurementIdsOpened() {
    this.materialFields.forEach(
      field =>
        field.id === FieldIds.MATERIAL_PROCUREMENT_ID &&
        this.checkIfSelectedObjectOpened(field),
    )
  }

  private onToggleButtonChange(fieldId: FieldIds) {
    const currentValue = this.getFieldStateValue(fieldId)
    this.updateFieldStateValue(fieldId, !currentValue)
    this.setDraftDelivery()
  }

  private onEquipmentFieldChange(
    fieldId: FieldIds,
    optionId: any,
    fieldIndex: number,
  ) {
    this.updateFieldStateValue(fieldId, optionId, fieldIndex)
    this.setDraftDelivery()
  }

  private onLevelFieldChange(fieldId: FieldIds, optionId: any) {
    this.onCommonChange(fieldId, optionId)
    this.checkIfSelectedEquipmentOpened(true)
  }

  private onMaterialChange(fieldId: FieldIds, value: any, fieldIndex: number) {
    this.onCommonChange(fieldId, value, fieldIndex)

    const material = this.materialsStore.getInstanceById(value)
    const lastUsedUnit = this.deliveriesStore.materialUnitsMap[material?.id]

    this.onCommonChange(
      FieldIds.MATERIAL_UNITS,
      lastUsedUnit?.id || EMPTY_VALUE,
      fieldIndex,
    )
    const categoryId = this.getFieldStateValue(
      FieldIds.MATERIAL_CATEGORY,
      null,
      fieldIndex,
    )
    if (material && material.categoryId !== categoryId) {
      this.onCommonChange(
        FieldIds.MATERIAL_CATEGORY,
        material.categoryId,
        fieldIndex,
      )
    }
    const isOnlyOneProcurement = material?.procurementDataList?.length === 1
    const procurementId =
      isOnlyOneProcurement && material.procurementDataList[0]?.procurementId
    const locationId =
      isOnlyOneProcurement &&
      material.procurementDataList[0]?.deliveryLocationId

    this.onCommonChange(
      FieldIds.MATERIAL_PROCUREMENT_ID,
      procurementId || EMPTY_STRING,
      fieldIndex,
    )

    const { isDeleted } = this.locationAttributesStore.getById(locationId) || {}
    if (locationId && !isDeleted) {
      this.onCommonChange(FieldIds.MATERIAL_LOCATION, locationId, fieldIndex)
    }
  }

  private onMaterialCategoryChange(
    fieldId: FieldIds,
    value: any,
    fieldIndex: number,
  ) {
    this.onCommonChange(fieldId, value, fieldIndex)
    this.onCommonChange(FieldIds.MATERIAL, EMPTY_VALUE, fieldIndex)
    this.onCommonChange(
      FieldIds.MATERIAL_PROCUREMENT_ID,
      EMPTY_VALUE,
      fieldIndex,
    )
    this.onCommonChange(FieldIds.MATERIAL_UNITS, EMPTY_VALUE, fieldIndex)
  }

  private onProcurementIdChange(
    fieldId: FieldIds,
    value: any,
    fieldIndex: number,
  ) {
    const material = this.materialsStore.selectedMaterials.find(m =>
      m.includesProcurementId(value),
    )
    if (material) {
      this.onMaterialChange(FieldIds.MATERIAL, material.id, fieldIndex)

      const { deliveryLocationId } =
        material.getProcurementDataById(value) || {}
      const { isDeleted } =
        this.locationAttributesStore.getById(deliveryLocationId) || {}

      this.onCommonChange(
        FieldIds.MATERIAL_LOCATION,
        !isDeleted ? deliveryLocationId : EMPTY_STRING,
        fieldIndex,
      )
      this.onCommonChange(fieldId, value, fieldIndex)
    }
  }

  private onCommonChange(fieldId: FieldIds, value: any, fieldIndex?: number) {
    this.updateFieldStateValue(fieldId, value, fieldIndex)
    this.setDraftDelivery()

    if (this.isEventPlaceholderActive && fieldId === FieldIds.ZONE) {
      this.calendarPlaceholderStore.setEventPlaceholderZone(value)
    }
  }

  private getValue(id: FieldIds, fieldIndex?: number) {
    const field = this.fields.find(
      f => f.id === id && (!fieldIndex || f.index === fieldIndex),
    )
    return field && (field.value || field.defaultValue)
  }

  private getFieldState(
    fieldId: FieldIds,
    fieldIndex?: number,
  ): IDeliveryControlState {
    return this.fieldsState.get(getFieldId(fieldId, fieldIndex)) || {}
  }

  private getFieldStateValue(
    fieldId: FieldIds,
    initialValue?: any,
    fieldIndex?: number,
  ) {
    const state = this.getFieldState(fieldId, fieldIndex)
    return state.value || initialValue
  }

  private updateFieldStateValue(
    fieldId: FieldIds,
    value: any,
    fieldIndex?: number,
  ) {
    const state = this.getFieldState(fieldId, fieldIndex)
    state.value = value
    this.setFieldState(fieldId, fieldIndex, state)
  }

  private getFieldStateNewOptionName(
    fieldId: FieldIds,
    initialValue?: any,
    fieldIndex?: number,
  ) {
    const state = this.getFieldState(fieldId, fieldIndex)
    return state.newOptionName || initialValue
  }

  private updateFieldStateNewOptionName(
    fieldId: FieldIds,
    newOptionName: any,
    fieldIndex?: number,
  ) {
    const state = this.getFieldState(fieldId, fieldIndex)
    state.newOptionName = newOptionName
    this.setFieldState(fieldId, fieldIndex, state)
  }

  @action.bound
  private setFieldState(
    fieldId: FieldIds,
    fieldIndex: number,
    state: IDeliveryControlState,
  ) {
    this.fieldsState.set(getFieldId(fieldId, fieldIndex), state)
  }

  @action.bound
  public toggleFieldCategory(fieldId: FieldIds, categoryId: string) {
    const fieldCategoryId = getFieldCategoryId(fieldId, categoryId)
    const isCollapsed = this.fieldCategoryCollapsingMap.get(fieldCategoryId)
    this.fieldCategoryCollapsingMap.set(fieldCategoryId, !isCollapsed)

    this.saveFieldCategoriesCollapsingState()
  }

  private saveFieldCategoriesCollapsingState = () => {
    const { user, activeProject } = this.eventsStore.appState
    try {
      const userPref = JSON.parse(localStorage.getItem(user.id)) || {}

      if (!userPref.projectSettings) {
        userPref.projectSettings = {}
      }

      let projectSettings = userPref.projectSettings[activeProject.id]
      if (!projectSettings) {
        projectSettings = userPref.projectSettings[activeProject.id] = {}
      }

      projectSettings.deliveryFormFieldCollapsedCategories = Array.from(
        this.fieldCategoryCollapsingMap.entries(),
      )

      localStorage.setItem(user.id, JSON.stringify(userPref))
    } catch {
      /* empty */
    }
  }

  private isFieldRequired(fieldId: FieldIds) {
    const { mandatoryFields } = this.appState.delivery
    return mandatoryFields[fieldId] || false
  }

  @computed
  private get requiredFields(): IDeliveryControl[] {
    if (
      this.hasUserPermissions(DeliveryDetailsActions.SIMPLIFIED_FORM_FILLING)
    ) {
      return this.fields.filter(
        field =>
          field.id === FieldIds.MATERIAL_PROCUREMENT_ID && field.isRequired,
      )
    }

    return this.fields.filter(field => field.isRequired)
  }

  private getDropDownOptions({
    list: list = [],
    isDefaultOptionHidden: isDefaultOptionHidden = false,
    hasRelatedAttribute: hasRelatedAttribute = false,
    nameKey: nameKey = 'name',
    idKey: idKey = 'id',
    isDisabled: isDisabled = 'isDisabled',
    closedLabels: closedLabels = 'closedLabels',
    onlyOpenLabels: onlyOpenLabels = 'onlyOpenLabels',
    timeLimitLabel: timeLimitLabel = 'timeLimitLabel',
    maximumDeliveriesLabel: maximumDeliveriesLabel = 'maximumDeliveriesLabel',
    hierarchyChains: hierarchyChains = 'hierarchyChains',
    hierarchyChainsObjects: hierarchyChainsObjects = 'hierarchyChainsObjects',
    sortByHierarchyChains: sortByHierarchyChains = 'sortByHierarchyChains',
    getExtraFields: getExtraFields,
  }: {
    list: any[]
    isDefaultOptionHidden?: boolean
    hasRelatedAttribute?: boolean
    nameKey?: string
    idKey?: string
    isDisabled?: string
    closedLabels?: string
    onlyOpenLabels?: string
    timeLimitLabel?: string
    maximumDeliveriesLabel?: string
    hierarchyChains?: string
    hierarchyChainsObjects?: string
    sortByHierarchyChains?: string
    getExtraFields?: (any) => any
  }) {
    const defaultOption = {
      value: EMPTY_VALUE,
      title: EMPTY_VALUE,
      hidden: isDefaultOptionHidden,
    }

    const canUserSelectRestrictedAttrs = this.hasUserPermissions(
      DeliveryDetailsActions.SELECT_RESTRICTED_ATTRS,
    )

    const options =
      list &&
      list.map(item => ({
        value: item[idKey],
        title: item[nameKey] || EMPTY_VALUE,
        hidden: false,
        isDisabled: item[isDisabled] && !canUserSelectRestrictedAttrs,
        closedLabels: item[closedLabels],
        onlyOpenLabels: item[onlyOpenLabels],
        timeLimitLabel: item[timeLimitLabel],
        maximumDeliveriesLabel: item[maximumDeliveriesLabel],
        relatedAttribute: hasRelatedAttribute && item,
        hierarchyChains: hasRelatedAttribute && item[hierarchyChains],
        hierarchyChainsObjects:
          hasRelatedAttribute && item[hierarchyChainsObjects],
        sortByHierarchyChains:
          hasRelatedAttribute && item[sortByHierarchyChains],
        extraFields: getExtraFields?.(item),
      }))

    return [defaultOption, ...options]
  }

  private getIntervalCaption = (
    interval: ILocationClosureInterval,
    isIntervalForDates?: boolean,
  ): string => {
    const { startDate, endDate, isDaily } = interval

    const {
      getTimeIntervalPerDayToDisplay,
      getMonthDayAndTimeToDisplay,
      getMonthDayYearAndTimeToDisplay,
      isThisYear,
    } = this.projectDateStore

    if (isDaily) {
      return `${getTimeIntervalPerDayToDisplay(startDate, endDate)} / ${
        Localization.translator.daily
      }`
    }

    if (!isIntervalForDates) {
      return getTimeIntervalPerDayToDisplay(startDate, endDate)
    }

    const getDateToDisplay = date => {
      return isThisYear(date)
        ? getMonthDayAndTimeToDisplay(date)
        : getMonthDayYearAndTimeToDisplay(date)
    }
    const startDateFormated = getDateToDisplay(startDate)
    const endDateFormated = getDateToDisplay(endDate)
    return `${startDateFormated} - ${endDateFormated}`
  }

  // ordering attributes by the first/highest parent in the chain
  private sortByHighestParent = (
    a: IDeliveryControlOption,
    b: IDeliveryControlOption,
  ): number => {
    const aHighestParent =
      a.hierarchyChainsObjects?.[a.hierarchyChainsObjects.length - 1]
    const bHighestParent =
      b.hierarchyChainsObjects?.[b.hierarchyChainsObjects.length - 1]

    if (aHighestParent && bHighestParent) {
      return aHighestParent.name.localeCompare(bHighestParent.name)
    }
    const aOrder = Number(!aHighestParent)
    const bOrder = Number(!bHighestParent)

    // parentless attributes listed after attributes with parents
    return aOrder - bOrder
  }

  private sortByParentAndName = (
    a: IDeliveryControlOption,
    b: IDeliveryControlOption,
  ): number => {
    // if parents are the same sorting by name
    return this.sortByHighestParent(a, b) || a.title?.localeCompare(b?.title)
  }

  // Area could be under level which is under building or not. So all this need to be compared
  private sortAreas = (
    a: IDeliveryControlOption,
    b: IDeliveryControlOption,
  ): number => {
    const aAreaLevel = a.hierarchyChainsObjects?.find(
      attribute => attribute.type === LocationType.Level,
    )
    const bAreaLevel = b.hierarchyChainsObjects?.find(
      attribute => attribute.type === LocationType.Level,
    )

    if (!aAreaLevel && bAreaLevel) {
      return 1
    }

    if (!bAreaLevel && aAreaLevel) {
      return -1
    }

    const aParent = this.locationAttributesStore.buildingsStore.byId.get(
      aAreaLevel?.parent?.parentId,
    )
    const bParent = this.locationAttributesStore.buildingsStore.byId.get(
      bAreaLevel?.parent?.parentId,
    )

    if (!aParent && bParent) {
      return 1
    }

    if (!bParent && aParent) {
      return -1
    }

    if (aParent?.id !== bParent?.id) {
      return aParent.name.localeCompare(bParent.name)
    }

    if (aAreaLevel && bAreaLevel) {
      const aLevel = getFloorFromLevel(aAreaLevel.name)
      const bLevel = getFloorFromLevel(bAreaLevel.name)
      if (aLevel !== bLevel) {
        return aLevel - bLevel
      }
    }

    return a.title.toLowerCase().localeCompare(b.title.toLowerCase())
  }

  private setClosedOptions(
    itemsList: any[],
    propKey?: string,
    sortByHierarchyChains?: (
      a: IDeliveryControlOption,
      b: IDeliveryControlOption,
    ) => number,
  ) {
    const { startDate, endDate } = this.selectedDates || {}
    const { isClosedBetween } = this.projectDateStore

    const recurringDates =
      this.isCreateModeActive &&
      this.getRecurringDeliveryDates(startDate?.getTime(), endDate?.getTime())

    return itemsList.map(item => {
      const closures = this.closuresStore.getLocationClosures(item.id)
      const closedLabels = this.selectedDates
        ? closures
            .filter(closure =>
              isClosedBetween(
                startDate,
                endDate,
                [],
                [closure.closureInterval],
                'partially',
              ),
            )
            .map(
              closure =>
                `${closed}: ${this.getIntervalCaption(
                  closure.closureInterval,
                  !closure.closureInterval.isDaily,
                )}`,
            )
        : []

      const onlyOpenLabels = this.selectedDates
        ? item.operatingIntervals
            .filter(interval =>
              isClosedBetween(startDate, endDate, [interval], [], 'partially'),
            )
            .map(
              interval => `${onlyOpen}: ${this.getIntervalCaption(interval)}`,
            )
        : []

      const itemMaxBookingDurationMs =
        item.maxBookingDuration * MILLISECONDS_IN_HOUR
      const timeLimitLabel =
        !!this.selectedDates &&
        itemMaxBookingDurationMs &&
        itemMaxBookingDurationMs < endDate.getTime() - startDate.getTime()
          ? timeLimitForItem(item.name, item.maxBookingDuration)
          : ''

      const isItemOverlappedMaxDeliveries =
        !!this.selectedDates &&
        !!item.maxOverlappingDeliveries &&
        item.maxOverlappingDeliveries <=
          this.getDeliveriesCountFromDates(propKey, item.id, [
            {
              startDate: startDate.getTime(),
              endDate: endDate.getTime(),
            },
          ])

      const isItemOverlappedRecurringDeliveries =
        !!recurringDates?.length &&
        !!item.maxOverlappingDeliveries &&
        item.maxOverlappingDeliveries <=
          this.getDeliveriesCountFromDates(propKey, item.id, recurringDates)

      const maximumDeliveriesLabel =
        (isItemOverlappedMaxDeliveries ||
          isItemOverlappedRecurringDeliveries) &&
        Localization.translator.deliveryDescriptions
          .maximumDeliveriesAreScheduled

      const requesterCompanyId = this.getFieldStateValue(
        FieldIds.COMPANY,
        EMPTY_VALUE,
      )

      const permittedCompaniesNames = item.getAllPermittedCompaniesNames(
        this.companiesStore,
      )

      const isRestricted = item.hasRestrictionForCompany(requesterCompanyId)
      if (isRestricted) {
        closedLabels.push(
          `${reservedFor}: ${permittedCompaniesNames.join(', ')}`,
        )
      }

      const isInOpenHours =
        !item.operatingIntervals.length ||
        item.operatingIntervals.length !== onlyOpenLabels.length
      const hierarchyChains = item.getHierarchyChains(
        this.tagsStore.tagStoreByTagTypeMap,
      )
      const hierarchyChainsObjects = item.getHierarchyChainsObjects(
        this.tagsStore.tagStoreByTagTypeMap,
      )

      const extendedItem = Object.assign(
        {
          isDisabled:
            isRestricted ||
            !!closedLabels.length ||
            !isInOpenHours ||
            !!timeLimitLabel ||
            !!maximumDeliveriesLabel,
          closedLabels,
          onlyOpenLabels: !isInOpenHours ? onlyOpenLabels : [],
          timeLimitLabel,
          maximumDeliveriesLabel,
          hierarchyChains,
          hierarchyChainsObjects,
          sortByHierarchyChains,
        },
        item,
      )
      return extendedItem
    })
  }

  private getDeliveriesCountFromDates(
    propName: string,
    id: string,
    dates: IMsDateInterval[],
  ) {
    return this.deliveriesStore.availableDeliveries.filter(delivery => {
      if (this.displayedDelivery && this.displayedDelivery.id === delivery.id) {
        return false
      }

      if (delivery.isCanceled) {
        return false
      }

      if (
        !!delivery.recurringSettingId &&
        delivery.recurringSettingId === this.existingRecurringSetting?.id
      ) {
        return false
      }

      if (
        dates.every(
          ({ startDate, endDate }) =>
            delivery.startDate >= endDate || delivery.endDate <= startDate,
        )
      ) {
        return false
      }

      if (!!delivery[propName] && typeof delivery[propName] === 'object') {
        const equipmentIds: IDeliveryAttribute[] = Object.values(
          delivery[propName],
        )
        return equipmentIds.some(equipment => equipment.id === id)
      }

      return delivery[propName] === id
    }).length
  }

  private removeDateAndLateRequestFromFields(prePopulatedFields: {
    [key: string]: any
  }) {
    prePopulatedFields[FieldIds.DATE] = null
    prePopulatedFields[FieldIds.UNSCHEDULED_REQUEST] = null
  }

  private swapFieldValues(a: FieldIds, b: FieldIds) {
    const aValue = this.getFieldStateValue(a)
    this.updateFieldStateValue(a, this.getFieldStateValue(b))
    this.updateFieldStateValue(b, aValue)
  }

  private swapFromAndToSectionValues() {
    this.swapFieldValues(FieldIds.BUILDING, FieldIds.FROM_BUILDING)
    this.swapFieldValues(FieldIds.AREA, FieldIds.FROM_AREA)
    this.swapFieldValues(FieldIds.LEVEL, FieldIds.FROM_LEVEL)
    this.swapFieldValues(FieldIds.ZONE, FieldIds.FROM_ZONE)
    this.swapFieldValues(FieldIds.STAGING, FieldIds.FROM_STAGING)
    this.swapFieldValues(
      FieldIds.INSTALLATION_ZONE,
      FieldIds.FROM_INSTALLATION_ZONE,
    )
  }

  private prepareFromToCreationFields(prePopulatedFields: {
    [key: string]: any
  }) {
    prePopulatedFields[FieldIds.FROM_TO_SWITCH] = true
    prePopulatedFields[FieldIds.ROUTE] = null
    prePopulatedFields[FieldIds.GATE] = null
    prePopulatedFields[FieldIds.INTERIOR_DOOR] = null
    prePopulatedFields[FieldIds.INTERIOR_PATH] = null
  }

  private get isInsideWorkTime(): boolean {
    const isoStartDate = this.getValue(FieldIds.DATE)
    const isoEndDate = this.getValue(FieldIds.END_DATE)
    const deliveryStartTime = this.getValue(FieldIds.START_TIME)
    const deliveryEndTime = this.getValue(FieldIds.END_TIME)

    if (!isoStartDate || !deliveryStartTime) {
      return true
    }

    const { startDate, endDate } = this.getDates(
      isoStartDate,
      isoEndDate,
      deliveryStartTime,
      deliveryEndTime,
    )

    return this.projectDateStore.isInsideWorkingDaysAndHours(startDate, endDate)
  }

  private get hierarchyAttributes(): LocationBase[] {
    return this.locationAttributesStore.allAttributes
  }

  private get attributesBySitemaps(): { [sitemapId: string]: string[] } {
    return this.hierarchyAttributes.reduce((map, attribute) => {
      this.deliveriesSitemaps.forEach(sitemap => {
        if (attribute.isSitemapAssigned(sitemap.id)) {
          if (map[sitemap.id]) {
            map[sitemap.id].push(attribute.id)
          } else {
            map[sitemap.id] = [attribute.id]
          }
        }
      })
      return map
    }, {})
  }

  private get attributesByGlobe(): { [sitemapId: string]: string[] } {
    return this.hierarchyAttributes.reduce((map, attribute) => {
      this.deliveriesGlobes.forEach(globe => {
        if (attribute.isGlobeAssigned(globe.id)) {
          if (map[globe.id]) {
            map[globe.id].push(attribute.id)
          } else {
            map[globe.id] = [attribute.id]
          }
        }
      })
      return map
    }, {})
  }

  @computed
  public get existingRecurringSetting(): RecurringDeliveriesSettings {
    if (!this.displayedDelivery?.recurringSettingId) {
      return null
    }

    return this.recurringSettingsStore.getById(
      this.displayedDelivery.recurringSettingId,
    )
  }

  @computed
  public get projectId(): string {
    return this.appState.activeProject.id
  }

  public getChangedFieldsMessage = (
    userNoteMessage: string,
    fields?: IDeliveryControl[],
  ): string => {
    const changedRequiredFields =
      fields?.filter(
        ({ isUtility, isRequired, id }) =>
          !isUtility && (isRequired || id === FieldIds.END_DATE),
      ) ||
      this.fields.filter(
        ({ isUtility, isChanged, isRequired, id }) =>
          !isUtility && (isRequired || id === FieldIds.END_DATE) && isChanged,
      )

    const changedFieldLines = changedRequiredFields.map(field => {
      const oldFieldValue =
        this.getFieldCaptionValue(field, true) || Localization.translator.none
      const newFieldValue = this.getFieldCaptionValue(field)

      const fieldIndexText = field.index ? ` #${field.index}` : EMPTY_STRING
      const fieldName = this.getFieldName(field.id) + fieldIndexText

      return `${fieldName}${FIELD_VALUES_SEPARATOR}${oldFieldValue}${FIELD_VALUES_SEPARATOR}${newFieldValue}`
    })

    const changedFieldsMessage = changedFieldLines.join(FIELD_LINES_SEPARATOR)

    if (!changedFieldsMessage) {
      return userNoteMessage
    }

    return (
      changedFieldsMessage +
      FIELDS_AND_NOTE_SEPARATOR +
      (userNoteMessage || EMPTY_STRING)
    )
  }

  private getRecurringDeliveryDates(
    startDate: number,
    endDate: number,
  ): IMsDateInterval[] {
    const recurringSetting = this.getFieldStateValue(FieldIds.RECURRING_OPTIONS)

    if (!recurringSetting || !startDate || !endDate) {
      return []
    }

    const shouldIgnoreNonWorkingTimes =
      this.userProject.isSuperUser || !this.shouldBlockOnNonWorkTimes

    return Delivery.getRecurringDates(
      startDate,
      endDate,
      recurringSetting,
      this.projectDateStore,
      shouldIgnoreNonWorkingTimes,
    )
  }

  private getAssignedAttributesIds(globeId: string): string[] {
    const attributes = this.attributesBySitemaps[globeId]
    if (attributes) {
      return attributes
    }

    const { firstBuilding } = this.locationAttributesStore.buildingsStore
    return firstBuilding ? [firstBuilding.id] : []
  }

  private getGlobeAssignedAttributesIds(globeId: string): string[] {
    const attributes = this.attributesByGlobe[globeId]
    if (attributes) {
      return attributes
    }

    const { firstBuilding } = this.locationAttributesStore.buildingsStore
    return firstBuilding ? [firstBuilding.id] : []
  }

  private filterEquipmentsByLevel = (
    levelId: string,
  ): OffloadingEquipment[] => {
    const { list } = this.locationAttributesStore.offloadingEquipmentsStore
    const { getInstanceById } = this.locationAttributesStore.levelsStore
    const selectedLevel = getInstanceById(levelId)
    const parentBuilding =
      this.locationAttributesStore.buildingsStore.getInstanceById(
        selectedLevel?.parent?.parentId,
      )

    return list.filter(eq => {
      const hasTheSameParentAsLvl =
        parentBuilding && selectedLevel?.hasCommonParent(eq)

      if (hasTheSameParentAsLvl && eq.accessibleLevels?.length) {
        return eq.accessibleLevels.includes(selectedLevel.id)
      }

      return true
    })
  }

  private getFieldCaptionValue = (
    field: IDeliveryControl,
    isOldValue?: boolean,
  ): string => {
    const {
      fromIsoString,
      combineISODateTime,
      getTimeToDisplay,
      getMonthDayAndYearToDisplay,
    } = this.projectDateStore

    const fieldValue = isOldValue
      ? this.displayedDeliveryFields[getFieldId(field.id, field.index)]
      : field.value

    if (timeFieldIds.includes(field.id)) {
      const date = combineISODateTime(DEFAULT_ISO_DATE_STRING, fieldValue)
      return getTimeToDisplay(date)
    } else if (dateFieldIds.includes(field.id)) {
      return getMonthDayAndYearToDisplay(fromIsoString(fieldValue))
    } else if (field.options) {
      const isMultiCombobox = field.type === DeliveryControlTypes.MULTI_COMBOBOX
      return field.options
        .filter(
          o =>
            (isMultiCombobox && fieldValue?.includes(o.value)) ||
            o.value === fieldValue,
        )
        .map(o => o.title)
        .join(', ')
    }

    return fieldValue
  }

  private get isMaterialsSectionHidden(): boolean {
    return this.appState.delivery.hiddenFields[FieldIds.MATERIALS_SECTION]
  }

  @action.bound
  private setMaterialsSectionsCount(sectionsCount: number) {
    if (!sectionsCount || sectionsCount <= MIN_MULTI_VALUE_COUNT) {
      return this.resetMaterialsSectionsCount()
    }

    if (sectionsCount <= MAX_MULTI_VALUE_COUNT) {
      this.materialsSectionsCount = sectionsCount
    }
  }

  private updateMaterialFieldsValues = (
    index: number = 0,
    matFields?: {
      [fieldId: string]: string
    },
  ) => {
    this.materialFields
      .filter(f => f.index > index)
      .forEach(field =>
        this.updateFieldStateValue(
          field.id,
          matFields?.[getFieldId(field.id, field.index)] || field.defaultValue,
          field.index,
        ),
      )
  }

  private getMaterialsAsFields = (
    deliveryMaterials: IDeliveryMaterial[],
    index: number = 0,
  ): { [fieldId: string]: string } => {
    return (deliveryMaterials || []).reduce((fieldsMap, delMat) => {
      const idx = index + 1

      const { materialId, procurementId, quantity, note, unitId, locationId } =
        delMat || {}

      const material = this.materialsStore.getInstanceById(materialId)
      if (!material) {
        index++
        return fieldsMap
      }

      fieldsMap[getFieldId(FieldIds.MATERIAL_CATEGORY, idx)] =
        material.categoryId
      fieldsMap[getFieldId(FieldIds.MATERIAL, idx)] = material.id
      fieldsMap[getFieldId(FieldIds.MATERIAL_PROCUREMENT_ID, idx)] =
        procurementId
      fieldsMap[getFieldId(FieldIds.MATERIAL_NOTE, idx)] = note
      fieldsMap[getFieldId(FieldIds.MATERIAL_QUANTITY, idx)] = quantity
        ? quantity.toString()
        : EMPTY_STRING
      fieldsMap[getFieldId(FieldIds.MATERIAL_UNITS, idx)] = unitId
      fieldsMap[getFieldId(FieldIds.MATERIAL_LOCATION, idx)] = locationId

      index++
      return fieldsMap
    }, {})
  }
}
