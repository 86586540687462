import * as React from 'react'

import { inject, observer } from 'mobx-react'

import BaseActionButton from '~/client/src/shared/components/BaseActionButton/BaseActionButton'
import { Content, Header, View } from '~/client/src/shared/components/Layout'
import VisitorSignUp from '~/client/src/shared/components/VisitorSignUp/VisitorSignUp'
import Localization from '~/client/src/shared/localization/LocalizationManager'

import DesktopCommonStore from '../../stores/ui/DesktopCommon.store'

interface IProps {
  common?: DesktopCommonStore
}

// translated

@inject('common')
@observer
export default class VisitorSignUpView extends React.Component<IProps> {
  public render() {
    const { common } = this.props

    return (
      <View className="pa20">
        <Header className="row x-end">
          <BaseActionButton
            title={Localization.translator.signIn}
            className="inverse-scale-blue-theme"
            isEnabled={true}
            onClick={common.displayLoginView}
          />
        </Header>
        <Content scrollable={true} className="w500 ma-x-auto py30">
          <VisitorSignUp />
        </Content>
      </View>
    )
  }
}
