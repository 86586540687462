import { LocationType } from '~/client/graph'
import FieldIds from '~/client/src/shared/enums/DeliveryFieldIds'
import CalendarEvent from '~/client/src/shared/models/CalendarEvent'
import LocationAttributeBase from '~/client/src/shared/models/LocationObjects/LocationAttributeBase'
import ProjectDateStore from '~/client/src/shared/stores/ui/ProjectDate.store'
import Guard from '~/client/src/shared/utils/Guard'

// localization: no display text to translate

export type NewEventData = { [key in FieldIds]?: string }
export enum DesktopCalendarColumnType {
  Date = 'Date',
  Unassigned = 'Unassigned',
  Attribute = 'Attribute',
}

export default class DesktopCalendarColumn {
  public static createAttributeColumn(
    attribute: LocationAttributeBase,
    date: Date,
    projectDateStore: ProjectDateStore,
    attributeType: LocationType,
  ) {
    return new DesktopCalendarColumn(
      DesktopCalendarColumnType.Attribute,
      attribute,
      date,
      (event: CalendarEvent) =>
        DesktopCalendarColumn.isEventDisplayedOnAttributeColumn(
          event,
          attributeType,
          attribute,
        ),
      DesktopCalendarColumn.createNewEventColumn(
        attributeType,
        date,
        projectDateStore,
        attribute,
      ),
      attributeType,
    )
  }

  public static createUnassignedZoneColumn(
    isEventDisplayedOnColumn: (event: CalendarEvent) => boolean,
    date: Date,
    projectDateStore: ProjectDateStore,
  ) {
    return new DesktopCalendarColumn(
      DesktopCalendarColumnType.Unassigned,
      null,
      date,
      isEventDisplayedOnColumn,
      {
        [FieldIds.DATE]: projectDateStore.getDashedFormattedDate(date),
      },
    )
  }

  public static createDateColumn(
    date: Date,
    projectDateStore: ProjectDateStore,
  ) {
    return new DesktopCalendarColumn(
      DesktopCalendarColumnType.Date,
      date,
      date,
      (event: CalendarEvent) =>
        projectDateStore.isWithinDateInterval(
          event.startDate,
          event.endDate,
          date,
        ),
      {
        [FieldIds.DATE]: projectDateStore.getDashedFormattedDate(date),
      },
    )
  }

  private static isEventDisplayedOnAttributeColumn = (
    event: CalendarEvent,
    attributeType: LocationType,
    attribute: LocationAttributeBase,
  ): boolean => {
    switch (attributeType) {
      case LocationType.Zone:
        return (
          event.data?.zone === attribute.id || event.zoneId === attribute.id
        )
      case LocationType.Area:
        return (
          event.data?.area === attribute.id || event.areaId === attribute.id
        )
      case LocationType.Building:
        return (
          event.data?.building === attribute.id ||
          event.buildingId === attribute.id
        )
      case LocationType.Gate:
        return (
          event.data?.gate === attribute.id || event.gateId === attribute.id
        )
      case LocationType.Level:
        return (
          event.data?.level === attribute.id || event.levelId === attribute.id
        )
      case LocationType.OffloadingEquipment:
        return (
          event.data?.offloadingEquipmentIds.includes(attribute.id) ||
          event.equipmentIds.includes(attribute.id)
        )
      case LocationType.Route:
        return (
          event.data?.route === attribute.id || event.routeId === attribute.id
        )
      default:
        return false
    }
  }

  private static createNewEventColumn = (
    locationType: LocationType,
    date: Date,
    projectDateStore: ProjectDateStore,
    attribute: LocationAttributeBase,
  ) => {
    switch (locationType) {
      case LocationType.Zone:
        return {
          [FieldIds.DATE]: projectDateStore.getDashedFormattedDate(date),
          [FieldIds.ZONE]: attribute.id,
        }
      case LocationType.Area:
        return {
          [FieldIds.DATE]: projectDateStore.getDashedFormattedDate(date),
          [FieldIds.AREA]: attribute.id,
        }
      case LocationType.Building:
        return {
          [FieldIds.DATE]: projectDateStore.getDashedFormattedDate(date),
          [FieldIds.BUILDING]: attribute.id,
        }
      case LocationType.Gate:
        return {
          [FieldIds.DATE]: projectDateStore.getDashedFormattedDate(date),
          [FieldIds.GATE]: attribute.id,
        }
      case LocationType.Level:
        return {
          [FieldIds.DATE]: projectDateStore.getDashedFormattedDate(date),
          [FieldIds.LEVEL]: attribute.id,
        }
      case LocationType.OffloadingEquipment:
        return {
          [FieldIds.DATE]: projectDateStore.getDashedFormattedDate(date),
          [FieldIds.OFFLOADING_EQUIPMENT]: attribute.id,
        }
      case LocationType.Route:
        return {
          [FieldIds.DATE]: projectDateStore.getDashedFormattedDate(date),
          [FieldIds.ROUTE]: attribute.id,
        }
      default:
        return {
          [FieldIds.DATE]: projectDateStore.getDashedFormattedDate(date),
        }
    }
  }

  public constructor(
    public type: DesktopCalendarColumnType,
    public title: LocationAttributeBase | Date | string,
    public date: Date,
    public isEventDisplayedOnColumn: (event: CalendarEvent) => boolean,
    public newEventData?: NewEventData,
    public attributeType?: LocationType,
  ) {
    Guard.requireAll({ type, title, date, isEventDisplayedOnColumn })
  }

  public get isAttributeType() {
    return this.type === DesktopCalendarColumnType.Attribute
  }

  public isEqual(other: DesktopCalendarColumn) {
    return this === other
  }

  public getEventsDisplayedOnColumn(events: CalendarEvent[]) {
    return events.filter(event => this.isEventDisplayedOnColumn(event))
  }
}
