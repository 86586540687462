import * as React from 'react'

import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'
import PhoneInput from 'react-phone-input-2'

import InitialState from '../../stores/InitialState'
import {
  PREFERRED_COUNTRY_CODES,
  isPhoneNumber,
} from '../../utils/phoneNumberHelpers'
import BaseStruxhubInput, { ISharedProps } from './BaseStruxhubInput'

interface IProps extends ISharedProps {
  onChange: (phoneNumber: any, country: any) => void
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void
  onBlur?: () => void
  onFocus?: () => void

  id?: string
  name?: string

  state?: InitialState
}

@inject('state')
@observer
export default class StruxhubPhoneInput extends React.Component<IProps> {
  public render() {
    const { isRequired, value, onChange, state, onPaste, id, name } = this.props

    const phoneData = isPhoneNumber(value)
      ? value
      : {
          phoneNumber: value,
          country: {
            countryCode: state.countryCode || PREFERRED_COUNTRY_CODES[0],
          },
        }

    return (
      // onPaste goes here because react-phone-input-2 doesn't support it
      // onBlur and onFocus here are not the same as the ones in there and those don't propagate properly
      // Also, onFocusOut to make sure the event propagates
      <div
        className="relative full-width"
        onPaste={onPaste}
        onBlur={this.props.onBlur}
        onFocus={this.props.onFocus}
      >
        <BaseStruxhubInput {...this.props}>
          {(isValueInvalid, isInFocus, onFocus, onBlur) => (
            <PhoneInput
              inputProps={{
                id,
                name,
                required: isRequired,
              }}
              inputClass={classList({
                'no-outline full-width': true,
                'ba-light-blue': isInFocus && !isValueInvalid,
                'ba-palette-brand-lighter': !isInFocus && !isValueInvalid,
                'ba-red': isValueInvalid,
              })}
              country={phoneData.country.countryCode}
              value={phoneData.phoneNumber}
              onFocus={onFocus}
              onFocusOut={this.props.onBlur}
              onBlur={onBlur}
              onChange={onChange}
              preferredCountries={PREFERRED_COUNTRY_CODES}
              enableSearch={true}
              disableSearchIcon={true}
              placeholder={null}
            />
          )}
        </BaseStruxhubInput>
      </div>
    )
  }
}
