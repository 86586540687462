import { IconNames } from '@blueprintjs/icons'
import { action, observable } from 'mobx'

import {
  DefaultLandingPage,
  IContactsVisibleToVisitors,
  ILastUpdatedItem,
  IMutation,
  IProjectAddressInput,
  IProjectClosedInterval,
  IProjectInput,
  IProjectTypeOptionsInput,
  IProjectVisitorOptions,
  IWorkingHoursInput,
} from '~/client/graph'
import { SaveProjectsDocument } from '~/client/graph/operations/generated/Project.generated'
import ISaveProjectSuccessOptions from '~/client/src/desktop/interfaces/ISaveProjectSuccessOptions'
import DesktopEventStore from '~/client/src/desktop/stores/EventStore/DesktopEvents.store'
import setUpPagesNames from '~/client/src/desktop/utils/setUpPagesNames'
import ProjectSetUpPage from '~/client/src/desktop/views/ProjectSetUp/ProjectSetUpPages'
import commonRoutes, {
  getTransitionPath,
} from '~/client/src/shared/constants/commonRoutes'
import DateTimeFormat from '~/client/src/shared/enums/DateTimeFormat'
import { IMapBoxFeature } from '~/client/src/shared/interfaces/IMapBoxFeature'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import Project, {
  DEFAULT_WORK_FINISH_HOUR,
  DEFAULT_WORK_START_HOUR,
} from '~/client/src/shared/models/Project'
import { ITag } from '~/client/src/shared/models/Tag'
import User from '~/client/src/shared/models/User'
import UserProject from '~/client/src/shared/models/UserProject'
import EventContext from '~/client/src/shared/stores/EventStore/EventContext'
import * as e from '~/client/src/shared/stores/EventStore/eventConstants'
import GraphExecutorStore from '~/client/src/shared/stores/domain/GraphExecutor.store'
import ProjectsStore from '~/client/src/shared/stores/domain/Projects.store'
import UserProjectsStore from '~/client/src/shared/stores/domain/UserProjects.store'
import ProjectDateStore, {
  DAYS_IN_WEEK,
} from '~/client/src/shared/stores/ui/ProjectDate.store'
import { WITHOUT_SPECIAL_CHARACTERS_PATTERN } from '~/client/src/shared/utils/regExpPatterns'
import {
  defaultTimezoneId,
  getTimezoneIdByCoordinates,
} from '~/client/src/shared/utils/timezones'
import { ToastTheme, showToast } from '~/client/src/shared/utils/toaster'

import ProjectSetUpPageStore from '../../ProjectSetUpPage.store'

const projectNameValidationRegex = /^[A-Za-z0-9\-_ ]+$/
export const projectCodeMaxLength = 5

const PAGE_NAME = setUpPagesNames[ProjectSetUpPage.PROJECT_DETAILS]

const defaultWorkingDays = [...Array(DAYS_IN_WEEK).keys()].map(i => ++i)

export default class ProjectSettingsStore {
  @observable public isNewProjectSelected: boolean = false
  @observable public isProjectErrorMessageVisible: boolean = false
  @observable public isProjectCodeErrorMessageVisible: boolean = false
  @observable public projectName: string = ''
  @observable public maturixToken: string = ''
  @observable public projectCode: string = ''
  @observable public projectLogoUrl: string = ''
  @observable public projectAddress: IProjectAddressInput
  @observable public closedIntervals: IProjectClosedInterval[] = []
  @observable public workingHours: IWorkingHoursInput = null
  @observable public workingDays: number[] = []
  @observable public defaultLandingPage: DefaultLandingPage =
    DefaultLandingPage.Home
  @observable public isDeliveriesEnabled: boolean = true
  @observable public isAnalyticsEnabled: boolean = true
  @observable public isTrackerEnabled: boolean = false
  @observable public isLogisticsEnabled: boolean = false
  @observable public isMaterialsEnabled: boolean = false
  @observable public isPhotosEnabled: boolean = false
  @observable public isFormsEnabled: boolean = false

  @observable public isConfirmationModalDisplayed: boolean = false
  @observable public preConfimedTimezoneId: string = ''

  @observable public timezoneId: string = ''
  @observable public dateTimeFormat: DateTimeFormat = DateTimeFormat.Default

  @observable public projectAddressSuggestions: IMapBoxFeature[] = []
  @observable public limitUserDirectory: boolean = true
  @observable public permitRequestEnabled: boolean = true
  @observable public requireToCreateAccount: boolean = true
  @observable
  public contactsVisibleToVisitors: IContactsVisibleToVisitors = {}

  @observable public shouldShowWhiteListedDomainDialog: boolean = false

  public constructor(
    private readonly projectsStore: ProjectsStore,
    private readonly eventsStore: DesktopEventStore,
    private readonly projectSetUpPageStore: ProjectSetUpPageStore,
    private readonly userProjectsStore: UserProjectsStore,
    private readonly graphExecutorStore: GraphExecutorStore,
    private readonly projectDateStore: ProjectDateStore,
  ) {}

  public get activeProject() {
    return this.projectsStore.activeProject
  }

  public get projects() {
    return this.projectsStore.list
  }

  private get isEditMode(): boolean {
    return !this.isNewProjectSelected && !!this.activeProject
  }

  private get defaultWorkingHours(): IWorkingHoursInput {
    return {
      startTime: this.projectDateStore
        .setHours(new Date(), DEFAULT_WORK_START_HOUR, 0)
        .getTime(),
      endTime: this.projectDateStore
        .setHours(new Date(), DEFAULT_WORK_FINISH_HOUR, 0)
        .getTime(),
    }
  }

  private get lastUpdated(): ILastUpdatedItem {
    const {
      id,
      email,
      firstName = null,
      lastName = null,
    } = this.eventsStore.appState.user

    return {
      pageName: PAGE_NAME,
      updateInfo: {
        updatedAt: Date.now(),
        by: { id, email, firstName, lastName },
      },
    }
  }

  private get updatedFields(): {
    name: string
    code: string
    logoUrl: string
    timezoneId: string
    dateTimeFormat: DateTimeFormat
    defaultLandingPage: DefaultLandingPage
  } {
    return {
      name: this.projectName,
      code: this.projectCode,
      logoUrl: this.projectLogoUrl,
      timezoneId: this.timezoneId,
      dateTimeFormat: this.dateTimeFormat,
      defaultLandingPage: this.defaultLandingPage,
    }
  }

  public onProjectUpdate = (eventContext: EventContext) => {
    const [eventType] = eventContext.event

    if (eventType === e.PROJECT_RECEIVED) {
      this.setProjectDataValues()
    }
  }

  public toggleConfirmationModal = () => {
    this.isConfirmationModalDisplayed = !this.isConfirmationModalDisplayed
  }

  public confirmTimzoneChange = () => {
    this.timezoneId = this.preConfimedTimezoneId

    if (this.isEditMode) {
      this.updateProject()
    } else {
      this.toggleConfirmationModal()
    }
  }

  public setProjectDataValues() {
    this.clearForm()
    if (!this.activeProject) {
      return
    }

    const {
      code: projectCode = '',
      name: projectName = '',
      logoUrl: projectLogoUrl = '',
      timezoneId: projectTimezoneId = '',
      dateTimeFormat: projectDateTimeFormat = DateTimeFormat.Default,
      defaultLandingPage,
    } = this.activeProject

    const { projectAddress, projectTypeOptions, projectVisitorOptions } =
      this.eventsStore.appState

    this.projectName = projectName
    this.projectCode = projectCode
    this.projectLogoUrl = projectLogoUrl
    this.timezoneId = projectTimezoneId
    this.dateTimeFormat = projectDateTimeFormat
    this.defaultLandingPage = defaultLandingPage || DefaultLandingPage.Home

    this.projectAddress = projectAddress
    if (projectTypeOptions) {
      const {
        isDeliveriesDisabled,
        isTrackerDisabled,
        isLogisticsDisabled,
        isMaterialsDisabled,
        isPhotosDisabled,
        isFormsDisabled,
        isAnalyticsDisabled,
      } = projectTypeOptions

      this.isDeliveriesEnabled = !isDeliveriesDisabled
      this.isAnalyticsEnabled = !isAnalyticsDisabled
      this.isTrackerEnabled = !isTrackerDisabled
      this.isLogisticsEnabled = !isLogisticsDisabled
      this.isMaterialsEnabled = !isMaterialsDisabled
      this.isPhotosEnabled = !isPhotosDisabled
      this.isFormsEnabled = !isFormsDisabled
    }

    this.limitUserDirectory = projectVisitorOptions?.limitUserDirectory
    this.permitRequestEnabled = projectVisitorOptions?.permitRequestEnabled
    this.requireToCreateAccount = projectVisitorOptions?.requireToCreateAccount
    this.contactsVisibleToVisitors =
      projectVisitorOptions?.contactsVisibleToVisitors
  }

  @action.bound
  public setProjectName(newProjectName: string) {
    this.projectName = newProjectName
    newProjectName = newProjectName.trim()
    this.isProjectErrorMessageVisible =
      !projectNameValidationRegex.test(newProjectName)
  }

  @action.bound
  public setProjectLogo(logoUrl: string) {
    this.projectLogoUrl = logoUrl
  }

  public saveProjectName() {
    const project = Object.assign(this.activeProject.toInput(), {
      name: this.projectName,
    })
    this.saveProject(project)
  }

  public saveProjectLogo() {
    const project = Object.assign(this.activeProject.toInput(), {
      logoUrl: this.projectLogoUrl,
    })
    this.saveProject(project)
  }

  @action.bound
  public setProjectCode(newProjectCode: string) {
    const projectCode = this.getFormattedProjectCode(newProjectCode)

    this.projectCode = projectCode
    this.isProjectCodeErrorMessageVisible = !projectCode.length
  }

  @action.bound
  public setProjectAddressSuggestions(
    newProjectAddressSuggestions: IMapBoxFeature[],
  ) {
    this.projectAddressSuggestions = newProjectAddressSuggestions
  }

  @action.bound
  public setProjectAddress(newProjectAddress: IProjectAddressInput) {
    this.projectAddress = newProjectAddress
    this.timezoneId = getTimezoneIdByCoordinates(
      newProjectAddress?.center?.lng || 0,
      newProjectAddress?.center?.lat || 0,
    )
  }

  @action.bound
  public saveProjectAdress() {
    this.saveAddress()
  }

  @action.bound
  public setDateTimeFormat(dateTime: DateTimeFormat) {
    this.dateTimeFormat = dateTime
    if (!this.isNewProjectSelected) {
      this.updateProjectDateTimeFormat()
    }
  }

  @action.bound
  public setProjectWorkingHours(startDate: Date, endDate: Date) {
    this.workingHours = {
      startTime: startDate.getTime(),
      endTime: endDate.getTime(),
    }
    if (!this.isNewProjectSelected) {
      this.updatesProjectWorkingHours()
    }
  }

  @action.bound
  public setProjectClosureDates(intervals: IProjectClosedInterval[]) {
    this.closedIntervals = intervals
    if (!this.isNewProjectSelected) {
      this.updatesProjectClosures()
    }
  }

  @action.bound
  public toggleWorkingDayForNewProject(dayNumber: number) {
    const index = this.workingDays.findIndex(day => day === dayNumber)
    if (index === -1) {
      this.workingDays.push(dayNumber)
    } else {
      this.workingDays.splice(index, 1)
    }
  }

  @action.bound
  public setProjectWorkingDays(workingDays: number[]) {
    this.workingDays = workingDays
    this.updateProjectWorkingDays()
  }

  @action.bound
  public setProjectDefaultLandingPage(page: DefaultLandingPage) {
    this.defaultLandingPage = page
    if (!this.isNewProjectSelected) {
      this.updateProjectDefaultLandingPage()
    }
  }

  @action.bound
  public showWhiteListDialog() {
    this.shouldShowWhiteListedDomainDialog = true
  }

  @action.bound
  public hideWhiteListDialog() {
    this.shouldShowWhiteListedDomainDialog = false
  }

  @action.bound
  public toggleDeliveriesEnabled() {
    this.isDeliveriesEnabled = !this.isDeliveriesEnabled
    if (
      !this.isDeliveriesEnabled &&
      this.defaultLandingPage === DefaultLandingPage.Deliveries
    ) {
      this.setDefaultLandingPage()
    }
    this.setTypeOptions()
  }

  @action.bound
  public toggleFormsEnabled() {
    this.isFormsEnabled = !this.isFormsEnabled
    if (
      !this.isFormsEnabled &&
      this.defaultLandingPage === DefaultLandingPage.Forms
    ) {
      this.setDefaultLandingPage()
    }
    this.setTypeOptions()
  }

  @action.bound
  public toggleAnalyticsEnabled() {
    this.isAnalyticsEnabled = !this.isAnalyticsEnabled
    if (
      !this.isAnalyticsEnabled &&
      this.defaultLandingPage === DefaultLandingPage.Analytics
    ) {
      this.setDefaultLandingPage()
    }
    this.setTypeOptions()
  }

  @action.bound
  public toggleTrackerEnabled() {
    this.isTrackerEnabled = !this.isTrackerEnabled
    if (
      !this.isTrackerEnabled &&
      this.defaultLandingPage === DefaultLandingPage.Tracker
    ) {
      this.setDefaultLandingPage()
    }
    this.setTypeOptions()
  }

  @action.bound
  public toggleLogisticsEnabled() {
    this.isLogisticsEnabled = !this.isLogisticsEnabled
    if (
      !this.isLogisticsEnabled &&
      this.defaultLandingPage === DefaultLandingPage.Home
    ) {
      this.setDefaultLandingPage()
    }
    this.setTypeOptions()
  }

  @action.bound
  public togglePhotosEnabled() {
    this.isPhotosEnabled = !this.isPhotosEnabled
    if (
      !this.isPhotosEnabled &&
      this.defaultLandingPage === DefaultLandingPage.Photos
    ) {
      this.setDefaultLandingPage()
    }
    this.setTypeOptions()
  }

  @action.bound
  public toggleMaterialsEnabled() {
    this.isMaterialsEnabled = !this.isMaterialsEnabled
    if (
      !this.isMaterialsEnabled &&
      this.defaultLandingPage === DefaultLandingPage.Materials
    ) {
      this.setDefaultLandingPage()
    }
    this.setTypeOptions()
  }

  @action.bound
  public toggleRequireToCreateAccount() {
    this.requireToCreateAccount = !this.requireToCreateAccount
    if (!this.isNewProjectSelected) {
      this.saveProjectVisitorOptions()
    }
  }

  @action.bound
  public toggleLimitUserDirectory() {
    this.limitUserDirectory = !this.limitUserDirectory
    if (!this.isNewProjectSelected) {
      this.saveProjectVisitorOptions()
    }
  }

  @action.bound
  public toggleAllowToRequestPermit() {
    this.permitRequestEnabled = !this.permitRequestEnabled
    if (!this.isNewProjectSelected) {
      this.saveProjectVisitorOptions()
    }
  }

  @action.bound
  public removeUserVisibleToVisitors({ id }: User) {
    this.contactsVisibleToVisitors.users = (
      this.contactsVisibleToVisitors.users || []
    ).filter(userId => userId !== id)

    this.saveProjectVisitorOptions()
  }

  @action.bound
  public removeTagVisibleToVisitors({ type, id }: ITag) {
    this.contactsVisibleToVisitors[type] = (
      this.contactsVisibleToVisitors[type] || []
    ).filter(tagId => tagId !== id)

    this.saveProjectVisitorOptions()
  }

  @action.bound
  public changeVisitorContacts(newContacts: IContactsVisibleToVisitors) {
    Object.assign(this.contactsVisibleToVisitors, newContacts)
    this.saveProjectVisitorOptions()
  }

  @action.bound
  public submitForm(
    additionalSaveEvent?: (projectId: string) => Promise<void>,
  ) {
    this.createProject(additionalSaveEvent)

    this.disableNewProjectSelection()
  }

  @action.bound
  public enableNewProjectSelection() {
    this.isNewProjectSelected = true
  }

  @action.bound
  public disableNewProjectSelection() {
    this.isNewProjectSelected = false
  }

  @action.bound
  public selectProject(project: Project) {
    this.disableNewProjectSelection()
    this.projectsStore.selectProject(project).then(() => {
      this.setProjectDataValues()
      this.resetErrors()
    })
  }

  @action.bound
  public onNewProjectSelected() {
    this.enableNewProjectSelection()
    this.clearForm()
    this.resetErrors()
    this.setDefaultProjectWorkingDays()
    this.setDefaultProjectTimezoneAndLocation()
    this.setDefaultProjectWorkingHours()
    this.projectSetUpPageStore.navigateTo(ProjectSetUpPage.PROJECT_CREATION)
  }

  @action
  public resetProjectCode() {
    this.projectCode = ''
  }

  public copySignUpLinkToClipboard = () => {
    window.navigator.clipboard.writeText(this.projectSignUpUrl)

    showToast(
      Localization.translator.copiedToClipboard,
      ToastTheme.SUCCESS,
      IconNames.TICK,
    )
  }

  public get projectSignUpUrl(): string {
    const path = getTransitionPath(
      commonRoutes.SIGN_UP,
      this.activeProject.code,
    )

    return location.origin + path
  }

  @action.bound
  public setDefaultProjectWorkingHours() {
    this.workingHours = this.defaultWorkingHours
  }

  @action.bound
  private setTypeOptions() {
    this.setDefaultToggle()

    if (!this.isNewProjectSelected) {
      this.updateProjectDefaultLandingPage()
      this.saveTypeOptions()
    }
  }

  @action.bound
  private setDefaultProjectWorkingDays() {
    this.workingDays = defaultWorkingDays.slice()
  }

  private setDefaultProjectTimezoneAndLocation = () => {
    const { latitude, longitude } = this.eventsStore.appState
    if (latitude && longitude) {
      this.timezoneId = getTimezoneIdByCoordinates(longitude, latitude)
      this.projectAddress.center = {
        lat: latitude,
        lng: longitude,
      }
    } else {
      this.timezoneId = defaultTimezoneId
    }
  }

  private clearForm() {
    const state = this.eventsStore.appState
    this.projectName = ''
    this.projectCode = ''
    this.projectLogoUrl = ''
    this.projectAddress = state.getDefaultProjectAddress()
    const {
      isDeliveriesDisabled,
      isTrackerDisabled,
      isLogisticsDisabled,
      isMaterialsDisabled,
      isFormsDisabled,
    } = state.getDefaultProjectTypeOptions()
    this.isDeliveriesEnabled = !isDeliveriesDisabled
    this.isFormsEnabled = !isFormsDisabled
    this.isTrackerEnabled = !isTrackerDisabled
    this.isLogisticsEnabled = !isLogisticsDisabled
    this.isMaterialsEnabled = !isMaterialsDisabled
    this.timezoneId = ''
    this.dateTimeFormat = DateTimeFormat.Default

    const {
      contactsVisibleToVisitors,
      limitUserDirectory,
      permitRequestEnabled,
      requireToCreateAccount,
    } = state.getDefaultProjectVisitorOptions()
    this.contactsVisibleToVisitors = contactsVisibleToVisitors
    this.limitUserDirectory = limitUserDirectory
    this.permitRequestEnabled = permitRequestEnabled
    this.requireToCreateAccount = requireToCreateAccount
  }

  private setDefaultToggle = () => {
    if (
      !this.isDeliveriesEnabled &&
      !this.isTrackerEnabled &&
      !this.isLogisticsEnabled &&
      !this.isMaterialsEnabled &&
      !this.isPhotosEnabled &&
      !this.isFormsEnabled
    ) {
      this.isLogisticsEnabled = true
    }
  }

  private getProjectTypeOptions(
    id: string,
    projectId: string,
  ): IProjectTypeOptionsInput {
    return {
      id,
      projectId,
      isDeliveriesDisabled: !this.isDeliveriesEnabled,
      isTrackerDisabled: !this.isTrackerEnabled,
      isLogisticsDisabled: !this.isLogisticsEnabled,
      isMaterialsDisabled: !this.isMaterialsEnabled,
      isPhotosDisabled: !this.isPhotosEnabled,
      isFormsDisabled: !this.isFormsEnabled,
      isAnalyticsDisabled: !this.isAnalyticsEnabled,
    }
  }

  private getProjectVisitorOptions(
    id: string,
    projectId: string,
  ): IProjectVisitorOptions {
    return {
      id,
      projectId,
      limitUserDirectory: this.limitUserDirectory,
      permitRequestEnabled: this.permitRequestEnabled,
      requireToCreateAccount: this.requireToCreateAccount,
      contactsVisibleToVisitors: this.contactsVisibleToVisitors,
    }
  }

  private async createProject(
    additionalSaveEvent?: (projectId: string) => Promise<void>,
  ): Promise<string> {
    const project = Object.assign(new Project(), this.updatedFields, {
      workingDays: this.workingDays,
      workingHours: this.workingHours,
      setUpFinished: [PAGE_NAME],
      defaultLandingPage: this.defaultLandingPage,
      closedIntervals: this.closedIntervals,
    })

    const {
      data: { saveManyProjects },
    } = await this.graphExecutorStore.mutate(SaveProjectsDocument, {
      projects: [project.toInput()],
    })

    const [savedProject] = saveManyProjects

    this.projectsStore.setOne(savedProject)

    const projectId = savedProject.id

    await this.addProjectToUser(savedProject)
    await this.saveTypeOptions(projectId, true)
    await this.saveAddress(projectId, true)
    await this.saveProjectVisitorOptions(projectId, true)
    await this.projectSetUpPageStore.saveProjectHistory(
      {
        lastUpdated: [this.lastUpdated],
        projectId,
      },
      true,
    )
    await this.fillProjectWithMaterials(projectId)

    if (additionalSaveEvent) {
      await additionalSaveEvent(projectId)
    }

    this.eventsStore.dispatch(e.ACTIVATE_PROJECT_SUCCESS, savedProject)

    return savedProject.id
  }

  private async addProjectToUser({ id: projectId }: IProjectInput) {
    const { user } = this.eventsStore.appState

    const userProject = UserProject.getDefaultSettingsForJustCreatedProject(
      user.id,
      projectId,
    )

    await this.userProjectsStore.save([userProject.toDto()])
  }

  private saveProject(
    project: Project | IProjectInput,
    hasTimezoneChanged?: boolean,
  ): Promise<string> {
    project.workingHours = project.workingHours || this.defaultWorkingHours

    return new Promise((resolve, reject) => {
      this.eventsStore.dispatch(
        e.SAVE_PROJECT,
        project,
        {
          onSuccessCb: resolve,
          hasTimezoneChanged,
        } as ISaveProjectSuccessOptions,
        reject,
      )
    })
  }

  private saveTypeOptions(
    newProjectId?: string,
    shouldCompleteRequest?: boolean,
  ): Promise<IMutation> {
    return new Promise((resolve, reject) => {
      const { id: projectTypeOptionId } =
        this.eventsStore.appState.projectTypeOptions
      const { id: projectId } = this.activeProject
      this.eventsStore.dispatch(
        e.SAVE_PROJECT_TYPE_OPTIONS,
        this.getProjectTypeOptions(
          newProjectId ? null : projectTypeOptionId,
          newProjectId || projectId,
        ),
        resolve,
        reject,
        shouldCompleteRequest,
      )
    })
  }

  private saveAddress(
    newProjectId?: string,
    shouldCompleteRequest?: boolean,
  ): Promise<IMutation> {
    return new Promise((resolve, reject) => {
      const { id: projectAddressId } = this.eventsStore.appState.projectAddress
      const { id: projectId } = this.activeProject
      this.eventsStore.dispatch(
        e.SAVE_PROJECT_ADDRESSES,
        [
          Object.assign(this.projectAddress, {
            id: newProjectId ? null : projectAddressId,
            projectId: newProjectId || projectId,
          }),
        ],
        resolve,
        reject,
        shouldCompleteRequest,
      )
    })
  }

  private saveProjectVisitorOptions(
    newProjectId?: string,
    shouldCompleteRequest?: boolean,
  ): Promise<IMutation> {
    return new Promise((resolve, reject) => {
      const { id } = this.eventsStore.appState.projectVisitorOptions
      const { id: projectId } = this.activeProject
      this.eventsStore.dispatch(
        e.SAVE_PROJECT_VISITOR_OPTIONS,
        this.getProjectVisitorOptions(
          newProjectId ? null : id,
          newProjectId || projectId,
        ),
        resolve,
        reject,
        shouldCompleteRequest,
      )
    })
  }

  private fillProjectWithMaterials(projectId: string): Promise<IMutation> {
    return new Promise((resolve, reject) => {
      this.eventsStore.dispatch(
        e.FILL_PROJECT_WITH_DEFAULT_MATERIALS,
        projectId,
        resolve,
        reject,
      )
    })
  }

  private updateProjectDateTimeFormat() {
    const project = Object.assign(this.activeProject.toInput(), {
      dateTimeFormat: this.dateTimeFormat,
    })
    this.saveProject(project)
  }

  private updateProjectWorkingDays() {
    const project = Object.assign(this.activeProject.toInput(), {
      workingDays: this.workingDays,
    })
    this.saveProject(project)
  }

  private updateProjectDefaultLandingPage() {
    const project = Object.assign(this.activeProject.toInput(), {
      defaultLandingPage: this.defaultLandingPage,
    })
    this.saveProject(project)
  }

  private updatesProjectClosures() {
    const project = Object.assign(this.activeProject.toInput(), {
      closedIntervals: this.closedIntervals,
    })
    this.saveProject(project)
  }

  private updatesProjectWorkingHours() {
    const project = Object.assign(this.activeProject.toInput(), {
      workingHours: this.workingHours,
    })
    this.saveProject(project)
  }

  private updateProject() {
    const hasTimezoneChanged = this.timezoneId !== this.activeProject.timezoneId
    const project = Object.assign(
      this.activeProject.toInput(),
      this.updatedFields,
    )
    this.projectSetUpPageStore.setPageLastUpdated(this.lastUpdated)
    this.projectSetUpPageStore.saveCurrentProjectHistory()

    this.saveAddress()
    this.saveTypeOptions()
    this.saveProjectVisitorOptions()
    this.saveProject(project, hasTimezoneChanged)
  }

  private setDefaultLandingPage() {
    switch (true) {
      case this.isLogisticsEnabled:
        this.defaultLandingPage = DefaultLandingPage.Home
        break
      case this.isDeliveriesEnabled:
        this.defaultLandingPage = DefaultLandingPage.Deliveries
        break
      case this.isTrackerEnabled:
        this.defaultLandingPage = DefaultLandingPage.Tracker
        break
      case this.isMaterialsEnabled:
        this.defaultLandingPage = DefaultLandingPage.Materials
        break
      case this.isPhotosEnabled:
        this.defaultLandingPage = DefaultLandingPage.Photos
        break
      case this.isFormsEnabled:
        this.defaultLandingPage = DefaultLandingPage.Forms
        break
    }
  }

  @action.bound
  private resetErrors() {
    this.isProjectErrorMessageVisible = false
    this.isProjectCodeErrorMessageVisible = false
  }

  private getFormattedProjectCode(bareProjectCode: string): string {
    return bareProjectCode
      .replace(WITHOUT_SPECIAL_CHARACTERS_PATTERN, '')
      .slice(0, projectCodeMaxLength)
      .toUpperCase()
  }
}
